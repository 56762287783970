const emptyCacheStorage = async (version) => {
  if ('caches' in window) {
    // Service worker cache should be cleared with caches.delete()
    //
    // Removed because security error from Firefox browser
    // const cacheKeys = await window.caches.keys();
    // await Promise.all(cacheKeys.map((key) => window.caches.delete(key)));
  }

  // reload page
  await window.localStorage.setItem('appVersion', version);
  window.location.replace(window.location.href);
};

export const checkBuildVersion = () => {
  const filename = process.env.REACT_APP_VERSION_FILENAME;
  const baseUrl = `/${filename}`;
  try {
    fetch(baseUrl, {
      cache: 'no-store',
    })
      .then((response) => response.json())
      .then((meta) => {
        const newVersion = meta.version;
        const currentVersion = window.localStorage.getItem('appVersion');
        if (newVersion !== parseInt(currentVersion)) {
          emptyCacheStorage(newVersion);
        }
      });
  } catch (err) {
    console.error(err);
  }
};
