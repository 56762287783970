import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/buttons/Button';

const ConfirmFreeContent = ({
  modalContext: { RenderModal },
  onConfirm,
  onSetPrice,
}) => {
  const { t } = useTranslation();
  return (
    <RenderModal
      title={t('features:blockReport.confirmFreePost', 'Confirm free post')}
      buttonClose
      footer={
        <>
          <Button onClick={onSetPrice} name="btn-grey" style={{ width: '48%' }}>
            {t('features:createPost.setPrice', 'Set price ')}
          </Button>
          <Button onClick={onConfirm} name="btn" style={{ width: '48%' }}>
            {t('main:Confirm', 'Confirm')}
          </Button>
        </>
      }
    >
      <p>{t('features:confirmPrice.confirmFreePost')}</p>
    </RenderModal>
  );
};

export default ConfirmFreeContent;
