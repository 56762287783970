import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSubscriptionsBundles,
  deleteSubscriptionBundle,
  getSubscriptionSettings,
  selectSubscriptionsPriceSettings,
} from '../subscriptionsSlice';
import { Button } from 'components/buttons';
import { unwrapResult } from '@reduxjs/toolkit';
import { IconCross, IconEdit } from 'assets/icons';

import styles from './subscriptionBundlesList.module.css';
import { useTranslation } from 'react-i18next';

const DeletBundle = ({ id }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onDelete = async () => {
    setLoading(true);
    const res = await dispatch(deleteSubscriptionBundle({ id }));
    await unwrapResult(res);

    dispatch(getSubscriptionSettings());
  };

  return (
    <div className={styles.deleteBtn}>
      <Button
        disabled={loading}
        onClick={onDelete}
        name="btn-bare"
        style={{ marginLeft: 'auto', paddingRight: '5px' }}
      >
        {loading ? (
          '...'
        ) : (
          <IconCross width={'15px'} style={{ fill: 'var(--accentColor)' }} />
        )}
      </Button>
    </div>
  );
};

const TableHead = () => {
  const { t } = useTranslation();
  return (
    <tr style={{ textTransform: 'uppercase' }}>
      <th>{t('features:subscriptions.bundlesList.paymentFrequency')}</th>
      <th></th>
      <th></th>
      <th style={{ textAlign: 'right' }}>
        {t('features:subscriptions.bundlesList.price')}
      </th>
      <th style={{ textAlign: 'right' }}>
        {t('features:subscriptions.bundlesList.action')}
      </th>
    </tr>
  );
};

const BundleRow = ({
  id,
  frequency,
  discount,
  originalPrice,
  price,
  action,
}) => {
  return (
    <tr>
      <td style={{ color: 'var(--grey3)' }}>{frequency}</td>
      <td style={{ color: 'var(--turg)', fontWeight: 'bold' }}>{discount}</td>
      <td>{originalPrice}</td>
      <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{price}</td>
      <td style={{ textAlign: 'right' }}>{action}</td>
    </tr>
  );
};

const SubscriptionBundlesList = ({ onBasePriceEdit }) => {
  const bundles = useSelector(selectSubscriptionsBundles);
  const { price_text } = useSelector(selectSubscriptionsPriceSettings);
  const { t } = useTranslation();

  if (!bundles) return null;

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <tbody>
          <TableHead />
          <BundleRow
            frequency={t('features:subscriptions.bundlesList.everyMonth')}
            price={price_text}
            action={
              <Button
                style={{ marginLeft: 'auto', paddingRight: 0 }}
                name="btn-bare"
                onClick={onBasePriceEdit}
              >
                <IconEdit
                  width={'15px'}
                  style={{ fill: 'var(--accentColor)' }}
                />
              </Button>
            }
          />
          {bundles.map((bundle) => {
            return (
              <BundleRow
                key={bundle.id}
                id={bundle.id}
                frequency={t(
                  'features:subscriptions.bundlesList.everySomeMonths',
                  { num: bundle.duration.title }
                )}
                discount={t(
                  'features:subscriptions.bundlesList.discountPercent',
                  { percent: bundle.discount_percent }
                )}
                price={bundle.price.price_text}
                action={<DeletBundle id={bundle.id} />}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SubscriptionBundlesList;
