import Header from './Header';
import Content from './Content';
//import Footer from "./Footer";
// import ActivityFeedFooter from './activityDetail/Footer';

const Activity = ({ post, publicModal }) => {
  return (
    <div className="raf-activity">
      <Header activity={post} publicModal={publicModal} isCategory />
      <Content activity={post} publicModal={publicModal} />
      {/*<Footer activity={post} publicModal={publicModal} />*/}
    </div>
  );
};

export default Activity;
