import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ReCaptchaProvider from 'app/recaptcha';
import WebSocketProvider from 'app/webSocket';
import { checkBuildVersion } from 'helpers/build_version';
import { ChatContextProvider } from 'features/chat/chatContext';

import { AuthRoutes, PublicRoutes, UserBlockRoutes } from 'app/routes';
import SplashScreen from 'components/layout/splashScreen/SplashScreen';
import Loader from 'components/layout/loader';
import { useUrlQuery } from 'hooks';
import {
  selectAuthenticated,
  selectSplashScreen,
  selectUserInfo,
  selectUserFetched,
  selectProfileIncomplete,
  getUserCurrency,
  getUser,
  checkID,
} from 'features/auth/authSlice';
import { resetSearch } from 'features/userList/search/searchSlice';
import { setInfoMessages } from 'features/infoMessages/infoMessagesSlice';
import { getUnreadNotificationNum } from 'features/notifications/notificationsSlice';
import { getUserStatistics } from 'features/auth/userStatistics';
import {
  getReportReasons,
  getCloseAccountReasons,
  getCountries,
  getFeedCategories,
  getUserCategories,
  saveLocation,
  applyFilters, getTips, getEventTypes, getCancellationPolicy,
  // setShowPublicLookingFor,
} from 'app/miscSlice';
import {LoginProvider} from "./features/auth/loginForm/LoginContext";

const App = () => {
  const isAuthenticated = useSelector(selectAuthenticated);
  const showSplashScreen = useSelector(selectSplashScreen);
  const profileIncomplete = useSelector(selectProfileIncomplete);
  const user = useSelector(selectUserInfo);
  const userFetched = useSelector(selectUserFetched);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = useUrlQuery();

  useEffect(() => {
    if (!userFetched) {
      dispatch(getUser());
    }
  }, [userFetched, dispatch]);

  useEffect(() => {
    checkBuildVersion();
  }, []);

  useEffect(() => {
    //save user intended location to restore after login

    const handleReferral = () => {
      const paramVal = query.get('ref');
      if (paramVal) {
        window.localStorage.setItem('ref', paramVal);
      }
    };

    if (userFetched) {
      if (!isAuthenticated) {
        const location = window.location.pathname + window.location.search;
        if (location !== '/') {
          dispatch(saveLocation(location));
        }
        handleReferral();
      }
    }
  }, [isAuthenticated, dispatch, search, query, userFetched]);

  useEffect(() => {
    if (userFetched) {
      if (isAuthenticated) {
        dispatch(
          setInfoMessages({
            total_pending_posts: user.stats.total_pending_posts,
            about_status: user.about_status,
          })
        );
        dispatch(resetSearch());
        dispatch(getUserCurrency(user.payments.user_currency));
        dispatch(getUserStatistics());
        dispatch(checkID());
        dispatch(getUnreadNotificationNum());
      }
    }
  }, [isAuthenticated, userFetched, dispatch, user]);

  useEffect(() => {
    if (userFetched) {
      const lookingFor = window.localStorage.getItem('lookingFor');
      if (isAuthenticated) {
        dispatch(
          applyFilters({
            gender: user.looking_for,
          })
        );
        window.localStorage.setItem('lookingFor', user.looking_for);
      } else {
        dispatch(
          applyFilters({
            gender: lookingFor ? Number(lookingFor) : 3,
          })
        );
      }
    }
  }, [user.looking_for, isAuthenticated, userFetched, dispatch]);

  useEffect(() => {
    if (userFetched) {
      dispatch(getCountries());
      dispatch(getTips());
      dispatch(getEventTypes());
      dispatch(getCancellationPolicy());
      dispatch(getFeedCategories({ isPublic: !isAuthenticated }));
      dispatch(getUserCategories({ isPublic: !isAuthenticated }));
      if (isAuthenticated) {
        dispatch(getReportReasons());
        dispatch(getCloseAccountReasons());
      }
      if (i18n && isAuthenticated) {
        if (user.language_gui && i18n.language !== user.language_gui) {
          i18n.changeLanguage(user.language_gui);
        }
      }
    }
  }, [isAuthenticated, dispatch, user.language_gui, userFetched, i18n]);

  if (!userFetched) {
    return <Loader />;
  }

  if (showSplashScreen) {
    return <SplashScreen />;
  }

  if (profileIncomplete) {
    return <UserBlockRoutes />;
  }

  if (isAuthenticated) {
    return (
      <WebSocketProvider>
        <ChatContextProvider>
          <AuthRoutes />
        </ChatContextProvider>
      </WebSocketProvider>
    );
  }

  return (
    <ReCaptchaProvider>
        <LoginProvider>
            <PublicRoutes />
        </LoginProvider>
    </ReCaptchaProvider>
  );
};

export default App;
