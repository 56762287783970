import { useState, useContext } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormikContext, Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import { isEmpty, isNumber, times } from 'lodash';
import * as Yup from 'yup';
import cx from 'classnames';

import { Row } from 'components/layout/grid';
import AfterRegisterContext from './Context';
import {
  FormRow,
  FormFieldError,
  TextArea,
  Select,
  //Switch,
} from 'components/forms';
import ProfileImageEdit from 'features/user/profileImageEdit/ProfileImageEdit';
import CountryCitySelect from 'components/forms/CountryCitySelect';
import { Avatar } from 'components/avatar';
import * as Icons from 'assets/icons';
import { trackEvent } from 'helpers/tracking';
import * as ProfileIcons from 'assets/icons/profile';
//import * as MenuIcons from 'assets/icons/mainMenu';
import Button from 'components/buttons/Button';
import { setProfileInfo } from 'features/settings/settingsSlice';
import { updateUser } from 'features/auth/authSlice';
import formStyles from 'components/forms/forms.module.css';
import styles from './afterRegister.module.css';
import {LogoSvg} from "assets/icons";

const AfterRegister = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [avatarImg, setAvatarImg] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isUploadDisabled = process.env.REACT_APP_DISABLE_UPLOAD === 'true';

  const validation = Yup.object().shape({
    gender: Yup.string().required(t('components:validation.required')),
    //looking_for: Yup.number()
//      .min(1, 'Choose at least one')
  //    .required('Required'),
    full_name: Yup.string()
      .min(5, t('components:validation.tooShort'))
      .max(20, t('components:validation.tooLong'))
      .required(t('components:validation.required')),
    city: Yup.string().required(t('components:validation.required')),
    country: Yup.string().required(t('components:validation.required')),
    dating_enabled: Yup.string().required(t('components:validation.required')),
  });

  const handleSubmit = async (values) => {
    let parsedValues = {
      gender: values.gender,
      looking_for: values.looking_for,
      full_name: values.full_name,
      about: values.about,
      town: values.city,
      dating_enabled: !values.dating,
      country_code: values.country.toLowerCase(),
    };
    await dispatch(setProfileInfo(parsedValues))
      .then(unwrapResult)
      .then((result) => {
        if (result.status) {
          dispatch(updateUser());
          trackEvent({
            category: 'auth',
            action: 'register',
            label: `setup_account - ${values.gender}`,
          });
        } else {
          toast.error(result.error.message);
        }
      });
  };

  const nextStep = () => {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
  };

  const StepsCounter = () => {
    const { currentStep, totalSteps } = useContext(AfterRegisterContext);

    return (
      <Row className={styles.counterWrapper}>
        <span>{`${t(
          'main:landing.register'
        )} ${currentStep}/${totalSteps}`}</span>
        <div className={styles.progressBar}>
          {times(totalSteps, (i) => (
            <div key={i} className={styles.progressItem}>
              <div
                className={cx(styles.progressItemInner, {
                  [styles.active]: i + 1 <= currentStep,
                })}
              />
            </div>
          ))}
        </div>
      </Row>
    );
  };

  const StepsHeader = () => {
    const { currentStep } = useContext(AfterRegisterContext);

    const steps = {
      1: { icon: () => <LogoSvg/> },
      //2: { icon: () => <MenuIcons.Dating /> },
      2: { icon: () => <ProfileIcons.City /> },
      3: { icon: () => <Icons.Profile /> },
      4: { icon: () => <ProfileIcons.DateDetails /> },
      5: { icon: () => <Icons.IconPhoto /> },
    };

    return (
      <div className={styles.descWrapper}>
        <div className={styles.ico}>{steps[currentStep].icon()}</div>
        <div className={styles.copy}>
          <h4>{t(`features:auth.afterRegister.steps.${currentStep}.title`)}</h4>
          <p>{t(`features:auth.afterRegister.steps.${currentStep}.text`)}</p>
        </div>
        <hr />
      </div>
    );
  };

  const StepsFooter = ({ isNextDisabled }) => {
    const { setNextStep, currentStep, totalSteps } = useContext(
      AfterRegisterContext
    );
    const { isSubmitting } = useFormikContext();

    const isLastStep = currentStep === totalSteps;

    return (
      <div className={styles.actionButtons}>
        {isLastStep ? (
          <Button
            fullWidth
            size="xl"
            name="btn-gradient"
            type="submit"
            loading={isSubmitting}
            disabled={isNextDisabled}
          >
            {t('main:main.Submit')}
          </Button>
        ) : (
          <Button
            fullWidth
            size="xl"
            name="btn-gradient"
            type="button"
            disabled={isNextDisabled}
            onClick={setNextStep}
          >
            {t('main:main.Next')}
          </Button>
        )}
      </div>
    );
  };

  const Step = ({ children, stepNum, stepFields = [] }) => {
    const { currentStep } = useContext(AfterRegisterContext);
    const { values, errors } = useFormikContext();

    const errorFields = !isEmpty(errors) ? Object.keys(errors) : [];
    const intersectErrors = stepFields.filter((element) =>
      errorFields.includes(element)
    );
    const intersectValues = stepFields.filter(
      (element) => isEmpty(values[element]) && !isNumber(values[element])
    );

    const isNextDisabled =
      !isEmpty(intersectErrors) || !isEmpty(intersectValues);

    const stepClassNames = `${styles.step} ${
      currentStep === stepNum ? styles.active : ''
    }`;

    return (
      <div className={stepClassNames}>
        <StepsHeader />
        {children}
        <StepsFooter isNextDisabled={isNextDisabled} />
      </div>
    );
  };

  const StepsForm = () => {
    const { avatar, setAvatar } = useContext(AfterRegisterContext);
    const { values } = useFormikContext();

    return (
      <Form style={{ width: '100%' }}>
        <Step stepNum={1} stepFields={['gender']}>
          <FormRow>
            <Select
              required
              label={t('features:auth.register.gender')}
              name="gender"
              options={[
                { slug: 'male', name: t('features:auth.register.IAmMan') },
                {
                  slug: 'female',
                  name: t('features:auth.register.IAmWoman'),
                },
              ]}
            />
          </FormRow>
          {/*<FormRow>
            <Select
              required
              label={t('features:auth.register.lookingFor')}
              name="looking_for"
              options={[
                {
                  slug: 1,
                  name:
                    t('features:auth.register.man') +
                    ' & ' +
                    t('features:auth.register.woman'),
                },
                { slug: 2, name: t('features:auth.register.man') },
                {
                  slug: 3,
                  name: t('features:auth.register.woman'),
                },
              ]}
            />
          </FormRow>*/}
        </Step>
        {/*<Step stepNum={2}>
          <FormRow className={styles.formRowDating}>
            <strong className={styles.datingLeft}>{t('main:main.yes')}</strong>
            <Switch name="dating" datingSwitch />
            <strong className={styles.datingRight}>{t('main:main.no')}</strong>
          </FormRow>
        </Step>*/}
        <Step stepNum={2} stepFields={['city', 'country']}>
          <FormRow style={{ position: 'relative' }}>
            <CountryCitySelect required withLabel />
            <FormFieldError fieldName="city" />
          </FormRow>
        </Step>
        <Step stepNum={3} stepFields={['full_name']}>
          <FormRow style={{ position: 'relative' }}>
            <label
              className={`${formStyles.formLabel} ${formStyles.required}`}
              htmlFor="full_name"
            >
              {t('features:auth.register.userName')}
            </label>

            <Field
              className={formStyles.input}
              placeholder={t('features:auth.register.userName')}
              type="text"
              name="full_name"
            />
            <FormFieldError fieldName="full_name" />
          </FormRow>
        </Step>
        <Step stepNum={4}>
          <FormRow style={{ position: 'relative' }}>
            <TextArea label="Bio" name="about" withCounter />
          </FormRow>
        </Step>
        {!isUploadDisabled && (
          <Step stepNum={5}>
            <FormRow style={{ position: 'relative' }}>
              <div className={styles.avatar}>
                <Avatar
                  circle
                  border
                  plus
                  size={180}
                  image={avatar}
                  gender={values.gender}
                />
                <ProfileImageEdit onImageEdit={setAvatar} />
              </div>
            </FormRow>
          </Step>
        )}
      </Form>
    );
  };

  const context = {
    totalSteps: isUploadDisabled ? 4 : 5,
    currentStep: currentStep,
    setNextStep: nextStep,
    avatar: avatarImg,
    setAvatar: setAvatarImg,
  };

  return (
    <Formik
      initialValues={{
        gender: '',
        full_name: '',
        looking_for: '',
        city: '',
        city_slug: '',
        country: '',
        country_name: '',
        about: '',
        dating_enabled: true,
      }}
      validationSchema={validation}
      onSubmit={(data) => {
        handleSubmit(data);
      }}
    >
      <AfterRegisterContext.Provider value={context}>
        <StepsCounter />
        <div className={styles.container}>
          <StepsForm />
        </div>
      </AfterRegisterContext.Provider>
    </Formik>
  );
};

export default AfterRegister;
