import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useParams, useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import Loader from 'components/layout/loader/Loader';
import { PublicLayout } from 'components/layout/layouts/Layout';
import ProfilePublicView from 'features/user/ProfilePublicView';
import { selectAuthenticated } from 'features/auth/authSlice';
import {
  getPublicProfile,
  selectPublicProfile,
  resetPublicProfile,
} from 'features/user/profileSlice';

const ProfilePublic = () => {
  const { hash_id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector(selectAuthenticated);
  const publicProfile = useSelector(selectPublicProfile);

  useEffect(() => {
    dispatch(getPublicProfile({ hash_id: hash_id }))
      .then(unwrapResult)
      .then((result) => {
        if (!result.status) {
          history.replace('/');
        }
      });
    return () => {
      dispatch(resetPublicProfile());
    };
  }, [dispatch, hash_id, history]);

  if (isEmpty(publicProfile)) {
    return <Loader />;
  }

  if (isAuthenticated && publicProfile) {
    history.replace(`/profile/${publicProfile.id}`);
  }

  return (
    <PublicLayout>
      <ProfilePublicView profileUser={publicProfile} />
    </PublicLayout>
  );
};

export default ProfilePublic;
