import React from 'react';
import PropTypes from 'prop-types';
import { addHours } from 'date-fns';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import cx from 'classnames';

import { Col } from 'components/layout/grid';
import { IconChevronDown, IconCheck } from 'assets/icons';
import styles from './forms.module.css';

export const FormRow = ({ children, isModal, ...rest }) => {
  return (
    <Col
      className={cx(styles.formRow, { [styles.formRowModal]: isModal })}
      {...rest}
    >
      {children}
    </Col>
  );
};

// Field error
export const FormFieldError = ({ fieldName, style, isCheckbox }) => {
  const classNames = cx(styles.formFieldError, {
    [styles.errorCheckbox]: isCheckbox,
  });
  return (
    <ErrorMessage name={fieldName}>
      {(msg) => (
        <span style={style} className={classNames}>
          {msg}
        </span>
      )}
    </ErrorMessage>
  );
};

// CheckBox
export const Checkbox = ({ name, children }) => {
  const form = useFormikContext();
  return (
    <label className={styles.customCheckBoxLabel}>
      <div className={styles.customCheckBox}>
        {form.values[name] ? <IconCheck className={styles.iconCheck} /> : null}
      </div>
      <p>{children}</p>
      <Field type="checkbox" name={name} />
    </label>
  );
};

export const CheckboxStandAlone = ({ children, value, onChange }) => {
  return (
    <label className={styles.customCheckBoxLabel}>
      <div className={styles.customCheckBox}>
        {value ? <IconCheck className={styles.iconCheck} /> : null}
      </div>
      <span style={{ margin: '0 10px' }}>{children}</span>
      <input
        defaultChecked={false}
        onChange={(e) => onChange(e.target.checked)}
        type="checkbox"
      />
    </label>
  );
};

export const Switch = ({ name, children, datingSwitch }) => {
  const form = useFormikContext();

  const setValue = () => {
    form.setFieldValue(name, !form.values[name]);
  };

  return (
    <label
      className={cx(styles.switchLabel, {
        [styles.on]: form.values[name],
        [styles.dating]: datingSwitch,
      })}
    >
      {children && <p>{children}</p>}
      <Field type="checkbox" name={name} onChange={setValue} />
    </label>
  );
};

export const Radio = ({ value, name, children }) => {
  const form = useFormikContext();

  const setValue = () => {
    form.setFieldValue(name, value);
  };

  return (
    <label className={styles.customCheckBoxLabel}>
      <div className={styles.customCheckBox}>
        {form.values[name] === value ? (
          <IconCheck className={styles.iconCheck} />
        ) : null}
      </div>
      <p>{children}</p>
      <Field type="radio" name={name} onChange={setValue} />
    </label>
  );
};

export const RadioCircle = ({ value, name, children }) => {
  const form = useFormikContext();

  const setValue = () => {
    form.setFieldValue(name, value);
  };

  return (
    <label className={styles.customCheckBoxLabel}>
      <div
        className={`${styles.customCheckBox} ${styles.customCheckBoxCircle}`}
      >
        {form.values[name] === value ? (
          <span className={styles.radioDot}></span>
        ) : null}
      </div>
      <p>{children}</p>
      <Field type="radio" name={name} onChange={setValue} />
    </label>
  );
};

// Text input
export const Input = (props) => {
  const {
    name,
    label,
    type,
    className,
    required,
    disabled = false,
    ...rest
  } = props;
  const labelClass = `${styles.formLabel} ${required ? styles.required : ''}`;
  return (
    <div className={className ? styles[className] : null}>
      <label htmlFor={name} className={labelClass}>
        {label}
      </label>
      <Field
        disabled={disabled}
        type={type || 'text'}
        id={name}
        name={name}
        className={styles.input}
        inputMode={type === 'number' ? 'numeric' : null}
        {...rest}
      ></Field>
      <FormFieldError fieldName={name} />
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
};

Input.defaultProps = {
  required: false,
};

export const Currency = ({
  children,
  name,
  label,
  className,
  required,
  disabled,
}) => {
  const labelClass = `${styles.formLabel} ${required ? styles.required : ''}`;
  return (
    <div className={className ? styles[className] : null}>
      {label && (
        <label htmlFor={name} className={labelClass}>
          {label}
        </label>
      )}
      <Field
        id={name}
        name={name}
        className={styles.input}
        component={children}
        disabled={disabled}
      />
      <span className={styles.errorMsg}>
        <FormFieldError fieldName={name} />
      </span>
    </div>
  );
};

Currency.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

Currency.defaultProps = {
  required: false,
  disabled: false,
};

export const Select = ({ options, name, label, className, required, pleaseSelectText = null, DisplayIconChevron = true, isDisabled = false }) => {
  const labelClass = `${styles.formLabel} ${required ? styles.required : ''}`;
  const { t } = useTranslation();
  return (
    <div className={className ? styles[className] : null}>
        {label &&
            <label htmlFor={name} className={labelClass}>
                {label}
            </label>
        }
      <Field as="select" name={name} className={styles.select} disabled={isDisabled}>
        <option key={0} value="">
            { pleaseSelectText ?
                pleaseSelectText :
                t('components:inputSelect.select', 'Please select')
            }
        </option>
        {options.map(function (item, i) {
          return (
            <option key={i + 1} value={item.slug}>
              {item.name}
            </option>
          );
        })}
      </Field>
      {DisplayIconChevron &&  <IconChevronDown className={styles.selectIcon} /> }
      <FormFieldError fieldName={name} />
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
};

Select.defaultProps = {
  required: false,
};

export const TextArea = ({
  name,
  label,
  className,
  maxLenght,
  required,
  withCounter,
  placeholder,
}) => {
  const { values } = useFormikContext();
  const description = values[name];
  const labelClass = `${styles.formLabel} ${required ? styles.required : ''}`;
  const inputClass = `${styles.textarea} ${withCounter ? styles.counter : ''}`;
  const wrapperClass = `${className ? styles[className] : ''} ${
    withCounter ? styles.wrapperCounter : ''
  }`;

  const handleTextareaRows = (text, initialRows, maxRows) => {
    const rows = initialRows + (text.match(/\n/g) || []).length;
    return Math.min(rows, maxRows);
  };

  return (
    <div className={wrapperClass}>
      {label && (
        <label htmlFor={name} className={labelClass}>
          {label}
        </label>
      )}
      <Field
        component="textarea"
        id={name}
        name={name}
        className={inputClass}
        maxLength={maxLenght}
        placeholder={placeholder}
        rows={handleTextareaRows(description, 1, 10)}
      ></Field>
      {withCounter && (
        <span className={styles.textCounter}>
          {description.length ? description.length : 0} / {maxLenght}
        </span>
      )}
      <FormFieldError fieldName={name} />
    </div>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  maxLenght: PropTypes.number,
  required: PropTypes.bool,
  withCounter: PropTypes.bool,
};

TextArea.defaultProps = {
  maxLenght: 1000,
  required: false,
  withCounter: false,
};

// use only within Formik context
export const OCalendar = ({ name, onHideCalendar }) => {
  const { values, setFieldValue } = useFormikContext();
  const today = addHours(new Date(), 3);
  let temp = addHours(new Date(), 3);
  const maxDate = temp.setDate(today.getDate() + 30);

  return (
    <Calendar
      value={values[name]}
      onChange={(val) => {
        setFieldValue(name, val);
        onHideCalendar();
      }}
      maxDate={new Date(maxDate)}
      minDate={today}
    />
  );
};



// Label Only
export const Label = (props) => {
    const {
        name,
        label,
        className,
        required,
        children,
    } = props;
    const labelClass = `${styles.formLabel} ${required ? styles.required : ''}`;
    return (
        <div className={className ? styles[className] : null}>
            <label htmlFor={name} className={labelClass}>
                {label}
            </label>
            { children }
            <FormFieldError fieldName={name} />
        </div>
    );
};

Label.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    children: PropTypes.node
};

Label.defaultProps = {
    required: false,
};