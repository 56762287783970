import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ChatContext } from 'stream-chat-react';
import { useTranslation } from 'react-i18next';
import { formatRelative } from 'date-fns';
import { de, enGB  } from 'date-fns/locale';
import cx from 'classnames';

import Avatar from 'components/avatar/Avatar';
import { ProfileBadges } from 'components/layout/badges/Badges';
import { Notifications } from 'assets/icons';
import { selectUserInfo } from 'features/auth/authSlice';
import styles from './channelPreview.module.css';

export const chatPartner = (channel, userId) => {
  for (const [key] of Object.entries(channel.state.members)) {
    if (Number(key) !== userId) return channel.state.members[key].user;
  }
};

const ChannelPreview = (props) => {
  const {
    channel,
    latestMessage,
    setActiveChannel,
    displayImage,
    displayTitle,
  } = props;
  const { channel: activeChannel } = useContext(ChatContext);
  const { i18n } = useTranslation();
  const me = useSelector(selectUserInfo);
  const partner = chatPartner(channel, me.uid);
  if (!partner) {
    return null;
  }
  const { id } = partner;
  const {
    age,
    verified,
    online,
    gender,
    dating_enabled,
    verified_phone,
  } = channel.state.members[id].user;
  const lang = i18n.language === 'de' ? de : enGB;
  const time = formatRelative(
    new Date(channel.state.last_message_at),
    new Date(),
    { locale: lang }
  );
  const isMuted = channel.muteStatus().muted;

  return (
    <div
      className={
        channel?.id === activeChannel?.id
          ? 'channel-preview__container selected'
          : 'channel-preview__container'
      }
      onClick={() => {
        setActiveChannel(channel);
      }}
    >
      <div className="channel-preview__avatar-container">
        <Avatar
          size={60}
          image={displayImage}
          online={online}
          gender={gender}
          circle
        />
      </div>
      <div className="channel-preview__content-wrapper">
        <div className="channel-preview__content-left">
          <div className="channel-preview__content-name">
            <span className={styles.userName}>{displayTitle}</span>
            <span className="channel-preview__content-age">{age}</span>
            <ProfileBadges small verified={verified} dating={dating_enabled} phone={verified_phone} />
          </div>

          <div className="channel-preview__content-message">
            {latestMessage || 'Send a message'}
          </div>
        </div>
        <div className="channel-preview__content-right">
          <div className={styles.muteBtn}>
            {isMuted && (
              <>
                <span className={styles.muteLine}></span>
                <Notifications style={{ width: '20px', height: '15px' }} />
              </>
            )}
          </div>
          <div className={styles.timeLine}>
            {channel.state.last_message_at && (
              <time
                dateTime={channel.state.last_message_at}
                title={channel.state.last_message_at}
                className={cx(styles.previewTime, {
                  [styles.unread]: channel.countUnread(),
                })}
              >
                {time}
              </time>
            )}

            {channel.countUnread() ? (
              <span className="channel-preview__content-unread">
                {channel.countUnread()}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChannelPreview;
