import React from 'react';
import { useTranslation } from 'react-i18next';
import SubHeader from 'components/layout/subHeader/SubHeader';
import styles from './channelListHeader.module.css';

const ChannelsListHeader = React.memo((props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.container}>
        <SubHeader
          hideOnMobile
          backBtn
          screenName={t('features:chat.chats')}
          style={{ paddingLeft: '15px' }}
        />
      </div>
      <div>{props.children}</div>
    </>
  );
});

export default ChannelsListHeader;
