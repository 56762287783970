import { useTranslation } from 'react-i18next';
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';

import {LegalPrivacy, LegalTerms } from 'assets/icons/settings';
import SettingsHeader from 'features/settings/SettingsHeader';
import SettingsLinksView from 'features/settings/SettingsLinksView';
import { Row, Col } from 'components/layout/grid';
import Layout from 'components/layout/layouts';

import { PrivacyPage, TermsPage, ImprintPage } from './LegalView';
import {LogoSvg} from "../../../assets/icons";

const LegalScreen = () => {
  const { url, path } = useRouteMatch();
  const location = useLocation();
  const { t } = useTranslation();

  const isMainPath = path === location.pathname;

  const options = {
    mainPage: [
      {
        to: `${path}/imprint`,
        title: t('features:settings.legal.imprint.h1'),
        text: t('features:settings.legal.imprint.span'),
        icon: () => <LogoSvg width="54" />,
      },
      {
        to: `${path}/privacy`,
        title: t('features:settings.legal.privacy.h1'),
        text: t('features:settings.legal.privacy.span'),
        icon: () => <LegalPrivacy width="50" />,
      },
      {
        to: `${path}/terms`,
        title: t('features:settings.legal.terms.h1'),
        text: t('features:settings.legal.terms.span'),
        icon: () => <LegalTerms width="50" />,
      },
    ],
    titles: {
      [path]: {
        h1: t('features:settings.legal.main.h1'),
      },
      [`${path}/imprint`]: {
        h1: t('features:settings.legal.imprint.h1'),
      },
      [`${path}/privacy`]: {
        h1: t('features:settings.legal.privacy.h1'),
      },
      [`${path}/terms`]: {
        h1: t('features:settings.legal.terms.h1'),
      },
    },
    tabs: [
      {
        to: `${path}/imprint`,
        text: t('features:settings.legal.imprint.tab'),
        exact: true,
        replace: true,
      },
      {
        to: `${path}/privacy`,
        text: t('features:settings.legal.privacy.tab'),
        exact: true,
        replace: true,
      },
      {
        to: `${path}/terms`,
        text: t('features:settings.legal.terms.tab'),
        exact: true,
        replace: true,
      },
    ],
  };

  return (
    <Layout
      screenName={t('features:settings.legal.main.h1')}
      mobilePadding={!isMainPath}
    >
      <Row center="md">
        <Col xs={12} md={10}>
          <SettingsHeader options={options} />
          <Switch>
            <Route path={url} exact>
              <SettingsLinksView options={options} />
            </Route>

            <Route path={`${path}/imprint`} exact>
              <ImprintPage />
            </Route>

            <Route path={`${path}/privacy`} exact>
              <PrivacyPage />
            </Route>

            <Route path={`${path}/terms`} exact>
              <TermsPage />
            </Route>

            <Redirect to={url} />
          </Switch>
        </Col>
      </Row>
    </Layout>
  );
};

export default LegalScreen;
