import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from 'app/api';
import AWS from 'aws-sdk';
import shortId from 'short-uuid';

import { trackEvent } from 'helpers/tracking';
import { useModal } from 'components/modal/useModal';
import Button from 'components/buttons/Button';
import { ImageEditor } from 'features/stories/components/create';
import VideoUploadProgress from './VideoUploadProgress';
import { getFileMeta } from 'helpers/mediaFileMeta';
import { selectUserInfo, selectKYCstatus } from 'features/auth/authSlice';
import { selectFilters } from 'app/miscSlice';
import { toggleCreateDrawer } from 'components/layout/layoutSlice';
import { getHomeStories } from 'features/stories/storiesSlice';
import styles from './create.module.css';

const CreateStoryTrigger = ({ children, className, onCreateStory }) => {
  const [image, setImage] = useState(null);
  const [imageMeta, setImageMeta] = useState(null);
  const [videoSuccess, setVideoSuccess] = useState(null);
  const [videoError, setVideoError] = useState(null);
  const [videoProgress, setVideoProgress] = useState(0);
  const { uid, gender } = useSelector(selectUserInfo);
  const kyc_status = useSelector(selectKYCstatus);
  const { t } = useTranslation();
  const modalUpload = useModal();
  const modalVerify = useModal();
  const dispatch = useDispatch();
  const fileSelect = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const filters = useSelector(selectFilters);
  const isHomepage = location.pathname === '/';

  const isUploadDisabled = process.env.REACT_APP_DISABLE_UPLOAD === 'true';
  const kyc_missing = kyc_status !== 3;

  AWS.config.update({
    region: process.env.REACT_APP_S3_BUCKET_REGION,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_S3_POOL_ID,
    }),
  });

  const handleFileUpload = async () => {
    trackEvent({
      category: 'menus',
      action: 'create story click',
      label: gender,
    });
    if (isUploadDisabled) {
      return modalUpload.showModal();
    } else if (kyc_missing) {
      return modalVerify.showModal();
    } else if (fileSelect) {
      fileSelect.current.click();
    }
  };

  const handleCloseImageEditor = () => {
    setImage(null);
    setImageMeta(null);
    fileSelect.current.value = null;
  };

  const handleCloseModal = () => {
    setVideoProgress(0);
    setVideoError(false);
    setVideoSuccess(false);
  };

  const handleVideoError = () => {
    setVideoError(true);
    setVideoProgress(100);
    fileSelect.current.value = null;
  };

  const onInputChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const fileInfo = await getFileMeta(file);
    if (!fileInfo) return;

    if (fileInfo.type === 'video') {
      // only videos shorter than 30 seconds
      if (Number(fileInfo.duration) < 31) {
        setVideoProgress(0);
        setVideoSuccess(false);
        setVideoError(false);
        uploadVideo(file, fileInfo);
      } else {
        handleVideoError();
      }
    } else {
      setImage(file);
      setImageMeta(fileInfo);
    }
  };

  function uploadVideo(file, fileMeta) {
    const fileExt = file.name.split('.').pop();
    const fileId = `stories/${uid}/${shortId.generate()}`;
    const s3Key = `${fileId}.${fileExt}`;
    let fileInfo = {
      duration: fileMeta.duration,
      width: fileMeta.width,
      height: fileMeta.height,
      type: fileMeta.type,
      size: file.size,
      format: fileExt,
      public_id: fileId,
    };
    // console.log(fileInfo, fileMeta);

    const upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME_UPLOAD,
        Key: s3Key,
        Body: file,
        ContentType: "image/jpg, image/png, image/jpeg",
      },
    });
    upload.on('httpUploadProgress', (e) => {
      setVideoProgress(Math.round((e.loaded * 100.0) / e.total));
    });

    const promise = upload.promise();
    promise.then(
      function (data) {
        fileInfo.s3_data = data;
        api
          .put('v2/user/profile/story', {
            media: JSON.stringify(fileInfo),
            status: 3, // success
          })
          .then((res) => {
            if (res.data.status) {
              setVideoSuccess(true);
              setTimeout(() => {
                setVideoProgress(0);
                setVideoSuccess(false);
                onCreateStory();
                if (isHomepage) {
                  dispatch(
                    getHomeStories({ gender: filters.gender, isRefresh: true })
                  );
                } else {
                  history.push('/');
                }
              }, 4000);
            } else {
              throw new Error('video story error');
            }
          })
          .catch((err) => {
            return handleVideoError();
          });
      },
      function (err) {
        return handleVideoError();
      }
    );
  }

  const imageWriter = {
    store: (state, options, onprogress) =>
      new Promise((resolve, reject) => {
        const { dest } = state;
        const fileExt = dest.name.split('.').pop();
        const fileId = `stories/${uid}/${shortId.generate()}`;
        const s3Key = `${fileId}.${fileExt}`;
        let fileInfo = {
          duration: imageMeta.duration,
          width: imageMeta.width,
          height: imageMeta.height,
          type: imageMeta.type,
          size: dest.size,
          format: fileExt,
          public_id: fileId,
        };
        // console.log(fileInfo, imageMeta);

        const upload = new AWS.S3.ManagedUpload({
          params: {
            Bucket: process.env.REACT_APP_S3_BUCKET_NAME_UPLOAD,
            Key: s3Key,
            Body: dest,
            ContentType: "image/jpg, image/png, image/jpeg",
          },
        });
        upload.on('httpUploadProgress', (e) => {
          onprogress(true, e.loaded, e.total);
        });

        const promise = upload.promise();
        promise.then(
          function (data) {
            fileInfo.s3_data = data;
            api
              .put('v2/user/profile/story', {
                media: JSON.stringify(fileInfo),
                status: 3, // success
              })
              .then((res) => {
                if (res.data.status) {
                  toast.success(t('features:stories.imageStory.success'));
                  onCreateStory();
                  setTimeout(() => {
                    if (isHomepage) {
                      dispatch(
                        getHomeStories({
                          gender: filters.gender,
                          isRefresh: true,
                        })
                      );
                    } else {
                      history.push('/');
                    }
                  }, 1500);
                  resolve(state);
                } else {
                  throw new Error('story create error');
                }
              })
              .catch((err) => {
                reject(t('main:errors.somethingWentWrong'));
              });
          },
          function (err) {
            reject(t('main:errors.somethingWentWrong'));
          }
        );
      }),
  };

  return (
    <>
      <div onClick={handleFileUpload} className={className}>
        <input
          ref={fileSelect}
          type="file"
          name="file"
          accept="image/*, video/*"
          onChange={onInputChange}
          className={styles.uploadInput}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
        {image && (
          <ImageEditor
            imageWriter={imageWriter}
            image={image}
            onHide={handleCloseImageEditor}
          />
        )}
        {children}
      </div>
      {videoProgress > 0 && (
        <VideoUploadProgress
          percentage={videoProgress}
          closeAction={() => handleCloseModal()}
          status={videoSuccess ? 'success' : videoError ? 'error' : 'loading'}
        />
      )}
      {isUploadDisabled && (
        <modalUpload.RenderModal
          buttonClose
          title={t('main:uploadDisabled.title')}
          children={<p>{t('main:uploadDisabled.text')}</p>}
          footer={
            <Button
              name="btn"
              onClick={() => modalUpload.hideModal()}
              type="button"
              fullWidth
            >
              {t('main:main.close')}
            </Button>
          }
        />
      )}
      {kyc_missing && (
        <modalVerify.RenderModal
          buttonClose
          title={t('features:Kyc.modalVerify.title')}
          children={<p>{t('features:Kyc.modalVerify.text')}</p>}
          footer={
            <Button
              name="btn"
              onClick={() => {
                history.push('/settings/wallet/kyc');
                dispatch(toggleCreateDrawer(false));
              }}
              type="button"
              fullWidth
            >
              {t('features:Kyc.verifyButton')}
            </Button>
          }
        />
      )}
    </>
  );
};

CreateStoryTrigger.propTypes = {
  onCreateStory: PropTypes.func,
};

CreateStoryTrigger.defaultProps = {
  onCreateStory: () => {},
};

export default CreateStoryTrigger;
