import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import api from 'app/api';

import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { getCountries, selectCountries } from 'app/miscSlice';

import customStyles from './react-select-customStyles.js';
import formStyles from './forms.module.css';

const AsyncCitySelect = ({
  countryCode,
  isDating,
  city,
  onChange,
  onFocus,
}) => {
  const { t } = useTranslation();
  if (!countryCode) {
    return null;
  }

  const asyncFechCities = async (inputValue) => {
    const cities = await api.get(
      `v1/signup/city?query=${inputValue}&country=${countryCode}`
    );
    return cities.data.data.map((c) => {
      return { label: c.name, value: c.slug };
    });
  };

  const defaultCityOption = city ? [{ label: city, value: city }] : [];

  if (isDating) {
    return (
      <AsyncSelect
        isClearable
        placeholder={t('components:city_select.enterCity')}
        styles={customStyles}
        classNamePrefix="ohlala"
        defaultValue={defaultCityOption[0]}
        defaultOptions={defaultCityOption}
        loadOptions={asyncFechCities}
        onFocus={onFocus}
        onChange={(e) => {
          if (e && e.label !== city) {
            onChange(e);
          } else {
            onChange();
          }
        }}
        noOptionsMessage={(string) => {
          return t('components:city_select.typeCity');
        }}
      />
    );
  }

  return (
    <AsyncCreatableSelect
      isClearable
      placeholder={t('components:city_select.enterCity')}
      styles={customStyles}
      classNamePrefix="ohlala"
      defaultValue={defaultCityOption[0]}
      defaultOptions={defaultCityOption}
      loadOptions={asyncFechCities}
      onFocus={onFocus}
      onChange={(e) => {
        if (e && e.label !== city) {
          onChange(e);
        } else {
          onChange();
        }
      }}
      formatCreateLabel={(string) => {
        return (
          t('components:city_select.selectCity') + ' ' + string // Cant interpolate {string var}
        );
      }}
      noOptionsMessage={(string) => {
        return t('components:city_select.typeCity');
      }}
    />
  );
};

const CountryCitySelect = ({
  withLabel,
  required,
  customPlaceholder,
  isDatingUser = true,
}) => {
  const { setFieldValue, values } = useFormikContext();
  const [isDating, setDatingCountry] = useState(isDatingUser);
  const [countryList, setCountryList] = useState({});
  const countries = useSelector(selectCountries);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(countries)) {
      dispatch(getCountries());
    } else {
      const formatCountries = countries.map((country) => {
        return {
          label: country.name,
          value: country.country_id,
          code: country.code,
          dating: country.dating,
        };
      });
      setCountryList(formatCountries);
    }
  }, [countries, dispatch]);

  if (isEmpty(countryList)) {
    return null;
  }

  const defaultCountry = find(countryList, ['label', values.country_name]);

  const placeholder =
    customPlaceholder || t('components:city_select.selectCountry');

  return (
    <div>
      {withLabel && (
        <label
          className={`${formStyles.formLabel} ${
            required ? formStyles.required : ''
          }`}
          htmlFor="city"
        >
          {t('components:city_select.countryCity')}
        </label>
      )}
      <Select
        cacheOptions
        placeholder={placeholder}
        styles={customStyles}
        classNamePrefix="ohlala"
        defaultValue={defaultCountry || null}
        options={countryList}
        onChange={(e) => {
          setFieldValue('country', e ? e.code : '');
          setFieldValue('country_name', e ? e.label : '');
          setFieldValue('city', '');
          setFieldValue('city_slug', '');
          setDatingCountry(e ? e.dating : false);
          // setDefaultCity('');
        }}
      />

      <div className="citySelect">
        <AsyncCitySelect
          key={values.country}
          countryCode={values.country}
          city={values.city}
          isDating={isDating}
          onChange={(e) => {
            // console.log(e);
            setFieldValue('city', e ? e.label : '');
            setFieldValue('city_slug', e ? e.value : '');
            // setFieldValue(
            //   'city_id',
            //   e ? (Number.isInteger(e.value) ? e.value : 0) : 0
            // );
          }}
          onFocus={() => {
            setFieldValue('city', '');
            setFieldValue('city_slug', '');
            // setFieldValue('city_id', 0);
          }}
        />
      </div>
    </div>
  );
};

export default CountryCitySelect;
