import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import api from 'app/api';

export const getTokens = createAsyncThunk('auth/csrf', async () => {
  const response = await api.get('sanctum/csrf-cookie');
  return response.data;
});

export const getUser = createAsyncThunk(
  'auth/getUser',
  async (params, { rejectWithValue }) => {
    const response = await api.get('v2/user', { skipInterceptor: true });
    if (!response.data.status) {
      return rejectWithValue(response.data.error);
    }
    return response.data;
  }
);

export const updateUser = createAsyncThunk(
  'auth/updateUser',
  async (params, { rejectWithValue }) => {
    const response = await api.get('v2/user');
    if (!response.data.status) {
      return rejectWithValue(response.data.error);
    }
    return response.data;
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async (credentials, { rejectWithValue }) => {
    const response = await api.post('v2/user/login', {
      email: credentials.email,
      password: credentials.password,
      phone: credentials.phone,
      countryID: credentials.countryID,
    });
    if (!response.data.status) {
      return rejectWithValue(response.data.error);
    }
    return response.data;
  }
);

export const auth = createAsyncThunk(
  'auth/auth',
  async (credentials, { rejectWithValue }) => {
    const response = await api.post('v2/user/auth', {
      email: credentials.email,
      auth: credentials.auth,
    });
    if (!response.data.status) {
      return rejectWithValue(response.data.error);
    }
    return response.data;
  }
);

export const signUp = createAsyncThunk(
  'auth/signup',
  async (payload, { rejectWithValue }) => {
    const response = await api.post('v1/signup', {
      ...payload,
    });
    if (!response.data.status) {
      return rejectWithValue(response.data.error);
    }
    return response.data;
  }
);

export const logout = createAsyncThunk('auth/logout', async (message) => {
  const response = await api.get('v2/user/logout');
  if (message) {
    response.data.toastMessage = message;
  }
  return response.data;
});

export const getUserCurrency = createAsyncThunk(
  'auth/getUserCurrency',
  async (currencyCode, { rejectWithValue }) => {
    const response = await api.get(`v2/currencies/info/${currencyCode}`);
    if (!response.data.status) {
      return rejectWithValue(response.data.error);
    }
    return response.data;
  }
);

export const checkID = createAsyncThunk(
  'auth/checkID',
  async (params, { rejectWithValue }) => {
    const response = await api.get('v2/kycs/status');
    if (!response.data.status) {
      return rejectWithValue(response.data.error);
    }
    return response.data;
  }
);

export const setDatingUser = createAsyncThunk(
  'misc/setDatingUser',
  async (params) => {
    const res = await api.put('v2/user/profile/update_dating_enabled', {
      dating_enabled: params.dating,
    });
    return res.data;
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    login: {
      loading: false,
      hasError: false,
    },
    auth: {
      loading: false,
      hasError: false,
    },
    signUp: {
      loading: false,
      hasError: false,
    },
    logout: {
      loading: false,
      toastMessage: '',
    },
    user: {
      loading: false,
      hasError: false,
      fetched: false,
      userInfo: [],
    },
    currency: {},
    kyc: { kyc_status: 0 },
  },
  reducers: {
    setUserAfterLogin: (state, action) => {
      state.user.userInfo = action.payload;
      state.user.hasError = false;
      // state.login.loading = false;
    },
    resetLogoutMessage: (state) => {
      state.logout.toastMessage = '';
    },
  },
  extraReducers: {
    [login.pending]: (state, action) => {
      state.login.loading = true;
      state.login.hasError = false;
    },
    [login.fulfilled]: (state, action) => {
      // state.login.loading = false;
      // state.user.userInfo = action.payload.data.user;
    },
    [login.rejected]: (state) => {
      state.login.loading = false;
      state.login.hasError = true;
    },
    [auth.pending]: (state, action) => {
      state.auth.loading = true;
      state.auth.hasError = false;
    },
    [auth.fulfilled]: (state, action) => {
      // state.login.loading = false;
      // state.user.userInfo = action.payload.data.user;
    },
    [auth.rejected]: (state) => {
      state.auth.loading = false;
      state.auth.hasError = true;
    },
    [signUp.pending]: (state) => {
      state.signUp.loading = true;
      state.signUp.hasError = false;
    },
    [signUp.fulfilled]: (state, action) => {
      state.signUp.loading = false;
    },
    [signUp.rejected]: (state, action) => {
      state.signUp.loading = false;
      state.signUp.hasError = true;
    },
    [getUser.pending]: (state, action) => {
      state.user.loading = true;
      state.user.fetched = false;
      state.user.hasError = false;
    },
    [getUser.fulfilled]: (state, action) => {
      state.user.loading = false;
      state.user.fetched = true;
      state.user.userInfo = action.payload.data.user;
    },
    [getUser.rejected]: (state, action) => {
      state.user.loading = false;
      state.user.fetched = true;
      state.user.hasError = true;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.user.userInfo = action.payload.data.user;
    },
    [getUserCurrency.fulfilled]: (state, action) => {
      state.currency = action.payload.data.currency;
    },
    [logout.pending]: (state, action) => {
      state.logout.loading = true;
    },
    [logout.fulfilled]: (state, action) => {
      state.logout.loading = false;
      state.logout.toastMessage = action.payload.toastMessage || '';
    },
    [checkID.fulfilled]: (state, action) => {
      state.kyc = action.payload.data;
    },
  },
});

export const { setUserAfterLogin, resetLogoutMessage } = authSlice.actions;

export const selectAuthenticated = (state) =>
  !isEmpty(state.auth.user.userInfo);
export const selectProfileIncomplete = (state) =>
  !isEmpty(state.auth.user.userInfo) &&
  (!state.auth.user.userInfo.name ||
    !state.auth.user.userInfo.town ||
    !state.auth.user.userInfo.gender ||
    !state.auth.user.userInfo.looking_for);

export const selectUserFetching = (state) => state.auth.user.loading;
export const selectUserFetched = (state) => state.auth.user.fetched;
export const selectUserInfo = (state) => state.auth.user.userInfo;
export const selectUserGender = (state) => state.auth.user.userInfo.gender;
export const selectUserDashboard = (state) =>
  state.auth.user.userInfo.creator_dashboard;
export const selectUserCurrency = (state) => state.auth.currency;
export const selectSplashScreen = (state) =>
  state.auth.logout.loading || state.auth.user.loading;
export const selectLoginState = (state) => state.auth.login;

export const selectSignupState = (state) => state.auth.signUp;
export const selectUserLanguage = (state) =>
  state.auth.user.userInfo.language_gui;

export const selectKYCstatus = (state) => Number(state.auth.kyc.kyc_status);

export const selectUserSubscriptionEnabled = (state) =>
  state.auth.user.userInfo.is_subscription_enabled;

export const selectLogoutMessage = (state) => state.auth.logout.toastMessage;

export default authSlice.reducer;
