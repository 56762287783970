import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Formik, Form } from 'formik';
import cx from 'classnames';

import {
  selectUserInfo,
  setDatingUser,
  updateUser,
} from 'features/auth/authSlice';
import { FormRow, Switch } from 'components/forms';
import * as Icons from 'assets/icons/mainMenu';
import Button from 'components/buttons/Button';
import { useModal } from 'components/modal/useModal';
import styles from './authModal.module.css';

const DatingModal = () => {
  const { RenderModal, showModal, hideModal } = useModal();
  const dispatch = useDispatch();
  const user = useSelector(selectUserInfo);
  const { t } = useTranslation();

  useEffect(() => {
    if (user.show_include_dating_popup) {
      showModal();
    } else {
      hideModal();
    }
  }, [dispatch, user.show_include_dating_popup, showModal, hideModal]);

  const handleSubmit = async (values) => {
    dispatch(setDatingUser({ dating: !values.dating }))
      .then(unwrapResult)
      .then((result) => {
        dispatch(updateUser());
      });
  };

  return (
    <RenderModal bareInner blockCloseOnBackdropClick>
      <Formik
        initialValues={{
          dating: false,
        }}
        onSubmit={(data) => {
          handleSubmit(data);
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <div className={styles.wrapper}>
              <div className={cx(styles.container, styles.modal)}>
                <div className={styles.descWrapper}>
                  <div className={styles.ico}>
                    <Icons.Dating />
                  </div>
                  <div className={styles.copy}>
                    <h4>{t('features:auth.datingModal.modalTitle')}</h4>
                    <p>{t('features:auth.datingModal.modalSubtitle')}</p>
                  </div>
                </div>
                <FormRow className={styles.formRowDating}>
                  <strong
                    className={cx(styles.datingLeft, {
                      [styles.unsel]: values.dating,
                    })}
                  >
                    {t('main:main.yes')}
                  </strong>
                  <Switch name="dating" datingSwitch />
                  <strong
                    className={cx(styles.datingRight, {
                      [styles.unsel]: !values.dating,
                    })}
                  >
                    {t('main:main.no')}
                  </strong>
                </FormRow>

                <Button
                  fullWidth
                  size="xl"
                  name="btn-gradient"
                  type="submit"
                  loading={isSubmitting}
                >
                  {t('main:main.save')}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </RenderModal>
  );
};

export default DatingModal;
