import React, { useState } from 'react';
import styles from './enableSubscriptions.module.css';
import { CheckboxStandAlone } from 'components/forms';
import { Button } from 'components/buttons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  enableSubscriptions,
  getSubscriptionSettings,
  selectEnableSubscriptions,
} from '../subscriptionsSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { Trans } from 'react-i18next';

const EnableSubscriptions = () => {
  const [confirm, setConfirm] = useState(false);
  const { loading, error } = useSelector(selectEnableSubscriptions);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSave = async () => {
    const res = dispatch(enableSubscriptions());
    await unwrapResult(res);
    dispatch(getSubscriptionSettings());
  };

  return (
    <div className={styles.container}>
      <h4 className={styles.headline}>
        {t('features:subscriptions.enable.enableSubscriptions')}
      </h4>
      <p className={styles.copy}>
        {t('features:subscriptions.enable.enableSubscriptionsCopy')}
      </p>

      <ul className={styles.list}>
        <Trans
          i18nKey={'features:subscriptions.enable.enableSubscriptionsPros'}
        >
          <li>one</li>
          <li>two</li>
          <li>three</li>
          <li>Four</li>
        </Trans>
      </ul>

      <h4 className={styles.subHeadline}>
        {t('features:subscriptions.enable.payouts')}
      </h4>
      <p className={styles.copy}>
        {t('features:subscriptions.enable.enableSubscriptionsPayouts')}
      </p>

      <div className={`${styles.upgrade} ${confirm ? styles.checked : ''}`}>
        <CheckboxStandAlone value={confirm} onChange={(val) => setConfirm(val)}>
          <p>
            <strong>
              {t('features:subscriptions.enable.enableSubscriptionsActivate')}
            </strong>{' '}
            <br />
            <span style={{ marginTop: '5px', display: 'inline-block' }}>
              {t(
                'features:subscriptions.enable.enableSubscriptionsActivateCopy'
              )}
            </span>
          </p>
        </CheckboxStandAlone>
      </div>
      {confirm && (
        <Button
          size="xl"
          name="btn-gradient"
          context="form"
          onClick={onSave}
          loading={loading}
          disabled={loading || !confirm}
        >
          {t('main:main.save')}
        </Button>
      )}
      {error && (
        <div>
          <p style={{ color: 'var(--accentColor)' }}>
            {t('main:errors.somethingWentWrong')}
          </p>
        </div>
      )}
    </div>
  );
};

export default EnableSubscriptions;
