import React, { useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import RegisterScreen from 'screens/RegisterScreen';

const SettingsBlockScreen = () => {
  const history = useHistory();
  const match = useRouteMatch('/');

  useEffect(() => {
    if (!match.isExact) {
      history.replace('/');
    }
  }, [match, history]);

  return <RegisterScreen afterRegister />;
};

export default SettingsBlockScreen;
