import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams, useHistory, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';

import { selectUserGender } from 'features/auth/authSlice';
import { trackEvent } from 'helpers/tracking';
import styles from './profile.module.css';

const ProfileContentWithUrls = ({ user, feed, videos, photos, info }) => {
  const { id, tab } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const userGender = useSelector(selectUserGender);

  const showFeed = feed && user.stats.total_posts > 0;
  const showVideos = videos && user.stats.total_videos > 0;
  const showPhotos = photos && user.stats.total_images > 0;

  const showTabs = showFeed || showVideos || showPhotos;
  const tabsClass = cx(styles.tabs, {
    [styles.noMedia]: !showVideos && !showPhotos,
  });

  if (
    (tab === 'videos' && !showVideos) ||
    (tab === 'photos' && !showPhotos) ||
    (tab === 'info' && !showTabs) ||
    (tab && ['videos', 'photos', 'info'].indexOf(tab) < 0)
  ) {
    history.replace(`/profile/${id}`);
  }

  return (
    <>
      {showTabs && (
        <div className={styles.tabsWrapper}>
          <ul className={tabsClass}>
            {showFeed && (
              <NavLink
                replace
                to={{
                  pathname: `/profile/${id}`,
                  state: location.state,
                }}
                onClick={() => {
                  trackEvent({
                    category: 'profile pages',
                    action: 'feed tab click',
                    label: userGender,
                  });
                }}
                className={styles.tab}
                activeClassName={`${isEmpty(tab) ? styles.tabActive : null}`}
              >
                <span>{t('features:profileTabs.Posts')}
                    <strong className={styles.notoficationNum}>
                      {user.stats.total_posts}
                    </strong>
                </span>
              </NavLink>
            )}
            {showVideos && (
              <NavLink
                replace
                to={{
                  pathname: `/profile/${id}/videos`,
                  state: location.state,
                }}
                onClick={() => {
                  trackEvent({
                    category: 'profile pages',
                    action: 'videos tab click',
                    label: userGender,
                  });
                }}
                className={styles.tab}
                activeClassName={styles.tabActive}
              >
                <span>{t('features:profileTabs.Videos')}
                  <strong className={styles.notoficationNum}>
                      {user.stats.total_videos}
                    </strong>
                </span>
              </NavLink>
            )}
            {showPhotos && (
              <NavLink
                replace
                to={{
                  pathname: `/profile/${id}/photos`,
                  state: location.state,
                }}
                onClick={() => {
                  trackEvent({
                    category: 'profile pages',
                    action: 'images tab click',
                    label: userGender,
                  });
                }}
                className={styles.tab}
                activeClassName={styles.tabActive}
              >
                <span>{t('features:profileTabs.Images')}
                  <strong className={styles.notoficationNum}>
                      {user.stats.total_images}
                    </strong>
                </span>
              </NavLink>
            )}
            <NavLink
              replace
              to={{
                pathname: `/profile/${id}/info`,
                state: location.state,
              }}
              onClick={() => {
                trackEvent({
                  category: 'profile pages',
                  action: 'info tab click',
                  label: userGender,
                });
              }}
              className={styles.tab}
              activeClassName={styles.tabActive}
            >
              <span>{t('features:profileTabs.Info')}</span>
            </NavLink>
          </ul>
        </div>
      )}
      {isEmpty(tab) && showFeed && feed}
      {tab === 'videos' && showVideos && videos}
      {tab === 'photos' && showPhotos && photos}
      {(tab === 'info' || (isEmpty(tab) && !showFeed) || !showTabs) && (
        <div className={styles.contentWrapper}>{info}</div>
      )}
    </>
  );
};

ProfileContentWithUrls.propTypes = {
  user: PropTypes.object.isRequired,
  info: PropTypes.element.isRequired,
  feed: PropTypes.element,
  videos: PropTypes.element,
  photos: PropTypes.element,
};

export default ProfileContentWithUrls;
