import { useFormikContext } from 'formik';
import React from 'react';
import PreferenceSelect from 'components/forms/preferenceSelect';

export const preferenceAdapterIn = (num) => {
  switch (num) {
    case 1:
      return ['male', 'female'];
    case 2:
      return ['male'];
    case 3:
      return ['female'];

    default:
      return ['male', 'female'];
  }
};
export const preferenceAdapterOut = (preference) => {
  const hasMale = preference.includes('male');
  const hasFemale = preference.includes('female');

  if (hasMale && hasFemale) return 1;
  if (hasMale) return 2;
  if (hasFemale) return 3;

  return 1;
};

const DatePreference = () => {
  const { setFieldValue, values } = useFormikContext();

  const onChange = (val) => {
    setFieldValue('looking_for', val);
  };

  return <PreferenceSelect active={values.looking_for} onSelect={onChange} />;
};

export default DatePreference;
