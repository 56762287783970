import usePlacesAutocomplete, {
    getDetails,
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import {Input} from "../forms";
import {useTranslation} from "react-i18next";
import style from "./placeAutoComplete.module.css";
//import {GoogleMap} from "@react-google-maps/api";
//import {Marker} from "google-maps-react";
import {useFormikContext} from 'formik';
import {useState} from "react";

const PlacesAutocomplete = () => {
    const {t} = useTranslation();
    const [photos, setPhotos] = useState([]);

    //const center = useMemo(() => ({ lat: 43.45, lng: -80.49 }), []);
    //const [selected, setSelected] = useState(null);
    const form = useFormikContext();

    const {
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        callbackName: "YOUR_CALLBACK_NAME",
        requestOptions: {
            /* Define search scope here */
            types: ["establishment"],
            options: {
                fields: ['formatted_address', 'geometry', 'photos'],
            }
        },
        debounce: 300,
    });
    const ref = useOnclickOutside(() => {
        // When the user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions();
    });

    /*const map = MapContainer = (props) => {
        return (
            <Map
                google={props.google}
                zoom={14}
                initialCenter={{ lat: 0, lng: 0 }} // Set initial center
            >
                {/!* Your map content goes here *!/}
            </Map>
        );
    }*/

    const handleInput = (e) => {
        // Update the keyword of the input element
        setValue(e.target.value);
    };

    /*const handleSelect = (...args) => () => {
        for (let i = 0; i < args.length; i++) {
            console.log(`Argument ${i + 1}:`, args[i]);
        }
    };*/

    const getAddressComponent = (results, types) => {
        const resultsc = results[0];
        const component = resultsc.address_components.find(f =>
            JSON.stringify(f.types) === JSON.stringify(types)
        );
        return component ? component.short_name : '';
    };

    const getAddressComponentLongName = (results, types) => {
        const resultsc = results[0];
        const component = resultsc.address_components.find(f =>
            JSON.stringify(f.types) === JSON.stringify(types)
        );
        return component ? component.long_name : '';
    };

    const getTimezoneInGMT = (utcOffset) => {
        const gmtOffset = utcOffset / 60;
        return gmtOffset;
    };

    const handleSelect =
        ({ description, place_id }) =>
            () => {
                //fetchPhotos(place_id);

                form.setFieldValue('place_id', place_id);
                form.setFieldValue('venue_address', description);

                const parameter = {
                    // Use the "place_id" of suggestion from the dropdown (object), here just taking the first suggestion for brevity
                    placeId: place_id,
                    // Specify the return data that you want (optional)
                    fields: ["name", "photos", "utc_offset"],
                };

                getDetails(parameter)
                    .then((details) => {
                        //console.log("Details: ", details);
                        //console.log(getTimezoneInGMT(details.utc_offset));
                        if (details.photos && details.photos.length > 0) {
                            const fetchedPhotos = details.photos.map((photo) => photo.getUrl());
                            setPhotos(fetchedPhotos);
                            form.setFieldValue('photos', fetchedPhotos);

                        } else {
                            setPhotos([]);
                            form.setFieldValue('photos', []);
                        }
                        form.setFieldValue('timezone', getTimezoneInGMT(details.utc_offset));
                        form.setFieldValue('venue_name', details.name);
                        setValue(details.name, false);
                        clearSuggestions();
                    })
                    .catch((error) => {
                        console.log("Error: ", error);
                    });



                //console.log(getDetails(place_id));
                //console.log("main_text==>",structured_formatting.main_text);
                // When the user selects a place, we can replace the keyword without request data from API
                // by setting the second parameter to "false"

                //console.log('description===========>', description);

                //form.setFieldValue('venue_name', description);

                // Get latitude and longitude via utility functions
                getGeocode({ address: description }).then((results) => {
                    //console.log('results===========>', results);
                    //const {address_components} = results[0];
                    //console.log('address_components===========>', address_components[0]);

                    /*const resultsc = results[0];
                    const country = resultsc.address_components.filter(f => JSON.stringify(f.types) === JSON.stringify(['country', 'political']))[0].short_name;
                    const city = resultsc.address_components.filter(f => JSON.stringify(f.types) === JSON.stringify(['locality', 'political']))[0].short_name;
                    const state = resultsc.address_components.filter(f => JSON.stringify(f.types) === JSON.stringify(['administrative_area_level_1', 'political']))[0].short_name;
                    const displayCountry = resultsc.address_components.filter(f => JSON.stringify(f.types) === JSON.stringify(['country', 'political']))[0].long_name;
                    const displayCity = resultsc.address_components.filter(f => JSON.stringify(f.types) === JSON.stringify(['locality', 'political']))[0].long_name;
                    const displayState = resultsc.address_components.filter(f => JSON.stringify(f.types) === JSON.stringify(['administrative_area_level_1', 'political']))[0].long_name;*/

                    //const displayCountry = getAddressComponentLongName(results, ['country', 'political']);
                    const shortCity = getAddressComponentLongName(results, ['locality', 'political']);
                    const shortState = getAddressComponentLongName(results, ['administrative_area_level_1', 'political']);

                    form.setFieldValue('extra', results);
                    const country = getAddressComponent(results, ['country', 'political']);
                    const city = getAddressComponent(results, ['locality', 'political']);
                    const state = getAddressComponent(results, ['administrative_area_level_1', 'political']);

                    form.setFieldValue('country', country);
                    form.setFieldValue('city', city);
                    form.setFieldValue('city_short', shortCity);
                    form.setFieldValue('state', state);
                    form.setFieldValue('state_short', shortState);

                    //console.log('==>',city,"===>",state, "====>",displayCity,'==>', displayState,country, displayCountry);
                    const { lat, lng } = getLatLng(results[0]);
                    //console.log(getZipCode(results[0]));
                    //console.log("📍 Coordinates: ", { lat, lng });
                    form.setFieldValue('latitude', lat);
                    form.setFieldValue('longitude', lng);


                });
            };

    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            //console.log(main_text)

            return (
                <li key={place_id} onClick={handleSelect(suggestion)} className={style.searchList}>
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                </li>
            );
        });

    return (
        <div ref={ref}>
            <Input
                type="text"
                label={t('features:eventRequest.venue_name')}
                name="venue_name"
                required
                onChange={handleInput}
                /*disabled={!ready}*/
                value={value}
                placeholder="Search a venue address"
                options={{
                    fields: ['formatted_address', 'geometry', 'photos'],
                }}
            />

            {/*<GoogleMap
                zoom={10}
                center={center}
                mapContainerClassName="map-container"
            >
                {selected && <Marker position={selected} />}
            </GoogleMap>*/}

            {/*<input
                value={value}
                onChange={handleInput}

                placeholder="Where are you going?"
            />*/}
            {/* We can use the "status" to decide whether we should display the dropdown or not */}
            {status === "OK" && <ul>{renderSuggestions()}</ul>}

            {photos.map((photoUrl, index) => (
                <img
                    key={index}
                    src={photoUrl}
                    alt={`Images ${index}`}
                    style={{ width: '150px', height: '150px', margin: '10px' }}
                />
            ))}
        </div>
    );
};

export default PlacesAutocomplete;