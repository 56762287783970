import isString from 'lodash/isString';
import TagManager from 'react-gtm-module';
import smartlookClient from 'smartlook-client';

export const trackEvent = ({ category, action, label, value }) => {
  if (process.env.REACT_APP_TRACKING_STATUS === 'off') {
    return;
  }

  if (!isString(category) || !isString(action)) {
    throw new Error('Missing required string parameters! (category, action)');
  }

  // console.log({ category, action, label, value });

  // SmartLook
  if (smartlookClient.initialized()) {
    smartlookClient.track(action, {
      category: category,
      ...(label && { label: label }),
      ...(value && { value: value }),
    });
  }

  // GTM
  TagManager.dataLayer({
    dataLayer: {
      event: 'appEvents',
      category: category,
      action: action,
      label: label,
      ...(value && { value: value }),
    },
  });

  return;
};
