import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import ProfileMedia from 'features/user/ProfileMedia';
import {
  getPurchased as getItems,
  getPurchased,
  selectPurchased,
} from 'features/user/profileSlice';
import {
  setShowSuggestions,
  resetShowSuggestions,
} from 'features/user/suggestions/suggestionsSlice';

const PurchasedView = () => {
  const { next, items, fetched } = useSelector(selectPurchased);
  const hasPurchased = !isEmpty(items);
  const hasMore = next > 1;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!hasPurchased) {
      dispatch(getPurchased());
    }
  }, [dispatch, hasPurchased]);

  useEffect(() => {
    if (fetched && isEmpty(next)) {
      dispatch(setShowSuggestions());
    }
    return () => dispatch(resetShowSuggestions());
  }, [dispatch, fetched, next]);

  const fetchNext = () => {
    dispatch(getItems(next));
  };

  return (
    <ProfileMedia
      files={items}
      getMore={fetchNext}
      hasMore={hasMore}
      fullWidth
    />
  );
};

export default PurchasedView;
