import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { filter } from 'lodash';
import api from './api';

export const getReportReasons = createAsyncThunk(
  'misc/getReportReasons',
  async () => {
    const res = await api.get('v2/user/actions/report/reasons');
    return res.data;
  }
);

export const getCloseAccountReasons = createAsyncThunk(
  'misc/getCloseAccountReasons',
  async () => {
    const res = await api.get('v2/user/actions/account/close/reasons');
    return res.data;
  }
);

export const getCountries = createAsyncThunk('misc/getCountries', async () => {
  const res = await api.get('v1/signup/countries');
  return res.data;
});

export const getTips = createAsyncThunk('misc/getTips', async () => {
  const res = await api.get('v2/tips/send/list');
  return res.data;
});

export const getEventTypes = createAsyncThunk('misc/getEventTypes', async () => {
  const res = await api.get('v2/episode_type/list');
  return res.data;
});

export const getCancellationPolicy = createAsyncThunk('misc/getCancellationPolicy', async () => {
  const res = await api.get('v2/cancellation_policy/list');
  return res.data;
});

export const getFeedCategories = createAsyncThunk(
  'misc/getFeedCategories',
  async (params) => {
    const version = params.isPublic ? 'guests' : 'v2';
    const res = await api.get(`${version}/categories/has_contents`);
    return res.data;
  }
);

export const getUserCategories = createAsyncThunk(
  'misc/getUserCategories',
  async (params) => {
    const version = params.isPublic ? 'guests' : 'v2';
    const res = await api.get(`${version}/categories/has_users`);
    return res.data;
  }
);

const initialState = {
  reportReasons: [],
  closeAccountReasons: [],
  countries: [],
  userCategories: [],
  feedCategories: [],
  savedLocation: null,
  showTutorial: false,
  showPublicRegister: false,
  showPublicLookingFor: false,
  showClubBooking: false,
  filters: {
    city: '',
    city_id: 0,
    country: '',
    country_name: '',
    gender: null,
    filter_episode_applications: 0,
    userCategories: [],
    feedCategories: [],
  },
};

export const miscSlice = createSlice({
  name: 'misc',
  initialState: initialState,
  reducers: {
    saveLocation: (state, action) => {
      state.savedLocation = action.payload;
    },
    setShowTutorial: (state, action) => {
      state.showTutorial = action.payload;
    },
    setShowPublicRegister: (state, action) => {
      state.showPublicRegister = action.payload;
    },
    setShowClubBooking: (state, action) => {
      state.showClubBooking = action.payload;
    },
    setShowPublicLookingFor: (state, action) => {
      state.showPublicLookingFor = action.payload;
    },
    applyFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    clearCityFilter: (state) => {
      state.filters = {
        ...state.filters,
        city: '',
        city_id: 0,
        country: '',
        country_name: '',
      };
    },
    clearGenderFilter: (state) => {
      state.filters = { ...state.filters, gender: 1 };
    },
    clearEpisodeApplicationFilter: (state) => {
      state.filters = { ...state.filters, filter_episode_applications: 0 };
    },
    clearFeedCategoriesFilter: (state) => {
      state.filters = { ...state.filters, feedCategories: [] };
    },
    removeFeedCategory: (state, action) => {
      state.filters.feedCategories = filter(
        state.filters.feedCategories,
        function (obj) {
          return obj.id !== action.payload;
        }
      );
    },
    removeUserCategory: (state, action) => {
      state.filters.userCategories = filter(
        state.filters.userCategories,
        function (obj) {
          return obj.id !== action.payload;
        }
      );
    },
  },
  extraReducers: {
    [getReportReasons.fulfilled]: (state, action) => {
      if (action.payload.status) {
        state.reportReasons = action.payload.data;
      }
    },
    [getCloseAccountReasons.fulfilled]: (state, action) => {
      if (action.payload.status) {
        state.closeAccountReasons = action.payload.data;
      }
    },
    [getCountries.fulfilled]: (state, action) => {
      if (action.payload.status) {
        state.countries = action.payload.data;
      }
    },
    [getTips.fulfilled]: (state, action) => {
      if (action.payload.status) {
        state.tips = action.payload.data;
      }
    },
    [getEventTypes.fulfilled]: (state, action) => {
      if (action.payload.status) {
        state.eventTypes = action.payload.data;
      }
    },
    [getCancellationPolicy.fulfilled]: (state, action) => {
      if (action.payload.status) {
        state.cancellationPolicy = action.payload.data;
      }
    },
    [getFeedCategories.fulfilled]: (state, action) => {
      if (action.payload.status) {
        state.feedCategories = action.payload.data.list;
      }
    },
    [getUserCategories.fulfilled]: (state, action) => {
      if (action.payload.status) {
        state.userCategories = action.payload.data.list;
      }
    },
  },
});

export const {
  saveLocation,
  setShowTutorial,
  setShowPublicRegister,
  setShowClubBooking,
  setShowPublicLookingFor,
  applyFilters,
  clearCityFilter,
  clearGenderFilter,
  clearEpisodeApplicationFilter,
  clearFeedCategoriesFilter,
  removeFeedCategory,
  removeUserCategory,
} = miscSlice.actions;

export const selectReportReasons = (state) => state.misc.reportReasons;
export const selectCloseAccountReasons = (state) =>
  state.misc.closeAccountReasons;
export const selectCountries = (state) => state.misc.countries;
export const selectTips = (state) => state.misc.tips;
export const selectEventTypes = (state) => state.misc.eventTypes;
export const selectCancellationPolicy = (state) => state.misc.cancellationPolicy;
export const selectFeedCategories = (state) => state.misc.feedCategories;
export const selectUserCategories = (state) => state.misc.userCategories;
export const selectSavedLocation = (state) => state.misc.savedLocation;
export const selectTutorial = (state) => state.misc.showTutorial;
export const selectPublicRegister = (state) => state.misc.showPublicRegister;
export const selectClubBooking = (state) => state.misc.showClubBooking;
export const selectPublicLookingFor = (state) =>
  state.misc.showPublicLookingFor;

export const selectFilters = (state) => state.misc.filters;

export default miscSlice.reducer;
