import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import SwiperCore, { EffectCube } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { isMacOs, isIOS } from 'react-device-detect';
import cx from 'classnames';

import { useWindowWidthLessThan, useWindowWidth } from 'hooks';
import { selectUserInfo } from 'features/auth/authSlice';
import { seenStory } from './storiesSlice';
import UserStories from './UserStories';
import styles from './stories.module.css';

SwiperCore.use([EffectCube]);

const FullScreenStories = React.memo(
  ({ userStories, showStories, closeStories, initialIndex, children }) => {
    const [filesIndex, setFilesIndex] = useState(initialIndex);
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [compKey, setCompKey] = useState(Math.random());
    const history = useHistory();
    const dispatch = useDispatch();
    const me = useSelector(selectUserInfo);
    const isMobile = useWindowWidthLessThan(769);
    const deviceWidth = useWindowWidth();
    const actionsRef = useRef(null);

    // https://picsum.photos/1080/1920

    const closeSwiper = async () => {
      setFilesIndex(initialIndex);
      await closeStories();
      swiperInstance.destroy();
      setSwiperInstance(null);
    };

    useEffect(() => {
      setFilesIndex(initialIndex);
    }, [initialIndex]);

    useEffect(() => {
      setFilesIndex(initialIndex);
      setCompKey(Math.random());
    }, [deviceWidth, initialIndex]);

    const handleSlideChange = (instance) => {
      setFilesIndex(instance.activeIndex);
      document.querySelectorAll('video').forEach((vid) => {
        if (typeof vid.pause === 'function') {
          vid.pause();
        }
      });
    };

    const handleNextSlide = () => {
      if (userStories.length > filesIndex + 1) {
        swiperInstance.slideNext();
      } else {
        closeSwiper();
      }
    };

    const handlePrevSlide = (currentIndex) => {
      if (currentIndex > 0) {
        swiperInstance.slidePrev();
      }
      return null;
    };

    const handleStoryStart = (storyIndex, story) => {
      if (!story.seen) {
        dispatch(seenStory(story.id));
      }
    };

    const handleOverlayUserClick = () => {
      const visibleUser = userStories[filesIndex];
      let profileLink = `/profile/${visibleUser.id}`;
      if (visibleUser.id === me.id) {
        profileLink = '/profile';
      }
      return history.push(profileLink);
    };

    const domEl = document.getElementById('stories-root');
    if (!domEl) return null;

    return ReactDOM.createPortal(
      <div
        className={cx(styles.wrapper, { [styles.open]: showStories })}
        key={compKey}
      >
        {(isMacOs || isIOS) && (
          <span className={styles.headerOverlay}>
            <span
              className={styles.left}
              onClick={handleOverlayUserClick}
            ></span>
            <span className={styles.right}>
              <span
                id="iosStoryActions"
                className={styles.actions}
                onClick={() => actionsRef.current.click()}
              ></span>
              <span className={styles.close} onClick={closeSwiper}></span>
            </span>
          </span>
        )}
        {showStories && (
          <Swiper
            initialSlide={initialIndex}
            onSwiper={setSwiperInstance}
            slidesPerView={1}
            onSlideChange={(swiper) => handleSlideChange(swiper)}
            effect={'cube'}
            grabCursor={true}
          >
            {userStories.map((user, i) => (
              <SwiperSlide key={i}>
                <UserStories
                  isActive={i === filesIndex}
                  slideIndex={i}
                  // onStoryEnd={() => console.log('onStoryEnd')}
                  onStoryStart={(storyIndex, story) =>
                    handleStoryStart(storyIndex, story)
                  }
                  actionsRef={actionsRef}
                  onAllStoriesEnd={() => handleNextSlide()}
                  getPrevSlide={() => handlePrevSlide(i)}
                  closeAction={() => closeSwiper()}
                  stories={user.stories}
                  profile={user}
                  isMyProfile={me.id === user.id}
                  isMobile={isMobile}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>,
      domEl
    );
  }
);

FullScreenStories.propTypes = {
  userStories: PropTypes.array.isRequired,
  showStories: PropTypes.bool.isRequired,
  closeStories: PropTypes.func.isRequired,
  initialIndex: PropTypes.number.isRequired,
};

FullScreenStories.defaultProps = {
  showStories: false,
  initialIndex: 0,
};

export default FullScreenStories;
