import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { LoaderInline } from 'components/layout/loader';

const LoadMoreTrigger = ({ hasMore, getMore }) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    margin: '200px',
    // triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      const timeout = setTimeout(() => {
        getMore();
      }, 500);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [inView, getMore]);

  if (!hasMore) return null;

  return (
    <div ref={ref} style={{ margin: 'auto' }}>
      <LoaderInline />
    </div>
  );
};

export default LoadMoreTrigger;
