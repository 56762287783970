import { createContext } from 'react';

const AfterRegisterContext = createContext({
  totalSteps: 1,
  currentStep: 1,
  setNextStep: () => {},
  avatar: null,
  setAvatar: () => {},
});

export default AfterRegisterContext;
