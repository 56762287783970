import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { selectUserGender } from 'features/auth/authSlice';
import { BottomDrawer } from 'components/bottomDrawer';
import { trackEvent } from 'helpers/tracking';
import { shareLink } from 'helpers/shareLink';
import {
  SubscribeButton,
  FollowButton,
  ChatButton,
  EditButton,
  ShareButton,
} from 'features/user/profileInfo/Buttons';
import { IconBlock, IconReport, IconShareNew } from 'assets/icons';
import styles from './profileActions.module.css';

const ActionsDrawer = ({
  profileUser,
  blockModal,
  reportModal,
  isVisible,
  onClickAction,
  mainActions,
  isMyProfile,
  isChat,
  isPublic,
}) => {
  const { t } = useTranslation();
  const userGender = useSelector(selectUserGender);

  const { chat, follow, subscribe, edit, share } = mainActions || {};

  const handleShare = () => {
    if (!profileUser.hash_id) {
      return;
    }
    trackEvent({
      category: `profile pages${isPublic ? ' - public' : ''}`,
      action: 'share click',
      label: userGender,
    });
    shareLink({
      title: profileUser.name,
      path: profileUser.hash_id,
      success: t('features:profileInfo.copied'),
    });
  };

  return (
    <BottomDrawer isVisible={isVisible} onClose={() => onClickAction()}>
      <div className={styles.drawerWrapper}>
        <div className={styles.actions}>
          {mainActions && (
            <div className={styles.actionsButtons}>
              {/*{tip.show && (
                  <TipButton
                      profileUser={profileUser}
                      isPublic={isPublic}
                      handleTip={() => {
                        onClickAction();
                        tip.onClick();
                      }}/>
              )}*/}
              {chat.show && (
                <ChatButton
                  handleChat={() => {
                    onClickAction();
                    chat.onClick();
                  }}
                  small={subscribe.show}
                />
              )}
              {subscribe.show && (
                <SubscribeButton
                  onClick={() => {
                    onClickAction();
                    subscribe.onClick();
                  }}
                  price={profileUser.subscription.price.amount_text}
                  isSubscribed={profileUser.subscription.subscribed}
                />
              )}
              {follow.show && (
                <FollowButton
                  onClick={() => {
                    onClickAction();
                    follow.onClick();
                  }}
                  showModal={() => {
                    onClickAction();
                    follow.showModal();
                  }}
                  isFollowing={profileUser.following}
                  loading={follow.loading}
                  userName={profileUser.name}
                />
              )}
              {edit.show && <EditButton />}
              {share.show && (
                <ShareButton
                  profileUser={profileUser}
                  onClick={() => onClickAction()}
                  isPublic={isPublic}
                />
              )}
            </div>
          )}

          {!isMyProfile && !isPublic && (
            <div className={styles.actionsButtonsSmall}>
              {!isChat && (
                <span
                  className={styles.button}
                  onClick={() => {
                    onClickAction();
                    handleShare();
                  }}
                >
                  <IconShareNew className={styles.iconShare} />
                  <span className={styles.buttonText}>
                    {t('features:profileInfo.shareProfile')}
                  </span>
                </span>
              )}
              <span
                className={cx(styles.button, {
                  [styles.chat]: isChat,
                })}
                onClick={() => {
                  onClickAction();
                  blockModal.showModal();
                }}
              >
                <IconBlock />
                <span className={styles.buttonText}>
                  {t('features:blockReport.Block')}
                </span>
              </span>
              <span
                className={cx(styles.button, {
                  [styles.chat]: isChat,
                })}
                onClick={() => {
                  onClickAction();
                  reportModal.showModal();
                }}
              >
                <IconReport />
                <span className={styles.buttonText}>
                  {t('features:blockReport.Report')}
                </span>
              </span>
            </div>
          )}
        </div>
      </div>
    </BottomDrawer>
  );
};

export default ActionsDrawer;
