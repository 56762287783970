import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import cx from 'classnames';

import { Grid, Row, Col } from 'components/layout/grid';
import { toggleSidebar } from 'components/layout/layoutSlice';
import Button from 'components/buttons/Button';
import { Avatar } from 'components/avatar';
import { trackEvent } from 'helpers/tracking';
import * as Icons from 'assets/icons';
import { useWindowWidthLessThan } from 'hooks';
import { selectNumOfUnreadNotifications } from 'features/notifications/notificationsSlice';
import { selectAuthenticated, selectUserInfo } from 'features/auth/authSlice';
import mainStyles from 'components/layout/main/main.module.css';
import styles from './header.module.css';
import {faBell} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Header = ({ title, noBorder, customRight }) => {
  const isAuthenticated = useSelector(selectAuthenticated);
  const { avatar, gender } = useSelector(selectUserInfo);
  const numberOfNotifications = useSelector(selectNumOfUnreadNotifications);
  const matchNotifications = useRouteMatch('/notifications');
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const smallWidth = useWindowWidthLessThan(576);

  const PageTitle = () => {
    const handleBack = () => {
      if (history.length > 2) {
        history.goBack();
      } else {
        history.push('/');
      }
    };

    return (
      <div className={styles.pageTitle}>
        <Button
          onClick={handleBack}
          name="btn-bare"
          className={styles.backButton}
        >
          <Icons.IconArrowLeft width={25} />
        </Button>
        <p className={styles.titleText}>{title}</p>
      </div>
    );
  };

  const LeftElement = () => {
    return smallWidth && !isEmpty(title) ? (
      <PageTitle />
    ) : (
      <Link to="/" className={styles.item}>
        <Icons.LogoSvg className={styles.logo} />
      </Link>
    );
  };

  const RightElement = () => {
    if (customRight) return customRight;
    return isAuthenticated ? (
      <div className={styles.item}>
        <span className={styles.notifications}>
          <Link
            to={'/notifications'}
            className={styles.notificationsLink}
            onClick={(e) => {
              e.stopPropagation();
              trackEvent({
                category: 'menus',
                action: 'notifications tab click',
                label: gender,
              });
            }}
          >
            {!matchNotifications ? (
                <FontAwesomeIcon icon={faBell} width={20} className={`${styles.height}`}  />
            ) : (
                <FontAwesomeIcon icon={faBell} width={20} className={`${styles.height}`} />
            )}

            {numberOfNotifications ? (
              <span className={styles.notoficationNum}>
                {numberOfNotifications}
              </span>
            ) : null}
            {/*<span className={styles.notificationsText}>
                {t('features:navs.settings.notifications')}
              </span>*/}
          </Link>
        </span>

        <span
          className={styles.profile}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            dispatch(toggleSidebar());
          }}
        >
          <Avatar
            circle
            size={40}
            image={avatar}
            gender={gender}
            style={{ marginRight: '6px' }}
          />
          <Icons.IconChevronDown width={15} />
        </span>
      </div>
    ) : (
      <div className={styles.item}>
        <Button
          onClick={() => history.push('/login')}
          style={{ marginRight: '8px' }}
          size={smallWidth ? 's' : 'l'}
          extraPadding={!smallWidth}
        >
          {t('main:main.Login')}
        </Button>
        <Button
          onClick={() => history.push('/register')}
          size={smallWidth ? 's' : 'l'}
          name="btn-gradient"
          extraPadding={!smallWidth}
        >
          {t('main:main.Register')}
        </Button>
      </div>
    );
  };

  return (
    <header
      onClick={(e) => e.stopPropagation()}
      className={cx(styles.header, { [styles.noBorder]: noBorder })}
    >
      <Grid className={mainStyles.gridBox}>
        <Row className={styles.headerInner}>
          <Col className={styles.inner}>
            <LeftElement />
            <RightElement />
          </Col>
        </Row>
      </Grid>
    </header>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  noBorder: PropTypes.bool,
};

Header.defaultProps = {
  title: '',
  noBorder: false,
};

export default Header;
