import {Route, Switch} from 'react-router-dom';

import CreatePostScreen from 'screens/CreatePostScreen';
import CreateDateScreen from 'screens/CreateDateScreen';
import PostDetail from 'screens/PostDetailScreen';
import MyProfile from 'screens/MyProfileScreen';
import Profile from 'screens/ProfileScreen';
import ProfilePublic from 'screens/ProfilePublicScreen';
import ChatScreen from 'screens/ChatScreen';
import LoginScreen from 'screens/LoginScreen';
import RegisterScreen from 'screens/RegisterScreen';
import SettingsScreen from 'screens/SettingsScreen';
import SettingsBlockScreen from 'screens/SettingsBlockScreen';
import DatingScreen from 'screens/DatingScreen';
import FeedScreen from 'screens/FeedScreen';
import FeedPublicScreen from 'screens/FeedPublicScreen';
import HomeScreen from 'screens/HomeScreen';
//import HomePublicScreen from 'screens/HomePublicScreen';
import NotificationsScreen from 'screens/NotificationsScreen';
//import CoinsCampaign from 'features/coinsCampaign/CoinsCampaign';
//import StatisticsScreen from 'screens/StatisticsScreen';
//import MyEpisodeDetail from "../features/dating/episodes/myEpisodes/MyEpisodeDetail";
//import GuestEpisodeDetail from "../features/dating/episodes/myEpisodes/GuestEpisodeDetail";
import ConfirmQRScan from "../features/dating/episodes/ConfirmQRScan";

export const AuthRoutes = () => {
    return (
        <Switch>
            <Route path="/notifications">
                <NotificationsScreen/>
            </Route>
            <Route path={['/settings', '/legal']}>
                <SettingsScreen/>
            </Route>
            <Route path={['/profile/:id/:tab', '/profile/:id']}>
                <Profile/>
            </Route>
            <Route path="/profile">
                <MyProfile/>
            </Route>
            <Route path={['/chat/:id', '/chat']}>
                <ChatScreen/>
            </Route>
            <Route path="/post/:post_id">
                <PostDetail/>
            </Route>
            <Route path="/create-post">
                <CreatePostScreen/>
            </Route>
            <Route path="/create-event">
                <CreateDateScreen/>
            </Route>
            <Route path="/users">
                <HomeScreen/>
            </Route>
            <Route path="/users/search">
                <HomeScreen/>
            </Route>
            <Route path="/feed">
                <FeedScreen/>
            </Route>
            {/*<Route path="/buy-coins">
                <CoinsCampaign />
            </Route>
            <Route path="/statistics">
                <StatisticsScreen />
            </Route>*/}

            <Route path="/">
                <DatingScreen/>
            </Route>
            <Route path="/:hash_id" component={ProfilePublic}/>
        </Switch>
    );
};

export const PublicRoutes = () => {
    return (
        <Switch>
            <Route path="/register" component={RegisterScreen}/>
            <Route path="/login" component={LoginScreen}/>
            {/*<Route path="/users/search" component={HomePublicScreen}/>*/}
            <Route path={`/q/e/:id`}>
                <ConfirmQRScan/>
            </Route>
            {/*<Route path={`/event/:id`}>
                <GuestEpisodeDetail/>
            </Route>*/}
            {/*<Route path={`/club/:id`}>
                <ClubDetail/>
            </Route>*/}
            <Route
                path={['/feed/category/:slug', '/feed']}
                component={FeedPublicScreen}
            />

            {/*<Route path="/" component={HomePublicScreen}/>*/}
            <Route path="/">
                <DatingScreen/>
            </Route>
            {/*<Route path="/:hash_id" component={ProfilePublic}/>*/}
        </Switch>
    );
};

export const UserBlockRoutes = () => {
    return (
        <Switch>
            <Route path="/">
                <SettingsBlockScreen/>
            </Route>
        </Switch>
    );
};
