import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { StreamApp, FlatFeed, Activity } from 'react-activity-feed';

import { getFeedDetail } from './feedSlice';
import ActivityFeedHeader from './activityDetail/Header';
import ActivityFeedContent from './activityDetail/Content';
// import ActivityFeedFooter from './activityDetail/Footer';
import { LoaderInline } from 'components/layout/loader';
import {
  setShowSuggestions,
  resetShowSuggestions,
} from 'features/user/suggestions/suggestionsSlice';

const ActivityFeedDetail = (props) => {
  const { post_id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(resetShowSuggestions());
  }, [dispatch]);

  const getPost = async (client, feedGroup, userId, options) => {
    const response = await dispatch(getFeedDetail({ post_id: post_id })).then(
      unwrapResult
    );

    dispatch(setShowSuggestions());

    return response;
  };

  return (
    <StreamApp
      apiKey={process.env.REACT_APP_STREAM_APP_KEY}
      appId={process.env.REACT_APP_STREAM_APP_ID}
      token={props.user.feed_token}
    >
      <FlatFeed
        feedGroup={props.feedType}
        options={{ reactions: { recent: true } }}
        doFeedRequest={getPost}
        LoadingIndicator={<LoaderInline />}
        Activity={(props) => (
          <Activity
            {...props}
            Header={<ActivityFeedHeader isDetail {...props} />}
            Content={<ActivityFeedContent isDetail {...props} />}
            // Footer={<ActivityFeedFooter isDetail {...props} />}
          />
        )}
      />
    </StreamApp>
  );
};

export default ActivityFeedDetail;
