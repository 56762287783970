import { useTranslation } from 'react-i18next';
import { Row, Col } from 'components/layout/grid';
import SubHeader from 'components/layout/subHeader/SubHeader';
import Layout from 'components/layout/layouts/Layout';
import NotificationsView from 'features/notifications/NotificationsView';

const NotificationsScreen = () => {
  const { t } = useTranslation();
  return (
    <Layout screenName={t('features:navs.settings.notifications')}>
      <Row center="xs" className="ohlala-main">
        <Col xs={12} className="mainContent">
          <SubHeader
            backBtn
            screenName={t('features:navs.settings.notifications')}
            hideOnMobile
          >
            <span></span>
          </SubHeader>

          <NotificationsView />
        </Col>
      </Row>
    </Layout>
  );
};

export default NotificationsScreen;
