import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDistanceStrict } from 'date-fns';
import {de, enGB} from 'date-fns/locale';

const StoryTime = ({ createdTime, className }) => {
  const { i18n } = useTranslation();
  const lang = i18n.language === 'de' ? de : enGB;

  let timeAgo = formatDistanceStrict(new Date(createdTime), new Date(), {
    addSuffix: true,
    locale: lang,
  });

  return (
    <time className={className} dateTime={createdTime} title={createdTime}>
      {timeAgo}
    </time>
  );
};

export default StoryTime;
