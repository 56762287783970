import React from "react";
import {getImageSrc} from "../../../helpers/s3media";
import {bannerDefault} from "../../../assets/default";
import cx from "classnames";
import styles from './continentsList.module.css';
import {useHistory} from "react-router-dom";
import {Col} from "../../../components/layout/grid";

const ContinentCard = ({city, hideFilter}) => {
    const history = useHistory();

    const firstImage = city.image;

    const firstImageUrl = firstImage ? getImageSrc({
        public_id: firstImage.public_id,
        format: firstImage.format,
        resize: {
            width: 400,
            fit: 'cover',
        },
    }) : getImageSrc(bannerDefault);


    const handleClickCity = () => {
        const clubLink = `/clubs/city/${city.id}`;
        history.push(clubLink);
    }

    return (
        <Col md={4} key={city.id}
             onClick={handleClickCity}>
            <div className={styles.outerbox}>
                <div className={styles.eventimg}>
                    <div
                        className={cx(styles.clubImageContainer, styles.clubImageCard)}>
                        <div>
                            <img
                                src={firstImageUrl}
                                className={styles.eventimg}
                                alt={city.name}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.outerboxCity}>{city.name}</div>
            </div>
        </Col>
    );
}

export default ContinentCard;