import copy from 'copy-to-clipboard';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

export const shareLink = ({ title, path, success }) => {
  if (isMobile && navigator.share) {
    navigator.share({
      title: `${title} | onlyvip.app`,
      url: `${process.env.REACT_APP_BASE_URL}${path}`,
    });
  } else {
    copy(`${process.env.REACT_APP_BASE_URL}${path}`);
    toast.success(success);
  }
};


const shareEventLink = ({ title, path, success }) => {
  if (isMobile && navigator.share) {
    navigator.share({
      title: `${title} | onlyvip.app`,
      url: `${process.env.REACT_APP_BASE_URL}events/event/${path}`,
    });
  } else {
    copy(`${process.env.REACT_APP_BASE_URL}events/event/${path}`);
    toast.success(success);
  }
};

export default shareLink;


export {shareEventLink};