import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'app/api';

export const getUserProfile = createAsyncThunk(
  'profile/getUserProfile',
  async (params) => {
    const query_params = params.query_params || null;
    const response = await api.get(`v2/user/profile_info/${params.user_id}`, {
      params: query_params,
    });
    return response.data;
  }
);

export const getUserDatingInfo = createAsyncThunk(
  'profile/getUserDatingInfo',
  async (params, { getState }) => {
    const { profile } = getState();
    const user_id = params.user_id || profile.currentProfile.info.id;
    let response = await api.get(`v2/user/profile_dating_status/${user_id}`);
    response.data.data.episode = params.episode || {};
    return response.data;
  },
  {
    condition: (params, { getState }) => {
      const { profile } = getState();
      const user_id = profile.currentProfile.info.id;
      if (!params.user_id && !user_id) {
        return false;
      }
    },
  }
);

export const getUserProfileImages = createAsyncThunk(
  'profile/getUserProfileImages',
  async ({ user_id, next }) => {
    const params = next > 1 ? { page: next } : null;
    const response = await api.get(`v2/user/profile_images/${user_id}`, {
      params: params,
    });
    return response.data;
  }
);

export const getUserStories = createAsyncThunk(
  'profile/getUserStories',
  async (user_id) => {
    const response = await api.get(`v2/stories/${user_id}`);
    return response.data;
  }
);

export const getUserProfileVideos = createAsyncThunk(
  'profile/getUserProfileVideos',
  async ({ user_id, next }) => {
    const params = next > 1 ? { page: next } : null;
    const response = await api.get(`v2/user/profile_videos/${user_id}`, {
      params: params,
    });
    return response.data;
  }
);

export const getMyProfileImages = createAsyncThunk(
  'profile/getMyProfileImages',
  async (next) => {
    const params = next > 1 ? { page: next } : null;
    const response = await api.get(`v2/user/my_images`, {
      params: params,
    });
    return response.data;
  }
);

export const getMyProfileVideos = createAsyncThunk(
  'profile/getMyProfileVideos',
  async (next) => {
    const params = next > 1 ? { page: next } : null;
    const response = await api.get(`v2/user/my_videos`, {
      params: params,
    });
    return response.data;
  }
);

export const getMyStories = createAsyncThunk(
  'profile/getMyStories',
  async (user_id) => {
    const response = await api.get(`v2/stories/${user_id}`);
    return response.data;
  }
);

export const getPurchased = createAsyncThunk(
  'profile/getPurchased',
  async (next) => {
    const params = next > 1 ? { page: next } : null;
    const response = await api.get(`v2/user/purchased_media`, {
      params: params,
    });
    return response.data;
  }
);

export const getPublicProfile = createAsyncThunk(
  'profile/getPublicProfile',
  async (params) => {
    const response = await api.get(`v2/external/info/en/${params.hash_id}`);
    return response.data;
  }
);

export const followUser = createAsyncThunk(
  'profile/followUser',
  async (params) => {
    const action_type = params.following ? 'unfollow' : 'follow';
    const response = await api.get(`v2/feeds/${action_type}/${params.user_id}`);
    return response.data;
  }
);

export const connectionUser = createAsyncThunk(
  'profile/connectionUser',
  async (params) => {
    const action_type = params.action === 1 ? 'connection' :
        params.action === 2 || params.action === 3 ? 'disconnection' :
        params.action === 4 ? 'confirm' : '';
    if(action_type) {
      const response = await api.post(`v2/user/actions/${action_type}/${params.user_id}`);
      return response.data;
    }
  }
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    currentTimestamp: 0,
    purchased: {
      items: [],
      total: 0,
      next: 1,
      fetched: false,
    },
    currentProfile: {
      info: {},
      dating: {
        applications: {},
        episode: {},
        fetched: false,
      },
      images: {
        items: [],
        total: 0,
        next: 1,
        fetched: false,
      },
      videos: {
        items: [],
        total: 0,
        next: 1,
        fetched: false,
      },
      stories: {
        items: [],
        total: 0,
        fetched: false,
      },
    },
    myProfile: {
      images: {
        items: [],
        total: 0,
        next: 1,
        fetched: false,
      },
      videos: {
        items: [],
        total: 0,
        next: 1,
        fetched: false,
      },
      stories: {
        items: [],
        total: 0,
        fetched: false,
      },
    },
    followUser: {
      fetching: false,
      showText: true,
    },
    connectionUser: {
      fetching: false,
      showText: true,
    },
    publicProfile: {},
  },
  reducers: {
    updateTimestamp: (state) => {
      state.currentTimestamp = new Date().getTime();
    },
    setDatingEpisode: (state, action) => {
      state.currentProfile.dating.episode = action.payload;
    },
    resetDatingEpisode: (state) => {
      state.currentProfile.dating.episode = {};
    },
    resetPublicProfile: (state) => {
      state.publicProfile = {};
    },
    resetPurchased: (state) => {
      state.purchased = {
        items: [],
        total: 0,
        next: 1,
        fetched: false,
      };
    },
    resetCurrentProfile: (state) => {
      state.currentProfile = {
        info: {},
        dating: {
          applications: {},
          episode: {},
          fetched: false,
        },
        images: {
          items: [],
          total: 0,
          next: 1,
          fetched: false,
        },
        videos: {
          items: [],
          total: 0,
          next: 1,
          fetched: false,
        },
        stories: {
          items: [],
          total: 0,
          fetched: false,
        },
      };
    },
    resetMyProfile: (state) => {
      state.myProfile = {
        images: {
          items: [],
          total: 0,
          next: 1,
          fetched: false,
        },
        videos: {
          items: [],
          total: 0,
          next: 1,
          fetched: false,
        },
        stories: {
          items: [],
          total: 0,
          fetched: false,
        },
      };
    },
  },
  extraReducers: {
    [getUserProfile.fulfilled]: (state, action) => {
      if (action.payload.status) {
        state.currentProfile.info = action.payload.data.user;
        state.followUser.showText = true;
        state.connectionUser.showText = true;
      }
    },
    [getUserDatingInfo.fulfilled]: (state, action) => {
      if (action.payload.status) {
        state.currentProfile.dating = action.payload.data;
        state.currentProfile.dating.fetched = true;
      }
    },
    [getUserProfileImages.fulfilled]: (state, action) => {
      state.currentProfile.images.fetched = true;
      if (action.payload.status) {
        state.currentProfile.images.items = [
          ...state.currentProfile.images.items,
          ...action.payload.data.media,
        ];
        state.currentProfile.images.total = action.payload.data.total;
        state.currentProfile.images.next = action.payload.data.next
          ? action.payload.data.next.split('=')[1]
          : 0;
      }
    },
    [getUserProfileVideos.fulfilled]: (state, action) => {
      state.currentProfile.videos.fetched = true;
      if (action.payload.status) {
        state.currentProfile.videos.items = [
          ...state.currentProfile.videos.items,
          ...action.payload.data.media,
        ];
        state.currentProfile.videos.total = action.payload.data.total;
        state.currentProfile.videos.next = action.payload.data.next
          ? action.payload.data.next.split('=')[1]
          : 0;
      }
    },
    [getUserStories.fulfilled]: (state, action) => {
      state.currentProfile.stories.fetched = true;
      if (action.payload.status) {
        state.currentProfile.stories.items = action.payload.data.list;
        state.currentProfile.stories.total = action.payload.data.paginate.total;
      }
    },
    [getMyProfileImages.fulfilled]: (state, action) => {
      state.myProfile.images.fetched = true;
      if (action.payload.status) {
        state.myProfile.images.items = [
          ...state.myProfile.images.items,
          ...action.payload.data.media,
        ];
        state.myProfile.images.total = action.payload.data.total;
        state.myProfile.images.next = action.payload.data.next
          ? action.payload.data.next.split('=')[1]
          : 0;
      }
    },
    [getMyProfileVideos.fulfilled]: (state, action) => {
      state.myProfile.videos.fetched = true;
      if (action.payload.status) {
        state.myProfile.videos.items = [
          ...state.myProfile.videos.items,
          ...action.payload.data.media,
        ];
        state.myProfile.videos.total = action.payload.data.total;
        state.myProfile.videos.next = action.payload.data.next
          ? action.payload.data.next.split('=')[1]
          : 0;
      }
    },
    [getMyStories.fulfilled]: (state, action) => {
      state.myProfile.stories.fetched = true;
      if (action.payload.status) {
        state.myProfile.stories.items = action.payload.data.list;
        state.myProfile.stories.total = action.payload.data.paginate.total;
      }
    },
    [getUserProfileImages.rejected]: (state, action) => {
      state.currentProfile.images.fetched = true;
    },
    [getUserProfileVideos.rejected]: (state, action) => {
      state.currentProfile.videos.fetched = true;
    },
    [getUserStories.rejected]: (state, action) => {
      state.currentProfile.stories.fetched = true;
    },
    [getMyProfileImages.rejected]: (state, action) => {
      state.myProfile.images.fetched = true;
    },
    [getMyProfileVideos.rejected]: (state, action) => {
      state.myProfile.videos.fetched = true;
    },
    [getMyStories.rejected]: (state, action) => {
      state.myProfile.stories.fetched = true;
    },
    [getPurchased.fulfilled]: (state, action) => {
      state.purchased.fetched = true;
      if (action.payload.status) {
        state.purchased.items = [
          ...state.purchased.items,
          ...action.payload.data.media,
        ];
        state.purchased.total = action.payload.data.totalResults;
        state.purchased.next = action.payload.data.next
          ? action.payload.data.next.split('=')[1]
          : 0;
      }
    },
    [getPurchased.rejected]: (state, action) => {
      state.purchased.fetched = true;
    },
    [getPublicProfile.fulfilled]: (state, action) => {
      if (action.payload.status) {
        state.publicProfile = action.payload.data.user;
      }
    },
    [followUser.pending]: (state, action) => {
      state.followUser.fetching = true;
      state.followUser.showText = false;
    },
    [followUser.fulfilled]: (state, action) => {
      state.followUser.fetching = false;
      if (action.payload.status) {
        state.currentProfile.following = !state.currentProfile.following;
      }
    },
    [followUser.rejected]: (state, action) => {
      state.followUser.fetching = false;
    },
    [connectionUser.pending]: (state, action) => {
      state.connectionUser.fetching = true;
      state.connectionUser.showText = false;
    },
    [connectionUser.fulfilled]: (state, action) => {
      state.connectionUser.fetching = false;
      if (action.payload.status) {
        state.currentProfile.connection = !state.currentProfile.connection;
      }
    },
    [connectionUser.rejected]: (state, action) => {
      state.connectionUser.fetching = false;
    },
  },
});

export const {
  updateTimestamp,
  setDatingEpisode,
  resetDatingEpisode,
  resetPublicProfile,
  resetCurrentProfile,
  resetPurchased,
  resetMyProfile,
} = profileSlice.actions;

export const selectPublicProfile = (state) => state.profile.publicProfile;
export const selectCurrentProfile = (state) => state.profile.currentProfile;
export const selectMyProfile = (state) => state.profile.myProfile;
export const selectFollowing = (state) => state.profile.followUser.showText;
export const selectConnected = (state) => state.profile.connectionUser.showText;
export const selectTimestamp = (state) => state.profile.currentTimestamp;

export const selectPurchased = (state) => state.profile.purchased;

export default profileSlice.reducer;
