import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//import { map, isEmpty } from 'lodash';
import api from 'app/api';

export const search = createAsyncThunk(
  'explorer/search',
  async ({ search, isPublic = false }, { rejectWithValue, getState }) => {
    //const { country, city, gender, userCategories } = getState().misc.filters;

    const searchTerm = search || '';
    const version = isPublic ? 'guests' : 'v2';
    const url = `${version}/explorer`;
    //const catQuery = !isEmpty(userCategories)
      //? map(userCategories, 'id').join()
      //: null;

    const response = await api.get(url, {
      params: {
        search: searchTerm,
        //category: catQuery,
        //gender: gender,
        //country: !isEmpty(country) ? country : null,
        //city: !isEmpty(city) ? city.toLowerCase() : null,
      },
    });

    if (!response.data.status) {
      return rejectWithValue(response.data.error);
    }

    return { ...response.data, query: searchTerm };
  }
);
export const searchMore = createAsyncThunk(
  'explorer/searchMore',
  async (next, { rejectWithValue }) => {
    const url = process.env.NODE_ENV === 'development' ? next.slice(27) : next;
    const response = await api.get(url);
    if (!response.data.status) {
      return rejectWithValue(response.data.error);
    }
    return response.data;
  }
);

const initialState = {
  status: {
    loading: false,
    hasError: false,
  },
  data: {
    results: [],
    total: 0,
    next: null,
    fetched: false,
  },
};

export const searchSlice = createSlice({
  name: 'search',
  initialState: initialState,
  reducers: {
    resetSearch: (state) => initialState,
  },
  extraReducers: {
    [search.pending]: (state, action) => {
      state.status.loading = true;
      state.status.hasError = false;
      state.data.fetched = false;
    },
    [search.rejected]: (state, action) => {
      state.status.loading = false;
      state.status.hasError = true;
      state.data.fetched = true;
    },
    [search.fulfilled]: (state, action) => {
      state.status.loading = false;
      state.data = action.payload.data;
      state.data.fetched = true;
    },
    [searchMore.pending]: (state, action) => {
      state.status.loading = true;
      state.status.hasError = false;
    },
    [searchMore.rejected]: (state, action) => {
      state.status.loading = false;
      state.status.hasError = true;
    },
    [searchMore.fulfilled]: (state, action) => {
      state.status.loading = false;
      state.data.results = [
        ...state.data.results,
        ...action.payload.data.results,
      ];
      state.data.next = action.payload.data.next;
      state.data.total = action.payload.data.total;
    },
  },
});

export const { resetSearch } = searchSlice.actions;

export const selectSearchResults = (state) => state.search.data;
export const selectSearchStatus = (state) => state.search.status;

export default searchSlice.reducer;
