import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
  useLocation,
  useHistory,
} from 'react-router-dom';

import {
  ProfileImages,
  ProfileInformation,
  //ProfileVerification,
} from 'assets/icons/settings';
import SettingsHeader from 'features/settings/SettingsHeader';
import SettingsLinksView from 'features/settings/SettingsLinksView';
import { Row, Col } from 'components/layout/grid';
import Button from 'components/buttons/Button';
import Layout from 'components/layout/layouts';
import ProfileRankRange from 'components/profileRankRange/ProfileRankRange';
import VerifyAccountView from './VerifyAccountView';
import ProfileImagesView from './ProfileImagesView';
import ProfileEditView from './ProfileEditView';
import { selectUserInfo } from 'features/auth/authSlice';

const ProfileScreen = () => {
  const userInfo = useSelector(selectUserInfo);
  const { url, path } = useRouteMatch();
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();

  const isMainPath = path === location.pathname;

  const options = {
    mainPage: [
      {
        to: `${path}/edit`,
        title: t('features:settings.profile.edit.h1'),
        text: t('features:settings.profile.edit.span'),
        icon: () => <ProfileInformation width="50" />,
      },
      {
        to: `${path}/images`,
        title: t('features:settings.profile.images.h1'),
        text: t('features:settings.profile.images.span'),
        icon: () => <ProfileImages width="50" />,
      },
      /*{
        to: `${path}/verify`,
        title: t('features:settings.profile.verify.h1'),
        text: t('features:settings.profile.verify.span'),
        icon: () => <ProfileVerification width="50" />,
        condition: !userInfo.verified_photo, // userInfo.verification_required, disabled since Ondato KYC
      },*/
    ],
    titles: {
      [path]: {
        h1: t('features:settings.profile.main.h1'),
      },
      [`${path}/edit`]: {
        h1: t('features:settings.profile.edit.h1'),
        span: t('features:settings.profile.edit.span'),
      },
      [`${path}/images`]: {
        h1: t('features:settings.profile.images.h1'),
        span: t('features:settings.profile.images.span'),
      },
     /* [`${path}/verify`]: {
        h1: t('features:settings.profile.verify.h1'),
      },*/
    },
    tabs: [
      {
        to: `${path}/edit`,
        text: t('features:settings.profile.edit.tab'),
        exact: true,
        replace: true,
      },
      {
        to: `${path}/images`,
        text: t('features:settings.profile.images.tab'),
        exact: true,
        replace: true,
      },
      /*{
        to: `${path}/verify`,
        text: t('features:settings.profile.verify.tab'),
        exact: true,
        replace: true,
        condition: !userInfo.verified_photo
      },*/
    ],
  };

  return (
    <Layout screenName={options.titles[path].h1} mobilePadding={!isMainPath}>
      <Row center="md">
        <Col xs={12} md={10}>
          <SettingsHeader options={options} />
          <ProfileRankRange isMainPath={isMainPath} />
          <Switch>
            <Route path={url} exact>
              <SettingsLinksView options={options} />
            </Route>

            <Route path={`${path}/edit`} exact>
              <ProfileEditView />
            </Route>

            <Route path={`${path}/images`} exact>
              <ProfileImagesView />
            </Route>

            {<Route path={`${path}/verify`} exact>
              <VerifyAccountView />
            </Route>}

            <Redirect to={url} />
          </Switch>
          {isMainPath && userInfo.open_profile && (
            <Button
              size="xl"
              name="btn-gradient"
              type="submit"
              context="form"
              onClick={() => history.push('/profile')}
              style={{ marginLeft: '16px' }}
            >
              {t('features:settings.yourProfile')}
            </Button>
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default ProfileScreen;
