import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../app/api';

export const getProfileInfo = createAsyncThunk(
  'settings/getProfileInfo',
  async (params) => {
    const response = await api.get('v2/user/profile/info');
    return response.data;
  }
);

export const setProfileInfo = createAsyncThunk(
  'settings/setProfileInfo',
  async (params) => {
    const response = await api.post('v2/user/profile/info', params);
    return response.data;
  }
);

export const getAccountInfo = createAsyncThunk(
  'settings/getAccountInfo',
  async (params) => {
    const response = await api.get('v2/user/profile/settings');
    return response.data;
  }
);

export const setAccountInfo = createAsyncThunk(
  'settings/setAccountInfo',
  async (params) => {
    const response = await api.post('v2/user/profile/settings', params);
    return response.data;
  }
);

export const getNotificationsInfo = createAsyncThunk(
  'settings/getNotificationsInfo',
  async (params) => {
    const response = await api.get('v2/user/profile/notifications');
    return response.data;
  }
);

export const setNotificationsInfo = createAsyncThunk(
  'settings/setNotificationsInfo',
  async (params) => {
    const response = await api.post('v2/user/profile/notifications', params);
    return response.data;
  }
);

export const removeProfileHeader = createAsyncThunk(
  'settings/removeProfileHeader',
  async (params) => {
    const response = await api.post(`v2/user/profile/headerimage`, {
     "_method": 'delete',
      "public_id": params.public_id
    });
    return response.data;
  }
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    profileInfo: {
      loading: false,
      hasError: false,
      success: false,
      data: [],
    },
    accountInfo: {
      loading: false,
      hasError: false,
      success: false,
      data: [],
    },
    notificationsInfo: {
      loading: false,
      hasError: false,
      success: false,
      data: [],
    },
  },
  reducers: {
    cleanProfileInfo: (state) => {
      state.profileInfo = {
        loading: false,
        hasError: false,
        success: false,
        data: [],
      };
    },
    cleanAccountInfo: (state) => {
      state.accountInfo = {
        loading: false,
        hasError: false,
        success: false,
        data: [],
      };
    },
    cleanNotificationsInfo: (state) => {
      state.notificationsInfo = {
        loading: false,
        hasError: false,
        success: false,
        data: [],
      };
    },
  },
  extraReducers: {
    [getAccountInfo.pending]: (state, action) => {
      state.accountInfo.loading = true;
      state.accountInfo.hasError = false;
      state.accountInfo.success = false;
    },
    [getAccountInfo.fulfilled]: (state, action) => {
      state.accountInfo.loading = false;
      if (action.payload.status) {
        state.accountInfo.success = true;
        state.accountInfo.data = action.payload.data;
      } else {
        state.accountInfo.hasError = true;
      }
    },
    [getAccountInfo.rejected]: (state, action) => {
      state.accountInfo.loading = false;
      state.accountInfo.hasError = true;
    },
    [getProfileInfo.pending]: (state, action) => {
      state.profileInfo.loading = true;
      state.profileInfo.hasError = false;
      state.profileInfo.success = false;
    },
    [getProfileInfo.fulfilled]: (state, action) => {
      state.profileInfo.loading = false;
      if (action.payload.status) {
        state.profileInfo.success = true;
        state.profileInfo.data = action.payload.data;
      } else {
        state.profileInfo.hasError = true;
      }
    },
    [getProfileInfo.rejected]: (state, action) => {
      state.profileInfo.loading = false;
      state.profileInfo.hasError = true;
    },
    [getNotificationsInfo.pending]: (state, action) => {
      state.notificationsInfo.loading = true;
      state.notificationsInfo.hasError = false;
      state.notificationsInfo.success = false;
    },
    [getNotificationsInfo.fulfilled]: (state, action) => {
      state.notificationsInfo.loading = false;
      if (action.payload.status) {
        state.notificationsInfo.success = true;
        state.notificationsInfo.data = action.payload.data;
      } else {
        state.notificationsInfo.hasError = true;
      }
    },
    [getNotificationsInfo.rejected]: (state, action) => {
      state.notificationsInfo.loading = false;
      state.notificationsInfo.hasError = true;
    },
    [removeProfileHeader.rejected]: (state, action) => {
      state.profileInfo.loading = false;
      state.profileInfo.hasError = true;
    },
  },
});

export const {
  cleanProfileInfo,
  cleanAccountInfo,
  cleanNotificationsInfo,
} = settingsSlice.actions;

export const selectProfileInfo = (state) => state.settings.profileInfo.data;
export const selectAccountInfo = (state) => state.settings.accountInfo.data;
export const selectNotificationsInfo = (state) =>
  state.settings.notificationsInfo.data;

export default settingsSlice.reducer;
