import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LocizeBackend from 'i18next-locize-backend';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// const isDevelopment = process.env.NODE_ENV === 'development';

// const backendOptions = isDevelopment
//   ? {
//       projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
//       apiKey: 'a967c577-56b4-4617-8424-2b510c891cb1',
//       referenceLng: 'en',
//       allowedAddOrUpdateHosts: ['localhost'],
//     }
//   : {
//       projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
//     };

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    supportedLngs: ['en', 'de'],
    ns: ['main', 'components', 'features', 'tutorial'],

    detection: {
      order: ['localStorage', 'navigator'],
    },

    interpolation: {
      escapeValue: false,
    },
    saveMissing: false, // isDevelopment,

    saveMissingTo: 'all',
    react: {
      bindI18n: 'languageChanged editorSaved',
    },

    // backend: {
    //   loadPath: `${process.env.REACT_APP_API_BASE_URL}v2/language/{{lng}}/{{ns}}`,
    //   addPath: `${process.env.REACT_APP_API_BASE_URL}v2/language/{{lng}}`,
    //   parsePayload: function (namespace, key, fallbackValue) {
    //     const keys = key.split('.');
    //     return {
    //       group: keys[0],
    //       key: keys[1],
    //       value: fallbackValue,
    //       category: namespace,
    //     };
    //   },
    // },
  });

export default i18n;
