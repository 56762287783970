import React, { useContext } from 'react';

import { ProgressContext } from 'features/stories/context';
import styles from './progress.module.css';

const Progress = (props) => {
  const { bufferAction, pause } = useContext(ProgressContext);
  const { width, active, count } = props;

  const getProgressStyle = ({ active }) => {
    switch (active) {
      case 2:
        return { width: '100%' };
      case 1:
        return { transform: `scaleX(${count / 100})` };
      case 0:
        return { width: 0 };
      default:
        return { width: 0 };
    }
  };

  const getProgressWrapperStyle = () => ({
    width: `${width * 100}%`,
    opacity: pause && !bufferAction ? 0 : 1,
  });

  return (
    <div className={styles.item} style={{ ...getProgressWrapperStyle(props) }}>
      <div
        className={styles.itemInner}
        style={{ ...getProgressStyle({ active }) }}
      />
    </div>
  );
};

export default Progress;
