import { useMemo, useCallback, useRef } from 'react';
import { isMobile } from 'react-device-detect';

const useTouchEvents = ({
  onTouch = () => {},
  onLongTouch = () => {},
  ms = 300,
} = {}) => {
  const timerRef = useRef(false);
  const eventRef = useRef({});

  const callback = useCallback(() => {
    onLongTouch('pause');
    eventRef.current = {};
    timerRef.current = false;
  }, [onLongTouch]);

  const start = useCallback(
    (ev) => {
      ev.persist();
      eventRef.current = ev;
      timerRef.current = setTimeout(callback, ms);
    },
    [callback, ms]
  );

  const stop = useCallback(
    (ev) => {
      ev.persist();
      eventRef.current = ev;
      if (timerRef.current) {
        onTouch();
        clearTimeout(timerRef.current);
        timerRef.current = false;
        eventRef.current = {};
      } else {
        onLongTouch('play');
      }
    },
    [onTouch, onLongTouch]
  );

  return useMemo(
    () => ({
      onPointerDown: !isMobile ? start : null,
      onPointerUp: !isMobile ? stop : null,
      onTouchStart: isMobile ? start : null,
      onTouchEnd: isMobile ? stop : null,
    }),
    [start, stop]
  );
};

export default useTouchEvents;
