import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { LoaderInline } from 'components/layout/loader';
import styles from './button.module.css';

const Button = React.forwardRef(
  (
    {
      children,
      context,
      name,
      size,
      color,
      loading,
      disabled,
      fullWidth,
      style,
      className,
      extraPadding,
      ...rest
    },
    ref
  ) => {
    const classNames = cx(styles.btn, {
      [styles[name]]: name && styles[name],
      [styles[size]]: size && styles[size],
      [styles[color]]: color && styles[color],
      [styles[context]]: context && styles[context],
      [styles.full]: fullWidth,
      [styles.loading]: loading,
      [styles.extraPadding]: extraPadding,
      [className]: className,
    });

    return (
      <button
        className={classNames}
        disabled={loading || disabled}
        style={style}
        ref={ref}
        {...rest}
      >
        {loading ? (
          <LoaderInline context="button" color={color} size={size} />
        ) : (
          children
        )}
      </button>
    );
  }
);

Button.propTypes = {
  name: PropTypes.oneOf(['btn', 'btn-gradient', 'btn-grey', 'btn-bare']),
  context: PropTypes.oneOf(['form', 'header', null]),
  color: PropTypes.oneOf(['pink', 'turq', 'grey']),
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl', null]),
  style: PropTypes.object,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  extraPadding: PropTypes.bool,
};

Button.defaultProps = {
  name: 'btn',
  color: 'pink',
  context: null,
  size: null,
  style: null,
  fullWidth: false,
  loading: false,
  disabled: false,
  extraPadding: false,
};

export default Button;
