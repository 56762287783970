import { useLocation, useRouteMatch } from 'react-router-dom';
import { useWindowWidthLessThan } from 'hooks';
import NavTitle from 'components/navTitle';
import NavTabs from 'components/navTabs';
import styles from './settings.module.css';

const SettingsHeader = ({ options }) => {
  const location = useLocation();
  const { url, path } = useRouteMatch();
  const smallWidth = useWindowWidthLessThan(576);

  if (url === location.pathname) {
    return smallWidth ? null : (
      <div className={styles.headerWrapper}>
        <h1>{options.titles[path].h1}</h1>
      </div>
    );
  }

  const h1 = options.titles[location.pathname]
    ? options.titles[location.pathname].h1
    : '';
  const span = options.titles[location.pathname]
    ? options.titles[location.pathname].span
    : '';

  return (
    <div className={styles.headerWrapper}>
      <NavTitle title={options.titles[path].h1} hide={smallWidth} />
      <NavTabs items={options.tabs} className={styles.tabsWrapper} />
      {smallWidth ? (
        <p className={styles.p}>{span}</p>
      ) : (
        <h1>
          {h1}
          <span>{span}</span>
        </h1>
      )}
    </div>
  );
};

export default SettingsHeader;
