import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { IconChevronDown } from 'assets/icons';
import styles from './phoneCountryPrefix.module.css';
import './flags.css';

const CountryPhonePrefix = ({ onChange, countries, disabled, club }) => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (countries) {
      setSelectedCountry(countries[0].prefix);
    }
    setFieldValue('countryID', countries[0].country_id);
  }, [countries, setFieldValue]);

  const onSelectChange = (e) => {
    onChange(e.target.value);
    const countryDetails = countries.filter(
      (c) => c.country_id === Number(e.target.value)
    );
    setSelectedCountry(countryDetails[0].prefix);
  };

  const getCountryFlagClass = () => {
    const countryDetails = countries.filter(
      (c) => c.prefix === selectedCountry
    );

    if (countryDetails.length) {
      return countryDetails[0].code.toLowerCase();
    }

    return '';
  };
const cls = club === true ? styles.containerNew : styles.container;

  return (
    <div className={cls}>
      {countries ? (
        <>
          <div className={styles.countryFlag}>
            <div className={`flag flag-${getCountryFlagClass()}`}>
              <span className={styles.prefix}>
                {selectedCountry}{' '}
                <IconChevronDown style={{ width: '14px', marginLeft: '5px' }} />
              </span>
            </div>
          </div>
          <div className={styles.countryNames}>
            <select
              disabled={disabled}
              className={styles.select}
              onChange={onSelectChange}
            >
              {countries.length &&
                countries.map((c, i) => (
                  <option key={`option ${i}`} value={c.country_id}>
                    {c.name}
                  </option>
                ))}
            </select>
          </div>
          <span className="phone-caret"></span>
        </>
      ) : null}
    </div>
  );
};

export default CountryPhonePrefix;
