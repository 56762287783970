import {Route, Switch, useRouteMatch} from 'react-router-dom';
//import {useSelector} from 'react-redux';
//import {selectUserGender} from 'features/auth/authSlice';
import {Row, Col} from 'components/layout/grid';
import Layout from 'components/layout/layouts/Layout';
//import SubHeader from 'components/layout/subHeader/SubHeader';
import MyEpisodesView from 'features/dating/episodes/myEpisodes/MyEpisodesView';
import MyEpisodeDetail from 'features/dating/episodes/myEpisodes/MyEpisodeDetail';
import DatingNav from 'features/dating/datingNav/DatingNav';
import EpisodesList from 'features/dating/episodes/EpisodesList';
import {useEffect} from "react";
import {checkBuildVersion} from "../helpers/build_version";
import ConfirmQRScan from "../features/dating/episodes/ConfirmQRScan";
import ContinentsList from "../features/dating/continents/ContinentsList";
import CityClubList from "../features/dating/continents/CityClubList";
import SubHeader from "../components/layout/subHeader/SubHeader";
import {useTranslation} from "react-i18next";
import ClubDetail from "../features/dating/clubs/ClubDetail";
import {useSelector} from "react-redux";
import {selectAuthenticated} from "../features/auth/authSlice";
import EpisodesListGuest from "../features/dating/episodes/EpisodesListGuest";
import GuestEpisodeDetail from "../features/dating/episodes/myEpisodes/GuestEpisodeDetail";

const DatingScreen = () => {
    const {t} = useTranslation();
    const isAuthenticated = useSelector(selectAuthenticated);
    //const { path } = useRouteMatch();
    const detailMatch = useRouteMatch(`/event/:id`);
    const detailMatchClubCity = useRouteMatch(`/clubs/city/:id`);
    const detailMatchClub = useRouteMatch(`/club/:id`);
    const isDetailScreen = (detailMatch && detailMatch.isExact)
        || (detailMatchClubCity && detailMatchClubCity.isExact)
        || (detailMatchClub && detailMatchClub.isExact);
    //const userGender = useSelector(selectUserGender);
    //const isMale = userGender === 'male';

    useEffect(() => {
        checkBuildVersion();
    }, []);

    const getTitle = () => {
        let title = '';
        if (isDetailScreen) {
            if (detailMatchClubCity && detailMatchClubCity.isExact)
                title = t('features:event.clubs', 'Clubs');
            if (detailMatchClub && detailMatchClub.isExact)
                title = t('features:event.clubs', 'Clubs');
            if (detailMatch)
                title = t('features:event.events');

            //title = t('features:event.applications');
        }
        return title;
    };

    return (
        <Layout screenName={getTitle()} noBorderHeader>
            <Row>
                <Col sm={12}>
                    {isDetailScreen &&
                    <SubHeader
                        backBtn
                        screenName={getTitle()}
                        hideOnMobile
                        /*style={{borderBottomColor: 'transparent'}}*/
                    />
                    }
                    {!isDetailScreen && <DatingNav/>}
                    {/*{isMale ? (
                        <Switch>
                            <Route path={`/event-requests`}>
                                <EpisodesList/>
                            </Route>
                            <Route path={`/event/:id`}>
                                <MyEpisodeDetail/>
                            </Route>
                            <Route path="/">
                                <MyEpisodesView/>
                            </Route>
                        </Switch>
                    ) : (*/}
                    {isAuthenticated ?
                        <Switch>
                            <Route path={`/clubs/city/:id`}>
                                <CityClubList/>
                            </Route>
                            <Route path="/events">
                                <EpisodesList/>
                            </Route>
                            <Route path={`/my-event-requests`}>
                                <MyEpisodesView/>
                            </Route>
                            <Route path={`/event/:id`}>
                                <MyEpisodeDetail/>
                            </Route>
                            <Route path={`/q/e/:id`}>
                                <ConfirmQRScan/>
                            </Route>
                            <Route path={`/club/:id`}>
                                <ClubDetail/>
                            </Route>
                            <Route path="/">
                                <ContinentsList/>
                            </Route>
                        </Switch>
                        : <Switch>
                            <Route path={`/clubs/city/:id`}>
                                <CityClubList/>
                            </Route>
                            <Route path="/events">
                                <EpisodesListGuest/>
                            </Route>
                            <Route path={`/event/:id`}>
                                <GuestEpisodeDetail/>
                            </Route>
                            {/*<Route path={`/q/e/:id`}>
                                <ConfirmQRScan/>
                            </Route>*/}
                            <Route path={`/club/:id`}>
                                <ClubDetail/>
                            </Route>
                            <Route path="/">
                                <ContinentsList/>
                            </Route>
                        </Switch>
                    }
                    {/*)}*/}
                </Col>
            </Row>
        </Layout>
    );
};

export default DatingScreen;
