import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {getBannerSrc, checkBannerImageSrc} from 'helpers/s3media';
import styles from './posterImage.module.css';
import cx from 'classnames';

const PosterImage = ({
                         width,
                         height,
                         image,
                         border,
                         className,
                         parentClassName,
                         style
                     }) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [imageDefaultSrc, setImageDefaultSrc] = useState(null);

    useEffect(() => {
        const image_src =
            typeof image === 'string'
                ? checkBannerImageSrc(image, width, height)
                : getBannerSrc(image, width, height);
        const srcDefault = getBannerSrc(null, width, height);

        //console.log(image_src);
        setError(false);
        setImageSrc(image_src);
        setImageDefaultSrc(srcDefault);
    }, [image, width, height]);

    const HandleImageError = () => {
        if (!error) {
            setError(true);
            setImageSrc(imageDefaultSrc);
        }
    };

    const additionalCls = cx({
        [styles.border]: border,
        [styles.loaded]: loaded,
        [className]: className,
    });

    return (
        <div className={cx(styles.poster,parentClassName)}>
            {imageSrc && (
                <img
                    style={{...style}}
                    className={cx(styles.avatar, additionalCls)}
                    src={imageSrc}
                    alt={''}
                    onLoad={() => setLoaded(true)}
                    onError={() => HandleImageError()}
                />
            )}
        </div>
    );
};

PosterImage.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    border: PropTypes.bool,
    image: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.string,
    ]),
    className: PropTypes.string,
};

PosterImage.defaultProps = {
    width: 731,
    height: 210,
    border: false,
};

export default PosterImage;
