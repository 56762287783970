import cx from 'classnames';
import { useTranslation } from 'react-i18next';
//import { IconCheck } from 'assets/icons';
//import { PhoneActive } from 'assets/icons/mainMenu';
import styles from './badges.module.css';
import {DatingNewActive, Verified} from "../../../assets/icons/profile";

const DatingBadge = ({ small, withText, classNameIcon }) => {
  return (
    <div
      className={cx(styles.badge, {
        [styles.small]: small,
        [styles.withText]: withText,
      })}
    >
      <DatingNewActive width={small ? '10' : '12'} className={classNameIcon}/>
      {withText && <span>Event</span>}
    </div>
  );
};

/*const PhoneBadge = ({ small, withText }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx(styles.badge, styles.phone, {
        [styles.small]: small,
        [styles.withText]: withText,
      })}
    >
      <PhoneActive width={small ? '10' : '12'} />
      {withText && <span>{t('main:main.phone_verified')}</span>}
    </div>
  );
};*/

const VerifyBadge = ({ small, withText, classNameIcon }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx(styles.badge, {
        [styles.small]: small,
        [styles.withText]: withText,
      })}
    >
      <Verified width={small ? '10' : '12'} className={classNameIcon}/>
      {withText && <span>{t('main:main.verified')}</span>}
    </div>
  );
};

const ProfileBadges = ({ small, withText, verified, dating, phone, className, classNameIcon }) => {
  if (!verified && !dating && !phone) {
    return null;
  }
  return (
    <div className={cx(styles.badges, className)}>
      {/*{phone && <PhoneBadge small={small} withText={withText} />}*/}
      {/*{dating && <DatingBadge small={small} withText={withText} classNameIcon={classNameIcon} />}*/}
      {/*{(verified || phone) && <VerifyBadge small={small} withText={withText} classNameIcon={classNameIcon} />}*/}
    </div>
  );
};

export { DatingBadge, VerifyBadge, ProfileBadges };
