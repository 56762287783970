export const getFileMeta = async (file) => {
  if (file.type.match('video.*')) {
    return new Promise((resolve, reject) => {
      try {
        let video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function () {
          resolve({
            width: video.videoWidth,
            height: video.videoHeight,
            type: 'video',
            duration: video.duration,
          });
        };

        video.onerror = function () {
          reject('Invalid video. Please select a video file.');
        };

        video.src = window.URL.createObjectURL(file);
      } catch (e) {
        reject(e);
      }
    });
  } else if (file.type.match('image.*')) {
    return new Promise((resolve, reject) => {
      try {
        let img = new Image();

        img.onload = () =>
          resolve({
            width: img.width,
            height: img.height,
            type: 'image',
            duration: 0,
          });

        img.onerror = function () {
          reject('Invalid image. Please select a image file.');
        };

        img.src = window.URL.createObjectURL(file);
      } catch (e) {
        reject(e);
      }
    });
  } else {
    return null;
  }
};
