import styles from "./episodesListItem.module.css";
import Avatar from "../../../components/avatar/Avatar";
import {monthDateTime} from "../../../helpers/dating";
import React from "react";
import {de, enGB} from "date-fns/locale";
import cx from "classnames";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {LocationInfo} from "../../user/profileInfo/profileMetaInfo";

const EventCard = ({episode, hideFilter, isPublic, episodesCount}) => {
    const {i18n} = useTranslation();
    const lang = i18n.language === 'de' ? de : enGB;

    const history = useHistory();
    const {venue} = episode;
    /*const firstImage = episode.images.length > 0 ? episode.images[0] : null;
    
    const firstImageUrl = firstImage ? getImageSrc({
        public_id: firstImage.image,
        format: JSON.parse(firstImage.extra).format,
        resize: {
            width: 400,
            fit: 'cover',
        },
    }) : getImageSrc(bannerDefault);*/

    const firstImageUrl = JSON.parse(venue.photos)[0];

    //let wrapperClass = styles.wrapper;
    let wrapperClass = cx(styles.col);
    if (hideFilter) {
        wrapperClass = cx(styles.col, styles.homeouter);
    }
    const handleClick = () => {
        //const profileLink = isPublic ? `/event-request/${episode.id}` : `/event/event-request/${episode.id}`;
        //const profileLink = isPublic ? `/event/${episode.key_id}` : `/event/${episode.key_id}`;
        const profileLink = `/event/${episode.key_id}`;
        history.push(profileLink);
    }

    //const acceptedApplications = episode.applications.filter(application => application.is_accepted === true);
    const listApplicant = episode.applications.slice(0, 5);
    const remainingApplicationCount = episode.applications.length - listApplicant.length;

    return (
        <div className={wrapperClass}>
            <div className={styles.outerbox} onClick={handleClick}>
                <div className={styles.eventimg}>
                    {/*<div className={styles.main}>
                        <div className={styles.imgover}>
                            <Avatar
                                size={44}
                                wrapperStyle={{position: 'absolute', top: '12px', left: '12px'}}
                                image={episode.user.avatar}
                                gender={episode.user.gender}
                                circle
                                className={styles.imguserover}
                            />
                            <span className={styles.eventname}>{episode.user.name}</span>
                        </div>
                        <div className={styles.right}>
                            {episode.event_type}
                        </div>
                    </div>*/}
                    <div className={styles.imgover}>
                        <Avatar
                            /*onClick={onClick}*/
                            size={44}
                            wrapperStyle={{position: 'absolute', top: '12px', left: '12px'}}
                            image={episode.user.avatar}
                            gender={episode.user.gender}
                            circle
                            className={styles.imguserover}
                        />
                        <span className={styles.eventname}>{episode.user.name}</span>
                        {episode.event_type &&
                        <span className={styles.cartegoryname}>{episode.event_type}</span>
                        }
                    </div>
                    <div className={styles.imgoverbottom}>
                        <div className={styles.images}>
                            {listApplicant.length > 0 &&
                            listApplicant.map((application, i) => {
                                return (
                                    <Avatar
                                        key={i}
                                        size={30}
                                        image={application.profile.avatar}
                                        gender={application.profile.gender}
                                        circle
                                    />
                                );
                            })
                            }
                            {remainingApplicationCount > 0 &&
                            <div className={styles.moreApplicationCount}>+{remainingApplicationCount}</div>
                            }
                        </div>
                        <span className={styles.eventdate}>{monthDateTime(episode, lang)}</span>
                    </div>

                    <div
                        className={cx(styles.clubImageContainer, styles.clubImageCard)}>
                        <div>
                            <img
                                src={firstImageUrl}
                                className={styles.eventimg}
                                alt={episode.name}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.eventinfo}>
                    <div className={styles.desc}>
                        <h3>{episode.name}</h3>
                        <div className={styles.venue}>{venue.name}</div>
                    </div>
                    <LocationInfo
                        customClass={styles.city}
                        town_name={venue.city}
                        country={venue.country_code.toLowerCase()}
                    />
                </div>
            </div>
            {/*{!isEmpty(applications) && (
                <ApplicationsList episode={episode} episodesCount={episodesCount} />
            )}*/}
        </div>
    );
}

export default EventCard;