import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import api from 'app/api';
import Button from 'components/buttons/Button';
import { FormRow, Input } from 'components/forms';

const ChangePasswordView = () => {
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required(
      t('components:validation.required', 'Required')
    ),
    password: Yup.string()
      .min(8, t('components:validation.tooShort', 'Too Short!'))
      .matches(
        /(?=.*[a-z])/,
        t(
          'components:validation.lowerCase',
          'at least 1 lower case letter required'
        )
      )
      .matches(
        /(?=.*[A-Z])/,
        t(
          'components:validation.upperCase',
          'at least 1 upper case letter required'
        )
      )
      .matches(
        /(?=.*\d)/,
        t('components:validation.includeDigit', 'use at least one digit')
      )
      .required(t('components:validation.required', 'Required')),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], t('components:validation.mustMatch'))
      .required(t('components:validation.required', 'Required')),
  });

  const handleError = (error = '') => {
    switch (error.code) {
      case 506:
        setError(
          t('components:validation.invalidPass', 'Invalid current password')
        );
        break;
      case 200:
        window.location.reload();
        break;
      default:
        toast.error(
          t(
            'components:validation.Failed to change password',
            'Failed to change password'
          )
        );
        break;
    }
  };

  const onUpdate = async (payload) => {
    setError(false);

    try {
      const res = await api.post('/v2/user/profile/password', payload);
      if (res.data.error) {
        return handleError(res.data.error);
      }

      toast.success(
        t(
          'components:changePassword.passChangeSuccess',
          'Password changed Successfully'
        )
      );
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Formik
      onSubmit={async (values) => {
        await onUpdate(values);
      }}
      validationSchema={validationSchema}
      initialValues={{
        old_password: '',
        password: '',
        password_confirmation: '',
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form>
          <FormRow>
            <Input
              type="password"
              label={t(
                'components:changePassword.currentPassword',
                'Current password'
              )}
              name="old_password"
            />
          </FormRow>
          <FormRow>
            <Input
              type="password"
              label={t('components:changePassword.newPassword', 'New password')}
              name="password"
            />
          </FormRow>
          <FormRow>
            <Input
              type="password"
              label={t(
                'components:changePassword.confirmNew',
                'Confirm new password'
              )}
              name="password_confirmation"
            />
          </FormRow>
          <FormRow style={{ textAlign: 'right' }}>
            <div style={{ textAlign: 'left', color: 'red' }}>
              {error ? error : ''}
            </div>
          </FormRow>
          <FormRow>
            <Button
              size="xl"
              context="form"
              type="submit"
              name="btn-gradient"
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {t('main:main.Submit')}
            </Button>
          </FormRow>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordView;
