import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
// import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { useUrlQuery } from 'hooks';
import LoadMoreTrigger from 'components/loadMore/LoadMoreTrigger';
import { setShowPublicRegister } from 'app/miscSlice';
import { selectAuthenticated } from 'features/auth/authSlice';
import {
  getGlobalFeed,
  getGlobalFeedMore,
  selectGlobalFeed,
} from './feedSlice';
import {
  setShowSuggestions,
  resetShowSuggestions,
} from 'features/user/suggestions/suggestionsSlice';
import { applyFilters, selectFilters } from 'app/miscSlice';
import Activity from './activityDetail/Activity';
import { LoaderInline } from 'components/layout/loader';

const GlobalFeed = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const query = useUrlQuery();
  const posts = useSelector(selectGlobalFeed);
  const filters = useSelector(selectFilters);
  const isAuthenticated = useSelector(selectAuthenticated);

  const { slug } = useParams();
  const ids = query.get('cat');

  const categories = ids || slug || null;

  useEffect(() => {
    if (isEmpty(posts.results) && !posts.fetched) {
      dispatch(
        getGlobalFeed({
          categories: categories,
          isPublic: !isAuthenticated,
        })
      );
    }
  }, [dispatch, isAuthenticated, categories, posts.results, posts.fetched]);

  useEffect(() => {
    if (isEmpty(filters.categories) && !isEmpty(categories)) {
      dispatch(applyFilters({ feedCategories: posts.categories }));
    }
  }, [dispatch, categories, filters.categories, posts.categories]);

  useEffect(() => {
    if (posts.fetched && isEmpty(posts.next)) {
      dispatch(setShowSuggestions());
    }
    return () => dispatch(resetShowSuggestions());
  }, [dispatch, posts.fetched, posts.next]);

  const fetchNext = () => {
    if (!isEmpty(posts.next) && !posts.fetching) {
      dispatch(getGlobalFeedMore(posts.next));
    }
  };

  const publicModal = () => {
    dispatch(setShowPublicRegister(true));
  };

  if (isEmpty(posts.results)) {
    if (posts.fetched) {
      return (
        <div style={{ margin: '20px 0px 80px 16px' }}>
          <p>{t('features:search.NoResults', 'No results')}</p>
        </div>
      );
    } else {
      return <LoaderInline />;
    }
  }

  return (
    <>
      {posts.results.map((post, index) => {
        return (
          <Activity
            post={post}
            publicModal={!isAuthenticated && publicModal}
            key={index}
          />
        );
      })}

      {!isEmpty(posts.results) && (
        <LoadMoreTrigger getMore={fetchNext} hasMore={!isEmpty(posts.next)} />
      )}
    </>
  );
};

export default GlobalFeed;
