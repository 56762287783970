import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import isEmpty from 'lodash/isEmpty';

import MyEpisodesListItem from './MyEpisodesListItem';
// import UserList from '../../userList/UserList';

import {
    resetNewApplicationsCount,
    // selectNewApplicationsCount,
} from '../../applications/applicationsSlice';

import styles from './myEpisodes.module.css';
import cx from "classnames";
import ClubListItem from "../../clubs/ClubListItem";

const MyEpisodesList = ({episodes, clubs}) => {
    // const countNew = useSelector(selectNewApplicationsCount);
    const dispatch = useDispatch();
    // const { t } = useTranslation();

    useEffect(() => {
        return () => dispatch(resetNewApplicationsCount());
    }, [dispatch]);

    //let wrapperClass = styles.wrapper;
    let wrapperClass = cx(styles.row, styles.eventlist);
    if (episodes.length !== 1) {
        wrapperClass += ' mainContent';
    }

    /*console.log("episodes===>",episodes)
    console.log("clubs===>",clubs)*/

    return (
        <div className={wrapperClass}>
            {episodes.map((episode, i) => {
                return (
                    <MyEpisodesListItem
                        key={i}
                        episode={episode}
                        episodesCount={episodes.length}
                    />
                );
            })}

            {clubs && clubs.list &&
            clubs.list.map((club, i) => {
                return <ClubListItem hideFilter={true} key={i} club={club}/>;
            })}


            {/*{episodes.length === 1 && isEmpty(episodes[0].applications) && (
        <div className={styles.suggestions}>
          <p>{t('features:datingEmptyList.chatCTA')}</p>
          <UserList />
        </div>
      )}*/}
        </div>
    );
};

export default MyEpisodesList;
