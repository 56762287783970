import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PinturaEditorModal } from 'react-pintura';
import api from 'app/api';
import AWS from 'aws-sdk';
import shortId from 'short-uuid';
import {
  createDefaultImageReader,
  createDefaultImageWriter,
  setPlugins,
  plugin_crop,
  plugin_finetune,
  plugin_filter,
  locale_en_gb,
  plugin_crop_locale_en_gb,
  plugin_finetune_locale_en_gb,
  plugin_filter_locale_en_gb,
  plugin_finetune_defaults,
  plugin_filter_defaults,
  markup_editor_defaults,
} from 'pintura';
import locale_de_DE from 'assets/extra/pintura/locale/de_DE/core/de_DE.js';
import plugin_crop_locale_de_DE from 'assets/extra/pintura/locale/de_DE/crop/de_DE.js';
import plugin_finetune_locale_de_DE from 'assets/extra/pintura/locale/de_DE/finetune/de_DE.js';
import plugin_filter_locale_de_DE from 'assets/extra/pintura/locale/de_DE/filter/de_DE.js';

import { getFileMeta } from 'helpers/mediaFileMeta';
import { selectUserInfo } from 'features/auth/authSlice';
import styles from 'features/settings/profile/profileEdit.module.css';

const ProfileImageEdit = ({ onImageEdit, afterUpload }) => {
  setPlugins(plugin_crop, plugin_finetune, plugin_filter);
  const [image, setImage] = useState(null);
  const [imageMeta, setImageMeta] = useState(null);
  const { uid } = useSelector(selectUserInfo);
  const { i18n, t } = useTranslation();
  const locale =
    i18n.language === 'de'
      ? {
          ...locale_de_DE,
          ...plugin_crop_locale_de_DE,
          ...plugin_finetune_locale_de_DE,
          ...plugin_filter_locale_de_DE,
        }
      : {
          ...locale_en_gb,
          ...plugin_crop_locale_en_gb,
          ...plugin_finetune_locale_en_gb,
          ...plugin_filter_locale_en_gb,
        };

  const editorConfig = {
    imageReader: createDefaultImageReader(),
    ...markup_editor_defaults,
    ...plugin_finetune_defaults,
    ...plugin_filter_defaults,
    locale: locale,
    imageCropAspectRatio: 1,
    utils: ['crop', 'filter', 'finetune'],
    willRenderCanvas: (shapes, state) => {
      const {
        utilVisibility,
        selectionRect,
        lineColor,
        backgroundColor,
      } = state;

      // Exit if crop utils is not visible
      if (utilVisibility.crop <= 0) return shapes;

      // Get variable shortcuts to the crop selection rect
      const { x, y, width, height } = selectionRect;

      return {
        // Copy all props from current shapes
        ...shapes,

        // Now we add an inverted ellipse shape to the interface shapes array
        interfaceShapes: [
          {
            x: x + width * 0.5,
            y: y + height * 0.5,
            rx: width * 0.5,
            ry: height * 0.5,
            opacity: utilVisibility.crop,
            inverted: true,
            backgroundColor: [...backgroundColor, 0.5],
            strokeWidth: 1,
            strokeColor: [...lineColor],
          },
          // Spread all existing interface shapes onto the array
          ...shapes.interfaceShapes,
        ],
      };
    },
  };

  AWS.config.update({
    region: process.env.REACT_APP_S3_BUCKET_REGION,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_S3_POOL_ID,
    }),
  });

  const onInputChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const fileInfo = await getFileMeta(file);
    if (!fileInfo) return;

    setImage(file);
    setImageMeta(fileInfo);
  };

  const imageWriter = createDefaultImageWriter({
    store: (state, options, onprogress) =>
      new Promise((resolve, reject) => {
        const { dest } = state;
        const fileExt = dest.name.split('.').pop();
        const fileId = `models/${uid}/${shortId.generate()}`;
        const s3Key = `${fileId}.${fileExt}`;
        let fileInfo = {
          duration: imageMeta.duration,
          width: imageMeta.width,
          height: imageMeta.height,
          type: imageMeta.type,
          size: dest.size,
          format: fileExt,
          public_id: fileId,
        };
        console.log(fileInfo, imageMeta);

        const upload = new AWS.S3.ManagedUpload({
          params: {
            Bucket: process.env.REACT_APP_S3_BUCKET_NAME_UPLOAD,
            Key: s3Key,
            Body: dest,
            ContentType: "image/jpg, image/png, image/jpeg",
          },
        });
        upload.on('httpUploadProgress', (e) => {
          onprogress(true, e.loaded, e.total);
        });

        const promise = upload.promise();
        promise.then(
          function (data) {
            fileInfo.s3_data = data;
            const newImage = {
              public_id: fileInfo.public_id,
              format: fileInfo.format,
            };
            onImageEdit(newImage);
            api
              .put('v2/user/profile/image', {
                media: JSON.stringify(fileInfo),
              })
              .then((res) => {
                if (res.data.status) {
                  afterUpload && afterUpload();
                  resolve(state);
                  resolve(state);
                } else {
                  throw new Error('story create error');
                }
              })
              .catch((err) => {
                reject(t('main:errors.somethingWentWrong'));
              });
          },
          function (err) {
            reject(t('main:errors.somethingWentWrong'));
          }
        );
      }),
  });

  const DefaultTrigger = () => {
    return (
      <input
        className={styles.uploadInput}
        type="file"
        name="file"
        accept="image/*"
        onChange={onInputChange}
      />
    );
  };

  return (
    <>
      <DefaultTrigger />
      {image && (
        <PinturaEditorModal
          {...editorConfig}
          imageWriter={imageWriter}
          src={image}
          // onLoad={(res) => console.log('load modal image', res)}
          onHide={() => setImage(false)}
          cropEnableSelectPreset={true}
        />
      )}
    </>
  );
};

export default ProfileImageEdit;
