import {
    IconAmorSticker,
    IconGiftSticker,
    IconHeartSticker,
    IconLoveLetterSticker,
    IconRoseSticker, IconWineSticker
} from "../../../assets/icons";
import styles from "./transactionModal.module.css";
import React from "react";
import {useSelector} from "react-redux";
import {selectTips} from "../../../app/miscSlice";

const TipData = () => {

        const tipdatasg = useSelector(selectTips);

        const updatedItems = tipdatasg.map(item => {
            let i_id = parseInt(item.id)
            if (i_id === 1) {
                return {...item, icon: <IconAmorSticker className={styles.tipBtnIcon}></IconAmorSticker>};
            }
            else if (i_id === 2) {
                return {...item, icon: <IconGiftSticker className={styles.tipBtnIcon}></IconGiftSticker>};
            }
            else if (i_id === 3) {
                return {...item, icon: <IconHeartSticker className={styles.tipBtnIcon}></IconHeartSticker>};
            }
            else if (i_id === 4) {
                return {...item, icon: <IconLoveLetterSticker className={styles.tipBtnIcon}></IconLoveLetterSticker>};
            }
            else if (i_id === 5) {
                return {...item, icon: <IconRoseSticker className={styles.tipBtnIcon}></IconRoseSticker>};
            }
            else if (i_id === 6) {
                return {...item, icon: <IconWineSticker className={styles.tipBtnIcon}></IconWineSticker>};
            } else {
                return {...item, icon: <IconWineSticker className={styles.tipBtnIcon}></IconWineSticker>};
            }
        });
        return updatedItems;


        /*console.log(tipdatasg);

        const tipdatas = [
            {
                id: 1,
                icon: <IconAmorSticker className={styles.tipBtnIcon}></IconAmorSticker>,
                name:
                    t(
                        'features:stickers.amor',
                        'Amor'),
                coins: 5,
                code: 'amor'
            },
            {
                id: 2,
                icon: <IconGiftSticker className={styles.tipBtnIcon}></IconGiftSticker>,
                name:
                    t(
                        'features:stickers.gift',
                        'Gift'),
                coins: 10,
                code: 'gift'
            },
            {
                id: 3,
                icon: <IconHeartSticker className={styles.tipBtnIcon}></IconHeartSticker>,
                name:
                    t(
                        'features:stickers.heart',
                        'Heart'),
                coins: 15,
                code: 'heart'
            },
            {
                id: 4,
                icon: <IconLoveLetterSticker className={styles.tipBtnIcon}></IconLoveLetterSticker>,
                name:
                    t(
                        'features:stickers.loveletter',
                        'Loveletter'),
                coins: 25,
                code: 'letter'
            },
            {
                id: 5,
                icon: <IconRoseSticker className={styles.tipBtnIcon}></IconRoseSticker>,
                name:
                    t(
                        'features:stickers.rose',
                        'Rose'),
                coins: 50,
                code: 'rose'
            },
            {
                id: 6,
                icon: <IconWineSticker className={styles.tipBtnIcon}></IconWineSticker>,
                name:
                    t(
                        'features:stickers.wine',
                        'Wine'),
                coins: 100,
                code: 'wine'
            }
        ];

        return tipdatas;*/
    }
;

export default TipData;