import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useModal } from '../modal/useModal';
import Button from '../buttons/Button';

const RouteLeavingGuard = ({ when, navigate, shouldBlockNavigation }) => {
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const history = useHistory();
  const { RenderModal, hideModal, showModal } = useModal();
  const { t } = useTranslation();

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      if (!navigate) {
        history.push(lastLocation.pathname);
      } else {
        navigate(lastLocation.pathname);
      }
    }
  }, [confirmedNavigation, lastLocation, navigate, history]);

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && !shouldBlockNavigation(nextLocation)) {
      showModal();
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    hideModal();
    setConfirmedNavigation(true);
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />

      <RenderModal
        title={t('components:routeGuard.title')}
        buttonClose
        footer={
          <>
            <Button
              onClick={hideModal}
              name="btn-grey"
              style={{ width: '48%' }}
            >
              {t('main:main.Cancel')}
            </Button>
            <Button
              onClick={handleConfirmNavigationClick}
              name="btn"
              style={{ width: '48%' }}
            >
              {t('main:main.Proceed')}
            </Button>
          </>
        }
      >
        <p>{t('components:routeGuard.text')}</p>
      </RenderModal>
    </>
  );
};

RouteLeavingGuard.propTypes = {
  when: PropTypes.bool.isRequired,
  navigate: PropTypes.func,
  shouldBlockNavigation: PropTypes.func,
};

RouteLeavingGuard.defaultProps = {
  shouldBlockNavigation: () => {
    return false;
  },
};

export default RouteLeavingGuard;
