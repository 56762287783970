import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import isEmpty from 'lodash/isEmpty';

import ItemPreview from './ItemPreview';
import { toggleDatingDisclaimer } from 'components/layout/layoutSlice';
import styles from './dating.module.css';

const DatingSidebar = ({ applications, episode }) => {
  const { ref, inView } = useInView();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleDatingDisclaimer(!inView));
  }, [inView, dispatch]);

  return (
    <div className={styles.sidebarWrapper} ref={ref} id="datingContainer">
      {!isEmpty(episode) ? (
        <ItemPreview episode={episode} />
      ) : (
        applications.map(function (application, i) {
          return (
            <ItemPreview
              key={i + 1}
              application={application}
              episode={application.episode}
            />
          );
        })
      )}
    </div>
  );
};

export default DatingSidebar;
