const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'var(--accentColor)' : '#fff',
    '&:hover': {
      backgroundColor: 'var(--accentColorLight)',
    },
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: 'var(--grey5)',
    backgroundColor: 'var(--baseGrey)',
    boxShadow: state.isFocused ? '0 0 3px var(--accentColor)' : 'none',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

export default customStyles;
