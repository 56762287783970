import React from 'react';
import Container from './components/Container';
import GlobalContext from './context/Global';

const UserStories = (props) => {
  const context = {
    stories: props.stories,
    profile: props.profile,
    isMyProfile: props.isMyProfile,
    isMobile: props.isMobile,
    loader: props.loader,
    storyContainerStyles: props.storyContainerStyles,
    storyStyles: props.storyStyles,
    defaultInterval: props.defaultInterval,
    isActive: props.isActive,
    slideIndex: props.slideIndex,
    onStoryStart: props.onStoryStart,
    onStoryEnd: props.onStoryEnd,
    onAllStoriesEnd: props.onAllStoriesEnd,
    getPrevSlide: props.getPrevSlide,
    closeAction: props.closeAction,
    actionsRef: props.actionsRef,
  };

  return (
    <GlobalContext.Provider value={context}>
      <Container />
    </GlobalContext.Provider>
  );
};

UserStories.defaultProps = {
  stories: [],
  slideIndex: 0,
  isActive: true,
  defaultInterval: 4000,
};

export default UserStories;
