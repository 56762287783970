import React, {
  useContext,
  useState,
  useEffect,
  useReducer,
  // useRef,
} from 'react';
import { GlobalContext, ProgressContext } from 'features/stories/context';
import Story from './Story';
import Header from './header';
//import Footer from './footer';
import ProgressBar from './progress';
import { useTouchEvents } from 'hooks';
import styles from './../stories.module.css';

const Container = () => {
  const [storyIndex, setStoryIndex] = useState(0);
  const [pause, setPause] = useState(true);
  const [bufferAction, setBufferAction] = useState(true);
  const [videoDuration, setVideoDuration] = useState(0);
  const [updates, forceUpdate] = useReducer((x) => x + 1, 0);
  const {
    stories,
    isActive,
    preventDefault,
    onAllStoriesEnd,
    getPrevSlide,
    slideIndex,
    defaultInterval,
  } = useContext(GlobalContext);

  // let isMounted = useRef(true);

  useEffect(() => {
    const firstUnseen = stories.findIndex((s) => s.seen === false);
    if (firstUnseen > 0) {
      setStoryIndex(firstUnseen);
    }
  }, [stories]);

  // useEffect(() => {
  //   return () => {
  //     isMounted.current = false;
  //   };
  // }, []);

  useEffect(() => {
    if (isActive && !bufferAction) {
      setPause(false);
    }
  }, [isActive, bufferAction]);

  useEffect(() => {
    const callback = (e) => {
      e.preventDefault();
    };
    document.addEventListener('contextmenu', callback);
    return () => {
      document.removeEventListener('contextmenu', callback);
    };
  }, []);

  const leftEvents = useTouchEvents({
    onTouch: () => handleClick('prev'),
    onLongTouch: (action) => handleLongClick(action),
  });

  const rightEvents = useTouchEvents({
    onTouch: () => handleClick('next'),
    onLongTouch: (action) => handleLongClick(action),
  });

  // useEffect(() => {
  //   const handleKeyDown = (e) => {
  //     if (e.key === 'ArrowLeft') {
  //       previous();
  //     } else if (e.key === 'ArrowRight') {
  //       next();
  //     }
  //   };
  //   const isClient = typeof window !== 'undefined' && window.document;
  //   if (isClient) {
  //     document.addEventListener('keydown', handleKeyDown);
  //     return () => {
  //       document.removeEventListener('keydown', handleKeyDown);
  //     };
  //   }
  // }, []);

  const handleClick = (type) => {
    if (pause || type === 'play') {
      toggleState('play');
    } else {
      type === 'next' ? next() : previous();
    }
  };

  const handleLongClick = (action) => {
    toggleState(action);
  };

  const toggleState = (action, bufferAction) => {
    setPause(action === 'pause');
    setBufferAction(!!bufferAction);
  };

  const previous = () => {
    if (storyIndex > 0) {
      setStoryIndex(storyIndex - 1);
      toggleState('pause', true);
    } else if (slideIndex > 0) {
      getPrevSlide();
    } else {
      forceUpdate();
    }
  };

  const next = () => {
    // if (!isMounted) return;
    if (storyIndex < stories.length - 1) {
      setStoryIndex(storyIndex + 1);
      toggleState('pause', true);
    } else {
      onAllStoriesEnd();
    }
  };

  const getVideoDuration = (duration) => {
    const interval = Math.max(duration * 1000, defaultInterval);
    setVideoDuration(interval);
  };

  return (
    <div className={styles.container}>
      <ProgressContext.Provider
        value={{
          bufferAction: bufferAction,
          playAction: () => handleClick('play'),
          videoDuration: videoDuration,
          story: stories[storyIndex],
          storyIndex,
          pause,
          next,
        }}
      >
        {isActive && <ProgressBar key={updates} />}
        <Header pauseAction={(action) => toggleState(action, false)} />
        {/*<Footer />*/}
      </ProgressContext.Provider>
      <Story
        key={updates}
        action={toggleState}
        bufferAction={bufferAction}
        playState={pause}
        isActive={isActive}
        story={stories[storyIndex]}
        getVideoDuration={getVideoDuration}
      />
      {!preventDefault && (
        <div className={styles.overlay}>
          <div style={{ width: '50%', zIndex: 999 }} {...leftEvents} />
          <div style={{ width: '50%', zIndex: 999 }} {...rightEvents} />
        </div>
      )}
    </div>
  );
};

export default Container;
