import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/api';

export const getFollowers = createAsyncThunk(
  'followings/getFollowers',
  async () => {
    const res = await api.get('/v2/feeds/followers');
    return res.data;
  }
);
export const getFollowings = createAsyncThunk(
  'followings/getFollowings',
  async () => {
    const res = await api.get('/v2/feeds/following');
    return res.data;
  }
);

export const followingsSlice = createSlice({
  name: 'followings',
  initialState: {
    followers: {
      data: [],
      loading: false,
      error: false,
      success: false,
    },
    followings: {
      data: [],
      loading: false,
      error: false,
      success: false,
    },
  },

  extraReducers: {
    [getFollowers.pending]: (state) => {
      state.followers.loading = true;
    },
    [getFollowers.fulfilled]: (state, action) => {
      if (action.payload.status) {
        state.followers.data = action.payload.data.followers;
        state.followers.loading = false;
      }
    },
    [getFollowings.pending]: (state) => {
      state.followings.loading = true;
    },
    [getFollowings.fulfilled]: (state, action) => {
      if (action.payload.status) {
        state.followings.data = action.payload.data.following;
        state.followings.loading = false;
      }
    },
  },
});

export const selectFollowers = (state) => state.followings.followers;
export const selectFollowings = (state) => state.followings.followings;

export default followingsSlice.reducer;
