import { createSlice } from '@reduxjs/toolkit';

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    showSidebar: false,
    showCreateDrawer: false,
    showDatingDisclaimer: false,
  },
  reducers: {
    toggleSidebar: (state) => {
      state.showSidebar = !state.showSidebar;
    },
    toggleCreateDrawer: (state, action) => {
      state.showCreateDrawer = action.payload;
    },
    toggleDatingDisclaimer: (state, action) => {
      state.showDatingDisclaimer =
        action.payload || !state.showDatingDisclaimer;
    },
  },
});

export const {
  toggleSidebar,
  toggleCreateDrawer,
  toggleDatingDisclaimer,
} = layoutSlice.actions;

export const selectSidebar = (state) => state.layout.showSidebar;
export const selectCreateDrawer = (state) => state.layout.showCreateDrawer;
export const selectDatingDisclaimer = (state) =>
  state.layout.showDatingDisclaimer;

export default layoutSlice.reducer;
