import React, { useState, useEffect, useRef } from 'react';

import WithDelay from 'components/WithDelay';
import { LoaderInline } from 'components/layout/loader';
import { getStorySrc } from 'helpers/s3media';
import styles from './../../stories.module.css';

export const Image = ({ story, action, isPaused }) => {
  const [loaded, setLoaded] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const imgEl = imageRef.current;
    if (imgEl && imgEl.complete && !loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  const imageLoaded = () => {
    if (!loaded) {
      setLoaded(true);
    }
    action('play');
  };

  return (
    <div className={styles.imageContainer}>
      <img
        ref={imageRef}
        className={styles.storyContent}
        onLoad={imageLoaded}
        src={getStorySrc(story.media)}
        alt=""
      />
      {!loaded && (
        <WithDelay>
          <LoaderInline centered color="white" />
        </WithDelay>
      )}
    </div>
  );
};

export default Image;
