import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { getMyEpisodesPath } from 'helpers/dating';
import Button from 'components/buttons/Button';
import { useModal } from 'components/modal/useModal';
import { LoaderRelative } from 'components/layout/loader';
import { getApplications } from 'features/dating/applications/applicationsSlice';
import { closeEpisode } from 'features/dating/episodes/episodesSlice';
import { selectUserInfo } from 'features/auth/authSlice';
import { IconBlock, IconDots } from 'assets/icons';
import styles from './episodeActions.module.css';

const EpisodeActions = ({ episode_id, isDetail }) => {
  const [showMenu, setShowMenu] = useState();
  const blockModal = useModal();
  const reportkModal = useModal();
  const menuRef = useRef(null);

  const trackClickOutsideMenu = useCallback((e) => {
    if (!menuRef.current) return;

    if (!menuRef.current.contains(e.target)) {
      document.removeEventListener('click', trackClickOutsideMenu, true);
      setShowMenu(false);
    }
  }, []);

  const closeContextMenu = () => {
    document.removeEventListener('click', trackClickOutsideMenu, true);
    setShowMenu(false);
  };

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('click', trackClickOutsideMenu, true);
    }
    return () =>
      document.removeEventListener('click', trackClickOutsideMenu, true);
  }, [showMenu, trackClickOutsideMenu]);

  return (
    <>
      <div className={styles.container}>
        <Button
          onClick={() => setShowMenu(true)}
          className={styles.trigger}
          name="btn-bare"
        >
          <IconDots
            style={{ stroke: 'var(--grey3)' }}
            className={styles.headerIconRight}
          />
        </Button>
        <ContextMenu
          show={showMenu}
          modal={blockModal}
          reportModal={reportkModal}
          onCloseMenu={closeContextMenu}
          ref={menuRef}
        />
      </div>
      {!showMenu && (
        <>
          {blockModal.isVisible && (
            <CancelEpisodeModal
              isDetail={isDetail}
              episode_id={episode_id}
              modalContext={blockModal}
            />
          )}
        </>
      )}
    </>
  );
};

const ContextMenu = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  if (!props.show) return null;
  return (
    <div ref={ref} className={styles.menu}>
      <Button
        onClick={() => {
          props.modal.showModal();
          props.onCloseMenu();
        }}
        className={styles.btn}
        name="btn-bare"
      >
        <IconBlock className={styles.ico} /> {t('main:main.close')}
      </Button>
    </div>
  );
});

const CancelEpisodeModal = ({
  modalContext: { RenderModal, hideModal },
  episode_id,
  isDetail,
}) => {
  const [error, setError] = useState(false);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const user = useSelector(selectUserInfo);
  const myEpisodesPath = getMyEpisodesPath(user.gender);

  const onBlock = async () => {
    setloading(true);
    try {
      dispatch(closeEpisode({ id: episode_id }))
        .then(unwrapResult)
        .then((result) => {
          if (result.status) {
            if (isDetail) {
              history.push(myEpisodesPath);
            } else {
              dispatch(getApplications())
                .then(unwrapResult)
                .then((result) => {
                  toast.success(
                    t('features:episodeActions.successfullyClosed')
                  );
                  hideModal();
                });
            }
          } else {
            throw new Error('block user error');
          }
        });
    } catch (error) {
      //console.log(error);
      setloading(false);
      setError(true);
    }
  };

  if (error) {
    return (
      <RenderModal
        title={t('features:episodeActions.closeModalTitle')}
        buttonClose
      >
        <div className={styles.reportReasons}>
          <p>{t('main:errors.somethingWentWrong')}</p>
          <br />
          <Button onClick={() => hideModal()} name="btn">
            {t('main:main.OK')}
          </Button>
        </div>
      </RenderModal>
    );
  }

  if (loading) {
    return (
      <RenderModal
        title={t('features:episodeActions.closeModalTitle')}
        buttonClose
      >
        <LoaderRelative />
      </RenderModal>
    );
  }

  return (
    <RenderModal
      title={t('features:episodeActions.closeModalTitle')}
      buttonClose
      footer={
        <>
          <Button
            onClick={() => hideModal()}
            name="btn-grey"
            style={{ width: '48%' }}
          >
            {t('main:main.no')}
          </Button>
          <Button onClick={onBlock} name="btn" style={{ width: '48%' }}>
            {t('main:main.yes')}
          </Button>
        </>
      }
    >
      <p className={styles.copy}>{t('features:episodeActions.confirmClose')}</p>
    </RenderModal>
  );
};

export default EpisodeActions;
