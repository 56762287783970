import { useEffect } from 'react';
import debounce from 'lodash/debounce';

export const updateVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const useRealViewportH = () => {
  useEffect(() => {
    updateVh();

    const onResizeEvent = debounce(updateVh, 100);

    window.addEventListener('resize', onResizeEvent, true);

    return () => {
      window.removeEventListener('resize', onResizeEvent, true);
      document.documentElement.style.removeProperty('--vh');
    };
  }, []);
};

export default useRealViewportH;
