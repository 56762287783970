import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { Rating } from 'react-simple-star-rating';
import { toast } from 'react-toastify';

import { LoaderRelative } from 'components/layout/loader';
import { useModal } from 'components/modal/useModal';
import Button from 'components/buttons/Button';
import { rateMember } from 'features/chat/chatSlice';
import styles from './chatFeedback.module.css';

const ChatFeedback = ({ chatId, member, callback, oldRating }) => {
  const [ratingValue, setRatingValue] = useState(0);
  const [subRating, setSubRating] = useState(oldRating || 0);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const modal = useModal();
  const dispatch = useDispatch();

  const handleRating = (rate) => {
    setRatingValue(rate);
  };

  const handleModalClose = () => {
    modal.hideModal();
  };

  const handleSubmit = () => {
    setLoading(true);
    dispatch(
      rateMember({
        chat_id: chatId,
        rating: ratingValue,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        setLoading(false);
        if (result.status) {
          callback && callback();
          setSubRating(ratingValue);
          modal.hideModal();
          toast.success(t('features:chat.rating.successMessage'));
        } else {
          throw new Error('Error');
        }
      })
      .catch(() => {
        modal.hideModal();
        toast.error(t('main:errors.somethingWentWrong'));
      });
  };

  if (subRating) {
    return (
      <Rating
        readonly
        initialValue={subRating}
        fillColor="var(--turg)"
        size={18}
      />
    );
  }

  return (
    <>
      <Button onClick={() => modal.showModal()} name="btn">
        {t('features:chat.rating.button')}
      </Button>
      <modal.RenderModal
        title={t('features:chat.rating.modal.title')}
        buttonClose
        onClose={handleModalClose}
      >
        <div className={styles.ratingsContent}>
          {loading ? (
            <LoaderRelative />
          ) : (
            <>
              <div className={styles.subTitle}>
                <div>
                  {t('features:chat.rating.modal.subTitle')}
                  <strong>{member?.name}</strong>?
                </div>
                {t('features:chat.rating.modal.note')}
              </div>
              <div className={styles.ratingsWrapper}>
                <Rating
                  onClick={handleRating}
                  initialValue={ratingValue}
                  fillColor="var(--turg)"
                />
              </div>
              <Button
                onClick={handleSubmit}
                disabled={ratingValue === 0}
                fullWidth
              >
                {t('features:chat.rating.modal.button')}
              </Button>
            </>
          )}
        </div>
      </modal.RenderModal>
    </>
  );
};

export default ChatFeedback;
