import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import formatDuration from 'date-fns/formatDuration';
import { useTranslation } from 'react-i18next';
import { de, enGB  } from 'date-fns/locale';

const Timer = ({ startTime, handleExpire, showSeconds, addHours }) => {
  const [timeLeft, setTimeLeft] = useState('');
  const [isExpired, setIsExpired] = useState(false);
  const { t, i18n } = useTranslation();
  const lang = i18n.language === 'de' ? de : enGB;

  const calculateTimeLeft = () => {
    if (isExpired) {
      return null;
    }
    let today = new Date();
    today.setHours(today.getHours() + addHours);

    const difference = startTime - today;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: showSeconds || (!days && !hours) ? seconds : null,
      };
    } else {
      setIsExpired(true);
      if (handleExpire) {
        handleExpire();
      }
    }

    return timeLeft;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return timeLeft !== null
    ? formatDuration(timeLeft, { locale: lang })
    : t('components:timer.Expired', 'Expired');
};

Timer.propTypes = {
  startTime: PropTypes.instanceOf(Date).isRequired,
  handleExpire: PropTypes.func,
  showSeconds: PropTypes.bool,
  addHours: PropTypes.number,
};

Timer.defaultProps = {
  handleExpire: () => console.log('Expired!'),
  showSeconds: true,
  addHours: 0,
};

export default Timer;
