import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import api from 'app/api';
import Button from 'components/buttons/Button';
import { FormRow, TextArea } from 'components/forms';
import ReportReasonsSelect from 'components/forms/ReportReasonsSelect';
import { LoaderRelative } from 'components/layout/loader';
import { getReportReasons, selectReportReasons } from 'app/miscSlice';
import styles from './profileActions.module.css';

const BlockUserModal = ({
  modalContext: { RenderModal, hideModal },
  profileUser,
}) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setloading] = useState(false);
  const { t } = useTranslation();

  const user_id = profileUser.uid || profileUser.id;

  const onBlock = async () => {
    setloading(true);
    try {
      const res = await api.get(`v2/user/actions/block/${user_id}`);
      if (!res.data.status) {
        throw new Error('block user error');
      }

      setSuccess(true);
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
      setError(true);
    }
  };

  if (error) {
    return (
      <RenderModal title={t('features:blockReport.blockMember')} buttonClose>
        <div className={styles.reportReasons}>
          <p>{t('features:blockReport.error')}</p>
          <br />
          <Button onClick={() => hideModal()} name="btn">
            {t('features:blockReport.OK')}
          </Button>
        </div>
      </RenderModal>
    );
  }

  if (loading) {
    return (
      <RenderModal title={t('features:blockReport.blockMember')} buttonClose>
        <LoaderRelative />
      </RenderModal>
    );
  }

  if (success) {
    return (
      <RenderModal title={t('features:blockReport.blockMember')} buttonClose>
        <div className={styles.reportReasons}>
          <p>
            {t('features:blockReport.successBlock', {
              userName: profileUser.name,
            })}
          </p>
          <br />
          <Button onClick={() => hideModal()} name="btn">
            {t('features:blockReport.OK')}
          </Button>
        </div>
      </RenderModal>
    );
  }

  return (
    <RenderModal
      title={t('features:blockReport.blockMember')}
      buttonClose
      footer={
        <>
          <Button
            onClick={() => hideModal()}
            name="btn-grey"
            style={{ width: '48%' }}
          >
            {t('main:main.Cancel')}
          </Button>
          <Button onClick={onBlock} name="btn" style={{ width: '48%' }}>
            {t('features:blockReport.Block')}
          </Button>
        </>
      }
    >
      <p className={styles.copy}>
        {t('features:blockReport.confirmBlock', { userName: profileUser.name })}
      </p>
    </RenderModal>
  );
};

const ReportUserModal = ({
  modalContext: { RenderModal, hideModal },
  profileUser,
}) => {
  const [success, setSuccess] = useState(false);
  const [loading, setloading] = useState(false);
  const [error, setError] = useState(false);
  const reportReasons = useSelector(selectReportReasons);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user_id = profileUser.uid || profileUser.id;

  const onReport = async (values) => {
    setloading(true);

    const url = 'v2/user/actions/report/create';
    const payload = {
      object_id: user_id,
      object_type: 1,
      reasons: values.reasons,
      description: values.description,
    };

    try {
      const res = await api.post(url, payload);
      if (!res.data.status) {
        throw new Error('report user error');
      }
      setSuccess(true);
      setloading(false);
    } catch (error) {
      setError(true);
      setloading(false);
    }
  };

  useEffect(() => {
    if (isEmpty(reportReasons)) {
      dispatch(getReportReasons());
    }
  }, [dispatch, reportReasons]);

  if (error) {
    return (
      <RenderModal title={t('features:blockReport.reportMember')} buttonClose>
        <div className={styles.reportReasons}>
          <p> {t('features:blockReport.error')}</p>
          <br />
          <Button onClick={() => hideModal()} name="btn">
            {t('features:blockReport.OK')}
          </Button>
        </div>
      </RenderModal>
    );
  }

  if (loading) {
    return (
      <RenderModal title={t('features:blockReport.reportMember')} buttonClose>
        <LoaderRelative />
      </RenderModal>
    );
  }

  if (success) {
    return (
      <RenderModal title={t('features:blockReport.reportMember')} buttonClose>
        <div className={styles.reportReasons}>
          <p>
            {t('features:blockReport.successReport', {
              userName: profileUser.name,
            })}
          </p>
          <br />
          <Button onClick={() => hideModal()} name="btn">
            OK
          </Button>
        </div>
      </RenderModal>
    );
  }

  return (
    <RenderModal title={t('features:blockReport.reportMember')} buttonClose>
      <p className={styles.copy}>
        {t('features:blockReport.reportReason', { userName: profileUser.name })}
      </p>

      <div className={styles.reportReasons}>
        <Formik
          initialValues={{ reasons: [], description: '' }}
          validationSchema={Yup.object().shape({
            reasons: Yup.array()
              .min(1, t('components:validation.required'))
              .required(t('components:validation.required')),
            description: Yup.string().required(
              t('components:validation.required')
            ),
          })}
          onSubmit={async (values) => {
            await onReport(values);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormRow>
                <ReportReasonsSelect
                  typeName="reasons"
                  options={reportReasons}
                  withLabel
                  required
                />
              </FormRow>
              <FormRow>
                <TextArea
                  placeholder={t('features:blockReportReason.writeReason')}
                  name="description"
                  label={t('features:blockReportReason.commentLabel')}
                  required
                ></TextArea>
              </FormRow>

              <div className={styles.customReasonBtns}>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  name="btn"
                  fullWidth
                  size="xl"
                >
                  {t('features:blockReport.Report')}
                </Button>
                <Button
                  onClick={() => {
                    hideModal();
                  }}
                  type="button"
                  name="btn-grey"
                  fullWidth
                  size="xl"
                >
                  {t('main:main.Cancel')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </RenderModal>
  );
};

const UnfollowModal = ({
  userName,
  onConfirmUnfollow,
  modalContext: { RenderModal, hideModal },
}) => {
  const { t } = useTranslation();

  const handleAction = () => {
    hideModal();
    onConfirmUnfollow();
    window.scrollTo(0, 0);
  };

  return (
    <RenderModal
      title={t('features:profileInfo.unfollowMember')}
      buttonClose
      footer={
        <>
          <Button
            onClick={() => hideModal()}
            name="btn-grey"
            style={{ width: '48%' }}
          >
            {t('main:main.Cancel')}
          </Button>
          <Button onClick={handleAction} name="btn" style={{ width: '48%' }}>
            {t('features:profileInfo.unfollow')}
          </Button>
        </>
      }
    >
      <p>
        {t('features:profileInfo.confirmUnfollow', {
          userName: userName,
        })}
      </p>
    </RenderModal>
  );
};

const DisconnectModal = ({
  userName,
  onConfirmDisconnect,
  modalContext: { RenderModal, hideModal },
}) => {
  const { t } = useTranslation();

  const handleAction = () => {
    hideModal();
    onConfirmDisconnect(3);
    window.scrollTo(0, 0);
  };

  return (
    <RenderModal
      title={t('features:profileInfo.disconnectMember')}
      buttonClose
      footer={
        <>
          <Button
            onClick={() => hideModal()}
            name="btn-grey"
            style={{ width: '48%' }}
          >
            {t('main:main.Cancel')}
          </Button>
          <Button onClick={handleAction} name="btn" style={{ width: '48%' }}>
            {t('features:profileInfo.Disconnect')}
          </Button>
        </>
      }
    >
      <p>
        {t('features:profileInfo.confirmDisconnect', {
          userName: userName,
        })}
      </p>
    </RenderModal>
  );
};

const CancelConnectModal = ({
  userName,
  onConfirmDisconnect,
  modalContext: { RenderModal, hideModal },
}) => {
  const { t } = useTranslation();

  const handleAction = () => {
    hideModal();
    onConfirmDisconnect(2);
    window.scrollTo(0, 0);
  };

  return (
    <RenderModal
      title={t('features:profileInfo.cancelConnectionRequest')}
      buttonClose
      footer={
        <>
          <Button
            onClick={() => hideModal()}
            name="btn-grey"
            style={{ width: '48%' }}
          >
            {t('main:main.no')}
          </Button>
          <Button onClick={handleAction} name="btn" style={{ width: '48%' }}>
            {t('main:main.yes')}
          </Button>
        </>
      }
    >
      <p>
        {t('features:profileInfo.confirmCancelConnectionRequest', {
          userName: userName,
        })}
      </p>
    </RenderModal>
  );
};

export { BlockUserModal, ReportUserModal, UnfollowModal, DisconnectModal, CancelConnectModal };
