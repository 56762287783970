import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'app/api';

export const getSuggestions = createAsyncThunk(
  'suggestions/getSuggestions',
  async (params) => {
    const ver = params.isPublic ? 'guests' : 'v2';
    const url = params.id
      ? `${ver}/suggestions/${params.id}`
      : `${ver}/suggestions`;
    const response = await api.get(url);
    return { ...response.data };
  }
);
export const getSuggestionsMore = createAsyncThunk(
  'suggestions/getSuggestionsMore',
  async (next) => {
    const response = await api.get(next);
    return response.data;
  }
);

const initialState = {
  status: {
    loading: false,
    hasError: false,
  },
  results: [],
  currentPage: 0,
  totalResults: 0,
  next: false,
  query: null,
  canShow: false,
};

export const suggestions = createSlice({
  name: 'suggestions',
  initialState: initialState,
  reducers: {
    setShowSuggestions: (state) => {
      state.canShow = true;
    },
    resetShowSuggestions: (state) => {
      state.canShow = false;
    },
    cleanSuggestions: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [getSuggestions.pending]: (state, action) => {
      state.status.loading = true;
      state.status.hasError = false;
    },
    [getSuggestions.fulfilled]: (state, action) => {
      state.results = action.payload.data.result;
      state.next = action.payload.data.next;
      state.totalResults = action.payload.data.total;
      state.query = action.payload.query;
      state.status.loading = false;
      state.status.hasError = false;
    },
    [getSuggestions.rejected]: (state, action) => {
      state.status.loading = false;
      state.status.hasError = true;
    },
    [getSuggestionsMore.pending]: (state, action) => {
      state.status.loading = true;
      state.status.hasError = false;
    },
    [getSuggestionsMore.fulfilled]: (state, action) => {
      state.results = [...state.results, ...action.payload.data.result];
      state.next = action.payload.data.next;
      state.totalResults = action.payload.data.total;
      state.status.loading = false;
      state.status.hasError = false;
    },
    [getSuggestionsMore.rejected]: (state, action) => {
      state.status.loading = false;
      state.status.hasError = true;
    },
  },
});

export const {
  setShowSuggestions,
  resetShowSuggestions,
  cleanSuggestions,
} = suggestions.actions;

export const selectSuggestions = (state) => state.suggestions.results;
export const selectisFetching = (state) => state.suggestions.status.loading;
export const selectSuggestionsNext = (state) => state.suggestions.next;
export const selectSuggestionsShow = (state) => state.suggestions.canShow;

export default suggestions.reducer;
