import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { StreamApp, FlatFeed, Activity } from 'react-activity-feed';

import { getFeedPublic } from './feedSlice';
import ActivityFeedHeader from './activityDetail/Header';
import ActivityFeedContent from './activityDetail/Content';
// import ActivityFeedFooter from './activityDetail/Footer';
import { LoaderInline } from 'components/layout/loader';

const ActivityFeedPublic = ({ profileUser, publicModal }) => {
  const { hash_id } = useParams();
  const dispatch = useDispatch();

  const getFeeds = async (client, feedGroup, userId, options) => {
    const response = await dispatch(getFeedPublic({ hash_id: hash_id }));
    return unwrapResult(response);
  };

  return (
    <StreamApp
      apiKey={process.env.REACT_APP_STREAM_APP_KEY}
      appId={process.env.REACT_APP_STREAM_APP_ID}
      token={profileUser.feed_token}
    >
      <FlatFeed
        feedGroup="user"
        doFeedRequest={getFeeds}
        LoadingIndicator={<LoaderInline />}
        Activity={(props) => (
          <Activity
            {...props}
            Header={<ActivityFeedHeader publicModal={publicModal} {...props} />}
            Content={
              <ActivityFeedContent publicModal={publicModal} {...props} />
            }
            // Footer={<ActivityFeedFooter publicModal={publicModal} {...props} />}
          />
        )}
      />
    </StreamApp>
  );
};

export default ActivityFeedPublic;
