import React from 'react';

const ProgressContext = React.createContext({
  currentId: 0,
  videoDuration: 0,
  bufferAction: false,
  pause: false,
  next: () => {},
});

export default ProgressContext;
