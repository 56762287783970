import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PhotoSwipe } from 'react-photoswipe';
import cx from 'classnames';

import {
  getFeedMediaSrc,
  getFeedMediaPreviewSrc,
  getVideoThumbSrc,
} from 'helpers/s3media';
// import { getVideoMimeTypeByFormat } from 'helpers/videoMimeType';
import { secToMin } from 'helpers/timeAdaptors';
import VideoPlayer from 'components/video/VideoPlayer';
import { IconPhoto, IconVideo } from 'assets/icons';
import styles from '../activityDetail.module.css';

SwiperCore.use([Navigation]);

const ActivityFeedMedia = ({ files, stats, videosDuration }) => {
  const [filesIndex, setFilesIndex] = useState(0);
  const [toggler, setToggler] = useState(false);

  const handleSlideChange = (instance) => {
    setFilesIndex(instance.activeIndex);
    document.querySelectorAll('video').forEach((vid) => {
      if (typeof vid.pause === 'function') {
        vid.pause();
      }
    });
  };

  const handleSlideClick = (file, skipVideo = false) => {
    if (!skipVideo || file.type !== 'video') {
      setToggler(true);
    }
  };

  const handleOnClose = () => {
    document.querySelectorAll('video').forEach((vid) => {
      if (typeof vid.pause === 'function') {
        vid.pause();
        vid.currentTime = 0;
      }
    });
    setToggler(false);
  };

  const createLbItems = () => {
    let items = [];
    files.forEach((file) => {
      const poster = getVideoThumbSrc(file);
      const file_src = getFeedMediaSrc(file);
      // const file_mime = getVideoMimeTypeByFormat(file.format);
      if (file.type === 'video') {
        items.push({
          html: `<div class="pswp__custom_item custom_item">
                      <video controls disablePictureInPicture controlslist="nodownload" playsinline poster="${poster}">
                        <source src="${file_src}">
                        Your browser does not support the video tag.
                      </video>
                  </div>`,
        });
      } else {
        items.push({
          html: `<div class="pswp__custom_item custom_item">
                    <img src="${file_src}" alt="" />
                  </div>`,
        });
      }
    });
    return items;
  };

  return (
    <div className={styles.mediaWrapper}>
      {files.length > 1 ? (
        <Swiper
          navigation={files.length > 1}
          slidesPerView={1}
          onSlideChange={(swiper) => handleSlideChange(swiper)}
        >
          {files.map((file, i) => (
            <SwiperSlide key={i} onClick={() => handleSlideClick(file)}>
              {file.type === 'video' ? (
                <div className={styles.videoItem}>
                  <img
                    src={getFeedMediaPreviewSrc(file, {
                      width: process.env.REACT_APP_FILE_WIDTH_BIG,
                      height: process.env.REACT_APP_FILE_WIDTH_BIG,
                      fit: 'cover',
                    })}
                    className={styles.imgResponsive}
                    alt=""
                  />
                  <span className={styles.overlayVideo}>
                    <span className={styles.play} />
                  </span>
                </div>
              ) : (
                <img
                  src={getFeedMediaPreviewSrc(file, {
                    width: process.env.REACT_APP_FILE_WIDTH_BIG,
                    height: process.env.REACT_APP_FILE_WIDTH_BIG,
                    fit: 'cover',
                  })}
                  className={styles.imgResponsive}
                  alt=""
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div
          // className="swiper-slide"
          onClick={() => handleSlideClick(files[0], true)}
        >
          {files[0].type === 'video' ? (
            <VideoPlayer
              file={files[0]}
              src={getFeedMediaSrc(files[0])}
              poster={getFeedMediaPreviewSrc(files[0], {
                width: files[0].width,
                height: files[0].height,
                fit: 'cover',
              })}
            />
          ) : (
            <img
              src={getFeedMediaSrc(files[0])}
              className={styles.imgResponsive}
              alt=""
            />
          )}
        </div>
      )}
      {(stats.num_images > 1 || stats.num_videos > 1) && (
        <span
          className={cx(styles.metaUnlocked, {
            [styles.single]: stats.num_images < 1 || stats.num_videos < 1,
          })}
        >
          {stats.num_images > 0 && (
            <span className={styles.metaItem}>
              <IconPhoto className={styles.metaIcon} /> {stats.num_images}
            </span>
          )}
          {stats.num_videos > 0 && (
            <span className={styles.metaItem}>
              <IconVideo className={styles.metaIcon} />{' '}
              {secToMin(videosDuration)}
            </span>
          )}
        </span>
      )}
      <PhotoSwipe
        options={{
          bgOpacity: 0.5,
          index: filesIndex,
          closeElClasses: [
            'item',
            'custom_item',
            'caption',
            'zoom-wrap',
            'ui',
            'top-bar',
          ],
          fullscreenEl: false,
          zoomEl: true,
          shareEl: false,
          counterEl: false,
          arrowEl: true,
          preloaderEl: true,
          history: false,
        }}
        isOpen={toggler}
        items={createLbItems()}
        onClose={handleOnClose}
      />
    </div>
  );
};

ActivityFeedMedia.propTypes = {
  files: PropTypes.array.isRequired,
  stats: PropTypes.object.isRequired,
  videosDuration: PropTypes.array,
};

export default ActivityFeedMedia;
