import React from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'components/layout/grid';
import Layout from 'components/layout/layouts/Layout';
import CreatePostView from 'features/feed/activityCreate/CreatePostView';

const CreatePostScreen = () => {
  const { t } = useTranslation();
  const screenName = t('features:episodeList.newPost');

  return (
    <Layout noBorderHeader mobilePadding>
      <Row>
        <Col xs={12}>
          <CreatePostView screenName={screenName} />
        </Col>
      </Row>
    </Layout>
  );
};

export default CreatePostScreen;
