import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import ProgressRing from 'components/progressRing';
import { Button } from 'components/buttons';
import { IconCross, IconCheck } from 'assets/icons';
import styles from './create.module.css';

const VideoUploadProgress = React.memo((props) => {
  const { percentage, status, closeAction } = props;
  const { t } = useTranslation();

  const domEl = document.getElementById('loader-root');
  if (!domEl) return null;

  const Title = () => {
    switch (status) {
      case 'success':
        return t('features:stories.videoUploader.success.title');
      case 'error':
        return t('features:stories.videoUploader.error.title');
      default:
        return t('features:stories.videoUploader.loading.title');
    }
  };

  const Message = () => {
    switch (status) {
      case 'success':
        return t('features:stories.videoUploader.success.message');
      case 'error':
        return t('features:stories.videoUploader.error.message');
      default:
        return '';
    }
  };

  const progressColor =
    status === 'success'
      ? '#6093a8'
      : status === 'error'
      ? '#66000c'
      : '#0E1013';

  const progressIcon =
    status === 'success' ? (
      <IconCheck className={styles.progressIcon} />
    ) : status === 'error' ? (
      <IconCross className={styles.progressIcon} />
    ) : null;

  return ReactDOM.createPortal(
    <div className={styles.progressWrapper}>
      <span className={styles.progressRing}>
        <ProgressRing
          animated
          percentage={percentage}
          color={progressColor}
          hideText={status !== 'loading'}
        />
        {progressIcon}
      </span>
      <span className={styles.progressMessage}>
        <h2>
          <Title />
        </h2>
        <p>
          <Message />
        </p>
      </span>
      {status === 'error' && (
        <Button
          fullWidth
          size="xl"
          onClick={() => closeAction()}
          style={{ maxWidth: '250px' }}
        >
          {t('features:stories.videoUploader.error.button')}
        </Button>
      )}
    </div>,
    domEl
  );
});

export default VideoUploadProgress;
