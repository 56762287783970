import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../app/api';

export const getBookings = createAsyncThunk(
  'applications/getBookings',
  async (params,{rejectWithValue}) => {
    const response = await api.get(`v2/club_booking/my_booking`);
    if (!response.data.status) {
      return rejectWithValue(response.data.error);
    }
    return {...response.data};
  }
);

export const bookingsSlice = createSlice({
  name: 'bookings',
  initialState: {
    unreadCount: 0,
    bookings: {
      loading: false,
      hasError: false,
      success: false,
      data: [],
    },
  },
  reducers: {
    // setNewbookingsCount: (state, action) => {
    //   state.unreadCount = action.payload;
    // },
    resetNewBookingsCount: (state) => {
      state.unreadCount = 0;
    },
    incrementNewBookingsCount: (state) => {
      state.unreadCount += 1;
    },
  },
  extraReducers: {
    [getBookings.pending]: (state, action) => {
      state.bookings.loading = true;
      state.bookings.hasError = false;
      state.bookings.success = false;
    },
    [getBookings.fulfilled]: (state, action) => {
      state.bookings.loading = false;
      if (action.payload.status) {
        state.bookings.success = true;
        state.bookings.data = action.payload.data;
      } else {
        state.bookings.hasError = true;
      }
    },
    [getBookings.rejected]: (state, action) => {
      state.bookings.loading = false;
      state.bookings.hasError = true;
    },
  },
});

export const {
  // setNewBookingsCount,
  resetNewBookingsCount,
  incrementNewBookingsCount,
} = bookingsSlice.actions;

export const selectNewBookingsCount = (state) =>
  state.bookings.unreadCount;
export const selectBookings = (state) =>
  state.bookings.bookings.data;
export const selectFetched = (state) =>
  state.bookings.bookings.success ||
  state.bookings.bookings.hasError;

export default bookingsSlice.reducer;
