import {useState, useEffect, useRef, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Field, Form, Formik} from 'formik';
import {isEmpty} from 'lodash';
import AWS from 'aws-sdk';
import shortId from 'short-uuid';

import {toast} from 'react-toastify';
import {FilePond, registerPlugin} from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import KycOndatoMessage from 'features/settings/wallet/kyc/KycOndatoMessage';
import Button from 'components/buttons/Button';
import SubHeader from 'components/layout/subHeader/SubHeader';
import {useModal} from 'components/modal/useModal';
import InfoBar from 'components/infoBar/InfoBar';
import ConfirmFreeContent from './ConfirmFreeContent';
import {createPost, setTempFile} from '../feedSlice';
import {
    selectUserInfo,
    updateUser,
    selectKYCstatus,
} from 'features/auth/authSlice';
import {getFileMeta} from 'helpers/mediaFileMeta';

import style from './createPost.module.css';
//import PriceRangeInput from './PriceRangeInput';
import {
    selectFilters,
    selectFeedCategories
} from 'app/miscSlice';
import CategoriesSelect from 'components/forms/CategoriesSelect';
import {IconAddMedia, IconInfo} from "../../../assets/icons";

const SubscriptionPriceMessage = () => {
    const {t} = useTranslation();
    const {subscription} = useSelector(selectUserInfo);

    if (!subscription.has_subscription || !subscription.accepted_conditions)
        return null;

    return (
        <InfoBar
            badge={t('features:subscriptions.createPost.createPostInfoNew')}
            info={
                <strong>
                    {t(t('features:subscriptions.createPost.createPostInfoDesc'))}
                </strong>
            }
        />
    );
};

registerPlugin(
    FilePondPluginImagePreview,
    FilePondPluginMediaPreview,
    FilePondPluginImageExifOrientation,
    FilePondPluginFileValidateType
);

function CreatePostView({screenName}) {
    const [files, setFiles] = useState([]);
    const [validCount, setValidCount] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [price] = useState(0);
    const [showMediaMessage, setMediaMessage] = useState(false);
    //const [submitMediaMessage, setSubmitMediaMessage] = useState(false);
    /*const [videoLCount, setVideoLCount] = useState(0);
    const [videoError, setVideoError] = useState(false);
    const [photoLCount, setPhotoLCount] = useState(0);
    const [photoError, setPhotoError] = useState(false);*/
    const [tempID, setTempID] = useState('');
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    const user = useSelector(selectUserInfo);
    const kyc_status = useSelector(selectKYCstatus);
    const setPriceModal = useModal();
    const confirmFreeContent = useModal();
    const dispatch = useDispatch();
    const history = useHistory();
    const pond = useRef(null);

    const isUploadDisabled = process.env.REACT_APP_DISABLE_UPLOAD === 'true';

    const filters = useSelector(selectFilters);
    const categories = useSelector(selectFeedCategories);

    AWS.config.update({
        region: process.env.REACT_APP_S3_BUCKET_REGION,
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.REACT_APP_S3_POOL_ID,
        }),
    });

    useEffect(() => {
        const timestamp = new Date().getTime();
        setTempID(timestamp);
    }, []);

    useEffect(() => {
        if (validCount < 1) {
            //setPrice(0);
        } else {
            setMediaMessage(false);
        }
    }, [validCount]);

    /*useEffect(() => {

        setPhotoError(false);
        setSubmitMediaMessage(false);

        if ((photoLCount > 0 && photoLCount < 3) && videoLCount === 0) {
            setPhotoError(true);
            setSubmitMediaMessage(true);
        }

        if (photoLCount >= 3 || videoLCount >= 1) {
            setValidCount(photoLCount + videoLCount)
        }

    }, [videoLCount, photoLCount]);

    useEffect(() => {

        setVideoError(false);
        setSubmitMediaMessage(false);

        files.forEach(function (item, index) {
            (async () => {
                const fileMeta = await getFileMeta(item.file);
                if (!fileMeta) {
                    //return error();
                }
                if (fileMeta.type === 'video' && fileMeta.duration < 30) {
                    setVideoError(true);
                    setSubmitMediaMessage(true);

                }
            })();
        });

    }, [files])*/

    const uploadS3 = useCallback(
        () => ({
            process: async (
                fieldName,
                file,
                metadata,
                load,
                error,
                progress,
                abort
            ) => {
                const fileMeta = await getFileMeta(file);
                if (!fileMeta) {
                    return error();
                }

                const fileExt = file.name.split('.').pop();
                const fileId = `models/${user.uid}/${shortId.generate()}`;
                const s3Key = `${fileId}.${fileExt}`;
                let fileInfo = {
                    duration: fileMeta.duration,
                    width: fileMeta.width,
                    height: fileMeta.height,
                    type: fileMeta.type,
                    size: file.size,
                    format: fileExt,
                    public_id: fileId,
                };

                /*if (fileMeta.type === 'video' && fileMeta.duration < 30) {
                    console.log('abort');
                    abort();
                    return false;
                }*/

                const upload = new AWS.S3.ManagedUpload({
                    params: {
                        Bucket: process.env.REACT_APP_S3_BUCKET_NAME_UPLOAD,
                        Key: s3Key,
                        Body: file,
                        ContentType: "image/jpg, image/png, image/jpeg",
                    },
                });
                upload.on('httpUploadProgress', (e) => {
                    progress(true, e.loaded, e.total);
                });

                const promise = upload.promise();
                promise.then(
                    function (data) {
                        fileInfo.s3_data = data;
                        load({body: fileInfo});
                        dispatch(
                            setTempFile({
                                temp_id: `${user.uid}_${tempID}`,
                                file_info: fileInfo,
                            })
                        );
                    },
                    function (err) {
                        return error(err.message);
                    }
                );

                return {
                    abort: () => {
                        console.log('abort');
                        abort();
                    },
                };
            },
            revert: null,
        }),
        [dispatch, tempID, user.uid]
    );

    const getPostFiles = () => {
        let validFiles = [];
        files.forEach(function (item, index) {
            if (item.serverId) {
                validFiles.push(item.serverId);
            }
        });
        return validFiles;
    };

    const getPriceValue = () => {
        if (validCount < 1) {
            return 0;
        }
        //console.log(price);
        return price;
        //return price.numAsString || 0;
    };

    const submitDisabled = (value) => {
        return (
            (isEmpty(value.trim().replace(/(<([^>]+)>)/gi, '')) && !validCount) ||
            isUploading
        );
    };

    const handleReorder = (items, origin, target) => {
        setFiles(items);
    };

    const handleSetFiles = (items) => {
        const newCount = items.filter((obj) => obj.status === 5).length;
        setFiles(items);
        setValidCount(newCount);
    };

    /*const handleCallback = (childData) =>{
        //console.log(childData,'me');
        setPrice(childData);
    }*/

    /*const handleRemoveFile = (error, file) => {
        (async () => {
            const fileMeta = await getFileMeta(file.file);
            if (!fileMeta) {
                //return error();
            }
            if (fileMeta.type === 'video') {
                setVideoLCount(prevCount => prevCount - 1);
            }

            if (fileMeta.type === 'image') {
                setPhotoLCount(prevCount => prevCount - 1);
            }
        })();
    };*/

    const handleProcessFile = (error, file) => {
        const newCount = file.status === 5 ? validCount + 1 : validCount;
        setValidCount(newCount);

        /*if (file.status === 5) {
            (async () => {
                const fileMeta = await getFileMeta(file.file);
                if (!fileMeta) {
                    //return error();
                }
                if (fileMeta.type === 'video') {
                    setVideoLCount(prevCount => prevCount + 1);
                }

                if (fileMeta.type === 'image') {
                    setPhotoLCount(prevCount => prevCount + 1);
                }
            })();
        }*/
    };

    const handleProcessFileStart = (file) => {
        setIsUploading(true);
    };

    const handleProcessFiles = () => {
        setIsUploading(false);
    };

    /*const handlePriceModal = () => {
      if (validCount < 1) {
        setMediaMessage(true);
      } else {
        setPriceModal.showModal();
      }
    };*/

    /*const handlePriceSubmit = (price) => {
      let newValue = null;
      if (price.numAsString) {
        newValue = price;
      }
      setPrice(newValue);
      setPriceModal.hideModal();
    };*/

    const handleTextareaRows = (text, initialRows, maxRows) => {
        const rows = initialRows + (text.match(/\n/g) || []).length;
        return Math.min(rows, maxRows);
    };

    const handlePostSubmit = (values) => {
        const text = values.feedText.trim().replace(/(<([^>]+)>)/gi, '');
        const media = getPostFiles();
        const price = getPriceValue();
        const id = `${user.uid}_${tempID}`;
        const categories = values.feedCategories;

        //console.log(id, text, price, media, validCount, categories);

        dispatch(createPost({id, text, price, media, categories}))
            .then(unwrapResult)
            .then((result) => {
                if (result.status) {
                    dispatch(updateUser())
                        .then(unwrapResult)
                        .then((result) => {
                            if (result.status) {
                                history.push('/profile');
                            }
                        });
                } else {
                    setLoading(false);
                    toast.error(t('main:errors.somethingWentWrong'));
                }
            });
    };

    const textareaPlaceholder = validCount
        ? t('features:createPost.addDescription', 'Add a description...')
        : t('features:createPost.composePost', 'Compose new post...');

    /*const pricePlaceholder = !isEmpty(price)
      ? t('features:createPost.Post price: ', 'Post price: ')
      : t('features:createPost.setPrice', 'Set price ');
    */

    /*const submitValidation = (noNeedToConfirmCallback)  => {
      confirmIfNoPrice(noNeedToConfirmCallback);
    };*/

    const confirmIfNoPrice = (noNeedToConfirmCallback) => {
        /*if (validCount && price === 0) {
            confirmFreeContent.showModal();
        } else {*/
            setLoading(true);
            noNeedToConfirmCallback();
        //}
    };

    if (isUploadDisabled) {
        return (
            <>
                <SubHeader backBtn screenName={screenName}/>
                <InfoBar badge={'INFO'} info={t('main:uploadDisabled.text')}/>
            </>
        );
    }

    if (kyc_status !== 3) {
        return (
            <>
                <SubHeader backBtn screenName={screenName}/>
                <KycOndatoMessage type="post" button/>
            </>
        );
    }

    return (
        <Formik
            initialValues={{
                feedText: '',
            }}
            onSubmit={async (values) => {
                setLoading(true);
                await handlePostSubmit(values);
            }}
        >
            {(props) => (
                <>
                    <SubHeader backBtn screenName={screenName}>

                    </SubHeader>
                    <SubscriptionPriceMessage/>
                    {showMediaMessage && (
                        <span className={style.priceMessage}>
                            <IconInfo/>
                            {t('features:createPost.attachmentsWarning')}
                        </span>
                    )}

                    <div className="createPostForm">
                        <Form>
                            <label className={`${style.formLabel} ${style.required}`} htmlFor="feedText">
                                {t('features:createPost.postDescription', 'Post Description ')}
                            </label>

                            <Field
                                placeholder={textareaPlaceholder}
                                component="textarea"
                                id="feedText"
                                name="feedText"
                                maxLength={1000}
                                rows={handleTextareaRows(props.values.feedText, 5, 10)}
                                style={style.textarea}
                            />

                            <label className={`${style.formLabel}`} htmlFor="btn-bare">
                                {t('features:createPost.mediaFiles', 'Media Files')}
                            </label>
                            {/*<div
                                className={`${style.postActions} ${
                                  !isEmpty(price) ? style.withPrice : ''
                                }`}
                              >
                                <span>
                                  <Button
                                    onClick={handlePriceModal}
                                    type="button"
                                    name="btn-bare"
                                  >
                                    <IconAddPrice className={style.icon} />
                                    <span className={style.actionText}>{pricePlaceholder}</span>
                                    {!isEmpty(price) && (
                                      <>
                                        <span
                                          className={style.priceValue}
                                        >{`${price.value}`}</span>
                                        <span
                                          className={style.resetPrice}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setPrice({});
                                          }}
                                        ></span>
                                      </>
                                    )}
                                  </Button>
                                </span>
                        </div>*/}

                        </Form>

                        <div className={`maleImages`}>
                            <FilePond
                                ref={pond}
                                dropOnPage
                                dropOnElement={false}
                                allowReorder
                                allowMultiple
                                files={files}
                                onupdatefiles={handleSetFiles}
                                onreorderfiles={handleReorder}
                                onprocessfile={handleProcessFile}
                                onprocessfilestart={handleProcessFileStart}
                                onprocessfiles={handleProcessFiles}
                                //onremovefile={handleRemoveFile}
                                credits={false}
                                itemInsertLocation="after"
                                imagePreviewHeight="300px"
                                allowFileTypeValidation
                                acceptedFileTypes={[
                                    'image/*',
                                    'video/mp4',
                                    'video/ogg',
                                    'video/quicktime',
                                    'video/x-matroska',
                                    'video/mpeg',
                                    'video/webm',
                                    'video/x-flv',
                                ]}
                                fileValidateTypeLabelExpectedTypes=""
                                labelFileTypeNotAllowed={t(
                                    'features:createPost.invalidFile',
                                    'File not valid!'
                                )}
                                labelIdle={t(
                                    'features:images.dragdrop',
                                    'Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                )}
                                server={uploadS3()}
                                // onactivatefile={(file) => console.log(file.serverId)}
                            />

                            <div className={`${style.postActions}`}>
                                <span>
                                    <Button
                                       onClick={() => pond.current && pond.current.browse()}
                                       type="button"
                                       name="btn-bare"
                                   >
                                            <IconAddMedia className={style.icon}/>
                                            <span className={style.actionText}>
                                              {t('features:createPost.addMedia', 'Add media ')}
                                            </span>
                                          </Button>
                                    </span>
                            </div>

                            {/*<InfoBar
                                info={
                                    <div dangerouslySetInnerHTML={{__html: t('features:createPost.contentWarning') }}>
                                    </div>
                                }
                            />*/}

                        </div>

                        {/*<label className={`${style.formLabel}`} htmlFor="btn-bare">
                            {t('features:createPost.setPrice', 'Set Price')}
                        </label>

                        <PriceRangeInput name="price" handleCallback={handleCallback}/>*/}

                        {/*<label className={`${style.formLabel}`} htmlFor="btn-bare">
                            {t('features:createPost.categories', 'Categories')}
                        </label>*/}

                        <CategoriesSelect
                            withLabel
                            options={categories}
                            typeName="feedCategories"
                            initOptions={filters.feedCategories}
                        />

                    </div>
                    { /*<PostPriceModal
                        modalContext={setPriceModal}
                        price={price}
                        onSave={handlePriceSubmit}
                      />*/}
                    <ConfirmFreeContent
                        onConfirm={props.handleSubmit}
                        onSetPrice={() => {
                            confirmFreeContent.hideModal();
                            setPriceModal.showModal();
                        }}
                        modalContext={confirmFreeContent}
                    />
                    <Button
                        size="xl"
                        name="btn-gradient"
                        context="header"
                        type="button"
                        style={{ 'marginTop':'30px','width':'180px'}}
                        onClick={() => confirmIfNoPrice(props.handleSubmit)}
                        disabled={submitDisabled(props.values.feedText)}
                        loading={loading}
                    >
                        {t('main:main.Submit')}
                    </Button>
                </>
            )}
        </Formik>
    );
};

export default CreatePostView;
