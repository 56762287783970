import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import {
  IconVerifiedSmallNoBorder,
  IconStar,
  IconCheck,
  IconInfoGrey,
  IconEdit,
} from 'assets/icons';
import getProfileRank from 'helpers/getProfileRank';
import { selectUserInfo } from 'features/auth/authSlice';
import { useModal } from 'components/modal/useModal';
import styles from './profileRankRange.module.css';

const ProfileRankRange = ({ isMainPath }) => {
  const user = useSelector(selectUserInfo);
  const [rangePosition, setRangePosition] = useState();
  const ref = useRef();
  const history = useHistory();
  const matchProfileEdit = useRouteMatch('/settings/profile/edit');
  const matchImagesEdit = useRouteMatch('/settings/profile/images');
  const { rank, legend } = getProfileRank(user);
  const modal = useModal();
  const { t } = useTranslation();
  // const rank = 70;

  useEffect(() => {
    setRangePosition(
      rank > 50 ? `calc(${rank}% - 25px)` : `calc(${rank}% - 5px)`
    );
  }, [rank]);

  const handleClickField = (hasValue, label) => {
    if (hasValue) {
      return null;
    }

    if (label === 'verification_required') {
      history.push('/settings/wallet/kyc');
    } else if (label === 'verified_phone') {
      history.push('/settings/account/edit');
    } else if (label === 'images' && !matchImagesEdit) {
      history.push('/settings/profile/images');
    } else if (label !== 'images' && !matchProfileEdit) {
      history.push('/settings/profile/edit');
    }
    return modal.hideModal();
  };

  const indicatorWidth = ref.current ? `${ref.current.offsetWidth}px` : '90vw';
  return (
    <div className={cx(styles.container, { [styles.main]: isMainPath })}>
      <div className={styles.label}>
        <p style={{ margin: '0 0 20px' }}>
          {t('features:settings.profile.main.rankText')}:
          <strong> {rank}% </strong>
          <span onClick={() => modal.showModal()} className={styles.rankInfo}>
            <IconInfoGrey width={20} />
          </span>
        </p>
      </div>

      <div className={styles.rangeWrapper}>
        <div ref={ref} className={styles.range}>
          <div className={`${styles.indicator} ${styles.front}`}>
            <IconVerifiedSmallNoBorder className={styles.icon} />
          </div>
          <div
            className={`${styles.indicator} ${styles.middle}`}
            style={{
              backgroundSize: `70px ${indicatorWidth}`,
              left: rangePosition,
              backgroundPosition: `${rank}% center`,
              transition: '1s',
            }}
          >
            <IconVerifiedSmallNoBorder
              style={{ fill: rank > 50 ? 'var(--turg)' : 'var(--accentColor)' }}
              className={styles.icon}
            />
          </div>
          <div className={`${styles.indicator} ${styles.back}`}>
            <IconStar className={`${styles.icon} ${styles.star}`} />
          </div>

          <div className={styles.bar}></div>
        </div>
      </div>
      <modal.RenderModal
        buttonClose
        title={`${t('features:settings.profile.main.rankText')} - ${rank}%`}
      >
        <p className={styles.modalSubtitle}>
          {t('features:settings.profile.main.rankSubText')}
        </p>

        <div>
          {legend.map((l, idx) => (
            <div
              key={idx}
              className={cx(styles.modalItem, {
                [styles.missingField]: !l.value,
              })}
              onClick={() => handleClickField(l.value, l.label)}
            >
              <span>{t(`components:profileRank.${l.label}`)}</span>
              {l.value ? (
                <IconCheck width={12} />
              ) : (
                <IconEdit width={14} style={{ fill: 'var(--accentColor)' }} />
              )}
            </div>
          ))}
        </div>
      </modal.RenderModal>
    </div>
  );
};

export default ProfileRankRange;
