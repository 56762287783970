import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../app/api';

const transaction3DSHeaders = {
  screen_language: navigator.language,
  screen_width: window.screen.width,
  screen_height: window.screen.height,
  screen_timezone: new Date().getTimezoneOffset(),
  screen_user_agent: navigator.userAgent,
  screen_color_depth: window.screen.colorDepth,
  screen_java_enabled: navigator.javaEnabled(),
  screen_javascript_enabled: true,
};

const chatPrice = process.env.REACT_APP_PRICE_PER_CHAT;

// TRANSACTIONs PAYMENT METHODS:  COINS, OCP, EXT
const transactionType = (user, price, hasCoinForTip) => {
  const crossCheckCoins = (typeof(hasCoinForTip) === 'undefined' || hasCoinForTip === true)  ? user.payments.total_credits : 0;
  const hasEnoughCoins = crossCheckCoins >= price;
  const opc = user.payments.ocp_ready;

  return hasEnoughCoins ? 'COINS' : opc ? 'OCP' : 'EXT';
};

export const startChatTransaction = createAsyncThunk(
  'transactions/startChatTransaction',
  async (payload, { getState }) => {
    const user = getState().auth.user.userInfo;
    const actor = payload.actor;

    const resPayload = {
      user,
      actor,
      transactionType: `CHAT_${transactionType(user, chatPrice)}`,
      episodeId: payload.episodeId || null,
    };

    return resPayload;
  }
);

// product list for slider
export const fetchChatOCPProducts = createAsyncThunk(
  'transactions/fetchChatOCPProducts',
  async (_, { getState }) => {
    const res = await api.get('/v2/payment/product_list');
    return res.data;
  }
);

//chat OCP
export const fetchChatOCPProduct = createAsyncThunk(
  'transactions/fetchChatOCPProduct',
  async (_, { getState }) => {
    const user_id = getState().transactions.actor.id;
    const episode_id = getState().transactions.episodeId;

    const res = await api.post('/v2/payment/chat_product_list', {
      chat_user_id: user_id,
      chat_episode_id: episode_id,
    });
    return res.data;
  }
);

export const buyChatOCPProduct = createAsyncThunk(
  'transactions/buyChatOCPProduct',
  async (_, { getState }) => {
    const user_id = getState().transactions.actor.id;
    const episode_id = getState().transactions.episodeId;
    const res = await api.post('/v2/payment/chat_popup_payment', {
      chat_user_id: user_id,
      chat_episode_id: episode_id,
      ...transaction3DSHeaders,
    });
    return res.data;
  }
);

export const openChatWithCoins = createAsyncThunk(
  'transactions/openChatWithCoins',
  async (_, { getState }) => {
    const user_id = getState().transactions.actor.id;
    const episode_id = getState().transactions.episodeId;

    if (episode_id) {
      // Chat related to episode
      const response = await api.get(`/v2/chats/episode_open/${user_id}/${episode_id}`);
      return response.data;
    } else {
      // standalone chat
      const response = await api.get(`v2/chats/open/${user_id}`);
      return response.data;
    }
  }
);

export const sendTipWithCoins = createAsyncThunk(
    'transactions/sendTipWithCoins',
    async (params, { getState }) => {
        const user_id = getState().transactions.actor.id;

        const response = await api.post(`v2/tips/send/${user_id}/${params.code}`,
            {
                message: params.tipMessage
            });
        return response.data;

    }
);

export const startContentTransaction = createAsyncThunk(
  'transactions/startContentTransaction',
  async (payload, { getState }) => {
    const user = getState().auth.user.userInfo;
    const actor = payload.actor;
    const item = payload.item;

    const resPayload = {
      user,
      actor: actor.data,
      item,
      transactionType: `CONTENT_${transactionType(user, item.coins)}`,
    };

    return resPayload;
  }
);

export const startTipTransaction = createAsyncThunk(
    'transactions/startTipTransaction',
    async (payload, { getState }) => {
        const user = getState().auth.user.userInfo;
        const actor = payload.actor;
        const item = payload.item;
        const items = payload.items;
        const coins = payload.coins;
        const state = getState();

        const resPayload = {
            user,
            actor,
            item,
            items,
            transactionType: `TIP_${transactionType(user, coins, state.transactions.availableCoinForTip)}`,
        }
        return resPayload;
    }
);

export const startTipTransactionModal = createAsyncThunk(
    'transactions/startTipTransactionModal',
    async (payload, { getState }) => {
        const user = getState().auth.user.userInfo;
        const actor = payload.actor;

        //define flag.. it's chattip transaction true false
        const isChatTipTransaction = typeof payload.chatTipTransaction !== 'undefined'
            && payload.chatTipTransaction !== null
            && payload.chatTipTransaction === true;

        const resPayload = {
            user,
            actor,
            isChatTipTransaction, //set flag for chat tip
            transactionType: `TIP_START`,
        };

        return resPayload;
    }
);

export const fetchTipOCPProduct = createAsyncThunk(
  'transactions/fetchTipOCPProduct',
  async (params, { getState }) => {
    console.log(params);
    const res = await api.post('/v2/payment/tip_product_list', {
        tip_id: params.tipId,
    });
    return res.data;
  }
);

export const fetchContentOCPProduct = createAsyncThunk(
  'transactions/fetchContentOCPProduct',
  async (_, { getState }) => {
    const item = getState().transactions.item;
    const res = await api.post('/v2/payment/content_product_list', {
      activity_id: item.id,
    });
    return res.data;
  }
);

export const buyTipOCPProduct = createAsyncThunk(
'transactions/buyTipOCPProduct',
async (params, { getState }) => {

    const user_id = getState().transactions.actor.id;
    const res = await api.post('/v2/payment/tip_popup_payment', {
        message: params.tipMessage,
        code: params.code,
        tip_id: params.tipId,
        receiver_id: user_id,
      ...transaction3DSHeaders,
    });
    return res.data;
  }
);

export const buyContentOCPProduct = createAsyncThunk(
  'transactions/buyContentOCPProduct',
  async (product_id, { getState }) => {
    const res = await api.post('/v2/payment/content_popup_payment', {
      product_id,
      ...transaction3DSHeaders,
    });
    return res.data;
  }
);

export const buyContentWithCoins = createAsyncThunk(
  'transactions/buyContentWithCoins',
  async (_, { getState }) => {
    const product_id = getState().transactions.item.id;

    const response = await api.post(`/v2/activities/buy`, { id: product_id });
    return response.data;
  }
);

const initialState = {
  showModal: false,
  actor: null,
  transactionType: null,
  episodeId: null,
  tipResponse:null
};

export const transactions = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    closeModal: (state) => {
        return {
            ...state,
            ...initialState
        };
    },
  },
  extraReducers: {
    [startChatTransaction.fulfilled]: (state, { payload }) => {
      state.showModal = true;
      state.transactionType = payload.transactionType;
      state.actor = payload.actor;
      state.episodeId = payload.episodeId;
    },
    [startContentTransaction.fulfilled]: (state, { payload }) => {
      state.showModal = true;
      state.transactionType = payload.transactionType;
      state.actor = payload.actor;
      state.item = payload.item;
    },
    [startTipTransaction.fulfilled]: (state, { payload }) => {
      state.showModal = true;
      state.transactionType = payload.transactionType;
      state.actor = payload.actor;
      state.item = payload.item;
    },
    [startTipTransactionModal.fulfilled]: (state, { payload }) => {
      state.showModal = true;
      state.transactionType = payload.transactionType;
      state.actor = payload.actor;
      state.isChatTipTransaction = payload.isChatTipTransaction;
    },
    [sendTipWithCoins.fulfilled]:  (state, action) => {
        state.availableCoinForTip = action.payload.data.total_credits >= 25 ? true : false;
        state.tipResponse = action;
    },
    [fetchChatOCPProduct.pending]: () => {},
    [fetchChatOCPProduct.fulfilled]: () => {},
    [fetchChatOCPProduct.rejected]: () => {},
  },
});

export const { closeModal } = transactions.actions;

export const selectTransactions = (state) => state.transactions;
export const selectTransactionType = (state) =>
  state.transactions.transactionType;

export const selectTipTransactionResponse = (state) => state.transactions.tipResponse;
export const selectShowTransactionModal = (state) => state.showTransactionModal;

export default transactions.reducer;
