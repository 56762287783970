import { Grid, Row, Col } from 'components/layout/grid';
import cx from 'classnames';

import MainMenu from '../mainMenu/MainMenu';
import styles from './main.module.css';

const Main = ({
  children,
  noMainMenu,
  fullScreen,
  noMarginBottom,
  mobilePadding,
  hasHeader,
  loginPage
}) => {
  const className = cx({
    noLeftSidebar: noMainMenu || fullScreen,
    mobilePadding: mobilePadding,
    withHeader: hasHeader,
    noMrg: noMarginBottom,
    loginPage: loginPage
  });
  return (
    <Grid
      tagName="main"
      className={cx(styles.gridBoxMain, { [styles.fullScreen]: fullScreen })}
    >
      <Row>
        {!noMainMenu && !fullScreen && (
          <Col id="onlyvip-menu">
            <MainMenu />
          </Col>
        )}
        <Col id="onlyvip-wrapper" className={className}>
          {children}
        </Col>
      </Row>
    </Grid>
  );
};

export default Main;
