import React, {useEffect, useRef, useState} from 'react';
import {unwrapResult} from '@reduxjs/toolkit';
import ReactDOM from 'react-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation, Trans} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import WithSubscription from './withSubscription/WithSubscription';
import Button from 'components/buttons/Button';
import Avatar from 'components/avatar/Avatar';
import Loader, {LoaderRelative} from 'components/layout/loader';
import {SubscribeModalBody} from 'features/subscriptions/subscribeModal/SubscribeModal';

import {useLockBodyScroll} from 'hooks';
import {getFeedMediaPreviewSrc} from 'helpers/s3media';
import {secToMin} from 'helpers/timeAdaptors';
import {
    buyChatOCPProduct,
    closeModal,
    selectTransactions,
    selectTransactionType,
    openChatWithCoins,
    fetchContentOCPProduct,
    buyContentOCPProduct,
    buyContentWithCoins,
    fetchChatOCPProduct,
    startTipTransactionModal,
    startTipTransaction,
    sendTipWithCoins,
    fetchTipOCPProduct,
    buyTipOCPProduct
} from '../transactionsSlice';
import {updateUser} from 'features/auth/authSlice';
import {getEpisodes} from 'features/dating/episodes/episodesSlice';
import {getFeedDetail, resetGlobalFeed} from 'features/feed/feedSlice';
import {resetPurchased} from 'features/user/profileSlice';
import {
    IconClose,
    IconEdit,
    IconLock,
    IconPhoto,
    IconVideo
} from 'assets/icons';
import styles from './transactionModal.module.css';
import TipData from "./TipData";
import {toast} from "react-toastify";

//const chatPrice = process.env.REACT_APP_PRICE_PER_CHAT;

const Backdrop = ({children}) => {
    const dispatch = useDispatch();
    const ref = useRef(null);

    const click = (e) => {
        e.stopPropagation();
        if (ref.current !== e.target) return;
        dispatch(closeModal());
    };

    return (
        <div onClick={click} ref={ref} className={styles.backdrop}>
            {children}
        </div>
    );
};

const CloseButton = ({onClick}) => {
    return (
        <Button onClick={onClick} className={styles.closeButton} name="btn-bare">
            <IconClose/>
        </Button>
    );
};

const ContentPreview = ({previewSrc, item}) => {
    if (item.type === 'video') {
        return (
            <div
                style={{
                    backgroundImage: `url(${previewSrc})`,
                }}
                className={styles.preview}
            >
                <div className={styles.previewPrice}>
          <span>
            <IconVideo/> {secToMin(item.videosDuration)}
              {item.stats.num_images ? (
                  <span style={{marginLeft: '5px'}}>
                <IconPhoto/>
                      {item.stats.num_images}
              </span>
              ) : null}
          </span>
                    <span>{item.coins} coins</span>
                </div>
            </div>
        );
    }

    return (
        <div
            style={{
                backgroundImage: `url(${previewSrc})`,
            }}
            className={styles.preview}
        >
            <div className={styles.previewPrice}>
        <span>
          <IconPhoto/> {item.stats.num_images}
            {item.stats.num_videos ? (
                <span style={{marginLeft: '5px'}}>
              <IconVideo/>
                    {secToMin(item.videosDuration)}
            </span>
            ) : null}
        </span>
                <span>
          <IconLock style={{width: '12px'}}/> {item.coins} coins
        </span>
            </div>
        </div>
    );
};

const ModalBody = ({children}) => {
    return <div className={styles.body}>{children}</div>;
};
const ModalBodyFull = ({children}) => {
    return <div className={styles.bodyfull}>{children}</div>;
};

const OCPPaymentError = ({type}) => {
    const state = useSelector(selectTransactions);

    const isEpisodeChat = state.episodeId; // ID || NULL
    const checkOutURL = process.env.REACT_APP_PAYMENTS_CHECKOUT;

    const checOutLinks = {
        episode: `${checkOutURL}?chat_epidose_id=${state.episodeId}`,
        chat: `${checkOutURL}?chat_user_id=${state.actor.id}`,
        content: `${checkOutURL}?activity_id=${state?.item?.id}`,
    };

    const getExternalCheckoutUrl = () => {
        if (type === 'chat') {
            return isEpisodeChat ? checOutLinks.episode : checOutLinks.chat;
        }
        if (type === 'content') {
            return checOutLinks.content;
        }
        return checkOutURL;
    };

    return (
        <div className={styles.errorBox}>
            <Trans i18nKey={'features:transactions.ocpError'}>
                <p>
                    Unable to procceed with transaction <br/>
                    <a href={getExternalCheckoutUrl()}>
                        Please review your payment methods
                    </a>
                </p>
            </Trans>
        </div>
    );
};

const ChatOCPModal = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const state = useSelector(selectTransactions);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [loading, setLoading] = useState(false);
    const [transactionSuccess, setTransactionSuccess] = useState(false);
    const [transactionError, setTransactionError] = useState(false);
    const [isRedirecting, setRedirecting] = useState(false);
    const {t} = useTranslation();

    //const chatType = state.episodeId ? 'episode' : 'direct';

    useEffect(() => {
        const getContentProduct = async () => {
            dispatch(fetchChatOCPProduct())
                .then(unwrapResult)
                .then((res) => {
                    if (!res.ok) throw new Error('Error fetching product for item');
                    setLoading(false);
                    setSelectedProduct(res.products[0]);
                })
                .catch((e) => {
                    console.log(e);
                    setLoading(false);
                    setTransactionError(true);
                });
        };
        getContentProduct();
    }, [dispatch]);

    const onBuyCoins = async () => {
        setLoading(true);
        dispatch(buyChatOCPProduct())
            .then(unwrapResult)
            .then((res) => {
                if (!res.ok) {
                    dispatch(updateUser());
                    throw new Error('Error fetching product for item');
                } else if (res.redirection && res.payment_status === 'redirect3DS') {
                    window.location.assign(res.url_redirect);
                } else {
                    window.location.assign(res.url_redirect);
                    setLoading(false);
                    setTransactionSuccess(true);
                }
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
                setTransactionError(true);
            });
    };

    const onOpenChat = async () => {
        setRedirecting(true);
        dispatch(openChatWithCoins())
            .then(unwrapResult)
            .then((res) => {
                if (!res.status) throw new Error(res.error);
                history.push(`/chat/${res.data.channel.id}`);
                dispatch(updateUser());
                dispatch(getEpisodes());
                dispatch(closeModal());
            })
            .catch((e) => {
                console.log(e);
                dispatch(updateUser());
                setTransactionError(true);
                setRedirecting(false);
            }); // TODO show pay modal on error when backend is ready
    };

    if (isRedirecting) return <Loader/>;

    const name = state.actor.name;

    return (
        <Backdrop>
            <div className={styles.container}>
                <CloseButton onClick={() => dispatch(closeModal())}/>
                <ModalBody>
                    <Avatar
                        className={styles.modalAvatar}
                        image={state.actor.avatar}
                        gender={state.actor.gender}
                        circle
                    />
                    {transactionSuccess ? (
                        <p style={{fontSize: '16px', textAlign: 'center'}}>
                            {t(
                                'features:transactions.proceedOpenChat',
                                'Success! Open chat with '
                            )}
                            <br/>
                            <strong>{state.actor.name}</strong>
                        </p>
                    ) : (
                        <p style={{fontSize: '16px', textAlign: 'center'}}>
                            <Trans i18nKey="features:transactions.youNeedCoins">
                                You need 15 coins to open chat with <strong>{{name}}</strong>.
                            </Trans>
                        </p>
                    )}

                    {transactionError && <OCPPaymentError type="chat"/>}

                    <Button
                        disabled={!selectedProduct}
                        loading={loading}
                        size="l"
                        onClick={transactionSuccess ? onOpenChat : onBuyCoins}
                    >
                        {transactionSuccess
                            ? t('features:transactions.openChat', 'Open Chat')
                            : t('features:transactions.getCoins', 'Get coins')}
                    </Button>
                    {!transactionError && selectedProduct && !transactionSuccess && (
                        <p style={{fontSize: '13px', color: '#888', margin: '10px'}}>
                            {t(
                                'features:transactions.chargeCard',
                                'Your card will be charged {{price}}',
                                {
                                    price: selectedProduct.price,
                                }
                            )}
                        </p>
                    )}
                    {/* <CancelBtn onClick={() => dispatch(closeModal())} /> */}
                    {/*<small>{t(`features:transactions.footerInfo.${chatType}`)}</small>*/}
                </ModalBody>
            </div>
        </Backdrop>
    );
};
const ChatEXTModal = () => {
    const dispatch = useDispatch();
    const state = useSelector(selectTransactions);
    const {t} = useTranslation();

//    const chatType = state.episodeId ? 'episode' : 'direct';

    const checkOutLink = state.episodeId
        ? `${process.env.REACT_APP_PAYMENTS_CHECKOUT}?chat_epidose_id=${state.episodeId}`
        : `${process.env.REACT_APP_PAYMENTS_CHECKOUT}?chat_user_id=${state.actor.id}`;

    const onBuyCoins = () => {
        console.info('redirect to checkout', checkOutLink);
        window.location.assign(checkOutLink);
    };

    const name = state.actor.name;

    return (
        <Backdrop>
            <div className={styles.container}>
                <CloseButton onClick={() => dispatch(closeModal())}/>
                <ModalBody>
                    <Avatar
                        className={styles.modalAvatar}
                        image={state.actor.avatar}
                        gender={state.actor.gender}
                        circle
                    />

                    <p style={{fontSize: '16px', textAlign: 'center'}}>
                        <Trans i18nKey="features:transactions.youNeedCoins">
                            You need 15 coins to open chat with <strong>{{name}}</strong>.
                        </Trans>
                    </p>

                    <Button size="l" onClick={onBuyCoins}>
                        {t('features:transactions.getCoins', 'Get coins')}
                    </Button>
                    {/* <CancelBtn onClick={() => dispatch(closeModal())} /> */}
                    {/*<small>{t(`features:transactions.footerInfo.${chatType}`)}</small>*/}
                </ModalBody>
            </div>
        </Backdrop>
    );
};
const ChatCOINSModal = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const state = useSelector(selectTransactions);
    const [isRedirecting, setRedirecting] = useState(false);
    const {t} = useTranslation();
    const [error, setError] = useState();

//    const chatType = state.episodeId ? 'episode' : 'direct';

    const onOpenChat = async () => {
        setRedirecting(true);
        dispatch(openChatWithCoins())
            .then(unwrapResult)
            .then((res) => {
                if (!res.status) throw new Error(res.error);
                history.push(`/chat/${res.data.channel.id}`);
                dispatch(updateUser());
                dispatch(getEpisodes());
                dispatch(closeModal());
            })
            .catch((e) => {
                dispatch(updateUser());
                setError(true);
                setRedirecting(false);
            }); // TODO show pay modal on error when backend is ready
    };

    if (isRedirecting) return <Loader/>;
    if (error)
        return (
            <Backdrop>
                <div className={styles.container}>
                    <CloseButton onClick={() => dispatch(closeModal())}/>
                    <ModalBody>
                        <p style={{fontSize: '16px', textAlign: 'center'}}>
                            {t('features:auth.passwordRecovery.tryAgain')}
                        </p>

                        <Button name="btn-grey" onClick={() => dispatch(closeModal())}>
                            Cancel
                        </Button>
                    </ModalBody>
                </div>
            </Backdrop>
        );

    return (
        <Backdrop>
            <div className={styles.container}>
                <CloseButton onClick={() => dispatch(closeModal())}/>
                <ModalBody>
                    <Avatar
                        className={styles.modalAvatar}
                        image={state.actor.avatar}
                        gender={state.actor.gender}
                        circle
                    />

                    <p style={{fontSize: '16px', textAlign: 'center'}}>
                        {t('features:transactions.chatWith')}
                        <br/>
                        <strong>{state.actor.name}</strong>
                    </p>

                    <Button size="l" onClick={onOpenChat}>
                        {t('features:event.openChat')}
                        {/*{t('features:transactions.openFor', {
                            chatPrice: chatPrice,
                        })}*/}
                    </Button>
                    {/* <CancelBtn onClick={() => dispatch(closeModal())} /> */}
                    {/*<small>{t(`features:transactions.footerInfo.${chatType}`)}</small>*/}
                </ModalBody>
            </div>
        </Backdrop>
    );
};

// CONTENT
const ContentOCPModal = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const state = useSelector(selectTransactions);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [transactionError, setTransactionError] = useState(false);
    const [isRedirecting, setRedirecting] = useState(false);
    const {t} = useTranslation();
    const [selectedSubscription, setSelectedSubscription] = useState(null);

    const previewSrc = getFeedMediaPreviewSrc(state.item);

    useEffect(() => {
        const getContentProduct = async () => {
            dispatch(fetchContentOCPProduct())
                .then(unwrapResult)
                .then((res) => {
                    if (!res.ok) throw new Error('Error fetching product for item');
                    setLoading(false);
                    setSelectedProduct(res.products[0]);
                })
                .catch((e) => {
                    console.log(e);
                    setLoading(false);
                    setTransactionError(true);
                });
        };
        getContentProduct();
    }, [dispatch]);

    const getProductDetail = async () => {
        dispatch(buyContentWithCoins())
            .then(unwrapResult)
            .then((res) => {
                if (window.location.pathname === `/post/${state.item.id}`) {
                    window.location.reload();
                } else {
                    history.push(`/post/${state.item.id}`);
                }
                dispatch(resetPurchased());
                dispatch(resetGlobalFeed());
                dispatch(updateUser());
                dispatch(closeModal());
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setRedirecting(false);
                setTransactionError(true);
            });
    };

    const onUnlock = async () => {
        setLoading(true);
        dispatch(buyContentOCPProduct(selectedProduct.product_id))
            .then(unwrapResult)
            .then((res) => {
                if (!res.ok) {
                    // dispatch(updateUser());
                    throw new Error('Error fetching product for item');
                } else if (res.redirection && res.payment_status === 'redirect3DS') {
                    window.location.assign(res.url_redirect);
                } else {
                    setRedirecting(true);
                    getProductDetail();
                }
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
                setTransactionError(true);
            });
    };

    if (selectedSubscription && !loading) {
        return (
            <Backdrop>
                <div className={styles.container}>
                    <CloseButton onClick={() => dispatch(closeModal())}/>
                    <div style={{padding: '20px'}}></div>
                    <ModalBody>
                        <SubscribeModalBody
                            profile={state.actor}
                            preselectedBundle={selectedSubscription}
                            onHideModal={() => dispatch(closeModal())}
                        />
                    </ModalBody>
                </div>
            </Backdrop>
        );
    }

    if (isRedirecting) return <Loader/>;

    return (
        <Backdrop>
            <div className={styles.container}>
                <CloseButton onClick={() => dispatch(closeModal())}/>
                <ModalBody>
                    <p style={{fontSize: '16px', textAlign: 'center', marginTop: 0}}>
                        {t(
                            'features:transactions.unlockContent',
                            'Unlock exclusive content from'
                        )}
                        <br/>
                        <strong>{state.actor.name}</strong>
                    </p>

                    {loading ? (
                        <LoaderRelative/>
                    ) : (
                        <ContentPreview previewSrc={previewSrc} item={state.item}/>
                    )}

                    {transactionError ? (
                        <OCPPaymentError type="content"/>
                    ) : (
                        <Button disabled={!selectedProduct} size="l" onClick={onUnlock}>
                            {t(
                                'features:transactions.unlockForCoins',
                                'Open for {{coins}} coins',
                                {
                                    coins: state.item.coins,
                                }
                            )}
                        </Button>
                    )}
                    {!transactionError && selectedProduct && (
                        <p style={{fontSize: '13px', color: '#888', margin: '10px'}}>
                            {t(
                                'features:transactions.chargeCard',
                                'Your card will be charged {{price}}',
                                {
                                    price: selectedProduct.price,
                                }
                            )}
                        </p>
                    )}
                    {/* <CancelBtn onClick={() => dispatch(closeModal())} /> */}

                    {!loading && (
                        <WithSubscription
                            actor={state.actor}
                            onSelect={(b) => {
                                setSelectedSubscription(b);
                            }}
                        />
                    )}
                </ModalBody>
            </div>
        </Backdrop>
    );
};

const ContentCOINSModal = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const state = useSelector(selectTransactions);
    const [loading, setLoading] = useState(false);
    const [transactionError, setTransactionError] = useState(false);
    const [isRedirecting, setRedirecting] = useState(false);
    const {t} = useTranslation();
    const [selectedSubscription, setSelectedSubscription] = useState(null);

    const previewSrc = getFeedMediaPreviewSrc(state.item);

    const onUnlock = async () => {
        setLoading(true);
        dispatch(buyContentWithCoins())
            .then(unwrapResult)
            .then((res) => {
                dispatch(getFeedDetail({post_id: state.item.id}));
                dispatch(resetPurchased());
                dispatch(resetGlobalFeed());
                dispatch(updateUser());
                if (window.location.pathname === `/post/${state.item.id}`) {
                    window.location.reload();
                } else {
                    history.push(`/post/${state.item.id}`);
                }
                dispatch(closeModal());
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setRedirecting(false);
                setTransactionError(true);
            });
    };

    if (selectedSubscription && !loading) {
        return (
            <Backdrop>
                <div className={styles.container}>
                    <CloseButton onClick={() => dispatch(closeModal())}/>
                    <div style={{padding: '20px'}}></div>
                    <ModalBody>
                        <SubscribeModalBody
                            profile={state.actor}
                            preselectedBundle={selectedSubscription}
                            onHideModal={() => dispatch(closeModal())}
                        />
                    </ModalBody>
                </div>
            </Backdrop>
        );
    }

    if (isRedirecting) return <Loader/>;

    return (
        <Backdrop>
            <div className={styles.container}>
                <CloseButton onClick={() => dispatch(closeModal())}/>
                <ModalBody>
                    <p style={{fontSize: '16px', textAlign: 'center', marginTop: 0}}>
                        {t('features:transactions.unlockContent')}
                        <br/>
                        <strong>{state.actor.name}</strong>
                    </p>

                    {loading ? (
                        <LoaderRelative/>
                    ) : (
                        <ContentPreview previewSrc={previewSrc} item={state.item}/>
                    )}

                    {transactionError ? (
                        <OCPPaymentError type="content"/>
                    ) : (
                        <Button size="l" fullWidth onClick={onUnlock}>
                            {t(
                                'features:transactions.unlockForCoins',
                                'Open for {{coins}} coins',
                                {
                                    coins: state.item.coins,
                                }
                            )}
                        </Button>
                    )}
                    {/* <CancelBtn onClick={() => dispatch(closeModal())} /> */}
                    <WithSubscription
                        actor={state.actor}
                        onSelect={(b) => {
                            setSelectedSubscription(b);
                        }}
                    />
                </ModalBody>
            </div>
        </Backdrop>
    );
};

const TipModal = () => {
    const dispatch = useDispatch();
    const state = useSelector(selectTransactions);
    const {t} = useTranslation();
    const items = TipData();

    const handleTip = (item, coins) => {
        dispatch(startTipTransaction({actor: state.actor, item: item, coins: coins,chatTipTransaction: state.isChatTipTransaction}));
    };
    return (
        <Backdrop>
            <div className={styles.container}>
                <CloseButton onClick={() => dispatch(closeModal())}/>
                <ModalBodyFull>
                    {/*<Avatar
                        size={60}
                        className={styles.modalAvatar}
                        image={state.actor.avatar}
                        gender={state.actor.gender}
                        circle
                    />*/}
                    <>
                        <div className={styles.tipHeading}>

                            {t(
                                'features:transactions.sendTip',
                                'Send Tip'
                            )}
                        </div>
                        <div className={styles.tipSubHeading} dangerouslySetInnerHTML={{
                            __html: t('features:transactions.questionSendTip', 'Would you like to send <b>{{name}}</b> a tip?',
                                {
                                    name: state.actor.name,
                                })
                        }}>
                        </div>

                        {items.map(item => (
                            <Button
                                key={item.id}
                                className={styles.tipBtn}
                                onClick={() => handleTip(item.id, item.coins)}>

                                {item.icon}
                                <span>
                                    {item.name}
                                </span>

                                <p>
                                    {item.coins} Coins
                                </p>
                            </Button>
                        ))}
                    </>
                </ModalBodyFull>
            </div>
        </Backdrop>
    )
}

const TipOCPModal = () => {
    const dispatch = useDispatch();
    const state = useSelector(selectTransactions);
    const [tipMessage, setTipMessage] = useState(false);
    const {t} = useTranslation();
    const items = TipData();
    const item = items.find(item => item.id === state.item);

    const code = item.code;
    const tipId = item.id;

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [loading, setLoading] = useState(false);
    const [transactionError, setTransactionError] = useState(false);

    const handleChange = (event) => {
        setTipMessage(event.target.value);
    };

    useEffect(() => {
        const getTipProduct = async () => {
            dispatch(fetchTipOCPProduct({tipId}))
                .then(unwrapResult)
                .then((res) => {
                    if (!res.ok) throw new Error('Error fetching tip for item');
                    setLoading(false);
                    setSelectedProduct(res.products[0]);
                })
                .catch((e) => {
                    console.log(e);
                    setLoading(false);
                    setTransactionError(true);
                });
        };
        getTipProduct(tipId);
    }, [dispatch, tipId]);

    const onSendTip = async () => {
        setLoading(true);
        dispatch(buyTipOCPProduct({tipId, code, tipMessage}))
            .then(unwrapResult)
            .then((res) => {
                if (!res.ok) {
                    // dispatch(updateUser());
                    throw new Error('Error fetching product for item');
                } else if (res.redirection && res.payment_status === 'redirect3DS') {
                    window.location.assign(res.url_redirect);
                } else {
                    //setRedirecting(true);
                    toast.success(t('features:tip.send.success'));
                    dispatch(updateUser());
                    //dispatch(getEpisodes());
                    dispatch(closeModal());
                    //sendTip();
                }
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
                setTransactionError(true);
            });
    };

    const handleTipBck = () => {
        dispatch(startTipTransactionModal({actor: state.actor, chatTipTransaction: state.isChatTipTransaction}));
    }

    return (
        <Backdrop>
            <div className={styles.container}>
                <CloseButton onClick={() => dispatch(closeModal())}/>
                <ModalBodyFull>

                    {loading ? (
                        <LoaderRelative/>
                    ) : (
                        <>

                            <div className={styles.tipHeading}>

                                {t(
                                    'features:transactions.sendTip',
                                    'Send Tip'
                                )}
                            </div>
                            <div className={styles.tipSubHeading} dangerouslySetInnerHTML={{
                                __html: t('features:transactions.questionSendTip', 'Would you like to send <b>{{name}}</b> a tip?',
                                    {
                                        name: state.actor.name,
                                    })
                            }}>
                            </div>

                            <Button
                                className={styles.tipBtnGrey}
                                onClick={handleTipBck}
                            >
                                {item.icon}
                                <span>{item.name}</span>

                                <IconEdit className={styles.tipBtnIconEdit}></IconEdit>
                                <span className={styles.txtGrey}>{item.coins} Coins</span>
                            </Button>

                            <textarea rows={8} style={{background:"var(--grey6)"}} onChange={handleChange} name={'tip_message'}
                                      placeholder={t('features:transactions.writeMsg', 'Write a message to {{name}}', {
                                          name: state.actor.name,
                                      })}>
                        </textarea>
                            <br/>
                        </>
                    )}

                    {transactionError ? (
                        <OCPPaymentError type="content"/>
                    ) : (

                        <Button className={styles.tipButton} size="l" disabled={!selectedProduct} onClick={onSendTip}>
                            {t('features:transactions.sendCoins', 'Send {{coins}} coins tip', {
                                coins: item.coins
                            })}
                        </Button>
                    )}

                    {!transactionError && selectedProduct && (
                        <p style={{fontSize: '13px', color: '#888', margin: '10px'}}>
                            {t(
                                'features:transactions.chargeCard',
                                'Your card will be charged {{price}}',
                                {
                                    price: selectedProduct.price,
                                }
                            )}
                        </p>
                    )}

                </ModalBodyFull>
            </div>
        </Backdrop>
    );
};

const TipCOINSModal = () => {
    const dispatch = useDispatch();
    const state = useSelector(selectTransactions);
    const [isRedirecting, setRedirecting] = useState(false);
    const [tipMessage, setTipMessage] = useState(false);
    const {t} = useTranslation();
    const [error, setError] = useState();
    const items = TipData();

    const item = items.find(item => item.id === state.item);

    const code = item.code;

    const handleChange = (event) => {
        setTipMessage(event.target.value);
    };

    const onSendTip = async () => {
        setRedirecting(true);
        dispatch(sendTipWithCoins({code, tipMessage}))
            .then(unwrapResult)
            .then((res) => {


                if (res.status === true) {
                    toast.success(t('features:tip.send.success'));
                    if(state.isChatTipTransaction === true)
                    {
                        //do nothing
                    } else {
                        dispatch(updateUser());
                    }
                }

                    //throw new Error(res.error);
                //history.push(`/chat/${res.data.channel.id}`);
                //dispatch(getEpisodes());
                dispatch(closeModal());
            })
            .catch((e) => {
                //dispatch(updateUser());
                setError(true);
                setRedirecting(false);
            }); // TODO show pay modal on error when backend is ready
    };

    if (isRedirecting) return <Loader/>;
    if (error)
        return (
            <Backdrop>
                <div className={styles.container}>
                    <CloseButton onClick={() => dispatch(closeModal())}/>
                    <ModalBody>
                        <p style={{fontSize: '16px', textAlign: 'center'}}>
                            {t('features:auth.passwordRecovery.tryAgain')}
                        </p>

                        <Button name="btn-grey" onClick={() => dispatch(closeModal())}>
                            Cancel
                        </Button>
                    </ModalBody>
                </div>
            </Backdrop>
        );

    const handleTipBck = () => {
        dispatch(startTipTransactionModal({actor: state.actor, chatTipTransaction: state.isChatTipTransaction}));
    }

    return (
        <Backdrop>
            <div className={styles.container}>
                <CloseButton onClick={() => dispatch(closeModal())}/>
                <ModalBodyFull>
                    {/*<Avatar
                        size={60}
                        className={styles.modalAvatar}
                        image={state.actor.avatar}
                        gender={state.actor.gender}
                        circle
                    />*/}
                    <div className={styles.tipHeading}>

                        {t(
                            'features:transactions.sendTip',
                            'Send Tip'
                        )}
                    </div>
                    <div className={styles.tipSubHeading} dangerouslySetInnerHTML={{
                        __html: t('features:transactions.questionSendTip', 'Would you like to send <b>{{name}}</b> a tip?',
                            {
                                name: state.actor.name,
                            })
                    }}>
                    </div>

                    <Button
                        className={styles.tipBtnGrey}
                        onClick={handleTipBck}
                    >
                        {item.icon}
                        <span>{item.name}</span>

                        <IconEdit className={styles.tipBtnIconEdit}></IconEdit>
                        <span className={styles.txtGrey}>
                            {item.coins} Coins
                        </span>
                    </Button>

                    <textarea rows={8} style={{background: "var(--grey6)"}} onChange={handleChange}
                              name={'tip_message'}
                              placeholder={t('features:transactions.writeMsg', 'Write a message to {{name}}', {
                                  name: state.actor.name,
                              })}>
                    </textarea>

                    <Button className={styles.tipButton} size="l" onClick={onSendTip}>
                        {t('features:transactions.sendCoins', 'Send {{coins}} coins tip', {
                            coins: item.coins
                        })}
                    </Button>

                    {/*<Button size="l" onClick={closeModal()}>
                        {t('features:subscribeModal.cancel', 'Cancel')}
                    </Button>*/}

                </ModalBodyFull>
            </div>
        </Backdrop>
    );
};

const TipEXTModal = () => {
    const dispatch = useDispatch();
    const state = useSelector(selectTransactions);
    //const checkOutLink = `${process.env.REACT_APP_PAYMENTS_CHECKOUT}?tip_id=${state.item.id}`;
    const checkOutLink = `${process.env.REACT_APP_PAYMENTS_CHECKOUT}`;
    const {t} = useTranslation();

    const onGetCoins = async () => {
        console.info('redirect to checkout', checkOutLink);
        window.location.assign(checkOutLink);
    };

    const handleTipBck = () => {
        dispatch(startTipTransactionModal({actor: state.actor, chatTipTransaction: state.isChatTipTransaction}));
    }
    const items = TipData();
    const item = items.find(item => item.id === state.item);

    return (
        <Backdrop>
            <div className={styles.container}>
                <CloseButton onClick={() => dispatch(closeModal())}/>
                <ModalBodyFull>
                    {/*<Avatar
                        size={60}
                        className={styles.modalAvatar}
                        image={state.actor.avatar}
                        gender={state.actor.gender}
                        circle
                    />*/}

                    <div className={styles.tipHeading}>

                        {t(
                            'features:transactions.sendTip',
                            'Send Tip'
                        )}
                    </div>
                    <div className={styles.tipSubHeading} dangerouslySetInnerHTML={{
                        __html: t('features:transactions.questionSendTip', 'Would you like to send <b>{{name}}</b> a tip?',
                            {
                                name: state.actor.name,
                            })
                    }}>
                    </div>

                    <Button
                        className={styles.tipBtnGrey}
                        onClick={handleTipBck}
                    >
                        {item.icon}
                        <span>{item.name}</span>

                        <IconEdit className={styles.tipBtnIconEdit}></IconEdit>
                        <span className={styles.txtGrey}>
                            {item.coins} Coins
                        </span>
                    </Button>

                    <textarea rows={8} style={{background:"var(--grey6)"}} name={'tip_message'}
                              placeholder={t('features:transactions.writeMsg', 'Write a message to {{name}}', {
                                  name: state.actor.name,
                              })}>
                    </textarea>

                    <Button className={styles.tipButton} size="l" onClick={onGetCoins}>
                        {t('features:transactions.sendCoins', 'Send {{coins}} coins tip', {
                            coins: item.coins
                        })}
                    </Button>

                    {/*<br/>

                    <Button size="l" onClick={closeModal()}>
                        {t('features:subscribeModal.cancel', 'Cancel')}
                    </Button>*/}

                </ModalBodyFull>
            </div>
        </Backdrop>
    );
};

const ContentEXTModal = () => {
    const dispatch = useDispatch();
    const state = useSelector(selectTransactions);
    const checkOutLink = `${process.env.REACT_APP_PAYMENTS_CHECKOUT}?activity_id=${state.item.id}`;
    const {t} = useTranslation();
    const [selectedSubscription, setSelectedSubscription] = useState(null);

    const previewSrc = getFeedMediaPreviewSrc(state.item);

    const onGetCoins = async () => {
        console.info('redirect to checkout', checkOutLink);
        window.location.assign(checkOutLink);
    };

    if (selectedSubscription) {
        return (
            <Backdrop>
                <div className={styles.container}>
                    <CloseButton onClick={() => dispatch(closeModal())}/>
                    <div style={{padding: '20px'}}></div>
                    <ModalBody>
                        <SubscribeModalBody
                            profile={state.actor}
                            preselectedBundle={selectedSubscription}
                            onHideModal={() => dispatch(closeModal())}
                        />
                    </ModalBody>
                </div>
            </Backdrop>
        );
    }

    return (
        <Backdrop>
            <div className={styles.container}>
                <CloseButton onClick={() => dispatch(closeModal())}/>
                <ModalBody>
                    <p style={{fontSize: '16px', textAlign: 'center', marginTop: 0}}>
                        {t(
                            'features:transactions.unlockContent',
                            'Unlock exclusive content from'
                        )}
                        <br/>
                        <strong>{state.actor.name}</strong>
                    </p>
                    <ContentPreview previewSrc={previewSrc} item={state.item}/>

                    <Button size="l" onClick={onGetCoins}>
                        {t('features:transactions.getCoins', 'Get coins')}
                    </Button>
                    {/* <CancelBtn onClick={() => dispatch(closeModal())} /> */}

                    <WithSubscription
                        actor={state.actor}
                        onSelect={(b) => {
                            setSelectedSubscription(b);
                        }}
                    />
                </ModalBody>
            </div>
        </Backdrop>
    );
};

const TransactionModal = () => {
    const type = useSelector(selectTransactionType);
    useLockBodyScroll();
    if (type === 'CHAT_OCP') return <ChatOCPModal/>;
    if (type === 'CHAT_EXT') return <ChatEXTModal/>;
    if (type === 'CHAT_COINS') return <ChatCOINSModal/>;

    if (type === 'CONTENT_OCP') return <ContentOCPModal/>;
    if (type === 'CONTENT_COINS') return <ContentCOINSModal/>;
    if (type === 'CONTENT_EXT') return <ContentEXTModal/>;

    if (type === 'TIP_START') return <TipModal/>;
    if (type === 'TIP_EXT') return <TipEXTModal/>;
    if (type === 'TIP_COINS') return <TipCOINSModal/>;
    if (type === 'TIP_OCP') return <TipOCPModal/>;

    return null;
};

export default React.memo(() => {
    const {showModal} = useSelector(selectTransactions);
    const domEl = document.getElementById('modal-root');

    if (!showModal) return null;
    return ReactDOM.createPortal(<TransactionModal/>, domEl);
});
