import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import { useWindowWidthLessThan } from 'hooks';
import { IconArrowLeft } from 'assets/icons';
import Button from '../../buttons/Button';
import styles from './subHeader.module.css';

const SubHeader = ({
  children,
  screenName,
  leftEl,
  backBtn,
  style,
  special,
  noTitleMobile,
  noMainHeader,
  className,
  customBackBtnHandler,
  hideOnMobile,
}) => {
  const history = useHistory();
  const smallWidth = useWindowWidthLessThan(576);

  const handleBack = () => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  if (smallWidth && hideOnMobile) {
    return null;
  }

  return (
    <div
      style={style}
      className={cx(styles.subHeader, {
        [styles.noBack]: !backBtn,
        [styles.noMainHeader]: noMainHeader,
        [styles.special]: special,
        [className]: className,
      })}
    >
      <div className={styles.left}>
        {backBtn && (
          <Button
            onClick={customBackBtnHandler || handleBack}
            name="btn-bare"
            style={{ padding: '5px 15px 5px 0px' }}
          >
            <IconArrowLeft style={{ width: '25px', height: '16px' }} />
          </Button>
        )}
        {screenName && !leftEl && (
          <p
            className={cx({
              [styles.titleDesk]: noTitleMobile,
              [styles.title]: !noTitleMobile,
            })}
          >
            {screenName}
          </p>
        )}
        {leftEl && <div className={styles.leftEl}>{leftEl}</div>}
      </div>
      <div className={styles.right}>{children ? children : null}</div>
    </div>
  );
};

SubHeader.propTypes = {
  backBtn: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  children: PropTypes.node,
  screenName: PropTypes.string,
  style: PropTypes.object,
  special: PropTypes.bool,
  noTitleMobile: PropTypes.bool,
  noMainHeader: PropTypes.bool,
  hideOnMobile: PropTypes.bool,
};

SubHeader.defaultProps = {
  className: '',
  backBtn: false,
  special: false,
  noTitleMobile: false,
  noMainHeader: false,
  hideOnMobile: false,
};

export default SubHeader;
