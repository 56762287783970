import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import api from 'app/api';
import * as Icons from 'assets/icons/auth';
import { useTimer } from 'hooks';
import Button from 'components/buttons/Button';
import styles from './confirmEmail.module.css';

const ConfirmEmail = () => {
  const [counter, setCounter] = useState(60);
  const { t } = useTranslation();
  const location = useLocation();
  const email = location.state ? location.state.email : null;
  const token = location.state ? location.state.token : null;

  const countDown = () => {
    setCounter(counter - 1);
  };

  useTimer(countDown, counter ? 1000 : null);

  const onClickResend = async () => {
    setCounter(60);
    try {
      const res = await api.post('/v1/rve', { email, token });
      if (!res.data.status) {
        throw new Error('Failed to resend confirmation email');
      }
      toast.success(t('features:auth.confirmEmail.verificationSent'));
    } catch (error) {
      console.log(error); //TODO: Log error
      toast.error(t('features:auth.confirmEmail.failedResend'));
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.ico}>
        <Icons.ConfirmEmail />
      </div>
      <div className={styles.copy}>
        <h4>{t('features:auth.confirmEmail.confirmEmail')}</h4>
        <p>
          {t('features:auth.confirmEmail.emailSent')}
          <br />
          {t('features:auth.confirmEmail.clickToproceed')}
        </p>
      </div>

      {email && token && (
        <div className={styles.resend}>
          <Button
            fullWidth
            onClick={onClickResend}
            disabled={counter > 0}
            size="xl"
            name="btn-gradient"
            type="submit"
          >
            {counter
              ? t('features:auth.confirmEmail.resendWait', {
                  counter: counter,
                })
              : t('features:auth.confirmEmail.resend')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ConfirmEmail;
