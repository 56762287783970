import { useEffect } from 'react';

import { Row, Col } from 'components/layout/grid';
import Layout from 'components/layout/layouts/Layout';
//import HomeTutorial from 'features/tutorials/HomeTutorial';
import { checkBuildVersion } from 'helpers/build_version';
import { ProfileSearch } from 'features/userList/search';
//import { NavFilters } from 'features/userList/navFilters';
//import UserList from 'features/userList/UserList';
//import StorySlider from 'features/home/stories/StorySlider';
//import EpisodesList from "../features/dating/episodes/EpisodesList";
//import {useWindowWidthLessThan} from "../hooks";
import {useTranslation} from "react-i18next";
import ConnectionList from "../features/userList/ConnectionList";
import EmptyCommunity from "../features/userList/search/EmptyCommunity";
import {useLocation} from "react-router-dom";
import SubHeader from "../components/layout/subHeader/SubHeader";

const HomeScreen = () => {
  const location = useLocation();
  //const smallWidth = useWindowWidthLessThan(1025);
  const { t } = useTranslation();

  useEffect(() => {
    checkBuildVersion();
  }, []);

  const getTitle = () => {
    let title = t('features:user.users', 'Users');
    /*if (isDetailScreen) {
      //title = t('features:event.applications');
      title = t('features:event.events');
    }*/
    return title;
  };

  return (
      <Layout screenName={getTitle()} noBorderHeader>
      <>
        <Row>
          <Col xl={12} lg={12}>
            <SubHeader
                backBtn
                screenName={getTitle()}
                hideOnMobile
                style={{ borderBottomColor: 'transparent' }}
            />
            {/*<StorySlider />*/}
            {/*{!smallWidth && (
                <h4 style={{ display : 'block', marginBottom: '12px',  marginTop: '30px', textTransform:'uppercase'}}>
                    {t('main:main.events_near_you', { count: 2 })}
                </h4>
            )}
            <EpisodesList hideFilter={true}/>*/}
            {/*{location.pathname === '/users/search' ?  <NavFilters /> :  '' }*/}
            {/*{!smallWidth && (
                <h4 style={{ display : 'block', marginTop: '40px', textTransform:'uppercase'}}>
                  {t('main:main.users', 'Users')}
                </h4>
            )}*/}
            {location.pathname === '/users/search' ? <ProfileSearch /> : <EmptyCommunity />}
            {/*<ProfileSearch />*/}
            <ConnectionList/>
          </Col>
        </Row>
        {/*<HomeTutorial />*/}
      </>
    </Layout>
  );
};

export default HomeScreen;
