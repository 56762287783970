import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { useFormikContext } from 'formik';
import Select from 'react-select';

import { FormFieldError } from 'components/forms';
import customStyles from './react-select-customStyles.js';
import formStyles from './forms.module.css';
import { useTranslation } from 'react-i18next';

const ReportReasonsSelect = ({ typeName, options, withLabel, required }) => {
  const { setFieldValue } = useFormikContext();
  const [reasons, setReasons] = useState([]);
  const { t } = useTranslation();

  const parseReasons = (reasons) => {
    if (_isEmpty(reasons)) {
      return [];
    }
    const parsedReasons = reasons.map((reason) => {
      return {
        label: reason.reason,
        value: reason.id,
        id: reason.id,
      };
    });
    return parsedReasons;
  };

  useEffect(() => {
    const reasons = parseReasons(options);
    setReasons(reasons);
  }, [options]);

  const handleOnChange = (e) => {
    let parsedValues = [];
    if (!_isEmpty(e)) {
      e.map((item) => {
        return parsedValues.push(item.id);
      });
    }
    setFieldValue(typeName, parsedValues);
  };

  if (_isEmpty(reasons)) {
    return null;
  }

  return (
    <>
      {withLabel && (
        <label
          className={`${formStyles.formLabel} ${
            required ? formStyles.required : ''
          }`}
          htmlFor={typeName}
        >
          {t('features:blockReportReason.reportReasonLabel', 'Report Reasons')}
        </label>
      )}
      <Select
        isMulti
        isClearable={false}
        isSearchable={false}
        name={typeName}
        options={reasons}
        styles={customStyles}
        classNamePrefix="ohlala"
        onChange={handleOnChange}
        placeholder={t('components:select.placeholder')}
      />
      <FormFieldError fieldName={typeName} />
    </>
  );
};

ReportReasonsSelect.propTypes = {
  options: PropTypes.array.isRequired,
  initOptions: PropTypes.any,
  required: PropTypes.bool,
  withLabel: PropTypes.bool,
  typeName: PropTypes.string,
};

ReportReasonsSelect.defaultProps = {
  required: false,
  withLabel: false,
};

export default ReportReasonsSelect;
