import React, { useEffect, useRef, useState } from 'react';

import WithDelay from 'components/WithDelay';
import { LoaderInline } from 'components/layout/loader';
import { getStorySrc } from 'helpers/s3media';
import styles from './../../stories.module.css';

export const Video = ({
  story,
  action,
  isPaused,
  isActive,
  messageHandler,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [muted, setMuted] = useState(false);

  let vid = useRef(null);

  // useEffect(() => {
  //   setLoaded(false);
  // }, [story]);

  useEffect(() => {
    if (vid.current) {
      if (isPaused) {
        vid.current.pause();
      } else {
        vid.current.play().catch(() => {});
      }
    }
  }, [isPaused]);

  const onWaiting = () => {
    action('pause', true);
  };

  const onPlaying = () => {
    action('play', true);
  };

  const videoLoaded = () => {
    messageHandler('UPDATE_VIDEO_DURATION', { duration: vid.current.duration });
    setLoaded(true);
    vid.current
      .play()
      .then(() => {
        action('play');
      })
      .catch(() => {
        setMuted(true);
        vid.current.play().finally(() => {
          action('play');
        });
      });
  };

  if (!isActive) {
    return null;
  }

  return (
    <div className={styles.videoContainer}>
      <video
        ref={vid}
        className={styles.storyContent}
        src={getStorySrc(story.media)}
        controls={false}
        onLoadedData={videoLoaded}
        playsInline
        onWaiting={onWaiting}
        onPlaying={onPlaying}
        muted={muted}
        autoPlay
        webkit-playsinline="true"
      />
      {!loaded && (
        <WithDelay>
          <LoaderInline centered color="white" />
        </WithDelay>
      )}
    </div>
  );
};

export default Video;
