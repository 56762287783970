import { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Chat,
  Channel,
  Thread,
  Window,
  ChannelList,
  MessageList,
  MessageInput,
} from 'stream-chat-react';

import { useWindowWidthLessThan } from 'hooks';
import { ChatContext } from './chatContext';
import { setUnreadMessagesCount } from './chatSlice';
import { selectUserInfo } from 'features/auth/authSlice';
import ChatChannelHeader from './chatChannelHeader/ChatChannelHeader';
import ChannelPreview from './channelPreview/ChannelPreview';
import { LoaderRelative } from 'components/layout/loader';
import ChannelsListHeader from './channelsListHeader/ChannelsListHeader';
import InfoBar from 'components/infoBar/InfoBar';
import ChatInput from './chatInput/ChatInput';

const ChatView = (props) => {
  const { chatClient } = useContext(ChatContext);
  const { uid } = useSelector(selectUserInfo);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { i18n } = props;

  const smallView = useWindowWidthLessThan(961);
  const activeChannel = id || !smallView ? id : '0';

  useEffect(() => {
    dispatch(setUnreadMessagesCount(0));
  }, [dispatch]);

  return (
    <Chat client={chatClient} i18nInstance={i18n}>
      <ChannelList
        List={ChannelsListHeader}
        Preview={(props) => <ChannelPreview {...props} />}
        customActiveChannel={activeChannel}
        filters={{ members: { $in: [`${uid}`] } }}
        LoadingIndicator={LoaderRelative}
        EmptyStateIndicator={() => <div></div>}
      />
      <Channel
        EmptyStateIndicator={() => (
          <InfoBar info={t('features:chat.emptyMessageList')} />
        )}
      >
        <Window>
          <ChatChannelHeader />
          <MessageList />
          <MessageInput Input={ChatInput} focus />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};

export default ChatView;
