import {useCallback, useContext, useState} from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
//import cx from 'classnames';

import LoginForm from './LoginForm';
import NavTabs from 'components/navTabs';
import { Row, Col } from 'components/layout/grid';
import {
  login,
  //auth,
  selectLoginState,
  //setUserAfterLogin,
} from 'features/auth/authSlice';
import {
  //saveLocation,
  //selectSavedLocation,
  selectCountries,
  //setShowTutorial,
} from 'app/miscSlice';
/*import { resetSearch } from 'features/userList/search/searchSlice';
import { resetGlobalFeed } from 'features/feed/feedSlice';
import { resetUserList } from 'features/userList/userListSlice';
import { resetEpisodesList } from 'features/dating/episodes/episodesSlice';
import { resetStories } from 'features/stories/storiesSlice';*/
import { AuthLayoutContext } from 'components/layout/layouts/Context';
import { getLayoutImageSrc } from 'helpers/s3media';
/*import { trackEvent } from 'helpers/tracking';*/
import { authLeft } from 'assets/default';
import { Mail, MailActive, Phone, PhoneActive } from 'assets/icons/auth';
import styles from 'features/auth/auth.module.css';
import SocialLogin from "../SocialLogin";
import {useLoginContext} from "./LoginContext";
import cx from "classnames";

const LoginTabs = ({ activeTab, onPhoneClick, onMailClick }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.tabsWrapper}>
      <ul className={styles.tabs}>
        <li
          onClick={onPhoneClick}
          className={cx(styles.tab, { [styles.tabActive]: activeTab })}
        >
          <span>
            {activeTab ? <MailActive width="26" /> : <Mail width="26" />}
            {t('features:auth.login.email')}
          </span>
        </li>
        <li
          onClick={onMailClick}
          className={cx(styles.tab, { [styles.tabActive]: !activeTab })}
        >
          <span>
            {activeTab ? <Phone width="16" /> : <PhoneActive width="16" />}
            {t('features:auth.login.phone')}
          </span>
        </li>
      </ul>
    </div>
  );
};

const Login = () => {
  const [activeTab, setActiveTab] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const countries = useSelector(selectCountries);
  const { loading, hasError } = useSelector(selectLoginState);
  const { smallView, tabItems } = useContext(AuthLayoutContext);
  const { t } = useTranslation();
  const {afterLoginTasks} = useLoginContext();

  const createLoginPayload = useCallback(
    (values) => {
      const payload = { password: values.password };
      if (!values.phone) {
        payload.email = values.email;
      } else {
        payload.phone = values.phone;
        payload.countryID = values.countryID || countries[0].country_id;
      }

      return payload;
    },
    [countries]
  );



  const loginAction = (values) => {
    const loginPayload = createLoginPayload(values);

    dispatch(login(loginPayload))
      .then(unwrapResult)
      .then((result) => {
        history.replace('/');
        afterLoginTasks(result);
      })
      .catch((err) => {
        toast.error(t('main:errors.somethingWentWrong'));
      });
  };

  return (
    <Row className={styles.authWrapper}>
      <Col className={styles.left} xl={6} hidden="xs" visible="xl">
        <img src={getLayoutImageSrc(authLeft)} alt="auth" />
      </Col>
      <Col className={styles.right} xl={6}>
        <Row className={styles.formRow} center="xs" middle="xs" start="xl">
          <Col xs={12} className={styles.formCol}>
            <div className={styles.login}>
              {!smallView && <NavTabs items={tabItems} />}
              <div className={styles.textRow}>
                <h1>
                  {t('features:auth.login.header')}
                  <span>{t('features:auth.login.subHeader')}</span>
                </h1>
              </div>
              <div className={styles.tabsRow}>
                <LoginTabs
                  activeTab={activeTab}
                  onPhoneClick={() => setActiveTab(true)}
                  onMailClick={() => setActiveTab(false)}
                />
              </div>
              <div className={styles.formRow}>
                <LoginForm
                  type={activeTab ? 'email' : 'phone'}
                  onSubmit={loginAction}
                  isLoading={loading}
                  hasError={hasError}
                />
                <SocialLogin afterLoginTasks={afterLoginTasks}/>
                <div className={styles.signupLink}>
                  <p>{t('features:auth.login.noAccount')}</p>
                  <Link to="/register">{t('features:auth.login.signUp')}</Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Login;
