import React from 'react';
//import {useHistory} from 'react-router-dom';
import EventCard from "./EventCard";

const EpisodesListItem = ({episode, isPublic, hideFilter}) => {
    //const history = useHistory();
    //const {profile} = episode;

    /*const handleClick = () => {
        return history.push(`/profile/${profile.id}`, {episode: episode});
    };*/

    return (
        <EventCard
            episode={episode}
            hideFilter={hideFilter}
            isPublic={isPublic}/>
    );
};

export default EpisodesListItem;
