import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { Row, Col } from 'components/layout/grid';
import ActivityFeed from 'features/feed/ActivityFeed';
import SuggestionsList from './suggestions/SuggestionsList';
import Loader from 'components/layout/loader';
import ProfileInfo from './profileInfo/ProfileInfo';
import ProfileContent from './ProfileContent';
import ProfileFields from './ProfileFields';
import ProfileMedia from './ProfileMedia';
import FullScreenStories from 'features/stories/Stories';
import styles from './profile.module.css';

import {
  setShowSuggestions,
  resetShowSuggestions,
} from 'features/user/suggestions/suggestionsSlice';
import {
  getMyProfileImages as getImages,
  getMyProfileVideos as getVideos,
  getMyStories as getStories,
  selectMyProfile,
  resetMyProfile,
} from './profileSlice';

const MyProfileView = ({ user }) => {
  const [showStories, setShowStories] = useState(false);
  const { images, videos, stories } = useSelector(selectMyProfile);
  const hasStories = !isEmpty(stories.items);
  const hasMoreVideos = videos.next > 1;
  const hasMoreImages = images.next > 1;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getImages());
    dispatch(getVideos());
    dispatch(getStories(user.id));
    dispatch(setShowSuggestions());
    return () => {
      dispatch(resetMyProfile());
      dispatch(resetShowSuggestions());
    };
  }, [dispatch, user.id]);

  const fetchMoreImages = () => {
    dispatch(getImages(images.next));
  };

  const fetchMoreVideos = () => {
    dispatch(getVideos(videos.next));
  };

  // is fetching data
  if (!videos.fetched || !images.fetched || !stories.fetched) {
    return <Loader />;
  }

  return (
    <>
      <Row>
        <Col sm={12} lg={8} className={styles.feedCollumn}>
          <ProfileInfo
            stories={hasStories ? stories.items[0].stories : null}
            storyAction={() => setShowStories(true)}
            profileUser={user}
            isMyProfile
          />

          <ProfileContent
            user={user}
            feed={<ActivityFeed feedType="user" location="my-profile" />}
            videos={
              !isEmpty(videos.items) ? (
                <ProfileMedia
                  files={videos.items}
                  getMore={fetchMoreVideos}
                  hasMore={hasMoreVideos}
                />
              ) : null
            }
            photos={
              !isEmpty(images.items) ? (
                <ProfileMedia
                  files={images.items}
                  getMore={fetchMoreImages}
                  hasMore={hasMoreImages}
                />
              ) : null
            }
            info={
              <ProfileFields
                city={user.town}
                age={user.age}
                languages={user.languages}
                smoker={user.smoker}
                height={user.size}
                profession={user.profession}
                body_type={user.body_type}
                hair_color={user.hair_color}
                hair_length={user.hair_length}
              />
            }
          />
        </Col>
        <Col sm={12} lg={4}>
          <SuggestionsList />
        </Col>
      </Row>
      {hasStories && (
        <FullScreenStories
          initialIndex={0}
          userStories={stories.items}
          showStories={showStories}
          closeStories={() => {
            setShowStories(false);
            dispatch(getStories(user.id));
          }}
        />
      )}
    </>
  );
};
export default MyProfileView;
