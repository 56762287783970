import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from 'app/api';
import AWS from 'aws-sdk';
import shortId from 'short-uuid';

import { trackEvent } from 'helpers/tracking';
import { useModal } from 'components/modal/useModal';
import Button from 'components/buttons/Button';
import { ImageEditor } from 'features/stories/components/create';
import { getFileMeta } from 'helpers/mediaFileMeta';
import {selectUserInfo, updateUser} from 'features/auth/authSlice';
import styles from './create.module.css';

const AddProfileHeaderTrigger = ({ children, className }) => {
  const [image, setImage] = useState(null);
  const [imageMeta, setImageMeta] = useState(null);
  const { uid, gender } = useSelector(selectUserInfo);
  const { t } = useTranslation();
  const modalUpload = useModal();
  const dispatch = useDispatch();
  const fileSelect = useRef(null);

  const isUploadDisabled = process.env.REACT_APP_DISABLE_UPLOAD === 'true';

  AWS.config.update({
    region: process.env.REACT_APP_S3_BUCKET_REGION,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_S3_POOL_ID,
    }),
  });

  const handleFileUpload = async () => {
    trackEvent({
      category: 'profile',
      action: 'create banner click',
      label: gender,
    });
    if (isUploadDisabled) {
      return modalUpload.showModal();
    } else if (fileSelect) {
      fileSelect.current.click();
    }
  };

  const handleCloseImageEditor = () => {
    setImage(null);
    setImageMeta(null);
    fileSelect.current.value = null;
  };

  const onInputChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const fileInfo = await getFileMeta(file);
    if (!fileInfo) return;

    if (fileInfo.type === 'image') {
      setImage(file);
      setImageMeta(fileInfo);
    }
  };

  const imageWriter = {
    store: (state, options, onprogress) =>
      new Promise((resolve, reject) => {
        const { dest } = state;
        const fileExt = dest.name.split('.').pop();
        const fileId = `headerimage/${uid}/${shortId.generate()}`;
        const s3Key = `${fileId}.${fileExt}`;
        let fileInfo = {
          duration: imageMeta.duration,
          width: imageMeta.width,
          height: imageMeta.height,
          type: imageMeta.type,
          size: dest.size,
          format: fileExt,
          public_id: fileId,
        };
        const upload = new AWS.S3.ManagedUpload({
          params: {
            Bucket: process.env.REACT_APP_S3_BUCKET_NAME_UPLOAD,
            Key: s3Key,
            Body: dest,
            ContentType: "image/jpg, image/png, image/jpeg",
          },
        });
        upload.on('httpUploadProgress', (e) => {
          onprogress(true, e.loaded, e.total);
        });

        const promise = upload.promise();
        promise.then(
          function (data) {
            fileInfo.s3_data = data;
            api
              .put('v2/user/profile/headerimage', {
                media: JSON.stringify(fileInfo),
                status: 3, // success
              })
              .then((res) => {
                if (res.data.status) {
                  toast.success(t('features:banner.add.success'));
                  setTimeout(() => {
                    dispatch(updateUser());
                  }, 1000);
                  resolve(state);
                } else {
                  throw new Error('banner update error');
                }
              })
              .catch((err) => {
                reject(t('main:errors.somethingWentWrong'));
              });
          },
          function (err) {
            reject(t('main:errors.somethingWentWrong'));
          }
        );
      }),
  };

  return (
    <>
      <div onClick={handleFileUpload} className={className}>
        <input
          ref={fileSelect}
          type="file"
          name="file"
          accept="image/*"
          onChange={onInputChange}
          className={styles.uploadInput}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
        {image && (
          <ImageEditor
            imageWriter={imageWriter}
            image={image}
            onHide={handleCloseImageEditor}
          />
        )}
        {children}
      </div>
      {isUploadDisabled && (
        <modalUpload.RenderModal
          buttonClose
          title={t('main:uploadDisabled.title')}
          children={<p>{t('main:uploadDisabled.text')}</p>}
          footer={
            <Button
              name="btn"
              onClick={() => modalUpload.hideModal()}
              type="button"
              fullWidth
            >
              {t('main:main.close')}
            </Button>
          }
        />
      )}
    </>
  );
};

AddProfileHeaderTrigger.propTypes = {
  onCreateStory: PropTypes.func,
};

AddProfileHeaderTrigger.defaultProps = {
  onCreateStory: () => {},
};

export default AddProfileHeaderTrigger;
