import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import api from 'app/api';
import { IconCross, IconCheck } from 'assets/icons';
import Button from 'components/buttons/Button';
import { trackEvent } from 'helpers/tracking';
import { LoaderRelative } from 'components/layout/loader';
import styles from './confirmEmail.module.css';

const ConfirmEmailLanding = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const handleError = (error) => {
      setLoading(false);
      switch (error.code) {
        case 509:
        case 101:
          setError(t('features:auth.confirmEmailError.invalidOrExpired'));
          break;
        case 201:
          setError(t('features:auth.confirmEmailError.deletedBlocked'));
          break;
        default:
          setError(t('features:auth.confirmEmailError.failedVerify'));
          break;
      }
    };
    const checkToken = async () => {
      const hash = new URLSearchParams(search);
      const code = hash.get('hash');
      if (code) {
        try {
          const res = await api.post(
            `/v2/user/verification/email?code=${code}`
          );
          if (res.data.error) {
            return handleError(res.data.error);
          }
          setLoading(false);
          trackEvent({
            category: 'auth',
            action: 'register',
            label: 'email_confirmed',
          });
        } catch (error) {
          handleError(error);
          setLoading(false);
        }
      } else {
        setLoading(false);
        return setError(t('features:auth.confirmEmailError.invalidOrExpired'));
      }
    };
    checkToken();
  }, [t, search]);

  if (loading)
    return (
      <div className={styles.container}>
        <LoaderRelative />
      </div>
    );

  if (error)
    return (
      <div className={styles.container}>
        <div className={styles.icoBig}>
          <IconCross />
        </div>
        <div className={styles.copy}>
          <h4>{t('main:main.Error')}</h4>
          <p>
            {error}
            <br />
            {t('features:auth.passwordRecovery.tryAgain')}
          </p>
        </div>
      </div>
    );

  return (
    <div className={styles.container}>
      <div className={styles.icoBig}>
        <IconCheck />
      </div>
      <div className={styles.copy}>
        <h4>{t('features:auth.confirmEmailError.emailConfirmed')}</h4>
        <p>
          {t('features:auth.confirmEmailError.canLogin')}
          <br />
        </p>
      </div>

      <div className={styles.resend}>
        <Button
          onClick={() => push('/login')}
          fullWidth
          size="xl"
          name="btn-gradient"
          type="submit"
        >
          {t('main:main.Login')}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmEmailLanding;
