import { useDispatch } from 'react-redux';
import { Row, Col } from 'components/layout/grid';
import ActivityFeedPublic from 'features/feed/ActivityFeedPublic';
import ProfileInfo from './profileInfo/ProfileInfo';
import ProfileContent from './ProfileContent';
import ProfileFields from './ProfileFields';
import SuggestionsListPublic from './suggestions/SuggestionsListPublic';
import { setShowPublicRegister } from 'app/miscSlice';

const ProfilePublicView = ({ profileUser }) => {
  const dispatch = useDispatch();

  const openModal = () => {
    dispatch(setShowPublicRegister(true));
  };

  return (
    <Row>
      <Col sm={12} lg={8}>
        <ProfileInfo profileUser={profileUser} publicModal={openModal} />
        <ProfileContent
          user={profileUser}
          feed={
            <ActivityFeedPublic
              profileUser={profileUser}
              publicModal={openModal}
            />
          }
          info={
            <ProfileFields
              city={profileUser.town_name}
              age={profileUser.age}
              languages={profileUser.languages}
              smoker={profileUser.smoker}
              height={profileUser.size}
              profession={profileUser.profession}
              body_type={profileUser.body_type}
              hair_color={profileUser.hair_color}
              hair_length={profileUser.hair_length}
            />
          }
        />
      </Col>
      <Col sm={12} lg={4}>
        <SuggestionsListPublic profile_id={profileUser.id} />
      </Col>
    </Row>
  );
};
export default ProfilePublicView;
