import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useParams, useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { getMyEpisodesPath } from 'helpers/dating';
import Loader from 'components/layout/loader/Loader';
//import MyEpisodesListItem from './MyEpisodesListItem';
//import ApplicationsList from '../../applications/ApplicationsList';
import { selectUserInfo } from 'features/auth/authSlice';
import {
  getEpisodeDetail,
  selectEpisodeDetail,
  resetEpisodeDetail,
} from '../episodesSlice';
import EventDetails from "../EventDetails";

const MyEpisodeDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const episode = useSelector(selectEpisodeDetail);
  const user = useSelector(selectUserInfo);
  const myEpisodesPath = getMyEpisodesPath(user.gender);
  const { applications } = episode;


  useEffect(() => {
    dispatch(getEpisodeDetail({ episode_id: id }))
      .then(unwrapResult)
      .then((result) => {
        if (!result.status) {
          history.push(myEpisodesPath);
        }
      });

    return () => dispatch(resetEpisodeDetail());
  }, [dispatch, id, history, myEpisodesPath]);

  if (isEmpty(episode)) {
    return <Loader />;
  }

  return (
    <>
      {/*<MyEpisodesListItem episode={episode} isDetail />*/}
      <EventDetails
          episode={episode}
          applications={applications}
      />

      {/*{!isEmpty(applications) && <ApplicationsList episode={episode} />}*/}
    </>
  );
};

export default MyEpisodeDetail;
