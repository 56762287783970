import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {unwrapResult} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {useFormikContext, Formik, Form} from 'formik';
import {de, enGB} from 'date-fns/locale';
import {
    getTime,
    format,
    addMinutes,
    addHours,
    isToday,
    isTomorrow,
} from 'date-fns';

import {getMyEpisodesPath} from 'helpers/dating';
import {FormRow, Label, OCalendar} from 'components/forms';
import DateStartRangeInput from 'components/forms/DateTimeRangeInput';
import Button from 'components/buttons/Button';
import SubHeader from 'components/layout/subHeader/SubHeader';
import ApplicationNoDating from '../applications/ApplicationsNoDating';
import BlockCreateView from './BlockCreateView';
import {
    preferenceAdapterIn,
    preferenceAdapterOut,
} from '../dateRequestSteps/DatePreference';
import Loader from 'components/layout/loader/Loader';

import {selectUserInfo} from 'features/auth/authSlice';
import {
    createEpisode,
    createEpisodeCheck,
} from 'features/dating/episodes/episodesSlice';

import {DateTime} from 'assets/icons/profile';
import {IconEdit} from 'assets/icons';
import styles from './episodeCreation.module.css';
//import DurationRangeInput from '../dateRequestSteps/DurationRangeInput';
import {VerificationModal} from "../episodes/VerificationModal";
import {useModal} from "../../../components/modal/useModal";
import StepOne from "../dateRequestSteps/StepOne";
import StepThree from "../dateRequestSteps/StepThree";

const EpisodeCreationStep = ({
                                 children,
                                 stepNum,
                                 stepName,
                                 description,
                                 tip,
                                 currentStep,
                                 onNextStep,
                                 onPrevStep,
                             }) => {
    const {values, errors} = useFormikContext();
    const errorsVal = {};
    //const isNextDisabled = stepName !== "description" && (!isEmpty(errors[stepName]) || !values[stepName]);

    if (stepName === 'dateType') {
        if (!values['name']) {
            errorsVal.firstName = 'Name is required';
        }
        if (!isEmpty(errors['dateType']) || !values['dateType']) {
            errorsVal.dateType = 'Event Type is required';
        }
        if (!values['city']) {
            errorsVal.city = 'Country / City is required';
        }
        if (!values['venue_name']) {
            errorsVal.venue_name = 'Venue Name is required';
        }
        if (!values['latitude']) {
            errorsVal.venue_name = 'Venue Name is required';
        }
        if (!values['longitude']) {
            errorsVal.venue_name = 'Venue Name is required';
        }
    }

    if (stepName === 'looking_for') {
        if (!values['date']) {
            errorsVal.date = 'Event is required';
        }
        if (!values['time']) {
            errorsVal.time = 'Time is required';
        }
        if (!values['duration']) {
            errorsVal.time = 'Duration is required';
        }
    }

    if (stepName === 'description') {
        if (!values['desired_female_company'] && !values['desired_male_company']) {
            errorsVal.desired_female_company = 'Desire Company is required';
        }
        if (values['desired_female_company'] === 0 && values['desired_male_company'] === 0) {
            errorsVal.desired_female_company = 'Desire Company is required';
        }
        if (!values['desired_female_company_price'] && !values['desired_male_company_price']) {
            errorsVal.desired_female_company_price = 'Price is required';
        }
        if (!values['cancellationPolicy']) {
            errorsVal.cancellationPolicy = 'Cancellation Policy is required';
        }
        if (!values['description']) {
            errorsVal.description = 'Description is required';
        }
    }

    //console.log("errorsVal",Object.keys(errorsVal).length)
    const isNextDisabled = !!Object.keys(errorsVal).length;

    const {t} = useTranslation();

    const stepClassNames = `${styles.step} ${
        currentStep === stepNum ? styles.active : ''
    }`;

    return (
        <div className={stepClassNames}>
            <div className={styles.header}>
                <h3>
                    <span className={styles.stepNum}>{stepNum + 1}</span>
                    {description}
                </h3>
                {tip && <p>{tip}</p>}
            </div>

            {children}
            <div className={styles.footerButton}>
                {onPrevStep && (
                    <Button
                        size="l"
                        type="button"
                        onClick={onPrevStep}
                        className={styles.previous}
                    >
                        {t('main:main.Previous', 'Previous')}
                    </Button>
                )}

                {onNextStep && (
                    <Button
                        size="l"
                        type="button"
                        disabled={isNextDisabled}
                        onClick={onNextStep}
                        className={styles.next}
                    >
                        {t('main:main.Next', 'Next')}
                    </Button>
                )}
                {currentStep === 2 && (
                    <Button size="l" type="submit" className={styles.submit} disabled={isNextDisabled}>
                        {t(`features:eventRequest.sendRequest`, 'Send Request')}
                    </Button>
                )}
            </div>
        </div>
    );
};

const EpisodeCreationFormElements = ({values, user, screenName, updateParentFiles}) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [step, setCurrentStep] = useState(0);
    const { t, i18n } = useTranslation();
    const lang = i18n.language === 'de' ? de : enGB;

    const nextStep = () => {
        setCurrentStep(step + 1);
    };

    const prevStep = () => {
        setCurrentStep(step - 1);
    };

    return (
        <Form>
            <EpisodeCreationStep
                stepName="dateType"
                currentStep={step}
                onNextStep={nextStep}
                onPrevStep={false}
                stepNum={0}
                description={t(
                    `features:eventRequest.whatKind`,
                    'What event would you like to share with the community?'
                )}
                /*tip={t(
                  'features:eventRequest.selectOptions',
                  'Select one or more options.'
                )}*/
            >
                <StepOne user={user} updateParentFiles={updateParentFiles}/>
            </EpisodeCreationStep>
            <EpisodeCreationStep
                stepName="looking_for"
                currentStep={step}
                onNextStep={nextStep}
                onPrevStep={prevStep}
                stepNum={1}
                description={t(
                    `features:eventRequest.whatDateTime`,
                    'When and how long will the event take place?'
                )}
                /*tip={t(
                  'features:eventRequest.selectOptions',
                  'Select one or more options.'
                )}*/
            >
                <FormRow>
                    <Label
                        label={t('features:eventRequest.event_date')}
                        name="date"
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignContent: 'center',
                            }}
                        >
                            {!showCalendar ? (
                                <span
                                    className={styles.dateTrigger}
                                    onClick={(e) => setShowCalendar(true)}
                                >
                                    <DateTime/>
                                    {isToday(values.date)
                                        ? t('main:main.Today', 'Today')
                                        : isTomorrow(values.date)
                                            ? t('main:main.Tomorrow', 'Tomorrow')
                                            : format(values.date, 'PPP', {locale: lang})}
                                    <IconEdit style={{right: '25px', left: 'auto'}}/>
                                </span>
                            ) : (
                                <OCalendar
                                    name="date"
                                    onHideCalendar={() => setShowCalendar(false)}
                                />
                            )}
                        </div>
                    </Label>
                </FormRow>

                <FormRow>
                    <Label
                        label={t('features:eventRequest.event_time')}
                        name="time"
                    >
                        <DateStartRangeInput type="range" name="time" label=""/>
                    </Label>
                </FormRow>

                {/*<FormRow>
                    <Label
                        label={t('features:eventRequest.event_duration')}
                        name="duration"
                    >
                        <DurationRangeInput name="duration"/>
                    </Label>
                </FormRow>*/}

                {/*<DatePreference />*/}
            </EpisodeCreationStep>

            <EpisodeCreationStep
                stepName="description"
                currentStep={step}
                onNextStep={false}
                onPrevStep={prevStep}
                stepNum={2}
                description={t(
                    'features:eventRequest.whatCompanionship',
                    'What kind of companionship do you want ?'
                )}
                /*tip={t(
                  'features:eventRequest.whatType',
                  'Describe a bit what you are up for?'
                )}*/
            >
                <StepThree user={user} screenName={screenName}/>
            </EpisodeCreationStep>

            {/*<EpisodeCreationStep
        stepName="city"
        currentStep={step}
        onNextStep={nextStep}
        onPrevStep={prevStep}
        stepNum={3}
        description={t(
          `features:eventRequest.where`,
          'Where do you want to date?'
        )}
      >
        <div className="episodeLocation" style={{ marginTop: '30px' }}>
          <CountryCitySelect isDatingUser={user.dating} />
        </div>
      </EpisodeCreationStep>*/}

            {/*<EpisodeCreationStep
        stepName="date"
        currentStep={step}
        onNextStep={nextStep}
        onPrevStep={prevStep}
        stepNum={4}
        description={t(
          `features:eventRequest.whatDay`,
          'What day you would like to meet?'
        )}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignContent: 'center',
          }}
        >
          {!showCalendar ? (
            <span
              className={styles.dateTrigger}
              onClick={(e) => setShowCalendar(true)}
            >
              <DateTime />
              {isToday(values.date)
                ? t('main:main.Today', 'Today')
                : isTomorrow(values.date)
                ? t('main:main.Tomorrow', 'Tomorrow')
                : format(values.date, 'PPP', { locale: lang })}
              <IconEdit style={{ right: '25px', left: 'auto' }} />
            </span>
          ) : (
            <OCalendar
              name="date"
              onHideCalendar={() => setShowCalendar(false)}
            />
          )}
        </div>
      </EpisodeCreationStep>*/}

            {/* <EpisodeCreationStep
        stepName="time"
        currentStep={step}
        onNextStep={nextStep}
        onPrevStep={prevStep}
        stepNum={5}
        description={t(
          `features:eventRequest.whatTime`,
          'At what time your date shell start?'
        )}
      >
        <DateStartRangeInput type="range" name="time" label="" />
      </EpisodeCreationStep>*/}

            {/*<EpisodeCreationStep
        stepName="duration"
        currentStep={step}
        onNextStep={nextStep}
        onPrevStep={prevStep}
        stepNum={6}
        description={t(
          `features:eventRequest.whatDurationRange`,
          'how long your date duration ?'
        )}
      >
        <DurationRangeInput name="duration"/>
      </EpisodeCreationStep>*/}


            {/*<EpisodeCreationStep
        stepName="budgetRange"
        currentStep={step}
        onNextStep={false}
        onPrevStep={prevStep}
        stepNum={7}
        description={t(
          `features:eventRequest.whatBudgetRange`,
          'how much is your budget?'
        )}
        tip={t(
          'features:eventRequest.selecSingletOption',
          'Select one option'
        )}
      >
        <BudgetRange />
      </EpisodeCreationStep>*/}
        </Form>
    );
};

const EpisodeCreationView = ({screenName}) => {
    const [noDating, setNoDating] = useState(null);
    const [blockCode, setBlockCode] = useState(1);
    const me = useSelector(selectUserInfo);
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    const myEpisodesPath = getMyEpisodesPath(me.gender);
    const verificationModal = useModal();
    const [files, setFiles] = useState([]);

    const updateParentFiles = (files) => {
        setFiles(files);
    };

    const getPostFiles = () => {
        let validFiles = [];
        files.forEach(function (item, index) {
            if (item.serverId) {
                validFiles.push(item.serverId);
            }
        });
        return validFiles;
    };

    useEffect(() => {
        if (me.indispensable_verification === true) {
            verificationModal.showModal();
        }
        dispatch(createEpisodeCheck())
            .then(unwrapResult)
            .then((result) => {
                if (result.status) {
                    setBlockCode(0);
                } else {
                    setBlockCode(result.error.code);
                }
            });
    }, [dispatch, me, verificationModal]);

    if (blockCode > 0) {
        return blockCode === 1 ? <Loader/> : <BlockCreateView code={blockCode}/>;
    }

    if (me.indispensable_verification === true) {
        return <VerificationModal
            modalContext={verificationModal}
        />
    }

    if (noDating) {
        return <ApplicationNoDating country={noDating}/>;
    }

    const DateRequestSchema = Yup.object().shape({
        dateType: Yup.string().min(1, 'Choose activity').required('Required'),
        budgetRange: Yup.number().required(
            t(`features:dateRequestValidation.choose`, 'Required')
        ),
        looking_for: Yup.array().min(1, 'Choose at least one').required('Required'),
        name: Yup.string()
            .min(
                2,
                t(
                    `features:dateRequestValidation.tooShortDescription`,
                    'Name is too short!'
                )
            )
            .max(
                50,
                t(
                    `features:dateRequestValidation.tooLongDescription`,
                    'Name is too long!'
                )
            ),
        description: Yup.string()
            .min(
                2,
                t(
                    `features:dateRequestValidation.tooShortDescription`,
                    'Description is too short!'
                )
            )
            .max(
                300,
                t(
                    `features:dateRequestValidation.tooLongDescription`,
                    'Description is too long!'
                )
            ),
        date: Yup.string().required(
            t(`features:dateRequestValidation.dateRequired`, 'Required')
        ),
        time: Yup.number().required(
            t(`features:dateRequestValidation.timeRequired`, 'Required')
        ),
        duration: Yup.number().required(
            t(`features:dateRequestValidation.durationRequired`, 'Required')
        ),
    });

    const handleSubmit = (values) => {
        // start time + range steps * 30 min
        const starttime = getTime(addMinutes(values.dayStart, values.time * 30));
        const proccesedData = {
            duration: values.duration,
            time: format(starttime, 'yyyy-MM-dd HH:mm:ss'),
            types: values.dateType,
            event_type: values.dateType.toString(),
            place_id: values.place_id,
            venue_name: values.venue_name,
            venue_address: values.venue_address,
            photos: values.photos,
            extra: values.extra,
            latitude: values.latitude,
            longitude: values.longitude,
            desired_female_company: values.desired_female_company,
            desired_female_company_price: values.desired_female_company_price,
            desired_male_company: values.desired_male_company,
            desired_male_company_price: values.desired_male_company_price,
            media:getPostFiles(),
            name: values.name,
            description: values.description,
            budget: values.budgetRange,
            city: values.city,
            city_short: values.city_short,
            state: values.state,
            state_short: values.state_short,
            country: values.country,
            timezone: values.timezone,
            cancellation_policy: values.cancellationPolicy,
            looking_for: preferenceAdapterOut(values.looking_for),
        };

        dispatch(createEpisode(proccesedData))
            .then(unwrapResult)
            .then((result) => {
                if (result.status) {
                    if (!result.data.dating) {
                        setNoDating(values.country_name);
                    } else {
                        history.push(myEpisodesPath);
                    }
                } else {
                    switch (result.error.code) {
                        case 1108:
                            toast.error(
                                t('features:dateRequestValidation.duplicatedEpisode')
                            );
                            break;
                        case 1109:
                            // better don't be explicit with the spam-user and show just generic message
                            toast.error(t('features:dateRequestValidation.spamFilter'));
                            break;
                        default:
                            toast.error(t('main:errors.somethingWentWrong'));
                    }
                }
            });
    };

    return (
        <div className={styles.container}>
            <SubHeader backBtn hideOnMobile screenName={screenName}/>
            <Formik
                initialValues={{
                    budgetRange: 0,
                    dateType: [],
                    name: '',
                    media: '',
                    venue_name: '',
                    latitude: '',
                    longitude: '',
                    description: '',
                    date: addHours(new Date(), 3),
                    time: '0',
                    desired_female_company: 0,
                    desired_female_company_price: 0,
                    desired_male_company: 0,
                    desired_male_company_price: 0,
                    duration: 1,
                    city: me.town_name,
                    city_slug: me.town,
                    // city_id: 0,
                    country: me.country,
                    country_name: me.country_name,
                    looking_for: preferenceAdapterIn(me.looking_for),
                }}
                validationSchema={DateRequestSchema}
                onSubmit={(data) => {
                    // console.log('submit');
                    handleSubmit(data);
                }}
            >
                {({values}) => {
                    return <EpisodeCreationFormElements values={values} user={me} screenName={screenName} updateParentFiles={updateParentFiles}/>;
                }}
            </Formik>
        </div>
    );
};

export default EpisodeCreationView;
