import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {getBannerSrc, checkBannerImageSrc} from 'helpers/s3media';
import styles from './profileheader.module.css';
import cx from 'classnames';
import AddProfileHeaderTrigger from "./AddProfileHeaderTrigger";
import {IconAddMediaActive, IconCross, IconEdit} from "../../assets/icons";

const ProfileHeader = ({
                           size,
                           width,
                           height,
                           image,
                           alt,
                           border,
                           circle,
                           online,
                           plus,
                           gender,
                           onClick,
                           noAvatarCallBack,
                           className,
                           style,
                           wrapperStyle,
                           wrapperClassName,
                           removeImage
                       }) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [imageDefaultSrc, setImageDefaultSrc] = useState(null);
    //const dispatch = useDispatch();
    //const {t} = useTranslation();

    useEffect(() => {
        const image_src =
            typeof image === 'string'
                ? checkBannerImageSrc(image, width, height)
                : getBannerSrc(image, width, height);
        const srcDefault = getBannerSrc(null, width, height);

        setError(false);
        setImageSrc(image_src);
        setImageDefaultSrc(srcDefault);
    }, [image, gender, width, height]);

    const HandleImageError = () => {
        if (!error) {
            noAvatarCallBack();
            setError(true);
            setImageSrc(imageDefaultSrc);
        }
    };

    /*const wrapperInlineStyle = {
      width: `${width}px`,
      height: `${height}px`,
      ...wrapperStyle,
    };

    const wrapperCls = cx(styles.wrapper, {
      [styles.cursor]: onClick && image && !error,
      [styles.circle]: circle,
      [wrapperClassName]: wrapperClassName,
    });*/

    const additionalCls = cx({
        [styles.border]: border,
        [styles.loaded]: loaded,
        [className]: className,
    });

    /*const onlineCls = cx(styles.online, {
      [styles.xxl]: size > 140,
      [styles.xl]: size <= 140 && size > 110,
      [styles.l]: size <= 110 && size > 90,
      [styles.m]: size <= 90 && size > 70,
      [styles.s]: size <= 70 && size > 50,
      [styles.xs]: size <= 50,
    });

    const plusCls = cx(styles.plus, {
      [styles.l]: size > 140,
      [styles.m]: size <= 140 && size > 90,
      [styles.s]: size <= 90,
    });*/

    return (
        <div className={styles.prelative}>
            {imageSrc && (
                <img
                    /*style={{ width: `${width}px`, height: `${height}px`, ...style }}*/
                    style={{width: `100%`, height: `100%`, ...style}}
                    className={cx(styles.avatar, additionalCls)}
                    src={imageSrc}
                    alt={alt}
                    onLoad={() => setLoaded(true)}
                    onError={() => HandleImageError()}
                />
            )}
            <div className={styles.iconsC}>
                <AddProfileHeaderTrigger>
                    {!image && (
                        <div className={styles.Ellipse1}>
                            <IconAddMediaActive width="13"/>
                        </div>
                    )}
                    {image && (
                        <div className={styles.Ellipse1}>
                            <IconEdit width="13"/>
                        </div>
                    )}
                </AddProfileHeaderTrigger>
                {image && (
                    <div className={styles.Ellipse1} onClick={() => removeImage()}>
                        <IconCross width="13"/>
                    </div>
                )}
            </div>
        </div>
    );
};

ProfileHeader.propTypes = {
    size: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    alt: PropTypes.string,
    border: PropTypes.bool,
    circle: PropTypes.bool,
    online: PropTypes.bool,
    plus: PropTypes.bool,
    gender: PropTypes.string,
    onClick: PropTypes.func,
    noAvatarCallBack: PropTypes.func,
    image: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.string,
    ]),
    className: PropTypes.string,
};

ProfileHeader.defaultProps = {
    noAvatarCallBack: () => {
    },
    size: 110,
    width: 731,
    height: 210,
    circle: false,
    online: false,
    plus: false,
    border: false,
    gender: 'male',
    alt: '',
};

export default ProfileHeader;
