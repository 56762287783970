import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';

import { getEpisodesPath } from 'helpers/dating';
import { ApplicationButtons as Buttons } from 'components/buttons';
import { getEpisodeDetail } from '../episodes/episodesSlice';
import { startChatTransaction } from 'features/transactions/transactionsSlice';
import {acceptApplication, denyApplication, getApplications} from './applicationsSlice';
import { selectUserInfo } from 'features/auth/authSlice';

const ApplicationButtons = ({
  application,
  episodeId,
  onChatClick,
  loaderCallback,
  episodesCount,
  size,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const me = useSelector(selectUserInfo);
  const episodesPath = getEpisodesPath(me.gender);

  const { profile, chat_id } = application;

  const handleOpenChat = () => {
    if (chat_id > 0) {
      history.push(`/chat/${me.uid}-${profile.uid}-messaging`);
    } else {
      dispatch(startChatTransaction({ actor: profile, episodeId: episodeId }));
    }
  };

  const removeApplication = () => {
    dispatch(getEpisodeDetail({ episode_id: episodeId }))
      .then(unwrapResult)
      .then((result) => {
        if (loaderCallback) {
          loaderCallback(false);
        }
        if (!result.status) {
          history.push(episodesPath);
        }
      });
  };

  const handleAccept = async () => {
    if (loaderCallback) {
      loaderCallback(true);
    }
    dispatch(acceptApplication({ id: application.id }))
        .then(unwrapResult)
        .then((result) => {
          if (episodesCount === 1) {
            dispatch(getApplications())
                .then(unwrapResult)
                .then((result) => {
                  if (loaderCallback) {
                    loaderCallback(false);
                  }
                });
          } else {
            removeApplication();
          }
        });
  };

  const handleDeny = async () => {
    if (loaderCallback) {
      loaderCallback(true);
    }
    dispatch(denyApplication({ id: application.id }))
      .then(unwrapResult)
      .then((result) => {
        if (episodesCount === 1) {
          dispatch(getApplications())
            .then(unwrapResult)
            .then((result) => {
              if (loaderCallback) {
                loaderCallback(false);
              }
            });
        } else {
          removeApplication();
        }
      });
  };

  return (
    <Buttons
      handleDeny={handleDeny}
      handleAccept={handleAccept}
      handleOpenChat={handleOpenChat}
      size={size}
    />
  );
};

ApplicationButtons.propTypes = {
  application: PropTypes.object.isRequired,
  loaderCallback: PropTypes.func,
  episodeId: PropTypes.number,
  episodesCount: PropTypes.number,
  size: PropTypes.string,
};

ApplicationButtons.defaultProps = {
  episodesCount: 0,
  size: 'xl',
};

export default ApplicationButtons;
