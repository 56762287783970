import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../app/api';
import isEmpty from 'lodash/isEmpty';

export const getApplications = createAsyncThunk(
  'applications/getApplications',
  async (params) => {
    const response = await api.get(`v2/episodes/applications`);
    return response.data;
  }
);

export const acceptApplication = createAsyncThunk(
    'applications/accept',
    async ({ id }) => {
      const response = await api.post(`v2/episode/application/${id}/accept`);
      return response.data;
    }
);

export const denyApplication = createAsyncThunk(
  'applications/deny',
  async ({ id }) => {
    const response = await api.post(`v2/episode/application/${id}/deny`);
    return response.data;
  }
);

export const applicationsSlice = createSlice({
  name: 'applications',
  initialState: {
    unreadCount: 0,
    applications: {
      loading: false,
      hasError: false,
      success: false,
      data: [],
    },
  },
  reducers: {
    // setNewApplicationsCount: (state, action) => {
    //   state.unreadCount = action.payload;
    // },
    resetNewApplicationsCount: (state) => {
      state.unreadCount = 0;
    },
    incrementNewApplicationsCount: (state) => {
      state.unreadCount += 1;
    },
  },
  extraReducers: {
    [getApplications.pending]: (state, action) => {
      state.applications.loading = true;
      state.applications.hasError = false;
      state.applications.success = false;
    },
    [getApplications.fulfilled]: (state, action) => {
      state.applications.loading = false;
      if (action.payload.status) {
        state.applications.success = true;
        state.applications.data = action.payload.data;
      } else {
        state.applications.hasError = true;
      }
    },
    [getApplications.rejected]: (state, action) => {
      state.applications.loading = false;
      state.applications.hasError = true;
    },
  },
});

export const {
  // setNewApplicationsCount,
  resetNewApplicationsCount,
  incrementNewApplicationsCount,
} = applicationsSlice.actions;

export const selectNewApplicationsCount = (state) =>
  state.applications.unreadCount;
export const selectApplications = (state) =>
  state.applications.applications.data;
export const selectHasEpisode = (state) =>
  !isEmpty(state.applications.applications.data.episodeDetails);
export const selectFetched = (state) =>
  state.applications.applications.success ||
  state.applications.applications.hasError;

export default applicationsSlice.reducer;
