import React, { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import ActionsDrawer from './Drawer';
import ContextMenu from './Popover';
import { BlockUserModal, ReportUserModal } from './Modals';
import { useModal } from 'components/modal/useModal';
import { IconDots } from 'assets/icons';
import styles from './profileActions.module.css';

const ProfileActions = ({
  profileUser,
  mainActions,
  darkTrigger,
  pinkTrigger,
  isMyProfile,
  isChat,
  isPublic,
  isMobile,
}) => {
  const [showMenu, setShowMenu] = useState();
  const [showDrawer, setShowDrawer] = useState(false);
  const blockModal = useModal();
  const reportkModal = useModal();
  const menuRef = useRef(null);

  const trackClickOutsideMenu = useCallback(
    (e) => {
      if (!menuRef.current) return;

      document.removeEventListener('scroll', trackClickOutsideMenu, true);

      if (!menuRef.current.contains(e.target)) {
        document.removeEventListener('click', trackClickOutsideMenu, true);
        setShowMenu(false);
      }
    },
    [menuRef]
  );

  const closeContextMenu = () => {
    document.removeEventListener('click', trackClickOutsideMenu, true);
    document.removeEventListener('scroll', trackClickOutsideMenu, true);
    setShowMenu(false);
  };

  const onClickHandler = (e) => {
    if (isMobile) {
      e.stopPropagation();
      e.preventDefault();
      setShowDrawer(true);
    } else {
      setShowMenu(true);
    }
  };

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('click', trackClickOutsideMenu, true);
      document.addEventListener('scroll', trackClickOutsideMenu, true);
    }
  }, [showMenu, trackClickOutsideMenu]);

  return (
    <>
      <div className={styles.container}>
        <span onClick={(e) => onClickHandler(e)} className={styles.trigger}>
          {profileUser.banner === null && (
            <IconDots
              className={cx(styles.headerIconRight, {
                [styles.pinkTrigger]: pinkTrigger,
              })}
            />
          )}
          {profileUser.banner && (
              <IconDots
                  className={cx(styles.headerIconRight, {
                    [styles.pinkTrigger]: pinkTrigger,
                  })}
              />
          )}
        </span>
        <ContextMenu
          show={showMenu}
          profileUser={profileUser}
          modal={blockModal}
          reportModal={reportkModal}
          onCloseMenu={closeContextMenu}
          isChat={isChat}
          ref={menuRef}
        />
      </div>
      {!showMenu && (
        <>
          {blockModal.isVisible && (
            <BlockUserModal
              profileUser={profileUser}
              modalContext={blockModal}
            />
          )}
          {reportkModal.isVisible && (
            <ReportUserModal
              profileUser={profileUser}
              modalContext={reportkModal}
            />
          )}
        </>
      )}
      <ActionsDrawer
        isPublic={isPublic}
        isMyProfile={isMyProfile}
        profileUser={profileUser}
        mainActions={mainActions}
        blockModal={blockModal}
        reportModal={reportkModal}
        isVisible={showDrawer}
        isChat={isChat}
        onClickAction={() => setShowDrawer(false)}
      />
    </>
  );
};

export default ProfileActions;
