//import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';

import {
  AccountSettings,
  AccountNotifications,
  AccountPassword,
  //AccountSubscriptions,
} from 'assets/icons/settings';
import SettingsHeader from 'features/settings/SettingsHeader';
import SettingsLinksView from 'features/settings/SettingsLinksView';
import { Row, Col } from 'components/layout/grid';
import Layout from 'components/layout/layouts';

//import { selectUserSubscriptionEnabled } from 'features/auth/authSlice';
import ChangePasswordView from './ChangePasswordView';
import Subscriptions from 'features/subscriptions/Subscriptions';
import NotificationsEditView from './NotificationsEditView';
import AccountEditView from './AccountEditView';

const AccountScreen = () => {
  //const subscriptionEnabled = useSelector(selectUserSubscriptionEnabled);
  const { url, path } = useRouteMatch();
  const location = useLocation();
  const { t } = useTranslation();

  const isMainPath = path === location.pathname;

  const options = {
    mainPage: [
      {
        to: `${path}/edit`,
        title: t('features:settings.account.settings.h1'),
        text: t('features:settings.account.settings.span'),
        icon: () => <AccountSettings width="56" />,
      },
      {
        to: `${path}/notifications`,
        title: t('features:settings.account.notifications.h1'),
        text: t('features:settings.account.notifications.span'),
        icon: () => <AccountNotifications width="56" />,
      },
      {
        to: `${path}/password`,
        title: t('features:settings.account.password.h1'),
        text: t('features:settings.account.password.span'),
        icon: () => <AccountPassword width="52" />,
      },
      /*{
        to: `${path}/subscriptions`,
        title: t('features:settings.account.subscriptions.tab'),
        text: t('features:settings.account.subscriptions.h1'),
        icon: () => <AccountSubscriptions width="54" />,
        condition: subscriptionEnabled,
      },*/
    ],
    titles: {
      [path]: {
        h1: t('features:settings.account.main.h1'),
      },
      [`${path}/edit`]: {
        h1: t('features:settings.account.settings.h1'),
        span: t('features:settings.account.settings.span'),
      },
      [`${path}/notifications`]: {
        h1: t('features:settings.account.notifications.h1'),
        span: t('features:settings.account.notifications.span'),
      },
      [`${path}/password`]: {
        h1: t('features:settings.account.password.h1'),
        span: t('features:settings.account.password.span'),
      },
     /* [`${path}/subscriptions`]: {
        h1: t('features:settings.account.subscriptions.h1'),
        span: t('features:settings.account.subscriptions.span'),
      },*/
    },
    tabs: [
      {
        to: `${path}/edit`,
        text: t('features:settings.account.settings.h1'),
        exact: true,
        replace: true,
      },
      {
        to: `${path}/notifications`,
        text: t('features:settings.account.notifications.h1'),
        exact: true,
        replace: true,
      },
      {
        to: `${path}/password`,
        text: t('features:settings.account.password.h1'),
        exact: true,
        replace: true,
      },
     /* {
        to: `${path}/subscriptions`,
        text: t('features:settings.account.subscriptions.tab'),
        exact: true,
        replace: true,
        condition: subscriptionEnabled,
      },*/
    ],
  };

  return (
    <Layout screenName={options.titles[path].h1} mobilePadding={!isMainPath}>
      <Row center="md">
        <Col xs={12} md={10}>
          <SettingsHeader options={options} />
          <Switch>
            <Route path={url} exact>
              <SettingsLinksView options={options} />
            </Route>

            <Route path={`${path}/edit`} exact>
              <AccountEditView />
            </Route>

            <Route path={`${path}/notifications`} exact>
              <NotificationsEditView />
            </Route>

            <Route path={`${path}/subscriptions`} exact>
              <Subscriptions />
            </Route>

            <Route path={`${path}/password`} exact>
              <ChangePasswordView />
            </Route>

            <Redirect to={url} />
          </Switch>
        </Col>
      </Row>
    </Layout>
  );
};

export default AccountScreen;
