import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../app/api';

export const getSubscriptionSettings = createAsyncThunk(
  'subscriptions/getSubscriptionSettings',
  async () => {
    const res = await api.get('v2/subscription/my_settings');
    return res.data;
  }
);

export const enableSubscriptions = createAsyncThunk(
  'subscriptions/enableSubscriptions',
  async () => {
    const res = await api.post(
      '/v2/subscription/my_settings/accept_conditions'
    );
    return res.data;
  }
);

export const setSubscriptionPrice = createAsyncThunk(
  'subscriptions/setSubscriptionPrice',
  async ({ type, price }) => {
    const res = await api.post('v2/subscription/my_settings/store', {
      price: price,
      duration_type: type,
    });
    return res.data;
  }
);

export const createSubscriptionBundle = createAsyncThunk(
  'subscriptions/createSubscriptionBundle',
  async ({ duration_id, discount_percent }) => {
    const res = await api.post('v2/subscription/my_settings/bundle/store', {
      bundle_duration_id: duration_id,
      discount_percent,
    });
    return res.data;
  }
);

export const deleteSubscriptionBundle = createAsyncThunk(
  'subscriptions/deleteSubscriptionBundle',
  async ({ id }) => {
    const res = await api.delete(
      `v2/subscription/my_settings/bundle/${id}/delete`
    );
    return res.data;
  }
);

export const initSubscription = createAsyncThunk(
  'subscriptions/initSubscription',
  async ({ id, bundle_id }) => {
    const res = await api.post(`v2/subscription/init`, {
      id,
      bundle_id,
    });
    return res.data;
  }
);

export const getMySubscriptions = createAsyncThunk(
  'subscriptions/getMySubscriptions',
  async () => {
    const res = await api.get('/v2/subscription/my_subscriptions');
    return res.data;
  }
);
export const getMySubscribers = createAsyncThunk(
  'subscriptions/getMySubscribers',
  async () => {
    const res = await api.get('/v2/subscription/my_subscribers');
    return res.data;
  }
);
export const cancelSubscription = createAsyncThunk(
  'subscriptions/cancelSubscription',
  async ({ subscription_id }) => {
    const res = await api.post('/v2/subscription/my_subscriptions/cancel', {
      subscription_id,
    });
    return res.data;
  }
);
export const acceptNewPrice = createAsyncThunk(
  'subscriptions/acceptNewPrice',
  async ({ subscription_id }) => {
    const res = await api.post(
      '/v2/subscription/my_subscriptions/accept_new_price',
      {
        subscription_id,
      }
    );
    return res.data;
  }
);
export const renewSubscription = createAsyncThunk(
  'subscriptions/renewSubscription',
  async ({ subscription_id }) => {
    const res = await api.post('/v2/subscription/my_subscriptions/renew', {
      subscription_id,
    });
    return res.data;
  }
);

export const subscriptions = createSlice({
  name: 'subscriptions',
  initialState: {
    settings: {
      price: null,
      duration_type: 1,
      discount_percents: [],
      durations: [],
    },
    bundles: [],
    getSettings: {
      loading: false,
      error: false,
      success: false,
    },
    enableSubscriptions: {
      loading: false,
      error: false,
      success: false,
    },
    setPrice: {
      loading: false,
      error: false,
      success: false,
      showWarning: false,
    },
    createBundle: {
      loading: false,
      error: false,
      success: false,
    },
    deleteBundle: {
      loading: false,
      error: false,
      success: false,
    },
    mySubscribers: {
      list: [],
      loading: false,
      error: false,
      success: false,
    },
    mySubscriptions: {
      list: [],
      loading: false,
      error: false,
      success: false,
    },
    cancelSubscription: {
      loading: false,
      error: false,
      success: false,
    },
    acceptNewPrice: {
      loading: false,
      error: false,
      success: false,
    },
    renewSubscription: {
      loading: false,
      error: false,
      success: false,
    },
  },
  reducers: {},
  extraReducers: {
    // get settings
    [getSubscriptionSettings.pending]: (state) => {
      state.getSettings.loading = true;
    },
    [getSubscriptionSettings.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        state.getSettings.loading = false;
        state.getSettings.success = true;
        state.settings = payload.data;
        state.bundles = payload.data.bundles;
      }
    },
    [getSubscriptionSettings.rejected]: (state, { payload }) => {
      state.getSettings.loading = false;
      state.getSettings.error = true;
    },
    // enable subscriptions
    [enableSubscriptions.pending]: (state) => {
      state.enableSubscriptions.loading = true;
      state.enableSubscriptions.success = true;
      state.enableSubscriptions.error = false;
    },
    [enableSubscriptions.fulfilled]: (state, { payload }) => {
      if (!payload.status) {
        state.enableSubscriptions.loading = false;
        state.enableSubscriptions.success = false;
        state.enableSubscriptions.error = true;
      } else {
        state.enableSubscriptions.loading = false;
        state.enableSubscriptions.success = true;
      }
    },
    [enableSubscriptions.rejected]: (state, { payload }) => {
      state.enableSubscriptions.loading = false;
      state.enableSubscriptions.error = true;
    },
    // set price
    [setSubscriptionPrice.pending]: (state) => {
      state.setPrice.loading = true;
    },
    [setSubscriptionPrice.fulfilled]: (state, { payload }) => {
      state.setPrice.loading = false;
      state.setPrice.success = true;
    },
    [setSubscriptionPrice.rejected]: (state, { payload }) => {
      state.setPrice.loading = false;
      state.setPrice.error = true;
    },
    // create bundle
    [createSubscriptionBundle.pending]: (state) => {
      state.createBundle.loading = true;
    },
    [createSubscriptionBundle.fulfilled]: (state, { payload }) => {
      state.createBundle.loading = false;
      state.createBundle.success = true;
    },
    [createSubscriptionBundle.rejected]: (state, { payload }) => {
      state.createBundle.loading = false;
      state.createBundle.error = true;
    },
    // delete bundle
    [deleteSubscriptionBundle.pending]: (state) => {
      state.deleteBundle.loading = true;
    },
    [deleteSubscriptionBundle.fulfilled]: (state, { payload }) => {
      state.deleteBundle.loading = false;
      state.deleteBundle.success = true;
    },
    [deleteSubscriptionBundle.rejected]: (state, { payload }) => {
      state.deleteBundle.loading = false;
      state.deleteBundle.error = true;
    },
    // my subscribers
    [getMySubscribers.pending]: (state) => {
      state.mySubscribers.loading = true;
    },
    [getMySubscribers.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        state.mySubscribers.loading = false;
        state.mySubscribers.success = true;
        state.mySubscribers.list = payload.data.subscribers;
      }
    },
    [getMySubscribers.rejected]: (state, { payload }) => {
      state.mySubscribers.loading = false;
      state.mySubscribers.error = true;
    },
    // my subscriptions
    [getMySubscriptions.pending]: (state) => {
      state.mySubscriptions.loading = true;
    },
    [getMySubscriptions.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        state.mySubscriptions.loading = false;
        state.mySubscriptions.success = true;
        state.mySubscriptions.list = payload.data.subscriptions;
      }
    },
    [getMySubscriptions.rejected]: (state, { payload }) => {
      state.mySubscriptions.loading = false;
      state.mySubscriptions.error = true;
    },

    // cancel subscription
    [cancelSubscription.pending]: (state) => {
      state.cancelSubscription.loading = true;
    },
    [cancelSubscription.fulfilled]: (state, { payload }) => {
      state.cancelSubscription.loading = false;
      state.cancelSubscription.success = true;
    },
    [cancelSubscription.rejected]: (state, { payload }) => {
      state.cancelSubscription.loading = false;
      state.cancelSubscription.error = true;
    },
    // accept New Price for subscription
    [acceptNewPrice.pending]: (state) => {
      state.acceptNewPrice.loading = true;
    },
    [acceptNewPrice.fulfilled]: (state, { payload }) => {
      state.acceptNewPrice.loading = false;
      state.acceptNewPrice.success = true;
    },
    [acceptNewPrice.rejected]: (state, { payload }) => {
      state.acceptNewPrice.loading = false;
      state.acceptNewPrice.error = true;
    },
    // renew expired or canceled subscription
    [renewSubscription.pending]: (state) => {
      state.renewSubscription.loading = true;
    },
    [renewSubscription.fulfilled]: (state, { payload }) => {
      state.renewSubscription.loading = false;
      state.renewSubscription.success = true;
    },
    [renewSubscription.rejected]: (state, { payload }) => {
      state.renewSubscription.loading = false;
      state.renewSubscription.error = true;
    },
  },
});

export const selectGetSubscriptionSettings = (state) =>
  state.subscriptions.getSettings;

export const selectEnableSubscriptions = (state) =>
  state.subscriptions.enableSubscriptions;

export const selectSubscriptionsPriceSettings = (state) =>
  state.subscriptions.settings;

export const selectSetSubscriptionPrice = (state) =>
  state.subscriptions.setPrice;

export const selectSubscriptionsBundleOptions = (state) => ({
  discount_percents: state.subscriptions.settings.discount_percents,
  durations: state.subscriptions.settings.durations,
});

export const selectSubscriptionsBundles = (state) =>
  state.subscriptions.bundles;

export const selectCreateSubscriptionBundle = (state) =>
  state.subscriptions.createBundle;

export const selectDeleteSubscriptionBundle = (state) =>
  state.subscriptions.deleteBundle;

export const selectMySubscribers = (state) => state.subscriptions.mySubscribers;
export const selectMySubscriptions = (state) =>
  state.subscriptions.mySubscriptions;

export const selectCancelSubscription = (state) =>
  state.subscriptions.cancelSubscription;

export const selectAsiricceptNewPrice = (state) =>
  state.subscriptions.acceptNewPrice;

export const selectRenewSubscription = (state) =>
  state.subscriptions.renewSubscription;

export default subscriptions.reducer;
