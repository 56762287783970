import React from 'react';
//import isEmpty from 'lodash/isEmpty';
//import { useHistory } from 'react-router-dom';
//import { de, enGB  } from 'date-fns/locale';
//import { useTranslation } from 'react-i18next';
//import { Swiper, SwiperSlide } from 'swiper/react';

//import Avatar from 'components/avatar/Avatar';
//import EpisodeActions from 'features/dating/episodeActions/EpisodeActions';
//import ApplicationsList from 'features/dating/applications/ApplicationsList';
//import { LoaderInline } from 'components/layout/loader';
//import {dateTime, dateTimeLeft, monthDateTime} from 'helpers/dating';
//import { IconMale, IconFemale } from 'assets/icons';
//import styles from './myEpisodes.module.css';
//import {getImageSrc} from "../../../../helpers/s3media";
//import {bannerDefault} from "../../../../assets/default";
import EventWaitingCard from "../EventWaitingCard";
import {useSelector} from "react-redux";
import {selectUserInfo} from "../../../auth/authSlice";

const MyEpisodesListItem = ({episode, isDetail, episodesCount}) => {
    const me = useSelector(selectUserInfo);
    //const { t } = useTranslation();
    //const history = useHistory();
    //const lang = de ;

    /*const LookingFor = ({ value }) => {
      switch (value) {
        case 1:
          return (
            <>
              <IconMale className={styles.male} />
              <IconFemale className={styles.female} />
            </>
          );

        case 2:
          return <IconMale className={styles.male} />;

        case 3:
          return <IconFemale className={styles.female} />;

        default:
          return null;
      }
    };*/

    /*const handleClick = () => {
      /!*if (isEmpty(applications) || episodesCount === 1) {
        return null;
      }*!/
      return history.push(`/event/${episode.id}`);
    };*/

    /*const firstImage = episode.images.length > 0 ? episode.images[0] : null;
    const firstImageUrl = firstImage ? getImageSrc({
      public_id: firstImage.image,
      format: firstImage.format,
      resize: {
        width: 400,
        fit: 'cover',
      },
    }) : getImageSrc(bannerDefault);*/

    /*const userImageUrl = episode.user.length > 0 && episode.user.avatar ? getImageSrc({
      public_id: episode.user.avatar.public_id,
      format: episode.user.avatar.format,
      resize: {
        width: 400,
        fit: 'cover',
      },
    }) : getImageSrc(bannerDefault);*/

    const {applications} = episode;
    const pendingApplications = applications.filter(application => application.profile.id === me.id && application.is_applied === true ) ;
    const deniedApplications = applications.filter(application => application.profile.id === me.id && application.is_denied === true ) ;
    const eventOwner = episode.user.id === me.id;
    /*console.log(me.id);
    console.log(pendingApplications);*/
    return (
        <>
            <EventWaitingCard
                episode={episode}
                episodesCount={episodesCount}
                pendingApplications={pendingApplications}
                deniedApplications={deniedApplications}
                eventOwner={eventOwner}
            />
            {/*{pendingApplications.length > 0 || deniedApplications.length > 0 ? (
                    <EventWaitingCard
                        episode={episode}
                        episodesCount={episodesCount}
                        pendingApplications={pendingApplications}
                        deniedApplications={deniedApplications}
                    />
                ) :
                <EventCard
                    episode={episode}
                    episodesCount={episodesCount}
                />
            }*/}

            {/*<div className={styles.col}>
        <a href="javascript:void(0);" onClick={handleClick}>
          <div className={styles.eventimg}>
            <div className={styles.imgover}>
              <Avatar
                  size={44}
                  wrapperStyle={{ position: 'absolute', top: '12px', left: '12px' }}
                  image={episode.user.avatar}
                  gender={episode.user.gender}
                  circle
                  className={styles.imguserover}
              />
                <span className={styles.eventname}>{episode.name}</span>
            </div>
            <div className={styles.imgoverbottom}>
              <div className={styles.images}>
              </div>
              <span className={styles.eventdate}>{monthDateTime(episode, lang)}</span>
            </div>

            <img
                src={firstImageUrl}
                className={styles.eventimg}/>
          </div>
          <div className={styles.eventinfo}>
            <h3>{episode.venue_name}</h3>
            <span className={styles.city}>
              <IconMapMark width={10} /> {episode.city}, {episode.country_code.toUpperCase()}
            </span>
          </div>
        </a>
      </div>*/}

            {/*<div className={styles.item}>
        <EpisodeActions episode_id={episode.id} isDetail={isDetail} />
        <div onClick={handleClick}>
          <span className={styles.timeInfo}>{dateTime(episode, lang)}</span>
          <span className={styles.timeLeft}>
            <IconClock />
            {t('features:episodes.timeLeft')}
            {dateTimeLeft(episode, lang, 0)}
          </span>
          <span className={styles.location}>
            <IconMapMark />
            {`${t('main:main.City')}: ${
              episode.city
            }, ${episode.country_code.toUpperCase()}`}
          </span>
          <div className={styles.types}>
            {episode.types.map((dateType, i) => (
              <span key={i} className={styles.type}>
                {dateType}
              </span>
            ))}
          </div>
          {episode.budget > 0 && (
            <div className={styles.budget}>
              {t(`features:eventRequest.budget_range_${episode.budget}`, episode.budget)}
              </div>
          )}
          {episode.duration > 0 && (
            <div className={styles.duration}>
              {t(`${episode.duration} main:hours`,`${episode.duration} hours`)}
            </div>
          )}
          {episode.description && (
            <div className={styles.desc}>{episode.description}</div>
          )}
          <span className={styles.lookingFor}>
            {t('features:episodes.lookingFor')}

            <LookingFor value={episode.looking_for} />
          </span>
        </div>

        <ApplicationsSlide
          applications={applications}
          skipSlide={isDetail || episodesCount === 1}
          episode_id={episode.id}
        />
      </div>*/}


        </>
    );
};
/*
const ApplicationsSlide = ({ applications, episode_id, skipSlide }) => {
  const { t } = useTranslation();
  const history = useHistory();

  if (isEmpty(applications)) {
    return (
      <div className={styles.applicationSlide}>
        <div className={styles.slideEmpty}>
          <LoaderInline style={{ margin: '14px', minWidth: '50px' }} />
          <div>
            <strong>{t('features:eventRequest.waiting')}</strong>
            <span>
              <span className={styles.link} onClick={() => history.push('/')}>
                {t('features:eventRequest.findMembers')}
              </span>{' '}
              {t('features:eventRequest.inCommunity')}
            </span>
          </div>
        </div>
      </div>
    );
  }

  if (skipSlide) {
    return null;
  }

  return (
    <div className={styles.applicationSlide}>
      <div className={styles.slideWrapper}>
        <span className={styles.heading}>
          {applications.length > 0 && (
            <span className={styles.badge}>{applications.length}</span>
          )}
          {t('features:event.newApplications')}
        </span>
        <Swiper
          spaceBetween={5}
          resistance={false}
          slidesPerView={'auto'}
          freeMode
        >
          {applications.map(({ profile }, i) => {
            return (
              <SwiperSlide key={i} style={{ width: '75px' }}>
                <Avatar
                  size={70}
                  circle
                  onClick={() =>
                    history.push(`/events/event-request/${episode_id}`)
                  }
                  online={profile.online}
                  image={profile.avatar}
                  gender={profile.gender}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};*/

export default MyEpisodesListItem;
