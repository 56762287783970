import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import * as Yup from 'yup';

import { FormFieldError, FormRow, Input } from 'components/forms';
import CountryPhonePrefix from 'components/forms/countryPhonePrefixes';
import Button from 'components/buttons/Button';
import { useTimer } from 'hooks';
import { selectCountries } from 'app/miscSlice';
import api from 'app/api';
import {
  getAccountInfo,
  selectAccountInfo,
} from 'features/settings/settingsSlice';
import { IconCheck } from 'assets/icons';
import formStyles from 'components/forms/forms.module.css';
import styles from './phoneEdit.module.css';

const ResendBtn = ({ onClick }) => {
  const timeLimit = process.env.NODE_ENV === 'development' ? 10 : 60;
  const { t } = useTranslation();

  const [counter, setCounter] = useState(timeLimit);

  const countDown = () => {
    setCounter(counter - 1);
  };
  useTimer(countDown, counter ? 1000 : null);

  return (
    <p
      onClick={() => {
        if (counter) return;
        onClick();
      }}
      className={cx(styles.resendButton, { [styles.counting]: counter })}
      name={'btn-bare'}
      size="s"
    >
      {t('features:phoneEdit.resendCode')} {counter ? `(${counter} s)` : null}
    </p>
  );
};

const ErrorMessage = ({ code }) => {
  const { t } = useTranslation();

  switch (code) {
    case '702':
      return t('features:phoneEdit.validation.incorrectFormat');
    case '703':
      return t('features:phoneEdit.validation.smsLimit');
    case '710':
      return t('features:phoneEdit.validation.invalidCodeFormat');
    case '711':
      return t('features:phoneEdit.validation.codeExpired');

    default:
      return t('features:phoneEdit.validation.general');
  }
};

const PhoneStep = ({ onSuccess, onCancel }) => {
  const countries = useSelector(selectCountries);
  const { phone } = useSelector(selectAccountInfo);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const onCodeSubmit = async (phone, countryID) => {
    try {
      const res = await api.post('v1/svs', { phone, countryID });
      if (res.data.error) throw new Error(res.data.error);

      onSuccess(phone);
      setError(false);
    } catch (error) {
      console.error('err', error);
      setError(error.message);
    }
  };

  return (
    <div>
      <p className={styles.description}>{t('features:phoneEdit.intro')}</p>
      <Formik
        initialValues={{ phone: '', countryID: 0 }}
        validationSchema={Yup.object().shape({
          phone: Yup.number()
            .required(t('components:validation.required'))
            .typeError(t('features:phoneEdit.validation.incorrectFormat')),
        })}
        onSubmit={async ({ phone, countryID }) => {
          setLoading(true);
          await onCodeSubmit(phone, countryID);
          setLoading(false);
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <FormRow style={{ position: 'relative' }}>
              <CountryPhonePrefix
                countries={countries}
                initialPrefix={phone.prefix}
                onChange={(val) => setFieldValue('countryID', Number(val))}
              />
              <label
                className={`${formStyles.formLabel} ${formStyles.required}`}
                htmlFor="phone"
              >
                {t('main:label.Phone', 'Phone')}
              </label>
              <FormFieldError fieldName="phone" />
              <Field
                className={`${formStyles.input} ${formStyles.phone}`}
                type="tel"
                name="phone"
                minLength={8}
                maxLength={14}
              />
            </FormRow>
            {error && (
              <span className={styles.errorMessage}>
                <ErrorMessage code={error} />
              </span>
            )}
            <FormRow
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '15px 0 0',
              }}
            >
              <Button
                name="btn-grey"
                style={{ width: '48%' }}
                onClick={onCancel}
                type="button"
              >
                {t('main:main.Cancel')}
              </Button>
              <Button
                style={{ width: '48%' }}
                loading={loading}
                disabled={loading}
              >
                {t('main:main.send')}{' '}
              </Button>
            </FormRow>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const VerificationCodeStep = ({ onSuccess, phone, onResend, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const onCodeSubmit = async (code) => {
    try {
      const res = await api.post('v1/vsc', { code, phone });
      if (res.data.error) throw new Error(res.data.error);
      onSuccess();
      setError(false);
    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  };

  return (
    <div>
      <p className={styles.description}>{t('features:phoneEdit.submitCode')}</p>
      <ResendBtn onClick={onResend} />

      <Formik
        initialValues={{ code: '' }}
        validationSchema={Yup.object().shape({
          code: Yup.number('must be number').required(
            t('components:validation.required', 'Required')
          ),
        })}
        onSubmit={async ({ code }) => {
          setLoading(true);
          await onCodeSubmit(code);
          setLoading(false);
        }}
      >
        {() => (
          <Form>
            <FormRow>
              <Input
                label={t('features:phoneEdit.code')}
                name="code"
                type="number"
              />
              {error && (
                <span className={styles.errorMessage}>
                  <ErrorMessage code={error} />
                </span>
              )}
            </FormRow>
            <FormRow
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '15px 0 0',
              }}
            >
              <Button
                name="btn-grey"
                style={{ width: '48%' }}
                onClick={onCancel}
                type="button"
              >
                {t('main:main.Cancel')}
              </Button>
              <Button
                loading={loading}
                style={{ width: '48%' }}
                disabled={loading}
              >
                {t('features:phoneEdit.verify')}
              </Button>
            </FormRow>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const PhoneEdit = ({ onCloseModal }) => {
  const [step, setStep] = useState('phone');
  const [phone, setPhone] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSuccessConfirm = () => {
    dispatch(getAccountInfo());
    onCloseModal();
  };

  if (step === 'phone') {
    return (
      <PhoneStep
        onCancel={onCloseModal}
        onSuccess={(phone) => {
          setPhone(phone);
          setStep('code');
        }}
      />
    );
  }

  if (step === 'code') {
    return (
      <VerificationCodeStep
        phone={phone}
        onCancel={onCloseModal}
        onResend={() => {
          setStep('phone');
        }}
        onSuccess={() => {
          setStep('success');
        }}
      />
    );
  }

  if (step === 'success') {
    return (
      <div className={styles.successModal}>
        <IconCheck className={styles.iconSuccess} />
        <p className={styles.successText}>
          {t('features:phoneEdit.successDesc')}
        </p>
        <Button fullWidth onClick={onSuccessConfirm}>
          {t('main:main.close')}
        </Button>
      </div>
    );
  }
};

const PhoneEditModal = ({ modalContext }) => {
  const { RenderModal, hideModal } = modalContext;
  const { t } = useTranslation();

  return (
    <RenderModal
      blockCloseOnBackdropClick
      buttonClose
      title={t('features:phoneEdit.title')}
    >
      <PhoneEdit onCloseModal={hideModal} />
    </RenderModal>
  );
};

export default PhoneEditModal;
