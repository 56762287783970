import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import {
  selectLogoutMessage,
  resetLogoutMessage,
} from 'features/auth/authSlice';
import { useRealViewportH, useWindowWidthLessThan } from 'hooks';
import AppContainer from '../AppContainer';
import { AuthLayoutContext } from './Context';
// import Footer from '../footer/Footer';
import Header, { HeaderAuth } from '../header';
import Main from '../main/Main';
import Sidebar from '../sidebar/Sidebar';
import TransactionsModal from 'features/transactions/transactionsModal/TransactionsModal';
import RegisterModal from 'features/auth/registerForm/PublicModal';
// import LookingForModal from 'features/auth/modals/LookingForModal';
import DatingModal from 'features/auth/modals/DatingModal';
import RedeemVoucher from 'features/vouchers/RedeemVoucher';
//import SupportButton from 'components/supportButton/SupportButton';
import FabButton from 'components/buttons/FabButton';
import { CreateDrawer } from 'components/bottomDrawer';

const Layout = ({
  children,
  customHeader,
  screenName,
  noBorderHeader,
  mobilePadding,
  noMarginBottom,
}) => {
  useRealViewportH();
  return (
    <AppContainer>
      {customHeader ? (
        customHeader
      ) : (
        <Header title={screenName} noBorder={noBorderHeader} />
      )}
      <Main
        children={children}
        hasHeader
        noMarginBottom={noMarginBottom}
        mobilePadding={mobilePadding}
      />
      <Sidebar />
      <TransactionsModal />
      <RedeemVoucher />
      <FabButton />
      <CreateDrawer />
      <ToastContainer
        autoClose={3000}
        limit={1}
        position={isMobile ? 'bottom-center' : 'bottom-left'}
      />
      <DatingModal />
    <RegisterModal />
    </AppContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export const PublicLayout = ({ children, noMainMenu }) => {
  const dispatch = useDispatch();
  const logoutMessage = useSelector(selectLogoutMessage);

  useEffect(() => {
    if (logoutMessage) {
      toast.success(logoutMessage, { autoClose: 6000 });
      dispatch(resetLogoutMessage());
    }
  }, [dispatch, logoutMessage]);

  useRealViewportH();
  return (
    <AppContainer>
      <Header />
      <Main children={children} noMainMenu={noMainMenu} hasHeader />
      <ToastContainer
        limit={1}
        autoClose={3000}
        position="bottom-center"
        className="publicToast"
      />
      <RegisterModal />
      {/*<LookingForModal />*/}
    </AppContainer>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export const AuthLayout = ({ children, afterRegister, loginPage }) => {
  useRealViewportH();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const smallView = useWindowWidthLessThan(1200);
  const mainAuthRoutes = ['/login', '/register'];
  const showHeader =
    smallView && !afterRegister && mainAuthRoutes.includes(pathname);

  const context = {
    smallView: smallView,
    showHeader: showHeader,
    tabItems: [
      {
        to: '/login',
        text: t('main:main.Login'),
        // exact: true,
      },
      {
        to: '/register',
        text: t('main:main.Register'),
      },
    ],
  };

  return (
    <AuthLayoutContext.Provider value={context}>
      <AppContainer>
        {showHeader && <HeaderAuth />}
        <Main
          children={children}
          fullScreen
          mobilePadding
          noMarginBottom
          hasHeader={showHeader}
          loginPage={loginPage}
        />
        {/*<SupportButton />*/}
        <ToastContainer
          limit={1}
          autoClose={3000}
          position="bottom-center"
          className="publicToast"
        />
      </AppContainer>
    </AuthLayoutContext.Provider>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
