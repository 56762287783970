import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';

import { startChatTransaction } from 'features/transactions/transactionsSlice';
import {applyEpisode, denyEpisode, getEpisodeDetail} from './episodesSlice';
import { EpisodeButtons as Buttons } from 'components/buttons';
import { selectUserInfo } from 'features/auth/authSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {useState} from "react";

const EpisodeButtons = ({ episode, loaderCallback, size, verificationModal }) => {
  const dispatch = useDispatch();
  //const { uid, verification_required, verified_phone, verified_photo } = useSelector(selectUserInfo);
  const { uid } = useSelector(selectUserInfo);
  const history = useHistory();
  const { t } = useTranslation();
  const [isChangeButton, setChangeButton] = useState(false);

  if (episode.profile.uid === uid) {
    return '';
  }

  const removeEpisode = () => {
    dispatch(getEpisodeDetail({ episode_id: episode.id }))
        .then(unwrapResult)
        .then((result) => {
          if (result.status) {
            loaderCallback(false);
            // toast -> type
          }
        });
  };

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const handleApply = async () => {
    /*if(verification_required === true && verified_phone  === false && verified_photo === false)
    {
      verificationModal.showModal();
      return false;
    }*/

    loaderCallback(true);
    dispatch(applyEpisode({ id: episode.id }))
      .then(unwrapResult)
      .then((result) => {
        if (result.status) {
          removeEpisode('apply');
        } else {
          // toast error
          if(result.error.code === 1110)
          {
            toast.error(t('main:errors.applicationOpenInDiffrentCity'));
          } else if(result.error.code === 1111) {
            toast.error(t('main:errors.verificationRequired'));
          } else {
            toast.error(t('main:errors.somethingWentWrong'));
          }
          
          loaderCallback(false);
        }
      });
  };

  const handleDeny = async () => {
    loaderCallback(true);
    dispatch(denyEpisode({ id: episode.id }))
      .then(unwrapResult)
      .then((result) => {
        setChangeButton(false)
        if (result.status) {
          removeEpisode('deny');
        } else {
          // toast error
          loaderCallback(false);
        }
      });
  };

  const handleChat = async () => {
    if (episode.profile.chat_open) {
      history.push(`/chat/${uid}-${episode.profile.uid}-messaging`);
    } else {
      dispatch(
        startChatTransaction({ actor: episode.profile, episodeId: episode.id })
      );
    }
  };

  const handleChange = async () => {
    loaderCallback(true);
    await delay(1000);
    setChangeButton(true)
    loaderCallback(false);
  };

  if (episode.applied || episode.accepted) {
    return <Buttons handleChat={handleChat} size={size} />
  }

  if (episode.rejected) {
    return '';
  }

  if (episode.declined) {
    return <>
        {isChangeButton ? (
            <Buttons handleDeny={handleDeny} handleApply={handleApply} size={size} />
          ) : (
          <Buttons handleChange={handleChange} size={size} />
        )}
      </>
    //return <div style={{textAlign:'center'}}> {t('features:profileDating.declinedMsg')}</div>
  }

  return (
    <Buttons handleDeny={handleDeny} handleApply={handleApply} size={size} />
  );
};

EpisodeButtons.propTypes = {
  episode: PropTypes.object.isRequired,
  loaderCallback: PropTypes.func.isRequired,
  size: PropTypes.string,
};

EpisodeButtons.defaultProps = {
  size: 'xl',
};

export default EpisodeButtons;
