import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
//import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import {
    selectGuestEpisodesFetched, selectGuestEpisodes, getGuestEpisodes,
} from './episodesSlice';
import {selectFilters} from 'app/miscSlice';
import Loader, {LoaderRelative} from 'components/layout/loader';
//import Button from 'components/buttons/Button';
import EpisodesListItem from './EpisodeListItem';
import styles from './episodesList.module.css';
import EpisodesListHeader from '../episodesListHeader/EpisodesListHeader';
//import {VerificationModal} from "./VerificationModal";
//import {useModal} from "../../../components/modal/useModal";
import cx from "classnames";
import {selectAuthenticated} from "../../auth/authSlice";
//import {faCalendar} from "@fortawesome/free-solid-svg-icons";
//import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const EpisodesListGuest = ({hideFilter}) => {
    const dispatch = useDispatch();
    //const { t } = useTranslation();
    const fetchedEpisodes = useSelector(selectGuestEpisodesFetched);
    const episodesList = useSelector(selectGuestEpisodes);
    //const user = useSelector(selectUserInfo);
    const filters = useSelector(selectFilters);
    //const town = user.town_name;
    //const verificationModal = useModal();
    //const { indispensable_verification } = useSelector(selectUserInfo);
    const isAuthenticated = useSelector(selectAuthenticated);

    useEffect(() => {
        if (isEmpty(episodesList) && !fetchedEpisodes) {
            dispatch(getGuestEpisodes());
        }
    }, [dispatch, episodesList, filters.gender, fetchedEpisodes]);

    /*useEffect(() => {
      return () => dispatch(resetNewEpisodesCount());
    }, [dispatch]);*/

    if (isEmpty(episodesList) && !fetchedEpisodes) {
        return (
            <div>
                {/*<EpisodesListHeader />*/}
                <Loader/>
            </div>
        );
    }

    /*const createDateValidation = () => {
      dispatch(setShowPublicRegister(true));
    /!*  if(indispensable_verification === true)
      {
        verificationModal.showModal();
        return false;
      }*!/
      //history.push('/create-event')
    }*/

    /*if (isEmpty(episodesList)) {
      return (
        <div>
          {/!*<VerificationModal
              modalContext={verificationModal}
          />*!/}
          <EpisodesListHeader />
          <div className={styles.emptyList}>
            <span className={styles.emptyIconWrapper}>
              <FontAwesomeIcon icon={faCalendar} width={20} />
            </span>

            {[1,2,3].includes(filters.filter_episode_applications) && (
                <>
                <strong>{ t('features:episodes.filterNoRequest') }</strong>
                <p>{ t('features:episodes.filterNoRequestSub') }</p>
                </>
            )}

            {[0,4].includes(filters.filter_episode_applications) && (
                <>
                <Trans i18nKey={'features:episodes.noEvents'}>
                  <strong>No new events </strong>
                  {/!*<p>Come back in a few minutes or search in a city nearby.</p>*!/}
                </Trans>
                <Button
                  onClick={createDateValidation}
                  name="btn-gradient"
                  size="xl"
                  extraPadding
                >
                  {t('features:event.nav.shareEventRequest')}
                </Button>
                </>
              )}
          </div>
        </div>
      );
    }*/
    let wrapperClass = cx(styles.row, styles.eventlist);

    return (
        <div>
            <div className={wrapperClass}>
                {/*<VerificationModal
          modalContext={verificationModal}
      />*/}
                {!hideFilter && <EpisodesListHeader/>}
                {isEmpty(episodesList) && !fetchedEpisodes ? (
                    <LoaderRelative/>
                ) : (

                        episodesList.map((episode, i) => {
                            return <EpisodesListItem
                                hideFilter={hideFilter}
                                key={i}
                                episode={episode}
                                isPublic={!isAuthenticated}
                            />;
                        })
                )}
            </div>
        </div>
    );
};

export default EpisodesListGuest;
