import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { formatRelative } from 'date-fns';
import { de, enGB  } from 'date-fns/locale';

import ChatFeedback from 'features/chat/chatFeedback/ChatFeedback';
import Avatar from 'components/avatar/Avatar';
import { useWindowWidthLessThan } from 'hooks';
import { selectUserInfo } from 'features/auth/authSlice';
import styles from './notificationDetail.module.css';

const AvatarImg = ({ user2 }) => {
  const { avatar, gender } = useSelector(selectUserInfo);

  const smallWidth = useWindowWidthLessThan(576);
  const avatarSize = smallWidth ? 50 : 72;
  const img = user2?.avatar || avatar;
  return (
    <div className={styles.avatarWrapper}>
      <Avatar
        className={styles.avatar}
        size={avatarSize}
        image={img}
        circle
        gender={gender}
      />
    </div>
  );
};

const NotificationDetail = ({ item }) => {
  const {
    created_at,
    amount_type,
    amount,
    notification_type,
    process_id,
    user2,
    headline,
    description,
    extra_info,
  } = item;
  const { i18n } = useTranslation();
  const lang = i18n.language === 'de' ? de : enGB;
  const time = formatRelative(new Date(created_at), new Date(), {
    locale: lang,
  });
  const history = useHistory();

  const onClick = () => {
    if (!user2) {
      return;
    }
    history.push(`profile/${user2.id}`);
  };

  const getNotificationColor = (type) => {
    switch (type) {
      case 1:
        return 'var(--turg)';
      case 2:
        return 'var(--accentColor)';
      case 3:
        return 'initial';

      default:
        return 'initial';
    }
  };

  return (
    <div className={styles.container}>
      <div
        onClick={onClick}
        className={styles.avatarWrapper}
        style={user2 ? { cursor: 'pointer' } : {}}
      >
        <AvatarImg user2={user2} />
      </div>
      <div className={styles.details}>
        <p className={styles.name}>{headline}</p>
        <p className={styles.description}>{description}</p>
        <p className={styles.date}>
          <span>{time}</span>
        </p>
      </div>

      {amount && (
        <div
          style={{
            color: getNotificationColor(amount_type),
            fontWeight: 'bold',
          }}
          className={styles.coinsDetails}
        >
          {amount}
        </div>
      )}

      {notification_type === 18 && (
        <div className={styles.actions}>
          <ChatFeedback
            chatId={process_id}
            member={user2}
            oldRating={extra_info?.rating ? Number(extra_info.rating) : 0}
          />
        </div>
      )}
    </div>
  );
};

export default NotificationDetail;
