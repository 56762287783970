import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getEpisodesPath } from 'helpers/dating';
import { Row, Col } from 'components/layout/grid';
import Button from 'components/buttons/Button';
import { selectUserInfo } from 'features/auth/authSlice';
import { IconMobileHearts } from 'assets/icons';
import styles from './blockCreate.module.css';

const BlockCreateView = ({ code }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector(selectUserInfo);
  const episodesPath = getEpisodesPath(user.gender);

  const errorTypes = {
    NOT_ENOUGH_COINS: {
      icon: () => <IconMobileHearts />,
      title: t('features:blockCreateEpisode.NotEnoughtCoins.title'),
      subtitle: t('features:blockCreateEpisode.NotEnoughtCoins.subtitle'),
      button: t('features:blockCreateEpisode.NotEnoughtCoins.button'),
      handleClick: () =>
        window.location.assign(process.env.REACT_APP_PAYMENTS_CHECKOUT),
    },
    MISSING_USER_AVATAR: {
      icon: () => <IconMobileHearts />,
      title: t('features:blockCreateEpisode.MissingUserAvatar.title'),
      subtitle: t('features:blockCreateEpisode.MissingUserAvatar.subtitle'),
      button: t('features:blockCreateEpisode.MissingUserAvatar.button'),
      handleClick: () => history.push('/settings/profile/profile-images'),
    },
    OUT_OF_LIMITS: {
      icon: () => <IconMobileHearts />,
      title: t('features:blockCreateEpisode.OutOfLimits.title'),
      subtitle: t('features:blockCreateEpisode.OutOfLimits.subtitle'),
      button: t('features:blockCreateEpisode.OutOfLimits.button'),
      handleClick: () => history.push(episodesPath),
    },
    DEFAULT: {
      icon: () => <IconMobileHearts />,
      title: t('features:blockCreateEpisode.Default.title'),
      subtitle: t('features:blockCreateEpisode.Default.subtitle'),
      button: t('features:blockCreateEpisode.Default.button'),
      handleClick: () =>
        window.location.assign(process.env.REACT_APP_SUPPORT_PAGE),
    },
  };

  let errorType = null;

  switch (code) {
    case 1100:
      errorType = errorTypes.NOT_ENOUGH_COINS;
      break;
    case 1101:
      errorType = errorTypes.MISSING_USER_AVATAR;
      break;
    case 1102:
      errorType = errorTypes.OUT_OF_LIMITS;
      break;
    default:
      errorType = errorTypes.DEFAULT;
  }

  return (
    <Row center="xs" className="ohlala-main">
      <Col className="mainContent">
        <div className={styles.wrapperEmpty}>
          <div className={styles.infoBox}>
            <div className={styles.iconTop}>{errorType.icon()}</div>
            <h2>{errorType.title}</h2>
            <p>{errorType.subtitle}</p>
            <Button onClick={errorType.handleClick}>{errorType.button}</Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default BlockCreateView;
