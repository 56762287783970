import React from 'react';
import Loader from 'components/layout/loader';

const SplashScreen = () => {
  return (
    <div
      style={{
        widows: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader />
    </div>
  );
};

export default SplashScreen;
