import { useContext } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Grid, Row, Col } from 'components/layout/grid';
import NavTabs from 'components/navTabs';
import { LogoSvg } from 'assets/icons';
import { AuthLayoutContext } from 'components/layout/layouts/Context';
import mainStyles from 'components/layout/main/main.module.css';
import styles from './header.module.css';

const HeaderAuth = () => {
  const { tabItems } = useContext(AuthLayoutContext);

  const LeftElement = () => {
    return (
      <Link to="/" className={styles.item}>
        <LogoSvg className={styles.logo} />
      </Link>
    );
  };

  const RightElement = () => {
    return (
      <div className={styles.item}>
        <NavTabs items={tabItems} />
      </div>
    );
  };

  return (
    <header onClick={(e) => e.stopPropagation()} className={styles.header}>
      <Grid className={cx(mainStyles.gridBox, mainStyles.headerAuth)}>
        <Row className={styles.headerInner}>
          <Col className={styles.inner}>
            <LeftElement />
            <RightElement />
          </Col>
        </Row>
      </Grid>
    </header>
  );
};

export default HeaderAuth;
