import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';
import {useParams, useHistory} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import {getMyEpisodesPath} from 'helpers/dating';
import Loader from 'components/layout/loader/Loader';
import {Row, Col} from 'components/layout/grid';
import {getClubDetail, resetClubDetail, selectClubDetail} from "./clubsSlice";
import ClubDetails from "./ClubDetails";

const ClubDetail = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const club = useSelector(selectClubDetail);
    const myEpisodesPath = getMyEpisodesPath('male');

    useEffect(() => {
        dispatch(getClubDetail({club_id: id}))
            .then(unwrapResult)
            .then((result) => {
                if (!result.status) {
                    history.push(myEpisodesPath);
                }
            });

        return () => dispatch(resetClubDetail());
    }, [dispatch, id, history, myEpisodesPath]);

    if (isEmpty(club)) {
        return <Loader/>;
    }

    return (
        /*<Layout noBorderHeader>*/
            <Row>
                <Col sm={12}>
                    <ClubDetails
                        club={club}
                        isPublic={true}
                    />
                </Col>
            </Row>
        /*</Layout>*/
    );
};

export default ClubDetail;
