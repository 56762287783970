import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Avatar } from 'components/avatar';
import { selectUserInfo } from 'features/auth/authSlice';
import { ProfileBadges } from 'components/layout/badges/Badges';
import styles from './userInfo.module.css';
import {LocationInfo} from "../../../features/user/profileInfo/profileMetaInfo";

const UserInfo = () => {
  const history = useHistory();
  const {
    name,
    avatar,
    gender,
    verification_required,
    age,
    town_name,
    country_name,
    dating_enabled,
    verified_phone,
    country
  } = useSelector(selectUserInfo);

  return (
    <div className={styles.userProfile}>
      <Avatar
        circle
        size={60}
        gender={gender}
        image={avatar}
        style={{ display: 'inline-block' }}
        onClick={() => history.push('/profile')}
      />
      <div className={styles.meta}>
        <ProfileBadges
          small
          verified={!verification_required}
          dating={dating_enabled}
          phone={verified_phone}
          className={styles.userBadges}
        />
        <div
          className={styles.userName}
          onClick={() => history.push('/profile')}
        >
          {name} {Number(age) > 0 && <span className={styles.age}>{age}</span>}
        </div>
        <p className={styles.location} onClick={() => history.push('/profile')}>
          {/*<IconMapMark width={10} />
          {location.substring(0, 25)}*/}
          <LocationInfo
              customClass={styles.mt0}
              town_name={town_name}
              country_name={country_name}
              country={country}
          />
        </p>
      </div>
    </div>
  );
};

export default UserInfo;
