import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'app/api';

export const getHomeStories = createAsyncThunk(
  'stories/getHomeStories',
  async ({ gender, isRefresh = false }) => {
    let url = 'v2/stories/homepage';
    if (gender) url = `${url}?gender=${gender}`;

    let response = await api.get(url);
    if (isRefresh && response.data.data.list) {
      response.data.data.list[0].refresh = true;
    }
    return response.data;
  }
);

export const getHomePublicStories = createAsyncThunk(
  'stories/getHomePublicStories',
  async ({ gender, isRefresh = false }) => {
    let url = 'guests/stories/homepage';
    if (gender) url = `${url}?gender=${gender}`;

    let response = await api.get(url);
    if (isRefresh && response.data.data.list) {
      response.data.data.list[0].refresh = true;
    }
    return response.data;
  }
);

export const getSeenStory = createAsyncThunk(
  'stories/getSeenStory',
  async (props) => {
    const { story_id, page } = props;
    let url = `v2/story/${story_id}/views`;
    if (page > 1) {
      url += `?page=${page}`;
    }
    const response = await api.get(url);
    return response.data;
  }
);

export const seenStory = createAsyncThunk(
  'stories/seenStory',
  async (story_id) => {
    let url = `v2/story/${story_id}/view`;

    const response = await api.post(url);
    return response.data;
  }
);

export const createStory = createAsyncThunk(
  'stories/createStory',
  async (payload) => {
    let url = 'v2/user/profile/story';

    const response = await api.put(url, {
      media: JSON.stringify(payload.file),
      status: payload.status,
    });
    return response.data;
  }
);

const initialState = {
  home: {
    status: {
      loading: false,
      fetched: false,
      hasError: false,
    },
    results: [],
  },
  homePublic: {
    status: {
      loading: false,
      fetched: false,
      hasError: false,
    },
    results: [],
  },
  views: {
    status: {
      loading: false,
      fetched: false,
      hasError: false,
    },
    results: [],
  },
};

export const stories = createSlice({
  name: 'stories',
  initialState: initialState,
  reducers: {
    resetSeenStory: (state) => {
      state.views = {
        status: {
          loading: false,
          fetched: false,
          hasError: false,
        },
        results: [],
      };
    },
    resetStories: (state) => {
      state.home = initialState.home;
      state.homePublic = initialState.homePublic;
      state.views = initialState.views;
    },
  },
  extraReducers: {
    [getHomeStories.pending]: (state, action) => {
      state.home.status.loading = true;
      state.home.status.hasError = false;
    },
    [getHomeStories.fulfilled]: (state, action) => {
      state.home.status.fetched = true;
      state.home.status.loading = false;
      if (action.payload.status) {
        state.home.results = action.payload.data;
        state.home.status.hasError = false;
      } else {
        state.home.status.hasError = true;
      }
    },
    [getHomeStories.rejected]: (state, action) => {
      state.home.status.fetched = true;
      state.home.status.loading = false;
      state.home.status.hasError = true;
    },
    [getHomePublicStories.pending]: (state, action) => {
      state.homePublic.status.loading = true;
      state.homePublic.status.hasError = false;
    },
    [getHomePublicStories.fulfilled]: (state, action) => {
      state.homePublic.status.fetched = true;
      state.homePublic.status.loading = false;
      if (action.payload.status) {
        state.homePublic.results = action.payload.data;
        state.homePublic.status.hasError = false;
      } else {
        state.homePublic.status.hasError = true;
      }
    },
    [getHomePublicStories.rejected]: (state, action) => {
      state.homePublic.status.fetched = true;
      state.homePublic.status.loading = false;
      state.homePublic.status.hasError = true;
    },
    [getSeenStory.pending]: (state, action) => {
      state.views.status.loading = true;
      state.views.status.hasError = false;
    },
    [getSeenStory.fulfilled]: (state, action) => {
      state.views.status.fetched = true;
      state.views.status.loading = false;
      if (action.payload.status) {
        state.views.status.hasError = false;
        if (action.payload.data.paginate.currentPage === 1) {
          state.views.results = action.payload.data;
        } else {
          state.views.results.list = [
            ...state.views.results.list,
            ...action.payload.data.list,
          ];
          state.views.results.paginate = action.payload.data.paginate;
        }
        const { currentPage, total, perPage } = action.payload.data.paginate;
        const totalPages = Math.ceil(total / perPage);
        const nextPage = currentPage < totalPages ? currentPage + 1 : null;
        state.views.results.paginate.nextPage = nextPage;
      } else {
        state.views.status.hasError = true;
      }
    },
    [getSeenStory.rejected]: (state, action) => {
      state.views.status.fetched = true;
      state.views.status.loading = false;
      state.views.status.hasError = true;
    },
  },
});

export const { resetSeenStory, resetStories } = stories.actions;

export const selectHomeStories = (state) => state.stories.home.results;
export const selectHomeStoriesStatus = (state) => state.stories.home.status;

export const selectHomePublicStories = (state) =>
  state.stories.homePublic.results;
export const selectHomePublicStoriesStatus = (state) =>
  state.stories.homePublic.status;

export const selectCurrentStoryViews = (state) => state.stories.views.results;
export const selectCurrentStoryViewsStatus = (state) =>
  state.stories.views.status;

export default stories.reducer;
