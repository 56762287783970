import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Streami18n } from 'stream-chat-react';
import { isEmpty } from 'lodash';

import { Row, Col } from 'components/layout/grid';
import Layout from 'components/layout/layouts';
import { LoaderRelative } from 'components/layout/loader';
import SubHeader from 'components/layout/subHeader/SubHeader';
import { selectUserInfo } from 'features/auth/authSlice';
import { ChatContext } from 'features/chat/chatContext';
import ChatView from 'features/chat/ChatView';
import UserList from 'features/userList/UserList';
import { IconChats } from 'assets/icons';
import { getEpisodesPath } from 'helpers/dating';
import Button from 'components/buttons/Button';
import ChatError from 'features/chat/chatError/ChatError';
import stylesEmpty from 'features/dating/applications/applicationsList.module.css';

const ChatScreen = () => {
  const { isChatError, chatClient } = useContext(ChatContext);
  const [channels, setChannels] = useState(0);
  const { uid, language_gui, gender } = useSelector(selectUserInfo);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { t } = useTranslation();
  const episodesPath = getEpisodesPath(gender);
  const chatI18n = useMemo(() => new Streami18n({ language: language_gui }), [
    language_gui,
  ]);

  useEffect(() => {
    async function checkChannels() {
      const res = await chatClient.queryChannels({
        members: { $in: [`${uid}`] },
      });
      setChannels(res);
      setLoading(false);
    }

    checkChannels();
  }, [chatClient, uid]);

  useEffect(() => {
    chatI18n.setLanguage(language_gui);
  }, [language_gui, chatI18n]);

  if (loading) {
    return (
      <Layout>
        <div>
          <LoaderRelative />
        </div>
      </Layout>
    );
  }

  if (isChatError) {
    return (
      <Layout>
        <ChatError />
      </Layout>
    );
  }

  if (isEmpty(channels))
    return (
      <Layout screenName={t('features:chat.chats')}>
        <Row center="xs">
          <Col xs={12}>
            <SubHeader
              backBtn
              special
              screenName={t('features:chat.chats')}
              className={stylesEmpty.subHeader}
              hideOnMobile
            />
            <div className={stylesEmpty.wrapperEmpty}>
              <div className={stylesEmpty.infoBox}>
                <div className={stylesEmpty.iconTop}>
                  <IconChats style={{ margin: 0 }} />
                </div>
                <h2>{t('features:chat.noChats')}</h2>
                <p>{t('features:chat.eventCTA')}</p>
                <Button
                  extraPadding
                  size="xl"
                  name="btn-gradient"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(episodesPath);
                  }}
                >
                  {t('features:chat.createEvent')}
                </Button>
              </div>
            </div>
            <div className={stylesEmpty.suggestions}>
              <p>{t('features:chat.ChatCTA')}</p>
              <UserList />
            </div>
          </Col>
        </Row>
      </Layout>
    );

  return (
    <Layout noMarginBottom>
      <Row>
        <Col xs={12}>
          <ChatView i18n={chatI18n} />
        </Col>
      </Row>
    </Layout>
  );
};

export default ChatScreen;
