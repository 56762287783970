import React from 'react';
import isEmpty from 'lodash/isEmpty';

import ActivityFeedMedia from './components/ContentMedia';
import ActivityFeedPreview from './components/ContentPreview';
import textRenderer from 'helpers/textRenderer';
import styles from './activityDetail.module.css';

const ActivityFeedContent = (props) => {
  let { text } = props.activity;
  if (text === undefined) {
    if (typeof props.activity.object === 'string') {
      text = props.activity.object;
    } else {
      text = '';
    }
  }

  const { marketplace, id, actor } = props.activity;
  if (actor.error) {
    return null;
  }

  // const isPublicProfile = typeof props.publicModal === 'function';
  const lockedPost = marketplace.type === 2 && !isEmpty(marketplace.media);

  // sum of the duration of all post videos
  const videosDuration = marketplace.media.map((a) => Math.ceil(a.duration));

  return (
    <div className="raf-activity__content">
      <div className={styles.rowWrapper}>
        {!!text && (
          <p>
            {textRenderer(
              text.trim(),
              'raf-activity',
              props.onClickMention,
              props.onClickHashtag
            )}
          </p>
        )}
      </div>

      {lockedPost ? (
        <ActivityFeedPreview
          id={id}
          actor={actor}
          file={marketplace.media[0]}
          videosDuration={videosDuration}
          coins={marketplace.price}
          stats={{
            num_images: marketplace.num_images,
            num_videos: marketplace.num_videos,
          }}
          publicModal={props.publicModal}
        />
      ) : !isEmpty(marketplace.media) ? (
        <ActivityFeedMedia
          files={marketplace.media}
          videosDuration={videosDuration}
          stats={{
            num_images: marketplace.num_images,
            num_videos: marketplace.num_videos,
          }}
        />
      ) : null}
    </div>
  );
};

export default ActivityFeedContent;
