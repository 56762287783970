import React from "react";
import {de, enGB} from "date-fns/locale";
import cx from "classnames";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {LocationInfo} from "../../user/profileInfo/profileMetaInfo";
import {Col, Row} from "../../../components/layout/grid";
import {faCalendar, faFemale, faMale} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getImageSrc} from "../../../helpers/s3media";
import {bannerDefault} from "../../../assets/default";
import styles from "./myRequestWaitingCard.module.css";
import {monthDateFix} from "../../../helpers/dating";

const MyRequestWaitingCard = ({club, hideFilter}) => {
    const {i18n} = useTranslation();
    const lang = i18n.language === 'de' ? de : enGB;

    const history = useHistory();
    const {t} = useTranslation();

    const firstImage = club.images.length > 0 ? club.images[0] : null;

    const firstImageUrl = firstImage ? getImageSrc({
        public_id: firstImage.public_id,
        format: firstImage.format,
        resize: {
            width: 400,
            fit: 'cover',
        },
    }) : getImageSrc(bannerDefault);

    //let wrapperClass = styles.wrapper;
    let wrapperClass = cx(styles.col);
    if (hideFilter) {
        wrapperClass = cx(styles.col, styles.homeouter);
    }
    const handleClick = () => {
        const clubLink = `/club/${club.key_id}`;
        history.push(clubLink);
    }

    return (
        <div className={wrapperClass}>
            <div className={styles.outerbox}>
                <Row onClick={handleClick}>
                    <Col xs={6} className={styles.eventimg}>
                        <div className={styles.imgover}>
                            <span className={styles.cartegorynameleft}>{t('features:club.club', 'Club')}</span>
                        </div>

                        <div className={styles.imgoverbottom}>
                            <span className={styles.eventdateleft}>
                                <FontAwesomeIcon icon={faCalendar} width={13} style={{marginRight: '5px'}}/>
                                {monthDateFix(club.booking_date, lang)}
                            </span>
                        </div>

                        <div
                            className={cx(styles.clubImageContainer, styles.clubImageCardNew)}>
                            <div>
                                <img
                                    src={firstImageUrl}
                                    className={styles.eventimg}
                                    alt={club.name}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} className={styles.eventinfo}>
                        <div className={styles.newblock}>
                            <div className={styles.descleft}>
                                <h3>{club.name}</h3>
                                <div className={styles.venue}>{club.address}</div>
                            </div>
                            <LocationInfo
                                customClass={styles.cityleft}
                                town_name={club.city}
                                country={club.country_code.toLowerCase()}
                            />
                        </div>
                    </Col>
                </Row>
                <div className={styles.pare}>
                    {club.club_booking_people_male > 0 &&
                        <div className={styles.first}>
                            <strong><FontAwesomeIcon icon={faMale} className={styles.icon} /> : {club.club_booking_people_male}</strong>
                        </div>
                    }
                    {club.club_booking_people_female > 0 &&
                        <div className={styles.second}>
                            <strong><FontAwesomeIcon icon={faFemale} className={styles.icon} /> : {club.club_booking_people_female}</strong>
                        </div>
                    }
                    {/*<EpisodeActions episode_id={episode.id} />*/}
                </div>

                <div className={styles.statusOne} onClick={handleClick}>
                    <div>
                        <span>{t('features:eventRequest.waitingForReview', 'Waiting For Review')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyRequestWaitingCard;