import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './qrModal.module.css';
import {Col, Row} from "../../../components/layout/grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faPersonCircleXmark} from "@fortawesome/free-solid-svg-icons";
import {monthDateTime} from "../../../helpers/dating";
import {IconMapMark} from "../../../assets/icons";
import CancellationPolicyModal from "./CancellationPolicyModal";
import {ShareEventButtonFull} from "../../user/profileInfo/Buttons";

const QRModal = ({
                     lang,
                     episode,
                     modalContext: {RenderModal},
                     handleViewMyEvents,
                     cancellationAllModalPop
                 }) => {
    const {t} = useTranslation();
    const {venue} = episode;

    const calculateTimeRemaining = () => {
        const currentTime = new Date();
        const endTime = new Date(episode.start_time);
        const timeDifference = endTime - currentTime;

        if (timeDifference <= 0) {
            return 0;
        }

        return Math.floor(timeDifference / 1000);
    };

    const [time, setTime] = useState(calculateTimeRemaining());

    useEffect(() => {
        const interval = setInterval(() => {
            const remainingTime = calculateTimeRemaining();
            if (remainingTime > 0) {
                setTime(remainingTime);
            }
        }, 1000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formatTime = (seconds) => {
        const days = Math.floor(seconds / 86400);
        const hours = Math.floor((seconds % 86400) / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        if(days === 0) {
            return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
        }
        return `${days} <small>d</small> ${hours < 10 ? '0' : ''}${hours} <small>h</small> ${minutes < 10 ? '0' : ''}${minutes} <small>m</small> ${remainingSeconds < 10 ? '0' : ''}${remainingSeconds} <small>s</small>`;
    };

    return (
        <RenderModal buttonClose>
            <div className={styles.wrapper}>
                <Row>
                    <Col md={12}>
                        <h1 className={styles.getready}>{t('features:eventRequest.get_ready', 'Get Ready!')}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <p className={styles.center}>{t('features:eventRequest.countdown', 'Countdown:')}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <h1 className={styles.timer} dangerouslySetInnerHTML={{__html: formatTime(time)}}></h1>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className={styles.qrcode}>
                        <img src={episode.qrcode_url} alt={episode.id}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className={styles.main}>
                            <div className={styles.category}>
                                {episode.event_type}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <h1 className={styles.heading}>{episode.name}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <ul className={styles.eventdetaillist}>
                            <li>
                                <FontAwesomeIcon icon={faCalendar} width={20}/>
                                {monthDateTime(episode, lang)}
                            </li>
                            <li>
                                <IconMapMark height={20}/>
                                {venue.address}
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faPersonCircleXmark} width={20}/>
                                <span>{t('features:eventRequest.cancellation', 'CANCELLATION POLICY: ')}</span>
                                <strong className={styles.cancellationPolicy}
                                >{episode.cancellation_policy}</strong>
                                <CancellationPolicyModal
                                    modalContext={cancellationAllModalPop}
                                />
                            </li>

                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <h3
                            className={styles.viewallevent}
                            onClick={() => handleViewMyEvents()}
                        >
                            {t('features:eventRequest.view_all_events', 'View all your events')}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className={styles.shareButton}>
                        <ShareEventButtonFull episode={episode} withText={true}/>
                    </Col>
                </Row>

            </div>
        </RenderModal>
    );
};

export default QRModal;
