import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';

import {
  applyEpisode,
  denyEpisode,
  getEpisodes,
} from 'features/dating/episodes/episodesSlice';
import { selectUserInfo } from 'features/auth/authSlice';
import { getUserDatingInfo } from 'features/user/profileSlice';
import { startChatTransaction } from 'features/transactions/transactionsSlice';
import { EpisodeButtons as Buttons } from 'components/buttons';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {useModal} from "../../../components/modal/useModal";
import {VerificationModal} from "../../dating/episodes/VerificationModal";

const EpisodeButtons = ({ episode }) => {
  const [isLoadingApply, setLoadingApply] = useState(false);
  const [isLoadingDeny, setLoadingDeny] = useState(false);
  const [isLoadingChange, setLoadingChange] = useState(false);
  const { uid, verification_required, verified_phone, verified_photo } = useSelector(selectUserInfo);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { profile } = episode;
  const [isChangeButton, setChangeButton] = useState(false);
  const verificationModal = useModal();

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const handleApply = async () => {
    if(verification_required === true && verified_phone  === false && verified_photo === false)
    {
      verificationModal.showModal();
      return false;
    }
    setLoadingApply(true);
    dispatch(applyEpisode({ id: episode.id }))
      .then(unwrapResult)
      .then((result) => {
      if (result.status) {
        dispatch(getEpisodes(episode.city.toLowerCase()))
          .then(unwrapResult)
          .then((result) => {
            dispatch(getUserDatingInfo({ user_id: profile.id }));
            window.scrollTo(0, 0);
          });      
      } else {
        setLoadingApply(false);
        // toast error
        if(result.error.code === 1110)
        {
          toast.error(t('main:errors.applicationOpenInDiffrentCity'));
        } else if(result.error.code === 1111) {
          toast.error(t('main:errors.verificationRequired'));
        } else {
          toast.error(t('main:errors.somethingWentWrong'));
        }        
      }
    });
      
  };

  const handleDeny = async () => {
    setLoadingDeny(true);
    dispatch(denyEpisode({ id: episode.id }))
      .then(unwrapResult)
      .then((result) => {
        dispatch(getEpisodes(episode.city.toLowerCase()))
          .then(unwrapResult)
          .then((result) => {
            dispatch(getUserDatingInfo({ user_id: profile.id }));
            history.goBack();
          });
      });
  };

  const handleChat = async () => {
    if (episode.profile.chat_open) {
      history.push(`/chat/${uid}-${episode.profile.uid}-messaging`);
    } else {
      dispatch(
        startChatTransaction({ actor: episode.profile, episodeId: episode.id })
      );
    }
  };

  const handleChange = async () => {
    setLoadingChange(true);
    await delay(1000);
    setChangeButton(true)
    setLoadingChange(false);
  };

  if (episode.applied) {
    return <Buttons handleChat={handleChat} />;
  }

  if (episode.rejected) {
    return '';
  }

  if (episode.declined) {
    return <div>
      {isChangeButton ? (
          <Buttons
              handleDeny={handleDeny}
              handleApply={handleApply}
              loadingDeny={isLoadingDeny}
              loadingApply={isLoadingApply}
          />
      ) : (
          <Buttons handleChange={handleChange}
                   loadingChange={isLoadingChange}

          />
      )}
    </div>
  }
  return (
      <>
        <VerificationModal
          modalContext={verificationModal}
      />
    <Buttons
      handleDeny={handleDeny}
      handleApply={handleApply}
      loadingDeny={isLoadingDeny}
      loadingApply={isLoadingApply}
    />
      </>
  );
};

EpisodeButtons.propTypes = {
  episode: PropTypes.object.isRequired,
};

export default EpisodeButtons;
