import React, {useState} from "react";
import {getImageSrc} from "../../../helpers/s3media";
import {bannerDefault} from "../../../assets/default";
import {Col, Row} from "../../../components/layout/grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faClock,
    faMusic,
    faShirt, faUsers
} from "@fortawesome/free-solid-svg-icons";
import styles from "./clubDetail.module.css";
import {useTranslation} from "react-i18next";
import {IconMapMark} from "../../../assets/icons";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import Description from "../../user/profileInfo/Description";
import {useWindowWidthLessThan} from "../../../hooks";
import Avatar from "../../../components/avatar/Avatar";
import {Rating} from "react-simple-star-rating";
import {ShareClubButton} from "../../user/profileInfo/Buttons";
import {PhotoSwipe} from "react-photoswipe";
import {
    setShowClubBooking, setShowPublicRegister,
} from "../../../app/miscSlice";
import {useDispatch, useSelector} from "react-redux";
import ClubBooking from "./ClubBooking";
import {selectAuthenticated} from "../../auth/authSlice";
import cx from "classnames";

const googleMapsLibraries = ['places'];

const ClubDetails = ({club, isPublic}) => {
    //const dispatch = useDispatch();
    //const [isBlocked, setBlocked] = useState(false);
    const {t} = useTranslation();
    const isMobile = useWindowWidthLessThan(769);
    const [filesIndex, setFilesIndex] = useState(0);
    const [filesToggler, setFilesToggler] = useState(false);
    const {latest} = club;
    const dispatch = useDispatch();

    const isAuthenticated = useSelector(selectAuthenticated);

    const firstImage = club.images.length > 0 ? club.images[0] : null;
    const firstImageUrl = firstImage ? getImageSrc({
        public_id: firstImage.public_id,
        format: firstImage.format,
        resize: {
            width: 1100,
            fit: 'cover',
        },
    }) : getImageSrc(bannerDefault);

    const getImages = () => {
        let items = [];
        club.images.forEach((file) => {
            const src = getImageSrc(file);
            items.push({
                html: `<div class="pswp__custom_item custom_item"><img src="${src}" alt="" /></div>`,
            });
        });
        return items;
    };

    const handleImageClick = (index) => {
        setFilesIndex(index);
        setFilesToggler(true);
    };

    const handleUserClick = (profile) => {
        if (isAuthenticated) {
            return dispatch(setShowClubBooking(true));
        }
        return dispatch(setShowPublicRegister(true));
    };

    //const {venue} = club;

    /*const isAuthenticated = useSelector(selectAuthenticated);
    const history = useHistory();*/

    /*const viewAllModal = useModal();

    const tracking = () => {
        trackEvent({
            category: `event-request-join`,
            action: 'request to join clicked',
            label: '',
        });
    }*/

    /*const handleUserClick = (profile) => {
        if (isAuthenticated) {
            return history.push(`/profile/${profile.id}`);
        }
        return dispatch(setShowPublicRegister(true, profile));
    };*/

    const mapStyles = {
        height: '300px',
        width: '100%',
        filter: 'gray'
    };

    const center = {
        lat: parseFloat(club.latitude), // Replace with your desired latitude
        lng: parseFloat(club.longitude), // Replace with your desired longitude
    };

    return (
        <>
            <Row>
                <Col xl={12}>
                    <div>
                        <div className={styles.eventdetail}>
                            <div className={styles.eventdetailhead}>
                                <div className={cx(styles.imageContainer, styles.imageContainerBanner)}>
                                    <div>
                                        <img
                                            src={firstImageUrl}
                                            className={styles.eventimg}
                                            alt={club.name}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.eventavatar}>
                                <div className={styles.avatarimg}>
                                    <Avatar
                                        /*onClick={onClick}*/
                                        size={80}
                                        /*wrapperStyle={{ position: 'absolute', top: '12px', left: '12px' }}*/
                                        image={club.avatar}
                                        radius
                                        className={styles.imguserover}
                                    />
                                    {/*<img
                                            src="https://images.unsplash.com/photo-1564564321837-a57b7070ac4f?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8bWFufGVufDB8fDB8fHww&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60"/>*/}
                                </div>
                                <div className={styles.username}>{club.name}
                                    {/*<FontAwesomeIcon icon={faCertificate} width={20}/>*/}
                                </div>
                                {/*<div className={styles.sharebutton}>
                                    <ShareButtonEventRound episode={episode} />
                                </div>*/}
                            </div>

                            <div className={styles.main}>
                                <div className={styles.category}>
                                    {t('features:club.club', 'Club')}
                                </div>
                                <div className={styles.sharebutton}>
                                    <ShareClubButton club={club} withText={true}/>
                                </div>
                            </div>
                            <div className={styles.main}>
                                <div className={styles.left}>
                                    <div className={styles.title}>
                                        <h2>{club.name}</h2>
                                    </div>

                                    <div className={styles.rating}>
                                        <Rating
                                            readonly
                                            initialValue={club.rating}
                                            fillColor="var(--accentColor)"
                                            size={18}
                                        />
                                    </div>


                                    <div className={styles.description}>
                                        {club.description.length > 0 && (
                                            <Description description={club.description} limit={isMobile ? 110 : 220}/>
                                        )}
                                    </div>
                                </div>
                                {/*
                                <div className={styles.right}>
                                    <img src={episode.qrcode_url} alt={episode.name} style={{width:'150px'}}/>
                                </div>*/}
                            </div>

                            <ul className={styles.eventdetaillist}>
                                <li>
                                    {/*<FontAwesomeIcon icon={faLocation} width={20}/>*/}
                                    <IconMapMark height={20}/>
                                    <a href="#map">{club.address}</a>
                                </li>
                                {club.music &&
                                <li>
                                    <FontAwesomeIcon icon={faMusic} width={20}/>
                                    <strong>{t('features:club.Music', 'Music: ')}</strong> {club.music}
                                </li>
                                }
                                {club.dresscode &&
                                <li>
                                    <FontAwesomeIcon icon={faShirt} width={20}/>
                                    <strong>{t('features:club.DressCode', 'Dress Code: ')}</strong> {club.dresscode}
                                </li>
                                }
                                {club.work_hours &&
                                <li>
                                    <FontAwesomeIcon icon={faClock} width={20}/>
                                    <strong>{t('features:club.WorkHours', 'Work Hours: ')}</strong> {club.work_hours}
                                </li>
                                }
                                {club.capacity &&
                                <li>
                                    <FontAwesomeIcon icon={faUsers} width={20}/>
                                    <strong>{t('features:club.Capacity', 'Capacity: ')}</strong> {club.capacity}
                                </li>
                                }
                            </ul>

                            {isPublic &&
                            <div className={styles.buttonouter}>
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        handleUserClick();
                                    }}
                                    className={styles.btn}>{t('features:club.VIPRequest', 'VIP Request')}</div>
                            </div>
                            }
                        </div>

                        {club.latitude && club.longitude &&
                        <>
                            <div className={styles.subtitle}>
                                <strong>{t('features:club.location', 'Location ')}</strong>
                            </div>
                            <div className={styles.map} id="map">
                                <LoadScript
                                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                                    libraries={googleMapsLibraries}
                                >
                                    <GoogleMap
                                        zoom={14}
                                        center={center}
                                        mapContainerStyle={mapStyles}
                                        mapTypeId="roadmap"
                                        /*options={{
                                            styles: customMapStyles, // Apply custom map styles
                                        }}*/
                                    >
                                        <Marker position={center}/>
                                    </GoogleMap>
                                </LoadScript>
                            </div>
                        </>
                        }

                        {club.images.length && (
                            <>
                                <div className={styles.subtitle}>
                                    <strong>{t('features:club.gallery', 'Gallery ')}</strong>
                                </div>

                                <div className={styles.photos}>
                                    <PhotoSwipe
                                        options={{
                                            bgOpacity: 0.5,
                                            index: filesIndex,
                                            closeElClasses: [
                                                'item',
                                                'custom_item',
                                                'caption',
                                                'zoom-wrap',
                                                'ui',
                                                'top-bar',
                                            ],
                                            closeOnScroll: false,
                                            fullscreenEl: false,
                                            zoomEl: true,
                                            shareEl: false,
                                            counterEl: false,
                                            arrowEl: true,
                                            preloaderEl: true,
                                            history: false,
                                        }}
                                        isOpen={filesToggler}
                                        items={getImages()}
                                        onClose={() => setFilesToggler(false)}
                                    />
                                </div>

                                <div className={styles.photos}>
                                    <Row className={styles.rowGallery}>
                                        {club.images.map((photo, index) => (
                                            <Col xs={4} sm={4} md={3} lg={2} className={styles.colPhoto}>
                                                <div
                                                    className={cx(styles.imageContainer, styles.imageContainerGallery)}>
                                                    <div>
                                                        <img
                                                            onClick={() => handleImageClick(index)}
                                                            key={index}
                                                            src={getImageSrc({
                                                                public_id: photo.public_id,
                                                                format: photo.format,
                                                                resize: {
                                                                    width: 180,
                                                                    fit: 'cover',
                                                                },
                                                            })}
                                                            alt={`Images ${index}`}
                                                            className={styles.image}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </>
                        )}
                    </div>

                </Col>
            </Row>
            {/*{isBlocked && (
                <div className={styles.overlay}>
                    <LoaderInline centered color="turq"/>
                </div>
            )}*/}
            <ClubBooking club={club.id} latest={latest}/>
        </>
    );
}

export default ClubDetails;