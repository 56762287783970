import React from 'react';
import cx from 'classnames';

import { ProfileMetaInfo } from './profileMetaInfo';
import { MoreButton } from './Buttons';
import SubHeader from 'components/layout/subHeader/SubHeader';
import { ProfileBadges } from 'components/layout/badges/Badges';
import styles from './profileInfo.module.css';

const StickyHeader = ({
  children,
  profileUser,
  mainActions,
  isPublic,
  isMobile,
  isMyProfile,
  customBackBtnHandler,
}) => {
  const wrapperClass = cx(styles.stickyWrapper, {
    [styles.isPublic]: isPublic,
  });

  return (
    <div className={wrapperClass}>
      <SubHeader
        special
        noMainHeader
        className={styles.sticky}
        backBtn
        customBackBtnHandler={customBackBtnHandler}
        leftEl={
          <div>
            <div className={styles.infoUser}>
              <span>{profileUser.name}</span>
              {profileUser.age && (
                <span className={styles.infoAge}>{profileUser.age}</span>
              )}
              <ProfileBadges
                verified={!profileUser.verification_required}
                dating={profileUser.dating_enabled}
                phone={profileUser.verified_phone}
                className={styles.infoBadges}
                classNameIcon={styles.colorGrey}
                small
              />
            </div>
            <ProfileMetaInfo
              customClass={styles.metaInfoDesktop}
              {...profileUser}
            />
            {/*<LocationInfo
              customClass={styles.locationInfoMobile}
              {...profileUser}
            />*/}
          </div>
        }
        children={
          isMobile ? (
            <MoreButton
              isPublic={isPublic}
              isMyProfile={isMyProfile}
              profileUser={profileUser}
              mainActions={mainActions}
              isMobile
            />
          ) : (
            children
          )
        }
      />
    </div>
  );
};

export default StickyHeader;
