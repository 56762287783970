import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import axios from 'axios';
import AWS from 'aws-sdk';
import shortId from 'short-uuid';
import { toast } from 'react-toastify';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import api from 'app/api';
import RouteLeavingGuard from 'components/routeLeavingGuard/RouteLeavingGuard';
import Button from 'components/buttons/Button';
import InfoBar from 'components/infoBar/InfoBar';
import { getImageSrc } from 'helpers/s3media';
import { getFileMeta } from 'helpers/mediaFileMeta';
import { selectUserInfo, updateUser } from 'features/auth/authSlice';

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateType
);

const ProfileImagesView = () => {
  const [files, setFiles] = useState(false);
  const [filesInfo, setFilesInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newChanges, setNewChanges] = useState(false);
  const { t } = useTranslation();

  const user = useSelector(selectUserInfo);
  const dispatch = useDispatch();
  const pond = useRef(null);

  const isUploadDisabled = process.env.REACT_APP_DISABLE_UPLOAD === 'true';

  AWS.config.update({
    region: process.env.REACT_APP_S3_BUCKET_REGION,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_S3_POOL_ID,
    }),
  });

  useEffect(() => {
    let finalFiles = [];
    let finalFilesInfo = [];
    user.images.forEach(function (item, index) {
      const url = getImageSrc({
        public_id: item.public_id,
        format: item.format,
        resize: { width: 200, fit: 'cover' },
      });
      // console.log(url);
      finalFiles.push({
        source: url,
        options: {
          type: 'local',
        },
      });
      finalFilesInfo.push({ url: url, info: item });
    });
    setFiles(finalFiles);
    setFilesInfo(finalFilesInfo);
  }, [user, dispatch]);

  useEffect(() => {
    return () => dispatch(updateUser());
  }, [dispatch]);

  const handleSetFiles = (fileItems) => {
    setFiles(fileItems);
  };

  const handleRemove = async (errRes, file) => {
    let item = _find(filesInfo, { url: file.serverId });
    if (item) {
      await api
        .delete('v2/user/profile/image', {
          data: { public_id: item.info.public_id },
        })
        .then((response) => {
          if (response.data.status) {
            setNewChanges(true);
          }
        })
        .catch((err) => console.log(err));
    } else {
      console.log('missing file Info');
    }
  };

  const handleReorder = (items, origin, target) => {
    setFiles(items);
    setNewChanges(true);
  };

  const countValidFiles = () => {
    return filter(files, ({ status }) => {
      return status !== 8 && status !== 6;
    }).length;
  };

  const getFilesIDs = () => {
    let validFiles = [];
    files.forEach(function (item, index) {
      if (typeof item.serverId === 'string') {
        let oldItem = _find(filesInfo, { url: item.serverId });
        validFiles.push(oldItem.info.public_id);
      } else if (typeof item.serverId === 'object') {
        validFiles.push(item.serverId.public_id);
      }
    });
    return validFiles;
  };

  const handleSubmitChanges = async () => {
    setLoading(true);
    const ids = getFilesIDs();
    // console.log(ids);
    await api
      .post('v2/user/profile/images/order', {
        images: ids,
      })
      .then((response) => {
        if (response.data.status) {
          setLoading(false);
          setNewChanges(false);
          toast.success(t('main:success.savedSuccessfully'));
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(t('main:errors.somethingWentWrong'));
        // console.log(err);
      });
  };

  const uploadS3 = () => ({
    process: async (
      fieldName,
      file,
      metadata,
      load,
      error,
      progress,
      abort
    ) => {
      const fileMeta = await getFileMeta(file);
      if (!fileMeta) {
        return error();
      }

      const fileExt = file.name.split('.').pop();
      const fileId = `models/${user.uid}/${shortId.generate()}`;
      const s3Key = `${fileId}.${fileExt}`;
      let fileInfo = {
        duration: fileMeta.duration,
        width: fileMeta.width,
        height: fileMeta.height,
        type: fileMeta.type,
        size: file.size,
        format: fileExt,
        public_id: fileId,
      };
      // console.log(fileInfo, fileMeta);

      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: process.env.REACT_APP_S3_BUCKET_NAME_UPLOAD,
          Key: s3Key,
          Body: file,
          ContentType: "image/jpg, image/png, image/jpeg",
        },
      });
      upload.on('httpUploadProgress', (e) => {
        progress(true, e.loaded, e.total);
      });

      const promise = upload.promise();
      promise.then(
        function (data) {
          load({ body: fileInfo });
          setNewChanges(true);
          fileInfo.s3_data = data;
          api.put('v2/user/profile/image', {
            media: JSON.stringify(fileInfo),
          });
        },
        function (err) {
          return error();
        }
      );

      return {
        abort: () => {
          console.log('abort');
          abort();
        },
      };
    },
    load: async (source, load, error, progress, abort, headers) => {
      await axios
        .get(source, {
          responseType: 'blob',
          timeout: 10000,
        })
        .then((response) => {
          load(response.data);
        })
        .catch((err) => {
          abort();
          console.log(err);
        });
    },
    revert: async (uniqueFileId, load, error) => {
      // console.log(uniqueFileId);
      await api
        .delete('v2/user/profile/image', {
          data: { public_id: uniqueFileId.public_id },
        })
        .then((response) => {
          load({ body: response.data });
          setNewChanges(true);
        })
        .catch((err) => console.log(err));
    },
  });

  if (isUploadDisabled) {
    return <InfoBar badge={'INFO'} info={t('main:uploadDisabled.text')} />;
  }

  return (
    <>
      <div className={`maleImages ${isEmpty(files) ? 'noFiles' : ''}`}>
        <FilePond
          ref={pond}
          files={files}
          dropOnPage
          allowReorder
          allowMultiple
          credits={false}
          dropOnElement={false}
          imagePreviewHeight="300px"
          itemInsertLocation="after"
          allowFileTypeValidation
          acceptedFileTypes={['image/*']}
          onupdatefiles={(fileItems) => handleSetFiles(fileItems)}
          onremovefile={(errRes, file) => handleRemove(errRes, file)}
          onreorderfiles={handleReorder}
          server={uploadS3()}
          name="files"
          labelIdle={t(
            'features:images.dragdrop',
            'Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          )}
        />
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '15px 15px 0',
          }}
        >
          <Button
            size="xl"
            type="button"
            onClick={() => pond.current && pond.current.browse()}
          >
            {t('features:images.AddImages')}
          </Button>
          <Button
            size="xl"
            name="btn-gradient"
            type="button"
            loading={loading}
            disabled={!newChanges || countValidFiles() < 1}
            onClick={handleSubmitChanges}
            style={{ marginLeft: '10px', width: '106px' }}
          >
            {t('main:main.Save')}
          </Button>
        </div>
      </div>
      <RouteLeavingGuard when={newChanges && countValidFiles() > 0} />
    </>
  );
};

export default ProfileImagesView;
