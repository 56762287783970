import React from 'react';
/*import ClubCard from "./ClubCard";*/
import MyRequestWaitingCard from "./MyRequestWaitingCard";

const ClubListItem = ({club, isPublic, hideFilter}) => {
    return (
        <MyRequestWaitingCard
            club={club}
            hideFilter={hideFilter}
            isPublic={isPublic}/>
    );
};

export default ClubListItem;
