import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/buttons/Button';
//import { IconMobileHearts } from 'assets/icons';
import styles from './myEpisodesEmpty.module.css';
import {VerificationModal} from "../VerificationModal";
import React from "react";
import {useModal} from "components/modal/useModal";
import {useSelector} from "react-redux";
import {selectUserInfo} from "../../../auth/authSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";

const MyEpisodesEmpty = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const verificationModal = useModal();
  const { indispensable_verification } = useSelector(selectUserInfo);

  const createDateValidation = () => {
    if(indispensable_verification === true)
    {
      verificationModal.showModal();
      return false;
    }
    history.push('/create-event')
  }

  return (
    <div className={styles.wrapperEmpty}>
      <VerificationModal
          modalContext={verificationModal}
      />
      <div className={styles.infoBox}>
        <div className={styles.iconTop}>
          {/*<IconMobileHearts />*/}
          <FontAwesomeIcon icon={faCalendar} width={20} />
        </div>
        <h2>{t('features:myEpisodes.emptyTitle')}</h2>
        <p>{t('features:myEpisodes.emptySubtitle')} </p>
        <Button
          extraPadding
          onClick={createDateValidation}
          name="btn-gradient"
          size="xl"
        >
          {t('features:event.nav.shareEventRequest')}
        </Button>
      </div>
    </div>
  );
};

export default MyEpisodesEmpty;
