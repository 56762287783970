import React, {useEffect, useRef} from "react";
import styles from "./search.module.css";
import {IconUserSearch} from "../../../assets/icons";
import {useTranslation} from "react-i18next";
import _debounce from "lodash/debounce";
import {resetSearch} from "./searchSlice";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";

const EmptyCommunity = () => {
    const history = useHistory();
    const ref = useRef(null);
    const query = new URLSearchParams(useLocation().search);
    const search = query.get('search');
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleInputChange = _debounce((event) => {
        const query = event.target.value;
        if (!query) {
            history.push('/users');
            dispatch(resetSearch());
        } else if (query.length < 3) {
            return;
        } else {
            history.push(`/users/search?search=${query}`);
            dispatch(resetSearch());
        }
    }, 500);

    useEffect(() => {
        ref.current.value = search;
    }, [search]);

    return (
        <div className={styles.wrapperEmpty}>
            <div className={styles.infoBox}>
                {/*<div className={styles.iconTop}>
                    <IconEmptySearch />
                </div>*/}
                <h2>{t('features:search.searchHead')}</h2>
                <p>{t('features:search.searchSubHead')}</p>
                <div className={styles.inputSearch}>
                    <input
                        placeholder={t('features:search.searchUsers')}
                        className={styles.inputnew}
                        // onBlur={resetInput}
                        onChange={handleInputChange}
                        onSubmit={handleInputChange}
                        ref={ref}
                    />
                    <IconUserSearch/>
                </div>
                {/*<h2>{t('features:myEpisodes.emptyTitle')}</h2>
                <p>{t('features:myEpisodes.emptySubtitle')} </p>*/}
            </div>
        </div>
    );
}

export default EmptyCommunity;