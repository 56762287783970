import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { isBoolean } from 'lodash';
import styles from './navTabs.module.css';
import cx from 'classnames';

const NavTabs = ({ items, className, small }) => {
  return (
    <div
      className={
        className
          ? className
          : cx(styles.tabsWrapper, { [styles.tabsWrapperSmall]: small })
      }
    >
      {items.map((item, i) => {
        if (isBoolean(item.condition) && !item.condition) return null;
        return (
          <NavLink
            key={i}
            to={item.to}
            exact={item.exact}
            replace={item.replace}
            className={cx(styles.tab, { [styles.tabSmall]: small })}
            activeClassName={styles.active}
          >
            {item.text}
          </NavLink>
        );
      })}
    </div>
  );
};

NavTabs.propTypes = {
  items: PropTypes.array.isRequired,
};

export default NavTabs;
