import _isEmpty from 'lodash/isEmpty';
import { isMobileOnly, isWearable, isIOS } from 'react-device-detect';
import {
  avatarMale,
  avatarFemale,
  videoThumbnail,
  videoPreview, bannerDefault,
} from 'assets/default';

const DEFAULT_SIZE = {
  hd: {
    w: process.env.REACT_APP_FILE_WIDTH_HD,
    h: process.env.REACT_APP_FILE_HEIGHT_HD,
  },
  big: process.env.REACT_APP_FILE_WIDTH_BIG,
  medium: process.env.REACT_APP_FILE_WIDTH_MEDIUM,
  small: process.env.REACT_APP_FILE_WIDTH_SMALL,
};

function calculate_resize(file) {
  let resize = {};
  if (file.height && file.width) {
    const isVertical = file.height > file.width;
    const file_full_size = isVertical ? file.height : file.width;
    const file_max_size = isVertical ? DEFAULT_SIZE.hd.h : DEFAULT_SIZE.hd.w;

    if (file_full_size > DEFAULT_SIZE.medium && (isMobileOnly || isWearable)) {
      resize.width = DEFAULT_SIZE.medium;
      resize.fit = 'cover';
    } else if (file_full_size > file_max_size) {
      if (isVertical) {
        resize.height = file_max_size;
      } else {
        resize.width = file_max_size;
      }
      resize.fit = 'cover';
    }
  }
  return resize;
}

export function getBase64encodedRequest(fileKey, format, resize) {
  const base_src = process.env.REACT_APP_S3_BASE_SRC;
  const imageRequest = JSON.stringify({
    bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    key: `${fileKey}.${format}`,
    edits: {
      resize: resize,
      rotate: null, // https://github.com/aws-solutions/serverless-image-handler/issues/135
    },
  });
  // console.log(`${fileKey}.${format}`, `${base_src}/${btoa(imageRequest)}`);
  return `${base_src}/${btoa(imageRequest)}`;
}

export function getImageSrc(props) {
  const fileKey = props.public_id;
  const format = props.format || 'jpg';
  const resize = props.resize || calculate_resize(props);
  return getBase64encodedRequest(fileKey, format, resize);
}

export function getLayoutImageSrc(image) {
  return getBase64encodedRequest(
    image.public_id,
    image.format,
    image.resize || {}
  );
}

export function checkImageSrc(src, size = 110, gender) {
  if (src.includes('cloudinary') || src.includes('cdn.onlyvip.com')) {
    return getAvatarSrc(null, size, gender);
  }
  return src;
}

export function checkBannerImageSrc(src, width = 480, height = 120) {
  if (src.includes('cloudinary') || src.includes('cdn.onlyvip.com')) {
    return getBannerSrc(null, width, height);
  }
  return src;
}

export function getBannerSrc(file, width = 480, height = 120) {
  const placeholder = bannerDefault;
  const image = _isEmpty(file) || _isEmpty(file.public_id) ? placeholder : file;
  const imageInfo = {
    public_id: image.public_id,
    format: image.format,
    resize: {
      width: width,
      height: height,
      fit: 'cover',
    },
  };

  return getImageSrc(imageInfo);
}

export function getAvatarSrc(file, size = 110, gender) {
  const placeholder = gender === 'male' ? avatarMale : avatarFemale;
  const image = _isEmpty(file) || _isEmpty(file.public_id) ? placeholder : file;
  const imageInfo = {
    public_id: image.public_id,
    format: image.format,
    resize: {
      width: size,
      height: size,
      fit: 'cover',
    },
  };

  return getImageSrc(imageInfo);
}

export function getDatingSrc(file, size) {
  const placeholder = avatarFemale;
  const image = _isEmpty(file) || _isEmpty(file.public_id) ? placeholder : file;
  const imageInfo = {
    public_id: image.public_id,
    format: image.format,
    resize: {
      width: size.w,
      height: size.h,
      fit: 'cover',
    },
  };

  return getImageSrc(imageInfo);
}

export function getVideoSrc(public_id, isStory = false) {
  const base_src = process.env.REACT_APP_S3_BASE_VIDEOS;
  const videoId = public_id.split('/').pop();
  const iOSsrc = isStory ? `mp4/${videoId}.mp4` : 'hls/master.m3u8';
  const video_codec = isIOS ? iOSsrc : `webmvp9/${videoId}.webm`;
  return `${base_src}/${public_id}/${video_codec}`;
}

export function getVideoGifSrc(file) {
  const base_src = process.env.REACT_APP_S3_BASE_VIDEOS;
  if (!file || !file.transform || !file.transform.gif) {
    return `${base_src}/${videoPreview.public_id}.${videoPreview.format}`;
  }
  const file_path = `${file.public_id}/preview/500x219.gif`;
  return `${base_src}/${file_path}`;
}

export function getVideoThumbSrc(file, resize) {
  if (!file.transform || !file.transform.jpg) {
    return getImageSrc(videoThumbnail);
  }

  const imageInfo = {
    public_id: `${file.public_id}/thumbnail/full`,
    format: 'jpg',
    resize: resize || calculate_resize(file),
  };
  // console.log(imageInfo);
  return getImageSrc(imageInfo);
}

export function getVideoThumbSrcMedia(file, resize) {
  if (!file.transform || !file.transform.jpg) {
    return getImageSrc(videoThumbnail);
  }

  const imageInfo = {
    public_id: `${file.public_id}/thumbnail/full`,
    format: 'jpg',
    resize: resize || calculate_resize(file),
  };
  return imageInfo;
  // console.log(imageInfo);
  //return getImageSrc(imageInfo);
}

export function getStorySrc(file) {
  if (file.type === 'image') {
    return getImageSrc(file);
  }
  return getVideoSrc(file.public_id, true);
}

export function getStoryPreview(file, size) {
  const resize = {
    width: size,
    height: size,
    fit: 'cover',
  };
  if (file.type === 'image') {
    return getImageSrc({
      public_id: file.public_id,
      format: file.format,
      resize: resize,
    });
  }
  return getVideoThumbSrc(file, resize);
}

export function getStoryPreviewMedia(file, size) {
  const resize = {
    width: size,
    height: size,
    fit: 'cover',
  };
  if (file.type === 'image') {
    return getImageSrc({
      public_id: file.public_id,
      format: file.format,
      resize: resize,
    });
  }
  return getVideoThumbSrcMedia(file, resize);
}

export function getFeedMediaSrc(file) {
  // VIDEO
  if (file.type === 'video') {
    return getVideoSrc(file.public_id);
  }

  // IMAGE
  return getImageSrc(file);
}

export function getFeedMediaPreviewSrc(file, resize) {
  const default_resize = {
    width: DEFAULT_SIZE.big,
    height: Math.floor(DEFAULT_SIZE.big / (16 / 7)),
    fit: 'cover',
  };

  // VIDEO
  if (file.type === 'video') {
    return getVideoThumbSrc(file, resize || default_resize);
  }

  // IMAGE
  const imageInfo = {
    public_id: file.public_id,
    format: file.format,
    resize: resize || default_resize,
  };
  return getImageSrc(imageInfo);
}
