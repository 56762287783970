import { Row, Col } from 'components/layout/grid';
import { PublicLayout } from 'components/layout/layouts';
import SubHeader from 'components/layout/subHeader/SubHeader';
import GlobalFeed from 'features/feed/GlobalFeed';
import { NavFilters } from 'features/feed/navFilters';
import SuggestionsListPublic from 'features/user/suggestions/SuggestionsListPublic';

const FeedPublicScreen = () => {
  const screenName = 'Feed';

  return (
    <PublicLayout>
      <Row>
        <Col sm={12} lg={8}>
          <SubHeader backBtn screenName={screenName} hideOnMobile />
          <NavFilters />
          <GlobalFeed />
        </Col>
        <Col sm={12} lg={4}>
          <SuggestionsListPublic />
        </Col>
      </Row>
    </PublicLayout>
  );
};

export default FeedPublicScreen;
