import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    handleResize();

    const onResizeEvent = debounce(handleResize, 100);

    window.addEventListener('resize', onResizeEvent, true);

    return () => window.removeEventListener('resize', onResizeEvent, true);
  }, []);

  return windowSize;
}

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(null);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    handleResize();

    const onResizeEvent = debounce(handleResize, 100);

    window.addEventListener('resize', onResizeEvent, true);

    return () => window.removeEventListener('resize', onResizeEvent, true);
  }, []);

  return windowWidth;
};

export const useWindowWidthLessThan = (breakpoint) => {
  const [isSmaller, setIsSmaller] = useState(window.innerWidth < breakpoint);

  useEffect(() => {
    function handleResize() {
      setIsSmaller(window.innerWidth < breakpoint);
    }

    handleResize();

    const onResizeEvent = debounce(handleResize, 100);

    window.addEventListener('resize', onResizeEvent, true);

    return () => window.removeEventListener('resize', onResizeEvent, true);
  }, [breakpoint]);

  return isSmaller;
};

export default useWindowSize;
