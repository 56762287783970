import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import cx from 'classnames';
import * as Yup from 'yup';

import { Checkbox, FormFieldError, FormRow } from 'components/forms';
import Button from 'components/buttons/Button';
import { selectSignupState } from 'features/auth/authSlice';
import formStyles from 'components/forms/forms.module.css';
import styles from 'features/auth/auth.module.css';

const RegisterForm = forwardRef(({ onSubmit, isModal }, ref) => {
  const signupState = useSelector(selectSignupState);
  const { t } = useTranslation();

  const formConfig = {
    schema: Yup.object().shape({
      email: Yup.string()
        .email(t('components:validation.invalidEmail'))
        .required(t('components:validation.required')),
      password: Yup.string()
        .min(8, 'Too Short!')
        .matches(/(?=.*[a-z])/, t('components:validation.lowerCase'))
        .matches(/(?=.*[A-Z])/, t('components:validation.upperCase'))
        .matches(/(?=.*\d)/, t('components:validation.includeDigit')),
      confirm_password: Yup.string()
        .oneOf(
          [Yup.ref('password'), null],
          t('components:validation.mustMatch')
        )
        .required(t('components:validation.required')),
      cbLegal: Yup.bool()
        .test(
          'cbLegal',
          t('components:validation.agreementRequired'),
          (value) => value === true
        )
        .required(t('components:validation.required')),
/*      cbAge: Yup.bool()
        .test(
          'cbAge',
          t('components:validation.mustBeAdult'),
          (value) => value === true
        )
        .required(t('components:validation.required')),*/
    }),
    initialValues: {
      email: '',
      password: '',
      confirm_password: '',
      cbLegal: false,
      //cbAge: false,
      referral_code: window.localStorage.getItem('ref')
        ? Number(window.localStorage.getItem('ref'))
        : null,
    },
  };

  return (
    <Formik
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.schema}
      onSubmit={(data) => {
        // console.log(data);
        onSubmit(data);
      }}
    >
      {({ hasError }) => (
        <Form className={styles.form}>
          <FormRow style={{ position: 'relative' }} isModal={isModal}>
            <label
              className={cx(formStyles.formLabel, formStyles.required)}
              htmlFor="email"
            >
              {t('main:label.Email')}
            </label>
            <FormFieldError fieldName="email" />
            <Field
              className={formStyles.input}
              placeholder={t('main:label.Email')}
              type="text"
              name="email"
            />
          </FormRow>
          <FormRow isModal={isModal}>
            <label
              className={cx(formStyles.formLabel, formStyles.required)}
              htmlFor="password"
            >
              {t('features:auth.register.Password')}
            </label>
            <FormFieldError fieldName="password" />
            <Field
              className={formStyles.input}
              placeholder={t('features:auth.register.Password')}
              type="password"
              name="password"
              autoComplete="on"
            />
          </FormRow>
          <FormRow isModal={isModal}>
            <label
              className={cx(formStyles.formLabel, formStyles.required)}
              htmlFor="confirm_password"
            >
              {t('features:auth.register.confirmPassword')}
            </label>
            <FormFieldError fieldName="confirm_password" />
            <Field
              className={formStyles.input}
              type="password"
              name="confirm_password"
              placeholder={t('features:auth.register.confirmPassword')}
              autoComplete="on"
            />
          </FormRow>
          {/*<FormRow
            isModal={isModal}
            className={cx(formStyles.formRow, formStyles.formRowCb)}
          >
            <label
              className={cx(styles.checkboxLabel, styles.checkboxSmall)}
              htmlFor="cbAge"
            >
              <Checkbox
                className={styles.checkBox}
                type="checkbox"
                name="cbAge"
                id="cbAge"
              >
                {t('features:auth.register.ageConfirm')}
              </Checkbox>
            </label>
            <FormFieldError fieldName="cbAge" isCheckbox />
          </FormRow>*/}
          <FormRow>
            <label
              className={cx(styles.checkboxLabel, styles.checkboxSmall)}
              htmlFor="cbLegal"
            >
              <Checkbox
                className={styles.checkBox}
                type="checkbox"
                id="cbLegal"
                name="cbLegal"
              >
                <Trans i18nKey={'features:auth.register.confirmTnC'}>
                  I have read and accept the{' '}
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={process.env.REACT_APP_PRIVACY_PAGE}
                  >
                    privacy policy
                  </a>{' '}
                  and the{' '}
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={process.env.REACT_APP_AGB_PAGE}
                  >
                    general terms and conditions
                  </a>
                  .
                </Trans>
              </Checkbox>
            </label>
            <FormFieldError fieldName="cbLegal" isCheckbox />
          </FormRow>
          <FormRow isModal={isModal}>
            <Button
              loading={signupState.loading}
              disabled={signupState.loading}
              size="xl"
              fullWidth
              name="btn-gradient"
              type="submit"
            >
              {t('main:main.Register')}
            </Button>
          </FormRow>
        </Form>
      )}
    </Formik>
  );
});

export default RegisterForm;
