import React from 'react';

import { Row, Col } from 'components/layout/grid';
import UserList from '../../userList/UserList';
// import SubHeader from '../../../components/layout/subHeader/SubHeader';

import { IconMobileHearts } from '../../../assets/icons';
import styles from './applicationsList.module.css';
import { Trans } from 'react-i18next';

const ApplicationNoDating = ({ country }) => {
  return (
    <Row center="xs" className="ohlala-main">
      <Col xs={12} md={8} className="mainContent">
        <div className={styles.wrapperEmpty}>
          {/*<SubHeader
            backBtn
            screenName={t(
              'features:datingApplications.Your date request',
              'Your date request'
            )}
            className={styles.subHeader}
          />*/}
          <div className={styles.infoBox}>
            <div className={styles.iconTop}>
              <IconMobileHearts />
            </div>
            <Trans i18nKey="features:event.ApplicationsNoDating">
              <h2>We apologies</h2>
              <p>Only VIP is not yet available in {{ country }}</p>
            </Trans>
          </div>
        </div>
        <div className={styles.suggestions}>
          {/* <p>
            {t(
              'features:datingEmptyList.chatCTA',
              'Chat with girls in your nearby'
            )}
          </p> */}
          <UserList narrow />
        </div>
      </Col>
    </Row>
  );
};

export default ApplicationNoDating;
