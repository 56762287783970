import React from 'react';

import { Default, Image, Video } from './content';
import styles from './../stories.module.css';

const Story = (props) => {
  const rendererMessageHandler = (type, data) => {
    switch (type) {
      case 'UPDATE_VIDEO_DURATION':
      default:
        props.getVideoDuration(data.duration);
        return { ack: 'OK' };
    }
  };

  return (
    <div className={styles.story}>
      {props.story.media.type === 'image' ? (
        <Image
          action={props.action}
          isPaused={props.playState}
          story={props.story}
        />
      ) : props.story.media.type === 'video' ? (
        <Video
          action={props.action}
          isPaused={props.playState}
          isActive={props.isActive}
          story={props.story}
          messageHandler={rendererMessageHandler}
        />
      ) : (
        <Default action={props.action} story={props.story} />
      )}
    </div>
  );
};

export default Story;
