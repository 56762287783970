import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/api';

export const getConnections = createAsyncThunk(
  'connections/getConnections',
  async () => {
    const res = await api.get('/v2/user/connections');
    return res.data;
  }
);

export const connectionList = createSlice({
  name: 'connectionList',
  initialState: {
    connections: {
      data: [],
      loading: false,
      error: false,
      success: false,
    },
  },

  extraReducers: {
    [getConnections.pending]: (state) => {
      state.connections.loading = true;
    },
    [getConnections.fulfilled]: (state, action) => {
      if (action.payload.status) {
        state.connections.data = action.payload.data.connections;
        state.connections.loading = false;
      }
    },
  },
});

export const selectConnections = (state) => state.connectionList.connections;

export default connectionList.reducer;