import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { getMyEpisodesPath } from 'helpers/dating';
import { ApplicationButtons as Buttons } from 'components/buttons';
import { getUserDatingInfo } from 'features/user/profileSlice';
import { denyApplication } from 'features/dating/applications/applicationsSlice';
import { startChatTransaction } from 'features/transactions/transactionsSlice';
import { selectUserInfo } from 'features/auth/authSlice';

const ApplicationButtons = ({ application }) => {
  const [isLoadingDeny, setLoadingDeny] = useState(false);
  const me = useSelector(selectUserInfo);
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const myEpisodesPath = getMyEpisodesPath(me.gender);

  const { profile, episode, chat_id } = application;

  const navState = location.state || {};
  const hasDatingParams = navState.application_id || navState.episode_id;

  const handleDeny = async () => {
    setLoadingDeny(true);
    dispatch(denyApplication({ id: application.id }))
      .then(unwrapResult)
      .then((result) => {
        if (result.status) {
          setLoadingDeny(false);
          if (hasDatingParams) {
            history.push(myEpisodesPath);
          } else {
            dispatch(getUserDatingInfo({ user_id: profile.id }));
          }
        } else {
          throw new Error('deny error');
        }
      })
      .catch((error) => {
        toast.error(t('main:errors.somethingWentWrong'));
      });
  };

  const handleOpenChat = () => {
    if (chat_id > 0) {
      history.push(`/chat/${me.uid}-${profile.uid}-messaging`);
    } else {
      dispatch(startChatTransaction({ actor: profile, episodeId: episode.id }));
    }
  };

  return (
    <Buttons
      handleDeny={handleDeny}
      handleOpenChat={handleOpenChat}
      loading={isLoadingDeny}
    />
  );
};

ApplicationButtons.propTypes = {
  application: PropTypes.object.isRequired,
};

export default ApplicationButtons;
