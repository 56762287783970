import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import styles from './dating.module.css';
import {faCheck, faClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ApplicationButtons = ({ handleDeny, handleAccept, handleOpenChat, loading, size }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.wrapperPop, { [styles.big]: size === 'xl' })}>
        <div
            size={size}
            /*color="turq"*/
            onClick={handleAccept}
            className={`${styles.requestbtn} ${styles.accept}`}
        >
            <FontAwesomeIcon icon={faCheck} width={20} />
            <span>{t('features:event.accept','Accept')}</span>
        </div>
      <div
        size={size}
        /*color="grey"*/
        onClick={handleDeny}
        loading={loading}
        className={`${styles.requestbtn} ${styles.reject}`}
      >
        <FontAwesomeIcon icon={faClose} width={20} />
        <span>{t('features:event.reject','Reject')}</span>
      </div>
    </div>
  );
};

ApplicationButtons.propTypes = {
  handleDeny: PropTypes.func.isRequired,
  handleOpenChat: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  size: PropTypes.string,
};

ApplicationButtons.defaultProps = {
  loading: false,
  size: 'xl',
};

export default ApplicationButtons;
