import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { useFormikContext } from 'formik';
import Select from 'react-select';

import customStyles from './react-select-customStyles.js';
import formStyles from './forms.module.css';
import { useTranslation } from 'react-i18next';

const LanguagesSelect = ({ options, initOptions, withLabel, required }) => {
  const { setFieldValue } = useFormikContext();
  const [languages, setLanguages] = useState([]);
  const [defaults, setDefaults] = useState([]);

  const { t } = useTranslation();

  const parseLanguages = (languages) => {
    if (_isEmpty(languages)) {
      return [];
    }
    const parsedLanguages = languages.map((language) => {
      return {
        label: language.name,
        value: language.slug,
        id: language.id,
      };
    });
    return parsedLanguages;
  };

  useEffect(() => {
    const defaults = parseLanguages(initOptions);
    setDefaults(defaults);

    const languages = parseLanguages(options);
    setLanguages(languages);
  }, [options, initOptions]);

  const handleOnChange = (e) => {
    let parsedValues = [];
    if (!_isEmpty(e)) {
      e.map((language) => {
        return parsedValues.push(language.value);
      });
    }
    setFieldValue('languages', parsedValues.join());
  };

  if (_isEmpty(languages)) {
    return null;
  }

  return (
    <>
      {withLabel && (
        <label
          className={`${formStyles.formLabel} ${
            required ? formStyles.required : ''
          }`}
          htmlFor="languages"
        >
          {t('components:langSelect.Languages')}
        </label>
      )}
      <Select
        isMulti
        isClearable={false}
        isSearchable={false}
        name="languages"
        options={languages}
        defaultValue={defaults}
        styles={customStyles}
        classNamePrefix="ohlala"
        onChange={handleOnChange}
        placeholder={t('components:select.placeholder')}
      />
    </>
  );
};

LanguagesSelect.propTypes = {
  options: PropTypes.array.isRequired,
  initOptions: PropTypes.any,
  required: PropTypes.bool,
  withLabel: PropTypes.bool,
};

LanguagesSelect.defaultProps = {
  required: false,
  withLabel: false,
};

export default LanguagesSelect;
