import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import api from 'app/api';
import { parseSelectOptions } from 'helpers/parseSelectOptions';
import Button from 'components/buttons/Button';
import { FormRow, TextArea, Select } from 'components/forms';
import { LoaderRelative } from 'components/layout/loader';
import {
  getCloseAccountReasons,
  selectCloseAccountReasons,
} from 'app/miscSlice';
import { logout } from 'features/auth/authSlice';
import styles from './modals.module.css';

const CloseAccountModal = ({ modalContext: { RenderModal, hideModal } }) => {
  const [loading, setloading] = useState(false);
  const [error, setError] = useState(false);
  const closeReasons = useSelector(selectCloseAccountReasons);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const parsedOptions = parseSelectOptions(closeReasons, 'id', 'reason');

  const logoutUser = () => {
    history.push('/');
    dispatch(
      logout(t('features:settings.account.closeAccount.success_message'))
    );
  };

  const handleSubmit = async (values) => {
    setloading(true);

    const url = 'v2/user/actions/account/close/create';
    const payload = {
      reason_id: Number(values.reason_id),
      description: values.description,
    };

    try {
      const res = await api.post(url, payload);
      if (!res.data.status) {
        throw new Error('report user error');
      }
      await hideModal();
      await logoutUser();
    } catch (error) {
      setError(true);
      setloading(false);
    }
  };

  useEffect(() => {
    if (isEmpty(closeReasons)) {
      dispatch(getCloseAccountReasons());
    }
  }, [dispatch, closeReasons]);

  if (error) {
    return (
      <RenderModal
        title={t('features:settings.account.closeAccount.title')}
        buttonClose
      >
        <div className={styles.closeReasons}>
          <p> {t('main:errors.somethingWentWrong')}</p>
          <br />
          <Button onClick={() => hideModal()} name="btn" fullWidth>
            {t('main:main.OK')}
          </Button>
        </div>
      </RenderModal>
    );
  }

  if (loading) {
    return (
      <RenderModal
        title={t('features:settings.account.closeAccount.title')}
        buttonClose
      >
        <LoaderRelative />
      </RenderModal>
    );
  }

  return (
    <RenderModal
      title={t('features:settings.account.closeAccount.title')}
      buttonClose
    >
      <p className={styles.copy}>
        {t('features:settings.account.closeAccount.description')}
      </p>

      <div className={styles.closeReasons}>
        <Formik
          initialValues={{ reason_id: 0, description: '' }}
          validationSchema={Yup.object().shape({
            reason_id: Yup.number()
              .min(1, t('components:validation.required'))
              .required(t('components:validation.required')),
            description: Yup.string(),
          })}
          onSubmit={async (values) => {
            await handleSubmit(values);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormRow>
                <Select
                  label={t(
                    'features:settings.account.closeAccount.reason_label'
                  )}
                  name="reason_id"
                  options={parsedOptions}
                  required
                />
              </FormRow>
              <FormRow>
                <TextArea
                  placeholder={t('features:blockReportReason.writeReason')}
                  name="description"
                  label={t('features:blockReportReason.commentLabel')}
                ></TextArea>
              </FormRow>

              <div className={styles.customReasonBtns}>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  name="btn"
                  fullWidth
                  size="xl"
                >
                  {t('features:settings.account.closeAccount.button')}
                </Button>
                <Button
                  onClick={() => {
                    hideModal();
                  }}
                  type="button"
                  name="btn-grey"
                  fullWidth
                  size="xl"
                >
                  {t('main:main.Cancel')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </RenderModal>
  );
};

const PauseAccountModal = ({ modalContext: { RenderModal, hideModal } }) => {
  const [loading, setloading] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const logoutUser = () => {
    history.push('/');
    dispatch(
      logout(t('features:settings.account.pauseAccount.success_message'))
    );
  };

  const handleSubmit = async (values) => {
    setloading(true);

    const url = 'v2/user/actions/account/pause';
    const payload = {
      description: values.description,
      pause_until: values.pause_until,
    };

    try {
      const res = await api.post(url, payload);
      if (!res.data.status) {
        throw new Error('report user error');
      }
      await hideModal();
      await logoutUser();
    } catch (error) {
      setError(true);
      setloading(false);
    }
  };

  if (error) {
    return (
      <RenderModal
        title={t('features:settings.account.pauseAccount.title')}
        buttonClose
      >
        <div className={styles.reportReasons}>
          <p> {t('main:errors.somethingWentWrong')}</p>
          <br />
          <Button onClick={() => hideModal()} name="btn" fullWidth>
            {t('main:main.OK')}
          </Button>
        </div>
      </RenderModal>
    );
  }

  if (loading) {
    return (
      <RenderModal
        title={t('features:settings.account.pauseAccount.title')}
        buttonClose
      >
        <LoaderRelative />
      </RenderModal>
    );
  }

  return (
    <RenderModal
      title={t('features:settings.account.pauseAccount.title')}
      buttonClose
    >
      <p className={styles.copy}>
        {t('features:settings.account.pauseAccount.description')}
      </p>

      <div className={styles.reportReasons}>
        <Formik
          initialValues={{ description: '', pause_until: '' }}
          validationSchema={Yup.object().shape({
            pause_until: Yup.string().required(
              t('components:validation.required')
            ),
            description: Yup.string(),
          })}
          onSubmit={async (values) => {
            await handleSubmit(values);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormRow>
                <Select
                  label={t('features:settings.account.pauseAccount.period')}
                  name="pause_until"
                  required
                  options={[
                    {
                      slug: '1_w',
                      name: t('features:settings.account.pauseAccount.1_week'),
                    },
                    {
                      slug: '1_m',
                      name: t('features:settings.account.pauseAccount.1_month'),
                    },
                    {
                      slug: '3_m',
                      name: t(
                        'features:settings.account.pauseAccount.3_months'
                      ),
                    },
                    {
                      slug: '6_m',
                      name: t(
                        'features:settings.account.pauseAccount.6_months'
                      ),
                    },
                  ]}
                />
              </FormRow>
              <FormRow>
                <TextArea
                  placeholder={t('features:blockReportReason.writeReason')}
                  name="description"
                  label={t('features:blockReportReason.commentLabel')}
                ></TextArea>
              </FormRow>

              <div className={styles.customReasonBtns}>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  name="btn"
                  fullWidth
                  size="xl"
                >
                  {t('features:settings.account.pauseAccount.button')}
                </Button>
                <Button
                  onClick={() => {
                    hideModal();
                  }}
                  type="button"
                  name="btn-grey"
                  fullWidth
                  size="xl"
                >
                  {t('main:main.Cancel')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </RenderModal>
  );
};

export { CloseAccountModal, PauseAccountModal };
