import {useEffect, useState} from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { Trans, useTranslation } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';

import { Checkbox, FormRow, Input, Select } from 'components/forms';
import PhoneEdit from 'features/phoneEdit/PhoneEdit';
import Button from 'components/buttons/Button';
import { LoaderRelative } from 'components/layout/loader';
import {
  selectAccountInfo,
  getAccountInfo,
  setAccountInfo,
} from '../settingsSlice';

import { selectUserInfo, updateUser } from 'features/auth/authSlice';
import { useModal } from 'components/modal/useModal';
import { getNotifications } from 'features/notifications/notificationsSlice';
import ClosePauseAccount from './ClosePauseAccount';
import styles from '../settings.module.css';

const AccountEditView = () => {
  const accountInfo = useSelector(selectAccountInfo);
  const { payments } = useSelector(selectUserInfo);
  // const countries = useSelector(selectCountries);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const phoneEditModal = useModal();
  const location = useLocation();
    const [clickPhone, setClickPhone] = useState(
        location?.state?.openPhone
    );

    if(clickPhone === true)
    {
        phoneEditModal.showModal();
        setClickPhone(false);
    }

  useEffect(() => {
    dispatch(getAccountInfo());
  }, [dispatch]);


  const onUpdate = async (payload) => {
    await dispatch(setAccountInfo(payload))
      .then(unwrapResult)
      .then((result) => {
        if (result.status) {
          toast.success(t('features:settings.profile.edit.savedSuccessfully'));
          dispatch(updateUser());
          dispatch(getNotifications());
          dispatch(getAccountInfo());
        } else {
          toast.error(result.error.message);
        }
      });
  };

  if (_isEmpty(accountInfo)) {
    return <LoaderRelative />;
  }

  return (
    <>
      <Formik
        initialValues={{
          currency: payments.user_currency,
          ...accountInfo,
        }}
        onSubmit={async (values) => {
          await onUpdate(values);
        }}
      >
        {({ isSubmitting, handleSubmit, setFieldValue }) => (
          <Form>
            <FormRow>
              <Input label="Email" name="email" disabled />
            </FormRow>
            <FormRow
              onClick={() => {
                phoneEditModal.showModal();
              }}
              style={{ position: 'relative' }}
            >
              <Input
                value={
                  accountInfo.phone.last_digits
                    ? `*** *** **${accountInfo.phone.last_digits}`
                    : ''
                }
                label={t('features:phoneEdit.label')}
                name="phone"
                disabled
              />
              <strong
                style={{
                  position: 'absolute',
                  right: '20px',
                  top: '41px',
                  fontSize: '1.2rem',
                  color: 'var(--accentColor)',
                  cursor: 'pointer',
                }}
              >
                {t('features:phoneEdit.edit')}
              </strong>
            </FormRow>
            <FormRow>
              <Select
                label={t('features:settings.Language')}
                name="language_gui"
                options={[
                  {
                    slug: 'english',
                    name: t('features:settings.English'),
                  },
                  {
                    slug: 'german',
                    name: t('features:settings.German'),
                  },
                ]}
              />
            </FormRow>
            <FormRow>
              <Select
                label={t('features:settings.Currency')}
                name="currency"
                options={[
                  {
                    slug: 'EUR',
                    name: 'Euro (EUR)',
                  },
                  {
                    slug: 'USD',
                    name: 'US Dollar (USD)',
                  },
                  {
                    slug: 'GBP',
                    name: 'Pound Sterling (GBP)',
                  },
                ]}
              />
            </FormRow>
            <hr className={styles.hr} />
            <FormRow>
              <Checkbox name="open_profile" value={true}>
                {t('features:settings.profileVisibility')}
              </Checkbox>
              <p style={{ fontSize: '1.4rem', margin: '5px' }}>
                <Trans i18nKey={'features:settings.openProfile'}>
                  I hereby agree that my profile is visible and accessible to
                  all users of the Only VIP services. This agreement can be
                  revoked at any time. Further information can be found in our{' '}
                  <Link to="/legal/privacy">privacy statement</Link>{' '}
                </Trans>
                *
              </p>
            </FormRow>
            <hr className={styles.hr} />
            <FormRow>
              <Checkbox name="creator_dashboard" value={true}>
                {t('features:settings.statistics')}
              </Checkbox>
              <p style={{ fontSize: '1.4rem', margin: '5px' }}>
                {t('features:settings.statisticsDesc')}
              </p>
            </FormRow>
            <hr className={styles.hr} />
            <FormRow>
              <Checkbox name="auto_save_payments" value={true}>
                {t('features:settings.paymentMethod')}
              </Checkbox>
              <p style={{ fontSize: '1.4rem', margin: '5px' }}>
                {t('features:settings.paymentMethodAgreement')}
              </p>
            </FormRow>
            <hr className={styles.hr} />
            <FormRow>
              <Button
                size="xl"
                name="btn-gradient"
                type="submit"
                onClick={handleSubmit}
                loading={isSubmitting}
                disabled={isSubmitting}
                extraPadding
              >
                {t('main:main.Save')}
              </Button>
            </FormRow>
          </Form>
        )}
      </Formik>
      <ClosePauseAccount />
      <PhoneEdit modalContext={phoneEditModal} />
    </>
  );
};

export default AccountEditView;
