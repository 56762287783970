import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';
import {useHistory, useLocation} from 'react-router-dom';
import {PhotoSwipe} from 'react-photoswipe';
import {useTranslation} from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';

import {trackEvent} from 'helpers/tracking';
import {getImageSrc} from 'helpers/s3media';
//import Button from 'components/buttons/Button';
import ContentUpdateMessage from 'features/infoMessages/ContentUpdateMessage';
import SubscribeModal from 'features/subscriptions/subscribeModal/SubscribeModal';
import {useModal} from 'components/modal/useModal';
import {useWindowWidthLessThan} from 'hooks';
import SubHeader from 'components/layout/subHeader/SubHeader';
import {
    ProfileMetaInfo,
    LocationInfo,
    StatsInfo,
    OnlineStatus,
} from './profileMetaInfo';
//import ProfileImageSlider from './profileImageSlider';
import ProfileImagesDesk from './profileImagesDesk';
//import MobileStories from './mobileStories';
import {Avatar} from 'components/avatar';
import StoryAvatar from 'features/stories/StoryAvatar';
import {ProfileBadges} from 'components/layout/badges/Badges';
import StickyHeader from './StickyHeader';

import Description from './Description';
import {ProfileButtonNew, SubscribeButtonRound} from './Buttons';
import ProfileActions from '../profileActions';
import {CancelConnectModal, DisconnectModal, UnfollowModal} from '../profileActions/Modals';
import {
    followUser,
    selectFollowing,
    getUserProfile,
    updateTimestamp, selectConnected, connectionUser,
} from '../profileSlice';
import {
    // initChatTransaction,
    startChatTransaction, startTipTransactionModal,
} from 'features/transactions/transactionsSlice';
import {selectUserInfo} from 'features/auth/authSlice';
//import {IconArrowLeft} from 'assets/icons';
import styles from './withoutBannerProfile.module.css';
import {toast} from "react-toastify";

const WithoutBannerProfile = ({
                                  stories,
                                  profileUser,
                                  isMyProfile,
                                  publicModal,
                                  storyAction,
                              }) => {

    const [showHeader, toogleShowHeader] = useState(false);
    const [filesIndex, setFilesIndex] = useState(0);
    const [filesToggler, setFilesToggler] = useState(false);
    const [noAvatar, setNoAvatar] = useState(isEmpty(profileUser.avatar));
    const showFollowText = useSelector(selectFollowing);
    const showConnectionText = useSelector(selectConnected);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {uid, gender} = useSelector(selectUserInfo);
    const subscribeModal = useModal();
    const followModal = useModal();
    const disConnectionModal = useModal();
    const cancelConnectionModal = useModal();
    const {search, state} = useLocation();
    const isMobile = useWindowWidthLessThan(769);

    const chatRefID = !isEmpty(state) ? state.chatID : null;

    const isPublicProfile = typeof publicModal === 'function';
    const hasPublicLink = !isEmpty(profileUser.hash_id);
    const hasSubscriptions = profileUser.subscription.has_subscription;
    const hasSubscriptionsEnabled = profileUser.is_subscription_enabled;
    const hasSubscriptionPrice = profileUser.subscription.price.amount;

    const hasSeenAllStories = stories && stories.every((s) => s.seen === true);
    const showMobileStories = !isEmpty(stories); // && (!hasSeenAllStories || isMyProfile);

    const showEditButton = isMyProfile;
    const showShareButton = hasPublicLink && (isMyProfile || isPublicProfile);
    const showChatButton = !isMyProfile;
    const showTipButton = !isMyProfile;
    const showSubscribeButton =
        !isMyProfile &&
        !isPublicProfile &&
        hasSubscriptions &&
        hasSubscriptionsEnabled &&
        hasSubscriptionPrice;
    const showFollowButton =
        !showSubscribeButton && !isMyProfile && !isPublicProfile;
    //const connected = profileUser.connection;
    const showConnectButton = !isMyProfile && !isPublicProfile;
    const showMoreButton = false; //!isMobile && !isMyProfile;

    const wrapperClass = isMyProfile
        ? 'myProfile'
        : isPublicProfile
            ? 'publicProfile'
            : 'otherProfile';

    const topClass = cx(styles.profileTop, {
        // [styles.topPublic]: isPublicProfile,
        [styles.singleImg]: profileUser.images.length < 2,
        [styles.withStories]: showMobileStories,
    });

    useEffect(() => {
        const param = new URLSearchParams(search);
        const paramVal = param.get('subscribe');

        if (paramVal === 'true') {
            subscribeModal.showModal();
        }
    }, [search, subscribeModal]);

    const handleConnection = (connectionAction) => {

        //const {user1_id}  = profileUser.connection;

        const connectable = connectionAction === 1;
        const disConnectable = connectionAction === 3;
        const cancellable = connectionAction === 2;
        const confirmable = connectionAction === 4;
        //const cancellable = !connectable && !disConnectable && connectionAction === 2  && parseInt(uid) === parseInt(user1_id);
        //const confirmable = !connectable && !disConnectable && connectionAction === 2  && parseInt(uid) !== parseInt(user1_id);

        const actionText =
            connectable ? 'connect' :
            cancellable ? 'cancel' :
            confirmable ? 'confirm' :
            disConnectable  ? 'disconnect' :
            '';

        const successText =
            connectable ? t('features:profileInfo.successConnectRequest') :
            cancellable ? t('features:profileInfo.successCancelConnectRequest') :
            confirmable ? t('features:profileInfo.successConnection') :
            disConnectable ? t('features:profileInfo.successDisconnect') :
            '';

        trackEvent({
            category: 'profile pages',
            action: `${actionText} click`,
            label: gender,
        });
        if (showHeader) {
            window.scrollTo(0, 0);
            toogleShowHeader(false);
        }

        dispatch(
            connectionUser({user_id: profileUser.id, action: connectionAction})
        )
            .then(unwrapResult)
            .then((result) => {
                if (result.status) {
                    toast.success(successText);
                    dispatch(updateTimestamp());
                    dispatch(getUserProfile({user_id: profileUser.id}));
                }
            });
    };

    const handleFollow = () => {
        trackEvent({
            category: 'profile pages',
            action: `${profileUser.following ? 'unfollow' : 'follow'} click`,
            label: gender,
        });
        if (showHeader) {
            window.scrollTo(0, 0);
            toogleShowHeader(false);
        }
        dispatch(
            followUser({user_id: profileUser.id, following: profileUser.following})
        )
            .then(unwrapResult)
            .then((result) => {
                if (result.status) {
                    dispatch(updateTimestamp());
                    dispatch(getUserProfile({user_id: profileUser.id}));
                }
            });
    };

    const handleChat = () => {
        trackEvent({
            category: `profile pages${isPublicProfile ? ' - public' : ''}`,
            action: 'open chat click',
            label: gender,
        });
        if (isPublicProfile) {
            publicModal();
        } else if (profileUser.chat_open) {
            history.push(`/chat/${uid}-${profileUser.uid}-messaging`);
        } else {
            dispatch(startChatTransaction({actor: profileUser}));
        }
    };

    const handleTip = () => {
        trackEvent({
            category: `profile pages${isPublicProfile ? ' - public' : ''}`,
            action: 'open tip click',
            label: gender,
        });
        if (isPublicProfile) {
            publicModal();
        } else {
            dispatch(startTipTransactionModal({actor: profileUser}));
        }
    };

    const handleSubscribe = () => {
        trackEvent({
            category: 'profile pages',
            action: 'subscribe click',
            label: gender,
        });
        if (isPublicProfile) {
            publicModal();
        } else {
            subscribeModal.showModal();
        }
    };

    const onButtonsViewChange = (isInView) => {
        toogleShowHeader(!isInView);
    };

    const getImages = () => {
        let items = [];
        profileUser.images.forEach((file) => {
            const src = getImageSrc(file);
            items.push({
                html: `<div class="pswp__custom_item custom_item"><img src="${src}" alt="" /></div>`,
            });
        });
        return items;
    };

    const handleImageClick = (index) => {
        setFilesIndex(index);
        setFilesToggler(true);
    };

    const handleAvatarClick = () => {
        if (!noAvatar) {
            setFilesIndex(0);
            setFilesToggler(true);
        }
    };

    const handleStoryAvatarClick = () => {
        trackEvent({
            category: 'profile pages',
            action: 'stories click',
            label: gender,
        });
        storyAction();
    };

    const mainActions = {
        subscribe: {
            show: false,
            onClick: handleSubscribe,
        },
        follow: {
            show: showFollowButton,
            onClick: handleFollow,
            showModal: followModal.showModal,
            loading: !showFollowText,
        },
        connection: {
            show: showConnectButton,
            onClick: handleConnection,
            showModal: disConnectionModal.showModal,
            showCancelModal: cancelConnectionModal.showModal,
            loading: !showConnectionText,
            uid: uid,
        },
        chat: {
            show: showChatButton,
            onClick: handleChat,
        },
        tip: {
            show: showTipButton,
            onClick: handleTip,
        },
        edit: {
            show: showEditButton,
        },
        share: {
            show: showShareButton,
        },
        more: {
            show: showMoreButton,
        },
    };

    const mainActionWithSubscribe = {...mainActions};

    mainActionWithSubscribe.subscribe = {
        show: showSubscribeButton,
        onClick: handleSubscribe,
    }
    mainActionWithSubscribe.more = {
        show : true
    };

    return (
        <div className={cx(styles.wrapper, wrapperClass)}>
            {isMyProfile && !isPublicProfile && <ContentUpdateMessage/>}

            {/*{!isMobile && (*/}
            <SubHeader
                screenName={t('components:sidebarLabel.Profile')}
                backBtn
                special
                customBackBtnHandler={
                    chatRefID ? () => history.push(`/chat/${chatRefID}`) : null
                }
                className={styles.navDesk}
            />
            {/*)}*/}

            <div className={topClass} style={{marginBottom: "0px"}}>
                <div className={styles.profileTopLeft}>
                    {/*{isMobile ? (
                        <>
                            <div className={styles.backBtn}>
                                <Button
                                    onClick={() => {
                                        chatRefID
                                            ? history.push(`/chat/${chatRefID}`)
                                            : history.goBack();
                                    }}
                                    name="btn-bare"
                                    style={{ paddingLeft: '16px' }}
                                >
                                    <IconArrowLeft style={{ width: '25px', height: '16px' }} />
                                </Button>
                            </div>
                            <div className={styles.blockReport}>
                                {!isMyProfile && !isPublicProfile && !showHeader && (
                                    <ProfileActions profileUser={profileUser} isMobile />
                                )}
                            </div>
                            <ProfileImageSlider
                                images={profileUser.images}
                                gender={profileUser.gender}
                            />
                            {showMobileStories && (
                                <MobileStories
                                    stories={stories}
                                    onClick={() => handleStoryAvatarClick()}
                                    allSeen={hasSeenAllStories}
                                />
                            )}
                        </>
                    ) : */}
                    {stories ? (
                        <StoryAvatar
                            isProfile
                            percentage={hasSeenAllStories ? 0 : 100}
                            user={profileUser}
                            thumbSize={isMobile ? 100 : 180}
                            onClick={() => handleStoryAvatarClick()}
                        />
                    ) : (
                        <Avatar
                            circle
                            size={isMobile ? 100 : 180}
                            online={profileUser.online}
                            image={profileUser.avatar}
                            gender={profileUser.gender}
                            onClick={() => handleAvatarClick()}
                            noAvatarCallBack={() => {
                                setNoAvatar(true);
                            }}
                        />
                    )}
                </div>

                <div className={styles.profileTopRight}>
                    <div className={styles.info}>
                        <div className={styles.infoUser}>
                            {/*{!isMobile && (*/}
                            {/*<ProfileBadges
                                    withText
                                    verified={!profileUser.verification_required}
                                    dating={profileUser.dating_enabled}
                                    phone={profileUser.verified_phone}
                                    className={styles.infoBadges}
                                />*/}
                            {/*)}*/}
                            <div className={styles.infoName}>
                                <span
                                    /*style={{maxWidth: profileUser.name.length > 13 ? '80%' : '100%'}}*/
                                >{profileUser.name}</span>
                                {profileUser.age && (
                                    <span className={styles.infoAge}>{profileUser.age}</span>
                                )}
                                <ProfileBadges
                                    verified={!profileUser.verification_required}
                                    dating={profileUser.dating_enabled}
                                    phone={profileUser.verified_phone}
                                    className={styles.infoBadges}
                                    classNameIcon={styles.colorGrey}
                                />
                            </div>
                            {/*{isMobile && (
                                <ProfileBadges
                                    verified={!profileUser.verification_required}
                                    dating={profileUser.dating_enabled}
                                    phone={profileUser.verified_phone}
                                    className={styles.infoBadges}
                                />
                            )}*/}
                        </div>
                        {isMobile && (
                            <LocationInfo
                                customClass={styles.locationInfoMobile}
                                {...profileUser}
                            />
                        )}
                        {isMobile && (
                            <div className={styles.statsInfoMobile}>
                                <OnlineStatus isOnline={profileUser.online}/>
                                <StatsInfo stats={profileUser.stats}/>
                            </div>
                        )}
                        <ProfileMetaInfo
                            customClass={styles.metaInfoDesktop}
                            {...profileUser}
                        />
                    </div>
                    {/*<ProfileButtons
                        mainActions={mainActions}
                        profileUser={profileUser}
                        inViewAction={onButtonsViewChange}
                        isPublic={isPublicProfile}
                    />*/}
                    {!isMobile && (
                        <div className={topClass} style={{justifyContent: "space-between", margin: '20px 15px 0px 0px'}}>
                            <ProfileButtonNew
                                mainActions={mainActions}
                                profileUser={profileUser}
                                inViewAction={onButtonsViewChange}
                            />

                            {uid && (
                            <button className={cx(styles.buttonMore, styles.tooltip)}>
                                <span className={styles.tooltiptext}>{t('features:profileInfo.more', 'More')}</span>
                                <ProfileActions profileUser={profileUser} pinkTrigger/>
                            </button>
                            )}
                        </div>
                    )}
                    {/*{isMobile && (
                        <div className={styles.statsInfoMobile}>
                            <OnlineStatus isOnline={profileUser.online} />
                            <StatsInfo stats={profileUser.stats} />
                        </div>
                    )}*/}
                </div>

            </div>
            {isMobile && (
                <div className={topClass} style={{justifyContent: "space-between", paddingLeft: '15px'}}>
                    <ProfileButtonNew
                        mainActions={mainActions}
                        profileUser={profileUser}
                        inViewAction={onButtonsViewChange}
                    />

                    <button style={{margin: '20px 15px 0px 0px'}} className={styles.buttonMore}>
                        <ProfileActions profileUser={profileUser} isMobile pinkTrigger/>
                    </button>
                </div>
            )}
            <hr className={styles.hr}/>
            {profileUser.about.length > 0 && (
                <>
                    {/*{isMobile ? (
                        <hr className={styles.hr} />
                    ) : (
                        <span className={styles.heading}>
              {t('features:profileInfo.about')}
            </span>
                    )}*/}
                    <Description description={profileUser.about} limit={isMobile ? 50 : 100}/>
                </>
            )}

            {profileUser.images.length > 1
            /*&& !isMobile*/
            && (
                <>
                    {/*<span className={styles.heading}>
            {t('features:profileInfo.profileImages')}
              <span className={styles.headingCount}>
              {profileUser.images.length}
            </span>
          </span>*/}
                    <ProfileImagesDesk
                        images={profileUser.images}
                        handleImageClick={handleImageClick}
                        stories={stories}
                        handleStoryAvatarClick={handleStoryAvatarClick}
                        hasSeenAllStories={hasSeenAllStories}
                        profileUser={profileUser}
                    />
                </>
            )}

            {/*{!isMobile && (*/}
            <PhotoSwipe
                options={{
                    bgOpacity: 0.5,
                    index: filesIndex,
                    closeElClasses: [
                        'item',
                        'custom_item',
                        'caption',
                        'zoom-wrap',
                        'ui',
                        'top-bar',
                    ],
                    closeOnScroll: false,
                    fullscreenEl: false,
                    zoomEl: true,
                    shareEl: false,
                    counterEl: false,
                    arrowEl: true,
                    preloaderEl: true,
                    history: false,
                }}
                isOpen={filesToggler}
                items={getImages()}
                onClose={() => setFilesToggler(false)}
            />
            {/*)}*/}

            <SubscribeButtonRound
                mainActions={mainActionWithSubscribe}
                profileUser={profileUser}
                onHeader
            />
            <SubscribeModal modalContext={subscribeModal} profile={profileUser}/>
            <UnfollowModal
                modalContext={followModal}
                onConfirmUnfollow={handleFollow}
                userName={profileUser.name}
            />
            <DisconnectModal
                modalContext={disConnectionModal}
                onConfirmDisconnect={handleConnection}
                userName={profileUser.name}
            />
            <CancelConnectModal
                modalContext={cancelConnectionModal}
                onConfirmDisconnect={handleConnection}
                userName={profileUser.name}
            />

            {showHeader && (
                <StickyHeader
                    isMyProfile={isMyProfile}
                    mainActions={mainActionWithSubscribe}
                    profileUser={profileUser}
                    isPublic={isPublicProfile}
                    isMobile={isMobile}
                    customBackBtnHandler={
                        chatRefID ? () => history.push(`/chat/${chatRefID}`) : null
                    }
                >
                    <ProfileButtonNew
                        mainActions={mainActionWithSubscribe}
                        profileUser={profileUser}
                        onHeader
                    />
                </StickyHeader>
            )}
        </div>
    );
};

export default WithoutBannerProfile;
