import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { trackEvent } from 'helpers/tracking';
import { shareLink } from 'helpers/shareLink';
import { selectUserGender } from 'features/auth/authSlice';
import { IconBlock, IconReport, IconShareNew } from 'assets/icons';
import styles from './profileActions.module.css';

const ContextMenu = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const userGender = useSelector(selectUserGender);

  const handleShare = () => {
    if (!props.profileUser.hash_id) {
      return;
    }
    trackEvent({
      category: 'profile pages',
      action: 'share click',
      label: userGender,
    });
    shareLink({
      title: props.profileUser.name,
      path: props.profileUser.hash_id,
      success: t('features:profileInfo.copied'),
    });
  };

  if (!props.show) return null;

  return (
    <div ref={ref} className={styles.menu}>
      {!props.isChat && (
        <span
          onClick={() => {
            handleShare();
            props.onCloseMenu();
          }}
          className={styles.btn}
        >
          <IconShareNew className={styles.icoShare} />
          {t('features:profileInfo.shareProfile')}
        </span>
      )}
      <span
        onClick={() => {
          props.modal.showModal();
          props.onCloseMenu();
        }}
        className={styles.btn}
      >
        <IconBlock className={styles.ico} /> {t('features:blockReport.Block')}
      </span>
      <span
        onClick={() => {
          props.reportModal.showModal();
          props.onCloseMenu();
        }}
        className={styles.btn}
      >
        <IconReport className={styles.ico} /> {t('features:blockReport.Report')}
      </span>
    </div>
  );
});

export default ContextMenu;
