import {useRef, useState} from 'react';
//import { unwrapResult } from '@reduxjs/toolkit';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
    getGuestEpisodes,
    //resetEpisodesList,
} from '../episodes/episodesSlice';
/*import { selectFilters, applyFilters } from 'app/miscSlice';
import { resetSearch } from 'features/userList/search/searchSlice';
import { resetUserList } from 'features/userList/userListSlice';
import { resetGlobalFeed } from 'features/feed/feedSlice';
import { resetStories } from 'features/stories/storiesSlice';*/
import {selectAuthenticated, selectUserInfo} from 'features/auth/authSlice';

import { IconEdit } from 'assets/icons';
import Button from 'components/buttons/Button';
import CountryCitySelect from 'components/forms/CountryCitySelect';
import { useModal } from 'components/modal/useModal';
//import { FiltersModal } from 'features/userList/navFilters';
import styles from './episodesListHeader.module.css';
import {getClubs} from "../clubs/clubsSlice";

const LocationSelectModal = ({ modal, user, onChange }) => {
  const { t } = useTranslation();
  const formRef = useRef();

  const saveForm = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <modal.RenderModal
      title={t('features:episodeList.changeCountry', 'Change Country')}
      footer={
        <>
          <Button
            name="btn-grey"
            style={{ width: '48%' }}
            onClick={modal.hideModal}
            type="button"
          >
            {t('main:main.Cancel', 'Cancel')}
          </Button>
          <Button
            name="btn"
            style={{ width: '48%' }}
            onClick={saveForm}
            type="button"
          >
            {t('main:main.Submit', 'Submit')}
          </Button>
        </>
      }
    >
      <div className={styles.formWrapper}>
        <Formik
          innerRef={formRef}
          initialValues={{
            city: user.town_name,
            city_slug: user.town,
            // city_id: null,
            country: user.country,
            country_name: user.country_name,
          }}
          onSubmit={(data) => {
            onChange(data);
            modal.hideModal();
          }}
        >
          {() => {
            return (
              <Form>
                <CountryCitySelect />
              </Form>
            );
          }}
        </Formik>
      </div>
    </modal.RenderModal>
  );
};

const EpisodesListHeader = () => {
  const user = useSelector(selectUserInfo);
  //const filters = useSelector(selectFilters);
  const locationModal = useModal();
  //const genderModal = useModal();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isAuthenticated = useSelector(selectAuthenticated);

  //const [selectedCity, SetSelectedCity] = useState(user.town_name ? user.town_name :'');
  const [selectedCountry, SetSelectedCountry] = useState(user.country_name ? user.country_name :'');

  const onChangeCity = async (city) => {
    if(isAuthenticated) {
        //SetSelectedCity(city.town_name);
        SetSelectedCountry(city.country_name);
        //console.log(user.country_name);
        /*const res = await dispatch(changeCity({ city: city.city_slug }));
        await unwrapResult(res);*/
        /*const res = await dispatch(changeCountry({ country: city.country }));
        await unwrapResult(res);*/
        //dispatch(updateUser());
        //dispatch(getEpisodes({country_code:city.country}));
        //console.log(city);

    } else {
        //SetSelectedCity(city.city);
        SetSelectedCountry(city.country_name);

        //console.log(city);

        dispatch(getGuestEpisodes({country_code:city.country}));
    }
     //console.log(city.country);
      dispatch(getClubs({country_code:city.country}));
  };

  /*const resetLists = () => {
    dispatch(resetSearch());
    dispatch(resetUserList());
    dispatch(resetEpisodesList());
    dispatch(resetGlobalFeed());
    dispatch(resetStories());
  };*/

  /*const onChangeGender = ({ gender }) => {
    dispatch(applyFilters({ gender: gender }));
    resetLists();
    window.localStorage.setItem('lookingFor', gender);
  };*/

    /*const onFilterSubmit = (values) => {
        dispatch(applyFilters(values));
        resetLists();
        window.localStorage.setItem('lookingFor', values.gender);
    };*/

  return (
    <div className={styles.container}>
      <span className={styles.location} onClick={locationModal.showModal}>
        <span className={styles.locationName}>
          {selectedCountry ?
              <>
                <strong>{t('features:profileFields.country', 'Country')}: </strong>
                {selectedCountry}
              </>
              : t('features:profileFields.search', 'Search') }
        </span>
        <IconEdit className={styles.locationIcon} />
      </span>

      {/*<Button
        className={styles.filterIconBtn}
        name="btn-bare"
        onClick={() => genderModal.showModal()}
      >
        <IconFilter style={{ width: '20px' }} />
      </Button>*/}

      <LocationSelectModal
        user={user}
        modal={locationModal}
        onChange={onChangeCity}
      />

      {/*<FiltersModal
        filters={filters}
        hideLocation
        showEpisodeFilter={true}
        hideCategories
        //onChange={onChangeGender}
        onChange={onFilterSubmit}
        modalContext={genderModal}
      />*/}
    </div>
  );
};

export default EpisodesListHeader;
