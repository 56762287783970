import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';

const usePlayer = ({ file, src, poster, controls, autoplay }) => {
  const videoRef = useRef(null);
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    const aspectRatio = (file) => {
      const { width, height } = file;
      if (!width || !height) {
        return '16:9';
      }
      const gcd = (a, b) => {
        return b ? gcd(b, a % b) : a;
      };
      const divisor = gcd(width, height);
      const ratio = `${width / divisor}:${height / divisor}`;

      return ratio.toString();
    };

    const options = {
      fill: true,
      fluid: true,
      playsinline: true,
      preload: 'none',
      aspectRatio: aspectRatio(file),
      // width: file.width,
      // height: file.height,
      html5: {
        hls: {
          enableLowInitialPlaylist: true,
          smoothQualityChange: true,
          overrideNative: false,
        },
      },
    };

    const vjsPlayer = videojs(videoRef.current, {
      ...options,
      controls,
      autoplay,
      sources: [src],
    });
    setPlayer(vjsPlayer);
  }, [file, src, poster, controls, autoplay]);

  useEffect(() => {
    if (player !== null) {
      player.src({ src });
    }
    return () => {
      if (player !== null) {
        player.dispose();
      }
    };
  }, [src, player]);

  return videoRef;
};

const VideoPlayer = ({ file, src, poster, controls, autoplay }) => {
  const playerRef = usePlayer({
    file,
    src,
    poster,
    controls,
    autoplay,
  });

  return (
    <div data-vjs-player>
      <video ref={playerRef} className="video-js" poster={poster} />
    </div>
  );
};

VideoPlayer.propTypes = {
  file: PropTypes.object.isRequired,
  src: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
  controls: PropTypes.bool,
  autoplay: PropTypes.bool,
};

VideoPlayer.defaultProps = {
  controls: true,
  autoplay: false,
};

export default VideoPlayer;
