import React from 'react';
import { useSelector } from 'react-redux';

import Layout from '../components/layout/layouts/Layout';
import MyProfileView from '../features/user/MyProfileView';
import { selectUserInfo } from '../features/auth/authSlice';

const MyProfile = (props) => {
  const user = useSelector(selectUserInfo);
  return (
    <Layout>
      <MyProfileView user={user} />
    </Layout>
  );
};

export default MyProfile;
