import React from 'react';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormFieldError, FormRow } from '../../components/forms';
import formStyles from '../../components/forms/forms.module.css';
import styles from './voucher.module.css';
import Button from '../../components/buttons/Button';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/modal/Modal';
// import api from '../../app/api';
import * as Yup from 'yup';
import { updateUser } from '../auth/authSlice';
import { redeemVoucher, selectVoucher, toggleVoucher } from './voucherSlice';
import { IconCheck, IconCross } from '../../assets/icons';
import { Trans } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';

export const VoucherError = ({ code }) => {
  const { t } = useTranslation();

  switch (code) {
    case 1201:
      return t('features:voucher.errorInvalidCode', 'VOUCHER INVALID');
    case 1202:
      return t('features:voucher.errorInvalidUser', 'USER INVALID');
    case 1203:
      return t('features:voucher.errorExpired', 'VOUCHER EXPIRED');
    case 1204:
      return t('features:voucher.errorLIMIT', 'VOUCHER LIMIT REACHED');
    default:
      return t('features:voucher.errorInvalidCode', 'VOUCHER INVALID');
  }
};

const RedeemVoucher = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showModal, loading, error, success } = useSelector(selectVoucher);

  const redeem = async ({ code }) => {
    try {
      const res = await dispatch(redeemVoucher(code));
      const unwrapped = unwrapResult(res);
      if (unwrapped.data.ok) {
        dispatch(updateUser());
      } else throw new Error('Redeem failed');
    } catch (error) {
      console.log(error);
    }
  };

  const closeModal = () => {
    dispatch(toggleVoucher(false));
  };

  if (!showModal) return null;

  return (
    <Modal
      buttonClose={true}
      closeModal={closeModal}
      title={!success && !error ? t('features:voucher.voucher') : null}
    >
      <div className={styles.container}>
        {success && (
          <div>
            <span
              style={{ background: '#6094A8' }}
              className={styles.iconWrapper}
            >
              <IconCheck style={{ height: '40px' }} />
            </span>
            <div style={{ padding: '10px 5px' }}>
              <Trans i18nKey={'features:voucher.redeemSuccess'}>
                <p style={{ fontWeight: 'bold' }}>
                  You have activated your voucher.
                </p>
                <p>Use your coins to unlock chats, photos and videos.</p>
              </Trans>
            </div>
          </div>
        )}
        {error && (
          <div>
            <span className={styles.iconWrapper}>
              <IconCross style={{ height: '40px', marrginTop: '10px' }} />
            </span>
            <div style={{ padding: '10px 5px', color: 'red' }}>
              <VoucherError code={error} />
            </div>
          </div>
        )}

        {error || success ? null : (
          <>
            <p style={{ margin: '-10px 10px 10px 5px', textAlign: 'left' }}>
              {t('features:voucher.voucherHeading')}
            </p>
            <Formik
              validationSchema={Yup.object().shape({
                code: Yup.string()
                  .min(3, t('components:validation.tooShort', 'Too Short!'))
                  .max(20, t('components:validation.Too Long!', 'Too Long!'))
                  .required(t('components:validation.required', 'Required')),
              })}
              initialValues={{ code: '' }}
              onSubmit={async (val) => {
                await redeem(val);
              }}
            >
              {({ isSubmitting, submitForm }) => (
                <Form>
                  <FormRow>
                    <label
                      className={`${formStyles.formLabel} ${formStyles.required}`}
                      htmlFor="code"
                    >
                      {t('features:voucher.voucherCode', 'Voucher code')}
                    </label>
                    <span className={formStyles.errorMsg}>
                      <FormFieldError fieldName="code" />
                    </span>
                    <Field
                      className={formStyles.input}
                      placeholder={t('features:voucher.placeholder')}
                      type="text"
                      name="code"
                    />
                  </FormRow>
                  <div style={{ textAlign: 'right', marginTop: '20px' }}>
                    <Button
                      disabled={isSubmitting || loading}
                      loading={loading}
                      type="submit"
                    >
                      {t('features:voucher.activate')}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
    </Modal>
  );
};

export default RedeemVoucher;
