import React, { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PhotoSwipe } from 'react-photoswipe';

import { LoaderRelative } from 'components/layout/loader';
import LanguagesSelect from 'components/forms/LanguagesSelect';
import {
  FormRow,
  Input,
  TextArea,
  Select,
  FormFieldError,
} from 'components/forms';
import Button from 'components/buttons/Button';
import RedeemVoucher from 'features/vouchers/RedeemVoucher';
import CountryCitySelect from 'components/forms/CountryCitySelect';
import { useModal } from 'components/modal/useModal';
import {
    selectProfileInfo,
    getProfileInfo,
    setProfileInfo,
    cleanProfileInfo, removeProfileHeader,
} from '../settingsSlice';
import { resetSearch } from 'features/userList/search/searchSlice';
import { resetEpisodesList } from 'features/dating/episodes/episodesSlice';
import { resetUserList } from 'features/userList/userListSlice';
import { resetGlobalFeed } from 'features/feed/feedSlice';
import { resetStories } from 'features/stories/storiesSlice';
import { getTopCreators } from 'features/home/topCreators/topCreatorsSlice';
import { selectUserInfo, updateUser } from 'features/auth/authSlice';
import { toggleVoucher } from 'features/vouchers/voucherSlice';
import { getAvatarSrc, getImageSrc } from 'helpers/s3media';
import { IconAccountEdit, IconEdit } from 'assets/icons';
import styles from './profileEdit.module.css';
import {ProfileHeader} from "../../../components/profileheader";

const ProfileEditView = ({ isBlockScreen }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserInfo);
  const profileInfo = useSelector(selectProfileInfo);
  const history = useHistory();
  const location = useLocation();
  const infoModal = useModal(isBlockScreen);
  const { t } = useTranslation();

  const isFirstLogin = location.state && location.state.isFirstLogin; //deprecated

  const validationSchema = Yup.object().shape({
    full_name: Yup.string()
      .min(5, t('features:settings.profile.edit.tooShort'))
      .max(20, t('features:settings.profile.edit.tooLong'))
      .required(t('components:validation.required')),
    about: Yup.string().min(
      5,
      t('features:settings.profile.edit.aboutTooShort')
    ),
    age: Yup.number().test(
      'is18-100',
      t('components:validation.mustBeAdult'),
      (val) => {
        if (val === '' || val === undefined) return true;
        return Number(val) >= 18 && Number(val) < 100;
      }
    ),
    city: Yup.string().required(t('components:validation.required')),
    country: Yup.string().required(t('components:validation.required')),
    //lookingFor: Yup.string().required(t('components:validation.required')),
    //dating_enabled: Yup.string().required(t('components:validation.required')),
    languages: Yup.string(),
  });

  useEffect(() => {
    dispatch(getProfileInfo());
    return () => {
      dispatch(cleanProfileInfo());
    };
  }, [dispatch]);

  const updateUserInfo = () => {
    dispatch(getProfileInfo());
    dispatch(updateUser());
  };

  const resetLists = () => {
    dispatch(resetSearch());
    dispatch(resetUserList());
    dispatch(resetEpisodesList());
    dispatch(resetGlobalFeed());
    dispatch(resetStories());
  };

  const parseLanguages = (languages) => {
    let parsedValues = [];
    if (!isEmpty(languages)) {
      languages.map((language) => {
        return parsedValues.push(language.slug);
      });
    }
    return parsedValues.join();
  };

  const handleSubmit = async (values) => {
    let parsedValues = {
      full_name: values.full_name,
      about: values.about,
      age: values.age,
      town: values.city,
      // city_id: values.city_id,
      country_code: values.country.toLowerCase(),
      height: values.height,
      smoker: values.smoker,
      body_type: values.body_type,
      languages: values.languages,
      occupation: values.occupation,
      hair_color: values.hair_color,
      hair_length: values.hair_length,
      //looking_for: values.lookingFor,
      //dating_enabled: values.dating_enabled === 'true',
    };
    await dispatch(setProfileInfo(parsedValues))
      .then(unwrapResult)
      .then((result) => {
        if (result.status) {
          toast.success(t('features:settings.profile.edit.savedSuccessfully'));
          updateUserInfo();
          resetLists();
          dispatch(getTopCreators({ gender: values.lookingFor }));
        } else {
          toast.error(result.error.message);
        }
      });
    window.localStorage.setItem('lookingFor', values.lookingFor);
  };

  if (isEmpty(profileInfo)) {
    return <LoaderRelative />;
  }

  const { city, country } = profileInfo;
  const city_name = typeof city === 'object' ? city.name : city;

  const VoucherInfo = () => {
    return (
      <div className={styles.voucherInfo}>
        <p className={styles.voucherText}>
          {t('features:voucher.voucherHeading')}
        </p>
        <Button size="s" onClick={() => dispatch(toggleVoucher(true))}>
          {t('features:voucher.activate')}
        </Button>
        <RedeemVoucher />
      </div>
    );
  };

  const ProfileMedia = () => {
    const [filesIndex, setFilesIndex] = useState(0);
    const [toggler, setToggler] = useState(false);

    const getImages = () => {
      let items = [];
      user.images.forEach((file) => {
        const src = getImageSrc({
          public_id: file.public_id,
          format: file.format,
        });
        items.push({
          html: `<div class="pswp__custom_item custom_item"><img src="${src}" alt="" /></div>`,
        });
      });
      return items;
    };

    const handleImageClick = (index) => {
      setFilesIndex(index);
      setToggler(true);
    };

    return (
      <div className={styles.images}>
        {isEmpty(user.images) ? (
          <div
            onClick={() => history.push('/settings/profile/images')}
            className={styles.slideMain}
            style={{
              backgroundImage: `url(${getAvatarSrc(null, 110, user.gender)}`,
            }}
          ></div>
        ) : (
          <Swiper
            spaceBetween={5}
            resistance={false}
            slidesPerView={'auto'}
            freeMode
          >
            {user.images.map((image, i) => {
              const url = getAvatarSrc(
                {
                  version: image.version,
                  public_id: image.public_id,
                  format: image.format,
                },
                220
              );
              return (
                <SwiperSlide key={i} style={{ width: '100px' }}>
                  <div
                    //onClick={() => history.push('/settings/profile/profile-images')}
                    onClick={() => handleImageClick(i)}
                    className={`${styles.slideInner} ${
                      i === 0 ? styles.slideMain : null
                    }`}
                    style={{
                      backgroundImage: `url(${url})`,
                    }}
                  ></div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}

        <PhotoSwipe
          options={{
            bgOpacity: 0.5,
            index: filesIndex,
            closeElClasses: [
              'item',
              'custom_item',
              'caption',
              'zoom-wrap',
              'ui',
              'top-bar',
            ],
            closeOnScroll: false,
            fullscreenEl: false,
            zoomEl: true,
            shareEl: false,
            counterEl: false,
            arrowEl: true,
            preloaderEl: true,
            history: false,
          }}
          isOpen={toggler}
          items={getImages()}
          onClose={() => setToggler(false)}
        />
        <span
          onClick={() => history.push('/settings/profile/images')}
          className={styles.imagesLink}
        >
          <IconEdit width="13" />

          {t('features:settings.profile.edit.editImages')}
        </span>
      </div>
    );
  };

    const removeImage = () => {

        dispatch(removeProfileHeader({public_id: user.banner.public_id}))
            .then(unwrapResult)
            .then((res) => {
                if (!res.status) throw new Error(res.error);
                toast.success(t('features:banner.remove.success'));
                dispatch(updateUser());
            })
            .catch((e) => {
                dispatch(updateUser());
                //setError(true);
            });
    };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        full_name: profileInfo.full_name,
        about: profileInfo.about,
        age: profileInfo.age || '',
        city: city_name || '',
        // city_id: city ? city.id : 0,
        country: country ? country.iso_code : '',
        country_name: country ? country.name : '',
        height: profileInfo.height || '',
        smoker: profileInfo.smoker,
        body_type: profileInfo.body_type,
        hair_color: profileInfo.hair_color,
        hair_length: profileInfo.hair_length,
        languages: parseLanguages(profileInfo.languages),
        occupation: profileInfo.occupation,
        //lookingFor: profileInfo.looking_for,
        //dating_enabled: profileInfo.dating_enabled,
      }}
      onSubmit={async (values) => {
        await handleSubmit(values);
      }}
    >
      {({
        isSubmitting,
        errors,
        submitCount,
        handleSubmit,
        setFieldValue,
        values,
      }) => {
        return (
          <>
            {(isBlockScreen || isFirstLogin) && <VoucherInfo />}

            {/* <h4>
                {t('features:settings.profile.edit.headingInfo', 'Profile Information')}
              </h4> */}
            <Form style={{ marginLeft: '-5px', paddingTop: '10px' }}>
              <FormRow>
                <Input
                  label={t('features:settings.profile.edit.fullName')}
                  name="full_name"
                  required
                />
              </FormRow>

                <hr className={styles.hr} />
                <label className={styles.labelCustom}>
                    {t('features:settings.profile.edit.banner')}
                </label>
                <div>
                    <ProfileHeader
                        image={user.banner} removeImage={removeImage}
                    />
                </div>

                <label className={styles.labelCustom}>
                    {t('features:settings.profile.edit.avatar')}
                </label>
                <ProfileMedia />
                <hr className={styles.hr} />

              <FormRow>
                <CountryCitySelect
                  withLabel
                  required
                  isDatingUser={country.dating}
                />
                <FormFieldError fieldName="city" />
              </FormRow>
              {/*<FormRow>
                <Select
                  required
                  label={t(`features:eventRequest.whatPreference`)}
                  name="lookingFor"
                  options={[
                    {
                      slug: 1,
                      name:
                        t('features:auth.register.man') +
                        ' & ' +
                        t('features:auth.register.woman'),
                    },
                    { slug: 2, name: t('features:auth.register.man') },
                    {
                      slug: 3,
                      name: t('features:auth.register.woman'),
                    },
                  ]}
                />
              </FormRow>
              <FormRow>
                <Select
                  required
                  label={t(`features:eventRequest.includeDating`)}
                  name="dating_enabled"
                  options={[
                    { slug: true, name: t('main:main.yes') },
                    { slug: false, name: t('main:main.no') },
                  ]}
                />
              </FormRow>*/}
              <FormRow>
                <TextArea label="Bio" name="about" withCounter />
              </FormRow>
              <FormRow>
                <LanguagesSelect
                  withLabel
                  options={profileInfo.options.language_list}
                  initOptions={profileInfo.languages}
                />
                <FormFieldError fieldName="languages" />
              </FormRow>
              <FormRow>
                <Input
                  label={t('features:settings.profile.edit.Age')}
                  name="age"
                  type="number"
                  maxLength={2}
                />
                {values.age && (
                  <Button
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: '28px',
                      fontSize: '12px',
                      color: 'var(--accentColor)',
                    }}
                    type="button"
                    name="btn-bare"
                    onClick={() => setFieldValue('age', '')}
                  >
                    {t('features:settings.profile.edit.hideAge')}
                  </Button>
                )}
              </FormRow>
              <FormRow>
                <Input
                  label={t('features:settings.profile.edit.heightCm')}
                  name="height"
                  type="number"
                />
              </FormRow>
              <FormRow>
                <Select
                  label={t('features:settings.profile.edit.Smoker')}
                  name="smoker"
                  options={profileInfo.options.smoker_list}
                />
              </FormRow>
              <FormRow>
                <Select
                  label={t('features:settings.profile.edit.Profession')}
                  name="occupation"
                  options={profileInfo.options.occupation_list}
                />
              </FormRow>
              <FormRow>
                <Select
                  label={t('features:settings.profile.edit.bodyType')}
                  name="body_type"
                  options={profileInfo.options.body_type_list}
                />
              </FormRow>
              <FormRow>
                <Select
                  label={t('features:settings.profile.edit.hairColor')}
                  name="hair_color"
                  options={profileInfo.options.hair_color_list}
                />
              </FormRow>
              <FormRow>
                <Select
                  label={t('features:settings.profile.edit.hairLength')}
                  name="hair_length"
                  options={profileInfo.options.hair_length_list}
                />
              </FormRow>
              <Button
                size="xl"
                name="btn-gradient"
                type="submit"
                context="form"
                onClick={handleSubmit}
                loading={isSubmitting}
                disabled={isSubmitting || !isEmpty(errors)}
              >
                {t('main:main.Submit')}
              </Button>
            </Form>
            {isBlockScreen && (
              <infoModal.RenderModal
                customStyles={{
                  margin: 0,
                }}
                buttonClose={true}
              >
                <div style={{ textAlign: 'center' }}>
                  <IconAccountEdit
                    style={{ width: '40px', fill: 'var(--accentColor)' }}
                  />
                  <h1>{t('features:blockScreen.WelcomeToOnlyVIP')}</h1>
                  <p>{t('features:blockScreen.description')}</p>
                  <Button
                    onClick={() => infoModal.hideModal()}
                    style={{ marginTop: '30px' }}
                    fullWidth
                  >
                    OK
                  </Button>
                </div>
              </infoModal.RenderModal>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default ProfileEditView;
