import { formatDistanceToNow, formatRelative, isToday } from 'date-fns';
import { format, toDate } from 'date-fns-tz';
import Timer from 'components/timer/Timer';

export const dateTime = (episode, lang, noTZ) => {
  const startDate = new Date(episode.start_time.replace(' ', 'T'));
  const timeZone = episode.time_zone;
  const tz = format(startDate, 'zzz', {
    timeZone: timeZone,
    locale: lang,
  });
  const day = formatRelative(startDate, new Date(), {
    locale: lang,
  });

  return `${day} ${!noTZ ? `(${tz})` : ''}`;
};

export const monthDateFix = (start_time, lang) => {
  const date = new Date(start_time.replace(' ', 'T'));
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate();
  return `${month} ${day}`;
};

export const monthDateTimeFix = (start_time, lang) => {
  const date = new Date(start_time.replace(' ', 'T'));
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate();
  const hours = date.getHours() % 12 || 12;
  const minutes = date.getMinutes();
  const amPm = date.getHours() >= 12 ? 'pm' : 'am';

  return `${month} ${day} ${hours}:${minutes < 10 ? '0' : ''}${minutes} ${amPm}`;
};

export const monthDateTime = (episode, lang) => {
  const date = new Date(episode.start_time.replace(' ', 'T'));
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate();
  const hours = date.getHours() % 12 || 12;
  const minutes = date.getMinutes();
  const amPm = date.getHours() >= 12 ? 'pm' : 'am';

  return `${month} ${day} ${hours}:${minutes < 10 ? '0' : ''}${minutes} ${amPm}`;
};

export const dateTimeLeft = (episode, lang, addHours) => {
  const startDate = toDate(episode.start_time.replace(' ', 'T'), {
    timeZone: episode.time_zone,
  });
  if (isToday(startDate)) {
    return (
      <Timer startTime={startDate} showSeconds={false} addHours={addHours} />
    );
  }
  return formatDistanceToNow(startDate, { locale: lang });
};

export const genderNumToString = (num) => {
  switch (num) {
    case 1:
    default:
      return null;
    case 2:
      return 'male';
    case 3:
      return 'female';
  }
};

export const preferenceAdapterIn = (num) => {
  switch (num) {
    case 1:
      return ['male', 'female'];
    case 2:
      return ['male'];
    case 3:
      return ['female'];
    default:
      return [];
  }
};

export const preferenceAdapterOut = (preference) => {
  const hasMale = preference.includes('male');
  const hasFemale = preference.includes('female');

  if (hasMale && hasFemale) return 1;
  if (hasMale) return 2;
  if (hasFemale) return 3;

  return null;
};

export const validateDateRequest = (user, req) => {
  if (req.city !== user.town && req.city !== user.town_name) {
    return false;
  }
  if (user.looking_for === 2 && req.gender === 'female') {
    return false;
  }
  if (user.looking_for === 3 && req.gender === 'male') {
    return false;
  }
  if (user.gender === 'male' && req.looking_for === 3) {
    return false;
  }
  if (user.gender === 'female' && req.looking_for === 2) {
    return false;
  }
  return true;
};

export const getEpisodesPath = (gender) => {
  const path = gender === 'male' ? '/event-requests' : '/';
  return path;
};

export const getMyEpisodesPath = (gender) => {
  const path = gender === 'male' ? '/' : '/my-event-requests';
  return path;
};
