import { useTranslation } from 'react-i18next';

import { CloseAccountModal, PauseAccountModal } from './Modals';
import { useModal } from 'components/modal/useModal';
import Button from 'components/buttons/Button';
import styles from './modals.module.css';

const CloseAccount = () => {
  const modalClose = useModal();
  const modalPause = useModal();
  const { t } = useTranslation();
  return (
    <div className={styles.modalButtonsWrapper}>
      <div className={styles.modalButtons}>
        <Button
          size="s"
          type="button"
          name="btn-bare"
          onClick={() => modalClose.showModal()}
        >
          {t('features:settings.account.closeAccount.button')}
        </Button>
        |
        <Button
          size="s"
          type="button"
          name="btn-bare"
          onClick={() => modalPause.showModal()}
        >
          {t('features:settings.account.pauseAccount.button')}
        </Button>
      </div>
      {modalClose.isVisible && <CloseAccountModal modalContext={modalClose} />}
      {modalPause.isVisible && <PauseAccountModal modalContext={modalPause} />}
    </div>
  );
};

export default CloseAccount;
