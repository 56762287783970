import isString from 'lodash/isString';

const UTM_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign'];

const getReqParams = (param_name) => {
  const req = window.location.search;
  if (!req) {
    return {};
  }
  const urlParamsArray = req.split(/[?&]/).slice(1);
  const urlParams = urlParamsArray.map((pair) =>
    pair.split(/=(.+)?/).slice(0, 2)
  );
  const urlParamsObj = urlParams.reduce((obj, pairArray) => {
    obj[pairArray[0]] = pairArray[1];
    return obj;
  }, {});

  if (param_name) {
    return urlParamsObj[param_name];
  }
  return urlParamsObj;
};

export const setUtmParams = () => {
  if (window.location.search) {
    UTM_PARAMS.forEach((param_name) => {
      let param_value = getReqParams(param_name);
      if (isString(param_value)) {
        window.localStorage.setItem(param_name, param_value);
      }
    });
  }
};

export const getUtmParam = (param_name) => {
  return window.localStorage.getItem(param_name);
};

export const addUtmParams = (obj) => {
  UTM_PARAMS.forEach((param_name) => {
    let param_value = getUtmParam(param_name);
    if (param_value) {
      obj[param_name] = param_value;
    }
  });
  return obj;
};

export const removeUtmParams = () => {
  UTM_PARAMS.forEach((param_name) => {
    window.localStorage.removeItem(param_name);
  });
};
