import React, { useState } from 'react';
import Modal from './Modal';

export const useModal = (startVisible) => {
  const [isVisible, setIsVisible] = useState(Boolean(startVisible));

  const showModal = () => setIsVisible(true);
  const hideModal = () => setIsVisible(false);

  const handleOnClose = (callback) => {
    if (callback) callback();
    hideModal();
  };

  const RenderModal = ({
    children,
    footer,
    title,
    subTitle,
    buttonClose,
    scrollable,
    onClose,
    customStyles,
    closeButtonClass,
    bareInner,
    blockCloseOnBackdropClick,
  }) => (
    <>
      {isVisible && (
        <Modal
          closeModal={() => handleOnClose(onClose)}
          footer={footer}
          title={title}
          subTitle={subTitle}
          scrollable={scrollable}
          buttonClose={buttonClose}
          customStyles={customStyles}
          closeButtonClass={closeButtonClass}
          bareInner={bareInner}
          blockCloseOnBackdropClick={blockCloseOnBackdropClick}
        >
          {children}
        </Modal>
      )}
    </>
  );

  return {
    isVisible,
    showModal,
    hideModal,
    RenderModal,
  };
};
