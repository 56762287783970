import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { Row, Col } from 'components/layout/grid';
import Layout from 'components/layout/layouts/Layout';
import SubHeader from 'components/layout/subHeader/SubHeader';
import NavTabs from 'features/feed/navTabs/NavTabs';
import ActivityFeedContainer from 'features/feed/ActivityFeed';
import PurchasedView from 'features/feed/PurchasedView';
import GlobalFeed from 'features/feed/GlobalFeed';
import { NavFilters } from 'features/feed/navFilters';
//import SuggestionsList from 'features/user/suggestions/SuggestionsList';

const FeedScreen = () => {
  const { path } = useRouteMatch();
  const screenName = 'Feed';

  return (
    <Layout screenName={screenName} noBorderHeader>
      <Row>
        <Col sm={12} lg={12}>
          <SubHeader
            backBtn
            screenName={screenName}
            hideOnMobile
            style={{ borderBottomColor: 'transparent' }}
          />
          <NavTabs />
          <Switch>
            <Route path={`${path}/purchased`}>
              <PurchasedView />
            </Route>
            <Route path={`${path}/following`}>
              <ActivityFeedContainer feedType="timeline" location="home" />
            </Route>
            <Route path={[`${path}/category/:slug`, `${path}`]}>
              <NavFilters />
              <GlobalFeed />
            </Route>
          </Switch>
        </Col>
        {/*<Col sm={12} lg={4}>
          <SuggestionsList />
        </Col>*/}
      </Row>
    </Layout>
  );
};

export default FeedScreen;
