import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSubscriptionBundle,
  getSubscriptionSettings,
  selectSubscriptionsBundleOptions,
  selectCreateSubscriptionBundle,
} from '../subscriptionsSlice';
import { Button } from 'components/buttons';
import { unwrapResult } from '@reduxjs/toolkit';
import formStyles from 'components/forms/forms.module.css';
import styles from './subscriptionBundles.module.css';
import { IconShortLeft } from 'assets/icons';
import { useTranslation } from 'react-i18next';

const SubscriptionBundles = ({ onCreateBundleDone }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { discount_percents, durations } = useSelector(
    selectSubscriptionsBundleOptions
  );
  const { loading } = useSelector(selectCreateSubscriptionBundle);

  const [durationId, setDurationId] = useState(null);
  const [discountPercent, setDiscountPercent] = useState(null);

  // console.log(durations);

  const createBundle = async () => {
    const res = await dispatch(
      createSubscriptionBundle({
        duration_id: durationId,
        discount_percent: discountPercent,
      })
    );
    await unwrapResult(res);
    onCreateBundleDone();
    dispatch(getSubscriptionSettings());
  };

  return (
    <div className={styles.container}>
      <div className={styles.formWrapper}>
        <div className={styles.selectContainer}>
          <label htmlFor="frequency">
            {t('features:subscriptions.createBundle.paymentFrequency')}
          </label>
          <select
            id="frequency"
            className={formStyles.select}
            onChange={(e) => setDurationId(e.target.value)}
          >
            <option value={''}>{t('main:main.select')}</option>

            {durations.map(({ id, quantity }) => (
              <option value={id} key={id}>
                {quantity}{' '}
                {t('features:subscriptions.priceInput.month', {
                  count: quantity,
                })}
              </option>
            ))}
          </select>
          <IconShortLeft className={styles.carret} />
        </div>
        <div className={styles.selectContainer}>
          <label htmlFor="discount">
            {t('features:subscriptions.createBundle.setDiscount')}
          </label>

          <select
            id="discount"
            className={formStyles.select}
            onChange={(e) => setDiscountPercent(e.target.value)}
          >
            <option value={''}>{t('main:main.select')}</option>
            {discount_percents.map((value) => (
              <option value={value} key={value}>
                {value}% {t('features:subscriptions.createBundle.discount')}
              </option>
            ))}
          </select>
          <IconShortLeft className={styles.carret} />
        </div>
        <Button
          className={styles.btn}
          loading={loading}
          disabled={!durationId || !discountPercent}
          onClick={createBundle}
        >
          {t('features:subscriptions.createBundle.createBundle')}
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionBundles;

export const CreateBundleModal = ({ modalContext }) => {
  const { t } = useTranslation();
  const { RenderModal, hideModal } = modalContext;

  return (
    <RenderModal
      title={t('features:subscriptions.createBundle.createBundle')}
      buttonClose
    >
      <p style={{ margin: '-10px 0 0 5px' }}>
        {t('features:subscriptions.createBundle.description')}
      </p>
      <SubscriptionBundles onCreateBundleDone={hideModal} />
    </RenderModal>
  );
};
