import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'components/layout/grid';
import Layout from 'components/layout/layouts/Layout';
import SubHeader from 'components/layout/subHeader/SubHeader';
import ActivityFeedDetail from 'features/feed/ActivityFeedDetail';
import SuggestionsList from 'features/user/suggestions/SuggestionsList';

import { selectUserInfo } from 'features/auth/authSlice';

const PostDetail = (props) => {
  const user = useSelector(selectUserInfo);
  const { t } = useTranslation();
  const screenName = t('features:postDetailScreen.title', 'Post');

  return (
    <Layout screenName={screenName}>
      <Row>
        <Col sm={12} lg={8}>
          <SubHeader backBtn hideOnMobile screenName={screenName} />
          <ActivityFeedDetail feedType="timeline" user={user} />
        </Col>
        <Col sm={12} lg={4}>
          <SuggestionsList />
        </Col>
      </Row>
    </Layout>
  );
};
export default PostDetail;
