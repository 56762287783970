import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/buttons/Button';
import { ProfileVerification } from 'assets/icons/settings';
import styles from './kycEmpty.module.css';

const KycOndatoMessage = ({ type, button }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className={styles.wrapperEmpty}>
      <div className={styles.infoBox}>
        <div className={styles.iconTop}>
          <ProfileVerification />
        </div>
        <h2>{t(`features:Kyc.placeholder.${type}Title`)}</h2>
        <p>{t(`features:Kyc.placeholder.${type}Subtitle`)} </p>
        {button && (
          <Button
            extraPadding
            onClick={() => history.push('/settings/wallet/kyc')}
            name="btn-gradient"
            size="xl"
          >
            {t('features:Kyc.verifyButton')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default KycOndatoMessage;
