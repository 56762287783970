import React from 'react';
import { useTranslation } from 'react-i18next';
import { de, enGB  } from 'date-fns/locale';

import { dateTime, dateTimeLeft } from 'helpers/dating';
import * as ProfileIcons from 'assets/icons/profile';
import styles from './profile.module.css';
import {ProfileVerification, WalletKyc} from "../../assets/icons/settings";
import {PhoneActive} from "../../assets/icons/mainMenu";

const ProfileFields = (props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language === 'de' ? de : enGB;
  const fieldsOptions = {
    verification_required: {
      text: t('main:main.kyc'),
      value: (val) => val,
      icon: () => <WalletKyc />,
    },
    verified_phone: {
      text: t('main:main.phone'),
      value: (val) => val,
      icon: () => <PhoneActive />,
    },
    verified_photo: {
      text: t('main:main.image'),
      value: (val) => val,
      icon: () => <ProfileVerification />,
    },
    city: {
      text: t('features:profileFields.City'),
      value: (val) => val,
      icon: () => <ProfileIcons.City />,
    },
    age: {
      text: t('features:profileFields.Age'),
      value: (val) => val,
      icon: () => <ProfileIcons.Age />,
    },
    smoker: {
      text: t('features:profileFields.Smoker'),
      value: (val) => val,
      icon: () => <ProfileIcons.Smoker />,
    },
    languages: {
      text: t('features:profileFields.Languages'),
      value: (val) => val,
      icon: () => <ProfileIcons.Languages />,
    },
    profession: {
      text: t('features:profileFields.Profession'),
      value: (val) => val,
      icon: () => <ProfileIcons.Occupation />,
    },
    height: {
      text: t('features:profileFields.Height'),
      value: (val) => val,
      icon: () => <ProfileIcons.Height />,
    },
    body_type: {
      text: t('features:profileFields.bodyType'),
      value: (val) => val,
      icon: () => <ProfileIcons.BodyType />,
    },
    hair_color: {
      text: t('features:profileFields.hairColor'),
      value: (val) => val,
      icon: () => <ProfileIcons.HairColor />,
    },
    hair_length: {
      text: t('features:profileFields.hairLength'),
      value: (val) => val,
      icon: () => <ProfileIcons.HairLength />,
    },
    dateType: {
      text: t('features:profileFields.dateActivity'),
      value: (val) => val.join(', '),
      icon: () => <ProfileIcons.DateType />,
    },
    dateDescription: {
      text: t('features:profileFields.datePlan'),
      value: (val) => val,
      icon: () => <ProfileIcons.DateDetails />,
    },
    lookingFor: {
      text: t('features:profileFields.lookingFor'),
      value: (val) => val,
      icon: () => <ProfileIcons.Gender />,
    },

    dateTime: {
      text: t('features:profileFields.dateTime'),
      value: (val) => dateTime(val, lang),
      icon: () => <ProfileIcons.DateTime />,
    },
    dateTimeLeft: {
      text: t(
        'features:profileFields.expireTime',
        'Time until request expires'
      ),
      value: (val) => dateTimeLeft(val, lang, 1),
      icon: () => <ProfileIcons.TimeLeft />,
    },
    // dateDuration: {
    //   text: t('features:profileFields.dateDuration', 'Event duration'),
    //   value: (val) =>
    //     formatDuration(
    //       {
    //         hours: Math.floor(val / 60),
    //       },
    //       { locale: lang }
    //     ),
    //   icon: () => <ProfileIcons.DateDuration />,
    // },
  };

  const fields = Object.keys(props).map((key) => {
    if (!props[key]) {
      return null;
    }
    return (
      <div key={key} className={styles.fieldItem}>
        {fieldsOptions[key].icon()}
        <div className={styles[key]}>
          <strong>{fieldsOptions[key].text}</strong>
          <span>{fieldsOptions[key].value(props[key])}</span>
        </div>
      </div>
    );
  });

  return fields;
};

export default ProfileFields;
