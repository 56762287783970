import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Ably from 'ably';
import api from 'app/api';
import {
  getApplications,
  incrementNewApplicationsCount,
} from 'features/dating/applications/applicationsSlice';
import {
  getEpisodes,
  incrementNewEpisodesCount,
} from 'features/dating/episodes/episodesSlice';
import { validateDateRequest } from 'helpers/dating';
import { getUserDatingInfo } from 'features/user/profileSlice';
import { selectUserInfo, updateUser } from 'features/auth/authSlice';
import {
  getNotifications,
  incrementNumOfUnreadNotifications,
} from 'features/notifications/notificationsSlice';

export const WebSocketContext = createContext(null);

const WebSocketProvider = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserInfo);
  const [ws, setWS] = useState({});

  useEffect(() => {
    let socket = null;
    let channelUser = null;
    let channelGlobal = null;

    const initAbly = () => {
      if (!socket) {
        socket = new Ably.Realtime.Promise({
          key: process.env.REACT_APP_ABLY_KEY,
        });
        getChannel();
      }
    };

    const getChannel = async () => {
      const response = await api.get('v2/channel');
      if (!response.data.status) {
        return;
      }
      const data = response.data.data;
      if (!data.ok) {
        return;
      }

      channelUser = await socket.channels.get(data.channel);
      channelGlobal = await socket.channels.get('global');

      setWS({
        socket: socket,
        channelUser: channelUser,
        channelGlobal: channelGlobal,
      });

      subscribe();
    };

    const subscribe = async () => {
      // console.log('subscribe =>', channelUser, channelGlobal);
      if (channelUser && channelGlobal) {
        channelUser.subscribe(receiveHandler);
        channelGlobal.subscribe(receiveHandler);
      }
    };

    const unsubscribe = async () => {
      // console.log('unsubscribe =>', channelUser, channelGlobal);
      if (channelUser && channelGlobal) {
        channelUser.unsubscribe();
        channelGlobal.unsubscribe();
      }
    };

    const receiveHandler = (recData) => {
      // console.log('SOCKET ->', recData);

      switch (recData.data.type) {
        case 'contact':
          dispatch(incrementNewApplicationsCount());
          dispatch(getApplications());
          dispatch(getUserDatingInfo({ user_id: null }));
          break;

        case 'user':
          dispatch(updateUser());
          break;

        case 'newreq':
          if (validateDateRequest(user, recData.data)) {
            dispatch(incrementNewEpisodesCount());
            dispatch(getEpisodes());
          }
          break;

        case 'notification':
          dispatch(incrementNumOfUnreadNotifications());
          dispatch(getNotifications());
          break;

        default:
          break;
      }

      if (recData.data.notificationId) {
        // delete persistent notification from server
        // TODO
      }
    };

    initAbly();
    return () => unsubscribe();
  }, [dispatch, user]);

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
