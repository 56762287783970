import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation, Redirect } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import Loader from 'components/layout/loader';
import Layout from 'components/layout/layouts/Layout';
import ProfileView from 'features/user/ProfileView';
import MobileDisclaimer from 'features/user/dating/MobileDisclaimer';
import { selectUserInfo } from 'features/auth/authSlice';
import {
  selectTimestamp,
  getUserProfile,
  selectCurrentProfile,
  resetCurrentProfile,
  getUserProfileImages as getImages,
  getUserProfileVideos as getVideos,
  getUserDatingInfo as getDatingInfo,
  getUserStories as getStories,
} from 'features/user/profileSlice';

const Profile = () => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(selectUserInfo);
  const timestamp = useSelector(selectTimestamp);
  const profile = useSelector(selectCurrentProfile);
  const { info, videos, images, dating, stories } = profile;

  const getProfileInfo = useCallback(() => {
    if (id === user.id) {
      return;
    }
    const navState = location.state || {};
    const params = {
      user_id: id,
      episode: navState.episode,
      query_params: {
        episode_id: navState.episode
          ? navState.episode.id
          : navState.episode_id,
        application_id: navState.application_id,
      },
    };
    dispatch(getUserProfile(params))
      .then(unwrapResult)
      .then((result) => {
        if (result.status) {
          dispatch(getDatingInfo(params));
          dispatch(getImages(params));
          dispatch(getVideos(params));
          dispatch(getStories(id));
        } else {
          throw new Error('missing profile');
        }
      })
      .catch((error) => {
        history.replace('/');
      });
  }, [dispatch, id, user.id, history, location.state]);

  useEffect(() => {
    getProfileInfo();

    return () => {
      dispatch(resetCurrentProfile());
    };
  }, [dispatch, getProfileInfo]);

  // redirect to my profile url
  if (id === user.id) {
    return <Redirect to="/profile" />;
  }

  // is fetching data
  if (
    isEmpty(info) ||
    !videos.fetched ||
    !images.fetched ||
    !dating.fetched ||
    !stories.fetched
  ) {
    return <Loader />;
  }

  return (
    <>
      <Layout>
        <ProfileView screenKey={`${id}_${timestamp}`} profileUser={info} />
      </Layout>
      <MobileDisclaimer profile={info} dating={dating} />
    </>
  );
};

export default Profile;
