import GoogleLogin from "react-google-login";
import React from "react";
import {auth} from "./authSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {gapi} from "gapi-script";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

const SocialLogin = ({afterLoginTasks}) => {
    const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const createAuthLoginPayload = ((values) => {
            const payload = {auth: values};
            payload.email = values.profileObj.email;
            return payload;
        }
    );

    const loginAuthAction = (values) => {
        const loginPayload = createAuthLoginPayload(values);

        dispatch(auth(loginPayload))
            .then(unwrapResult)
            .then((result) => {
                history.replace('/');
                afterLoginTasks(result);
            })
            .catch((err) => {
                toast.error(t('main:errors.somethingWentWrong'));
            });
    };

    gapi.load("client:auth2", () => {
        gapi.auth2.init({
            clientId: googleClientId,
            scopes: "profile email"
        });
    });

    const responseGoogle = (response) => {
        loginAuthAction(response);
    };
    const errorMessage = (error) => {
        console.log(error);
        toast.error(t('main:errors.somethingWentWrong'));
    };

    return (
        <div style={{paddingBottom: "30px", textAlign: "center" }}>
            <GoogleLogin
                clientId={googleClientId}
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={errorMessage}
                cookiePolicy={'single_host_origin'}
                theme="dark"
            >
                <font
                    name='google'
                />
                <span> Login with Google</span>
            </GoogleLogin>
        </div>
    )
};

export default SocialLogin;