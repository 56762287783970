import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import Loader from 'components/layout/loader/Loader';
import MyEpisodesEmpty from './MyEpisodesEmpty';
import MyEpisodesList from './MyEpisodesList';

import {
  getApplications,
  selectApplications,
  selectFetched,
} from '../../applications/applicationsSlice';
import { resetNewEpisodesCount } from '../episodesSlice';
import {getBookings, selectBookings} from "../../clubs/bookingsSlice";
import MyClubList from "../../clubs/MyClubList";

const MyEpisodesView = () => {
  const dispatch = useDispatch();
  const episodes = useSelector(selectApplications);
  const clubs = useSelector(selectBookings);
  const {list} = clubs;
  const isFetched = useSelector(selectFetched);

  useEffect(() => {
    dispatch(getApplications());
    dispatch(getBookings());
    return () => dispatch(resetNewEpisodesCount());
  }, [dispatch]);

  if (isEmpty(episodes) && isEmpty(list) ) {
    return isFetched ? <MyEpisodesEmpty/> : <Loader/>;
  } else if (isEmpty(episodes)) {
    return <MyClubList clubs={clubs} />
  }

  return <MyEpisodesList episodes={episodes} clubs={clubs} />;
};

export default MyEpisodesView;
