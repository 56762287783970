import React from 'react';

const CountryFlag = ({countryCode, className}) => {

    if (countryCode !== undefined) {
        // Show a placeholder or loading state until the SVG is loaded
        return <img className={className} src={`/flags/4x3/${countryCode}.svg`} alt={countryCode}/>;
    }
    return null;
};

export default CountryFlag;