import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ProgressRing = ({
  size,
  strokeWidth,
  percentage,
  color,
  hideText,
  animated,
}) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(percentage);
  }, [percentage]);

  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (progress * circumference) / 100;

  return (
    <svg width={size} height={size} viewBox={viewBox}>
      <circle
        fill="none"
        stroke="#e2e2e2"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      {percentage > 0 && (
        <circle
          fill="none"
          stroke={color}
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
          strokeDasharray={[dash, circumference - dash]}
          strokeLinecap="round"
          style={animated ? { transition: 'all 1s' } : null}
        />
      )}
      {!hideText && (
        <text
          fill="black"
          fontSize="36px"
          x="50%"
          y="50%"
          dy={`${size / 12}px`}
          textAnchor="middle"
        >
          {`${percentage}%`}
        </text>
      )}
    </svg>
  );
};

ProgressRing.propTypes = {
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
  percentage: PropTypes.number,
  color: PropTypes.string,
  hideText: PropTypes.bool,
  animated: PropTypes.bool,
};

ProgressRing.defaultProps = {
  hideText: false,
  animated: false,
  color: '#0E1013',
  percentage: 100,
  strokeWidth: 15,
  size: 180,
};

export default ProgressRing;
