import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { isSafari } from 'react-device-detect';

import { IconBlock, IconReport } from 'assets/icons';
import { IconInsights } from 'assets/icons/stories';
import styles from './storyActions.module.css';

const ContextMenu = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const {
    show,
    isMyProfile,
    isMobile,
    blockModal,
    reportModal,
    deleteModal,
    insightsModal,
    onCloseMenu,
  } = props;

  if (!show) return null;

  const Element = () => {
    return (
      <div ref={ref} className={styles.menu}>
        {isMyProfile ? (
          <>
            <span
              onClick={() => {
                deleteModal.showModal();
                onCloseMenu();
              }}
              className={styles.btn}
            >
              <IconBlock className={styles.ico} />{' '}
              {t('features:stories.delete.button')}
            </span>
            <span
              onClick={() => {
                insightsModal.showModal();
                onCloseMenu();
              }}
              className={styles.btn}
            >
              <IconInsights className={styles.icoInsights} />{' '}
              {t('features:stories.insights.button')}
            </span>
          </>
        ) : (
          <>
            <span
              onClick={() => {
                blockModal.showModal();
                onCloseMenu();
              }}
              className={styles.btn}
            >
              <IconBlock className={styles.ico} />{' '}
              {t('features:blockReport.Block')}
            </span>
            <span
              onClick={() => {
                reportModal.showModal();
                onCloseMenu();
              }}
              className={styles.btn}
            >
              <IconReport className={styles.ico} />{' '}
              {t('features:blockReport.Report')}
            </span>
          </>
        )}
      </div>
    );
  };

  if (isSafari && !isMobile) {
    return ReactDOM.createPortal(
      <Element />,
      document.getElementById('iosStoryActions')
    );
  }

  return <Element />;
});

export default ContextMenu;
