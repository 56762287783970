import styles from './infoBar.module.css';

const InfoBar = ({ badge, info, style }) => {
  return (
    <div className={styles.container} style={style}>
      {badge && (
        <div className={styles.badge}>
          <span>{badge}</span>
        </div>
      )}
      <p className={styles.info}>{info}</p>
    </div>
  );
};

export default InfoBar;
