import {useTranslation} from 'react-i18next';
import VerificationLinksView from "./VerificationLinksView";
import {Row} from "components/layout/grid";
import Button from 'components/buttons/Button';
import {kycVerification, phoneVerification, imageVerification} from 'assets/default';

const VerificationModal = ({
                               modalContext: {RenderModal, hideModal}
                           }) => {
    const {t} = useTranslation();

    const options = {
        mainPage: [
            {
                to: `/settings/wallet/kyc/`,
                title: t('main:main.kyc'),
                text: t('main:main.verification'),
                image: kycVerification
            },
            {
                to: {
                    pathname: `/settings/account/edit/`,
                    state: {openPhone: true}
                },
                title: t('main:main.phone'),
                text: t('main:main.verification'),
                image: phoneVerification
            },
            {
                to: `/settings/profile/verify/`,
                title: t('main:main.image'),
                text: t('main:main.verification'),
                image: imageVerification
            }
        ],
    };

    return (
        <RenderModal
            title={t('features:episodes.VerificationModalTitle')}
            subTitle={t('features:episodes.VerificationModalSubtitle')}
            buttonClose
            customStyles={{
                width: '550px',
                maxWidth: '550px',
                margin: '20px var(--half-gutter-width) 0px var(--half-gutter-width)'
            }}
            footer={
                <>
                    <Button
                        onClick={() => hideModal()}
                        name="btn-grey"
                        style={{width: '100%',marginTop:'20px'}}>
                        {t('main:main.Cancel')}
                    </Button>
                </>
            }
        >
            <Row center="md">
                <VerificationLinksView options={options}/>
            </Row>
        </RenderModal>
    );
};

export {VerificationModal};
