import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'app/api';

export const getTopCreators = createAsyncThunk(
  'topCreators/getTopCreators',
  async ({ gender, isPublic = false }) => {
    const version = isPublic ? 'guests' : 'v2';
    let url = `${version}/top/creators`;

    if (gender) url = `${url}?gender=${gender}`;

    const response = await api.get(url);
    return response.data;
  }
);

const initialState = {
  status: {
    loading: false,
    hasError: false,
  },
  results: [],
};

export const topCreators = createSlice({
  name: 'topCreators',
  initialState: initialState,
  reducers: {
    cleanTopCreators: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [getTopCreators.pending]: (state, action) => {
      state.status.loading = true;
      state.status.hasError = false;
    },
    [getTopCreators.fulfilled]: (state, action) => {
      state.status.loading = false;
      if (action.payload.status) {
        state.results = action.payload.data;
        if (state.results) {
          state.results.timestamp = Date.now();
        }
        state.status.hasError = false;
      } else {
        state.status.hasError = true;
      }
    },
    [getTopCreators.rejected]: (state, action) => {
      state.status.loading = false;
      state.status.hasError = true;
    },
  },
});

export const { cleanTopCreators } = topCreators.actions;

export const selectTopCreators = (state) => state.topCreators.results;
export const selectFetching = (state) => state.topCreators.status.loading;

export default topCreators.reducer;
