import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import { useLockBodyScroll } from 'hooks';
import { IconClose } from 'assets/icons';
import Button from 'components/buttons/Button';
import styles from './modal.module.css';
import cx from 'classnames';

const Modal = React.memo(
  ({
    children,
    footer,
    title,
    subTitle,
    buttonClose,
    closeModal,
    customStyles,
    bareInner,
    scrollable,
    closeButtonClass,
    blockCloseOnBackdropClick = false,
  }) => {
    const domEl = document.getElementById('modal-root');
    const backdropRef = useRef(null);

    const handleBackdropClick = (e) => {
      if (blockCloseOnBackdropClick) return;

      e.stopPropagation();
      if (backdropRef.current !== e.target) return;
      closeModal();
    };

    useLockBodyScroll();

    if (!domEl) return null;

    if (bareInner) {
      return ReactDOM.createPortal(
        <div
          ref={backdropRef}
          onClick={handleBackdropClick}
          className={styles.backdrop}
        >
          {children}
        </div>,
        domEl
      );
    }

    return ReactDOM.createPortal(
      <div
        ref={backdropRef}
        onClick={handleBackdropClick}
        className={styles.backdrop}
      >
        <div
          className={cx(styles.container, { [styles.scrollable]: scrollable })}
          style={customStyles}
        >
          <div className={styles.header}>
            {title && <span className={styles.title}>{title}</span>}
            {buttonClose && (
              <Button
                name="btn-bare"
                className={cx(styles.close, closeButtonClass)}
                onClick={closeModal}
              >
                <IconClose />
              </Button>
            )}
          </div>
          {subTitle && <div className={styles.subTitle}>{subTitle}</div> }
          <div className={styles.body}>{children}</div>
          {footer && <div className={styles.footer}>{footer}</div>}
        </div>
      </div>,
      domEl
    );
  }
);

export default Modal;
