import React from "react";
import {getImageSrc} from "../../../helpers/s3media";
import {bannerDefault} from "../../../assets/default";
import cx from "classnames";
import {useHistory} from "react-router-dom";
import styles from "./cityClubsListItem.module.css";
import {Col} from "../../../components/layout/grid";
import Avatar from "../../../components/avatar/Avatar";

const CityClubCard = ({club, hideFilter}) => {
    const history = useHistory();
    const firstImage = club.images.length > 0 ? club.images[0] : null;

    const firstImageUrl = firstImage ? getImageSrc({
        public_id: firstImage.public_id,
        format: firstImage.format,
        resize: {
            width: 400,
            fit: 'cover',
        },
    }) : getImageSrc(bannerDefault);

    /*let wrapperClass = cx(styles.col);
    if (hideFilter) {
        wrapperClass = cx(styles.col, styles.homeouter);
    }*/
    const handleClick = () => {
        const clubLink = `/club/${club.key_id}`;
        history.push(clubLink);
    }

    return (
        <Col lg={3} md={4} sm={6} xs={6} key={club.id} className={styles.outerboxCol}
             onClick={handleClick}>
            <div className={styles.outerbox}>
                <div className={styles.eventimg}>
                    <div
                        className={cx(styles.clubImageContainer, styles.clubImageCard)}>
                        <div>
                            <img
                                src={firstImageUrl}
                                className={styles.eventimg}
                                alt={club.name}
                            />
                        </div>
                    </div>

                </div>
                <div className={styles.outerboxCity}>
                    <Avatar
                        /*onClick={onClick}*/
                        size={80}
                        /*wrapperStyle={{ position: 'absolute', top: '12px', left: '12px' }}*/
                        image={club.avatar}
                        city
                        className={styles.imguserover}
                    />
                    {/*<img
                                            src="https://images.unsplash.com/photo-1564564321837-a57b7070ac4f?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8bWFufGVufDB8fDB8fHww&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60"/>*/}
                </div>
               {/* <div className={styles.outerboxCity}>
                    <img
                        src={avatarImageUrl}
                        className={styles.eventimg}
                        alt={club.name}
                    />
                </div>*/}
                <div className={styles.footerBox}>
                    {club.name}
                </div>
                {/*<div className={styles.eventinfo}>
                    <div className={styles.desc}>
                        <h3>{club.name}</h3>
                        <div className={styles.venue}>{club.address}</div>
                    </div>

                    <LocationInfo
                        customClass={styles.city}
                        town_name={club.city}
                        country={club.country_code.toLowerCase()}
                    />
                </div>*/}
            </div>
        </Col>
    );
}

export default CityClubCard;