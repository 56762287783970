import React from "react";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
/*import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
*/
import { Avatar } from 'components/avatar';
import { selectUserInfo } from 'features/auth/authSlice';
//import { IconCoin } from 'assets/icons';
import { ProfileBadges } from 'components/layout/badges/Badges';
import styles from './sidebarUserProfile.module.css';
import {LocationInfo} from "../../../../features/user/profileInfo/profileMetaInfo";

const SidebarUserProfile = ({ callback }) => {
  const history = useHistory();
  //const { t } = useTranslation();
  const user = useSelector(selectUserInfo);
  //const currency = useSelector(selectUserCurrency);

  // missing Pound Icon
  //const currencyIcon = currency.code === 'EUR' ? <IconEuro /> : <IconDollar />;

  return (
    <div className={styles.userProfile}>
      <div>
        <Avatar
          circle
          online
          size={70}
          image={user.avatar}
          gender={user.gender}
          style={{ display: 'inline-block' }}
          onClick={() => {
            callback();
            history.push('/profile');
          }}
        />
        <div
          className={styles.userName}
          onClick={() => {
            callback();
            history.push('/profile');
          }}
        >
          {user.name}{' '}
          {Number(user.age) > 0 && (
            <span className={styles.age}>{user.age}</span>
          )}
          <ProfileBadges
            small
            verified={!user.verification_required}
            dating={user.dating_enabled}
            phone={user.verified_phone}
          />
        </div>
        <p
          className={styles.location}
          onClick={() => {
            callback();
            history.push('/profile');
          }}
        >
          {/*<IconMapMark width={10} /> {user.town_name}, {user.country_name}*/}
            <LocationInfo
                town_name={user.town_name}
                country_name={user.country_name}
                country={user.country}
            />
        </p>
      </div>
      {/*<div className={styles.paymentsRow}>
        <div
          className={styles.coins}
          onClick={() => {
            window.location.assign(process.env.REACT_APP_PAYMENTS_CHECKOUT);
          }}
        >
          <IconCoin />
          <span>{user.payments.total_credits}</span>
        </div>
        {!isEmpty(currency) && user.payments.total_balance !== 0 && (
          <div className={styles.coins}>
            <NumberFormat
              value={user.payments.total_balance}
              displayType={'text'}
              thousandSeparator={currency.thousands}
              decimalSeparator={currency.decimal}
              fixedDecimalScale
              decimalScale={currency.number_decimals}
              prefix={
                currency.placement === 'before' ? `${currency.symbol}` : null
              }
              suffix={
                currency.placement === 'after' ? ` ${currency.symbol}` : null
              }
            />
          </div>
        )}
      </div>*/}
      {/*<Link
        className={styles.link}
        onClick={() => {
          callback();
          window.location.assign(process.env.REACT_APP_PAYMENTS_CHECKOUT);
        }}
        to="/"
      >
         <IconCoin width={10} />
        <span>{t('components:sidebarLabel.buyCoins')}</span>
      </Link>*/}
    </div>
  );
};

export default SidebarUserProfile;
