import { NavLink } from 'react-router-dom';
import { isBoolean } from 'lodash';
import { IconArrowLeft } from 'assets/icons';
import styles from './verificationModal.module.css';
import {getLayoutImageSrc} from "../../../helpers/s3media";
import {Col} from "components/layout/grid";

const VerificationLinksView = ({ options }) => {
  if (!options) return null;

  return (
      <>
        {options.mainPage.map((item, i) => {
          if (isBoolean(item.condition) && !item.condition) return null;
            return (
                <Col xs={12} md={4} key={i}>
                    <NavLink className={styles.vcenter} to={item.to} key={i}>
                        <div className={styles.img}>
                          <img
                              style={{ maxWidth: '90%', margin: '10px 0' }}
                              src={getLayoutImageSrc(item.image)}
                              alt=""
                          />
                        </div>
                        <div className={styles.text}>
                          <h4 className={styles.vh4}>{item.title}</h4>
                          <span className={styles.vspan}>{item.text}</span>
                        </div>
                        <div className={styles.arrow}>
                            <IconArrowLeft width={52} />
                        </div>
                    </NavLink>
                </Col>
            );
        })}
      </>
  );
};

export default VerificationLinksView;
