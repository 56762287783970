import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import RegisterForm from './RegisterForm';
import { signUp } from 'features/auth/authSlice';
import {
  addUtmParams,
  setUtmParams,
  removeUtmParams,
} from 'helpers/utm_params';
import { trackEvent } from 'helpers/tracking';
import SocialLogin from "../SocialLogin";
import {useLoginContext} from "../loginForm/LoginContext";

const Register = ({ isModal }) => {
  const dispatch = useDispatch(signUp);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const history = useHistory();
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const {afterLoginTasks} = useLoginContext();

  useEffect(() => {
    setUtmParams();
  }, []);

  const onSubmitForm = async (data) => {
    const token = await executeRecaptcha('register');

    const payload = {
      // full_name: data.full_name,
      // country: data.country,
      // country_name: data.country_name,
      // city: data.city_slug,
      // city_id: data.city_id,
      email: data.email,
      password: data.password,
      recaptcha: token || null,
      // type: gender,
      // looking_for: getPreference(),
      allow_newsletter: false,
      language: 'english',
      referral_code: data.referral_code,
    };

    const finalPayload = addUtmParams(payload);
    // console.log(finalPayload);

    try {
      const resultAction = await dispatch(signUp(finalPayload));
      const response = unwrapResult(resultAction);
      if (response.status) {
        trackEvent({
          category: 'auth',
          action: 'register',
          label: 'create_account',
        });
        removeUtmParams();
        window.localStorage.removeItem('ref'); // Clean referral code
        history.push('/register/send-email-confirm', {
          email: response.data.email,
          token: response.data.token,
        });
      } else {
        throw new Error();
      }
    } catch (err) {
      //console.log(err);
      toast.error(t('main:errors.somethingWentWrong'));
    }
  };

  return (
    <div>
      <RegisterForm ref={inputRef} onSubmit={onSubmitForm} isModal={isModal} />
      <SocialLogin afterLoginTasks={afterLoginTasks}/>
    </div>
  );
};

export default Register;
