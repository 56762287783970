import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// import { getUser } from './features/auth/authSlice';
import * as serviceWorker from './serviceWorker';

import store from 'app/store';
/*import SmartLook from 'app/smartlook';*/
import 'app/i18n';
import 'app/initLibs';

import './index.css';
import 'assets/css/libs';

export const history = createBrowserHistory();

// store.dispatch(getUser());

const render = () => {
  const App = require('./App').default;
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Router>
          <Suspense fallback={null}>
            <App />
          </Suspense>
          {/*<SmartLook />*/}
        </Router>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
