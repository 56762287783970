import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { wp_api } from 'app/api';
import { LoaderRelative } from 'components/layout/loader';
import { selectUserLanguage } from 'features/auth/authSlice';

const LEGAL_PAGES = {
  terms: {
    en: 'v2/pages/3405',
    de: 'v2/pages/3405',
  },
  privacy: {
    en: 'v2/pages/232',
    de: 'v2/pages/232',
  },
  imprint: {
    en: 'v2/pages/213',
    de: 'v2/pages/213',
  },
};

function Legal({ page = 'terms' }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const lang = useSelector(selectUserLanguage);
  const { t } = useTranslation();

  useEffect(() => {
    const getContent = async () => {
      try {
        const res = await wp_api.get(LEGAL_PAGES[page][lang]);
        if (res.status === 200) {
          setData(res.data.content.rendered);
          setLoading(false);
          setError(false);
        } else {
          throw new Error('report user error');
        }
      } catch (error) {
        setLoading(false);
        setError(true);
      }
    };
    getContent();
  }, [page, lang]);

  if (loading) return <LoaderRelative />;

  return (
    <div id="legal-pages">
      {error ? (
        <p>{t('features:blockReport.error')}</p>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: data }}></div>
      )}
    </div>
  );
}

export const TermsPage = () => <Legal page="terms" />;
export const PrivacyPage = () => <Legal page="privacy" />;
export const ImprintPage = () => <Legal page="imprint" />;

export default Legal;
