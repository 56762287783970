import React from 'react';
import {useTranslation} from 'react-i18next';
import {ImageDropzone, FileUploadButton} from 'react-file-utils';
import {
    ChatAutoComplete,
    EmojiPicker,
    UploadsPreview,
    //useChannelActionContext,
    useChannelStateContext,
    useMessageInputContext,
} from 'stream-chat-react';
import {IconPlus, IconChatEmoji} from 'assets/icons';
import Button from 'components/buttons/Button';
import styles from './chatInput.module.css';
/*import {TipChatButtonWithText} from "features/user/profileInfo/Buttons";
import {trackEvent} from "helpers/tracking";
import {
    selectTipTransactionResponse,
    startTipTransactionModal
} from "features/transactions/transactionsSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectUserInfo} from 'features/auth/authSlice';
import {chatPartner} from "../chatChannelHeader/ChatChannelHeader";
import {getLayoutImageSrc} from "helpers/s3media";
import {tipAmor, tipGift, tipHeart, tipLoveLetter, tipRose, tipWine} from "assets/default";
*/
const ChatInput = () => {
    const {t} = useTranslation();
    const {
        acceptedFiles,
        maxNumberOfFiles,
        multipleUploads,
        //channel
    } = useChannelStateContext();

    //to send custom message inside chat
    /*const {sendMessage} = useChannelActionContext();

    const dispatch = useDispatch();*/

    const messageInput = useMessageInputContext();

    //to send tip to partner
    /*const me = useSelector(selectUserInfo);
    const partner = chatPartner(channel, me.uid);

    const tipUser = {...partner, id: partner.original_id};

    const handleTip = () => {
        trackEvent({
            category: `on chat`,
            action: 'open tip click',
            label: partner.gender,
        });
        dispatch(startTipTransactionModal({actor: tipUser, chatTipTransaction: true}));
    };

    const res = useSelector(selectTipTransactionResponse);

    const [tipItems] = useState([
        {key: 'wine', value: tipWine},
        {key: 'rose', value: tipRose},
        {key: 'loveletter', value: tipLoveLetter},
        {key: 'heart', value: tipHeart},
        {key: 'gift', value: tipGift},
        {key: 'amor', value: tipAmor},
        // Add more key-value pairs as needed
    ]);

    useEffect(() => {
        if (res && res.payload.status === true) {
            const filteredPairs = tipItems.filter((pair) =>
                pair.key.toLowerCase().includes(res.meta.arg.code.toLowerCase())
            );
            const img = filteredPairs.map((pair) => {
                return getLayoutImageSrc(pair.value);
            })

            const coinmsg = res.meta.arg.tipMessage.length > 0 ?  t('features:tip.send.sentViaChat',{Sender:me.name})
                + res.meta.arg.tipMessage : t('features:tip.send.sentViaChat', {Sender:me.name});
            //send tip inside chat box
            sendMessage({
                text: coinmsg,
                attachments: [
                    {
                        type: 'image',
                        image_url: img[0],
                    },
                ],
            });
        }
    }, [res, tipItems, sendMessage]);*/

    return (
        <div className={`str-chat__messaging-input ${styles.container}`}>
            <div className={styles.inner}>
                <ImageDropzone
                    style={{flexGrow: 2}}
                    accept={acceptedFiles}
                    handleFiles={messageInput.uploadNewFiles}
                    multiple={multipleUploads}
                    disabled={
                        maxNumberOfFiles !== undefined &&
                        messageInput.numberOfUploads >= maxNumberOfFiles
                    }
                >
                    <div className="messaging-input__input-wrapper">
                        <UploadsPreview/>
                        <ChatAutoComplete
                            onChange={messageInput.handleChange}
                            rows={1}
                            placeholder={t('features:chat.inputPlaceholder')}
                        />
                    </div>
                </ImageDropzone>
                <div className={styles.buttonsWrapper}>
                    <div
                        className={`messaging-input__button emoji-button ${styles.emojiBtn}`}
                        role="button"
                        aria-roledescription="button"
                        onClick={messageInput.openEmojiPicker}
                        ref={messageInput.emojiPickerRef}
                    >
                        <IconChatEmoji className={styles.emojiIcon}/>
                    </div>
                    {/*<TipChatButtonWithText
                        profileUser={partner}
                        handleTip={handleTip}/>*/}
                    <div className={styles.uplodBtn}>
                        <FileUploadButton handleFiles={messageInput.uploadNewFiles}>
                            <IconPlus className={styles.uploadIcon}/>
                        </FileUploadButton>
                    </div>

                    <Button
                        onClick={messageInput.handleSubmit}
                        className={styles.sendButton}
                        name="btn-gradient"
                        size="m"
                    >
                        {t('main:main.send')}
                    </Button>
                </div>
            </div>
            {/*<div
        className={`messaging-input__button ${styles.submitBtn}`}
        role="button"
        aria-roledescription="button"
        onClick={messageInput.handleSubmit}
      >
        <IconChatSubmit className={styles.sendIco} />
      </div>*/}
            <EmojiPicker/>
        </div>
    );
};

export default React.memo(ChatInput);
