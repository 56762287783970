import { Route, Switch, Redirect } from 'react-router-dom';
import ProfileScreen from 'features/settings/profile/ProfileScreen';
import AccountScreen from 'features/settings/account/AccountScreen';
//import ListsScreen from 'features/settings/lists/ListsScreen';
//import WalletScreen from 'features/settings/wallet/WalletScreen';
import LegalScreen from 'features/settings/legal/LegalScreen';

const SettingsScreen = () => {
  return (
    <Switch>
      <Route path="/legal" component={LegalScreen} />
      <Route path="/settings/account" component={AccountScreen} />
      <Route path="/settings/profile" component={ProfileScreen} />
      {/*<Route path="/settings/lists" component={ListsScreen} />
      <Route path="/settings/wallet" component={WalletScreen} />*/}
      <Redirect to="/" />
    </Switch>
  );
};

export default SettingsScreen;
