import { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withChatContext } from 'stream-chat-react';

import { ChatContext } from '../chatContext';
import Avatar from 'components/avatar/Avatar';
import Button from 'components/buttons/Button';
import ProfileActions from 'features/user/profileActions/ProfileActions';
import { selectUserInfo } from 'features/auth/authSlice';
import { Notifications, IconArrowLeft } from 'assets/icons';
import styles from './chatChannelHeader.module.css';

// get chat members and exclude own user ? stream api
export const chatPartner = (channel, userId) => {
  for (const [key] of Object.entries(channel.state.members)) {
    if (Number(key) !== userId) {
      return channel.state.members[key].user;
    }
  }
};

const ChatChannelHeader = (props) => {
  const { openMobileNav, channel, client } = props;
  const { mobileView } = useContext(ChatContext);
  const [isOnline, setIsOnline] = useState(false);
  const [isMuted, setMuted] = useState(channel.muteStatus().muted);
  const history = useHistory();
  const me = useSelector(selectUserInfo);
  const partner = chatPartner(channel, me.uid);
  // console.log(partner);

  const onlineStatusHandler = (event) => {
    setIsOnline(event.user.online);
  };

  const muteHandler = () => {
    if (isMuted) {
      channel.unmute();
      setMuted(false);
    } else {
      channel.mute();
      setMuted(true);
    }
  };

  const userClick = () => {
    if (partner.original_id) {
      return history.push(`/profile/${partner.original_id}`, {
        chatID: channel.id,
      });
    }
  };

  useEffect(() => {
    if (partner && partner.online) {
      setIsOnline(partner.online);
    }
    channel.watch({ presence: true });
    // subscribe
    client.on('user.presence.changed', onlineStatusHandler);
    return () => {
      // unsubscribe
      client.off('user.presence.changed', onlineStatusHandler);
    };
  }, [partner, channel, client]);

  if (!partner) {
    return null;
  }

  const { name, gender, image } = partner;

  return (
    <div className={styles.header}>
      <div className={styles.left}>
        {mobileView && (
          <Button
            name="btn-bare"
            onClick={openMobileNav}
            style={{ padding: '5px 15px 5px 0px' }}
          >
            <IconArrowLeft style={{ width: '25px', height: '16px' }} />
          </Button>
        )}

        <div className={styles.leftContent}>
          <div onClick={userClick} className={styles.user}>
            <Avatar
              size={45}
              image={image}
              online={isOnline}
              gender={gender}
              circle
            />
            <p className={styles.userName}>
              {name}
              {/*<span className={styles.age}>{age}</span>
            <span className={styles.verified}>
              {verified && <IconVerifiedSmall />}
            </span>*/}
            </p>
          </div>
          <Button
            style={{ position: 'relative', height: '20px', padding: '5px' }}
            onClick={muteHandler}
            name="btn-bare"
            className={styles.muteBtn}
          >
            {isMuted && <span className={styles.muteLine}></span>}
            <Notifications style={{ width: '20px', height: '15px' }} />
          </Button>
          {/* <Button name="btn-bare">
              <IconStar />
            </Button> */}
        </div>
      </div>

      <div className={styles.right}>
        <ProfileActions
          darkTrigger
          isChat
          profileUser={partner}
          isMobile={mobileView}
        />
      </div>
    </div>
  );
};

export default withChatContext(ChatChannelHeader);
