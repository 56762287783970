import React, { createContext, useContext, useRef } from 'react';
import {setUserAfterLogin} from "../authSlice";
import {resetSearch} from "../../userList/search/searchSlice";
import {resetUserList} from "../../userList/userListSlice";
import {resetEpisodesList} from "../../dating/episodes/episodesSlice";
import {resetGlobalFeed} from "../../feed/feedSlice";
import {resetStories} from "../../stories/storiesSlice";
import {trackEvent} from "../../../helpers/tracking";
import {saveLocation, selectSavedLocation, setShowTutorial} from "../../../app/miscSlice";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

// Create a context for Login component
const LoginContext = createContext();

const useLoginContext = () => useContext(LoginContext);

const LoginProvider = ({ children }) => {
    const loginRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();
    const savedLocation = useSelector(selectSavedLocation);

    const afterLoginTasks = async (result) => {
        const { user, first_login } = result.data;
        await dispatch(setUserAfterLogin(user));
        dispatch(resetSearch());
        dispatch(resetUserList());
        dispatch(resetEpisodesList());
        dispatch(resetGlobalFeed());
        dispatch(resetStories());

        trackEvent({
            category: 'auth',
            action: `login - ${user.gender}`,
            label: first_login ? 'first_login' : 'returning',
        });

        if (first_login) {
            dispatch(setShowTutorial(true));
        } else if (savedLocation) {
            history.push(savedLocation);
            dispatch(saveLocation(null));
        } else if (user.creator_dashboard) {
            history.replace('/statistics');
        }
    };

    return (
        <LoginContext.Provider value={{ afterLoginTasks, loginRef }}>
            {children}
        </LoginContext.Provider>
    );
};

export { LoginProvider, useLoginContext };
