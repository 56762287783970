import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import api from 'app/api';
import Button from 'components/buttons/Button';
import Avatar from 'components/avatar/Avatar';
import { FormRow, TextArea } from 'components/forms';
import ReportReasonsSelect from 'components/forms/ReportReasonsSelect';
import { LoaderRelative, LoaderInline } from 'components/layout/loader';
import { ProfileBadges } from 'components/layout/badges/Badges';
import { useWindowWidthLessThan } from 'hooks';
import { getReportReasons, selectReportReasons } from 'app/miscSlice';
import { updateUser } from 'features/auth/authSlice';
import {
  getSeenStory,
  resetSeenStory,
  selectCurrentStoryViews,
  selectCurrentStoryViewsStatus,
} from 'features/stories/storiesSlice';
import { IconMapMark } from 'assets/icons';
import { IconViews } from 'assets/icons/stories';
import styles from './storyActions.module.css';

const BlockUserModal = ({
  modalContext: { RenderModal, hideModal },
  profileUser,
  pauseAction,
}) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setloading] = useState(false);
  const { t } = useTranslation();

  const onBlock = async () => {
    setloading(true);
    try {
      const res = await api.get(`v2/user/actions/block/${profileUser.uid}`);
      if (!res.data.status) {
        throw new Error('block user error');
      }

      setSuccess(true);
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
      setError(true);
    }
  };

  const onClose = () => {
    pauseAction('play');
    hideModal();
  };

  if (error) {
    return (
      <RenderModal
        title={t('features:blockReport.blockMember')}
        onClose={() => pauseAction('play')}
        buttonClose
      >
        <div className={styles.reportReasons}>
          <p>{t('features:blockReport.error')}</p>
          <br />
          <Button onClick={onClose} name="btn">
            {t('features:blockReport.OK')}
          </Button>
        </div>
      </RenderModal>
    );
  }

  if (loading) {
    return (
      <RenderModal
        title={t('features:blockReport.blockMember')}
        onClose={() => pauseAction('play')}
        buttonClose
      >
        <LoaderRelative />
      </RenderModal>
    );
  }

  if (success) {
    return (
      <RenderModal
        title={t('features:blockReport.blockMember')}
        onClose={() => pauseAction('play')}
        buttonClose
      >
        <div className={styles.reportReasons}>
          <p>
            {t('features:blockReport.successBlock', {
              userName: profileUser.name,
            })}
          </p>
          <br />
          <Button onClick={onClose} name="btn">
            {t('features:blockReport.OK')}
          </Button>
        </div>
      </RenderModal>
    );
  }

  return (
    <RenderModal
      title={t('features:blockReport.blockMember')}
      onClose={() => pauseAction('play')}
      buttonClose
      footer={
        <>
          <Button onClick={onClose} name="btn-grey" style={{ width: '48%' }}>
            {t('main:main.Cancel')}
          </Button>
          <Button onClick={onBlock} name="btn" style={{ width: '48%' }}>
            {t('features:blockReport.Block')}
          </Button>
        </>
      }
    >
      <p className={styles.copy}>
        {t('features:blockReport.confirmBlock', { userName: profileUser.name })}
      </p>
    </RenderModal>
  );
};

const StoryInsightsModal = ({
  modalContext: { RenderModal, hideModal },
  closeAction,
  pauseAction,
  profileUser,
  story,
}) => {
  const storyViews = useSelector(selectCurrentStoryViews);
  const { fetched, loading } = useSelector(selectCurrentStoryViewsStatus);
  const smallWidth = useWindowWidthLessThan(576);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (isEmpty(storyViews)) {
      dispatch(getSeenStory({ story_id: story.id }));
    }
  }, [dispatch, storyViews, story.id]);

  useEffect(() => {
    return () => {
      dispatch(resetSeenStory());
    };
  }, [dispatch]);

  const getMoreUsers = () => {
    dispatch(
      getSeenStory({
        story_id: story.id,
        page: storyViews.paginate.nextPage,
      })
    );
  };

  if (isEmpty(storyViews)) {
    return (
      <RenderModal
        title={t('features:stories.insights.modal.title')}
        onClose={() => pauseAction('play')}
        buttonClose
      >
        {fetched ? <LoaderInline size="s" /> : <LoaderInline size="s" />}
      </RenderModal>
    );
  }

  return (
    <RenderModal
      title={t('features:stories.insights.modal.title')}
      onClose={() => pauseAction('play')}
      scrollable
      buttonClose
    >
      <div className={styles.tabs}>
        <div className={styles.viewsCount}>
          <IconViews width="20" />
          <strong>{storyViews.paginate.total}</strong>
          <span>
            {t('features:stories.insights.modal.views', {
              count: storyViews.paginate.total,
            })}
          </span>
        </div>
      </div>
      <div className={styles.listWrapper}>
        {storyViews.list.map((item, i) => {
          const location = `${item.city}, ${item.country_name}`;
          return (
            <div
              key={i}
              className={styles.userWrapper}
              onClick={() => history.push(`/profile/${item.id}`)}
            >
              <div className={styles.avatarWrapper}>
                <Avatar
                  className={styles.avatar}
                  size={smallWidth ? 50 : 72}
                  gender={item.gender}
                  image={item.avatar}
                  circle
                />
              </div>
              <div className={styles.userInfo}>
                <div className={styles.userName}>
                  <span>{item.name}</span>
                  {Number(item.age) > 0 && (
                    <span className={styles.age}>{item.age}</span>
                  )}
                  <ProfileBadges
                    small
                    verified={item.verified}
                    dating={item.dating_enabled}
                    phone={item.verified_phone}
                  />
                </div>
                <span className={styles.location}>
                  <IconMapMark width={10} />
                  {location.substring(0, 25)}
                </span>
              </div>
            </div>
          );
        })}
        {storyViews.paginate.nextPage && (
          <Button
            onClick={() => getMoreUsers()}
            name="btn"
            fullWidth
            loading={loading}
          >
            {t('features:stories.insights.modal.more', 'More')}
          </Button>
        )}
      </div>
    </RenderModal>
  );
};

const DeleteStoryModal = ({
  modalContext: { RenderModal, hideModal },
  closeAction,
  pauseAction,
  profileUser,
  story,
}) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onDelete = async () => {
    setloading(true);
    try {
      const res = await api.delete(`v2/story/${story.id}`);
      if (res.data.status) {
        dispatch(updateUser());
      } else {
        throw new Error('delete story error');
      }
      setSuccess(true);
      setloading(false);
    } catch (error) {
      setloading(false);
      setError(true);
    }
  };

  const onClose = () => {
    pauseAction('play');
    hideModal();
  };

  const onCloseAll = () => {
    closeAction();
    hideModal();
  };

  if (error) {
    return (
      <RenderModal
        title={t('features:stories.delete.modal.title')}
        buttonClose
        onClose={() => pauseAction('play')}
      >
        <div className={styles.reportReasons}>
          <p>{t('main:errors.somethingWentWrong')}</p>
          <br />
          <Button onClose={onClose} name="btn">
            {t('main:main.OK')}
          </Button>
        </div>
      </RenderModal>
    );
  }

  if (loading) {
    return (
      <RenderModal
        title={t('features:stories.delete.modal.title')}
        buttonClose
        onClose={() => pauseAction('play')}
      >
        <LoaderRelative />
      </RenderModal>
    );
  }

  if (success) {
    return (
      <RenderModal
        title={t('features:stories.delete.modal.title')}
        onClose={() => closeAction()}
        buttonClose
      >
        <div className={styles.reportReasons}>
          <p>{t('features:stories.delete.modal.success')}</p>
          <br />
          <Button onClick={onCloseAll} name="btn">
            {t('main:main.OK')}
          </Button>
        </div>
      </RenderModal>
    );
  }

  return (
    <RenderModal
      title={t('features:stories.delete.modal.title')}
      buttonClose
      onClose={() => pauseAction('play')}
      footer={
        <>
          <Button onClick={onClose} name="btn-grey" style={{ width: '48%' }}>
            {t('main:main.Cancel')}
          </Button>
          <Button onClick={onDelete} name="btn" style={{ width: '48%' }}>
            {t('main:main.Delete')}
          </Button>
        </>
      }
    >
      <p className={styles.copy}>
        {t('features:stories.delete.modal.confirmText')}
      </p>
    </RenderModal>
  );
};

const ReportStoryModal = ({
  modalContext: { RenderModal, hideModal },
  profileUser,
  pauseAction,
  story,
}) => {
  const [success, setSuccess] = useState(false);
  const [loading, setloading] = useState(false);
  const [error, setError] = useState(false);
  const reportReasons = useSelector(selectReportReasons);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onReport = async (values) => {
    setloading(true);

    const url = `v2/story/${story.id}/report`;
    const payload = {
      object_id: story.id,
      object_type: 3,
      reasons: values.reasons,
      description: values.description,
    };

    try {
      const res = await api.post(url, payload);
      if (!res.data.status) {
        throw new Error('report user error');
      }
      setSuccess(true);
      setloading(false);
    } catch (error) {
      setError(true);
      setloading(false);
    }
  };

  useEffect(() => {
    if (isEmpty(reportReasons)) {
      dispatch(getReportReasons());
    }
  }, [dispatch, reportReasons]);

  const onClose = () => {
    pauseAction('play');
    hideModal();
  };

  if (error) {
    return (
      <RenderModal
        title={t('features:blockReport.reportMember')}
        onClose={() => pauseAction('play')}
        buttonClose
      >
        <div className={styles.reportReasons}>
          <p> {t('features:blockReport.error')}</p>
          <br />
          <Button onClick={onClose} name="btn">
            {t('features:blockReport.OK')}
          </Button>
        </div>
      </RenderModal>
    );
  }

  if (loading) {
    return (
      <RenderModal
        title={t('features:blockReport.reportMember')}
        onClose={() => pauseAction('play')}
        buttonClose
      >
        <LoaderRelative />
      </RenderModal>
    );
  }

  if (success) {
    return (
      <RenderModal
        title={t('features:blockReport.reportMember')}
        onClose={() => pauseAction('play')}
        buttonClose
      >
        <div className={styles.reportReasons}>
          <p>
            {t('features:blockReport.successReport', {
              userName: profileUser.name,
            })}
          </p>
          <br />
          <Button onClick={onClose} name="btn">
            OK
          </Button>
        </div>
      </RenderModal>
    );
  }

  return (
    <RenderModal
      title={t('features:blockReport.reportMember')}
      onClose={() => pauseAction('play')}
      buttonClose
    >
      <p className={styles.copy}>
        {t('features:blockReport.reportReason', { userName: profileUser.name })}
      </p>
      <div className={styles.reportReasons}>
        <Formik
          initialValues={{ reasons: [], description: '' }}
          validationSchema={Yup.object().shape({
            reasons: Yup.array()
              .min(1, t('components:validation.required'))
              .required(t('components:validation.required')),
            description: Yup.string().required(
              t('components:validation.required')
            ),
          })}
          onSubmit={async (values) => {
            await onReport(values);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormRow>
                <ReportReasonsSelect
                  typeName="reasons"
                  options={reportReasons}
                  withLabel
                  required
                />
              </FormRow>
              <FormRow>
                <TextArea
                  placeholder={t('features:blockReportReason.writeReason')}
                  name="description"
                  label={t('features:blockReportReason.commentLabel')}
                  required
                ></TextArea>
              </FormRow>

              <div className={styles.customReasonBtns}>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  name="btn"
                  fullWidth
                  size="xl"
                >
                  {t('features:blockReport.Report')}
                </Button>
                <Button
                  onClick={() => {
                    hideModal();
                    onClose();
                  }}
                  type="button"
                  name="btn-grey"
                  fullWidth
                  size="xl"
                >
                  {t('main:main.Cancel')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </RenderModal>
  );
};

export {
  BlockUserModal,
  ReportStoryModal,
  DeleteStoryModal,
  StoryInsightsModal,
};
