import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import DatingSidebar from './dating/DatingSidebar';
import SuggestionsList from './suggestions/SuggestionsList';
import { selectCurrentProfile } from './profileSlice';
import {
  setShowSuggestions,
  resetShowSuggestions,
} from 'features/user/suggestions/suggestionsSlice';

const ProfileSidebar = ({ profile_id }) => {
  const dispatch = useDispatch();
  const { dating } = useSelector(selectCurrentProfile);
  const hasApplications = false;
  //const hasApplications = dating.applications && dating.applications.total > 0;

  useEffect(() => {
    dispatch(setShowSuggestions());
    return () => dispatch(resetShowSuggestions());
  }, [dispatch]);

  if (!hasApplications && isEmpty(dating.episode))
    return <SuggestionsList profile_id={profile_id} />;

  return (
    <DatingSidebar
      applications={dating.applications.data}
      episode={dating.episode}
    />
  );
};

ProfileSidebar.propTypes = {
  profile_id: PropTypes.string.isRequired,
};

export default ProfileSidebar;
