import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';

import { useWindowWidthLessThan } from 'hooks';
import { LoaderInline } from 'components/layout/loader';
import ProfileCard from 'components/profileCard/ProfileCard';
import LoadMoreTrigger from 'components/loadMore/LoadMoreTrigger';

import {
  getSuggestions,
  getSuggestionsMore,
  selectSuggestions,
  selectSuggestionsNext,
  selectSuggestionsShow,
  cleanSuggestions,
} from './suggestionsSlice';
import styles from './suggestions.module.css';

const SuggestionsList = ({ showHeading, profile_id }) => {
  const dispatch = useDispatch();
  const suggestionsList = useSelector(selectSuggestions);
  const next = useSelector(selectSuggestionsNext);
  const show = useSelector(selectSuggestionsShow);
  const isMobile = useWindowWidthLessThan(992);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getSuggestions({ id: profile_id }));
    return () => dispatch(cleanSuggestions());
  }, [dispatch, profile_id]);

  if (!show && isMobile) {
    return null;
  } else if (isEmpty(suggestionsList)) {
    return <LoaderInline />;
  }

  const fetchNext = () => {
    if (!isEmpty(next)) {
      dispatch(getSuggestionsMore(next));
    }
  };

  return (
    <div className={cx(styles.wrapper, { [styles.profile]: profile_id })}>
      {showHeading && <h2>{t('features:episodes.Suggestions')}</h2>}
      <div className={styles.listWrapper}>
        {suggestionsList.map((user, i) => {
          return <ProfileCard key={user.id} profile={user} suggestions />;
        })}
        {isMobile && (
          <LoadMoreTrigger getMore={fetchNext} hasMore={!isEmpty(next)} />
        )}
      </div>
    </div>
  );
};

SuggestionsList.propTypes = {
  showHeading: PropTypes.bool,
  profile_id: PropTypes.string,
};

SuggestionsList.defaultProps = {
  showHeading: true,
  profile_id: null,
};

export default SuggestionsList;
