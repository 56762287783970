import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectClubBooking, selectCountries, setShowClubBooking} from "../../../app/miscSlice";
import {useModal} from "../../../components/modal/useModal";
import styles from "./clubBooking.module.css";
import Button from "../../../components/buttons/Button";
import {IconClose} from "../../../assets/icons";
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {FormFieldError, FormRow} from "../../../components/forms";
import cx from "classnames";
import formStyles from "../../../components/forms/forms.module.css";
import {useTranslation} from "react-i18next";
import CountryPhonePrefix from "../../../components/forms/countryPhonePrefixes";
import {unwrapResult} from "@reduxjs/toolkit";
import {trackEvent} from "../../../helpers/tracking";
import {toast} from "react-toastify";
import {clubBooking} from "./clubsSlice";
import {selectUserInfo} from "../../auth/authSlice";
import {useHistory} from "react-router-dom";
import {parseSelectOptions} from "../../../helpers/parseSelectOptions";

function ClubBooking({club, latest}) {
    // Initialize state for form fields
    const {RenderModal, showModal, hideModal} = useModal();
    const dispatch = useDispatch();
    const history = useHistory();
    const showClubBooking = useSelector(selectClubBooking);
    const {t} = useTranslation();
    const countries = useSelector(selectCountries);
    const [thankYouScreen, setThankYouScreen] = useState(false);
    const user = useSelector(selectUserInfo);

    //const [peopleMale, setPeopleMale] = useState(0);
    //const [peopleMaleSt, setPeopleMaleSt] = useState(0);
    //const [peopleFeMaleSt, setPeopleFeMaleSt] = useState(0);


    const currentDate = new Date();
    const maxDate = new Date(); // Get the current date
    maxDate.setFullYear(maxDate.getFullYear() - 18); // Subtract 18 years from the current date


    /*****/
    const numberArray = Array.from({length: 11}, (_, index) => ({
        id: index + 1,
        name: index > 9 ? '10 +' : (index + 1).toString(),
    }));
    const parsedOptionsNumbers = parseSelectOptions(numberArray, 'id', 'name');

    /*const daysArray = Array.from({length: 31}, (_, index) => ({
        id: index + 1,
        name: (index + 1).toString(),
    }));
    const parsedOptionsDays = parseSelectOptions(daysArray, 'id', 'name');


    const monthsArray = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ].map((month, index) => ({
        id: index + 1,
        name: month,
    }));
    const parsedOptionsMonths = parseSelectOptions(monthsArray, 'id', 'name');

    const currentYear = new Date().getFullYear();
    const yearsArray = Array.from({length: 100}, (_, index) => ({
        id: currentYear - index,
        name: (currentYear - index).toString(),
    }));
    const parsedOptionsYears = parseSelectOptions(yearsArray, 'id', 'name');*/
    /***** /


     /*const [formData, setFormData] = useState({
        club: club,
        firstname: '',
        surname: '',
        birthdate: '',
        phone: '',
        email: '',
        peopleMale: '',
        peopleFeMale: '',
        bookingDate: '',
        description: '',
    });*/

    const handlePastBooking = (key) => {
        history.push('/my-event-requests')
    }

    // Function to handle the change in the select input
    /*const handleSelectChangeMale = (e) => {
        const selectedValue = parseInt(e.target.value);
        setPeopleMale(selectedValue);
    };*/

    // Function to handle the change in the select input
    /*const handleSelectChangeFeMale = (e) => {
        const selectedValue = parseInt(e.target.value);
        setPeopleFeMale(selectedValue);
    };*/

    const getPrefilledValue = (key) => {
        if (latest && Object.keys(latest).length > 0 && key in latest) {
            return latest[key];
        } else if (user && Object.keys(user).length > 0 && key in user) {
            return user[key];
        } else {
            return ''; // You can choose to return a different value if needed
        }
    };

    //console.log(getPrefilledValue('date_birth'));
    const formConfig = {
        schema: Yup.object().shape({
            firstname: Yup.string().required('First Name is required'),
            surname: Yup.string().required('Last Name is required'),
            //birthdate: Yup.date().required('Date of birth is required'),
            phone: Yup.string().required('Phone is required'),
            //email: Yup.string().email('Invalid email').required('Email is required'),
            peopleMale: Yup.number().required('Male is required').typeError('Male must be a number'),
            peopleFeMale: Yup.number().required('Female is required').typeError('Female must be a number'),
            bookingDate: Yup.date().required('Booking Date is required'),
            //bookingDay: Yup.string().required('Day is required'),
            //bookingMonth: Yup.string().required('Month is required'),
            //bookingYear: Yup.string().required('Year is required'),
            description: Yup.string(),
            otherPeopleMale: Yup.number().typeError('Male must be a number'),
            otherPeopleFeMale: Yup.number().typeError('Female must be a number'),
        }),
        initialValues: {
            club: club,
            firstname: getPrefilledValue('firstname'),
            surname: getPrefilledValue('surname'),
            //birthdate: getPrefilledValue('date_birth'),
            countryID: getPrefilledValue('countryID'),
            phone: getPrefilledValue('phone'),
            //email: getPrefilledValue('email'),
            peopleMale: '',
            peopleFeMale: '',
            otherPeopleMale: '',
            otherPeopleFeMale: '',
            numGuests: '',
            bookingDate: '',
            //bookingDay: '',
            //bookingMonth: '',
            //bookingYear: '',
            description: '',
        }
    };


    useEffect(() => {
        if (showClubBooking) {
            showModal();
        }
        return () => dispatch(setShowClubBooking(false));
    }, [dispatch, showClubBooking, showModal]);


    const handleClose = () => {
        hideModal();
        dispatch(setShowClubBooking(false));
    };


    // Handle form field changes
    /*const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };*/

    if (thankYouScreen) {
        return (
            <div>
                {/*<EpisodesListHeader />*/}
                <RenderModal
                    customStyles={{
                        maxHeight: '90vh',
                        maxWidth: 650,
                        margin: 0,
                        overflowY: 'scroll',
                        padding: 0,
                    }}
                    bareInner
                    buttonClose
                    onClose={() => dispatch(setShowClubBooking(false))}
                >
                    <div className={styles.wrapper}>
                        <Button name="btn-bare" className={styles.close} onClick={handleClose}>
                            <IconClose/>
                        </Button>
                        <div className={styles.box}>
                            <div className={styles.header}>
                                <span
                                    className={styles.title}>{t('features:club.thankYou', 'Thank you for booking')}</span>
                                <span
                                    className={styles.longText}>{t('features:club.thankYou', 'We\'ve received your booking request')}</span>
                            </div>
                            <Button
                                /*loading={signupState.loading}
                                disabled={signupState.loading}*/
                                size="xl"
                                fullWidth
                                name="btn-gradient"
                                type="button"
                                onClick={handleClose}
                            >
                                {t('main:main.close', 'close')}
                            </Button>
                        </div>
                    </div>
                </RenderModal>
            </div>
        );
    }

    const onSubmitForm = async (data) => {

        const payload = {
            club_id: club,
            firstname: data.firstname,
            surname: data.surname,
            date_birth: data.birthdate,
            phone: data.phone,
            //email: data.email,
            people_male: data.peopleMale > 10 ? data.otherPeopleMale : data.peopleMale,
            people_female: data.peopleFeMale  > 10 ? data.otherPeopleFeMale : data.peopleFeMale,
            date_booking: data.bookingDate,
            description: data.description,
        };

        try {
            const resultAction = await dispatch(clubBooking(payload));
            const response = unwrapResult(resultAction);
            if (response.status) {
                trackEvent({
                    category: 'club',
                    action: 'booking',
                    label: 'create_booking',
                });
                setThankYouScreen(true);
                /*history.push('/register/send-email-confirm', {
                    email: response.data.email,
                    token: response.data.token,
                });*/
            } else {
                throw new Error();
            }
        } catch (err) {
            //console.log(err);
            toast.error(t('main:errors.somethingWentWrong'));
        }
    };


    return (
        <RenderModal
            customStyles={{
                maxHeight: '90vh',
                maxWidth: 650,
                margin: 0,
                overflowY: 'scroll',
                padding: 0,
            }}
            bareInner
            buttonClose
            onClose={() => dispatch(setShowClubBooking(false))}
        >
            <div className={styles.wrapper}>
                <Button name="btn-bare" className={styles.close} onClick={handleClose}>
                    <IconClose/>
                </Button>
                <div className={styles.box}>
                    <div className={styles.header}>
                        <span
                            className={styles.title}>{t('features:club.askForVIPTable', 'Ask for your VIP table')}</span>
                        <span
                            className={styles.longText}>{t('features:club.askForVIPTableSubTitle', 'Contact your Concierge for last minute booking and further questions')}</span>
                    </div>

                    <Formik
                        initialValues={formConfig.initialValues}
                        validationSchema={formConfig.schema}
                        onSubmit={async (data) => {
                            await onSubmitForm(data);
                            /*if (res.fieldErorrs) {
                                actions.setErrors(res.errors);
                            }*/
                        }}
                    >
                        {({isSubmitting, isValid, values, setFieldValue}) => (
                            <Form className={styles.form}>
                                <div className={styles.twoColumn}>
                                    {latest && latest.firstname ? '' :
                                        <FormRow style={{position: 'relative'}}>
                                            <FormFieldError fieldName="firstname"/>
                                            <Field
                                                className={formStyles.input}
                                                placeholder={t('main:label.firstname', 'Firstname*')}
                                                type="text"
                                                name="firstname"
                                            />
                                        </FormRow>
                                    }
                                    {latest && latest.surname ? '' :
                                        <FormRow style={{position: 'relative'}}>
                                            <FormFieldError fieldName="surname"/>
                                            <Field
                                                className={formStyles.input}
                                                placeholder={t('main:label.surname', 'Lastname*')}
                                                type="text"
                                                name="surname"
                                            />
                                        </FormRow>
                                    }
                                </div>
                                {/*{latest && latest.date_birth   ? '' :
                                    <FormRow style={{position: 'relative'}}>

                                        <label
                                            className={cx(formStyles.formLabel, formStyles.required)}
                                            htmlFor="birthdate"
                                        >
                                            {t('main:label.birthdate', 'Date of Birth')}
                                        </label>
                                        <FormFieldError fieldName="birthdate"/>
                                        <Field
                                            className={formStyles.input}
                                            placeholder={t('main:label.birthdate', 'Date of Birth')}
                                            type="date"
                                            max={maxDate.toISOString().split('T')[0]} // Set the maximum date allowed
                                            name="birthdate"
                                        />
                                    </FormRow>
                                }*/}
                                {latest && latest.phone ? '' :
                                    <FormRow style={{position: 'relative'}}>
                                        <CountryPhonePrefix
                                            countries={countries}
                                            club={true}
                                            onChange={(val) => setFieldValue('countryID', Number(val))}
                                        />
                                        {/*<label
                                            className={cx(formStyles.formLabel, formStyles.required)}
                                            htmlFor="phone"
                                        >
                                            {t('main:label.phone', 'Phone')}
                                        </label>*/}
                                        <FormFieldError fieldName="phone"/>
                                        <Field
                                            className={`${formStyles.input} ${formStyles.phone}`}
                                            placeholder={t('main:label.phone', 'Phone*')}
                                            type="tel"
                                            name="phone"
                                        />
                                    </FormRow>
                                }
                                {/*<FormRow style={{position: 'relative'}} isModal={true}>
                                    <FormFieldError fieldName="email"/>
                                    <Field
                                        className={formStyles.input}
                                        placeholder={t('main:label.Email')}
                                        type="text"
                                        name="email"
                                    />
                                </FormRow>*/}
                                <div className={styles.twoColumn}>
                                    <FormRow style={{position: 'relative'}} isModal={true}>
                                        <label
                                            className={cx(formStyles.formLabel, formStyles.required)}
                                            htmlFor="people_male"
                                        >
                                            {t('main:label.Male', 'Male')}
                                        </label>
                                        <FormFieldError fieldName="peopleMale"/>

                                        <Field as="select" name={'peopleMale'} className={styles.wfull}>
                                            <option key={0} value="0">
                                                {t('main:label.peopleMale', 'Male')}
                                            </option>
                                            {parsedOptionsNumbers.map(function (item, i) {
                                                return (
                                                    <option key={i + 1} value={item.slug}>
                                                        {item.name}
                                                    </option>
                                                );
                                            })}
                                        </Field>

                                        {values.peopleMale > 10 &&
                                        <Field
                                            className={formStyles.input}
                                            placeholder={t('main:label.peopleMale', 'Enter number')}
                                            type="text"
                                            name="otherPeopleMale"
                                            style={{marginTop: '5px'}}
                                        />
                                        }

                                    </FormRow>
                                    <FormRow style={{position: 'relative'}} isModal={true}>
                                        <label
                                            className={cx(formStyles.formLabel, formStyles.required)}
                                            htmlFor="people_male"
                                        >
                                            {t('main:label.Female', 'Female')}
                                        </label>
                                        <FormFieldError fieldName="peopleFeMale"/>
                                        <Field as="select" name={'peopleFeMale'} className={styles.wfull}>
                                            <option key={0} value="0">
                                                {t('main:label.peopleFemale', 'Female')}
                                            </option>
                                            {parsedOptionsNumbers.map(function (item, i) {
                                                return (
                                                    <option key={i + 1} value={item.slug}>
                                                        {item.name}
                                                    </option>
                                                );
                                            })}
                                        </Field>

                                        {values.peopleFeMale > 10 &&
                                        <Field
                                            className={formStyles.input}
                                            placeholder={t('main:label.peopleFeMale', 'Enter number')}
                                            type="text"
                                            name="otherPeopleFeMale"
                                            style={{marginTop: '5px'}}
                                        />
                                        }
                                    </FormRow>
                                </div>
                                <FormRow style={{position: 'relative'}} isModal={true}>
                                    <label
                                        className={cx(formStyles.formLabel, formStyles.required)}
                                        htmlFor="bookingDate"
                                    >
                                        {t('main:label.bookingDate', 'Booking Date')}
                                    </label>
                                    <FormFieldError fieldName="bookingDate"/>
                                    <Field
                                        className={formStyles.input}
                                        placeholder={t('main:label.bookingDate', 'Booking Date')}
                                        type="date"
                                        min={currentDate.toISOString().split('T')[0]} // Set the minimum date allowed (current date)
                                        name="bookingDate"
                                    />
                                </FormRow>
                                {/*<FormRow style={{position: 'relative'}} isModal={true}>
                                    <div className={styles.threeColumn}>
                                        <Field as="select" name={'day'} className={styles.wfull}>
                                            <option key={0} value="">
                                                {t('components:inputSelect.day', 'Day')}
                                            </option>
                                            {parsedOptionsDays.map(function (item, i) {
                                                return (
                                                    <option key={i + 1} value={item.slug}>
                                                        {item.name}
                                                    </option>
                                                );
                                            })}
                                        </Field>
                                        <Field as="select" name={'day'} className={styles.wfull}>
                                            <option key={0} value="">
                                                {t('components:inputSelect.month', 'Month')}
                                            </option>
                                            {parsedOptionsMonths.map(function (item, i) {
                                                return (
                                                    <option key={i + 1} value={item.slug}>
                                                        {item.name}
                                                    </option>
                                                );
                                            })}
                                        </Field>
                                        <Field as="select" name={'day'} className={styles.wfull}>
                                            <option key={0} value="">
                                                {t('components:inputSelect.year', 'Year')}
                                            </option>
                                            {parsedOptionsYears.map(function (item, i) {
                                                return (
                                                    <option key={i + 1} value={item.slug}>
                                                        {item.name}
                                                    </option>
                                                );
                                            })}
                                        </Field>
                                    </div>
                                </FormRow>*/}
                                <FormRow style={{position: 'relative'}} isModal={true}>
                                    {/*<label
                                    className={cx(formStyles.formLabel, formStyles.required)}
                                    htmlFor="people_male"
                                >
                                    {t('main:label.Email')}
                                </label>*/}
                                    <FormFieldError fieldName="description"/>
                                    <Field
                                        className={formStyles.input}
                                        placeholder={t('main:label.description', 'Special Request')}
                                        as="textarea"
                                        name="description"
                                    />
                                </FormRow>
                                <FormRow isModal={true}>
                                    <Button
                                        /*loading={signupState.loading}
                                        disabled={signupState.loading}*/
                                        size="xl"
                                        fullWidth
                                        name="btn-gradient"
                                        type="submit"
                                        loading={isSubmitting}
                                        disabled={isSubmitting}
                                    >
                                        {t('main:main.Send', 'Send')}
                                    </Button>
                                </FormRow>
                                {latest &&
                                <div className={styles.pastbooking} onClick={handlePastBooking}>
                                    View Booking Request(s)
                                </div>
                                }
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </RenderModal>
    );
}

export default ClubBooking;
