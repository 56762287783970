import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getAvatarSrc, checkImageSrc } from 'helpers/s3media';
import styles from './avatar.module.css';
import cx from 'classnames';

const Avatar = ({
  size,
  image,
  alt,
  border,
  circle,
  radius,
  city,
  online,
  plus,
  gender,
  onClick,
  noAvatarCallBack,
  className,
  style,
  wrapperStyle,
  wrapperClassName,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [imageDefaultSrc, setImageDefaultSrc] = useState(null);

  useEffect(() => {
    const requestSize = size > 110 ? 220 : 110;
    const image_src =
      typeof image === 'string'
        ? checkImageSrc(image, requestSize, gender)
        : getAvatarSrc(image, requestSize, gender);
    const srcDefault = getAvatarSrc(null, requestSize, gender);

    setError(false);
    setImageSrc(image_src);
    setImageDefaultSrc(srcDefault);
  }, [image, gender, size]);

  const HandleImageError = () => {
    if (!error) {
      noAvatarCallBack();
      setError(true);
      setImageSrc(imageDefaultSrc);
    }
  };

  const wrapperInlineStyle = {
    width: `${size}px`,
    height: `${size}px`,
    ...wrapperStyle,
  };

  const wrapperCls = cx(styles.wrapper, {
    [styles.cursor]: onClick && image && !error,
    [styles.circle]: circle,
    [styles.radius]: radius,
    [styles.city]: city,
    [wrapperClassName]: wrapperClassName,
  });

  const additionalCls = cx({
    [styles.border]: border,
    [styles.loaded]: loaded,
    [className]: className,
  });

  const onlineCls = cx(styles.online, {
    [styles.xxl]: size > 140,
    [styles.xl]: size <= 140 && size > 110,
    [styles.l]: size <= 110 && size > 90,
    [styles.m]: size <= 90 && size > 70,
    [styles.s]: size <= 70 && size > 50,
    [styles.xs]: size <= 50,
  });

  const plusCls = cx(styles.plus, {
    [styles.l]: size > 140,
    [styles.m]: size <= 140 && size > 90,
    [styles.s]: size <= 90,
  });

  return (
    <div className={wrapperCls} style={wrapperInlineStyle} onClick={onClick}>
      {imageSrc && (
        <img
          style={{ width: `${size}px`, height: `${size}px`, ...style }}
          className={cx(styles.avatar, additionalCls)}
          src={imageSrc}
          alt={alt}
          onLoad={() => setLoaded(true)}
          onError={() => HandleImageError()}
        />
      )}
      {online && circle && <div className={onlineCls}></div>}
      {!online && circle && plus && <div className={plusCls}>+</div>}
    </div>
  );
};

Avatar.propTypes = {
  size: PropTypes.number,
  alt: PropTypes.string,
  border: PropTypes.bool,
  circle: PropTypes.bool,
  radius: PropTypes.bool,
  city: PropTypes.bool,
  online: PropTypes.bool,
  plus: PropTypes.bool,
  gender: PropTypes.string,
  onClick: PropTypes.func,
  noAvatarCallBack: PropTypes.func,
  image: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  className: PropTypes.string,
};

Avatar.defaultProps = {
  noAvatarCallBack: () => {},
  size: 110,
  circle: false,
  radius: false,
  city: false,
  online: false,
  plus: false,
  border: false,
  gender: 'male',
  alt: '',
};

export default Avatar;
