import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Row, Col } from 'components/layout/grid';
import cx from 'classnames';

import { getLayoutImageSrc } from 'helpers/s3media';
import { AuthLayout } from 'components/layout/layouts/Layout';
import NavTabs from 'components/navTabs';
import {
  ConfirmEmail,
  ConfirmEmailLanding,
  PasswordRecoveryLanding,
} from 'features/auth/recoverPassword';
import Register from 'features/auth/registerForm/Register';
import { AfterRegister } from 'features/auth';
import { AuthLayoutContext } from 'components/layout/layouts/Context';
import { authLeft } from 'assets/default';
import styles from 'features/auth/auth.module.css';

const RegisterScreen = ({ afterRegister }) => {
  let { path } = useRouteMatch();
  const { t } = useTranslation();

  const RegisterScreenWrapper = ({ children, screenName, screenSubName }) => {
    const { smallView, tabItems } = useContext(AuthLayoutContext);
    return (
      <Row className={styles.authWrapper}>
        <Col className={styles.left} xl={6} hidden="xs" visible="xl">
          <img src={getLayoutImageSrc(authLeft)} alt="auth" />
        </Col>
        <Col className={styles.right} xl={6}>
          <Row className={styles.formRow} center="xs" middle="xs" start="xl">
            <Col xs={12} className={styles.formCol}>
              <div className={styles.register}>
                {!smallView && <NavTabs items={tabItems} />}
                <div className={styles.textRow}>
                  <h1>
                    {screenName}
                    {screenSubName && <span>{screenSubName}</span>}
                  </h1>
                </div>
                {children}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const SubScreenWrapper = ({ children, afterRegister }) => {
    return (
      <Row
        className={cx(styles.authWrapper, styles.subScreen)}
        center="xs"
        middle="xs"
      >
        <Col className={styles.middle} xs={12}>
          <Row className={styles.formRow} center="xs" middle="xs">
            <Col xs={12} className={afterRegister ? styles.formRowInner : null}>
              {children}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <AuthLayout afterRegister={afterRegister} loginPage={true}>
      {afterRegister ? (
        <SubScreenWrapper afterRegister>
          <AfterRegister />
        </SubScreenWrapper>
      ) : (
        <Switch>
          <Route path={`${path}/send-email-confirm`}>
            <SubScreenWrapper>
              <ConfirmEmail />
            </SubScreenWrapper>
          </Route>
          <Route path={`${path}/email-link-confirm`}>
            <SubScreenWrapper>
              <ConfirmEmailLanding />
            </SubScreenWrapper>
          </Route>
          <Route path={`${path}/email-link-recover`}>
            <SubScreenWrapper>
              <PasswordRecoveryLanding />
            </SubScreenWrapper>
          </Route>
          <Route path={path}>
            <RegisterScreenWrapper
              screenName={t('features:auth.registerScreens.header')}
              screenSubName={t('features:auth.registerScreens.subHeader')}
              children={<Register />}
            />
          </Route>
        </Switch>
      )}
    </AuthLayout>
  );
};

export default RegisterScreen;
