import React from 'react';
import PropTypes from 'prop-types';
import { formatDistanceStrict } from 'date-fns';
import {de, enGB} from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

import { ProfileBadges } from 'components/layout/badges/Badges';
import Avatar from 'components/avatar/Avatar';
import styles from '../activityDetail.module.css';
import cx from "classnames";
import CountryFlag from "../../../../components/CountryFlag";

const UserBar = ({ actor, timestamp, onClickUser, onClickDate }) => {
  const { i18n } = useTranslation();
  const lang = i18n.language === 'de' ? de : enGB;
  let timeAgo = formatDistanceStrict(new Date(timestamp), new Date(), {
    addSuffix: true,
    locale: lang,
  });
  const location = `${actor.city}`;
  return (
    <div className={styles.bar}>
      <Avatar
        size={50}
        circle
        onClick={onClickUser}
        online={actor.online}
        image={actor.avatar}
        gender={actor.gender}
      />
      <div className={`${styles.details} ${onClickUser ? styles.cursor : ''}`}>
        <div className={styles.username} onClick={onClickUser}>
          <span className={styles.nameLine}>{actor.name}</span>
          {actor.age && <span className={styles.age}>{actor.age}</span>}
          <ProfileBadges
            small
            verified={actor.verified}
            dating={actor.dating_enabled}
            phone={actor.verified_phone}
          />
        </div>
        {actor.city ? (
          <div className={styles.subtitle}>
            {/*<IconMapMark className={styles.iconSmall} />*/}
            {/*<div className={styles.cityname}>*/}
                <CountryFlag countryCode={actor.country} className={cx(styles.deatilIcon, styles.location)}/>
                <span>{location}</span>
            {/*</div>*/}
          </div>
        ) : null}
      </div>
      <div
        className={`${styles.extra} ${onClickDate ? styles.cursor : ''}`}
        onClick={onClickDate}
      >
        <time dateTime={timestamp} title={timestamp}>
          {timeAgo}
        </time>
      </div>
    </div>
  );
};

UserBar.propTypes = {
  actor: PropTypes.object.isRequired,
  onClickUser: PropTypes.func,
  onClickDate: PropTypes.func,
  timestamp: PropTypes.string.isRequired,
};

export default UserBar;
