import React, { useEffect } from 'react';
import styles from './../../stories.module.css';

const Default = ({ story, action, isActive }) => {
  useEffect(() => {
    if (isActive) {
      action('play');
    }
  }, [isActive, action]);

  return (
    <div className={styles.storyContent}>
      <p className={styles.textContainer}>This story could not be loaded.</p>
    </div>
  );
};

export default Default;
