import React from 'react';
import { useTranslation } from 'react-i18next';
import { isFirefox } from 'react-device-detect';
import {
  createDefaultImageReader,
  createDefaultImageWriter,
  setPlugins,
  plugin_crop,
  plugin_finetune,
  plugin_annotate,
  plugin_filter,
  plugin_sticker,
  createDefaultFontFamilyOptions,
  createMarkupEditorFontFamilyOptions,
  createMarkupEditorShapeStyleControls,
  createMarkupEditorToolbar,
  createMarkupEditorColorOptions,
  createMarkupEditorToolStyles,
  createMarkupEditorToolStyle,
  markup_editor_defaults,
  plugin_finetune_defaults,
  plugin_filter_defaults,
  locale_en_gb,
  plugin_crop_locale_en_gb,
  plugin_finetune_locale_en_gb,
  plugin_filter_locale_en_gb,
  plugin_annotate_locale_en_gb,
  plugin_sticker_locale_en_gb,
  markup_editor_locale_en_gb,
} from 'pintura';
import { PinturaEditorModal } from 'react-pintura';
import locale_de_DE, {
  MarkupEditor as markup_editor_locale_de_DE,
} from 'assets/extra/pintura/locale/de_DE/core/de_DE.js';
import plugin_crop_locale_de_DE from 'assets/extra/pintura/locale/de_DE/crop/de_DE.js';
import plugin_finetune_locale_de_DE from 'assets/extra/pintura/locale/de_DE/finetune/de_DE.js';
import plugin_filter_locale_de_DE from 'assets/extra/pintura/locale/de_DE/filter/de_DE.js';
import plugin_annotate_locale_de_DE from 'assets/extra/pintura/locale/de_DE/annotate/de_DE.js';
import plugin_sticker_locale_de_DE from 'assets/extra/pintura/locale/de_DE/sticker/de_DE.js';

import amorSticker from 'assets/stickers/amor.svg';
import giftSticker from 'assets/stickers/gift.svg';
import heartSticker from 'assets/stickers/heart.svg';
import letterSticker from 'assets/stickers/letter.svg';
import roseSticker from 'assets/stickers/rose.svg';
import wineSticker from 'assets/stickers/wine.svg';

const ImageEditor = ({ image, imageWriter, onHide }) => {
  setPlugins(
    plugin_crop,
    plugin_finetune,
    plugin_annotate,
    plugin_filter,
    plugin_sticker
  );
  const { i18n } = useTranslation();
  const locale =
    i18n.language === 'de'
      ? {
          ...locale_de_DE,
          ...plugin_crop_locale_de_DE,
          ...plugin_finetune_locale_de_DE,
          ...plugin_filter_locale_de_DE,
          ...plugin_annotate_locale_de_DE,
          ...plugin_sticker_locale_de_DE,
          ...markup_editor_locale_de_DE,
        }
      : {
          ...locale_en_gb,
          ...plugin_crop_locale_en_gb,
          ...plugin_finetune_locale_en_gb,
          ...plugin_filter_locale_en_gb,
          ...plugin_annotate_locale_en_gb,
          ...plugin_sticker_locale_en_gb,
          ...markup_editor_locale_en_gb,
        };

  const createImageWriter = createDefaultImageWriter(imageWriter);
  const defaultFontFamilies = createDefaultFontFamilyOptions();

  const stickers = [
    [
      'Emoji',
      [
        '🥰',
        '😘',
        '😍',
        '🤪',
        '😄',
        '👍',
        '🥰',
        '🍑',
        '🍆',
        '❤️',
        '💯',
        '💓',
        '💦',
        '🐽',
        '🎉',
        '⭐',
        '🌞',
        '🌈',
        '🕶️',
      ],
    ],
  ];

  if (!isFirefox) {
    stickers.push([
      'Only VIP Stickers',
      [
        {
          src: amorSticker,
          width: 300,
          height: 300,
          alt: 'Number one',
        },
        {
          src: giftSticker,
          width: 300,
          height: 300,
          alt: 'Number one',
        },
        {
          src: heartSticker,
          width: 300,
          height: 300,
          alt: 'Number one',
        },
        {
          src: letterSticker,
          width: 300,
          height: 300,
          alt: 'Number one',
        },
        {
          src: roseSticker,
          width: 300,
          height: 300,
          alt: 'Number one',
        },
        {
          src: wineSticker,
          width: 300,
          height: 300,
          alt: 'Number one',
        },
      ],
    ]);
  }

  const editorConfig = {
    imageReader: createDefaultImageReader(),
    ...markup_editor_defaults,
    ...plugin_finetune_defaults,
    ...plugin_filter_defaults,
    imageCropMinSize: {
      width: 128,
      height: 128
    },
    imageWriter: createDefaultImageWriter(),
    imageCropAspectRatio: 16/9,
    cropSelectPresetFilter:'landscape',
    cropEnableFilterMatchAspectRatio:true,
    cropSelectPresetOptions : [
          [undefined, 'Custom'],
      [1, 'Square'],

  // shown when cropSelectPresetFilter is set to 'landscape'
  [2 / 1, '2:1'],
      [3 / 2, '3:2'],
      [4 / 3, '4:3'],
      [16 / 10, '16:10'],
      [16 / 9, '16:9'],

      // shown when cropSelectPresetFilter is set to 'portrait'
      [1 / 2, '1:2'],
      [2 / 3, '2:3'],
      [3 / 4, '3:4'],
      [10 / 16, '10:16'],
      [9 / 16, '9:16'],
],
    //shapePreprocessor: createDefaultShapePreprocessor(),
    /*imageCropAspectRatio: 3 / 1,
    cropInteractionFocus:'selection',
    cropSelectPresetOptions: [
      [
        'Crop',
        [
          [undefined, 'Custom'],
          [3 / 1, 'Landscape'],
        ],
      ],
      [
        'Size',
        [
          [[900, 300], 'Header Image'],
        ],
      ],
    ],/*
    imageCropAspectRatio: [
      [
        'Crop Aspect Ratios',
        [
          [undefined, 'Custom'],
          [1, 'Square'],
          [4 / 3, 'Landscape'],
          [3 / 4, 'Portrait'],
        ],
      ],
      [
        'Crop sizes',
        [
          [[180, 180], 'Profile Picture'],
          [[1200, 600], 'Header Image'],
          [[800, 400], 'Timeline Photo'],
        ],
      ],
    ],*/
    markupEditorToolbar: createMarkupEditorToolbar([
      'text',
      'line',
      'arrow',
      'rectangle',
      'ellipse',
      'preset',
    ]),
    markupEditorToolStyles: createMarkupEditorToolStyles({
      text: createMarkupEditorToolStyle('text', {
        fontFamily: 'Albert Sans',
        fontSize: '10%',
        color: [0.9019, 0.3411, 0.4196],
      }),
      line: createMarkupEditorToolStyle('line', {
        strokeWidth: '0.5%',
        strokeColor: [0.9019, 0.3411, 0.4196],
      }),
      arrow: createMarkupEditorToolStyle('line', {
        lineStart: 'none',
        lineEnd: 'arrow-solid',
        strokeColor: [0.9019, 0.3411, 0.4196],
        strokeWidth: '0.5%',
      }),
      rectangle: createMarkupEditorToolStyle('rectangle', {
        strokeColor: [0.9764, 0.8352, 0.8549],
        backgroundColor: [0.9019, 0.3411, 0.4196],
      }),
      ellipse: createMarkupEditorToolStyle('ellipse', {
        strokeColor: [0.9764, 0.8352, 0.8549],
        backgroundColor: [0.9019, 0.3411, 0.4196],
      }),
    }),
    markupEditorShapeStyleControls: createMarkupEditorShapeStyleControls({
      colorOptions: createMarkupEditorColorOptions({
        pinkLight: [0.9764, 0.8352, 0.8549],
        pinkDefault: [0.9019, 0.3411, 0.4196],
        pinkStrong: [0.796, 0.1725, 0.396],
        turqLight: [0.8431, 0.8941, 0.9137],
        turqDefault: [0.3764, 0.5764, 0.6588],
        turqStrong: [0.3019, 0.5098, 0.6],
      }),
      fontFamilyOptions: createMarkupEditorFontFamilyOptions([
        ['Albert Sans', 'Albert Sans'],
        ['Snell Roundhand', 'Brush Script MT'],
        ...defaultFontFamilies,
      ]),
    }),
    utils: ['crop', 'sticker', 'annotate', 'filter', 'finetune'],
    stickers: stickers,
    locale: locale,
  };

  return (
    <PinturaEditorModal
      {...editorConfig}
      imageWriter={createImageWriter}
      src={image}
      onHide={onHide}
      // onLoad={(res) => console.log('load modal image', res)}
    />
  );
};

export default ImageEditor;
