import { NavLink } from 'react-router-dom';
import { isBoolean } from 'lodash';
import { IconArrowLeft } from 'assets/icons';
import styles from './settings.module.css';

const SettingsLinksView = ({ options }) => {
  if (!options) return null;

  return (
    <div className={styles.linkWrapper}>
      {options.mainPage.map((item, i) => {
        if (isBoolean(item.condition) && !item.condition) return null;
        return (
          <NavLink className={styles.navItem} to={item.to} key={i}>
            <div className={styles.icon}>{item.icon()}</div>
            <div className={styles.text}>
              <h2>{item.title}</h2>
              <span>{item.text}</span>
            </div>
            <div className={styles.arrow}>
              <IconArrowLeft />
            </div>
          </NavLink>
        );
      })}
    </div>
  );
};

export default SettingsLinksView;
