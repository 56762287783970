import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import api from 'app/api';
import Button from 'components/buttons/Button';
import { FormRow, Input } from 'components/forms';
import { IconCheck } from 'assets/icons';
import { HeartLock } from 'assets/icons/auth';
import styles from './confirmEmail.module.css';

const PasswordRecoveryLanding = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const [hash, setHash] = useState(false);
  const [success, setSucces] = useState(false);
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, t('components:validation.tooShort'))
      .matches(/(?=.*[a-z])/, t('components:validation.lowerCase'))
      .matches(/(?=.*[A-Z])/, t('components:validation.upperCase'))
      .matches(/(?=.*\d)/, t('components:validation.includeDigit'))
      .required(t('components:validation.required')),
    password_confirmation: Yup.string()
      .required(t('components:validation.required'))
      .oneOf([Yup.ref('password'), null], t('components:validation.mustMatch')),
  });

  useEffect(() => {
    const param = new URLSearchParams(search);
    const paramVal = param.get('hash');
    if (!paramVal) {
      return;
    }
    setHash(paramVal);
  }, [search]);

  const onSubmit = async (values) => {
    const updatePayLoad = {
      hash: hash,
      ...values,
    };
    try {
      const res = await api.post(
        'v1/user/password/update/external',
        updatePayLoad
      );
      if (!res.data.status) throw new Error('Error recovering password');
      setSucces(true);
    } catch (error) {
      toast.error(t('main:errors.somethingWentWrong'));
      console.log(error);
    }
  };

  if (!hash) return null;
  if (success)
    return (
      <div>
        <div className={styles.container}>
          <div className={styles.icoBig}>
            <IconCheck />
          </div>
          <div className={styles.copy}>
            <h4>{t('main:main.Success')}</h4>
            <p>{t('features:auth.passwordRecovery.nowLogin')}</p>
          </div>
          <div className={styles.resend}>
            <Button
              onClick={() => push('/login')}
              fullWidth
              size="xl"
              name="btn-gradient"
              type="submit"
            >
              {t('main:main.Login')}
            </Button>
          </div>
        </div>
      </div>
    );

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.ico}>
          <HeartLock />
        </div>
        <div className={styles.copy}>
          <h4>{t('features:auth.registerScreens.passwordRecovery')}</h4>
          <p>{t('features:auth.passwordRecovery.setNewPass')}</p>
        </div>
        <hr />
        <div className={styles.form}>
          <Formik
            onSubmit={async (values) => {
              await onSubmit(values);
            }}
            validationSchema={validationSchema}
            initialValues={{
              password: '',
              password_confirmation: '',
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <FormRow>
                  <Input
                    type="password"
                    label={t('features:auth.passwordRecovery.newPassword')}
                    name="password"
                  />
                </FormRow>
                <FormRow>
                  <Input
                    type="password"
                    label={t(
                      'features:auth.passwordRecovery.newPasswordConfirm'
                    )}
                    name="password_confirmation"
                  />
                </FormRow>
                <FormRow style={{ textAlign: 'right', paddingTop: '20px' }}>
                  <Button
                    fullWidth
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    size="xl"
                    name="btn-gradient"
                    type="submit"
                  >
                    {t('main:main.Proceed')}
                  </Button>
                </FormRow>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default PasswordRecoveryLanding;
