import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
/*import isEmpty from 'lodash/isEmpty';
import {useTranslation} from 'react-i18next';


import {
    CommentList,
    CommentField,
    ReactionToggleIcon,
} from 'react-activity-feed';*/
import {selectUserInfo} from 'features/auth/authSlice';
//import {IconCommentsActive, IconComments} from 'assets/icons';
//import {avatarMale, avatarFemale} from 'assets/default';
/*import {getAvatarSrc} from 'helpers/s3media';

import likebuttonActive from 'assets/icons/like-active-pink.svg';
import likebuttonInactive from 'assets/icons/like.svg';*/
import styles from './activityDetail.module.css';
import {TipButtonWithText} from "../../user/profileInfo/Buttons";
import {trackEvent} from "../../../helpers/tracking";
import {startTipTransactionModal} from "../../transactions/transactionsSlice";
import cx from "classnames";

const ActivityFeedFooter = (props) => {
    //const [showComments, setShowComments] = useState(props.isDetail || false);
    const user = useSelector(selectUserInfo);
    //const {t} = useTranslation();
    const dispatch = useDispatch();
    const {activity} = props;

    if(user.id === activity.actor.data.id) {
        return '';
    }
    //const {activity, reaction} = props;
    /*let counts, own_reactions;
    if (reaction && props.onToggleChildReaction) {
        counts = reaction.children_counts;
        own_reactions = reaction.own_children;
    } else {
        if (reaction) {
            console.warn(
                'reaction is passed to the LikeButton but ' +
                'onToggleChildReaction is not, falling back to liking the activity'
            );
        }
        counts = activity.reaction_counts;
        own_reactions = activity.own_reactions;
    }

    const avatarFile = !isEmpty(user.avatar)
        ? user.avatar
        : user.gender === 'female'
            ? avatarFemale
            : avatarMale;

    const handleLike = () => {
        const {
            activity,
            reaction,
            onToggleReaction,
            onToggleChildReaction,
        } = props;

        if (props.publicModal) {
            return props.publicModal();
        }

        if (reaction && onToggleChildReaction) {
            return onToggleChildReaction('like', reaction, {}, {});
        }
        return onToggleReaction('like', activity, {}, {});
    };*/

    const handleTip = () => {
        trackEvent({
            category: `profile pages}`,
            action: 'open tip click',
            label: user.gender,
        });
        dispatch(startTipTransactionModal({actor: activity.actor.data}));
    };

    return (
        <>
            <div className={cx(styles.rowWrapper, styles.footerWrapper)}>
                <TipButtonWithText
                    profileUser={activity.actor.data}
                    withText={true}
                    handleTip={handleTip}/>
                {/*<div className="activityFeedActions">
        <div className={styles.feedAction}>
          <ReactionToggleIcon
            counts={counts}
            own_reactions={own_reactions}
            kind="like"
            onPress={handleLike}
            activeIcon={likebuttonActive}
            inactiveIcon={likebuttonInactive}
          />
        </div>
        <div className={styles.feedAction}>
          {showComments ? (
            <IconCommentsActive
              height="21"
              onClick={() => setShowComments((s) => !s)}
            />
          ) : (
            <IconComments
              height="21"
              onClick={() => setShowComments((s) => !s)}
            />
          )}
        </div>
      </div>
      <div className={styles.feedInfo}>
        {activity.reaction_counts.like > 0 && (
          <span className={styles.feedInfoItem}>
            <strong>{activity.reaction_counts.like}</strong>
            {t('features:post.likes', 'likes', {
              count: activity.reaction_counts.like,
            })}
          </span>
        )}
        {activity.reaction_counts.comment > 0 && (
          <span className={styles.feedInfoItem}>
            <strong>{activity.reaction_counts.comment}</strong>
            {t('features:post.comments', 'comments', {
              count: activity.reaction_counts.comment,
            })}
          </span>
        )}
      </div>
      {showComments && (
        <div className={styles.feedComments}>
          <CommentList activityId={props.activity.id} />
          {props.publicModal ? (
            <div onClick={props.publicModal}>
              <CommentField image={getAvatarSrc(avatarFile)} />
            </div>
          ) : (
            <CommentField
              image={getAvatarSrc(avatarFile)}
              activity={props.activity}
              onAddReaction={props.onAddReaction}
            />
          )}
        </div>
      )}*/}
            </div>
            <hr/>
        </>

    );
};

export default ActivityFeedFooter;
