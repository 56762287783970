import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {isEmpty} from 'lodash';

import ProfileCardPreview from 'components/profileCard/ProfileCard';
import LoadMoreTrigger from 'components/loadMore/LoadMoreTrigger';
/*import {LoaderInline} from 'components/layout/loader';*/
import {selectAuthenticated} from 'features/auth/authSlice';
import {
    resetSearch,
    search as searchFirst,
    searchMore,
    selectSearchResults,
    selectSearchStatus,
} from './searchSlice';
import styles from './search.module.css';
import {IconUserSearch} from "../../../assets/icons";
import _debounce from "lodash/debounce";
import cx from "classnames";

const ProfileSearch = ({isUsers}) => {
    const isAuthenticated = useSelector(selectAuthenticated);
    const history = useHistory();
    const ref = useRef(null);
    const query = new URLSearchParams(useLocation().search);
    const search = query.get('search');
    // const page = query.get('page');
    const data = useSelector(selectSearchResults);
    const status = useSelector(selectSearchStatus);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const fetchNext = () => {
        if (!isEmpty(data.next) && !status.loading) {
            dispatch(searchMore(data.next));
        }
    };

    useEffect(() => {
        if (isEmpty(data.results) && !data.fetched) {
            dispatch(searchFirst({search, isPublic: !isAuthenticated}));
        }
    }, [search, isUsers, dispatch, data, isAuthenticated]);

    useEffect(() => {
        ref.current.value = search;
    }, [search]);

    /*if (isEmpty(data.results)) {
        if (data.fetched || status.hasError) {
            return (
                <div style={{margin: '20px 0px 80px 16px'}}>
                    <p>
                        {t('features:search. No results for', ' No results for')}:
                        <span style={{fontWeight: 'bold'}}> {search}</span>
                    </p>
                </div>
            );
        } else {
            return <LoaderInline/>;
        }
    }*/

    const handleInputChange = _debounce((event) => {
        const query = event.target.value;
        if (!query) {
            history.push('/users');
            dispatch(resetSearch());
        } else if (query.length < 3) {
            return;
        } else {
            history.push(`/users/search?search=${query}`);
            dispatch(resetSearch());
        }
    }, 500);

    return (
        <div className={styles.wrapperEmpty}>

            <div className={styles.infoBox}>
                {/*<div className={styles.iconTop}>
                    <IconEmptySearch />
                </div>*/}
                <h2>{t('features:search.searchHead')}</h2>
                <p>{t('features:search.searchSubHead')}</p>
                <div className={styles.inputSearch}>
                    <input
                        placeholder={t('features:search.searchUsers')}
                        className={styles.inputnew}
                        // onBlur={resetInput}
                        onChange={handleInputChange}
                        onSubmit={handleInputChange}
                        ref={ref}
                    />
                    <IconUserSearch/>
                </div>
                {/*<h2>{t('features:myEpisodes.emptyTitle')}</h2>
                <p>{t('features:myEpisodes.emptySubtitle')} </p>*/}
            </div>
            {(isEmpty(data.results)) && (data.fetched || status.hasError) &&
            <div style={{margin: '20px 0px 80px 16px'}}>
                <p>
                    {t('features:search. No results for', ' No results for')}:
                    <span style={{fontWeight: 'bold'}}> {search}</span>
                </p>
            </div>
            }

            <div className={cx(styles.row, styles.userlist)}>
                {data.results.map((profile) => (
                    <ProfileCardPreview
                        key={profile.id}
                        profile={profile}
                        isPublic={!isAuthenticated}
                    />
                ))}
            </div>

            {!isEmpty(data.results) && (
                <LoadMoreTrigger getMore={fetchNext} hasMore={!isEmpty(data.next)}/>
            )}
        </div>
    );
};

export default ProfileSearch;
