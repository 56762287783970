import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { FormFieldError, FormRow } from 'components/forms';
import Button from 'components/buttons/Button';
import CountryPhonePrefix from 'components/forms/countryPhonePrefixes';
import { ResetPasswordModal } from '../recoverPassword';
import { useModal } from 'components/modal/useModal';

import { selectCountries } from 'app/miscSlice';
import formStyles from 'components/forms/forms.module.css';
import styles from 'features/auth/auth.module.css';

const ResetFormAfterTypeChange = ({ type }) => {
  const { resetForm } = useFormikContext();

  useEffect(() => {
    resetForm();
  }, [type, resetForm]);

  return null;
};

const ForgotPasswordWithModal = ({ children, modalContext }) => {
  return (
    <div style={{ margin: '5px' }}>
      <span onClick={modalContext.showModal} className={styles.forgot}>
        {children}
      </span>
      <modalContext.RenderModal
        customStyles={{ maxWidth: '575px', borderRadius: '20px' }}
        buttonClose
      >
        <ResetPasswordModal onClose={modalContext.hideModal} />
      </modalContext.RenderModal>
    </div>
  );
};

const LoginForm = ({ onSubmit, isLoading, hasError, type }) => {
  const countries = useSelector(selectCountries);
  const forgotPassword = useModal();
  const { t } = useTranslation();

  const withEmail = type === 'email';

  const formConfig = {
    schema: {
      email: Yup.object().shape({
        email: Yup.string()
          .email(t('components:validation.invalidEmail'))
          .required(t('components:validation.required')),
        password: Yup.string().required(t('components:validation.required')),
      }),
      phone: Yup.object().shape({
        phone: Yup.number()
          .typeError(t('components:validation.invalidPhone'))
          .required(t('components:validation.required')),
        password: Yup.string().required(t('components:validation.required')),
      }),
    },
    initialVals: {
      email: { email: '', password: '' },
      phone: { phone: '', password: '', countryID: 0 },
    },
  };

  return (
    <Formik
      initialValues={{ email: '', phone: '', password: '', countryID: 0 }}
      validationSchema={formConfig.schema[type]}
      onSubmit={(values) => {
        let submitPayload = values;
        onSubmit(submitPayload);
      }}
    >
      {({ dirty, isValid, setFieldValue }) => (
        <Form>
          {withEmail ? (
            <FormRow style={{ position: 'relative' }}>
              <label
                className={`${formStyles.formLabel} ${formStyles.required}`}
                htmlFor="email"
              >
                {t('main:label.Email')}
              </label>
              <FormFieldError fieldName="email" />
              <Field
                className={formStyles.input}
                placeholder={t('main:label.Email')}
                type="text"
                name="email"
              />
            </FormRow>
          ) : (
            <FormRow style={{ position: 'relative' }}>
              <CountryPhonePrefix
                countries={countries}
                onChange={(val) => setFieldValue('countryID', Number(val))}
              />
              <label
                className={`${formStyles.formLabel} ${formStyles.required}`}
                htmlFor="phone"
              >
                {t('main:label.Phone')}
              </label>

              <FormFieldError fieldName="phone" />
              <Field
                className={`${formStyles.input} ${formStyles.phone}`}
                type="tel"
                name="phone"
              />
            </FormRow>
          )}
          <FormRow>
            <label
              className={`${formStyles.formLabel} ${formStyles.required}`}
              htmlFor="password"
            >
              {t('main:label.Password')}
            </label>

            <FormFieldError fieldName="password" />
            <Field
              className={formStyles.input}
              placeholder={t('main:label.Password')}
              type="password"
              name="password"
              autoComplete="on"
            />
            <ForgotPasswordWithModal modalContext={forgotPassword}>
              {t('features:auth.login.forgotPass')}
            </ForgotPasswordWithModal>
          </FormRow>
          <FormRow style={{ paddingTop: '30px' }}>
            <Button
              disabled={!isValid && dirty}
              loading={isLoading}
              size="xl"
              fullWidth
              name="btn-gradient"
              type="submit"
            >
              {t('main:main.Login')}
            </Button>
          </FormRow>
          <ResetFormAfterTypeChange type={type} />
        </Form>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

export default LoginForm;
