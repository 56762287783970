import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import Progress from './Progress';
import { GlobalContext, ProgressContext } from 'features/stories/context';
import styles from './progress.module.css';

const ProgressBar = () => {
  const [count, setCount] = useState(0);
  const { story, storyIndex, next, videoDuration, pause } = useContext(
    ProgressContext
  );
  const { stories, defaultInterval, onStoryEnd, onStoryStart } = useContext(
    GlobalContext
  );

  let animationFrameId = useRef(null);
  let prevCountRef = useRef(count);

  const incrementCount = useCallback(() => {
    const storyStartCallback = () => {
      onStoryStart(storyIndex, story);
    };

    const storyEndCallback = () => {
      onStoryEnd && onStoryEnd(storyIndex, story);
    };

    const getStoryInterval = () => {
      if (story.media.type === 'video') return videoDuration;
      if (typeof story.duration === 'number') return story.duration;
      return defaultInterval;
    };

    cancelAnimationFrame(animationFrameId.current);
    if (prevCountRef.current === 0) storyStartCallback();
    setCount((prevCount) => {
      const interval = getStoryInterval();
      const newCount = prevCount + 100 / ((interval / 1000) * 60);
      prevCountRef.current = newCount;
      return newCount;
    });
    if (prevCountRef.current < 100) {
      animationFrameId.current = requestAnimationFrame(incrementCount);
    } else {
      storyEndCallback();
      next();
    }
  }, [
    next,
    story,
    storyIndex,
    defaultInterval,
    onStoryEnd,
    onStoryStart,
    videoDuration,
  ]);

  useEffect(() => {
    setCount(0);
    prevCountRef.current = 0;
  }, [storyIndex, stories]);

  useEffect(() => {
    if (!pause) {
      animationFrameId.current = requestAnimationFrame(incrementCount);
    }
    return () => {
      cancelAnimationFrame(animationFrameId.current);
    };
  }, [pause, count, incrementCount]);

  return (
    <div className={styles.wrapper}>
      {stories.map((_, i) => (
        <Progress
          key={i}
          count={count}
          width={1 / stories.length}
          active={i === storyIndex ? 1 : i < storyIndex ? 2 : 0}
        />
      ))}
    </div>
  );
};

export default ProgressBar;
