import React from 'react';
import {useTranslation} from 'react-i18next';

import styles from './cancellationPolicyModal.module.css';
import {Col, Row} from "../../../components/layout/grid";

const CancellationPolicyModal = ({
                                     modalContext: {RenderModal}
                                 }) => {
    const {t} = useTranslation();

    return (
        <RenderModal title={t('features:eventRequest.cancellation', 'CANCELLATION POLICY')}
                     customStyles={{
                         maxHeight: '90vh',
                         maxWidth: 650,
                         margin: 0,
                         overflowY: 'scroll',
                         padding: 0,
                     }}
                     buttonClose>
            <div className={styles.wrapper}>
                <Row className={styles.tabsWrapper}>
                    <Col md={12}>
                        <Row>
                            <Col>
                                <h1>FLEXIBLE</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h2>CO-TABLER</h2>
                            </Col>
                            <ul>
                                <li>Full refund for cancellations made more than 24 hours prior to the start of the
                                    event.
                                </li>
                                <li> 50% refund for cancellations within 24 hours of the start of the event.</li>
                                <li> No refund in case of a no-show or cancellation after the start of the event.</li>
                                <li> Full refund in case of extenuating circumstances.</li>
                            </ul>
                        </Row>
                        <Row>
                            <Col>
                                <h2>HOST</h2>
                            </Col>
                            <ul>
                                <li>No action is required for cancellations made more than 48 hours prior to the start
                                    of the event.
                                </li>
                                <li> Cancellations made within 48 hours prior to the start of the event will result in a
                                    warning. Two or more warnings will result in a temporary suspension of the
                                    account and might lead to a termination. No warning is issued in case of
                                    extenuating circumstances.
                                </li>
                                <li> If a HOST cancels the table/event, all accepted guests receive a full refund.</li>
                            </ul>
                        </Row>
                        <Row>
                            <Col>
                                <h2>TABLEE</h2>
                            </Col>
                            <ul>
                                <li>No action is required for cancellations made more than 48 hours prior to the start
                                    of the event.
                                </li>
                                <li> Cancellations made within 48 hours prior to the start of the event will or a
                                    now-show result in a warning. Two or more warnings will result in a temporary
                                    suspension of the account and might lead to a termination. No warning is issued
                                    in case of extenuating circumstances.
                                </li>
                            </ul>
                        </Row>
                    </Col>
                </Row>
                <Row className={styles.tabsWrapper}>
                    <Col md={12}>
                        <Row>
                            <Col>
                                <h1>MODERATE</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h2>CO-TABLER</h2>
                            </Col>
                            <ul>
                                <li>Full refund for cancellations made more than 72 hours prior to the start of the
                                    event.
                                </li>
                                <li> 50% refund for cancellations within 72 hours of the start of the event.</li>
                                <li> No refund in case of a no-show or cancellation after the start of the event.</li>
                                <li> Full refund in case of extenuating circumstances.</li>
                            </ul>
                        </Row>
                        <Row>
                            <Col>
                                <h2>HOST</h2>
                            </Col>
                            <ul>
                                <li>No action is required for cancellations made more than 72 hours prior to the start
                                    of the event.
                                </li>
                                <li> Cancellations made within 72 hours prior to the start of the event will result in a
                                    warning. Two or more warnings will result in a temporary suspension of the
                                    account and might lead to a termination. No warning is issued in case of
                                    extenuating circumstances.
                                </li>
                                <li> If a HOST cancels the table/event, all accepted guests receive a full refund.</li>
                            </ul>
                        </Row>
                        <Row>
                            <Col>
                                <h2>TABLEE</h2>
                            </Col>
                            <ul>
                                <li>No action is required for cancellations made more than 72 hours prior to the start
                                    of the event.
                                </li>
                                <li> Cancellations made within 72 hours prior to the start of the event or a now-show
                                    will result in a warning. Two or more warnings will result in a temporary
                                    suspension of the account and might lead to a termination. No warning is issued
                                    in case of extenuating circumstances.
                                </li>
                            </ul>
                        </Row>
                    </Col>
                </Row>
                <Row className={styles.tabsWrapper}>
                    <Col md={12}>
                        <Row>
                            <Col>
                                <h1>FLEXIBLE</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h2>CO-TABLER</h2>
                            </Col>
                            <ul>
                                <li> Full refund for cancellations made within 48 hours of booking, if the start of the
                                    event is at least 168 hours away.
                                </li>
                                <li> 50% refund for cancellations made more than 96 hours prior to the start of the
                                    event.
                                </li>
                                <li> No refund in case of a no-show or cancellation within 96 hours prior to the start
                                    of
                                    the event.
                                </li>
                                <li> Full refund in case of extenuating circumstances.</li>
                            </ul>
                        </Row>
                        <Row>
                            <Col>
                                <h2>HOST</h2>
                            </Col>
                            <ul>
                                <li>No action is required for cancellations made more than 96 hours prior to the start
                                    of the event.
                                </li>
                                <li> Cancellations made within 96 hours prior to the start of the event will result in a
                                    warning. Two or more warnings will result in a temporary suspension of the
                                    account and might If a HOST cancels the table/event, all accepted guests receive
                                    a full refund
                                </li>
                            </ul>
                        </Row>
                        <Row>
                            <Col>
                                <h2>TABLEE</h2>
                            </Col>
                            <ul>
                                <li>No action is required for cancellations made more than 96 hours prior to the start
                                    of the event.
                                </li>
                                <li> Cancellations made within 96 hours prior to the start of the event or a now-show
                                    will result in a warning. Two or more warnings will result in a temporary
                                    suspension of the account and might lead to a termination. No warning is issued
                                    in case of extenuating circumstances
                                </li>
                            </ul>
                        </Row>
                    </Col>
                </Row>
                <Row className={styles.tabsWrapper}>
                    <Col md={12}>
                        <Row>
                            <Col>
                                <h2>Overview</h2>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <p>This Extenuating Circumstances Policy explains how cancellations are handled when
                                    unforeseen events beyond your control arise after booking and make it impracticable
                                    or illegal to complete your reservation. This Policy applies to reservations for
                                    both club
                                    tables/events or private tables/events.</p>
                                <p>Extenuating Circumstances take precedence over the cancellation policy. TABLEES and
                                    CO-TABLERS that are impacted by an event covered by this policy can cancel their
                                    reservation and receive, depending on the circumstances, a cash refund and/or other
                                    consideration. HOSTS that are impacted by an event covered by this Policy can cancel
                                    without adverse consequences.</p>
                                <p>In any case, extenuating circumstances can only be exercised if properly documented
                                    by the user, and if the channels provided by ONLY VIP are used.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h2>
                                    What events are covered
                                </h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                    This Policy uses the term “Event” to refer to the following situations that occur
                                    after booking, are unforeseen at the time of booking, and prevent or legally
                                    prohibit completion of
                                    the reservation.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <ul>
                                <li>Changes to government travel requirements: Unexpected changes to visa or
                                    passport requirements imposed by a governmental agency that prevent travel to
                                    the destination. This does not include lost or expired travel documents or other
                                    personal circumstances relating to a guest’s authorization to travel.
                                </li>
                                <li> Declared emergencies and epidemics: Government declared local or national
                                    emergencies, epidemics, pandemics, and public health emergencies. This does
                                    not include diseases that are endemic or commonly associated with an area—for
                                    example, malaria in Thailand or dengue fever in Hawaii.
                                </li>
                                <li> Government travel restrictions: Travel restrictions imposed by a governmental
                                    agency that prevent or prohibit traveling to, staying at, or returning from the
                                    Listing location. This does not include non-binding travel advisories and similar
                                    government guidance.
                                </li>
                                <li> Military actions and other hostilities: Acts of war, hostilities, invasions, civil
                                    war,
                                    terrorism, explosions, bombings, rebellions, riots, insurrection, civil disorder,
                                    and
                                    civil unrest.
                                </li>
                                <li> Natural disasters: Natural disasters, acts of God, large-scale outages of essential
                                    utilities, volcanic eruptions, tsunamis, and other severe and abnormal weather
                                    events. This does not include weather or natural conditions that are common
                                    enough to be foreseeable in that location—for example, hurricanes occurring
                                    during hurricane season in Florida
                                </li>
                            </ul>
                        </Row>
                    </Col>
                </Row>
            </div>
        </RenderModal>
    );
};

export default CancellationPolicyModal;
