import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col } from 'components/layout/grid';
import isEmpty from 'lodash/isEmpty';

import ActivityFeed from '../feed/ActivityFeed';
import ProfileInfo from './profileInfo/ProfileInfo';
import ProfileContentWithUrls from './ProfileContentWithUrls';
import ProfileMedia from './ProfileMedia';
import ProfileFields from './ProfileFields';
import ProfileSidebar from './ProfileSidebar';
import FullScreenStories from 'features/stories/Stories';
import {
  getUserProfileImages as getImages,
  getUserProfileVideos as getVideos,
  getUserStories,
  selectCurrentProfile,
} from './profileSlice';
import styles from './profile.module.css';
import {useTranslation} from "react-i18next";

const ProfileView = ({ screenKey, profileUser }) => {
  const [showStories, setShowStories] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = profileUser;
  const { t } = useTranslation();

  const { images, videos, stories } = useSelector(selectCurrentProfile);
  const hasStories = !isEmpty(stories.items);
  const hasMoreVideos = videos.next > 1;
  const hasMoreImages = images.next > 1;

  const fetchMoreImages = () => {
    dispatch(getImages({ user_id: id, next: images.next }));
  };

  const fetchMoreVideos = () => {
    dispatch(getVideos({ user_id: id, next: videos.next }));
  };

  return (
    <>
      <Row>
        <Col sm={12} lg={8} className={styles.feedCollumn}>
          <ProfileInfo
            stories={hasStories ? stories.items[0].stories : null}
            profileUser={user}
            storyAction={() => setShowStories(true)}
          />

          <ProfileContentWithUrls
            user={user}
            feed={
              <ActivityFeed
                key={screenKey}
                feedType="user"
                location="profile"
                profileUser={profileUser}
              />
            }
            videos={
              !isEmpty(videos.items) ? (
                <ProfileMedia
                  files={videos.items}
                  getMore={fetchMoreVideos}
                  hasMore={hasMoreVideos}
                />
              ) : null
            }
            photos={
              !isEmpty(images.items) ? (
                <ProfileMedia
                  files={images.items}
                  getMore={fetchMoreImages}
                  hasMore={hasMoreImages}
                />
              ) : null
            }
            info={
              <ProfileFields
                verification_required={!user.verification_required && t('main:main.verified')}
                verified_phone={user.verified_phone && t('main:main.verified')}
                verified_photo={user.verified_photo && t('main:main.verified')}
                city={user.town}
                age={user.age}
                languages={user.languages}
                smoker={user.smoker}
                height={user.size}
                profession={user.profession}
                body_type={user.body_type}
                hair_color={user.hair_color}
                hair_length={user.hair_length}
              />
            }
          />
        </Col>
        <Col sm={12} lg={4}>
          <ProfileSidebar profile_id={id} />
        </Col>
      </Row>
      {hasStories && (
        <FullScreenStories
          initialIndex={0}
          userStories={stories.items}
          showStories={showStories}
          closeStories={() => {
            setShowStories(false);
            dispatch(getUserStories(user.id));
          }}
        />
      )}
    </>
  );
};

ProfileView.propTypes = {
  screenKey: PropTypes.string.isRequired,
  profileUser: PropTypes.object.isRequired,
};

export default ProfileView;
