import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { StreamApp, FlatFeed, Activity } from 'react-activity-feed';

import { getFeedList } from './feedSlice';
import ActivityFeedHeader from './activityDetail/Header';
import ActivityFeedContent from './activityDetail/Content';
 import ActivityFeedFooter from './activityDetail/Footer';
import { LoaderInline } from 'components/layout/loader';
import { selectUserInfo } from 'features/auth/authSlice';
import { selectTimestamp } from 'features/user/profileSlice';
import {
  setShowSuggestions,
  resetShowSuggestions,
} from 'features/user/suggestions/suggestionsSlice';

const ActivityFeed = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserInfo);
  const isHome = props.location === 'home';
  const profileUser = props.profileUser || user;
  const handleSuggestions = !['my-profile', 'profile'].includes(props.location);

  useEffect(() => {
    if (handleSuggestions) {
      return () => dispatch(resetShowSuggestions());
    }
  }, [dispatch, handleSuggestions]);

  const getFeeds = async (client, feedGroup, userId, options) => {
    let endpoint = '';
    switch (props.location) {
      case 'my-profile':
        endpoint = 'my_profile';
        break;
      case 'profile':
        endpoint = `profile/${profileUser.id}`;
        break;
      default:
        endpoint = 'my_feed';
    }

    const response = await dispatch(
      getFeedList({ endpoint: endpoint, last_id: options.last_id })
    ).then(unwrapResult);

    if (handleSuggestions) {
      dispatch(setShowSuggestions());
    }

    return response;
  };

  return (
    <StreamApp
      apiKey={process.env.REACT_APP_STREAM_APP_KEY}
      appId={process.env.REACT_APP_STREAM_APP_ID}
      token={user.feed_token}
    >
      <FlatFeed
        userId={profileUser.uid.toString()}
        notify
        feedGroup={props.feedType}
        options={{ reactions: { recent: true } }}
        doFeedRequest={getFeeds}
        LoadingIndicator={<LoaderInline />}
        Activity={(props) => (
          <Activity
            Header={<ActivityFeedHeader {...props} isHome={isHome} />}
            Content={<ActivityFeedContent {...props} isHome={isHome} />}
            Footer={<ActivityFeedFooter {...props} isHome={isHome} />}
          />
        )}
      />
    </StreamApp>
  );
};

// Helper component used to refresh feed after transaction
const ActivityFeedContainer = (props) => {
  const timeStamp = useSelector(selectTimestamp);

  return <ActivityFeed {...props} key={timeStamp} />;
};

export default ActivityFeedContainer;
