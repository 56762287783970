import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Avatar } from 'components/avatar';
import Button from 'components/buttons/Button';
import StoryTime from './StoryTime';
import { selectUserGender } from 'features/auth/authSlice';
import { ProfileBadges } from 'components/layout/badges/Badges';
import { IconClose, IconDots } from 'assets/icons';
import { GlobalContext, ProgressContext } from 'features/stories/context';
import StoryActions from './../actions';
import { trackEvent } from 'helpers/tracking';
import styles from './header.module.css';

const Header = ({ pauseAction }) => {
  const history = useHistory();
  const userGender = useSelector(selectUserGender);
  const { closeAction, profile, isMyProfile, isMobile } = useContext(
    GlobalContext
  );
  const { story, pause, bufferAction } = useContext(ProgressContext);
  const { id, name, avatar, gender, verified, age, dating_enabled, verified_phone } = profile;

  const handleProfileClick = () => {
    trackEvent({
      category: 'stories',
      action: `story profile click - ${gender}`,
      label: userGender,
    });
    const profileLink = isMyProfile ? '/profile' : `/profile/${id}`;
    history.push(profileLink);
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.left}
        onClick={handleProfileClick}
        style={{
          opacity: pause && !bufferAction ? 0 : 1,
        }}
      >
        <Avatar
          circle
          border
          size={40}
          gender={gender}
          image={avatar}
          style={{ display: 'inline-block' }}
        />
        <div className={styles.meta}>
          <div className={styles.userName}>
            <span>{name}</span>
            {Number(age) > 0 && <span className={styles.age}>{age}</span>}
            <ProfileBadges small verified={verified} dating={dating_enabled} phone={verified_phone}/>
          </div>
          <StoryTime className={styles.time} createdTime={story.created_time} />
        </div>
      </div>
      <div className={styles.right}>
        <StoryActions pauseAction={pauseAction}>
          <IconDots
            style={{
              opacity: pause && !bufferAction && isMobile ? 0 : 1,
            }}
          />
        </StoryActions>
        <Button
          name="btn-bare"
          className={styles.closeButton}
          onClick={() => closeAction()}
          style={{
            opacity: pause && !bufferAction && isMobile ? 0 : 1,
          }}
        >
          <IconClose />
        </Button>
      </div>
    </div>
  );
};

export default Header;
