import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import cx from 'classnames';
import * as Yup from 'yup';

import api from 'app/api';
import Button from 'components/buttons/Button';
import { FormFieldError, FormRow } from 'components/forms';
import { HeartLock } from 'assets/icons/auth';
import formStyles from 'components/forms/forms.module.css';
import styles from './confirmEmail.module.css';

const ResetPasswordModal = ({ onClose }) => {
  const [success, toggleSuccess] = useState(false);
  const [error, toggleError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('components:validation.invalidEmail'))
      .required(t('components:validation.required')),
  });

  const asyncSendPassReset = async ({ email, token }) => {
    setLoading(true);
    try {
      const res = await api.get(`/v1/user/fpwo/${email}`);
      // console.log(res);
      if (res.data.status) {
        toggleSuccess(true);
      }
    } catch (error) {
      console.log(error);
      toggleError('Network error');
      toast.error(t('main:errors.somethingWentWrong'));
    }
  };

  if (success) {
    return (
      <div className={cx(styles.container, styles.modal)}>
        <div className={styles.ico}>
          <HeartLock />
        </div>
        <div className={styles.copy}>
          <h4>{t('features:auth.registerScreens.passwordRecovery')}</h4>
          <p>{t('features:auth.passwordRecovery.emailSent')}</p>
        </div>
        <Button
          type="button"
          onClick={() => onClose()}
          fullWidth
          size="xl"
          name="btn-gradient"
          style={{ maxWidth: '320px', marginTop: '30px' }}
        >
          {t('main:main.close')}
        </Button>
      </div>
    );
  }

  return (
    <div className={cx(styles.container, styles.modal)}>
      <div className={styles.ico}>
        <HeartLock />
      </div>
      <div className={styles.copy}>
        <h4>{t('features:auth.registerScreens.passwordRecovery')}</h4>
        <p>{t('features:auth.passwordRecovery.setNewPass')}</p>
      </div>
      <hr />
      <div className={styles.form}>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            // console.log(values);
            asyncSendPassReset(values);
          }}
        >
          <Form>
            <FormRow>
              <label
                className={`${formStyles.formLabel} ${formStyles.required}`}
                htmlFor="email"
              >
                {t('main:main.Email')}
              </label>
              <FormFieldError fieldName="email" />
              <Field
                className={formStyles.input}
                placeholder={t('main:main.Email')}
                type="text"
                name="email"
              />
              {error && <span className={formStyles.errorMsg}>{error}</span>}
            </FormRow>
            <div>
              <Button
                loading={loading}
                fullWidth
                size="xl"
                name="btn-gradient"
                type="submit"
              >
                {t('main:main.Submit')}
              </Button>
              <br />
              <Button
                onClick={onClose}
                fullWidth
                size="xl"
                name="btn-grey"
                type="button"
              >
                {t('main:main.Cancel')}
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ResetPasswordModal;
