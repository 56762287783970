import React from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import styles from './loader.module.css';

const Loader = React.memo(() => {
  const domEl = document.getElementById('loader-root');

  if (!domEl) return null;
  return ReactDOM.createPortal(
    <div className={styles.backdrop}>
      <div className={styles.loader}>
        <div className={styles.bounce1}></div>
        <div className={styles.bounce2}></div>
      </div>
    </div>,
    domEl
  );
});

export const LoaderRelative = (className) => {
  return (
    <div className={className}>
      <div className={styles.loader}>
        <div className={styles.bounce1}></div>
        <div className={styles.bounce2}></div>
      </div>
    </div>
  );
};

export const LoaderInline = ({
  style,
  size,
  context,
  centered,
  color = 'pink',
}) => {
  const className = cx(styles.loaderInline, {
    [styles[context]]: context && styles[context],
    [styles[size]]: size && styles[size],
    [styles[color]]: color && styles[color],
    [styles.centered]: centered,
  });
  return (
    <div className={className} style={style || null}>
      <div className={styles.bounce1}></div>
      <div className={styles.bounce2}></div>
    </div>
  );
};

export default Loader;
