import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { map, isEmpty } from 'lodash';
import api from 'app/api';

export const getUserList = createAsyncThunk(
  'explorer/getUserList',
  async ({ isPublic = false }, { rejectWithValue, getState }) => {
    const { country, city, gender, userCategories } = getState().misc.filters;
    const { looking_for } = getState().auth.user.userInfo;

    const version = isPublic ? 'guests' : 'v2';
    const url = `${version}/explorer`;

    const catQuery = !isEmpty(userCategories)
      ? map(userCategories, 'id').join()
      : null;

    const response = await api.get(url, {
      params: {
        category: catQuery,
        gender: gender || looking_for,
        country: !isEmpty(country) ? country : null,
        city: !isEmpty(city) ? city.toLowerCase() : null,
      },
    });
    if (!response.data.status) {
      return rejectWithValue(response.data.error);
    }
    return { ...response.data };
  }
);
export const getUserListMore = createAsyncThunk(
  'explorer/getUserListMore',
  async (next, { rejectWithValue }) => {
    const url = process.env.NODE_ENV === 'development' ? next.slice(27) : next;
    const response = await api.get(url);
    if (!response.data.status) {
      return rejectWithValue(response.data.error);
    }
    return response.data;
  }
);

const initialState = {
  status: {
    loading: false,
    hasError: false,
  },
  data: {
    results: [],
    total: 0,
    next: null,
    query: null,
    fetched: false,
  },
};

export const userList = createSlice({
  name: 'userList',
  initialState: initialState,
  reducers: {
    resetUserList: (state) => {
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [getUserList.pending]: (state) => {
      state.status.loading = true;
      state.status.hasError = false;
    },
    [getUserList.fulfilled]: (state, action) => {
      state.status.loading = false;
      state.data = action.payload.data;
      state.data.fetched = true;
    },
    [getUserList.rejected]: (state) => {
      state.status.loading = false;
      state.status.hasError = true;
      state.data.fetched = true;
    },
    [getUserListMore.pending]: (state) => {
      state.status.loading = true;
      state.status.hasError = false;
    },
    [getUserListMore.fulfilled]: (state, action) => {
      state.status.loading = false;
      state.data.results = [
        ...state.data.results,
        ...action.payload.data.results,
      ];
      state.data.next = action.payload.data.next;
      state.data.total = action.payload.data.total;
    },
    [getUserListMore.rejected]: (state) => {
      state.status.loading = false;
      state.status.hasError = true;
    },
  },
});

export const { resetUserList } = userList.actions;

export const selectUserList = (state) => state.userList.data;
export const selectUserListStatus = (state) => state.userList.status;

export default userList.reducer;
