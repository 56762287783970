import {NavLink} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {selectNewApplicationsCount} from 'features/dating/applications/applicationsSlice';
import {selectNewEpisodesCount} from 'features/dating/episodes/episodesSlice';
import {selectAuthenticated, selectUserGender} from 'features/auth/authSlice';
import {trackEvent} from 'helpers/tracking';
import styles from './datingNav.module.css';

const DatingNav = () => {
    /*const {path} = useRouteMatch();*/
    const {t} = useTranslation();
    const isAuthenticated = useSelector(selectAuthenticated);
    const userGender = useSelector(selectUserGender);
    const numOfNewApplications = useSelector(selectNewApplicationsCount);
    const numOfNewEpisodes = useSelector(selectNewEpisodesCount);
    //const isMale = userGender === 'male';

    return (
        <div className={styles.wrapper}>
            <nav className={styles.nav}>
                {/*{isMale ? (
          <>
            <NavLink
              className={styles.navItem}
              activeClassName={styles.active}
              to={`${path}`}
              exact={true}
              onClick={() => {
                trackEvent({
                  category: 'events page',
                  action: 'my-event-requests tab click',
                  label: userGender,
                });
              }}
            >
              <span>
                {t('features:event.nav.eventRequests')}
                {numOfNewApplications > 0 && (
                  <strong className={styles.notoficationNum}>
                    {numOfNewApplications}
                  </strong>
                )}
              </span>
            </NavLink>

            <NavLink
              className={styles.navItem}
              activeClassName={styles.active}
              to={`/event-requests`}
              onClick={() => {
                trackEvent({
                  category: 'event page',
                  action: 'apply-for-event tab click',
                  label: userGender,
                });
              }}
            >
              <span>
                {t('features:event.nav.joinEvents')}
                {numOfNewEpisodes > 0 && (
                  <strong className={styles.notoficationNum}>
                    {numOfNewEpisodes}
                  </strong>
                )}
              </span>
            </NavLink>
          </>
        ) : (*/}
                <>
                    <NavLink
                        className={styles.navItem}
                        activeClassName={styles.active}
                        to={`/`}
                        exact={true}
                        onClick={() => {
                            trackEvent({
                                category: 'club page',
                                action: 'club tab click',
                                label: userGender,
                            });
                        }}
                    >
                      <span>
                        {t('features:event.nav.clubs', 'Clubs')}
                      </span>
                    </NavLink>

                    <NavLink
                        className={styles.navItem}
                        activeClassName={styles.active}
                        to={`/events`}
                        exact={true}
                        onClick={() => {
                            trackEvent({
                                category: 'event page',
                                action: 'join event tab click',
                                label: userGender,
                            });
                        }}
                    >
                      <span>
                        {t('features:event.nav.joinEvents')}
                          {numOfNewEpisodes > 0 && (
                              <strong className={styles.notoficationNum}>
                                  {numOfNewEpisodes}
                              </strong>
                          )}
                      </span>
                    </NavLink>

                    {isAuthenticated &&
                    <NavLink
                        className={styles.navItem}
                        activeClassName={styles.active}
                        to={`/my-event-requests`}
                        onClick={() => {
                            trackEvent({
                                category: 'my event page',
                                action: 'my-event-requests tab click',
                                label: userGender,
                            });
                        }}
                    >
                      <span>
                        {t('features:event.nav.eventRequests')}
                          {numOfNewApplications > 0 && (
                              <strong className={styles.notoficationNum}>
                                  {numOfNewApplications}
                              </strong>
                          )}
                      </span>
                    </NavLink>
                    }
                </>
                {/*)}*/}
            </nav>
        </div>
    );
};

export default DatingNav;
