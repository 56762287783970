import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';
import {useHistory, useLocation} from 'react-router-dom';
import {PhotoSwipe} from 'react-photoswipe';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';

import {trackEvent} from 'helpers/tracking';
import {getImageSrc} from 'helpers/s3media';
import Button from 'components/buttons/Button';
import ContentUpdateMessage from 'features/infoMessages/ContentUpdateMessage';
import SubscribeModal from 'features/subscriptions/subscribeModal/SubscribeModal';
import {useModal} from 'components/modal/useModal';
import {useWindowWidthLessThan} from 'hooks';
import {
    LocationInfo,
    ProfileMetaInfo,
} from './profileMetaInfo';
import ProfileImagesDesk from './profileImagesDesk';
import {Avatar} from 'components/avatar';
import {ProfileBadges} from 'components/layout/badges/Badges';
import StickyHeader from './StickyHeader';

import Description from './Description';
import {ProfileButtonNew, SubscribeButtonRound} from './Buttons';
import ProfileActions from '../profileActions';
import {CancelConnectModal, DisconnectModal, UnfollowModal} from '../profileActions/Modals';
import {
    followUser,
    selectFollowing,
    getUserProfile,
    updateTimestamp, connectionUser, selectConnected,
} from '../profileSlice';
import {
    // initChatTransaction,
    startChatTransaction, startTipTransactionModal,
} from 'features/transactions/transactionsSlice';
import {selectUserInfo} from 'features/auth/authSlice';
import {IconArrowLeft} from 'assets/icons';
import styles from './profileInfo.module.css';
import PosterImage from "../../../components/profileheader/PosterImage";
import WithoutBannerProfile from "./WithoutBannerProfile";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

const ProfileInfo = ({
                         stories,
                         profileUser,
                         isMyProfile,
                         publicModal,
                         storyAction,
                     }) => {
    const [showHeader, toogleShowHeader] = useState(false);
    const [filesIndex, setFilesIndex] = useState(0);
    const [sliderImage] = useState(profileUser.images);

    const [filesToggler, setFilesToggler] = useState(false);
    const [noAvatar, setNoAvatar] = useState(isEmpty(profileUser.avatar));

    const showFollowText = useSelector(selectFollowing);
    const showConnectionText = useSelector(selectConnected);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {uid, gender} = useSelector(selectUserInfo);
    const subscribeModal = useModal();
    const followModal = useModal();
    const disConnectionModal = useModal();
    const cancelConnectionModal = useModal();
    const {search, state} = useLocation();
    const isMobile = useWindowWidthLessThan(769);

    const chatRefID = !isEmpty(state) ? state.chatID : null;

    const isPublicProfile = typeof publicModal === 'function';
    const hasPublicLink = !isEmpty(profileUser.hash_id);
    const hasSubscriptions = profileUser.subscription.has_subscription;
    const hasSubscriptionsEnabled = profileUser.is_subscription_enabled;
    const hasSubscriptionPrice = profileUser.subscription.price.amount;

    const hasSeenAllStories = stories && stories.every((s) => s.seen === true);
    //const showMobileStories = !isEmpty(stories); // && (!hasSeenAllStories || isMyProfile);

    const showEditButton = isMyProfile;
    const showShareButton = hasPublicLink && (isMyProfile || isPublicProfile);
    const showChatButton = !isMyProfile;
    const showTipButton = !isMyProfile;
    const showSubscribeButton =
        !isMyProfile &&
        !isPublicProfile &&
        hasSubscriptions &&
        hasSubscriptionsEnabled &&
        hasSubscriptionPrice;
    const showFollowButton =
        !showSubscribeButton && !isMyProfile && !isPublicProfile;
    const showConnectButton = !isMyProfile && !isPublicProfile;
    const showMoreButton = !isMobile && !isMyProfile && !isPublicProfile;

    const wrapperClass = isMyProfile
        ? 'myProfile'
        : isPublicProfile
            ? 'publicProfile'
            : 'otherProfile';

    /*const topClass = cx(styles.profileTop, {
        // [styles.topPublic]: isPublicProfile,
        [styles.singleImg]: profileUser.images.length < 2,
        [styles.withStories]: showMobileStories,
    });*/

    useEffect(() => {
        const param = new URLSearchParams(search);
        const paramVal = param.get('subscribe');

        if (paramVal === 'true') {
            subscribeModal.showModal();
        }

        /*const story = stories.find((s) => s.seen === false) || stories[0];
        const src = getStoryPreviewMedia(story.media, 50);
        src.type = "image";
        const data = Object.values(src);
        setSliderImage(prevSliderImage => [...prevSliderImage, ...data]);*/

    }, [search, subscribeModal, stories]);

    const handleConnection = (connectionAction) => {

        //const {user1_id}  = profileUser.connection;

        console.log(connectionAction);

        const connectable = connectionAction === 1;
        const disConnectable = connectionAction === 3;
        const cancellable = connectionAction === 2;
        const confirmable = connectionAction === 4;
        //const cancellable = !connectable && !disConnectable && connectionAction === 2  && parseInt(uid) === parseInt(user1_id);
        //const confirmable = !connectable && !disConnectable && connectionAction === 2  && parseInt(uid) !== parseInt(user1_id);

        const actionText =
            connectable ? 'connect' :
                cancellable ? 'cancel' :
                    confirmable ? 'confirm' :
                        disConnectable  ? 'disconnect' :
                            '';

        const successText =
            connectable ? t('features:profileInfo.successConnectRequest') :
                cancellable ? t('features:profileInfo.successCancelConnectRequest') :
                    confirmable ? t('features:profileInfo.successConnection') :
                        disConnectable ? t('features:profileInfo.successDisconnect') :
                            '';

        trackEvent({
            category: 'profile pages',
            action: `${actionText} click`,
            label: gender,
        });
        if (showHeader) {
            window.scrollTo(0, 0);
            toogleShowHeader(false);
        }

        dispatch(
            connectionUser({user_id: profileUser.id, action: connectionAction})
        )
            .then(unwrapResult)
            .then((result) => {
                if (result.status) {
                    toast.success(successText);
                    dispatch(updateTimestamp());
                    dispatch(getUserProfile({user_id: profileUser.id}));
                }
            });
    };

    const handleFollow = () => {
        trackEvent({
            category: 'profile pages',
            action: `${profileUser.following ? 'unfollow' : 'follow'} click`,
            label: gender,
        });
        if (showHeader) {
            window.scrollTo(0, 0);
            toogleShowHeader(false);
        }
        dispatch(
            followUser({user_id: profileUser.id, following: profileUser.following})
        )
            .then(unwrapResult)
            .then((result) => {
                if (result.status) {
                    dispatch(updateTimestamp());
                    dispatch(getUserProfile({user_id: profileUser.id}));
                }
            });
    };

    const handleChat = () => {
        trackEvent({
            category: `profile pages${isPublicProfile ? ' - public' : ''}`,
            action: 'open chat click',
            label: gender,
        });
        if (isPublicProfile) {
            publicModal();
        } else if (profileUser.chat_open) {
            history.push(`/chat/${uid}-${profileUser.uid}-messaging`);
        } else {
            dispatch(startChatTransaction({actor: profileUser}));
        }
    };

    const handleTip = () => {
        trackEvent({
            category: `profile pages${isPublicProfile ? ' - public' : ''}`,
            action: 'open tip click',
            label: gender,
        });
        if (isPublicProfile) {
            publicModal();
        } else {
            dispatch(startTipTransactionModal({actor: profileUser}));
        }
    };

    const handleSubscribe = () => {
        trackEvent({
            category: 'profile pages',
            action: 'subscribe click',
            label: gender,
        });
        if (isPublicProfile) {
            publicModal();
        } else {
            subscribeModal.showModal();
        }
    };

    const onButtonsViewChange = (isInView) => {
        toogleShowHeader(!isInView);
    };

    const getImages = () => {
        let items = [];
        profileUser.images.forEach((file) => {
            const src = getImageSrc(file);
            items.push({
                html: `<div class="pswp__custom_item custom_item"><img src="${src}" alt="" /></div>`,
            });
        });
        return items;
    };

    const handleImageClick = (index) => {
        setFilesIndex(index);
        setFilesToggler(true);
    };

    const handleAvatarClick = () => {
        if (!noAvatar) {
            setFilesIndex(0);
            setFilesToggler(true);
        }
    };

    const handleStoryAvatarClick = () => {
        trackEvent({
            category: 'profile pages',
            action: 'stories click',
            label: gender,
        });
        storyAction();
    };

    const mainActions = {
        subscribe: {
            show: false,
            onClick: handleSubscribe,
        },
        follow: {
            show: showFollowButton,
            onClick: handleFollow,
            showModal: followModal.showModal,
            loading: !showFollowText,
        },
        connection: {
            show: showConnectButton,
            onClick: handleConnection,
            showModal: disConnectionModal.showModal,
            showCancelModal: cancelConnectionModal.showModal,
            loading: !showConnectionText,
            uid: uid,
        },
        chat: {
            show: showChatButton,
            onClick: handleChat,
        },
        tip: {
            show: showTipButton,
            onClick: handleTip,
        },
        edit: {
            show: showEditButton,
        },
        share: {
            show: showShareButton,
        },
        more: {
            show: showMoreButton,
        },
    };

    const mainActionWithSubscribe = {...mainActions};

    mainActionWithSubscribe.subscribe = {
        show: showSubscribeButton,
        onClick: handleSubscribe,
    }

    const profileInfoBox = (classname) => {
        return (
            <div className={classname}>
                <div className={cx(styles.infoUsers)}>
                    <div className={styles.infoName}>
                        <span>{profileUser.name}</span>
                        {profileUser.age && (
                            <span className={styles.infoAges}>{profileUser.age}</span>
                        )}
                    </div>
                    <ProfileBadges
                        verified={!profileUser.verification_required}
                        dating={profileUser.dating_enabled}
                        phone={profileUser.verified_phone}
                        className={styles.infoBadges}
                        classNameIcon={styles.colorGrey}
                    />
                </div>
                <ProfileMetaInfo
                    customClass={styles.statsInfoMobile}
                    {...profileUser}
                />
               {/* <LocationInfo
                    {...profileUser}
                />
                <div className={styles.statsInfoMobile}>
                    <OnlineStatus isOnline={profileUser.online}/>
                    <StatsInfo stats={profileUser.stats}/>
                </div>*/}
                {/*<ProfileMetaInfo
                            customClass={styles.metaInfoDesktop}
                            {...profileUser}
                        />*/}
            </div>
        )
    }

    const storyImageBlock = () => {
        return (
            <div className={styles.storyBlockWrapper}>
                {/*{profileUser.images.length == 0 && (
                    <Avatar
                        size={100}
                        online={profileUser.online}
                        image={profileUser.avatar}
                        gender={profileUser.gender}
                        onClick={() => handleAvatarClick()}
                        noAvatarCallBack={() => {
                            setNoAvatar(true);
                        }}
                        className={styles.storyBlock}
                        wrapperClassName={styles.storyBlockWrapper}
                    />
                )}*/}

                {/*{displayImages()}*/}

                {sliderImage.length > 1 && (

                    <ProfileImagesDesk
                        images={sliderImage}
                        handleImageClick={handleImageClick}
                        isMobile
                        stories={stories}
                        handleStoryAvatarClick={handleStoryAvatarClick}
                        hasSeenAllStories={hasSeenAllStories}
                        profileUser={profileUser}
                    />
                )}

                {/*{showMobileStories && (
                    <>
                        <MobileStories
                            stories={stories}
                            onClick={() => handleStoryAvatarClick()}
                            allSeen={hasSeenAllStories}
                        />

                        <div className={styles.storyText}>
                            {t('features:stories.story')}
                        </div>
                    </>
                )}*/}
            </div>
        )
    }

    return (
            <>
            {profileUser.banner === null && (
             <WithoutBannerProfile
                 stories={stories}
                profileUser={profileUser}
                isMyProfile={isMyProfile}
                publicModal={publicModal}
                storyAction={storyAction}
             />
            )}

            {profileUser.banner && (
                <div className={cx(styles.wrapper, wrapperClass)}>
            {isMyProfile && !isPublicProfile && <ContentUpdateMessage/>}

            {isMobile && (
                <>
                    {/*Back Button*/}
                    <div className={styles.backBtn}>
                        <Button
                            onClick={() => {
                                chatRefID
                                    ? history.push(`/chat/${chatRefID}`)
                                    : history.goBack();
                            }}
                            name="btn-bare"
                            className={styles.backBtnBtn}
                        >
                            <IconArrowLeft style={{width: '25px', height: '16px'}}/>
                        </Button>

                        {/*Profile Info*/}
                        <div className={styles.infoMobile}>
                            <div className={cx(styles.infoUser)}>
                                {/*Profile Name*/}
                                <div className={styles.infoName}>
                                    <span>{profileUser.name}</span>
                                    {profileUser.age && (
                                        <span className={styles.infoAge}>{profileUser.age}</span>
                                    )}
                                </div>
                                {/*Profile Badge*/}
                                <ProfileBadges
                                    verified={!profileUser.verification_required}
                                    dating={profileUser.dating_enabled}
                                    phone={profileUser.verified_phone}
                                    className={styles.infoBadges}
                                />
                            </div>
                            {/*Profile Location*/}
                            <LocationInfo
                                customClass={styles.locationInfoMobile}
                                {...profileUser}
                            />
                            {/*<ProfileMetaInfo
                                {...profileUser}
                            />*/}
                        </div>
                        <div className={styles.blockReport}>
                            {!isMyProfile && !isPublicProfile && !showHeader && (
                                <ProfileActions profileUser={profileUser} isMobile/>
                            )}
                        </div>
                        <PosterImage image={profileUser.banner}/>
                    </div>

                    <div className={styles.infoUserAvatarBlock}>
                        <Avatar
                            circle
                            size={100}
                            online={profileUser.online}
                            image={profileUser.avatar}
                            gender={profileUser.gender}
                            onClick={() => handleAvatarClick()}
                            noAvatarCallBack={() => {
                                setNoAvatar(true);
                            }}
                            className={styles.avatarBorder}
                            wrapperClassName={styles.avatarBlock}
                        />

                        <ProfileButtonNew
                            mainActions={mainActions}
                            profileUser={profileUser}
                            inViewAction={onButtonsViewChange}
                            onHeader
                        />
                    </div>

                    {profileInfoBox(styles.infos)}
                    {/*<div className={styles.infos}>
                        <div className={cx(styles.infoUsers)}>
                            <div className={styles.infoName}>
                                <span>{profileUser.name}</span>
                                {profileUser.age && (
                                    <span className={styles.infoAges}>{profileUser.age}</span>
                                )}
                            </div>
                            <ProfileBadges
                                verified={!profileUser.verification_required}
                                dating={profileUser.dating_enabled}
                                phone={profileUser.verified_phone}
                                className={styles.infoBadges}
                                classNameIcon={styles.colorGrey}
                            />
                        </div>
                        <LocationInfo
                            {...profileUser}
                        />
                        <div className={styles.statsInfoMobile}>
                            <OnlineStatus isOnline={profileUser.online}/>
                            <StatsInfo stats={profileUser.stats}/>
                        </div>
                        <ProfileMetaInfo
                            customClass={styles.metaInfoDesktop}
                            {...profileUser}
                        />
                    </div>*/}
                    {/*<ProfileImageSlider
                        images={profileUser.images}
                        gender={profileUser.gender}
                    />*/}
                    {/*{showMobileStories && (
                            <MobileStories
                                stories={stories}
                                onClick={() => handleStoryAvatarClick()}
                                allSeen={hasSeenAllStories}
                            />
                        )}*/}

                    {profileUser.about.length > 0 && (
                        <>
                            {/*{isMobile ? (
                <hr className={styles.hr} />
            ) : (
                <span className={styles.heading}>
              {t('features:profileInfo.about')}
            </span>
            )}*/}
                            <Description description={profileUser.about} limit={isMobile ? 50 : 100}/>
                        </>
                    )}

                    {storyImageBlock()}

                    {/*{profileUser.images.length > 1 && (
                            <>
                                <span className={styles.heading}>
                                    {t('features:profileInfo.profileImages')}
                                    <span className={styles.headingCount}>
                                      {profileUser.images.length}
                                    </span>
                                </span>
                                <ProfileImagesDesk
                                    images={profileUser.images}
                                    handleImageClick={handleImageClick}
                                />
                            </>
                        )}*/}

                    <PhotoSwipe
                        options={{
                            bgOpacity: 0.5,
                            index: filesIndex,
                            closeElClasses: [
                                'item',
                                'custom_item',
                                'caption',
                                'zoom-wrap',
                                'ui',
                                'top-bar',
                            ],
                            closeOnScroll: false,
                            fullscreenEl: false,
                            zoomEl: true,
                            shareEl: false,
                            counterEl: false,
                            arrowEl: true,
                            preloaderEl: true,
                            history: false,
                        }}
                        isOpen={filesToggler}
                        items={getImages()}
                        onClose={() => setFilesToggler(false)}
                    />

                    <SubscribeButtonRound
                        mainActions={mainActionWithSubscribe}
                        profileUser={profileUser}
                        onHeader
                    />

                    <SubscribeModal modalContext={subscribeModal} profile={profileUser}/>
                    <UnfollowModal
                        modalContext={followModal}
                        onConfirmUnfollow={handleFollow}
                        userName={profileUser.name}
                    />
                    <DisconnectModal
                        modalContext={disConnectionModal}
                        onConfirmDisconnect={handleConnection}
                        userName={profileUser.name}
                    />
                    <CancelConnectModal
                        modalContext={cancelConnectionModal}
                        onConfirmDisconnect={handleConnection}
                        userName={profileUser.name}
                    />

                    {showHeader && (
                        <StickyHeader
                            isMyProfile={isMyProfile}
                            mainActions={mainActionWithSubscribe}
                            profileUser={profileUser}
                            isPublic={isPublicProfile}
                            isMobile={isMobile}
                            customBackBtnHandler={
                                chatRefID ? () => history.push(`/chat/${chatRefID}`) : null
                            }
                        >
                            <ProfileButtonNew
                                mainActions={mainActionWithSubscribe}
                                profileUser={profileUser}
                                onHeader
                            />
                        </StickyHeader>
                    )}
                </>
            )}

            {/*********************************************desktop*********************************************/}

            {!isMobile && (
                <>
                    {/*Back Button*/}
                    <div className={styles.deskBackBtn}>
                        <div className={styles.prelative}>
                            <Button
                                onClick={() => {
                                    chatRefID
                                        ? history.push(`/chat/${chatRefID}`)
                                        : history.goBack();
                                }}
                                name="btn-bare"
                                style={{padding: '35px 0px 0px 16px', position: 'relative', zIndex: '12'}}
                            >
                                <IconArrowLeft style={{width: '25px', height: '16px'}}/>
                            </Button>

                            {/*Profile Info*/}
                            <div className={styles.infoDesk}>
                                <div className={cx(styles.infoUser)}>
                                    {/*Profile Name*/}
                                    <div className={styles.bannerInfoName}>
                                        <span>{profileUser.name}</span>
                                        {profileUser.age && (
                                            <span className={styles.infoAge}>{profileUser.age}</span>
                                        )}
                                    </div>
                                    {/*Profile Badge*/}
                                    <ProfileBadges
                                        verified={!profileUser.verification_required}
                                        dating={profileUser.dating_enabled}
                                        phone={profileUser.verified_phone}
                                        className={styles.infoBadges}
                                    />
                                </div>
                                {/*Profile Location*/}
                                <LocationInfo
                                    customClass={styles.locationInfoMobileDesk}
                                    {...profileUser}
                                />
                                {/*<ProfileMetaInfo
                                {...profileUser}
                            />*/}
                            </div>

                            {!isMyProfile && uid && (
                                <div className={styles.blockReport}>
                                    <ProfileActions
                                        isPublic={isPublicProfile}
                                        profileUser={profileUser}
                                        isMyProfile={isMyProfile}
                                        mainActions={mainActions}
                                        isMobile={isMobile}
                                    />
                                </div>
                            )}

                            {/*<ProfileButtons
                                mainActions={mainActions}
                                profileUser={profileUser}
                                inViewAction={onButtonsViewChange}
                                isPublic={isPublicProfile}
                            />*/}
                            <PosterImage image={profileUser.banner}/>
                        </div>
                    </div>

                    <div className={styles.infoUserAvatarBlock}>
                        <Avatar
                            circle
                            size={100}
                            online={profileUser.online}
                            image={profileUser.avatar}
                            gender={profileUser.gender}
                            onClick={() => handleAvatarClick()}
                            noAvatarCallBack={() => {
                                setNoAvatar(true);
                            }}
                            className={styles.avatarBorder}
                            wrapperClassName={styles.avatarBlock}
                        />

                        <ProfileButtonNew
                            mainActions={mainActions}
                            profileUser={profileUser}
                            inViewAction={onButtonsViewChange}
                            hideMore={true}
                            onHeader
                        />
                    </div>

                    {profileInfoBox(styles.infoHeadDesk)}

                    {profileUser.about.length > 0 && (
                        <>
                            {/*{isMobile ? (
                <hr className={styles.hr} />
            ) : (
                <span className={styles.heading}>
              {t('features:profileInfo.about')}
            </span>
            )}*/}
                            <Description description={profileUser.about} limit={isMobile ? 50 : 100}/>
                        </>
                    )}

                    {storyImageBlock()}

                    <PhotoSwipe
                        options={{
                            bgOpacity: 0.5,
                            index: filesIndex,
                            closeElClasses: [
                                'item',
                                'custom_item',
                                'caption',
                                'zoom-wrap',
                                'ui',
                                'top-bar',
                            ],
                            closeOnScroll: false,
                            fullscreenEl: false,
                            zoomEl: true,
                            shareEl: false,
                            counterEl: false,
                            arrowEl: true,
                            preloaderEl: true,
                            history: false,
                        }}
                        isOpen={filesToggler}
                        items={getImages()}
                        onClose={() => setFilesToggler(false)}
                    />

                    <SubscribeButtonRound
                        mainActions={mainActionWithSubscribe}
                        profileUser={profileUser}
                        onHeader
                    />

                    <SubscribeModal modalContext={subscribeModal} profile={profileUser}/>
                    <UnfollowModal
                        modalContext={followModal}
                        onConfirmUnfollow={handleFollow}
                        userName={profileUser.name}
                    />
                    <DisconnectModal
                        modalContext={disConnectionModal}
                        onConfirmDisconnect={handleConnection}
                        userName={profileUser.name}
                    />
                    <CancelConnectModal
                        modalContext={cancelConnectionModal}
                        onConfirmDisconnect={handleConnection}
                        userName={profileUser.name}
                    />

                    {showHeader && (
                        <StickyHeader
                            isMyProfile={isMyProfile}
                            mainActions={mainActionWithSubscribe}
                            profileUser={profileUser}
                            isPublic={isPublicProfile}
                            isMobile={isMobile}
                            customBackBtnHandler={
                                chatRefID ? () => history.push(`/chat/${chatRefID}`) : null
                            }
                        >
                            <ProfileButtonNew
                                mainActions={mainActionWithSubscribe}
                                profileUser={profileUser}
                                onHeader
                            />
                        </StickyHeader>
                    )}
                </>
            )}
                </div>
            )}
        </>

    );
};

export default ProfileInfo;
