import { AuthLayout } from 'components/layout/layouts';
import LoginContainer from 'features/auth/loginForm/LoginContainer';

const LoginScreen = () => {
  return (
    <AuthLayout loginPage={true}>
      <LoginContainer />
    </AuthLayout>
  );
};

export default LoginScreen;
