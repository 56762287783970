import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation, useParams} from 'react-router-dom';

import api from 'app/api';
import {IconCross} from 'assets/icons';
import {trackEvent} from 'helpers/tracking';
import {LoaderRelative} from 'components/layout/loader';
import styles from './confirmQRScan.module.css';

const ConfirmQRScan = () => {
    const {push} = useHistory();
    const {search} = useLocation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const {t} = useTranslation();
    const {id} = useParams();

    useEffect(() => {
        const handleError = () => {
            setLoading(false);
            setError(t('features:event.scanError'));
        };

        const checkToken = async () => {
            if (id) {
                try {
                    const res = await api.get(
                        `/v2/qrcode_info_episode/${id}`
                    );
                    if (res.data.error) {
                        return handleError(res.data.error);
                    }
                    setLoading(false);
                    trackEvent({
                        category: 'qrcode',
                        action: 'scan',
                        label: 'confirmed',
                    });

                    push(`/event/${res.data.data.key_id}`);

                } catch (error) {
                    handleError(error);
                    setLoading(false);
                }
            } else {
                setLoading(false);
                return setError(t('features:event.scanError'));
            }
        };
        checkToken();
    }, [t, search, id, push]);

    if (loading)
        return (
            <div className={styles.container}>
                <LoaderRelative/>
            </div>
        );

    if (error)
        return (
            <div className={styles.container}>
                <div className={styles.icoBig}>
                    <IconCross/>
                </div>
                <div className={styles.copy}>
                    <h4>{t('main:main.Error')}</h4>
                    <p>
                        {error}
                        <br/>
                        {t('features:auth.passwordRecovery.tryAgain')}
                    </p>
                </div>
            </div>
        );

    return '';
};

export default ConfirmQRScan;
