import React from 'react';
import ContinentCard from "./ContinentCard";

const ContinentListItem = ({city, isPublic, hideFilter}) => {
    return (
        <ContinentCard
            city={city}
            hideFilter={hideFilter}
            isPublic={isPublic}/>
    );
};

export default ContinentListItem;
