import axios from 'axios';
// import store from './store';
import { history } from '../index';

export const wp_api = axios.create({
  baseURL: process.env.REACT_APP_WP_API_BASE_URL,
});

let api = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.response.use((response) => {
  const { status, error } = response.data;
  const { skipInterceptor } = response.config;
  if (!skipInterceptor && !status && error) {
    // console.log(response);
    switch (error.code) {
      case 200:
        history.push('/login');
        history.go(0);
        break;
      default:
        console.log(error.code);
    }
  }
  return response;
}, undefined);

export default api;
