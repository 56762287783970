import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/api';

export const getTransactions = createAsyncThunk(
  'notifications/getTransactions',
  async () => {
    const res = await api.get('v2/user/transactions');
    return res.data;
  }
);

export const getUnreadNotificationNum = createAsyncThunk(
  'notifications/getUnreadNotificationNum',
  async () => {
    const res = await api.get('v2/user/notifications/unread');
    return res.data;
  }
);

export const getNotifications = createAsyncThunk(
  'notifications/getNotifications',
  async (_, { rejectWithValue }) => {
    const res = await api.get('v2/user/notifications');
    if (!res.data.status) {
      return rejectWithValue(res.data.error);
    }
    return res.data;
  }
);

export const getNotificationsMore = createAsyncThunk(
  'notifications/getNotificationsMore',
  async (next) => {
    const response = await api.get(next);
    return response.data;
  }
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    numOfUnread: 0,
    transactions: [],
    notifications: {
      results: [],
      next: '',
      fetched: false,
      fetching: false,
      hasError: false,
    },
  },
  reducers: {
    incrementNumOfUnreadNotifications: (state) => {
      state.numOfUnread += 1;
    },
    resetNumOfUnreadNotifications: (state) => {
      state.numOfUnread = 0;
    },
  },
  extraReducers: {
    [getUnreadNotificationNum.fulfilled]: (state, action) => {
      if (action.payload.status) {
        state.numOfUnread = action.payload.data.unread_notifications;
      }
    },
    [getTransactions.fulfilled]: (state, action) => {
      if (action.payload.status) {
        state.transactions = action.payload.data;
      }
    },
    [getNotifications.pending]: (state, action) => {
      state.notifications.fetched = false;
      state.notifications.fetching = true;
      state.notifications.hasError = false;
    },
    [getNotifications.fulfilled]: (state, action) => {
      state.notifications.results = action.payload.data.results;
      state.notifications.next = action.payload.data.next;
      state.notifications.total = action.payload.data.total;
      state.notifications.fetched = true;
      state.notifications.fetching = false;
    },
    [getNotifications.rejected]: (state, action) => {
      state.notifications.fetched = true;
      state.notifications.fetching = false;
      state.notifications.hasError = true;
    },
    [getNotificationsMore.pending]: (state) => {
      state.notifications.fetching = true;
      state.notifications.hasError = false;
    },
    [getNotificationsMore.fulfilled]: (state, action) => {
      state.notifications.fetching = false;
      state.notifications.results = [
        ...state.notifications.results,
        ...action.payload.data.results,
      ];
      state.notifications.total = action.payload.data.total;
      state.notifications.next = action.payload.data.next;
    },
    [getNotificationsMore.rejected]: (state) => {
      state.notifications.fetching = false;
      state.notifications.hasError = true;
    },
  },
});

export const {
  incrementNumOfUnreadNotifications,
  resetNumOfUnreadNotifications,
} = notificationsSlice.actions;

export const selectTransactions = (state) => state.notifications.transactions;
export const selectNotifications = (state) => state.notifications.notifications;
export const selectNumOfUnreadNotifications = (state) =>
  state.notifications.numOfUnread;

export default notificationsSlice.reducer;
