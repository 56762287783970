import { Avatar } from 'components/avatar';
import ProgressRing from 'components/progressRing';
import styles from './stories.module.css';

const StoryAvatar = ({ isProfile, percentage, user, thumbSize, onClick }) => {
  const sizeGap = isProfile ? 12 : 10;
  const stroke = isProfile ? 3 : 2;
  return (
    <div
      className={styles.avatarWrapper}
      style={{ width: `${thumbSize}px` }}
      onClick={onClick}
    >
      <ProgressRing
        hideText
        animated={isProfile || user.refresh}
        size={thumbSize}
        strokeWidth={stroke}
        percentage={percentage}
      />
      <Avatar
        wrapperStyle={{ position: 'absolute' }}
        image={user.avatar}
        gender={user.gender}
        size={thumbSize - sizeGap}
        circle
      />
    </div>
  );
};

export default StoryAvatar;
