import React from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Drawer from 'react-bottom-drawer';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import {
  toggleCreateDrawer,
  selectCreateDrawer,
} from 'components/layout/layoutSlice';
import {selectUserGender, selectUserInfo} from 'features/auth/authSlice';
import { trackEvent } from 'helpers/tracking';
import { CreateStoryTrigger } from 'features/stories';
import * as Icons from 'assets/icons/mainMenu';
import styles from './bottomDrawer.module.css';
import {useModal} from "../modal/useModal";
import {VerificationModal} from "../../features/dating/episodes/VerificationModal";
import {IconCalendar} from "../../assets/icons";

// TODO: support pre-built types as on TransactionsModal
// actually only CreateDrawer pre-built is needed

export const BottomDrawer = React.memo(({ children, isVisible, onClose }) => {
  const domEl = document.getElementById('drawer-root');

  if (!domEl) return null;
  return ReactDOM.createPortal(
    <div className="drawer">
      <Drawer
        duration={250}
        hideScrollbars
        onClose={onClose}
        isVisible={isVisible}
        className="bottomDrawer"
      >
        {children}
      </Drawer>
    </div>,
    domEl
  );
});

/*
 ** PRE-BUILT TYPES
 */
export function CreateDrawer() {
  const isVisible = useSelector(selectCreateDrawer);
  const userGender = useSelector(selectUserGender);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const verificationModal = useModal();
  const { indispensable_verification } = useSelector(selectUserInfo);

  return (
      <>
        <VerificationModal
            modalContext={verificationModal}
        />
    <BottomDrawer
      isVisible={isVisible}
      onClose={() => dispatch(toggleCreateDrawer(false))}
    >
      <div className={styles.createWrapper}>
        <nav className={styles.nav}>
          <CreateStoryTrigger
            className={styles.createNavLink}
            onCreateStory={() => dispatch(toggleCreateDrawer(false))}
          >
            <Icons.Story className={cx(styles.createIcon, styles.story)} />
            <span className={styles.createLinkText}>
              {t('components:fabButton.createStory')}
            </span>
          </CreateStoryTrigger>
          <NavLink
            onClick={() => {
              trackEvent({
                category: 'menus',
                action: 'create post click',
                label: userGender,
              });
              dispatch(toggleCreateDrawer(false));
            }}
            className={styles.createNavLink}
            to="/create-post"
          >
            <Icons.Feed className={styles.createIcon} />
            <span className={styles.createLinkText}>
              {t('components:fabButton.createPost')}
            </span>
          </NavLink>
          <NavLink
            onClick={(e) => {
              trackEvent({
                category: 'menus',
                action: 'create date request click',
                label: userGender,
              });

              if(indispensable_verification === true)
              {
                e.preventDefault();
                verificationModal.showModal();
                dispatch(toggleCreateDrawer(false));
                return false;
              } else {
                dispatch(toggleCreateDrawer(false));
              }
            }}
            className={styles.createNavLink}
            to="/create-event"
          >
            {/*<Icons.Dating className={styles.createIcon} />*/}
            <IconCalendar className={styles.createIcon}/>
            <span className={styles.createLinkText}>
              {t('components:fabButton.createEvent')}
            </span>
          </NavLink>
        </nav>
      </div>
    </BottomDrawer>
      </>
  );
}
