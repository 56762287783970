import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { useFormikContext } from 'formik';
import Select from 'react-select';

import customStyles from './react-select-customStyles.js';
import formStyles from './forms.module.css';
import { useTranslation } from 'react-i18next';

const CategoriesSelect = ({
  typeName,
  options,
  initOptions,
  withLabel,
  required,
}) => {
  const { setFieldValue } = useFormikContext();
  const [categories, setCategories] = useState([]);
  const [defaults, setDefaults] = useState([]);
  const { t } = useTranslation();

  const parseCategories = (categories) => {
    if (_isEmpty(categories)) {
      return [];
    }
    const parsedCategories = categories.map((category) => {
      return {
        label: category.name,
        value: category.id,
        id: category.id,
      };
    });
    return parsedCategories;
  };

  useEffect(() => {
    const defaults = parseCategories(initOptions);
    setDefaults(defaults);

    const categories = parseCategories(options);
    setCategories(categories);
  }, [options, initOptions]);

  const handleOnChange = (e) => {
    let parsedValues = [];
    if (!_isEmpty(e)) {
      e.map((cat) => {
        return parsedValues.push({ id: cat.id, name: cat.label });
      });
    }
    setFieldValue(typeName, parsedValues);
  };

  if (_isEmpty(categories)) {
    return null;
  }

  return (
    <>
      {withLabel && (
        <label
          className={`${formStyles.formLabel} ${
            required ? formStyles.required : ''
          }`}
          htmlFor={typeName}
        >
          {t('main:main.categories')}
        </label>
      )}
      <Select
        isMulti
        isClearable={false}
        isSearchable={false}
        name={typeName}
        options={categories}
        defaultValue={defaults}
        styles={customStyles}
        classNamePrefix="ohlala"
        onChange={handleOnChange}
        placeholder={t('components:select.placeholder')}
      />
    </>
  );
};

CategoriesSelect.propTypes = {
  options: PropTypes.array,
  initOptions: PropTypes.any,
  required: PropTypes.bool,
  withLabel: PropTypes.bool,
  typeName: PropTypes.string,
};

CategoriesSelect.defaultProps = {
  required: false,
  withLabel: false,
};

export default CategoriesSelect;
