import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getSubscriptionSettings,
  selectGetSubscriptionSettings,
  selectSubscriptionsPriceSettings,
} from './subscriptionsSlice';
import { LoaderRelative } from 'components/layout/loader';
import SubscriptionPriceInput, {
  SubscriptionPriceEditModal,
} from './subscriptionPriceInput/SubscriptionPriceInput';
import { CreateBundleModal } from './subscriptionBundles/SubscriptionBundles';
import SubscriptionBundlesList from './subscriptionBundlesList/SubscriptionBundlesList';
import { useModal } from 'components/modal/useModal';
import { Button } from 'components/buttons';
import EnableSubscriptions from './enableSubscriptions/EnableSubscriptions';
import { selectUserSubscriptionEnabled } from 'features/auth/authSlice';

const SubscriptionsNotEnabled = () => {
  return <p>Subscriptions are not avaliable at this moment</p>;
};

const Subscriptions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, error, success } = useSelector(
    selectGetSubscriptionSettings
  );
  const { price, accepted_conditions } = useSelector(
    selectSubscriptionsPriceSettings
  );
  const subscriptionEnabled = useSelector(selectUserSubscriptionEnabled);
  const createBundleModal = useModal();
  const priceEdit = useModal();

  useEffect(() => {
    dispatch(getSubscriptionSettings());
  }, [dispatch]);

  if (error) return 'error';

  if (loading && !success) return <LoaderRelative />;

  if (!subscriptionEnabled) return <SubscriptionsNotEnabled />;

  if (!accepted_conditions) return <EnableSubscriptions />;

  return (
    <div>
      {!price ? (
        <SubscriptionPriceInput />
      ) : (
        <SubscriptionBundlesList
          onBasePriceEdit={() => priceEdit.showModal()}
        />
      )}

      {price ? (
        <Button
          onClick={() => {
            createBundleModal.showModal();
          }}
          style={{ marginTop: '20px' }}
        >
          {t('features:subscriptions.priceInput.addBundle')}
        </Button>
      ) : null}

      <CreateBundleModal modalContext={createBundleModal} />
      <SubscriptionPriceEditModal modalContext={priceEdit} />
    </div>
  );
};

export default Subscriptions;
