import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AWS from 'aws-sdk';
import shortId from 'short-uuid';

import api from 'app/api';
import InfoBar from 'components/infoBar/InfoBar';
import Button from 'components/buttons/Button';
import { getLayoutImageSrc } from 'helpers/s3media';
import { getFileMeta } from 'helpers/mediaFileMeta';
import { selectUserInfo } from 'features/auth/authSlice';
import { verifyFemale, verifyMale } from 'assets/default';
import styles from './profileEdit.module.css';

const VerifyAccountView = ({ isMobile }) => {
  const user = useSelector(selectUserInfo);
  const type = user.type;
  const [isStarted, setStarted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [vImage, setVimage] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const defaultImageItem = type === 'male' ? verifyMale : verifyFemale;

  const isUploadDisabled = process.env.REACT_APP_DISABLE_UPLOAD === 'true';

  AWS.config.update({
    region: process.env.REACT_APP_S3_BUCKET_REGION,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_S3_POOL_ID,
    }),
  });

  const onCancel = () => {
    if (vImage) {
      setVimage(false);
      setFileToUpload(null);
      setLoading(false);
    } else {
      setStarted(false);
      setLoading(false);
    }
  };

  const onInputChange = (e) => {
    const file = e.target.files[0];
    setFileToUpload(file);
    setVimage(URL.createObjectURL(file));
  };

  const onUpload = async (e) => {
    setLoading(true);
    const file = fileToUpload;
    const fileMeta = await getFileMeta(file);
    if (!fileMeta) {
      return console.log('error');
    }

    const fileExt = file.name.split('.').pop();
    const fileId = `models/${user.uid}/${shortId.generate()}`;
    const s3Key = `${fileId}.${fileExt}`;
    let fileInfo = {
      duration: fileMeta.duration,
      width: fileMeta.width,
      height: fileMeta.height,
      type: fileMeta.type,
      size: file.size,
      format: fileExt,
      public_id: fileId,
    };
    // console.log(fileInfo, fileMeta);

    const upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME_UPLOAD,
        Key: s3Key,
        Body: file,
        ContentType: "image/jpg, image/png, image/jpeg",
      },
    });

    const promise = upload.promise();
    promise.then(
      function (data) {
        fileInfo.s3_data = data;
        sendToVerify(fileInfo);
      },
      function (err) {
        console.log(err);
        setLoading(false);
        toast.error(t('main:errors.somethingWentWrong'));
      }
    );
  };

  const sendToVerify = async (fileInfo) => {
    try {
      const res = await api.put('v2/user/profile/verification', {
        media: JSON.stringify(fileInfo),
      });
      setLoading(false);
      if (!res.data.status) {
        throw new Error();
      }
      setIsSuccess(true);
    } catch (error) {
      setLoading(false);
      toast.error(t('main:errors.somethingWentWrong'));
      // console.log(error);
    }
  };

  if (isUploadDisabled) {
    return <InfoBar badge={'INFO'} info={t('main:uploadDisabled.text')} />;
  }

  return (
    <div className={styles.profileVerify}>
      {isStarted ? (
        <>
          <Trans
            i18nKey={'features:settings.profile.verify.verifyInstructions'}
          >
            <h3 style={{ margin: 0 }}>Here is how you verify:</h3>
            <p>
              1. Write a note that says "Hi Only VIP!". <br />
              2. Take a selfie with this note, showing your face. <br />
              3. Upload your selfie and wait a bit. <br />
              4. Boom - you're verified!
            </p>
          </Trans>
          <div>
            {vImage ? (
              <img
                style={{
                  maxWidth: '70%',
                  maxHeight: '300px',
                  margin: '30px 0',
                  padding: '10px',
                  border: '1px solid #888',
                }}
                src={vImage}
                alt=""
              />
            ) : (
              <img
                style={{ maxWidth: '90%', margin: '30px 0' }}
                src={getLayoutImageSrc(defaultImageItem)}
                alt=""
              />
            )}
          </div>

          {isSuccess ? (
            <p style={{ color: 'var(--turgStrong)', fontWeight: 'bold' }}>
              {t('features:settings.profile.verify.succesMsgText')}
            </p>
          ) : vImage ? (
            <>
              <p>{t('features:settings.profile.verify.picInfo')}</p>
              <Button
                size="xl"
                name="btn-gradient"
                context="form"
                loading={loading}
                onClick={onUpload}
                style={{ position: 'relative' }}
              >
                {t('features:settings.profile.verify.sendToVerify')}
              </Button>
            </>
          ) : (
            <div style={{ position: 'relative', display: 'block' }}>
              <Button size="xl" name="btn-gradient" context="form">
                {t('features:settings.profile.verify.uploadImage')}
                <input
                  className={styles.uploadInput}
                  type="file"
                  name="file"
                  accept="image/*"
                  onChange={onInputChange}
                />
              </Button>
            </div>
          )}

          {!isSuccess && (
            <Button
              onClick={onCancel}
              style={{
                fontWeight: 'bold',
                color: '#888',
                margin: '20px auto',
              }}
              name="btn-bare"
            >
              {t('main:main.Cancel')}
            </Button>
          )}
        </>
      ) : (
        <>
          <Trans i18nKey={'features:settings.profile.verify.verifyExplanation'}>
            <h3 style={{ margin: 0 }}>Make your profile shine</h3>
            <p>
              Authenticity is important for the safety of our community <br />
              Take a moment to verify yourself, please.
            </p>
          </Trans>
          <img
            style={{ maxWidth: '90%', margin: '30px 0' }}
            src={getLayoutImageSrc(defaultImageItem)}
            alt=""
          />

          <Button
            onClick={() => setStarted(true)}
            size="xl"
            name="btn-gradient"
            context="form"
          >
            {t('features:settings.profile.verify.verifyMe')}
          </Button>
        </>
      )}
    </div>
  );
};

export default VerifyAccountView;
