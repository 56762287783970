import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectFeedCategories } from 'app/miscSlice';
import Button from 'components/buttons/Button';
import { FormRow, Radio, FormFieldError } from 'components/forms';
import CategoriesSelect from 'components/forms/CategoriesSelect';
import formStyles from 'components/forms/forms.module.css';
import styles from 'features/auth/auth.module.css';

const FiltersModal = ({
  onChange,
  modalContext: { RenderModal, hideModal },
  filters,
}) => {
  const { t } = useTranslation();
  const categories = useSelector(selectFeedCategories);

  const handleSubmit = (values) => {
    onChange(values);
    hideModal();
  };

  const getInitialFilterValues = () => {
    // check current filters ?
    // check user ?
    return filters;
  };

  return (
    <RenderModal title={t('features:feed.filters.modalTitle')} buttonClose>
      <div>
        <br />
        {/*<p
          style={{
            margin: '-10px 0 20px',
            padding: '0 0.5rem',
            color: 'var(--grey3)',
            lineHeight: '1.5',
          }}
        >
          {t('features:feed.filters.modalText')}
        </p>*/}
        <Formik
          initialValues={getInitialFilterValues()}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormRow>
              <label className={`${formStyles.formLabel}`} htmlFor="full_name">
                {t('features:auth.register.gender')}
              </label>
              <label className={styles.checkboxLabel} htmlFor="male">
                <Radio
                  className={styles.checkBox}
                  type="checkbox"
                  id="male"
                  name="gender"
                  value={2}
                >
                  {t('main:main.Male')}
                </Radio>
              </label>
              <label className={styles.checkboxLabel} htmlFor="female">
                <Radio
                  className={styles.checkBox}
                  type="checkbox"
                  id="female"
                  name="gender"
                  value={3}
                >
                  {t('main:main.Female')}
                </Radio>
              </label>
              <label className={styles.checkboxLabel} htmlFor="both">
                <Radio
                  className={styles.checkBox}
                  type="checkbox"
                  id="both"
                  name="gender"
                  value={1}
                >
                  {t('main:main.MaleAndFemale')}
                </Radio>
              </label>
            </FormRow>
            <FormRow>
              <CategoriesSelect
                withLabel
                options={categories}
                typeName="feedCategories"
                initOptions={filters.feedCategories}
              />
              <FormFieldError fieldName="feedCategories" />
            </FormRow>
            <FormRow>
              <Button type="submit" fullWidth={true}>
                {t('features:feed.filters.buttonSubmit')}
              </Button>

              <Button
                onClick={() => hideModal()}
                type="button"
                fullWidth={true}
                name="btn-grey"
                style={{ marginTop: '10px' }}
              >
                {t('main:main.Cancel')}
              </Button>
            </FormRow>
          </Form>
        </Formik>
      </div>
    </RenderModal>
  );
};

export default FiltersModal;
