import Avatar from "../../../components/avatar/Avatar";
import {monthDateTime} from "../../../helpers/dating";
import React, {useState} from "react";
import {de, enGB} from "date-fns/locale";
import {Col, Row} from "../../../components/layout/grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendar,
    faMars, faPersonCircleXmark, faUsers, faVenus
} from "@fortawesome/free-solid-svg-icons";
import {setShowPublicRegister} from "../../../app/miscSlice";
import {trackEvent} from "../../../helpers/tracking";
import {useDispatch, useSelector} from "react-redux";
import styles from "../../dating/clubs/clubDetail.module.css";
import EpisodeButtons from "./EpisodeButtons";
import {LoaderInline} from "../../../components/layout/loader";
import {useTranslation} from "react-i18next";
import {selectAuthenticated, selectUserInfo} from "../../auth/authSlice";
import {useHistory} from "react-router-dom";
import {IconMapMark} from "../../../assets/icons";
//import {ShareButtonEventRound} from "../../user/profileInfo/Buttons";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import EventRequestModal from "./EventRequestModal";
import {useModal} from "../../../components/modal/useModal";
import {startChatTransaction} from "../../transactions/transactionsSlice";
import Description from "../../user/profileInfo/Description";
import {useWindowWidthLessThan} from "../../../hooks";
import {ShareEventButton} from "../../user/profileInfo/Buttons";
import cx from "classnames";
import CancellationPolicyModal from "./CancellationPolicyModal";
import QRModal from "./QRModal";

const googleMapsLibraries = ['places'];

const EventDetails = ({episode, hideFilter, isPublic}) => {
    const {i18n} = useTranslation();
    const lang = i18n.language === 'de' ? de : enGB;
    //const history = useHistory();
    const dispatch = useDispatch();
    const [isBlocked, setBlocked] = useState(false);
    const me = useSelector(selectUserInfo);
    const {t} = useTranslation();
    const isMobile = useWindowWidthLessThan(769);
    const {venue} = episode;
    /*const firstImage = episode.images.length > 0 ? episode.images[0] : null;
    const firstImageUrl = firstImage ? getImageSrc({
        public_id: firstImage.image,
        format: JSON.parse(firstImage.extra).format,
        resize: {
            width: 400,
            fit: 'cover',
        },
    }) : getImageSrc(bannerDefault);*/

    const firstImageUrl = JSON.parse(venue.photos)[0];


    const isAuthenticated = useSelector(selectAuthenticated);
    const history = useHistory();
    const viewAllModal = useModal();
    const cancellationAllModalPop = useModal();
    const qRModalPop = useModal();

    const tracking = () => {
        trackEvent({
            category: `event-request-join`,
            action: 'request to join clicked',
            label: '',
        });
    }

    const handleUserClick = (profile) => {
        if (isAuthenticated) {
            return history.push(`/profile/${profile.id}`);
        }
        return dispatch(setShowPublicRegister(true, profile));
    };

    const handleViewMyEvents = (profile) => {
        if (isAuthenticated) {
            return history.push('/my-event-requests/');
        }
        return dispatch(setShowPublicRegister(true, profile));
    };


    const mapStyles = {
        height: '300px',
        width: '100%',
    };


    //const center = useMemo(() => ({ lat: episode.latitude, lng: episode.longitude }), []);
    const center = {
        lat: parseFloat(venue.latitude), // Replace with your desired latitude
        lng: parseFloat(venue.longitude), // Replace with your desired longitude
    };

    // Define custom map styles for a lighter color theme
    /*const customMapStyles = [
        {
            featureType: 'all',
            elementType: 'all',
            stylers: [
                {
                    lightness: 70, // Adjust the lightness value to control brightness
                },
            ],
        },
    ];*/

    /*const handleChat = (profileUser) => {
        trackEvent({
            category: `event request' : ''}`,
            action: 'open chat click',
            label: gender,
        });

        if (profileUser.chat_open) {
            history.push(`/chat/${uid}-${profileUser.uid}-messaging`);
        } else {
            viewAllModal.hideModal();
            dispatch(startChatTransaction({actor: profileUser}));
        }
    };*/

    const handleOpenChat = (profile, chat_id, episodeId) => {
        trackEvent({
            category: `event request' : ''}`,
            action: 'open chat click',
            label: me.gender,
        });
        viewAllModal.hideModal();

        if (isPublic) {
            dispatch(setShowPublicRegister(true));
        } else {
            if (chat_id > 0) {
                history.push(`/chat/${me.uid}-${profile.uid}-messaging`);
            } else {
                dispatch(startChatTransaction({actor: profile, episodeId: episodeId}));
            }
        }
    };

    return (
        <>
            <Row>
                <Col xl={12}>
                    <div>
                        <div className={styles.eventdetail}>
                            <div className={styles.eventdetailhead}>
                                <div className={styles.qrcode} onClick={() => qRModalPop.showModal()}>
                                    <img src={episode.qrcode_url} alt={episode.id}/>
                                </div>
                                <QRModal
                                    lang={lang}
                                    episode={episode}
                                    modalContext={qRModalPop}
                                    handleViewMyEvents={handleViewMyEvents}
                                    cancellationAllModalPop={cancellationAllModalPop}
                                />
                                <div className={styles.imgover}>
                                    {/*<div className={styles.backarrow}>
                                        <FontAwesomeIcon icon={faArrowLeft} width={20}/>
                                    </div>*/}
                                    {/*<div className={styles.dots}>
                                        <IconDots/>
                                    </div>*/}
                                    {/*<EpisodeActions/>*/}
                                </div>
                                <div className={styles.imgoverbottom}>
                                    {/*<h1>{ episode.name }</h1>
                                    <span>{ episode.venue_name }</span>*/}
                                </div>
                                <div className={cx(styles.imageContainer, styles.imageContainerBanner)}>
                                    <div>
                                        <img
                                            src={firstImageUrl}
                                            className={styles.eventimg}
                                            alt={episode.name}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.eventavatar}>
                                <div className={styles.avatarimg}>
                                    <Avatar
                                        /*onClick={onClick}*/
                                        size={80}
                                        /*wrapperStyle={{ position: 'absolute', top: '12px', left: '12px' }}*/
                                        image={episode.user.avatar}
                                        gender={episode.user.gender}
                                        circle
                                        className={styles.imguserover}
                                    />
                                    {/*<img
                                            src="https://images.unsplash.com/photo-1564564321837-a57b7070ac4f?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8bWFufGVufDB8fDB8fHww&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60"/>*/}
                                </div>
                                <div className={styles.username}>{episode.user.name} <span>{episode.user.age}</span>
                                    {/*<FontAwesomeIcon icon={faCertificate} width={20}/>*/}
                                </div>
                                {/*<div className={styles.sharebutton}>
                                    <ShareButtonEventRound episode={episode} />
                                </div>*/}
                            </div>

                            {/*{episode.event_type &&
                                <div className={styles.category}>
                                    {episode.event_type}
                                </div>
                            }*/}
                            <div className={styles.main}>
                                <div className={styles.category}>
                                    {episode.event_type}
                                </div>
                                <div className={styles.sharebutton}>
                                    <ShareEventButton episode={episode} withText={true}/>
                                </div>
                            </div>
                            <div className={styles.main}>
                                <div className={styles.left}>
                                    <div className={styles.title}>
                                        <h2>{episode.name}</h2>
                                    </div>

                                    {episode.description.length > 0 &&
                                    <div className={styles.description}>
                                        {episode.description.length > 0 && (
                                            <Description description={episode.description} limit={isMobile ? 50 : 100}/>
                                        )}
                                    </div>
                                    }
                                </div>
                            </div>

                            <ul className={styles.eventdetaillist}>
                                {/*<li>
                                    <FontAwesomeIcon icon={faChampagneGlasses} width={20}/>
                                    <strong>{episode.name}</strong></li>*/}
                                <li>
                                    <FontAwesomeIcon icon={faCalendar} width={20}/>
                                    {monthDateTime(episode, lang)}
                                </li>
                                {/*<li>
                                        <FontAwesomeIcon icon={faStar} width={20}/>
                                        DJ Rick
                                    </li>*/}
                                <li>
                                    {/*<FontAwesomeIcon icon={faLocation} width={20}/>*/}
                                    <IconMapMark height={20}/>
                                    <a href="#map">{venue.address}</a>
                                </li>
                                {episode.applications.length > 0 &&
                                <li>
                                    <FontAwesomeIcon icon={faUsers} width={20} style={{marginTop: '5px'}}/>
                                    {
                                        episode.applications.map((application, i) => {
                                            return (
                                                <Avatar
                                                    key={i}
                                                    size={25}
                                                    onClick={() => handleUserClick(application.profile)}
                                                    image={application.profile.avatar}
                                                    gender={application.profile.gender}
                                                    circle
                                                />
                                            );
                                        })
                                    }
                                    <strong className={styles.viewAll}
                                            onClick={() => viewAllModal.showModal()}
                                    >
                                        {t('features:event.view_request', 'View All')}
                                    </strong>
                                    <EventRequestModal
                                        episode={episode}
                                        modalContext={viewAllModal}
                                        handleUserClick={handleUserClick}
                                        handleOpenChat={handleOpenChat}
                                    />
                                </li>
                                }
                                <li>
                                    <FontAwesomeIcon icon={faPersonCircleXmark} width={20}/>
                                    <span>{t('features:eventRequest.cancellation', 'CANCELLATION POLICY: ')}</span>
                                    <strong className={styles.cancellationPolicy}
                                            onClick={() => cancellationAllModalPop.showModal()}
                                    >{episode.cancellation_policy}</strong>
                                    {/*<CancellationPolicyModal modalContext={viewCancellationPolicyModal}/>*/}
                                    <CancellationPolicyModal
                                        modalContext={cancellationAllModalPop}
                                    />
                                </li>

                            </ul>

                            <div className={styles.row}>
                                {episode.desired_female_company > 0 &&
                                <div className={styles.col}>
                                    <div className={styles.genderbox}>
                                        <FontAwesomeIcon icon={faVenus} width={20}/>
                                        <strong>{episode.desired_female_company}x</strong>
                                        {
                                            episode.desired_female_company_price === 'free' ? (
                                                    t('features:eventRequest.free'))
                                                :
                                                (
                                                    <span>{episode.desired_female_company_price} {episode.profile.user_currency}</span>)
                                        }
                                    </div>
                                </div>
                                }
                                {episode.desired_male_company > 0 &&
                                <div className={styles.col}>
                                    <div className={styles.genderbox}>
                                        <FontAwesomeIcon icon={faMars} width={20}/>
                                        <strong>{episode.desired_male_company}x</strong>
                                        {
                                            episode.desired_male_company_price === 'free' ? (
                                                    t('features:eventRequest.free'))
                                                :
                                                (
                                                    <span>{episode.desired_male_company_price} {episode.profile.user_currency}</span>)
                                        }
                                    </div>
                                </div>
                                }
                            </div>
                            {isPublic &&
                            <div className={styles.buttonouter}>
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        tracking();
                                        dispatch(setShowPublicRegister(true));
                                    }}
                                    className={styles.btn}>Request to join
                                </div>
                            </div>
                            }
                            {!isPublic &&
                            <div className={styles.buttonouter}>
                                <EpisodeButtons
                                    episode={episode}
                                    loaderCallback={setBlocked}
                                />
                            </div>
                            }
                            {/*{!isPublic && !isEmpty(applications) && <ApplicationsList episode={episode}/>}*/}

                        </div>

                        {venue.latitude && venue.longitude &&
                        <div className={styles.map} id="map">
                            <LoadScript
                                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                                libraries={googleMapsLibraries}
                            >
                                <GoogleMap
                                    zoom={14}
                                    center={center}
                                    mapContainerStyle={mapStyles}
                                    mapTypeId="roadmap"
                                    /*options={{
                                        styles: customMapStyles, // Apply custom map styles
                                    }}*/
                                >
                                    <Marker position={center}/>
                                </GoogleMap>
                            </LoadScript>
                        </div>
                        }
                        {/*<MyEpisodesListItem episode={episode} isDetail/>*/}
                    </div>
                </Col>
            </Row>

            {
                isBlocked && (
                    <div className={styles.overlay}>
                        <LoaderInline centered color="turq"/>
                    </div>
                )
            }
        </>
    )
        ;
}

export default EventDetails;