import React, { useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Checkbox, FormRow } from 'components/forms';
import Button from 'components/buttons/Button';
import { LoaderRelative } from 'components/layout/loader';
import {
  selectNotificationsInfo,
  getNotificationsInfo,
  setNotificationsInfo,
  cleanNotificationsInfo,
} from '../settingsSlice';
import { updateUser, selectUserInfo } from 'features/auth/authSlice';

const NotificationsEditView = () => {
  const notificationsInfo = useSelector(selectNotificationsInfo);
  const user = useSelector(selectUserInfo);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getNotificationsInfo());
    return () => {
      dispatch(cleanNotificationsInfo());
    };
  }, [dispatch]);

  const onUpdate = async (payload) => {
    await dispatch(setNotificationsInfo(payload))
      .then(unwrapResult)
      .then((result) => {
        if (result.status) {
          toast.success(t('features:settings.profile.edit.savedSuccessfully'));
          dispatch(updateUser());
        } else {
          toast.error(result.error.message);
        }
      });
  };

  if (_isEmpty(notificationsInfo)) {
    return <LoaderRelative />;
  }

  return (
    <Formik
      initialValues={notificationsInfo}
      onSubmit={async (values) => {
        await onUpdate(values);
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form>
          {user.verified_phone && (
            <FormRow>
              <Checkbox name="allow_sms_notification" value={true}>
                {t('features:notifications.getSms')}
              </Checkbox>
              <hr style={{ margin: '30px 0 0' }} />
            </FormRow>
          )}
          <FormRow>
            <Checkbox name="allow_email_notification" value={true}>
              {t('features:notifications.getEmail')}
            </Checkbox>
            <hr style={{ margin: '30px 0 0' }} />
          </FormRow>
          <FormRow>
            <Checkbox name="allow_newsletter" value={true}>
              {t('features:notifications.getUpdates')}
            </Checkbox>
            <hr style={{ margin: '30px 0 0' }} />
          </FormRow>
          <FormRow>
            <Button
              size="xl"
              name="btn-gradient"
              type="submit"
              context="form"
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {t('main:main.Submit')}
            </Button>
          </FormRow>
        </Form>
      )}
    </Formik>
  );
};

export default NotificationsEditView;
