import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

import {
  removeFeedCategory,
  applyFilters,
  selectFilters,
  clearGenderFilter,
} from 'app/miscSlice';
import { resetSearch } from 'features/userList/search/searchSlice';
import { resetGlobalFeed } from 'features/feed/feedSlice';
import { resetUserList } from 'features/userList/userListSlice';
import { resetEpisodesList } from 'features/dating/episodes/episodesSlice';
import { resetStories } from 'features/stories/storiesSlice';
import FiltersModal from './FiltersModal';
import { Button } from 'components/buttons';
import { useModal } from 'components/modal/useModal';
import { IconFilter, IconX } from 'assets/icons';
import { useUrlQuery } from 'hooks';
import styles from './navFilters.module.css';

const NavFilters = () => {
  const filters = useSelector(selectFilters);
  const filterModal = useModal();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const query = useUrlQuery();
  const { slug } = useParams();
  const ids = query.get('cat');
  const categories = ids || slug || null;

  const resetLists = () => {
    dispatch(resetSearch());
    dispatch(resetUserList());
    dispatch(resetEpisodesList());
    dispatch(resetGlobalFeed());
    dispatch(resetStories());
  };

  const onFilterSubmit = (values) => {
    if (!isEmpty(categories)) {
      history.push('/feed');
    }
    dispatch(applyFilters(values));
    resetLists();
    window.localStorage.setItem('lookingFor', values.gender);
  };

  const CategoryFilter = () => {
    const label = t('main:main.category');
    if (isEmpty(filters.feedCategories)) return null;

    return (
      <>
        {filters.feedCategories.map((cat, index) => {
          return (
            <FilterLabel
              key={index}
              label={label}
              onRemove={() => {
                if (!isEmpty(categories)) {
                  history.push('/feed');
                }
                dispatch(removeFeedCategory(cat.id));
                dispatch(resetGlobalFeed());
              }}
            >
              {cat.name}
            </FilterLabel>
          );
        })}
      </>
    );
  };

  const GenderFilter = () => {
    const { gender } = filters;
    const label = t('main:main.gender');
    const onRemove = () => {
      dispatch(clearGenderFilter());
      resetLists();
    };

    if (gender === 1) return null;

    if (gender === 2) {
      return (
        <FilterLabel label={label} onRemove={onRemove}>
          {t('main:main.Male')}
        </FilterLabel>
      );
    }

    return (
      <FilterLabel label={label} onRemove={onRemove}>
        {t('main:main.Female')}
      </FilterLabel>
    );
  };

  const FilterLabel = ({ children, onRemove, label }) => {
    return (
      <div className={styles.label}>
        <div className={styles.left}>
          <strong className={styles.title}>{label}: </strong>
          <span> {children}</span>
        </div>
        <div className={styles.right}>
          <Button onClick={onRemove} name="btn-bare" className={styles.button}>
            <IconX />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.activeFilters}>
        <GenderFilter /> <CategoryFilter />
      </div>
      <Button
        className={styles.iconBtn}
        name="btn-bare"
        onClick={() => filterModal.showModal()}
      >
        <IconFilter />
      </Button>
      <FiltersModal
        filters={filters}
        onChange={onFilterSubmit}
        modalContext={filterModal}
      />
    </div>
  );
};

export default NavFilters;
