import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { trackEvent } from 'helpers/tracking';
import styles from './profile.module.css';

const ProfileContent = ({ user, feed, videos, photos, info, reverseOrder }) => {
  const [active, setActive] = useState(0);
  const { t } = useTranslation();

  const showFeed = feed && user.stats.total_posts > 0;
  const showVideos = videos && user.stats.total_videos > 0;
  const showPhotos = photos && user.stats.total_images > 0;

  const showTabs = showFeed || showVideos || showPhotos;
  const tabsClass = cx(styles.tabs, {
    [styles.noMedia]: !showVideos && !showPhotos,
  });

  useEffect(() => {
    if (!reverseOrder) {
      if (showFeed) {
        setActive(1);
      } else if (showVideos) {
        setActive(2);
      } else if (showPhotos) {
        setActive(3);
      }
    }
  }, [showFeed, showVideos, showPhotos, reverseOrder]);

  const handleClick = (index) => {
    if (index !== active) {
      setActive(index);
    }
  };
  return (
    <>
      {showTabs && (
        <div className={styles.tabsWrapper}>
          <ul
            className={tabsClass}
            style={reverseOrder ? { flexDirection: 'row-reverse' } : null}
          >
            {showFeed && (
              <li
                onClick={() => {
                  trackEvent({
                    category: 'profile pages - public',
                    action: 'feed tab click',
                  });
                  handleClick(1);
                }}
                className={cx(styles.tab, { [styles.tabActive]: active === 1 })}
              >
                <span>{t('features:profileTabs.Posts')}</span>
              </li>
            )}
            {showVideos && (
              <li
                onClick={() => {
                  trackEvent({
                    category: 'profile pages - public',
                    action: 'videos tab click',
                  });
                  handleClick(2);
                }}
                className={cx(styles.tab, { [styles.tabActive]: active === 2 })}
              >
                <span>{t('features:profileTabs.Videos')}</span>
              </li>
            )}
            {showPhotos && (
              <li
                onClick={() => {
                  trackEvent({
                    category: 'profile pages - public',
                    action: 'images tab click',
                  });
                  handleClick(3);
                }}
                className={cx(styles.tab, { [styles.tabActive]: active === 3 })}
              >
                <span>{t('features:profileTabs.Images')}</span>
              </li>
            )}
            <li
              onClick={() => {
                trackEvent({
                  category: 'profile pages - public',
                  action: 'info tab click',
                });
                handleClick(0);
              }}
              className={cx(styles.tab, { [styles.tabActive]: active === 0 })}
            >
              <span>{t('features:profileTabs.Info')}</span>
            </li>
          </ul>
        </div>
      )}
      {active === 1 && showFeed && feed}
      {active === 2 && showVideos && videos}
      {active === 3 && showPhotos && photos}
      {active === 0 && <div className={styles.contentWrapper}>{info}</div>}
    </>
  );
};

ProfileContent.propTypes = {
  user: PropTypes.object.isRequired,
  info: PropTypes.element.isRequired,
  feed: PropTypes.element,
  videos: PropTypes.element,
  photos: PropTypes.element,
  reverseOrder: PropTypes.bool,
};

export default ProfileContent;
