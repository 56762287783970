//import PropTypes from 'prop-types';
import React from 'react';
//import {Field} from 'formik';
//import styles from './dateTypeCheckBoxes.module.css';
import {FormRow, Input, Label, Select} from '../../../components/forms';
//import * as Icons from '../../../assets/icons/episode';
import {useTranslation} from 'react-i18next';
//import CountryCitySelect from "../../../components/forms/CountryCitySelect";

import {useSelector} from "react-redux";
import AWS from "aws-sdk";
/*import {getFileMeta} from "../../../helpers/mediaFileMeta";
import shortId from "short-uuid";
import {setTempFile} from "../../feed/feedSlice";
//import {isEmpty} from "lodash";
import {FilePond} from "react-filepond";
import style from "./stepOne.module.css";
import Button from "../../../components/buttons/Button";
import {IconAddMedia} from "../../../assets/icons";
import InfoBar from "../../../components/infoBar/InfoBar";*/
//import PAutocomplete from "../../../components/placeAutocomplete/PAutoComplete";
import {LoadScript} from "@react-google-maps/api";
import PlacesAutocomplete from "../../../components/placeAutocomplete/PlaceAutocomplete";
import {selectEventTypes} from "../../../app/miscSlice";
import {parseSelectOptions} from "../../../helpers/parseSelectOptions";

/*export const DateTypeCheckBox = ({name, value}) => {
    const {t} = useTranslation();
    return (
        <Field name={name}>
            {({field, form}) => (
                <label className={styles.checkboxLabel}>
                    <input
                        className={styles.checkboxInput}
                        type="radio"
                        name={name}
                        value={value}
                        checked={field.value.includes(value)}
                        onChange={() => {
                            form.touched[name] = true;
                            if (field.value.includes(value)) {
                                const nextValue = field.value.filter((v) => v !== value);
                                form.setFieldValue(name, nextValue);
                            } else {
                                const nextValue = field.value.concat(value);
                                form.setFieldValue(name, nextValue);
                            }
                        }}
                    />
                    <div className={styles.dateType}>
                        {value === 'drinks' ? (
                            field.value.includes(value) ? (
                                <Icons.DrinksActive/>
                            ) : (
                                <Icons.DrinksInactive/>
                            )
                        ) : null}
                        {value === 'dinner' ? (
                            field.value.includes(value) ? (
                                <Icons.DinnerActive/>
                            ) : (
                                <Icons.DinnerInactive/>
                            )
                        ) : null}
                        {value === 'ohlala' ? (
                            field.value.includes(value) ? (
                                <Icons.OhlalaActive/>
                            ) : (
                                <Icons.OhlalaInactive/>
                            )
                        ) : null}
                        {t(`features:eventRequest.${value}`, value)}
                        <div className={styles.plus}></div>
                    </div>
                </label>
            )}
        </Field>
    );
};*/

/*DateTypeCheckBox.propTypes = {
    inputClass: PropTypes.string,
    labelClass: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
};*/

const googleMapsLibraries = ['places'];

const StepOne = ({user, updateParentFiles}) => {
    const {t} = useTranslation();
    const eventTypes = useSelector(selectEventTypes);
    //const [files, setFiles] = useState([]);
    //const [validCount, setValidCount] = useState(0);
    //const [isUploading, setIsUploading] = useState(false);
    //const dispatch = useDispatch();
    //const [tempID] = useState('');

    //const pond = useRef(null);
    //const isUploadDisabled = process.env.REACT_APP_DISABLE_UPLOAD === 'true';

    const parsedOptions = parseSelectOptions(eventTypes, 'id', 'name');

    AWS.config.update({
        region: process.env.REACT_APP_S3_BUCKET_REGION,
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.REACT_APP_S3_POOL_ID,
        }),
    });

    /*const uploadS3 = useCallback(
        () => ({
            process: async (
                fieldName,
                file,
                metadata,
                load,
                error,
                progress,
                abort
            ) => {
                const fileMeta = await getFileMeta(file);
                if (!fileMeta) {
                    return error();
                }

                const fileExt = file.name.split('.').pop();
                const fileId = `episodes/${user.uid}/${shortId.generate()}`;
                const s3Key = `${fileId}.${fileExt}`;
                let fileInfo = {
                    duration: fileMeta.duration,
                    width: fileMeta.width,
                    height: fileMeta.height,
                    type: fileMeta.type,
                    size: file.size,
                    format: fileExt,
                    public_id: fileId,
                };

                const upload = new AWS.S3.ManagedUpload({
                    params: {
                        Bucket: process.env.REACT_APP_S3_BUCKET_NAME_UPLOAD,
                        Key: s3Key,
                        Body: file,
                        ContentType: "image/jpg, image/png, image/jpeg",
                    },
                });
                upload.on('httpUploadProgress', (e) => {
                    progress(true, e.loaded, e.total);
                });

                const promise = upload.promise();
                promise.then(
                    function (data) {
                        fileInfo.s3_data = data;
                        load({body: fileInfo});
                        dispatch(
                            setTempFile({
                                temp_id: `${user.uid}_${tempID}`,
                                file_info: fileInfo,
                            })
                        );
                    },
                    function (err) {
                        return error(err.message);
                    }
                );

                return {
                    abort: () => {
                        console.log('abort');
                        abort();
                    },
                };
            },
            revert: null,
        }),
        [dispatch, tempID, user.uid]
    );

    if (isUploadDisabled) {
        return (
            <>
                {/!*<SubHeader backBtn screenName={screenName}/>*!/}
                <InfoBar badge={'INFO'} info={t('main:uploadDisabled.text')}/>
            </>
        );
    }*/

    /*const getPostFiles = () => {
        let validFiles = [];
        files.forEach(function (item, index) {
            if (item.serverId) {
                validFiles.push(item.serverId);
            }
        });
        return validFiles;
    };*/

    /*const submitDisabled = (value) => {
        return (
            (isEmpty(value.trim().replace(/(<([^>]+)>)/gi, '')) && !validCount) ||
            isUploading
        );
    };*/

    /*const handleReorder = (items, origin, target) => {
        setFiles(items);
        updateParentFiles(items);
    };

    const handleSetFiles = (items) => {
        const newCount = items.filter((obj) => obj.status === 5).length;
        setFiles(items);
        setValidCount(newCount);
        updateParentFiles(items);
    };

    const handleProcessFile = (error, file) => {
        const newCount = file.status === 5 ? validCount + 1 : validCount;
        setValidCount(newCount);
    };

    const handleProcessFileStart = (file) => {
        //setIsUploading(true);
    };

    const handleProcessFiles = () => {
        //setIsUploading(false);
    };*/

    return (
        <div>
            <FormRow>
                <Input
                    label={t('features:eventRequest.name')}
                    name="name"
                    required
                />
            </FormRow>
            <FormRow>
                <Label
                    label={t('features:eventRequest.event_type')}
                    name="dateType"
                >
                    <Select
                        required
                        label=''
                        name="dateType"
                        options={parsedOptions}
                    />
                    {/*<div className={styles.error}>
                        <FormFieldError fieldName="dateType"/>
                    </div>*/}
                </Label>
            </FormRow>

            {/*<FormRow>
                <div className="episodeLocation" style={{ marginTop: '30px' }}>
                    <CountryCitySelect isDatingUser={user.dating}  withLabel={true}/>
                </div>
            </FormRow>*/}

            <FormRow>
                {/*<Input
                    label={t('features:eventRequest.venue_name')}
                    name="venue_name"
                    required
                />*/}
                {/*<PAutocomplete/>*/}
                <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                    libraries={googleMapsLibraries}
                >
                <PlacesAutocomplete />
                </LoadScript>

            </FormRow>

            {/*<FormRow>
                <div className={`maleImages`} style={{ OverFlow:'hidden'}}>
                <FilePond
                    ref={pond}
                    dropOnPage
                    dropOnElement={false}
                    allowReorder
                    allowMultiple
                    files={files}
                    onupdatefiles={handleSetFiles}
                    onreorderfiles={handleReorder}
                    onprocessfile={handleProcessFile}
                    onprocessfilestart={handleProcessFileStart}
                    onprocessfiles={handleProcessFiles}
                    //onremovefile={handleRemoveFile}
                    credits={false}
                    itemInsertLocation="after"
                    imagePreviewHeight="300px"
                    allowFileTypeValidation
                    acceptedFileTypes={[
                        'image/*'
                    ]}
                    fileValidateTypeLabelExpectedTypes=""
                    labelFileTypeNotAllowed={t(
                        'features:createPost.invalidFile',
                        'File not valid!'
                    )}
                    labelIdle={t(
                        'features:images.dragdrop',
                        'Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    )}
                    server={uploadS3()}
                    // onactivatefile={(file) => console.log(file.serverId)}
                />

                <div className={`${style.postActions}`}>
                    <span>
                        <Button
                            onClick={() => pond.current && pond.current.browse()}
                            type="button"
                            name="btn-bare"
                        >
                                <IconAddMedia className={style.icon}/>
                                <span className={style.actionText}>
                                  {t('features:createPost.addMedia', 'Add media ')}
                                </span>
                              </Button>
                        </span>
                </div>

                <InfoBar
                    info={
                        <div dangerouslySetInnerHTML={{__html: t('features:createPost.contentWarning') }}>
                        </div>
                    }
                />

            </div>
            </FormRow>*/}
        </div>
    );
};

export default StepOne;
