import { isEmpty, orderBy } from 'lodash';

export const getProfileRank = (user) => {
  if (isEmpty(user)) return null;

  // defining the relevance of each field
  const fields = {
    avatar: 5,
    name: 5,
    images: 4,
    town: 4,
    //verification_required: 4,
    verified_phone: 3,
    age: 3,
    about_status: 2,
    size: 1,
    smoker: 1,
    profession: 1,
    body_type: 1,
    hair_color: 1,
    hair_length: 1,
  };

  const validateField = (key, value) => {
    // special fields
    if (key === 'verification_required') {
      return value === false;
    }
    if (key === 'about_status') {
      return value === 3;
    }
    if (key === 'images') {
      return Array.isArray(value) && value.length > 2;
    }

    // standard fields
    switch (typeof value) {
      case 'boolean':
        return value === true;
      case 'number':
        return value > 0;
      default:
        return !isEmpty(value);
    }
  };

  const getRank = () => {
    let sum = 0;
    const legend = [{ label: 'info', value: true, rank: 1 }];

    for (const [key, value] of Object.entries(user)) {
      if (fields.hasOwnProperty(key)) {
        const fieldRank = fields[key];
        if (validateField(key, value)) {
          sum += fieldRank;
          if (fieldRank > 1) {
            legend.push({ label: key, value: true, rank: fieldRank });
          }
        } else {
          if (fieldRank === 1) {
            legend.find((o) => o.label === 'info').value = false;
          } else {
            legend.push({ label: key, value: false, rank: fieldRank });
          }
        }
      }
    }
    const resortLegend = orderBy(legend, ['rank'], ['desc']);
    return { rank: sum, legend: resortLegend };
  };

  const { rank, legend } = getRank();

  const minimum = 0;
  const maximum = Object.values(fields).reduce((prev, curr) => prev + curr, 0);

  const percentage = 100 * ((rank - minimum) / (maximum - minimum));

  return { rank: Math.trunc(percentage), legend };
};

export default getProfileRank;
