import Button from 'components/buttons/Button';
import { IconRefresh } from 'assets/icons';
import { trackEvent } from 'helpers/tracking';
import styles from './chatError.module.css';

const ChatError = () => {
  const onBtnClick = () => {
    trackEvent({
      category: 'chat',
      action: 'error click reload',
    });
    window.location.reload();
  };

  return (
    <div className={styles.container}>
      <h1>Error connecting chat server</h1>
      <Button onClick={onBtnClick} className={styles.btn} name="btn-submit">
        <span>Please refresh the page</span>{' '}
        <IconRefresh className={styles.icon} />
      </Button>
    </div>
  );
};

export default ChatError;
