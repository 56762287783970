/*
 ** Default images
 */

export const authLeft = {
  version: '1645440531',
  public_id: 'assets/mobile-home-onlyvip',
  type: 'image',
  format: 'png',
};

export const profileBanner = {
  version: '1610103131',
  public_id: 'assets/profile-top-empty',
  type: 'image',
  format: 'jpg',
};

export const feedEmpty = {
  version: '1610103131',
  public_id: 'assets/post-feed-empty',
  type: 'image',
  format: 'jpg',
};

export const homeBanner = {
  version: '1613581868',
  public_id: 'assets/date-banner-img',
  type: 'image',
  format: 'jpg',
};

export const avatarMale = {
  version: '1610103131',
  public_id: 'assets/man-profile',
  type: 'image',
  format: 'jpg',
};

export const avatarFemale = {
  version: '1610103131',
  public_id: 'assets/woman-profile',
  type: 'image',
  format: 'jpg',
};

export const bannerDefault = {
  version: '1610103131',
  public_id: 'assets/banner-proof',
  type: 'image',
  format: 'jpg',
};

export const verifyFemale = {
  version: '1612879628',
  public_id: 'assets/verify2-female',
  resize: { width: 500, fit: 'cover' },
  type: 'image',
  format: 'jpg',
};

export const verifyMale = {
  version: '1612879628',
  public_id: 'assets/verify2-male',
  resize: { width: 500, fit: 'cover' },
  type: 'image',
  format: 'jpg',
};

export const videoThumbnail = {
  public_id: 'assets/collection-placeholder',
  type: 'image',
  format: 'jpeg',
};

export const videoPreview = {
  public_id: 'assets/feed-video-placeholder',
  type: 'image',
  format: 'gif',
};

export const kycVerification = {
  public_id: 'assets/kyc-verification',
  type: 'image',
  format: 'png',
};
export const phoneVerification = {
  public_id: 'assets/phone-verification',
  type: 'image',
  format: 'png',
};
export const imageVerification = {
  public_id: 'assets/image-verification',
  type: 'image',
  format: 'png',
};
export const tipWine = {
  public_id: 'assets/tip/wine',
  type: 'image',
  format: 'png',
};
export const tipRose = {
  public_id: 'assets/tip/rose',
  type: 'image',
  format: 'png',
};

export const tipLoveLetter = {
  public_id: 'assets/tip/loveletter',
  type: 'image',
  format: 'png',
};

export const tipHeart = {
  public_id: 'assets/tip/heart',
  type: 'image',
  format: 'png',
};

export const tipGift = {
  public_id: 'assets/tip/gift',
  type: 'image',
  format: 'png',
};

export const tipAmor = {
  public_id: 'assets/tip/amor',
  type: 'image',
  format: 'png',
};
