import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Button} from 'components/buttons';
import api from 'app/api';
import {LoaderRelative} from 'components/layout/loader';
import styles from './subscribeModal.module.css';
import {useDispatch, useSelector} from 'react-redux';

import {selectUserInfo} from 'features/auth/authSlice';
import {getUserProfile, updateTimestamp} from 'features/user/profileSlice';
import {Bundle} from 'features/transactions/transactionsModal/withSubscription/WithSubscription';
import {Avatar} from 'components/avatar';
import {useTranslation} from 'react-i18next';
import PosterImage from "../../../components/profileheader/PosterImage";
import cx from "classnames";
import {ProfileBadges} from "../../../components/layout/badges/Badges";
import {LocationInfo} from "../../user/profileInfo/profileMetaInfo";
import {IconArrowLeft, IconTick} from "../../../assets/icons";
//import {ProfileButtonNew} from "../../user/profileInfo/Buttons";
//import { Link } from 'react-router-dom';

const BundleItem = ({price_text, discount_percent, title, onClick}) => {
    const {t} = useTranslation();
    return (
        <div className={styles.item} onClick={onClick}>
            <span className={styles.price}>{price_text} </span>
            <span className={styles.duration}>{title} </span>
            {discount_percent !== 0 && (
                <span className={styles.discount}>
          {discount_percent}%
                    {t('features:subscriptions.subscribeModal.discount')}{' '}
        </span>
            )}
        </div>
    );
};

BundleItem.propTypes = {
    onClick: PropTypes.func,
    user_setting_id: PropTypes.number,
    subscription_type: PropTypes.number,
    duration_type: PropTypes.number,
    quantity: PropTypes.number,
    price: PropTypes.number,
    currency: PropTypes.string,
    price_text: PropTypes.string,
    discount_percent: PropTypes.number,
    title: PropTypes.string,
};

const BundlesList = ({bundles, onSelect}) => {
    const {t} = useTranslation();
    const [showFull, toggleShowFull] = useState(true);
    const readMoreText = showFull
        ? t('features:subscriptions.subscribeModal.hideBenefits', 'HIDE BENEFITS')
        : t('features:subscriptions.subscribeModal.showBenefits', 'SHOW BENEFITS');

    return (
        <div className={styles.list}>
            {bundles.map((bundle, idx) => (
                <div key={idx + 100}>
                    {(idx === 0 || showFull) && (
                        <Bundle
                            key={idx}
                            duration={bundle.title}
                            discount={bundle.discount_percent}
                            originalPrice={bundle.original_price_text}
                            price={bundle.price_text}
                            onClick={() => onSelect(bundle)}
                            insgesamt={idx === 0 ? t('features:subscriptions.subscribeModal.month') : t('features:subscriptions.subscribeModal.total')}
                        />
                    )}

                    {idx === 0 && showFull && (
                        <hr className={styles.hrSpace}/>
                    )}

                    {idx === bundles.length - 1 && (
                        <>
                            <hr className={styles.hrSpace}/>
                            <div
                                onClick={() => toggleShowFull(!showFull)}
                                className={styles.readMoreBtn}
                            >
                                <IconArrowLeft className={showFull ? styles.arrowUp : styles.arrowDown}/>
                                <br/>
                                {readMoreText}
                            </div>
                        </>
                    )}
                </div>
            ))}
        </div>
    );
}

BundlesList.propTypes = {
    // bundles: PropTypes.arrayOf(PropTypes.instanceOf(BundleItem)),
    onSelect: PropTypes.func,
}

const ConfirmSubscribe = ({
                              bundle,
                              onClickSubscribe,
                              loading,
                              profile,
                              hideModal,
                          }) => {
    const {t} = useTranslation();
    const {
        subscription_price_id,
    } = bundle;

    const onClick = () => {
        onClickSubscribe(subscription_price_id);
    };

    return (
        <>
            {profile.banner && (
                <div className={styles.deskBannerBlock}>
                    <PosterImage image={profile.banner} style={{width: '100%', height: '100%'}}/>
                </div>
            )}
            <div className={!profile.banner ? styles.withoutBannerInfoBox : ''}>
                <div className={profile.banner ? styles.infoUserAvatarBlock : ''}>
                    <Avatar
                        circle
                        size={70}
                        online={profile.online}
                        image={profile.avatar}
                        gender={profile.gender}
                        className={styles.avatarBorder}
                        wrapperClassName={styles.avatarBlock}
                    />
                </div>
                <div className={profile.banner ? styles.infoDesk : styles.withoutBannerInfo}>
                    <div className={cx(styles.infoUser)}>
                        {/*Profile Name*/}
                        <div className={styles.bannerInfoName}>
                            <span>{profile.name}</span>
                            {profile.age && (
                                <span className={styles.infoAge}>{profile.age}</span>
                            )}
                        </div>
                        {/*Profile Badge*/}
                        <ProfileBadges
                            verified={!profile.verification_required}
                            dating={profile.dating_enabled}
                            phone={profile.verified_phone}
                            className={styles.infoBadges}
                        />
                    </div>
                    {/*Profile Location*/}
                    <LocationInfo
                        customClass={styles.mt0}
                        {...profile}
                    />
                    {/*<ProfileMetaInfo
                                    {...profile}
                                />*/}
                </div>
            </div>
            <hr/>
            <div className={styles.modalBody}>
                <div className={styles.subscribeText}>
                    {/*<p style={{margin: '0 0 5px 0', fontSize: '1.5rem'}}>
                        <strong>
                            {t('features:subscriptions.subscribeModal.confirmSubscription')}
                        </strong>
                    </p>*/}
                    <div style={{margin: '0 0 5px 0'}}>
                        <strong>
                            {t('features:subscriptions.subscribeModal.subscribeTo', {
                                user: profile.name,
                            })}
                        </strong>
                    </div>
                    <div className={styles.bulletBox}>
                        <span className={styles.bullet}>
                            <IconTick/>
                        </span>
                        <span className={styles.bulletText}>
                            {t('features:subscriptions.subscribeModal.subscribeToSub1')}
                        </span>
                    </div>
                    <div className={styles.bulletBox}>
                        <span className={styles.bullet}>
                            <IconTick/>
                        </span>
                        <span className={styles.bulletText}>
                            {t('features:subscriptions.subscribeModal.subscribeToSub2')}
                        </span>
                    </div>
                </div>
            </div>

            <div className={styles.subscriptionDetails}>
                {/*<p>{title}</p>*/}
                {/*{discount_percent ? (
                    <p>
                        <strong style={{color: 'var(--turg)'}}>
                            {discount_percent}%{' '}
                            {t('features:subscriptions.subscribeModal.discount')}
                        </strong>{' '}
                        <span
                            style={{
                                color: 'var(--accentColor)',
                                textDecoration: 'line-through',
                                marginLeft: '5px',
                            }}
                        >
              {original_price_text}
            </span>
                    </p>
                ) : null}*/}

                <Bundle
                    duration={bundle.title}
                    discount={bundle.discount_percent}
                    originalPrice={bundle.original_price_text}
                    price={bundle.price_text}
                    onClick={onClick}
                    insgesamt={t('features:subscriptions.subscribeModal.month')}
                />
                {/*<p>
                    <strong>{price_text}</strong>
                </p>*/}
            </div>
            {/*<hr/>
            <div className={styles.confirmSubscriptionBtns}>
                <Button loading={loading} onClick={onClick} fullWidth>
                    {t('features:subscriptions.subscribeModal.confirm')}
                </Button>
            </div>*/}
        </>
        /*<div
            style={{marginTop: '-30px'}}
            className={styles.confirmSubscribeWrapper}
        >
            <p style={{margin: '0 0 5px 0', fontSize: '1.5rem'}}>
                <strong>
                    {t('features:subscriptions.subscribeModal.confirmSubscription')}
                </strong>
            </p>
            <p style={{margin: '0 0 10px 0'}}>
                {t('features:subscriptions.subscribeModal.subscribeTo', {
                    user: profile.name,
                })}
            </p>

            <div className={styles.avatarContainer}>
                <Avatar circle image={profile.avatar} gender={profile.gender}/>
            </div>
            <div className={styles.subscriptionDetails}>
                <p>{title}</p>
                {discount_percent ? (
                    <p>
                        <strong style={{color: 'var(--turg)'}}>
                            {discount_percent}%{' '}
                            {t('features:subscriptions.subscribeModal.discount')}
                        </strong>{' '}
                        <span
                            style={{
                                color: 'var(--accentColor)',
                                textDecoration: 'line-through',
                                marginLeft: '5px',
                            }}
                        >
              {original_price_text}
            </span>
                    </p>
                ) : null}

                <p>
                    <strong>{price_text}</strong>
                </p>
            </div>
            <hr/>

            {/!*<p className={styles.legalNotice}>
        <Trans i18nKey={'features:subscriptions.subscribeModal.warning'}>
          <span>zero</span>
          <Link
            to={'/settings/legal/terms'}
            target="_blank"
            rel="noopener noreferer"
          >
            one
          </Link>
          <span>two</span>

          <Link
            to={'/settings/legal/privacy'}
            target="_blank"
            rel="noopener noreferer"
          >
            three
          </Link>
        </Trans>
      </p>*!/}

            <div className={styles.confirmSubscriptionBtns}>
                <Button loading={loading} onClick={onClick} fullWidth>
                    {t('features:subscriptions.subscribeModal.confirm')}
                </Button>
            </div>
        </div>*/
    )
        ;
};

ConfirmSubscribe.propTypes = {
    // bundle: PropTypes.instanceOf(BundleItem),
    handleSubscribe: PropTypes.func,
};

export const SubscribeModalBody = ({
                                       profile,
                                       onHideModal,
                                       preselectedBundle,
                                   }) => {
    const [bundles, setBundles] = useState([]);
    const [selectedBundle, setSelectedBundle] = useState(null);
    const {payments} = useSelector(selectUserInfo);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        const getBundles = async () => {
            const res = await api.get(`v2/subscription/user_settings/${profile.id}`);

            if (res.data.data.prices.length === 1) {
                // skip select when there is only on bundle
                setSelectedBundle(res.data.data.prices[0]);
                setLoading(false);
            } else {
                setBundles(res.data.data.prices);
                setLoading(false);
            }
        };

        if (preselectedBundle) {
            setSelectedBundle(preselectedBundle);
            setLoading(false);
        } else {
            getBundles();
        }
    }, [preselectedBundle, profile.id]);

    const selectBundle = (bundle) => {
        setSelectedBundle(bundle);
    };

    const handleOneClickSubscribe = async (id) => {
        setLoading(true);

        try {
            const res = await api.post(`v2/payment/subscription_popup_payment`, {
                subscription_price_id: id,
            });

            if (res.data.ok) {
                if (res.data.payment_status === 'redirect3DS') {
                    window.location.assign(res.data.url_redirect);
                } else {
                    await dispatch(getUserProfile({user_id: profile.id}));
                    dispatch(updateTimestamp());
                    setLoading(false);
                    onHideModal();
                }
            } else {
                setError(true);
                setLoading(false);
                return;
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    };

    const handleSubscribe = (id) => {
        if (payments.ocp_ready) {
            handleOneClickSubscribe(id);
        } else {
            window.location.assign(
                `${process.env.REACT_APP_SUBSCRIPTION_CHECKOUT}/${id}`
            );
        }
    };

    if (loading) {
        return (
            <div className={styles.modalBody}>
                <p className={styles.modalCopy}>
                    {t('features:subscriptions.subscribeModal.subscribeTo', {
                        user: profile.name,
                    })}
                </p>
                <LoaderRelative/>
            </div>
        );
    }

    if (error) {
        return (
            <div className={styles.modalBody}>
                <p className={styles.modalCopy}>
                    {t('main:errors.somethingWentWrong', {user: profile.name})}
                </p>
            </div>
        );
    }

    if (selectedBundle)
        return (
            <ConfirmSubscribe
                bundle={selectedBundle}
                onClickSubscribe={handleSubscribe}
                loading={loading}
                profile={profile}
                hideModal={onHideModal}
            />
        );

    return (
        <>
            {profile.banner && (
                <div className={styles.deskBannerBlock}>
                    <PosterImage image={profile.banner} style={{width: '100%', height: '100%'}}/>
                </div>
            )}
            <div className={!profile.banner ? styles.withoutBannerInfoBox : ''}>
                <div className={profile.banner ? styles.infoUserAvatarBlock : ''}>
                    <Avatar
                        circle
                        size={80}
                        online={profile.online}
                        image={profile.avatar}
                        gender={profile.gender}
                        className={styles.avatarBorder}
                        wrapperClassName={styles.avatarBlock}
                    />
                </div>
                <div className={profile.banner ? styles.infoDesk : styles.withoutBannerInfo}>
                    <div className={cx(styles.infoUser)}>
                        {/*Profile Name*/}
                        <div className={styles.bannerInfoName}>
                            <span>{profile.name}</span>
                            {profile.age && (
                                <span className={styles.infoAge}>{profile.age}</span>
                            )}
                        </div>
                        {/*Profile Badge*/}
                        <ProfileBadges
                            verified={!profile.verification_required}
                            dating={profile.dating_enabled}
                            phone={profile.verified_phone}
                            className={styles.infoBadges}
                        />
                    </div>
                    {/*Profile Location*/}
                    <LocationInfo
                        customClass={styles.mt3}
                        {...profile}
                    />
                    {/*<ProfileMetaInfo
                                    {...profile}
                                />*/}
                </div>
            </div>
            <hr/>
            <div className={styles.modalBody}>
                <div className={styles.subscribeText}>
                    {/*<p style={{margin: '0 0 5px 0', fontSize: '1.5rem'}}>
                        <strong>
                            {t('features:subscriptions.subscribeModal.confirmSubscription')}
                        </strong>
                    </p>*/}
                    <div style={{margin: '0 0 5px 0'}}>
                        <strong>
                            {t('features:subscriptions.subscribeModal.subscribeTo', {
                                user: profile.name,
                            })}
                        </strong>
                    </div>
                    <div className={styles.bulletBox}>
                        <span className={styles.bullet}>
                            <IconTick/>
                        </span>
                        <span className={styles.bulletText}>
                            {t('features:subscriptions.subscribeModal.subscribeToSub1')}
                        </span>
                    </div>
                    <div className={styles.bulletBox}>
                        <span className={styles.bullet}>
                            <IconTick/>
                        </span>
                        <span className={styles.bulletText}>
                            {t('features:subscriptions.subscribeModal.subscribeToSub2')}
                        </span>
                    </div>
                </div>

                {/* <p className={styles.modalCopy}>
        {t('features:subscriptions.subscribeModal.subscribeTo', {
          user: profile.name,
        })}
      </p> */}

                <BundlesList bundles={bundles} onSelect={selectBundle}/>

                <div style={{marginTop: '20px'}}>
                    <Button onClick={onHideModal} name="btn-grey" fullWidth className={styles.cancelButton}>
                        {t('features:subscriptions.subscribeModal.cancel', 'Cancel')}
                    </Button>
                </div>
            </div>
        </>
    );
};

SubscribeModalBody.propTypes = {
    onHideModal: PropTypes.func,
    profile: PropTypes.shape({
        id: PropTypes.any,
        name: PropTypes.string,
    }),
};

const SubscribeModal = ({modalContext, profile}) => {
    const {RenderModal, hideModal} = modalContext;
    const {subscription} = profile;

    if (!subscription) return null;
    if (subscription.subscribed) return null;
    if (!subscription.has_subscription) return null;

    return (
        <RenderModal
            // title={t('features:subscriptions.subscribeModal.confirmSubscription')}
            buttonClose
            closeButtonClass={styles.cancelButtonSvg}
            customClassName={styles.cancelButtonSvg}
            customStyles={{maxWidth: '500px', lineHeight: '1.5'}}
        >
            <SubscribeModalBody profile={profile} onHideModal={hideModal}/>
        </RenderModal>
    );
};

SubscribeModal.propTypes = {
    profile: PropTypes.object.isRequired,
};

export default SubscribeModal;
