import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';
import {useParams, useHistory} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import {getMyEpisodesPath} from 'helpers/dating';
import Loader from 'components/layout/loader/Loader';
import {
    getGuestEpisodeDetail,
    selectGuestEpisodeDetail,
    resetEpisodeDetail,
} from '../episodesSlice';
//import {PublicLayout} from "../../../../components/layout/layouts";
import EventDetails from "../EventDetails";

const GuestEpisodeDetail = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const episode = useSelector(selectGuestEpisodeDetail);
    //const user = useSelector(selectUserInfo);
    const myEpisodesPath = getMyEpisodesPath('male');
    const {applications} = episode;

    useEffect(() => {
        dispatch(getGuestEpisodeDetail({episode_id: id}))
            .then(unwrapResult)
            .then((result) => {
                if (!result.status) {
                    history.push(myEpisodesPath);
                }
            });

        return () => dispatch(resetEpisodeDetail());
    }, [dispatch, id, history, myEpisodesPath]);

    if (isEmpty(episode)) {
        return <Loader/>;
    }

    return (
            <>
                <EventDetails
                    episode={episode}
                    applications={applications}
                    isPublic={true}
                />
            </>
    );
};

export default GuestEpisodeDetail;
