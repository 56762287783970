import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { IconCheck } from 'assets/icons';
import { useWindowWidthLessThan } from 'hooks';
import Button from 'components/buttons/Button';
import styles from './dating.module.css';

const EpisodeButtons = ({
  handleDeny,
  handleApply,
  handleChat,
  handleChange,
  loadingDeny,
  loadingApply,
  loadingChange,
  size,
}) => {
  const { t } = useTranslation();
  const isMobile = useWindowWidthLessThan(769);
  const roundMobile = isMobile && size === 'm';

  if (handleChat) {
    return (
      <Button
        name="btn-gradient"
        fullWidth
        size={size}
        onClick={handleChat}
        className={cx(styles.button, styles.deny, {
          [styles.roundMobile]: roundMobile,
        })}
      >
        {roundMobile ? (
          <IconCheck width={13} />
        ) : (
          <span>{t('features:event.openChat')}</span>
        )}
      </Button>
    );
  }

  if (handleChange) {
    return (
      <Button
        fullWidth
        size={size}
        color="grey"
        onClick={handleChange}
        loading={loadingChange}
        className={cx(styles.button, styles.deny, {
          [styles.roundMobile]: roundMobile,
        })}
      >
        {roundMobile ? (
          <IconCheck width={13} />
        ) : (
          <span>{t('features:event.change')}</span>
        )}
      </Button>
    );
  }

  return (
    <div className={cx(styles.wrapper, { [styles.big]: size === 'xl' })}>
      {/*<Button
        size={size}
        color="grey"
        onClick={handleDeny}
        loading={loadingDeny}
        className={cx(styles.button, styles.deny, {
          [styles.roundMobile]: roundMobile,
        })}
      >
        {roundMobile ? (
          <IconCross width={13} />
        ) : (
          <span>{t('features:event.decline')}</span>
        )}
      </Button>*/}
      <Button
        size={size}
        color=""
        onClick={handleApply}
        loading={loadingApply}
        className={cx(styles.button, {
          [styles.roundMobile]: roundMobile,
        })}
      >
        {roundMobile ? (
          <IconCheck width={16} />
        ) : (
          <span>{t('features:event.request_to_join')}</span>
        )}
      </Button>
    </div>
  );
};

EpisodeButtons.propTypes = {
  handleDeny: PropTypes.func,
  handleApply: PropTypes.func,
  handleChat: PropTypes.func,
  handleChange: PropTypes.func,
  loadingDeny: PropTypes.bool,
  loadingApply: PropTypes.bool,
  size: PropTypes.string,
};

EpisodeButtons.defaultProps = {
  loadingDeny: false,
  loadingApply: false,
  size: 'xl',
};

export default EpisodeButtons;
