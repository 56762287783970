import { useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'features/auth/authSlice';
import { selectSidebar, toggleSidebar } from 'components/layout/layoutSlice';
import SidebarUserProfile from './sidebarUserProfile/SidebarUserProfile';
import { IconClose } from 'assets/icons';
import Button from 'components/buttons/Button';
import styles from './sidebar.module.css';
import {faGear, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Sidebar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const showSidebar = useSelector(selectSidebar);
  const sidebarRef = useRef(null);
  const { t } = useTranslation();

  const urlProfile = useRouteMatch('/settings/profile');
  const urlAccount = useRouteMatch('/settings/account');
  //const urlLists = useRouteMatch('/settings/lists');
  //const urlWallet = useRouteMatch('/settings/wallet');
  //const urlStats = useRouteMatch('/statistics');

  const trackClickOutside = useCallback(
    (e) => {
      if (!sidebarRef.current) return;

      if (!sidebarRef.current.contains(e.target)) {
        document.body.setAttribute('style', 'overflow: unset;');

        dispatch(toggleSidebar());
        document.removeEventListener('click', trackClickOutside, true);
      }
    },
    [dispatch]
  );

  const hideSidebarAndRemoveListener = () => {
    document.body.setAttribute('style', 'overflow: unset;');

    document.removeEventListener('click', trackClickOutside, true);
    dispatch(toggleSidebar());
  };

  const logoutUser = () => {
    history.push('/');
    hideSidebarAndRemoveListener();
    dispatch(logout());
  };

  useEffect(() => {
    if (showSidebar) {
      document.body.setAttribute('style', 'overflow: hidden;');
      document.addEventListener('click', trackClickOutside, true);
    }
  }, [showSidebar, trackClickOutside]);

  return (
    <aside
      onClick={(e) => e.stopPropagation()}
      className={`${styles.sidebar} ${showSidebar ? styles.active : ''}`}
    >
      <div className={styles.backdrop}></div>
      <div ref={sidebarRef} className={styles.sidebarInner}>
        <SidebarUserProfile callback={hideSidebarAndRemoveListener} />
        <button
          className={styles.closeBtn}
          onClick={hideSidebarAndRemoveListener}
        >
          <IconClose />
        </button>
        <nav className={styles.sections}>
          <NavLink
            activeClassName={styles.active}
            className={styles.link}
            onClick={() => hideSidebarAndRemoveListener()}
            to="/settings/profile"
          >
            {urlProfile ? <FontAwesomeIcon icon={faUser} className={`${styles.icon} ${styles.active}`} />:  <FontAwesomeIcon icon={faUser} className={`${styles.icon}`} />}
            {t('features:navs.myProfile.myProfile')}
          </NavLink>
          <NavLink
            activeClassName={styles.active}
            className={styles.link}
            onClick={() => hideSidebarAndRemoveListener()}
            to="/settings/account"
          >
            {urlAccount ? <FontAwesomeIcon icon={faGear} className={`${styles.icon} ${styles.active}`} />:  <FontAwesomeIcon icon={faGear} className={`${styles.icon}`} />}
            {t('features:navs.settings.account')}
          </NavLink>
          {/*<NavLink
            activeClassName={styles.active}
            className={styles.link}
            onClick={() => hideSidebarAndRemoveListener()}
            to="/settings/lists"
          >
            {urlLists ? <FontAwesomeIcon icon={faList} className={`${styles.icon} ${styles.active}`} />:  <FontAwesomeIcon icon={faList} className={`${styles.icon}`} />}
            {t('features:navs.lists.lists')}
          </NavLink>
          <NavLink
            activeClassName={styles.active}
            className={styles.link}
            onClick={() => hideSidebarAndRemoveListener()}
            to="/settings/wallet"
          >
            {urlWallet ? <FontAwesomeIcon icon={faWallet} className={`${styles.icon} ${styles.active}`} />:  <FontAwesomeIcon icon={faWallet} className={`${styles.icon}`} />}
            {t('features:navs.myWallet.myWallet')}
          </NavLink>

          <NavLink
            activeClassName={styles.active}
            className={styles.link}
            onClick={() => hideSidebarAndRemoveListener()}
            to="/statistics"
          >
            {urlStats ? <FontAwesomeIcon icon={faChartLine} className={`${styles.icon} ${styles.active}`} />:  <FontAwesomeIcon icon={faChartLine} className={`${styles.icon}`} />}
            {t('features:navs.stats.stats')}
          </NavLink>*/}
        </nav>
        <div className={styles.logout}>
          <Button onClick={logoutUser} size="xl" fullWidth>
            Logout
          </Button>
        </div>
        <div className={styles.bottomLinks}>
          <NavLink
            className={styles.bottomLink}
            onClick={() => hideSidebarAndRemoveListener()}
            to="/legal"
          >
            LEGAL
          </NavLink>
          <span>|</span>
          <a
            onClick={(e) => {
              e.preventDefault();
              window.location.assign(`${process.env.REACT_APP_SUPPORT_PAGE}`);
              hideSidebarAndRemoveListener();
            }}
            href={`${process.env.REACT_APP_SUPPORT_PAGE}`}
          >
            SUPPORT
          </a>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
