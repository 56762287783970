import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { IconPhoto, IconPosts, IconVideo } from 'assets/icons';
import styles from './profileMetaInfo.module.css';
import CountryFlag from "../../../../components/CountryFlag";

export const LocationInfo = ({ town_name, country_name, country, customClass }) => {

  return (
    <div className={cx(styles.detail, customClass)}>
      {/*<IconMapMark className={cx(styles.deatilIcon, styles.location)} />*/}
      <CountryFlag countryCode={country} className={cx(styles.deatilIcon, styles.location)}/>
      <span className={styles.detailTxt}>
        {`${town_name}`}
      </span>
    </div>
  );
};

export const StatsInfo = ({ stats }) => {
  return (
    <>
      {stats.total_posts > 0 && (
        <div className={styles.detail}>
          <IconPosts className={cx(styles.deatilIcon, styles.location)} />
          <span className={styles.detailTxt}>{stats.total_posts}</span>
        </div>
      )}
      {stats.total_images > 0 && (
        <div className={styles.detail}>
          <IconPhoto className={cx(styles.deatilIcon, styles.location)} />
          <span className={styles.detailTxt}>{stats.total_images}</span>
        </div>
      )}
      {stats.total_videos > 0 && (
        <div className={styles.detail}>
          <IconVideo className={cx(styles.deatilIcon, styles.location)} />
          <span className={styles.detailTxt}>{stats.total_videos}</span>
        </div>
      )}
    </>
  );
};

export const OnlineStatus = ({ isOnline }) => {
  if (!isOnline) return null;

  const fill = '#00a24e';
  const txt = 'Online';
  return (
    <div className={styles.detail}>
      <svg
        viewBox="0 0 100 100"
        style={{ fill: fill }}
        className={styles.deatilIcon}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="50" cy="50" r="50" />
      </svg>
      <span className={styles.detailOnlineTxt}>{txt}</span>
    </div>
  );
};

const ProfileMetaInfo = ({
  stats,
  online,
  town_name,
  country_name, country,
  lightTheme,
  customClass,
}) => {
  return (
    <div
      className={cx(styles.wrapper, customClass, {
        [styles.light]: lightTheme,
      })}
    >
      <LocationInfo
        customClass={customClass}
        town_name={town_name}
        country_name={country_name}
        country={country}
      />
      <StatsInfo stats={stats} />
    </div>
  );
};

ProfileMetaInfo.propTypes = {
  country_name: PropTypes.string,
  town_name: PropTypes.string,
  lightTheme: PropTypes.bool,
  online: PropTypes.bool,
  stats: PropTypes.shape({
    total_images: PropTypes.number,
    total_posts: PropTypes.number,
    total_videos: PropTypes.number,
  }),
};

export default ProfileMetaInfo;
