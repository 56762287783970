import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import UserBar from './components/UserBar';
import styles from './activityDetail.module.css';

const ActivityFeedHeader = ({
  activity,
  isDetail,
  isHome,
  isCategory,
  publicModal,
}) => {
  const history = useHistory();
  const actor = activity.actor;

  if (actor.error) {
    return null;
  }

  const handleUserClick = () => {
    if (publicModal) {
      history.push(`/${actor.data.hash_id}`);
    } else {
      history.push(`/profile/${actor.data.id}`);
    }
    return;
  };

  // const handleDateClick = () => {
  //   if (publicModal) {
  //     publicModal();
  //   } else {
  //     history.push(`/post/${activity.id}`);
  //   }
  //   return;
  // };

  return (
    <div className={styles.rowWrapper}>
      <UserBar
        actor={actor.data}
        onClickUser={isHome || isDetail || isCategory ? handleUserClick : null}
        // onClickDate={!isDetail ? handleDateClick : null}
        onClickDate={null}
        timestamp={activity.time}
      />
    </div>
  );
};

ActivityFeedHeader.propTypes = {
  activity: PropTypes.object.isRequired,
  isDetail: PropTypes.bool,
  isHome: PropTypes.bool,
  isCategory: PropTypes.bool,
  publicModal: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

ActivityFeedHeader.defaultProps = {
  isDetail: false,
  isHome: false,
  isCategory: false,
};

export default ActivityFeedHeader;
