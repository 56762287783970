import React, { useState } from 'react';
import styles from './subscriptionPriceInput.module.css';

import { CheckboxStandAlone } from '../../../components/forms';
import { Form, Formik } from 'formik';
import { Button } from 'components/buttons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSubscriptionSettings,
  selectSetSubscriptionPrice,
  selectSubscriptionsPriceSettings,
  setSubscriptionPrice,
} from '../subscriptionsSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { selectUserCurrency } from 'features/auth/authSlice';

const MAX_SUBSCRIPTION_PRICE = 50;
const MIN_SUBSCRIPTION_PRICE = 5;
const DEFAULT_SUBSCRIPTION_PRICE = MIN_SUBSCRIPTION_PRICE;

const ErrorMessage = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.errorBox}>
      <h4 style={{ color: 'red', marginBottom: 0, margin: '0' }}>Error</h4>
      <p style={{ margin: 0 }}>{t('main:errors.somethingWentWrong')}</p>
    </div>
  );
};

const PriceSet = ({ onIncrement, onDecrement, price, max }) => {
  const { symbol } = useSelector(selectUserCurrency);
  const { t } = useTranslation();

  return (
    <div className={styles.priceSet}>
      <div className={styles.priceSetInner}>
        <Button
          disabled={price < MIN_SUBSCRIPTION_PRICE + 1}
          onClick={onDecrement}
          name="btn-bare"
          type="button"
          className={styles.priceSetBtn}
        >
          <span className={`${styles.priceSetControl} ${styles.minus}`}></span>
        </Button>
        <div className={styles.priceSetValue}>{price},00</div>
        <Button
          disabled={price >= max}
          onClick={onIncrement}
          name="btn-bare"
          type="button"
          className={styles.priceSetBtn}
        >
          <span className={styles.priceSetControl}>+</span>
        </Button>
      </div>
      <span style={{ marginLeft: '10px', color: 'var(--grey2)' }}>
        {symbol} / {t('features:subscriptions.priceInput.month')}
      </span>
    </div>
  );
};

const SubscriptionPriceInput = ({ onSave, isModalContext }) => {
  const [confirm, setConfirm] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, error } = useSelector(selectSetSubscriptionPrice);
  const { price } = useSelector(selectSubscriptionsPriceSettings);
  const { symbol } = useSelector(selectUserCurrency);

  const onClickSubmit = async (price) => {
    await handlePriceChange(price);
    if (onSave) {
      onSave();
    }
  };

  const handlePriceChange = async (price) => {
    const res = await dispatch(
      setSubscriptionPrice({
        type: 1,
        price,
      })
    );
    await unwrapResult(res);
    dispatch(getSubscriptionSettings());
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <p style={{ margin: 0, fontSize: '1.4rem' }}>
          <strong>
            {t('features:subscriptions.priceInput.pricePerMonth')} *
          </strong>
        </p>
        <p style={{ margin: '0', fontSize: '1.2rem', color: 'var(--grey4)' }}>
          {t(
            'features:subscriptions.priceInput.minPrice',
            `Min ${MIN_SUBSCRIPTION_PRICE}${symbol}, Max ${MAX_SUBSCRIPTION_PRICE}${symbol}`
          )}
        </p>
      </div>
      <Formik initialValues={{ price: price || DEFAULT_SUBSCRIPTION_PRICE }}>
        {({ values, setFieldValue }) => (
          <Form>
            <div
              className={styles.priceSetForm}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <PriceSet
                price={values.price}
                max={MAX_SUBSCRIPTION_PRICE}
                onDecrement={() => setFieldValue('price', values.price - 1)}
                onIncrement={() => setFieldValue('price', values.price + 1)}
              />
              {!isModalContext && (
                <Button
                  name="btn-gradient"
                  size="xl"
                  context="form"
                  type="submit"
                  onClick={() => onClickSubmit(values.price)}
                  loading={loading}
                  disabled={loading}
                >
                  {t('main:main.save')}
                </Button>
              )}
            </div>
            {isModalContext && (
              <>
                <div className={styles.changeInfo}>
                  <p>
                    <span
                      style={{ marginRight: '5px' }}
                      className={styles.label}
                    >
                      INFO
                    </span>

                    {t('features:subscriptions.priceChange.info')}
                  </p>
                  <p style={{ fontWeight: 'normal' }}>
                    {t('features:subscriptions.priceChange.info2')}
                  </p>
                  <div style={{ padding: '10px 5px' }}>
                    <CheckboxStandAlone
                      value={confirm}
                      onChange={(val) => setConfirm(val)}
                    >
                      {t('features:subscriptions.priceChange.continue')}
                    </CheckboxStandAlone>
                  </div>
                </div>
                {error && <ErrorMessage />}
                <Button
                  name="btn-gradient"
                  size="xl"
                  context="form"
                  type="submit"
                  onClick={() => onClickSubmit(values.price)}
                  loading={loading}
                  disabled={loading || !confirm}
                  fullWidth
                >
                  {t('main:main.save')}
                </Button>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SubscriptionPriceInput;

export const SubscriptionPriceEditModal = ({ modalContext }) => {
  const { RenderModal, hideModal } = modalContext;
  const { t } = useTranslation();

  return (
    <RenderModal
      title={t('features:subscriptions.priceChange.editPricePerMonth')}
      buttonClose
    >
      <div style={{ marginTop: '-20px' }}></div>
      <SubscriptionPriceInput onSave={hideModal} isModalContext={true} />
    </RenderModal>
  );
};
