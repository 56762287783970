import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
  getNotifications,
  getNotificationsMore,
  resetNumOfUnreadNotifications,
  selectNotifications,
} from './notificationsSlice';
import LoadMoreTrigger from 'components/loadMore/LoadMoreTrigger';
import { LoaderInline } from 'components/layout/loader';
import NotificationDetail from './NotificationDetail';

const NotificationsView = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);

  useEffect(() => {
    if (isEmpty(notifications.results) && !notifications.fetched) {
      dispatch(getNotifications());
    }
    dispatch(resetNumOfUnreadNotifications());
  }, [notifications.results, notifications.fetched, dispatch]);

  const fetchNext = () => {
    if (!isEmpty(notifications.next) && !notifications.fetching) {
      dispatch(getNotificationsMore(notifications.next));
    }
  };

  if (isEmpty(notifications.results)) return <LoaderInline />;

  return (
    <>
      <div>
        <div style={{ marginBottom: '50px' }}>
          {notifications.results.map((item, i) => (
            <NotificationDetail key={i} item={item} />
          ))}
        </div>
      </div>

      {!isEmpty(notifications.results) && (
        <LoadMoreTrigger
          getMore={fetchNext}
          hasMore={!isEmpty(notifications.next)}
        />
      )}
    </>
  );
};

export default NotificationsView;
