import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import {Avatar} from 'components/avatar';
import {ProfileBadges} from 'components/layout/badges/Badges';
import {LoaderRelative} from 'components/layout/loader';
import {useWindowWidthLessThan} from 'hooks';
import styles from './connectionList.module.css';
import {getConnections, selectConnections} from "./connectionListSlice";
import {LocationInfo} from "../user/profileInfo/profileMetaInfo";
import {selectUserInfo} from "../auth/authSlice";
//import {ConnectionButtonRound, ProfileButtonNew} from "../user/profileInfo/Buttons";
//import {useModal} from "../../components/modal/useModal";
//import cx from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {trackEvent} from "../../helpers/tracking";
import {connectionUser, updateTimestamp} from "../user/profileSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {toast} from "react-toastify";

const ConnectionItem = ({connection, action}) => {
    const {profile} = connection;
    const isMobile = useWindowWidthLessThan(575);
    return (
        <tr>
            <td className={styles.listItemWrapper}>
                <Link to={`/profile/${profile.id}`} className={styles.listItem}>
                    <div className={styles.avatarContainer}>
                        <Avatar
                            image={profile.avatar}
                            size={60}
                            className={styles.avatar}
                            circle
                            gender={profile.gender}
                        />
                    </div>
                    <div className={styles.itemDetails}>
                        <div className={styles.name}>
                            <span>{profile.name}</span>
                            {Number(profile.age) > 0 && (
                                <span className={styles.age}>{profile.age}</span>
                            )}
                            <ProfileBadges
                                small
                                verified={profile.verified}
                                dating={profile.dating_enabled}
                                phone={profile.verified_phone}
                            />
                        </div>
                        <p className={styles.date}>
                            <LocationInfo
                                town_name={profile.town}
                                country={profile.country_code}
                            />
                        </p>
                    </div>
                </Link>
                {action && !isMobile ? action : null}
            </td>
        </tr>
    );
};

const ConnectionList = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {data, loading} = useSelector(selectConnections);

    useEffect(() => {
        dispatch(getConnections());
    }, [dispatch]);

    if (loading) return <LoaderRelative/>;

    /*if (isEmpty(data)) {
        return '';
    }*/


    const ButtonList = ({connection}) => {

        const {status, user1_id} = connection.profile.connection;
        const {uid, gender} = useSelector(selectUserInfo);

        //console.log("user1_id, uid===>", user1_id, uid);

        const handleConnection = (connectionAction, action) => {

            const connectable = connectionAction === 1;
            const disConnectable = connectionAction === 3;
            const cancellable = connectionAction === 2;
            const confirmable = connectionAction === 4;
            //const cancellable = !connectable && !disConnectable && connectionAction === 2 && action === false && parseInt(uid) === parseInt(user1_id);
            //const confirmable = !connectable && !disConnectable && connectionAction === 2 && action === true && parseInt(uid) !== parseInt(user1_id);

            //return false;
            const actionText =
                connectable ? 'connect' :
                    cancellable ? 'cancel' :
                        confirmable ? 'confirm' :
                            disConnectable ? 'disconnect' :
                                '';

            const successText =
                connectable ? t('features:profileInfo.successConnectRequest') :
                    cancellable ? t('features:profileInfo.successCancelConnectRequest') :
                        confirmable ? t('features:profileInfo.successConnection') :
                            disConnectable ? t('features:profileInfo.successDisconnect') :
                                '';

            trackEvent({
                category: 'profile pages',
                action: `${actionText} click`,
                label: gender,
            });

            dispatch(
                connectionUser({user_id: connection.profile.id, action: connectionAction})
            )
                .then(unwrapResult)
                .then((result) => {
                    if (result.status) {
                        toast.success(successText);
                        dispatch(updateTimestamp());
                        dispatch(getConnections());
                        //dispatch(getUserProfile({user_id: connection.profile.id}));
                    }
                });
        };

        //const disConnectionModal = useModal();
        //const cancelConnectionModal = useModal();

        /*const mainActions = {
            connection: {
                show: true,
                onClick: handleConnection,
                showModal: disConnectionModal.showModal,
                showCancelModal: cancelConnectionModal.showModal,
                uid: uid,
            },
        }*/
        return <div>

            {
                parseInt(status) === 2 && parseInt(user1_id) === parseInt(uid) &&
                <span className={styles.waitingButton}>{t('features:profileInfo.waiting')}</span>
            }
            {
                parseInt(status) === 2 && parseInt(user1_id) !== parseInt(uid) &&
                <div>
                    <div className={styles.confirmButton} onClick={() => handleConnection(2, false)}>
                        <span className={styles.tooltiptext}>{t('features:profileInfo.Cancel', 'Cancel')}</span>
                        <FontAwesomeIcon icon={faClose} color={'black'} width={20}/>
                    </div>

                    <div className={styles.confirmButton} onClick={() => handleConnection(4, true)}>
                        <span className={styles.tooltiptext}>{t('features:profileInfo.Confirm', 'Confirm')}</span>
                        <FontAwesomeIcon icon={faCheck} color={'black'} width={20}/>
                    </div>
                </div>

            }
        </div>;
    }

    return (
        <div className={styles.topBorder}>
            <p className={styles.heading}>{t('features:search.friends')}</p>
            <table className={styles.table}>
                <tbody>
                {data.map((connection) => (
                    <ConnectionItem
                        key={connection.created_at}
                        connection={connection}
                        action={<ButtonList connection={connection}/>}
                    />
                ))}
                {isEmpty(data) &&
                    <p className={styles.nofriend}>{t('features:search.nofriend')}</p>
                }
                </tbody>
            </table>
        </div>
    );
};

export default ConnectionList;
