import isEmpty from 'lodash/isEmpty';

export const parseSelectOptions = (options, index, value) => {
  let parsedOptions = [];
  if (isEmpty(options)) {
    return parsedOptions;
  }
  for (let i = 0; i < options.length; i++) {
    let parsedItem = { slug: options[i][index], name: options[i][value] };
    parsedOptions.push(parsedItem);
  }
  return parsedOptions;
};
