import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './profileInfo.module.css';

const Description = ({ description, limit }) => {
  const [showFull, tooggleShowFull] = useState(false);
  const { t } = useTranslation();

  if (!description.length) return null;

  const aboutLimit = limit || process.env.REACT_APP_FULLTEXT_LIMIT;

  const aboutText =
    description.length <= aboutLimit || showFull
      ? description
      : (description.includes('<br />')
          ? description.split('<br />')[0]
          : `${description.substring(0, aboutLimit)}...`);

  const readMoreText = showFull
    ? t('components:fullText.showLess', 'show less')
    : t('components:fullText.readMore');

  return (
    <div className={styles.descriptionPreview}>
      <div className={styles.descriptionBody}>
        <p
          dangerouslySetInnerHTML={{
            __html: aboutText,
          }}
        />
          <br />
        {description.length > aboutLimit && (
          <span
            onClick={() => tooggleShowFull(!showFull)}
            name="btn-bare"
            className={styles.readMoreBtn}
          >
            {readMoreText}
          </span>
        )}
      </div>
    </div>
  );
};

export default Description;
