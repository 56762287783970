import { useHistory } from 'react-router-dom';
import { IconArrowLeft } from 'assets/icons';
import Button from 'components/buttons/Button';
import styles from './navTitle.module.css';

const NavTitle = ({ title, hide }) => {
  const history = useHistory();

  if (hide) {
    return null;
  }

  const handleBack = () => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <Button
          onClick={handleBack}
          name="btn-bare"
          style={{ padding: '5px 15px 5px 0px' }}
        >
          <IconArrowLeft style={{ width: '25px', height: '16px' }} />
        </Button>
        <p className={styles.title}>{title}</p>
      </div>
    </div>
  );
};

export default NavTitle;
