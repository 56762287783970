import React, {useState} from 'react';
import {FormRow, Label, Select, TextArea} from '../../../components/forms';
import {useTranslation} from 'react-i18next';
import styles from '../episodeCreation/episodeCreation.module.css';
import {
    faArrowCircleDown,
    faArrowCircleUp,
    faFemale,
    faMale
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
//import Button from "../../../components/buttons/Button";
import {useFormikContext} from "formik";
import {parseSelectOptions} from "../../../helpers/parseSelectOptions";
import {useSelector} from "react-redux";
import {selectCancellationPolicy} from "../../../app/miscSlice";

const StepThree = (user, screenName) => {
    const {t} = useTranslation();
    const form = useFormikContext();
    const cancellationPolicy = useSelector(selectCancellationPolicy);
    const [countMale, setCountMale] = useState(0);
    const [countFemale, setCountFemale] = useState(0);
    const [femalePriceDisabled, setFemalePriceDisabled] = useState(true);
    const [malePriceDisabled, setMalePriceDisabled] = useState(true);
    const parsedOptions = parseSelectOptions(cancellationPolicy, 'id', 'name');

    const incrementMale = () => {
        if (countMale < 10) {
            setCountMale(countMale + 1);
            setMalePriceDisabled(false)
            form.setFieldValue('desired_male_company', countMale + 1);
        }
    };

    const decrementMale = () => {
        if (countMale > 0) {
            setCountMale(countMale - 1);
            form.setFieldValue('desired_male_company', countMale - 1);
            if(countMale - 1 === 0) {
                setMalePriceDisabled(true);
                form.setFieldValue('desired_female_company_price', '');
            }
        }
    };

    const incrementFeMale = () => {
        if (countFemale < 10) {
            setCountFemale(countFemale + 1);
            setFemalePriceDisabled(false)
            form.setFieldValue('desired_female_company', countFemale + 1 );
        }
    };

    const decrementFeMale = () => {
        if (countFemale > 0) {
            setCountFemale(countFemale - 1);
            form.setFieldValue('desired_female_company', countFemale - 1);
            if(countFemale - 1 === 0) {
                setFemalePriceDisabled(true);
                form.setFieldValue('desired_male_company_price', '');
            }
        }
    };

    return (
        <div>
            <FormRow>
                <Label
                    label={t('features:eventRequest.desire_company')}
                    name="desired_female_company"
                >
                </Label>
                <div className={styles.counterRow}>
                    <div className={styles.counter}>
                        <FontAwesomeIcon icon={faFemale} className={styles.icon} />

                        <div className={styles.counterArrow}>
                            <FontAwesomeIcon icon={faArrowCircleUp} onClick={incrementFeMale}/>
                            <p>{countFemale}</p>
                            <FontAwesomeIcon icon={faArrowCircleDown} onClick={decrementFeMale}/>
                        </div>

                        <Select
                            required
                            label=''
                            name="desired_female_company_price"
                            pleaseSelectText={t('features:eventRequest.cost','Cost')}
                            DisplayIconChevron={false}
                            isDisabled={femalePriceDisabled}
                            options={[
                                {
                                    slug: 1,
                                    name:
                                        t('features:eventRequest.free')
                                },
                                {slug: 2, name: t('features:eventRequest.10')},
                                {slug: 3, name: t('features:eventRequest.20')},
                                {slug: 4, name: t('features:eventRequest.50')},
                                {slug: 5, name: t('features:eventRequest.100')},
                                {slug: 6, name: t('features:eventRequest.200')},
                                {slug: 7, name: t('features:eventRequest.500')},
                                {slug: 8, name: t('features:eventRequest.1000')},
                                {slug: 9, name: t('features:eventRequest.1500')},
                                {slug: 10, name: t('features:eventRequest.2000')},
                            ]}
                        />
                    </div>

                    <div className={styles.counter}>
                        <FontAwesomeIcon icon={faMale} className={styles.icon} />
                        <div className={styles.counterArrow}>
                            <FontAwesomeIcon icon={faArrowCircleUp} onClick={incrementMale}/>
                            <p>{countMale}</p>
                            <FontAwesomeIcon icon={faArrowCircleDown} onClick={decrementMale}/>
                        </div>

                        <Select
                            required
                            label=''
                            name="desired_male_company_price"
                            pleaseSelectText={t('features:eventRequest.cost','Cost')}
                            DisplayIconChevron={false}
                            isDisabled={malePriceDisabled}
                            options={[
                                {
                                    slug: 1,
                                    name:
                                        t('features:eventRequest.free')
                                },
                                {slug: 2, name: t('features:eventRequest.10')},
                                {slug: 3, name: t('features:eventRequest.20')},
                                {slug: 4, name: t('features:eventRequest.50')},
                                {slug: 5, name: t('features:eventRequest.100')},
                                {slug: 6, name: t('features:eventRequest.200')},
                                {slug: 7, name: t('features:eventRequest.500')},
                                {slug: 8, name: t('features:eventRequest.1000')},
                                {slug: 9, name: t('features:eventRequest.1500')},
                                {slug: 10, name: t('features:eventRequest.2000')},
                            ]}
                        />
                    </div>
                </div>
            </FormRow>

            <FormRow>
                <Label
                    label={t('features:eventRequest.cancellation_policy','Cancellation Policy')}
                    name="cancellationPolicy"
                >
                    <Select
                        required
                        label=''
                        name="cancellationPolicy"
                        options={parsedOptions}
                    />
                    {/*<div className={styles.error}>
                        <FormFieldError fieldName="dateType"/>
                    </div>*/}
                </Label>
            </FormRow>

            {/*<FormRow>
                <FormRow>
                    <Input
                        label={t('features:eventRequest.male')}
                        name="desired_male_company"
                        className={styles.w50}
                        required
                    />

                </FormRow>
            </FormRow>*/}

            <FormRow>
                <TextArea
                    name="description"
                    className={styles.textarea}
                    maxLenght={300}
                    withCounter
                    label={t('features:eventRequest.description')}
                />
            </FormRow>

        </div>
    );
};

export default StepThree;
