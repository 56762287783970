import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { selectDatingDisclaimer } from 'components/layout/layoutSlice';
import styles from './dating.module.css';

const MobileDisclaimer = ({ profile, dating }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const show = useSelector(selectDatingDisclaimer);

  const hasApplications = dating.applications && dating.applications.total > 0;
  const episode = location.state ? location.state.episode : null;

  if ((!hasApplications && !episode) || !show) {
    return null;
  }

  const text = episode
    ? t('features:profileDating.userLooking')
    : t('features:profileDating.userApplied');

  return (
    <Link
      className={styles.mobileDisclaimer}
      to="datingContainer"
      offset={-75}
      spy
      smooth
    >
      <p>
        <strong>{profile.name}</strong>
        {text}
      </p>
    </Link>
  );
};

export default MobileDisclaimer;
