import React from 'react';
import {de, enGB} from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import Avatar from 'components/avatar/Avatar';
import EpisodeButtons from './EpisodeButtons';
import ApplicationButtons from './ApplicationButtons';
import { dateTime, dateTimeLeft } from 'helpers/dating';
import { IconMale, IconFemale } from 'assets/icons';
import styles from './dating.module.css';

const ItemPreview = ({ episode, application }) => {

  const { t, i18n } = useTranslation();
  const lang = i18n.language === 'de' ? de : enGB;

  const profile = !isEmpty(application) ? application.profile : episode.profile;
  const addCounterHours = !isEmpty(application) ? 0 : 1;

  const headerText = application
    ? t('features:profileDating.userApplied')
    : t('features:profileDating.userLooking');

  const LookingFor = ({ value }) => {
    switch (value) {
      case 1:
        return (
          <>
            <IconMale />
            <IconFemale />
          </>
        );

      case 2:
        return <IconMale />;

      case 3:
        return <IconFemale />;

      default:
        return null;
    }
  };

  return (
    <>
      {(
          episode.applied ||
          episode.declined ||
          episode.rejected)
      && (
          <div className={styles.ribbon + ' '
          + `${episode.applied ? styles.ribbonApplied : ''}`
          + `${episode.declined ? styles.ribbonDeclined : ''}`
          + `${episode.rejected ? styles.ribbonRejected : ''}`
          }>
            {episode.applied && t('features:episodeList.Applied')}
            {episode.declined && t('features:episodeList.Declined')}
            {episode.rejected && t('features:episodeList.Rejected')}

          </div>
      )}

      <div className={styles.itemWrapper + ' '
      + `${episode.applied ? styles.appliedBg : ''}`
      + `${episode.declined ? styles.declinedBg : ''}`
      + `${episode.rejected ? styles.rejectedBg : ''}`
      }>
    {/*<div className={styles.itemWrapper}>*/}
      <div className={styles.itemHeader}>
        <Avatar
          image={profile.avatar}
          gender={profile.gender}
          size={80}
          circle
          border
        />
        <p>
          <strong>{profile.name}</strong>
          {headerText}
        </p>
      </div>
      {episode.description && (
            <div className={styles.description}>
              <p>{episode.description}</p>
            </div>
          )}
      <div className={styles.itemContent}>
        <p>
          <strong>{t('features:profileDating.when')}</strong>
          <span> {dateTime(episode, lang)}</span>
        </p>
        <p>
          <strong>{t('features:profileDating.timeLeft')}</strong>
          <span>{dateTimeLeft(episode, lang, addCounterHours)}</span>
        </p>
        <p>
          <strong>{t('features:profileDating.type')}</strong>
          <span style={{ textTransform: 'capitalize' }}>
            {episode.types.join(', ')}
          </span>
        </p>
        <p>
          <strong>{t('features:profileDating.city')}</strong>
          <span>{`${
            episode.city
          }, ${episode.country_code.toUpperCase()}`}</span>
        </p>
        <p>
          <strong>{t('features:profileDating.lookingFor')}</strong>
          <span>
            <LookingFor value={episode.looking_for} />
          </span>
        </p>
        <p>
          <strong>{t('features:profileDating.budget')}</strong>
          <span>
          {t(`features:eventRequest.budget_range_${episode.budget}`, episode.budget)}
          </span>
        </p>
        <p>
          <strong>{t('features:profileDating.duration')}</strong>
          <span>
            {t(`${episode.duration} main:hours`,`${episode.duration} hours`)}
          </span>
        </p>
      </div>
      {!isEmpty(application) ? (
          <ApplicationButtons application={application} />
      ) : (
          <EpisodeButtons episode={episode} />
      )}
      {/*{episode.declined == false && (
        !isEmpty(application) ? (
          <ApplicationButtons application={application} />
        ) : (
          <EpisodeButtons episode={episode} />
        )
      )}

      {episode.declined && (
        <div style={{textAlign:'center'}}> {t('features:profileDating.declinedMsg')}</div>
      )}*/}
    </div>
    </>
  );
};

export default ItemPreview;
