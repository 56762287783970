import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'app/api';

export const getChatConnection = createAsyncThunk(
  'chat/getChatConnection',
  async (params) => {
    const response = await api.get(`v2/chats/open/${params.user_id}`);
    return response.data;
  }
);

export const rateMember = createAsyncThunk(
  'misc/rateMember',
  async (params) => {
    const res = await api.post('v2/chats/rating', {
      chat_id: params.chat_id,
      rating: params.rating,
    });
    return res.data;
  }
);

const initialState = {
  chatUser: {},
  unreadMessages: 0,
  chatChannels: [],
  fetched: false,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState: initialState,
  reducers: {
    initChat: (state, action) => {
      state.unreadMessages = action.payload.unreadMessages;
      state.chatChannels = action.payload.channels;
      state.chatUser = action.payload.me;
      state.fetched = true;
    },
    updateChatChannels: (state, action) => {
      state.chatChannels = action.payload.channels;
    },
    setUnreadMessagesCount: (state, action) => {
      state.unreadMessages = action.payload;
    },
    incrementUnreadMessagesCount: (state) => {
      state.unreadMessages += 1;
    },
    resetChatInfo: (state, action) => {
      state = initialState;
    },
  },
  extraReducers: {},
});

export const {
  setUnreadMessagesCount,
  incrementUnreadMessagesCount,
  initChat,
  updateChatChannels,
  resetChatInfo,
} = chatSlice.actions;
export const selectUnreadCount = (state) => state.chat.unreadMessages;
export const selectChatChannels = (state) => state.chat.chatChannels;
export const selectChatUser = (state) => state.chat.chatUser;
export const selectChatFetched = (state) => state.chat.fetched;

export default chatSlice.reducer;
