import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/api';

export const getUserStatistics = createAsyncThunk(
  'explorer/getUserStatistics',
  async () => {
    const response = await api.get('v2/dashboard');
    return response.data;
  }
);

export const UserStatistics = createSlice({
  name: 'statistics',
  initialState: {
    episodes: {},
    user: {},
    unreadMessages: 0,
  },
  reducers: {
    setUnreadMessages: (state, action) => {
      state.unreadMessages = action.payload;
    },
  },
  extraReducers: {
    [getUserStatistics.fulfilled]: (state, { payload }) => {
      state.episodes = payload.data.episodes;
      state.user = payload.data.user;
    },
  },
});

export const { setUnreadMessages } = UserStatistics.actions;
export const selectStatistics = (state) => state.statistics;

export default UserStatistics.reducer;
