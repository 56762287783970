import api from 'app/api';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './withSubscription.module.css';

export const Bundle = ({
  duration,
  discount,
  originalPrice,
  price,
  onClick,
  insgesamt
}) => {
  const onSelect = () => {
    onClick();
  };
  return (
    <div onClick={onSelect} className={styles.bundle}>
      <span className={styles.duration}>{duration}
        {discount > 0 && (
            <span className={styles.discount}>{`- ${discount}%`}</span>
        )}
      {/*{discount > 0 ? (
        <span className={styles.discount}>{`- ${discount}%`}</span>
      ) : (
        <span style={{ opacity: 0 }} className={styles.discount}>
          0% discount
        </span>
      )}*/}
        </span>
      {/*{originalPrice && (
        <span
          style={originalPrice === price ? { opacity: 0 } : null}
          className={styles.originalPrice}
        >
          {originalPrice}
        </span>
      )}*/}
      <span className={styles.price}>{price} {insgesamt}</span>
    </div>
  );
};

const WithSubscription = ({ actor, onSelect }) => {
  const { t } = useTranslation();
  const { id } = actor;
  const [subSettings, setSubSettings] = useState(null);

  useEffect(() => {
    const getSettings = async () => {
      try {
        const settings = await api.get(`/v2/subscription/user_settings/${id}`);
        setSubSettings(settings.data.data);
      } catch (error) {
        console.error(error);
        setSubSettings([]);
      }
    };

    getSettings();
  }, [id]);

  const selectBundle = (bundle) => {
    onSelect(bundle);
  };

  if (!subSettings) {
    return <div className={styles.container}>{/* <LoaderRelative /> */}</div>;
  }

  if (isEmpty(subSettings.prices)) return null;

  return (
    <div className={styles.container}>
      <div className={styles.seperator}>
        <div></div>
        <span>{t('features:subscriptions.subscribeModal.or')}</span>
        <div></div>
      </div>
      <p className={styles.sub}>
        {t('features:subscriptions.subscribeModal.subscribeToAll')}
      </p>
      <div className={styles.bundles}>
        {subSettings.prices.map((b) => {
          return (
            <Bundle
              key={b.subscription_price_id}
              id={b.subscription_price_id}
              onClick={() => {
                selectBundle(b);
              }}
              duration={b.title}
              price={b.price_text}
              discount={b.discount_percent}
              originalPrice={b.original_price_text}
            />
          );
        })}
      </div>
    </div>
  );
};

export default WithSubscription;
