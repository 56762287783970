import React, { createContext, useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StreamChat } from 'stream-chat';
import { useTranslation } from 'react-i18next';
import { formatRelative } from 'date-fns';
import { de, enGB  } from 'date-fns/locale';
import { isEmpty } from 'lodash';

import { useWindowWidthLessThan } from 'hooks';
import { getAvatarSrc } from 'helpers/s3media';
import { selectUserInfo } from 'features/auth/authSlice';
import { setUnreadMessages } from 'features/auth/userStatistics';
import {
  incrementUnreadMessagesCount,
  initChat,
  updateChatChannels,
  // selectChatUser,
  // resetChatInfo,
} from './chatSlice';

export const ChatContext = createContext(null);
const chatClient = new StreamChat(process.env.REACT_APP_STREAM_CHAT_APP_KEY);

export const ChatContextProvider = ({ children }) => {
  const user = useSelector(selectUserInfo);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const lang = i18n.language === 'de' ? de : enGB;
  const [isChatError, setChatError] = useState(false);
  const mobileView = useWindowWidthLessThan(961);

  const noText = t('features:chat.nothing');
  const noTextImg = t('features:chat.attachment');

  const getChannels = useCallback(
    (channels, userID) => {
      const collection = [];
      channels.forEach((c) => {
        for (const partner in c.state.members) {
          if (partner !== userID) {
            const lastMessage = c.state.messages
              ? Object.values(c.state.messages).pop()
              : null;
            const last_message_at = c.state.last_message_at
              ? formatRelative(new Date(c.state.last_message_at), new Date(), {
                  locale: lang,
                })
              : null;
            const last_message_content = !lastMessage
              ? noText
              : lastMessage.text
              ? lastMessage.text
              : !isEmpty(lastMessage.attachments)
              ? noTextImg
              : noText;
            collection.push({
              ...c.state.members[partner],
              channelId: c.id,
              lastMessage: last_message_content,
              unreadCount: c.state.unreadCount,
              last_message_at: last_message_at,
            });
          }
        }
      });
      return collection;
    },
    [lang, noText, noTextImg]
  );

  const setChatUser = useCallback(
    async (user) => {
      if (isEmpty(user)) throw new Error('no user provided!!');

      try {
        const { me } = await chatClient.connectUser(
          {
            id: user.uid.toString(),
            name: user.name,
            image: getAvatarSrc(user.avatar),
          },
          user.chat_token
        );

        const channels = await chatClient.queryChannels({
          members: { $in: [`${me.id}`] },
        });

        dispatch(
          initChat({
            unreadMessages: me.total_unread_count,
            channels: getChannels(channels, me.id),
            me: me,
          })
        );
        dispatch(setUnreadMessages(me.total_unread_count));
      } catch (error) {
        setChatError(error.message);
      }
    },
    [dispatch, getChannels]
  );

  const updateChannels = useCallback(async () => {
    const userID = user.uid.toString();
    try {
      const channels = await chatClient.queryChannels({
        members: { $in: [`${userID}`] },
      });

      dispatch(
        updateChatChannels({
          channels: getChannels(channels, userID),
        })
      );
    } catch (error) {
      setChatError(error.message);
    }
  }, [dispatch, getChannels, user.uid]);

  useEffect(() => {
    function startChatEventListeners() {
      chatClient.on((event) => {
        if (event.type === 'message.new' || event.type === 'message.read') {
          updateChannels(user);
        }

        if (window.location.pathname.includes('chat')) return;

        switch (event.type) {
          case 'message.new':
            dispatch(incrementUnreadMessagesCount());
            break;
          case 'notification.added_to_channel':
            dispatch(incrementUnreadMessagesCount());
            break;
          default:
            break;
        }
      });
    }

    async function initStreamChat() {
      try {
        await setChatUser(user);
        startChatEventListeners();
      } catch (error) {
        setChatError(error.message);
      }
    }
    initStreamChat();
    return () => {
      chatClient.disconnectUser();
      // dispatch(resetChatInfo());
    };
  }, [user, setChatUser, updateChannels, dispatch]);

  return (
    <ChatContext.Provider value={{ chatClient, isChatError, mobileView }}>
      {children}
    </ChatContext.Provider>
  );
};
