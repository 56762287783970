import React, { useEffect, useState } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import {
  format,
  isToday,
  isTomorrow,
  startOfHour,
  addMinutes,
  startOfDay,
  endOfDay,
  differenceInHours,
  addHours,
} from 'date-fns';
import { de, enGB  } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

const DateStartRangeInput = ({ name, label, className }) => {
  const { i18n } = useTranslation();
  const lang = i18n.language === 'de' ? de : enGB;
  const {
    values: { date, time },
    setFieldValue,
  } = useFormikContext();

  const [max, setMax] = useState(0);
  const [dayStart, setDayStart] = useState(new Date());

  useEffect(() => {
    const isDateToday = isToday(date);

    const calculateRange = () => {
      const now = new Date();
      const dEnd = endOfDay(now);
      let dStart = startOfHour(now);

      const hoursUntilEnd = differenceInHours(dEnd, dStart);

      if (!isDateToday) {
        if (hoursUntilEnd < 2 && isTomorrow(date)) {
          return {
            dayStart: addHours(startOfDay(date), 2 - hoursUntilEnd),
            hoursUntilEnd: 23.5 - 2 + hoursUntilEnd,
          };
        } else {
          return {
            dayStart: startOfDay(date),
            hoursUntilEnd: 23.5,
          };
        }
      }

      return {
        dayStart: addHours(dStart, 3),
        hoursUntilEnd: Math.abs(hoursUntilEnd) - 2.5,
      };
    };

    const { dayStart, hoursUntilEnd } = calculateRange();

    setDayStart(dayStart);
    setFieldValue('dayStart', dayStart);
    setFieldValue(time, dayStart);
    setMax(hoursUntilEnd * 2);
  }, [date, setFieldValue, time]);

  if (!max) {
    return null;
  }

  return (
    <div className={className} style={{ margin: '0px 10px' }}>
      <label htmlFor={name}>{label}</label>
      <input
        type="range"
        value={time}
        min={0}
        max={max}
        step={1}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
          setFieldValue('dayStart', dayStart);
        }}
      ></input>
      <div
        style={{
          padding: '10px 0px',
          textAlign: 'right',
          color: 'var(--grey4)',
        }}
      >
        {format(addMinutes(dayStart, time * 30), 'p', { locale: lang })}
      </div>
      <span className="validationError">
        <ErrorMessage name={name} />
      </span>
    </div>
  );
};

export default DateStartRangeInput;
