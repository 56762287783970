import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';

import ActionsDrawer from './Drawer';
import ContextMenu from './Popover';
import Button from 'components/buttons/Button';
import {
  BlockUserModal,
  ReportStoryModal,
  DeleteStoryModal,
  StoryInsightsModal,
} from './Modals';
import { GlobalContext, ProgressContext } from 'features/stories/context';
import { useModal } from 'components/modal/useModal';
import styles from './storyActions.module.css';

const StoryActions = ({ children, pauseAction }) => {
  const [showMenu, setShowMenu] = useState();
  const [showDrawer, setShowDrawer] = useState(false);
  const blockModal = useModal();
  const reportkModal = useModal();
  const deleteModal = useModal();
  const insightsModal = useModal();
  const menuRef = useRef(null);

  const {
    closeAction,
    profile,
    isMyProfile,
    isMobile,
    isActive,
    actionsRef,
  } = useContext(GlobalContext);
  const { story } = useContext(ProgressContext);

  const trackClickOutsideMenu = useCallback(
    (e) => {
      if (!menuRef.current) return;

      document.removeEventListener('scroll', trackClickOutsideMenu, true);

      if (!menuRef.current.contains(e.target)) {
        document.removeEventListener('click', trackClickOutsideMenu, true);
        pauseAction('play');
        setShowMenu(false);
      }
    },
    [menuRef, pauseAction]
  );

  const closeContextMenu = () => {
    document.removeEventListener('click', trackClickOutsideMenu, true);
    document.removeEventListener('scroll', trackClickOutsideMenu, true);
    setShowMenu(false);
  };

  const onClickHandler = () => {
    pauseAction('pause');
    if (isMobile) {
      setShowDrawer(true);
    } else {
      setShowMenu(true);
    }
  };

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('click', trackClickOutsideMenu, true);
      document.addEventListener('scroll', trackClickOutsideMenu, true);
    }
  }, [showMenu, trackClickOutsideMenu]);

  return (
    <>
      <div className={styles.container}>
        <Button
          ref={isActive ? actionsRef : null}
          name="btn-bare"
          className={styles.actionsButton}
          onClick={onClickHandler}
        >
          {children}
        </Button>
        <ContextMenu
          show={showMenu}
          isMobile={isMobile}
          isMyProfile={isMyProfile}
          blockModal={blockModal}
          reportModal={reportkModal}
          deleteModal={deleteModal}
          insightsModal={insightsModal}
          onCloseMenu={closeContextMenu}
          ref={menuRef}
        />
      </div>
      {!showMenu && (
        <>
          {blockModal.isVisible && (
            <BlockUserModal
              profileUser={profile}
              modalContext={blockModal}
              pauseAction={pauseAction}
            />
          )}
          {reportkModal.isVisible && (
            <ReportStoryModal
              story={story}
              profileUser={profile}
              pauseAction={pauseAction}
              modalContext={reportkModal}
            />
          )}
          {deleteModal.isVisible && (
            <DeleteStoryModal
              story={story}
              closeAction={closeAction}
              pauseAction={pauseAction}
              profileUser={profile}
              modalContext={deleteModal}
            />
          )}
          {insightsModal.isVisible && (
            <StoryInsightsModal
              story={story}
              closeAction={closeAction}
              pauseAction={pauseAction}
              profileUser={profile}
              modalContext={insightsModal}
            />
          )}
        </>
      )}
      <ActionsDrawer
        isMyProfile={isMyProfile}
        profileUser={profile}
        story={story}
        blockModal={blockModal}
        reportModal={reportkModal}
        deleteModal={deleteModal}
        insightsModal={insightsModal}
        isVisible={showDrawer}
        pauseAction={pauseAction}
        onClickAction={() => setShowDrawer(false)}
      />
    </>
  );
};

export default StoryActions;
