import { configureStore } from '@reduxjs/toolkit';
import combinedReducers from './reducers';

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout/fulfilled') {
    // resetting store after logout
    state = undefined;
  }
  return combinedReducers(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducers', () => {
    const newRootReducer = require('./reducers').default;
    store.replaceReducer(newRootReducer);
  });
}

export default store;
