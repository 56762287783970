import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import LazyLoad from 'react-lazyload';
import cx from 'classnames';

import LoadMoreTrigger from 'components/loadMore/LoadMoreTrigger';
import {
  getFeedMediaSrc,
  getFeedMediaPreviewSrc,
  getVideoThumbSrc,
} from 'helpers/s3media';
// import { getVideoMimeTypeByFormat } from 'helpers/videoMimeType';
import { PhotoSwipe } from 'react-photoswipe';

import { IconPhoto, IconVideo, IconLock } from 'assets/icons';
import styles from './profileMedia.module.css';

const ProfileMedia = ({ files, hasMore, getMore, fullWidth }) => {
  const [filteredFiles, setFilteredFiles] = useState({});
  const [filesIndex, setFilesIndex] = useState(0);
  const [toggler, setToggler] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  let postID = null;

  useEffect(() => {
    const filteredFiles = filter(files, function (o) {
      return o.content_type !== 2;
    });
    setFilteredFiles(filteredFiles);
  }, [files]);

  const getFiles = () => {
    let items = [];
    files.forEach((file) => {
      if (file.content_type !== 2) {
        const poster = getVideoThumbSrc(file.media);
        const file_src = getFeedMediaSrc(file.media);
        // const file_mime = getVideoMimeTypeByFormat(file.media.format);
        if (file.media.type === 'video') {
          items.push({
            html: `<div class="pswp__custom_item custom_item">
                        <video controls disablePictureInPicture controlslist="nodownload" playsinline poster="${poster}">
                          <source src="${file_src}">
                          Your browser does not support the video tag.
                        </video>
                    </div>`,
          });
        } else {
          items.push({
            html: `<div class="pswp__custom_item custom_item"><img src="${file_src}" alt="" /></div>`,
          });
        }
      }
    });
    return items;
  };

  const handleChange = (instance) => {
    document.querySelectorAll('video').forEach((vid) => {
      if (typeof vid.pause === 'function') {
        vid.pause();
      }
    });
    const currentIndex = instance.getCurrentIndex();
    const post_id = filteredFiles[currentIndex].stream_activity_id;
    if (post_id) {
      postID = post_id;
    }
  };

  const handleClick = (item) => {
    if (
      item.media.type === 'video' &&
      (!item.media.transform ||
        (!item.media.transform.webm && item.media.transform.hls))
    ) {
      console.log('video missing');
      return false;
    }
    const index = findIndex(filteredFiles, { id: item.id });
    setFilesIndex(index);
    setToggler(true);
  };

  const handlePostClick = () => {
    setToggler(false);
    history.push(`/post/${postID}`);
  };

  const handleOnClose = () => {
    document.querySelectorAll('video').forEach((vid) => {
      if (typeof vid.pause === 'function') {
        vid.pause();
        vid.currentTime = 0;
      }
    });
    setToggler(false);
  };

  return (
    <>
      <div className={styles.gallery}>
        {files.map((item, index) => {
          if (item.content_type === 2) {
            // pay content
            return (
              <div
                key={index}
                className={cx(styles.galleryItem, styles.pay, {
                  [styles.full]: fullWidth,
                  [styles.empty]:
                    item.media.type === 'video' &&
                    (!item.media.transform || !item.media.transform.jpg),
                })}
                onClick={() => history.push(`/post/${item.stream_activity_id}`)}
              >
                <LazyLoad offset={200}>
                  <img
                    src={getFeedMediaPreviewSrc(item.media, {
                      width: process.env.REACT_APP_FILE_WIDTH_SMALL,
                      height: process.env.REACT_APP_FILE_WIDTH_SMALL,
                      fit: 'cover',
                    })}
                    alt=""
                  />
                </LazyLoad>
                <span className={styles.overlay}>
                  <IconLock className={styles.lock} />
                </span>
                <div className={styles.meta}>
                  <span>
                    {item.media.type === 'image' ? (
                      <IconPhoto className={styles.metaIcon} />
                    ) : (
                      <IconVideo className={styles.metaIcon} />
                    )}
                  </span>
                  <span className={styles.metaRight}>
                    {item.price} Coins
                    <IconLock className={styles.metaIcon} />
                  </span>
                </div>
              </div>
            );
          }
          // free content
          return (
            <div
              key={index}
              className={cx(styles.galleryItem, {
                [styles.full]: fullWidth,
              })}
              onClick={() => handleClick(item)}
            >
              <LazyLoad offset={200}>
                <img
                  src={getFeedMediaPreviewSrc(item.media, {
                    width: process.env.REACT_APP_FILE_WIDTH_SMALL,
                    height: process.env.REACT_APP_FILE_WIDTH_SMALL,
                    fit: 'cover',
                  })}
                  alt=""
                />
                {item.media.type === 'video' && (
                  <span className={styles.overlayVideo}>
                    <span className={styles.play} />
                  </span>
                )}
              </LazyLoad>
            </div>
          );
        })}
      </div>
      {hasMore && <LoadMoreTrigger hasMore={hasMore} getMore={getMore} />}
      <PhotoSwipe
        options={{
          bgOpacity: 0.5,
          index: filesIndex,
          closeElClasses: [
            'item',
            'custom_item',
            'caption',
            'zoom-wrap',
            'ui',
            'top-bar',
          ],
          closeOnScroll: false,
          fullscreenEl: false,
          zoomEl: true,
          shareEl: false,
          counterEl: false,
          arrowEl: true,
          preloaderEl: true,
          history: false,
        }}
        beforeChange={(instance) => handleChange(instance)}
        isOpen={toggler}
        items={getFiles()}
        onClose={handleOnClose}
      />
      {toggler && (
        <span onClick={handlePostClick} className={styles.post_link}>
          {t('features:profileInfo.goToPost')}
        </span>
      )}
    </>
  );
};

export default ProfileMedia;
