import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import ProfileCardPreview from 'components/profileCard/ProfileCard';
import LoadMoreTrigger from 'components/loadMore/LoadMoreTrigger';
import { LoaderInline } from 'components/layout/loader';
import {
  selectUserList,
  getUserList,
  selectUserListStatus,
  getUserListMore,
} from './userListSlice';
import { selectAuthenticated } from 'features/auth/authSlice';
import styles from './search/search.module.css';

const UserList = ({ narrow = false }) => {
  const dispatch = useDispatch();
  const data = useSelector(selectUserList);
  const status = useSelector(selectUserListStatus);
  const isAuthenticated = useSelector(selectAuthenticated);
  const { t } = useTranslation();

  const fetchNext = () => {
    if (!isEmpty(data.next) && !status.loading) {
      dispatch(getUserListMore(data.next));
    }
  };

  useEffect(() => {
    if (isEmpty(data.results) && !data.fetched) {
      dispatch(getUserList({ isPublic: !isAuthenticated }));
    }
  }, [dispatch, data.results, data.fetched, isAuthenticated]);

  if (isEmpty(data.results)) {
    if (data.fetched || status.hasError) {
      return (
        <div style={{ margin: '20px 0px 80px 16px' }}>
          <p>{t('features:search.NoResults', 'No results')}</p>
        </div>
      );
    } else {
      return <LoaderInline />;
    }
  }

  return (
    <div className={styles.container}>
      {data.results.map((profile, index) => (
        <ProfileCardPreview
          key={`${profile.id}_${index}`}
          profile={profile}
          narrow={narrow}
          isPublic={!isAuthenticated}
        />
      ))}

      {!isEmpty(data.results) && (
        <LoadMoreTrigger getMore={fetchNext} hasMore={!isEmpty(data.next)} />
      )}
    </div>
  );
};

export default UserList;
