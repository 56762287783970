import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'app/api';

export const getGuestEpisodes = createAsyncThunk(
    'episodes/getGuestEpisodes',
    async (params = { looking_for: null, city:null, country_code:null }, { rejectWithValue, getState }) => {
        // set looking_for if not provided
        const genderSelection =
            params.looking_for || getState().misc.filters.gender;

        //console.log(getState().misc.filters.filter_episode_applications);
        const EpisodeApplicationFilterSelection =
            getState().misc.filters.filter_episode_applications;

        const response = await api.get(`guests/episodes`,
            {params: {
                country_code: params.country_code,
                city: params.city,
                looking_for: genderSelection,
                filter_episode_applications: EpisodeApplicationFilterSelection
            }});

        /*const response = await api.get(`guests/episodes`, {
            looking_for: genderSelection,
            filter_episode_applications: EpisodeApplicationFilterSelection,
        });*/
        if (!response.data.status) {
            return rejectWithValue(response.data.error);
        }
        return { ...response.data, looking_for: genderSelection };
    }
);

export const getEpisodes = createAsyncThunk(
  'episodes/getEpisodes',
  async (params = { looking_for: null }, { rejectWithValue, getState }) => {
    // set looking_for if not provided
    const genderSelection =
      params.looking_for || getState().misc.filters.gender;
    //console.log(getState().misc.filters.filter_episode_applications);
    const EpisodeApplicationFilterSelection =
        getState().misc.filters.filter_episode_applications;

    const response = await api.post(`v2/episodes`, {
      looking_for: genderSelection,
        filter_episode_applications: EpisodeApplicationFilterSelection,
    });
    if (!response.data.status) {
      return rejectWithValue(response.data.error);
    }
    return { ...response.data, looking_for: genderSelection };
  }
);


export const getGuestEpisodeDetail = createAsyncThunk(
    'episodes/getGuestEpisodeDetail',
    async (params, { rejectWithValue }) => {
        const response = await api.get(`guests/episode/${params.episode_id}`);
        /*if (!response.data.status) {
            return rejectWithValue(response.data.error);
        }*/
        return response.data;
    }
);


export const getEpisodeDetail = createAsyncThunk(
  'episodes/getEpisodeDetail',
  async (params, { rejectWithValue }) => {
    const response = await api.get(`v2/episode/${params.episode_id}`);
    if (!response.data.status) {
      return rejectWithValue(response.data.error);
    }
    return response.data;
  }
);

export const createEpisodeCheck = createAsyncThunk(
  'episodes/createEpisodeCheck',
  async (params) => {
    const response = await api.get(`v2/episode/init`, params);
    return response.data;
  }
);

export const createEpisode = createAsyncThunk(
  'episodes/createEpisode',
  async (params) => {
    const response = await api.post(`v2/episode/create`, params);
    return response.data;
  }
);

export const closeEpisode = createAsyncThunk(
  'episodes/closeEpisode',
  async ({ id }) => {
    const response = await api.get(`v2/episode/${id}/close`);
    return response.data;
  }
);

export const changeCountry = createAsyncThunk(
  'episodes/changeCountry',
  async (params) => {
    const response = await api.put('v1/user/country', { country: params.country });
    return response.data;
  }
);

export const changeCity = createAsyncThunk(
  'episodes/changeCity',
  async (params) => {
    const response = await api.put('v1/user/city', { city: params.city });
    return response.data;
  }
);

export const applyEpisode = createAsyncThunk(
  'episodes/apply',
  async ({ id }, { getState }) => {
    const looking_for = getState().misc.filters.gender;
    const response = await api.post(`v2/episode/${id}/apply`, { looking_for });
    return response.data;
  }
);

export const denyEpisode = createAsyncThunk(
  'episodes/deny',
  async ({ id }, { getState }) => {
    const looking_for = getState().misc.filters.gender;
    const response = await api.post(`v2/episode/${id}/deny`, { looking_for });
    return response.data;
  }
);

export const flagUser = createAsyncThunk('episodes/flag', async (params) => {
  const response = await api.post(`v1/contacts/flag/${params.contact_id}`);
  return response.data;
});

export const blockUser = createAsyncThunk('episodes/block', async (params) => {
  const response = await api.post(`v1/contacts/block/${params.contact_id}`);
  return response.data;
});

const initialState = {
  unreadCount: 0,
  episodeDetail: {},
  episodeGuestDetail: {},
  episodesList: {
    loading: false,
    hasError: false,
    success: false,
    fetched: false,
    data: [],
  },
  episodesGuestList: {
    loading: false,
    hasError: false,
    success: false,
    fetched: false,
    data: [],
  },
};

export const episodesSlice = createSlice({
  name: 'episodes',
  initialState: initialState,
  reducers: {
    resetGuestEpisodesList: (state) => {
      state.episodesGuestList = initialState.episodesGuestList;
    },
    resetGuestEpisodeDetail: (state) => {
      state.episodeGuestDetail = initialState.episodeGuestDetail;
    },
    resetEpisodesList: (state) => {
      state.episodesList = initialState.episodesList;
    },
    resetEpisodeDetail: (state) => {
      state.episodeDetail = initialState.episodeDetail;
    },
    resetNewEpisodesCount: (state) => {
      //state.unreadCount = initialState.unreadCount;
    },
    incrementNewEpisodesCount: (state) => {
      state.unreadCount += 1;
    },
  },
  extraReducers: {
    [getGuestEpisodes.pending]: (state, action) => {
      state.episodesGuestList.loading = true;
      state.episodesGuestList.hasError = false;
      state.episodesGuestList.success = false;
    },
    [getGuestEpisodes.fulfilled]: (state, action) => {
      state.episodesGuestList.loading = false;
      state.episodesGuestList.fetched = true;
      state.episodesGuestList.success = true;
      state.episodesGuestList.data = action.payload.data.list;
      state.unreadCount = action.payload.data.paginate.total;
    },
    [getGuestEpisodes.rejected]: (state, action) => {
      state.episodesGuestList.loading = false;
      state.episodesGuestList.fetched = true;
      state.episodesGuestList.hasError = true;
    },
    [getEpisodes.pending]: (state, action) => {
      state.episodesList.loading = true;
      state.episodesList.hasError = false;
      state.episodesList.success = false;
    },
    [getEpisodes.fulfilled]: (state, action) => {
      state.episodesList.loading = false;
      state.episodesList.fetched = true;
      state.episodesList.success = true;
      state.episodesList.data = action.payload.data.list;
      state.unreadCount = action.payload.data.paginate.total;
    },
    [getEpisodes.rejected]: (state, action) => {
      state.episodesList.loading = false;
      state.episodesList.fetched = true;
      state.episodesList.hasError = true;
    },
    [getEpisodeDetail.fulfilled]: (state, action) => {
      state.episodeDetail = action.payload.data;
    },
    [getGuestEpisodeDetail.fulfilled]: (state, action) => {
      state.episodeGuestDetail = action.payload.data;
    },
  },
});

export const {
  resetEpisodesList,
  resetEpisodeDetail,
  resetNewEpisodesCount,
  incrementNewEpisodesCount,
} = episodesSlice.actions;

export const selectEpisodes = (state) => state.episodes.episodesList.data;
export const selectEpisodeDetail = (state) => state.episodes.episodeDetail;
export const selectEpisodesFetched = (state) =>
  state.episodes.episodesList.fetched;
export const selectNewEpisodesCount = (state) => state.episodes.unreadCount;

export const selectGuestEpisodes = (state) => state.episodes.episodesGuestList.data;
export const selectGuestEpisodeDetail = (state) => state.episodes.episodeGuestDetail;
export const selectGuestEpisodesFetched = (state) =>
    state.episodes.episodesGuestList.fetched;

export default episodesSlice.reducer;
