import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import {Col, Row} from 'components/layout/grid';
import {selectUserInfo} from 'features/auth/authSlice';
import Loader, {LoaderRelative} from 'components/layout/loader';
import styles from './continentsList.module.css';
import cx from "classnames";
import {
    getContinents,
    selectContinents,
    selectContinentsFetched
} from "../clubs/clubsSlice";
import {useTranslation} from "react-i18next";
import ContinentListItem from "./ContinentListItem";

const ContinentsList = ({hideFilter}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const fetchedClubs = useSelector(selectContinentsFetched);
    const continentList = useSelector(selectContinents);
    const user = useSelector(selectUserInfo);
    const [activeItem, setActiveItem] = useState(0);

    useEffect(() => {
        if (isEmpty(continentList) && !fetchedClubs) {
            dispatch(getContinents());
        }
    }, [dispatch, user, continentList, fetchedClubs]);

    if (isEmpty(continentList) && !fetchedClubs) {
        return (
            <div>
                <Loader/>
            </div>
        );
    }

    const handleClick = (index) => {

        setActiveItem(index);
        setTimeout(() => {
            const currentScrollPosition = window.scrollY;
            window.scrollTo(0, currentScrollPosition - 120);
        }, 100); // 3000 milliseconds = 3 seconds
    };

    const wrapperClass = cx(styles.row, styles.eventlist, styles.grid);

    return (
        <div>
            {isEmpty(continentList) && !fetchedClubs ? (
                <LoaderRelative/>
            ) : (
                <>
                    <Row className={cx(styles.row, styles.eventlist, styles.grid, styles.eventlistContinent)}>
                        <Col md={12} className={styles.continentdiv}>
                            <ul className={styles.continent}>
                                {continentList.map((continent, index) => {
                                    return (
                                        <li key={index}
                                            className={index === activeItem ? styles.active : ''}
                                        >
                                            <a href={`#${index}`} onClick={() => handleClick(index)}>
                                                {continent.name}
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                            <div className={styles.continentdivinner}>
                            </div>
                        </Col>
                    </Row>

                    {continentList.map((continent, index) => {
                        return (
                            <Row id={index} key={index} className={wrapperClass}>
                                <Col md={12}>
                                    <h2 className={styles.heading}>{continent.name}</h2>
                                    <div className={styles.tagline}>
                                        {t(`features:clubList.tagline`, 'The most requested party hot spots in')} {continent.name}
                                    </div>
                                    <Row className={wrapperClass}>
                                        {continent.cities.map((city, i) => {
                                            return <ContinentListItem hideFilter={hideFilter} key={i} city={city}/>;
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        );
                    })}
                </>
            )}
        </div>
    );
};

export default ContinentsList;
