import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { selectPublicRegister, setShowPublicRegister } from 'app/miscSlice';
import { getLayoutImageSrc } from 'helpers/s3media';
import Register from './Register';
import { IconClose } from 'assets/icons';
import Button from 'components/buttons/Button';
import { useModal } from 'components/modal/useModal';
import { authLeft } from 'assets/default';
import NavTabs from 'components/navTabs';
import styles from './publicModal.module.css';

const RegisterModal = () => {
  const { RenderModal, showModal, hideModal } = useModal();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const showPublicRegister = useSelector(selectPublicRegister);
  const { t } = useTranslation();

  useEffect(() => {
    if (showPublicRegister) {
      showModal();
    }
    return () => dispatch(setShowPublicRegister(false));
  }, [dispatch, showPublicRegister, showModal]);

  const handleClose = () => {
    hideModal();
    dispatch(setShowPublicRegister(false));
  };

  const tabItems = [
    {
      to: '/login',
      text: t('main:main.Login'),
      // exact: true,
    },
    {
      to: pathname,
      text: t('main:main.Register'),
    },
  ];

  return (
    <RenderModal
      customStyles={{
        maxHeight: '90vh',
        maxWidth: 650,
        margin: 0,
        overflowY: 'scroll',
        padding: 0,
      }}
      bareInner
      buttonClose
      onClose={() => dispatch(setShowPublicRegister(false))}
    >
      <div className={styles.wrapper}>
        <Button name="btn-bare" className={styles.close} onClick={handleClose}>
          <IconClose />
        </Button>
        <div className={styles.left}>
          <img src={getLayoutImageSrc(authLeft)} alt="auth" />
        </div>
        <div className={styles.right}>
          <NavTabs items={tabItems} small />
          <span className={styles.title}>
            {t('features:auth.registerPublic.modalTitle')}
          </span>
          <Register isModal />
          <span className={styles.loginText}>
            {t('features:auth.registerPublic.alreadyRegistered')}
            <Link to="/login">{t('main:main.Login')}</Link>
          </span>
        </div>
      </div>
    </RenderModal>
  );
};

export default RegisterModal;
