import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from 'app/api';

export const getContinents = createAsyncThunk(
    'clubs/getContinents',
    async (params = {looking_for: null}, {rejectWithValue, getState})    => {

        const response = await api.post(`v2/continents`);
        if (!response.data.status) {
            return rejectWithValue(response.data.error);
        }
        return {...response.data};
    }
);

export const getCityClubs = createAsyncThunk(
    'clubs/getCityClubs',
    async (params = { id: null}, {rejectWithValue, getState})    => {

        const response = await api.post(`v2/clubs/city/${params.id}`);

        if (!response.data.status) {
            return rejectWithValue(response.data.error);
        }
        return {...response.data};
    }
);

export const getClubs = createAsyncThunk(
    'clubs/getClubs',
    async (params = {looking_for: null}, {rejectWithValue, getState})    => {

        const EpisodeApplicationFilterSelection =
            getState().misc.filters.filter_episode_applications;

        const response = await api.post(`v2/clubs`, {
            country_code: params.country_code,
            filter_episode_applications: EpisodeApplicationFilterSelection,
        });
        if (!response.data.status) {
            return rejectWithValue(response.data.error);
        }
        return {...response.data};
    }
);

export const getClubDetail = createAsyncThunk(
    'clubs/getClubDetail',
    async (params, {rejectWithValue}) => {
        const response = await api.get(`v2/club/${params.club_id}`);
        if (!response.data.status) {
            return rejectWithValue(response.data.error);
        }
        return response.data;
    }
);


export const clubBooking = createAsyncThunk(
    'clubs/booking',
    async (payload, { rejectWithValue }) => {
        const response = await api.post('v2/club_booking/create', {
            ...payload,
        });
        if (!response.data.status) {
            return rejectWithValue(response.data.error);
        }
        return response.data;
    }
);


const initialState = {
    unreadCount: 0,
    clubDetail: {},
    clubList: {
        loading: false,
        hasError: false,
        success: false,
        fetched: false,
        data: [],
    },
    cityClubList: {
        loading: false,
        hasError: false,
        success: false,
        fetched: false,
        city: [],
        data: [],
    },
    continentList: {
        loading: false,
        hasError: false,
        success: false,
        fetched: false,
        data: [],
    }
};

export const clubsSlice = createSlice({
    name: 'clubs',
    initialState: initialState,
    reducers: {
        resetContinentList: (state) => {
            state.continentList = initialState.continentList;
        },
        resetCityClubList: (state) => {
            state.cityClubList = initialState.cityClubList;
        },
        resetClubList: (state) => {
            state.clubList = initialState.clubList;
        },
        resetClubDetail: (state) => {
            state.clubDetail = initialState.clubDetail;
        },
    },
    extraReducers: {
        [getContinents.fulfilled]: (state, action) => {
            state.continentList.loading = false;
            state.continentList.fetched = true;
            state.continentList.success = true;
            state.continentList.data = action.payload.data.list;
            //state.unreadCount = action.payload.data.paginate.total;
        },
        [getClubs.fulfilled]: (state, action) => {
            state.clubList.loading = false;
            state.clubList.fetched = true;
            state.clubList.success = true;
            state.clubList.data = action.payload.data.list;
            state.unreadCount = action.payload.data.paginate.total;
        },
        [getCityClubs.fulfilled]: (state, action) => {
            state.cityClubList.loading = false;
            state.cityClubList.fetched = true;
            state.cityClubList.success = true;
            state.cityClubList.city = action.payload.data.city;
            state.cityClubList.data = action.payload.data.list;
        },
        [getClubDetail.fulfilled]: (state, action) => {
            state.clubDetail = action.payload.data;
        },
        [clubBooking.fulfilled]: (state, action) => {
            state.clubBookingDetail = action.payload.data;
        },
    },
});

export const {
    resetClubList,
    resetCityClubList,
    resetContinentList,
    resetClubDetail,
} = clubsSlice.actions;

export const selectContinentsFetched = (state) =>
    state.clubs.continentList.fetched;
export const selectContinents = (state) => state.clubs.continentList.data;

export const selectCityClubsFetched = (state) =>
    state.clubs.cityClubList.fetched;
export const selectCityClubs = (state) => state.clubs.cityClubList.data;
export const selectedCityDetails = (state) => state.clubs.cityClubList.city;

export const selectClubsFetched = (state) =>
    state.clubs.clubList.fetched;
export const selectClubs = (state) => state.clubs.clubList.data;

export const selectClubDetail = (state) => state.clubs.clubDetail;
export const selectClubBookingDetail = (state) => state.clubs.clubBookingDetail;

export default clubsSlice.reducer;