import { createSlice } from '@reduxjs/toolkit';

// About status:
// DRAFT => 1
// PENDING_FOR_APPROVAL => 2
// APPROVED => 3
// REJECTED => 4

// Activity status:
// DRAFT => 1
// PENDING_FOR_APPROVAL => 2
// APPROVED => 3
// NOT_APPROVED => 4

export const infoMessagesSlice = createSlice({
  name: 'infoMessages',
  initialState: {
    userDissmissed: false,
    descriptioUpdate: {
      status: 0,
    },
    newPosts: {
      total: 0,
    },
  },
  reducers: {
    setInfoMessages: (state, action) => {
      state.descriptioUpdate.status = action.payload.about_status;
      state.newPosts.total = action.payload.total_pending_posts;
    },
    closeDescriptioUpdate: (state) => {
      state.userDissmissed = true;
    },
  },
  extraReducers: {},
});

export const {
  setInfoMessages,
  closeDescriptioUpdate,
} = infoMessagesSlice.actions;
export const selectInfoMessages = (state) => state.infoMessages;

export default infoMessagesSlice.reducer;
