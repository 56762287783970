import { combineReducers } from '@reduxjs/toolkit';
import miscReducer from 'app/miscSlice';
import authReducer from 'features/auth/authSlice';
import userStatisticsReducer from 'features/auth/userStatistics';
import layoutReducer from 'components/layout/layoutSlice';
import chatReducer from 'features/chat/chatSlice';
import feedReducer from 'features/feed/feedSlice';
import settingsReducer from 'features/settings/settingsSlice';
import profileReducer from 'features/user/profileSlice';
import episodesReducer from 'features/dating/episodes/episodesSlice';
import clubsReducer from 'features/dating/clubs/clubsSlice';
import applicationsReducer from 'features/dating/applications/applicationsSlice';
import bookingsReducer from "../features/dating/clubs/bookingsSlice";
import transactionReducer from 'features/transactions/transactionsSlice';
import searchReducer from 'features/userList/search/searchSlice';
import userListReducer from 'features/userList/userListSlice';
import suggestionsReducer from 'features/user/suggestions/suggestionsSlice';
import topCreatorsReducer from 'features/home/topCreators/topCreatorsSlice';
import storiesReducer from 'features/stories/storiesSlice';
import infoMessagesReducer from 'features/infoMessages/infoMessagesSlice';
import voucherReducer from 'features/vouchers/voucherSlice';
import notificationsReducer from 'features/notifications/notificationsSlice';
import subscriptionsReducer from 'features/subscriptions/subscriptionsSlice';
import followingsReducer from 'features/settings/lists/followings/followingsSlice';
import connectionListReducer from "features/userList/connectionListSlice";

const combinedReducers = combineReducers({
  misc: miscReducer,
  auth: authReducer,
  chat: chatReducer,
  feed: feedReducer,
  profile: profileReducer,
  settings: settingsReducer,
  layout: layoutReducer,
  episodes: episodesReducer,
  clubs: clubsReducer,
  applications: applicationsReducer,
  bookings: bookingsReducer,
  transactions: transactionReducer,
  search: searchReducer,
  userList: userListReducer,
  suggestions: suggestionsReducer,
  topCreators: topCreatorsReducer,
  stories: storiesReducer,
  infoMessages: infoMessagesReducer,
  voucher: voucherReducer,
  statistics: userStatisticsReducer,
  notifications: notificationsReducer,
  subscriptions: subscriptionsReducer,
  followings: followingsReducer,
  connectionList: connectionListReducer,
});

export default combinedReducers;
