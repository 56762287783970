import { NavLink, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './navTabs.module.css';

const NavTabs = () => {
  const { path } = useRouteMatch();
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <nav className={styles.nav}>
        <NavLink
          className={styles.navItem}
          activeClassName={styles.active}
          to={`${path}`}
          isActive={(match, location) => {
            if (match.isExact || location.pathname.includes('/category/')) {
              return true;
            }
          }}
        >
          <span>{t('features:feed.nav.global')}</span>
        </NavLink>
        <NavLink
          className={styles.navItem}
          activeClassName={styles.active}
          to={`${path}/following`}
        >
          <span>{t('features:feed.nav.following')}</span>
        </NavLink>
       {/* <NavLink
          className={styles.navItem}
          activeClassName={styles.active}
          to={`${path}/purchased`}
        >
          <span>{t('features:feed.nav.purchased')}</span>
        </NavLink>*/}
      </nav>
    </div>
  );
};

export default NavTabs;
