import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const ReCaptchaProvider = ({ children }) => {
  return (
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={process.env.REACT_APP_GRECAPTCHA_SITE_KEY}
      scriptProps={{ async: true, defer: true, appendTo: 'body' }}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default ReCaptchaProvider;
