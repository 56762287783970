import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import cx from 'classnames';

import { IconPlus } from 'assets/icons';
import { trackEvent } from 'helpers/tracking';
import { CreateStoryTrigger } from 'features/stories';
import { FeedActive, Story } from 'assets/icons/mainMenu';
import {selectUserGender, selectUserInfo} from 'features/auth/authSlice';
import styles from './fabButton.module.css';
import {useModal} from "../modal/useModal";
import {VerificationModal} from "../../features/dating/episodes/VerificationModal";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Action = ({ children, ...p }) => (
  <button type="button" className={styles.fab__ab} {...p}>
    {children}
  </button>
);

const FabMain = ({ children, ...p }) => (
  <button type="button" className={styles.fab__mb} {...p}>
    {children}
  </button>
);

// const FabSingle = ({ onClick, ...p }) => {
//   return (
//     <ul className={styles.fab} {...p}>
//       <li className={styles.fab__mb__c}>
//         <FabMain onClick={onClick} role="button" tabIndex={0}>
//           <IconPlus className={styles.iconPlus} />
//         </FabMain>
//       </li>
//     </ul>
//   );
// };

export const FabSingleMenu = ({ onClick, ...p }) => {
  return (
    <ul {...p}>
      <li className={styles.fab__mb__c}>
        <FabMain onClick={onClick} role="button" tabIndex={0}>
          <IconPlus className={styles.iconPlus} />
        </FabMain>
      </li>
    </ul>
  );
};

const FabGroup = ({ children, onClick, ...p }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ariaHidden = !isOpen;
  const interpolatedEvent = 'ontouchstart' in window ? 'click' : 'hover';
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const enter = () => interpolatedEvent === 'hover' && open();
  const leave = () => interpolatedEvent === 'hover' && close();
  const toggle = (e) => {
    if (onClick) {
      return onClick(e);
    }
    e.persist();
    return interpolatedEvent === 'click' ? (isOpen ? close() : open()) : null;
  };

  const actionOnClick = (e, userFunc) => {
    e.persist();
    setIsOpen(false);
    setTimeout(() => {
      userFunc(e);
    }, 1);
  };

  const actions = () =>
    React.Children.map(children, (ch, i) => {
      if (React.isValidElement(ch)) {
        return (
          <li className={styles.fab__ab__c}>
            {React.cloneElement(ch, {
              'aria-label': ch.props.text || `Menu button ${i + 1}`,
              'aria-hidden': ariaHidden,
              tabIndex: isOpen ? 0 : -1,
              ...ch.props,
              onClick: (e) => {
                if (ch.props.onClick) actionOnClick(e, ch.props.onClick);
              },
            })}
            {ch.props.text && (
              <span
                className={cx(styles.right, styles.alwaysShow)}
                aria-hidden={ariaHidden}
              >
                {ch.props.text}
              </span>
            )}
          </li>
        );
      }
      return null;
    });

  return (
    <ul
      onMouseEnter={enter}
      onMouseLeave={leave}
      className={cx(styles.fab, { [styles.open]: isOpen })}
      {...p}
    >
      <li className={styles.fab__mb__c}>
        <FabMain
          onClick={toggle}
          role="button"
          aria-label="Floating menu"
          tabIndex={0}
        >
          <IconPlus className={styles.iconPlus} />
        </FabMain>
        <ul>{actions()}</ul>
      </li>
    </ul>
  );
};

const FabButton = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const userGender = useSelector(selectUserGender);
    const verificationModal = useModal();
    const { indispensable_verification } = useSelector(selectUserInfo);

  // const isDatingArea = location.pathname.includes('/dating');
  // const isContentArea = location.pathname.includes('/community');
  const isChatArea = location.pathname.includes('/chat');
  const createDatePath = '/create-event';
  const createPostPath = '/create-post';
  const hideButton = isChatArea;

  if (hideButton) {
    return null;
  }

  // if (isDatingArea || isContentArea) {
  //   const path = isDatingArea ? createDatePath : createPostPath;
  //   return <FabSingle onClick={() => history.push(path)} />;
  // }

  return (
    <FabGroup>
      <Action
        text={t('components:fabButton.createEvent')}
        onClick={() => {
          trackEvent({
            category: 'menus',
            action: 'create event click',
            label: userGender,
          });
            if(indispensable_verification === true) {
                verificationModal.showModal();
                return false;
            }
          history.push(createDatePath);
        }}
      >
          <VerificationModal
              modalContext={verificationModal}
          />
          <FontAwesomeIcon icon={faCalendar} className={`${styles.iconAction} ${styles.faIconAction}`} />
      </Action>
      <Action
        text={t('components:fabButton.createPost')}
        onClick={() => {
          trackEvent({
            category: 'menus',
            action: 'create post click',
            label: userGender,
          });
          history.push(createPostPath);
        }}
      >
        <FeedActive className={styles.iconAction} />
      </Action>
      <Action text={t('components:fabButton.createStory')}>
        <CreateStoryTrigger className={styles.story}>
          <Story className={cx(styles.iconAction, styles.story)} />
        </CreateStoryTrigger>
      </Action>
    </FabGroup>
  );
};

export default FabButton;
