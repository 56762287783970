import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { selectAuthenticated, selectUserGender } from 'features/auth/authSlice';
import { selectNewApplicationsCount } from 'features/dating/applications/applicationsSlice';
import { selectNewEpisodesCount } from 'features/dating/episodes/episodesSlice';
import {
  toggleSidebar,
  toggleCreateDrawer,
} from 'components/layout/layoutSlice';
import { setShowPublicRegister } from 'app/miscSlice';
import { selectUnreadCount } from 'features/chat/chatSlice';
import UserInfo from './UserInfo';
import { trackEvent } from 'helpers/tracking';
import { FabSingleMenu } from 'components/buttons/FabButton';
import styles from './mainMenu.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsis, faFeed, faHouse, faMessage, faUsers} from "@fortawesome/free-solid-svg-icons";

const MainMenu = () => {
  const { t } = useTranslation();
  const isAuthenticated = useSelector(selectAuthenticated);
  const userGender = useSelector(selectUserGender);
  const numOfUnreadMsg = useSelector(selectUnreadCount);
  const numOfNewApplications = useSelector(selectNewApplicationsCount);
  const numOfNewEpisodes = useSelector(selectNewEpisodesCount);
  const location = useLocation();
    const matchHome =
        ['/', '/feed', '/event/:id', '/event-requests','/create-event'].indexOf(
            location.pathname
        ) >= 0 || location.pathname.includes('event');
  //const matchHome = location.pathname === '/' || location.pathname.includes('events');
  // const matchLists = location.pathname === '/lists';
  const matchSettings = useRouteMatch('/settings');
  //const matchDating = location.pathname.includes('events');
  const matchUsers = location.pathname.includes('users');
  const matchCommunity =
    ['/create-post', '/feed', '/feed/following', '/feed/purchased'].indexOf(
      location.pathname
    ) >= 0;
  const matchChat = useRouteMatch('/chat');
  const dispatch = useDispatch();

  const datingCounter = numOfNewApplications + numOfNewEpisodes;

  const WrapperLink = ({ children, to, className, handleClick, tracking }) => {
    if (!isAuthenticated && to !== '/' && to !== '/feed') {
      return (
        <NavLink
          className={className}
          to="/"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            tracking();
            dispatch(setShowPublicRegister(true));
          }}
        >
          {children}
        </NavLink>
      );
    }
    return (
      <NavLink
        className={className}
        to={to || '/'}
        onClick={(e) => {
          if (!to) {
            e.stopPropagation();
            e.preventDefault();
          }
          handleClick();
          tracking();
        }}
      >
        {children}
      </NavLink>
    );
  };

  WrapperLink.propTypes = {
    handleClick: PropTypes.func,
    tracking: PropTypes.func,
  };

  WrapperLink.defaultProps = {
    handleClick: () => {},
    tracking: () => {},
  };

  return (
    <footer onClick={(e) => e.stopPropagation()} className={styles.wrapper}>
      {isAuthenticated && <UserInfo />}
      <nav className={styles.nav}>

        <WrapperLink
          className={styles.navLink}
          to="/"
          tracking={() => {
            trackEvent({
              category: `menus${isAuthenticated ? '' : ' - public'}`,
              action: 'events tab click',
              label: userGender,
            });
          }}
        >
          {matchHome ? (
            <FontAwesomeIcon icon={faHouse} className={`${styles.icon} ${styles.active}`} />
          ) : (
            <>
              <FontAwesomeIcon icon={faHouse} className={`${styles.icon}`} />
              {datingCounter > 0 && (
                <span className={styles.notoficationNum}>{datingCounter}</span>
              )}
            </>
          )}
          <span
            className={`${styles.navLinkText} ${
                matchHome ? styles.linkActive : ''
            }`}
          >
            {t('components:mainMenuLabel.Home')}
          </span>
        </WrapperLink>

        <WrapperLink
            className={styles.navLink}
            to="/users"
            tracking={() => {
              trackEvent({
                category: `menus${isAuthenticated ? '' : ' - public'}`,
                action: 'community tab click',
                label: userGender,
              });
            }}
        >
          {matchUsers ? (
              <FontAwesomeIcon icon={faUsers} className={`${styles.icon}  ${styles.active}`} />
          ) : (
              <FontAwesomeIcon icon={faUsers} className={`${styles.icon}`} />
          )}
          <span
              className={`${styles.navLinkText} ${
                  matchUsers ? styles.linkActive : ''
              }`}
          >
            {t('components:mainMenuLabel.Users', 'Users')}
          </span>
        </WrapperLink>

          <WrapperLink
              handleClick={() => {
                  dispatch(toggleCreateDrawer(true));
              }}
              className={`${styles.navLink} ${styles.hideDesk}`}
          >
              <FabSingleMenu className={styles.fab_menu} />
          </WrapperLink>

        <WrapperLink
            className={styles.navLink}
            to="/feed"
            tracking={() => {
              trackEvent({
                category: `menus${isAuthenticated ? '' : ' - public'}`,
                action: 'feed tab click',
                label: userGender,
              });
            }}
        >
          {matchCommunity ? (
              <FontAwesomeIcon icon={faFeed} className={`${styles.icon} ${styles.active}`} />
          ) : (
              <>
                <FontAwesomeIcon icon={faFeed} className={`${styles.icon}`} />
              </>
          )}
          <span
              className={`${styles.navLinkText} ${
                  matchCommunity ? styles.linkActive : ''
              }`}
          >
            {t('components:mainMenuLabel.Feed')}
          </span>
        </WrapperLink>

        <WrapperLink
          className={styles.navLink}
          to="/chat"
          tracking={() => {
            trackEvent({
              category: `menus${isAuthenticated ? '' : ' - public'}`,
              action: 'chat tab click',
              label: userGender,
            });
          }}
        >
          {matchChat ? (
            <FontAwesomeIcon icon={faMessage} className={`${styles.icon} ${styles.active}`} />
          ) : (
            <>
              <FontAwesomeIcon icon={faMessage} className={`${styles.icon}`} /> {' '}
              {!!numOfUnreadMsg && (
                <span className={styles.notoficationNum}>{numOfUnreadMsg}</span>
              )}
            </>
          )}
          <span
            className={`${styles.navLinkText} ${
              matchChat ? styles.linkActive : ''
            }`}
          >
            {t('components:mainMenuLabel.Chats')}
          </span>
        </WrapperLink>

        {/*<WrapperLink
          className={`${styles.navLink} ${styles.hideMobile}`}
          to="/lists"
        >
          {matchLists ? (
            <Icons.ListsActive className={`${styles.icon} ${styles.active}`} />
          ) : (
            <Icons.Lists className={styles.icon} />
          )}
          <span
            className={`${styles.navLinkText} ${
              matchLists ? styles.linkActive : ''
            }`}
          >
            {t('components:mainMenuLabel.Lists')}
          </span>
        </WrapperLink>*/}

        <WrapperLink
          handleClick={() => {
            dispatch(toggleSidebar());
          }}
          tracking={() => {
            trackEvent({
              category: `menus${isAuthenticated ? '' : ' - public'}`,
              action: 'open sidebar click',
              label: userGender,
            });
          }}
          className={`${styles.navLink} ${styles.hideMobile}`}
        >
          {matchSettings ? (
            <FontAwesomeIcon icon={faEllipsis} className={`${styles.icon} ${styles.active}`} />
          ) : (
              <FontAwesomeIcon icon={faEllipsis} className={`${styles.icon}`} />
          )}
          <span
            className={`${styles.navLinkText} ${
              matchSettings ? styles.linkActive : ''
            }`}
          >
            {t('components:mainMenuLabel.More')}
          </span>
        </WrapperLink>

        {/*<WrapperLink
          className={`${styles.navLink} ${styles.hideMobile}`}
          handleClick={() => {
            window.location.assign(process.env.REACT_APP_PAYMENTS_CHECKOUT);
          }}
          tracking={() => {
            trackEvent({
              category: `menus${isAuthenticated ? '' : ' - public'}`,
              action: 'buy coins click',
              label: userGender,
            });
          }}
        >
          <FontAwesomeIcon icon={faCopyright} className={`${styles.icon} ${styles.pink}`} />
          <span className={`${styles.navLinkText} ${styles.pink}`}>
            {t('components:mainMenuLabel.BuyCoins')}
          </span>
        </WrapperLink>*/}
      </nav>
    </footer>
  );
};

export default MainMenu;
