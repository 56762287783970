import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useInView} from 'react-intersection-observer';
import cx from 'classnames';

import {selectUserGender} from 'features/auth/authSlice';
import {shareEventLink, shareLink} from 'helpers/shareLink';
import {trackEvent} from 'helpers/tracking';
import ProfileActions from '../profileActions';
import Button from 'components/buttons/Button';
import styles from './profileInfo.module.css';
import {Share, Tips, Follow, Following, TipChat} from "../../../assets/icons/profile";
import {IconEdit} from "../../../assets/icons";
import {ChatsActive} from "../../../assets/icons/mainMenu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMessage} from "@fortawesome/free-regular-svg-icons";
import {faLink, faLinkSlash} from "@fortawesome/free-solid-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";

const ProfileButtons = ({
                            mainActions,
                            profileUser,
                            inViewAction,
                            isMyProfile,
                            isPublic,
                            onHeader,
                        }) => {
    const {ref, inView} = useInView();
    const {chat, follow, subscribe, more, edit, share} = mainActions || {};

    useEffect(() => {
        if (inViewAction && window.pageYOffset > 0) {
            inViewAction(inView);
        }
    }, [inView, inViewAction]);

    const wrapperClassName = cx(styles.actions, {
        [styles.stickyRight]: onHeader,
        [styles.moreHidden]: !more.show && onHeader,
    });

    return (
        <div className={wrapperClassName} ref={inViewAction ? ref : null}>
            <div className={styles.actionsButtons}>
                {edit.show && <EditButton/>}
                {chat.show && (
                    <ChatButton small={subscribe.show} handleChat={chat.onClick}/>
                )}

                {subscribe.show && (
                    <SubscribeButton
                        onClick={subscribe.onClick}
                        price={profileUser.subscription.price.amount_text}
                        isSubscribed={profileUser.subscription.subscribed}
                    />
                )}

                {follow.show && (
                    <FollowButton
                        onClick={follow.onClick}
                        showModal={follow.showModal}
                        isFollowing={profileUser.following}
                        loading={follow.loading}
                        userName={profileUser.name}
                    />
                )}
                {share.show && (
                    <ShareButton profileUser={profileUser} isPublic={isPublic}/>
                )}
                {more.show && (
                    <MoreButton
                        profileUser={profileUser}
                        isMyProfile={isMyProfile}
                        isPublic={isPublic}
                    />
                )}
            </div>
        </div>
    );
};

const MoreButton = ({
                        profileUser,
                        mainActions,
                        isMobile,
                        isMyProfile,
                        isPublic,
                    }) => {
    return (
        <Button className={styles.buttonMore}>
            <ProfileActions
                isPublic={isPublic}
                profileUser={profileUser}
                isMyProfile={isMyProfile}
                pinkTrigger
                mainActions={mainActions}
                isMobile={isMobile}
            />
        </Button>
    );
};

const ChatButton = ({handleChat, small}) => {
    const {t} = useTranslation();

    const className = cx(styles.chatBtn, {[styles.small]: small});

    return (
        <Button onClick={handleChat} className={className}>
            {t('features:profileInfo.openChat')}
        </Button>
    );
};

const ChatButtonRound = ({handleChat, small, withText}) => {
    const {t} = useTranslation();
    return (
        <div
            className={cx(styles.Ellipse1, styles.gradientWhite, styles.tooltip, {
                [styles.small]: small,
                [styles.withText]: withText,
            })}
            onClick={handleChat}
        >
            <span className={styles.tooltiptext}>{t('features:profileInfo.openChat', 'Open Chat')}</span>
            <ChatsActive width={small ? '10' : '12'}/>
            {withText && <span>{t('features:profileInfo.openChat')}</span>}
        </div>
    );
};


const ChatDirectButtonRound = ({handleChat, small, withText}) => {
    const {t} = useTranslation();
    return (
        <div
            className={cx(styles.Ellipse1, styles.gradientWhite, {
                [styles.small]: small,
                [styles.withText]: withText,
            })}
            onClick={handleChat}
        >
            {/*<ChatsActive width={small ? '10' : '12'}/>*/}
            <FontAwesomeIcon icon={faMessage} color={'white'} width={20}/>
            {withText && <span>{t('features:profileInfo.openChat')}</span>}
        </div>
    );
};

const TipButton = ({handleTip, small}) => {
    const {t} = useTranslation();

    const className = cx(styles.chatBtn, {[styles.small]: small});

    return (
        <Button onClick={handleTip} className={className}>
            {t('features:transactions.sendTip')}
        </Button>
    );
};

const TipButtonRound = ({handleTip, small, withText}) => {
    const {t} = useTranslation();
    return (
        <div
            className={cx(styles.Ellipse1, styles.tooltip, {
                [styles.small]: small,
                [styles.withText]: withText,
            })}
            onClick={handleTip}
        >
            <span className={styles.tooltiptext}>{t('features:transactions.sendTip', 'Send Tip')}</span>
            <Tips width={small ? '10' : '12'}/>
            {withText && <span>{t('features:transactions.sendTip', 'Send Tip')}</span>}
        </div>
    );
};

const TipButtonWithText = ({handleTip, small, withText}) => {
    const {t} = useTranslation();
    return (
        <div
            className={cx(styles.tipsvg, styles.tooltip, {
                [styles.small]: small,
                [styles.withText]: withText,
            })}
            onClick={handleTip}
        >
            {/*<span className={styles.tooltiptext}>{t('features:transactions.sendTip', 'Send Tip')}</span>*/}
            <Tips width={small ? '10' : '12'}/>
            {withText && <span>{t('features:transactions.sendTip', 'Send Tip')}</span>}
        </div>
    );
};

/*Display Tip Icon for chat*/
const TipChatButtonWithText = ({handleTip, small, withText}) => {
    const {t} = useTranslation();
    return (
        <div
            className={cx(styles.tipsvgnew, styles.tooltip, {
                [styles.small]: small,
                [styles.withText]: withText,
            })}
            onClick={handleTip}
        >
            {/*<span className={styles.tooltiptext}>{t('features:transactions.sendTip', 'Send Tip')}</span>*/}
            <TipChat width={small ? '10' : '12'}/>
            {withText && <span>{t('features:transactions.sendTip', 'Send Tip')}</span>}
        </div>
    );
};

const EditButton = () => {
    const {t} = useTranslation();
    const history = useHistory();

    return (
        <Button
            className={styles.editButton}
            onClick={() => history.push('/settings/profile/edit')}
        >
            <span>{t('features:profileInfo.Edit')}</span>
        </Button>
    );
};

const EditButtonRound = ({small, withText}) => {
    const {t} = useTranslation();
    const history = useHistory();

    return (
        <div
            className={cx(styles.Ellipse1,styles.tooltip,  {
                [styles.small]: small,
                [styles.withText]: withText,
            })}
            onClick={() => history.push('/settings/profile/edit')}
        >
            <span className={styles.tooltiptext}>{t('features:profileInfo.Edit', 'Edit')}</span>
            <IconEdit width={small ? '10' : '12'}/>
            {withText && <span>{t('features:profileInfo.Edit')}</span>}
        </div>
    );
};

const ShareButton = ({profileUser, onClick, isPublic}) => {
    const {t} = useTranslation();
    const userGender = useSelector(selectUserGender);

    const handleShare = () => {
        if (onClick) {
            onClick();
        }
        trackEvent({
            category: `profile pages${isPublic ? ' - public' : ''}`,
            action: 'share click',
            label: userGender,
        });
        shareLink({
            title: profileUser.name,
            path: profileUser.hash_id,
            success: t('features:profileInfo.copied'),
        });
    };

    return (
        <Button onClick={() => handleShare()} className={styles.shareButton}>
            <span>{t('features:profileInfo.shareProfile')}</span>
        </Button>
    );
};

const ShareClubButton = ({club, onClick, isPublic, small, withText}) => {
    const {t} = useTranslation();
    const userGender = useSelector(selectUserGender);

    const handleShare = () => {
        if (onClick) {
            onClick();
        }
        trackEvent({
            category: `profile pages${isPublic ? ' - public' : ''}`,
            action: 'share click',
            label: userGender,
        });
        shareLink({
            title: club.name,
            path: club.key_id,
            success: t('features:club.copied','Link to club was copied to clipboard!'),
        });
    };

    return (
        <div
            className={cx(styles.bold, styles.tooltip, {
                [styles.small]: small,
                [styles.withText]: withText,
            })}
            onClick={handleShare}
        >
            <span className={styles.tooltiptext}>{t('features:club.share', 'Share')} </span>
            <Share width={small ? '10' : '12'}/>
            {withText && <span> {t('features:club.share', 'Share')}</span>}
        </div>
    );
};


const ShareEventButtonFull = ({episode, onClick, isPublic}) => {
    const {t} = useTranslation();
    const userGender = useSelector(selectUserGender);

    const handleShare = () => {
        if (onClick) {
            onClick();
        }
        trackEvent({
            category: `event pages${isPublic ? ' - public' : ''}`,
            action: 'share click',
            label: userGender,
        });
        shareLink({
            title: episode.name,
            path: episode.id,
            success: t('features:event.copied','Link to event was copied to clipboard!'),
        });
    };

    return (
        <Button onClick={() => handleShare()} className={cx(styles.shareButton,styles.block, styles.center, styles.black)}>
            <span>{t('features:profileInfo.shareProfile')}</span>
        </Button>
    );
};

const ShareEventButton = ({episode, onClick, isPublic, small, withText}) => {
    const {t} = useTranslation();
    const userGender = useSelector(selectUserGender);

    const handleShare = () => {
        if (onClick) {
            onClick();
        }
        trackEvent({
            category: `event pages${isPublic ? ' - public' : ''}`,
            action: 'share click',
            label: userGender,
        });
        shareLink({
            title: episode.name,
            path: episode.id,
            success: t('features:event.copied','Link to event was copied to clipboard!'),
        });
    };

    return (
        <div
            className={cx(styles.bold, styles.tooltip, {
                [styles.small]: small,
                [styles.withText]: withText,
            })}
            onClick={handleShare}
        >
            <span className={styles.tooltiptext}>{t('features:club.share', 'Share')} </span>
            <Share width={small ? '10' : '12'}/>
            {withText && <span> {t('features:club.share', 'Share')}</span>}
        </div>
    );
};

const ShareButtonRound = ({profileUser, onClick, isPublic, small, withText}) => {
    const {t} = useTranslation();
    const userGender = useSelector(selectUserGender);

    const handleShare = () => {
        if (onClick) {
            onClick();
        }
        trackEvent({
            category: `profile pages${isPublic ? ' - public' : ''}`,
            action: 'share click',
            label: userGender,
        });
        shareLink({
            title: profileUser.name,
            path: profileUser.hash_id,
            success: t('features:profileInfo.copied'),
        });
    };

    return (
        <div
            className={cx(styles.Ellipse1, styles.tooltip, {
                [styles.small]: small,
                [styles.withText]: withText,
            })}
            onClick={handleShare}
        >
            <span className={styles.tooltiptext}>{t('features:profileInfo.shareProfile', 'Share')}</span>
            <Share width={small ? '10' : '12'}/>
            {withText && <span>{t('features:profileInfo.shareProfile')}</span>}
        </div>
    );
};


const ShareButtonEventRound = ({episode, onClick, isPublic, small, withText}) => {
    const {t} = useTranslation();
    const userGender = useSelector(selectUserGender);

    const handleShare = () => {
        if (onClick) {
            onClick();
        }
        trackEvent({
            category: `event pages${isPublic ? ' - public' : ''}`,
            action: 'share click',
            label: userGender,
        });
        shareEventLink({
            title: episode.name,
            path: episode.id,
            success: t('features:profileInfo.copiedEvent'),
        });
    };

    return (
        <div
            className={cx(styles.Ellipse1, styles.tooltip, {
                [styles.small]: small,
                [styles.withText]: withText,
            })}
            onClick={handleShare}
        >
            <span className={styles.tooltiptext}>{t('features:profileInfo.shareProfile', 'Share')}</span>
            <Share width={small ? '10' : '12'}/>
            {withText && <span>{t('features:profileInfo.shareProfile')}</span>}
        </div>
    );
};

const FollowButton = ({
                          onClick,
                          showModal,
                          isFollowing,
                          loading,
                          userName,
                      }) => {
    const {t} = useTranslation();

    const followText = isFollowing
        ? t('features:profileInfo.Following')
        : t('features:profileInfo.Follow');

    const handleClickButton = () => {
        if (isFollowing) {
            showModal();
        } else {
            onClick();
        }
    };

    const className = cx(styles.followBtn, {
        [styles.following]: isFollowing,
    });

    return (
        <Button onClick={handleClickButton} loading={loading} className={className}>
            <span>{followText}</span>
        </Button>
    );
};


const ConnectionButtonRound = ({
                               onClick,
                               showModal,
                               showCancelModal,
                               isConnected,
                               loginUid,
                               small,
                               withText
                           }) => {
    const {t} = useTranslation();

    console.log("isConnected==>",isConnected);

    const {status, user1_id}  = isConnected;
    const statusInteger = parseInt(status);

    const connectable = statusInteger === 1;
    const disConnectable = statusInteger === 3;
    const cancellable = !connectable && !disConnectable && statusInteger === 2  && parseInt(loginUid) === parseInt(user1_id);
    const confirmable = !connectable && !disConnectable && statusInteger === 2  && parseInt(loginUid) !== parseInt(user1_id);

    const connectionText =
        connectable ? t('features:profileInfo.Connect', 'Send friend request') :
        cancellable ? t('features:profileInfo.Cancel','Cancel friend request') :
        confirmable ? t('features:profileInfo.Confirm','Accept friend request') :
        disConnectable  ? t('features:profileInfo.Disconnect','Unfriend') :
        '';
console.log("connectionText==", connectionText);

    const handleClickButton = (statusInteger) => {
        //set action for dispatch
        if (cancellable) {
            console.log("clickc");
            showCancelModal();
        } else if (disConnectable) {
            showModal();
        } else if(confirmable) {
            onClick(4);
        } else {
            onClick(statusInteger);
        }
    };

    return (
        <>
        <div
            className={cx(styles.Ellipse1, styles.tooltip, {
                [styles.small]: small,
                [styles.withText]: withText,
            })}
            onClick={() => handleClickButton(statusInteger)}
        >
            {statusInteger === 1 && (
                <>
                    <span className={styles.tooltiptext}>{connectionText}</span>
                    <FontAwesomeIcon icon={faLink} color={'black'} width={20}/>
                    {withText && <span>{connectionText}</span>}
                </>
            )}

            {statusInteger === 2 && (
                <>
                    <span className={styles.tooltiptext}>{connectionText}</span>
                    <FontAwesomeIcon icon={faSpinner} className={'fa-spin'} color={'black'} width={20}/>
                    {withText && <span>{connectionText}</span>}
                </>
            )}

            {statusInteger === 3 && (
                <>
                    <span className={styles.tooltiptext}>{connectionText}</span>
                    <FontAwesomeIcon icon={faLinkSlash} color={'black'} width={20}/>
                    {withText && <span>{connectionText}</span>}
                </>
            )}
        </div>
            {/*{statusInteger === 2 && (
                <div
                    className={cx(styles.Ellipse1, styles.tooltip, {
                        [styles.small]: small,
                        [styles.withText]: withText,
                    })}
                    onClick={() => handleClickButton(statusInteger)}
                >
                    <span className={styles.tooltiptext}>{connectionText}</span>
                    <FontAwesomeIcon icon={faLinkSlash} className={'fa-spin'} color={'black'} width={20}/>
                    {withText && <span>{connectionText}</span>}
                </div>
            )}*/}
        </>
    );
};

const FollowButtonRound = ({
                               onClick,
                               showModal,
                               isFollowing,
                               loading,
                               userName,
                               small,
                               withText
                           }) => {
    const {t} = useTranslation();

    const followText = isFollowing
        ? t('features:profileInfo.Following')
        : t('features:profileInfo.Follow');

    const handleClickButton = () => {
        if (isFollowing) {
            showModal();
        } else {
            onClick();
        }
    };

    return (
        <div
            className={cx(styles.Ellipse1, styles.tooltip, {
                [styles.small]: small,
                [styles.withText]: withText,
            })}
            onClick={handleClickButton}
        >
            {isFollowing && (
                <>
                    <span className={styles.tooltiptext}>{t('features:profileInfo.Following', 'Following')}</span>
                    <Following width={small ? '10' : '12'}/>
                    {withText && <span>{followText}</span>}
                </>
            )}

            {!isFollowing && (
                <>
                    <span className={styles.tooltiptext}>{t('features:profileInfo.Follow', 'Follow')}</span>
                    <Follow width={small ? '10' : '12'}/>
                    {withText && <span>{followText}</span>}
                </>
            )}
        </div>
    );
};

const SubscribeButton = ({isSubscribed, onClick, price}) => {
    const {t} = useTranslation();

    const text = isSubscribed
        ? t('features:subscriptions.subscribeModal.subscribed')
        : t('features:subscriptions.subscribeModal.subscribeFor', {price: price});

    return (
        <Button
            disabled={isSubscribed}
            className={styles.subscribeBtn}
            onClick={onClick}
            dangerouslySetInnerHTML={{ __html: text }}
            style={{ justifyContent: isSubscribed ? 'center' : 'space-between' }}
        >
        </Button>
    );
};

const SubscribeButtonRound = ({
                                  mainActions,
                                  profileUser,
                                  inViewAction,
                                  isMyProfile,
                                  isPublic,
                                  onHeader,
                              }) => {
    const {subscribe} = mainActions || {};
    return (
        <div className={styles.buttonNew}>
            {subscribe.show && (
                <SubscribeButton
                    onClick={subscribe.onClick}
                    price={profileUser.subscription.price.amount_text}
                    isSubscribed={profileUser.subscription.subscribed}
                />
            )}
        </div>
    );
}

const ProfileButtonNew = ({
                              mainActions,
                              profileUser,
                              inViewAction,
                              isMyProfile,
                              isPublic,
                              hideMore,
                              onHeader,
                          }) => {
    const { ref, inView } = useInView();
    //const { chat, follow, subscribe, more, edit, share } = mainActions || {};
    const {chat, share, connection, follow, edit, more, subscribe} = mainActions || {};
    //console.log(edit.show);
        useEffect(() => {
          if (inViewAction && window.pageYOffset > 0) {
            inViewAction(inView);
          }
        }, [inView, inViewAction]);


    const wrapperClassName = cx(styles.actions, {
      [styles.stickyRight]: onHeader,
      [styles.moreHidden]: !more.show && onHeader,
    });

    const justifyWrapper = profileUser.banner === null ? { justifyContent: 'left'} : { justifyContent: 'right' };

    /*const { t } = useTranslation();*/
    /*<div className={styles.buttonNew} style={profileUser.banner === null && ({ justifyContent:"start" }) ||
            profileUser.banner && ({ justifyContent:"start" })}>*/

    return (
        /*<div className={styles.buttonNew}>*/
        <div className={wrapperClassName} ref={inViewAction ? ref : null} style={justifyWrapper}>

            {/*{tip.show && (
                <TipButtonRound profileUser={profileUser} isPublic={isPublic} handleTip={tip.onClick}/>
            )}*/}

            {share.show && (
                <ShareButtonRound profileUser={profileUser} isPublic={isPublic}/>
            )}

            {subscribe.show && (
                <SubscribeButton
                    onClick={subscribe.onClick}
                    price={profileUser.subscription.price.amount_text}
                    isSubscribed={profileUser.subscription.subscribed}
                />
            )}

            {connection.show && (
                <ConnectionButtonRound
                    onClick={connection.onClick}
                    showModal={connection.showModal}
                    showCancelModal={connection.showCancelModal}
                    isConnected={profileUser.connection}
                    loading={connection.loading}
                    loginUid={connection.uid}
                />
            )}

            {follow.show && (
                <FollowButtonRound
                    onClick={follow.onClick}
                    showModal={follow.showModal}
                    isFollowing={profileUser.following}
                    loading={follow.loading}
                    userName={profileUser.name}
                />
            )}

            {chat.show && (
                <ChatButtonRound handleChat={chat.onClick}/>
            )}

            {more.show && hideMore !== true && (
                <MoreButton
                    profileUser={profileUser}
                    isMyProfile={isMyProfile}
                    isPublic={isPublic}
                />
            )}

            {edit.show && <EditButtonRound/>}
        </div>
    );
}

export default ProfileButtons;

export {
    SubscribeButton,
    SubscribeButtonRound,
    FollowButton,
    FollowButtonRound,
    ConnectionButtonRound,
    EditButton,
    EditButtonRound,
    ShareButton,
    ShareButtonRound,
    ShareEventButton,
    ShareEventButtonFull,
    ShareClubButton,
    ShareButtonEventRound,
    ChatButton,
    ChatButtonRound,
    ChatDirectButtonRound,
    ProfileButtonNew,
    MoreButton,
    TipButton,
    TipButtonRound,
    TipButtonWithText,
    TipChatButtonWithText
};
