import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import {Col, Row} from 'components/layout/grid';
import Loader, {LoaderRelative} from 'components/layout/loader';
import styles from './continentsList.module.css';
import cx from "classnames";
import {
    getCityClubs, resetCityClubList,
    selectCityClubs,
    selectCityClubsFetched, selectedCityDetails,
} from "../clubs/clubsSlice";
import {useParams} from "react-router-dom";
import CityClubCard from "./CityClubCard";
import {useTranslation} from "react-i18next";

const CityClubList = ({hideFilter}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {id} = useParams();
    const fetchedClubs = useSelector(selectCityClubsFetched);
    const clubList = useSelector(selectCityClubs);
    const city = useSelector(selectedCityDetails);
    //const user = useSelector(selectUserInfo);

    useEffect(() => {
        if (isEmpty(clubList) && !fetchedClubs) {
            dispatch(getCityClubs({id: id}));
            window.scrollTo(0, 0);
        }
        /* return () => {
             dispatch(resetCityClubList());
         };*/
    }, [dispatch, clubList, fetchedClubs, id]);

    useEffect(() => {
        return () => dispatch(resetCityClubList());
    }, [dispatch]);

    if (isEmpty(clubList) && !fetchedClubs) {
        return (
            <div>
                <Loader/>
            </div>
        );
    }
    let wrapperClass = cx(styles.row, styles.eventlist, styles.grid);

    return (
        <div>
            {/*<SubHeader
                hideOnMobile
                backBtn
                screenName={t('features:clubs.club')}
                style={{ paddingLeft: '15px' }}
            />*/}
            {isEmpty(clubList) && !fetchedClubs ? (
                <LoaderRelative/>
            ) : (
                <Row className={wrapperClass}>
                    <Col md={12}>
                        <h2 className={styles.heading}>{city.name}</h2>
                        <div className={styles.tagline}>
                            {t(`features:clubList.tagline`, 'The most requested party hot spots in')} {city.name}
                        </div>
                        <Row className={cx(styles.row, styles.eventlist, styles.grid, styles.rowCityCard)}>
                            {clubList.map((club, i) => {
                                return <CityClubCard hideFilter={hideFilter} key={i} club={club}/>;
                            })}
                        </Row>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default CityClubList;
