import styles from "./eventCardWaiting.module.css";
import Avatar from "../../../components/avatar/Avatar";
import {monthDateTime} from "../../../helpers/dating";
import React from "react";
import {de, enGB} from "date-fns/locale";
import cx from "classnames";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {LocationInfo} from "../../user/profileInfo/profileMetaInfo";
import {Col, Row} from "../../../components/layout/grid";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EpisodeActions from "../episodeActions/EpisodeActions";

const EventWaitingCard = ({episode, hideFilter, pendingApplications, deniedApplications, eventOwner}) => {
    const {i18n} = useTranslation();
    const lang = i18n.language === 'de' ? de : enGB;

    const history = useHistory();
    const {venue} = episode;
    const {t} = useTranslation();
    /*const firstImage = episode.images.length > 0 ? episode.images[0] : null;
    
    const firstImageUrl = firstImage ? getImageSrc({
        public_id: firstImage.image,
        format: JSON.parse(firstImage.extra).format,
        resize: {
            width: 400,
            fit: 'cover',
        },
    }) : getImageSrc(bannerDefault);*/
    //console.log("pendingApplications===>", pendingApplications);

    const firstImageUrl = JSON.parse(venue.photos)[0];

    //let wrapperClass = styles.wrapper;
    let wrapperClass = cx(styles.col);
    if (hideFilter) {
        wrapperClass = cx(styles.col, styles.homeouter);
    }
    const handleClick = () => {
        //const profileLink = isPublic ? `/event-request/${episode.id}` : `/event/event-request/${episode.id}`;
        //const profileLink = isPublic ? `/event/${episode.key_id}` : `/event/${episode.key_id}`;
        const profileLink = `/event/${episode.key_id}`;
        history.push(profileLink);
    }

    //const acceptedApplications = episode.applications.filter(application => application.is_accepted === true);
    const listApplicant = episode.applications.slice(0, 5);
    const remainingApplicationCount = episode.applications.length - listApplicant.length;

    return (
        <div className={wrapperClass}>
            <div className={styles.outerbox}>
                <Row onClick={handleClick}>
                    <Col xs={6} className={styles.eventimg}>
                        <div className={styles.imgover}>
                            {episode.event_type &&
                            <span className={styles.cartegorynameleft}>{episode.event_type}</span>
                            }
                        </div>

                        <div className={styles.imgoverbottom}>
                            <span className={styles.eventdateleft}>
                                <FontAwesomeIcon icon={faCalendar} width={13} style={{marginRight: '5px'}}/>
                                {monthDateTime(episode, lang)}
                            </span>
                        </div>

                        <div
                            className={cx(styles.clubImageContainer, styles.clubImageCardNew)}>
                            <div>
                                <img
                                    src={firstImageUrl}
                                    className={styles.eventimg}
                                    alt={episode.name}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} className={styles.eventinfo}>
                        <div className={styles.newblock}>
                            <div className={styles.descleft}>
                                <h3>{episode.name}</h3>
                                <div className={styles.venue}>{venue.name}</div>
                            </div>
                            <LocationInfo
                                customClass={styles.cityleft}
                                town_name={venue.city}
                                country={venue.country_code.toLowerCase()}
                            />
                        </div>
                    </Col>
                </Row>
                <div className={styles.pare}>
                    {!eventOwner &&
                    <div className={styles.first}>
                        <strong>{t('features:eventRequest.host', 'Host:')}</strong>
                        <Avatar
                            /*onClick={onClick}*/
                            size={25}
                            image={episode.user.avatar}
                            gender={episode.user.gender}
                            circle
                            className={styles.imguserover}
                        />
                    </div>
                    }

                    {
                        listApplicant.length > 0 &&
                        <div className={styles.second}>
                            <strong>{t('features:eventRequest.participants', 'Participants:')}</strong>

                            {listApplicant.map((application, i) => {
                                return (
                                    <Avatar
                                        key={i}
                                        size={25}
                                        image={application.profile.avatar}
                                        gender={application.profile.gender}
                                        circle
                                    />
                                );
                            })}
                            {remainingApplicationCount > 0 &&
                            <div className={styles.moreApplicationCount}>+{remainingApplicationCount}</div>
                            }
                        </div>
                    }

                    <EpisodeActions episode_id={episode.id} />
                </div>


                {
                    pendingApplications.length > 0 &&
                    <div className={styles.statusOne} onClick={handleClick}>
                        <div>
                            <span>{t('features:eventRequest.waitingForApproval', 'Waiting For Approval')}</span>
                        </div>
                    </div>
                }
                {
                    deniedApplications.length > 0 &&
                    <div className={styles.statusTwo} onClick={handleClick}>
                        <div>
                            <span>{t('features:eventRequest.declined', 'Declined')}</span>
                        </div>
                    </div>
                }
            </div>
            {/*{!isEmpty(applications) && (
                <ApplicationsList episode={episode} episodesCount={episodesCount} />
            )}*/
            }
        </div>
    );
}

export default EventWaitingCard;