import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { map, isEmpty } from 'lodash';
import { genderNumToString } from 'helpers/dating';
import api from 'app/api';

export const createPost = createAsyncThunk(
  'feed/createPost',
  async (params, { rejectWithValue }) => {
    const response = await api.post('v2/posts/create', {
      tempID: params.id,
      text: params.text,
      price: params.price,
      media: params.media,
      categories: params.categories
    });
    if (!response.data.status) {
      return rejectWithValue(response.data.error);
    }
    return response.data;
  }
);

export const getGlobalFeed = createAsyncThunk(
  'feed/getGlobalFeed',
  async (params, { rejectWithValue, getState }) => {
    const { feedCategories, gender } = getState().misc.filters;
    const catQuery =
      params.categories ||
      (!isEmpty(feedCategories) ? map(feedCategories, 'id').join() : null);
    const version = params.isPublic ? 'guests' : 'v2';
    const response = await api.get(`${version}/feed`, {
      params: {
        category: catQuery,
        gender: genderNumToString(gender),
      },
    });
    if (!response.data.status) {
      return rejectWithValue(response.data.error);
    }
    return response.data;
  }
);

export const getGlobalFeedMore = createAsyncThunk(
  'feed/getGlobalFeedMore',
  async (next) => {
    const response = await api.get(next);
    return response.data;
  }
);

export const getFeedList = createAsyncThunk('feed/getList', async (params) => {
  const response = await api.get(`v2/feeds/${params.endpoint}`, {
    params: { last_id: params.last_id },
  });
  return response.data;
});

export const getFeedDetail = createAsyncThunk(
  'feed/getDetail',
  async (params) => {
    const response = await api.get(`v2/activities/details/${params.post_id}`);
    return response.data;
  }
);

export const getFeedPublic = createAsyncThunk(
  'feed/getFeedPublic',
  async (params) => {
    const response = await api.get(`v2/external/feed/en/${params.hash_id}`);
    return response.data;
  }
);

export const setTempFile = createAsyncThunk(
  'feed/setTempFile',
  async (params, { rejectWithValue }) => {
    const response = await api.post(
      `v2/posts/add_temp_media/${params.temp_id}`,
      { file_info: params.file_info }
    );
    if (!response.data.status) {
      return rejectWithValue(response.data.error);
    }
    return response.data;
  }
);

const initialState = {
  createPost: {
    loading: false,
    hasError: false,
    success: false,
  },
  setTempFile: {
    hasError: false,
    success: false,
  },
  globalFeed: {
    results: [],
    categories: [],
    next: '',
    fetched: false,
    fetching: false,
    hasError: false,
  },
};

export const feedSlice = createSlice({
  name: 'feed',
  initialState: initialState,
  reducers: {
    resetGlobalFeed: (state) => {
      state.globalFeed = initialState.globalFeed;
    },
  },
  extraReducers: {
    [createPost.pending]: (state, action) => {
      state.createPost.loading = true;
      state.createPost.hasError = false;
      state.createPost.success = false;
    },
    [createPost.fulfilled]: (state, action) => {
      state.createPost.loading = false;
      state.createPost.success = true;
    },
    [createPost.rejected]: (state, action) => {
      state.createPost.loading = false;
      state.createPost.hasError = true;
    },
    [setTempFile.pending]: (state, action) => {
      state.setTempFile.hasError = false;
      state.setTempFile.success = false;
    },
    [setTempFile.fulfilled]: (state, action) => {
      state.setTempFile.success = true;
    },
    [setTempFile.rejected]: (state, action) => {
      state.setTempFile.hasError = true;
    },
    [getGlobalFeed.pending]: (state, action) => {
      state.globalFeed.fetched = false;
      state.globalFeed.fetching = true;
      state.globalFeed.hasError = false;
    },
    [getGlobalFeed.fulfilled]: (state, action) => {
      state.globalFeed.results = action.payload.data.results;
      state.globalFeed.next = action.payload.data.next;
      state.globalFeed.categories = action.payload.data.categories;
      state.globalFeed.total = action.payload.data.total;
      state.globalFeed.fetched = true;
      state.globalFeed.fetching = false;
    },
    [getGlobalFeed.rejected]: (state, action) => {
      state.globalFeed.fetched = true;
      state.globalFeed.fetching = false;
      state.globalFeed.hasError = true;
    },

    [getGlobalFeedMore.pending]: (state) => {
      state.globalFeed.fetching = true;
      state.globalFeed.hasError = false;
    },
    [getGlobalFeedMore.fulfilled]: (state, action) => {
      state.globalFeed.fetching = false;
      state.globalFeed.results = [
        ...state.globalFeed.results,
        ...action.payload.data.results,
      ];
      state.globalFeed.categories = action.payload.data.categories;
      state.globalFeed.total = action.payload.data.total;
      state.globalFeed.next = action.payload.data.next;
    },
    [getGlobalFeedMore.rejected]: (state) => {
      state.globalFeed.fetching = false;
      state.globalFeed.hasError = true;
    },
  },
});

export const { resetGlobalFeed } = feedSlice.actions;

export const selectGlobalFeed = (state) => state.feed.globalFeed;

export default feedSlice.reducer;
