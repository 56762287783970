import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/buttons/Button';
import { BottomDrawer } from 'components/bottomDrawer';
import styles from './storyActions.module.css';

const ActionsDrawer = ({
  blockModal,
  reportModal,
  deleteModal,
  insightsModal,
  isVisible,
  onClickAction,
  isMyProfile,
  pauseAction,
}) => {
  const { t } = useTranslation();

  return (
    <BottomDrawer
      isVisible={isVisible}
      onClose={() => {
        pauseAction('play');
        onClickAction();
      }}
    >
      <div className={styles.drawerWrapper}>
        <div className={styles.actions}>
          <div className={styles.actionsButtons}>
            {isMyProfile ? (
              <>
                <Button
                  onClick={() => {
                    onClickAction();
                    deleteModal.showModal();
                  }}
                  size="xl"
                >
                  <span>{t('features:stories.delete.button')}</span>
                </Button>
                <Button
                  onClick={() => {
                    onClickAction();
                    insightsModal.showModal();
                  }}
                  size="xl"
                >
                  <span>{t('features:stories.insights.button')}</span>
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => {
                    onClickAction();
                    reportModal.showModal();
                  }}
                  size="xl"
                >
                  <span>{t('features:blockReport.Report')}</span>
                </Button>
                <Button
                  onClick={() => {
                    onClickAction();
                    blockModal.showModal();
                  }}
                  size="xl"
                >
                  <span>{t('features:blockReport.Block')}</span>
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </BottomDrawer>
  );
};

export default ActionsDrawer;
