import React from 'react';
import styles from './myClubs.module.css';
import cx from "classnames";
import ClubListItem from "./ClubListItem";

const MyClubList = ({clubs}) => {

    let wrapperClass = cx(styles.row, styles.eventlist);
    if (clubs.list.length !== 1) {
        wrapperClass += ' mainContent';
    }

    return (
        <div className={wrapperClass}>
            {clubs.list.map((club, i) => {
                return <ClubListItem hideFilter={true} key={i} club={club}/>;
            })}


        </div>
    );
};

export default MyClubList;
