import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import cx from 'classnames';

import styles from './eventRequestModal.module.css';
import {trackEvent} from "../../../helpers/tracking";

import Avatar from "../../../components/avatar/Avatar";
import {IconChats} from "../../../assets/icons";
import ApplicationButtons from "../applications/ApplicationButtons";
import {LoaderInline} from "../../../components/layout/loader";
import {selectUserInfo} from "../../auth/authSlice";

const EventRequestModal = ({
                               episode,
                               modalContext: {RenderModal},
                               handleUserClick,
                               handleOpenChat
                           }) => {
    const { uid } = useSelector(selectUserInfo);
    const {applications} = episode;
    const {t} = useTranslation();
    const [active, setActive] = useState(2);
    const [isBlocked, setBlocked] = useState(false);
    const tabsClass = cx(styles.tabs);

    const handleClick = (index) => {
        if (index !== active) {
            setActive(index);
        }
    };

    const acceptedApplications = applications.filter(application => application.is_accepted === true);
    const pendingApplications = applications.filter(application => application.is_applied === true);

    const userPrice = (application) => {
        const price = application.profile.gender === 'male' ? episode.desired_male_company_price : episode.desired_female_company_price;
        return (
            price === 0 ?
                <span className={styles.entryPrice}>{ t('features:event.entry','Entry') }: <strong>{ t('features:eventRequest.free') }</strong> </span>
                :
                <span className={styles.entryPrice}>{ t('features:event.entry','Entry') }: <strong>{application.profile.user_currency} {price}</strong> </span>
        )
    };

    return (
        <RenderModal title={t('features:event.guests', 'Guests')} buttonClose>
            <div className={styles.wrapper}>
                <div className={styles.tabsWrapper}>
                    <ul
                        className={tabsClass}
                    >
                        <li
                            onClick={() => {
                                trackEvent({
                                    category: 'event request - approved',
                                    action: 'event detail page request view all',
                                });
                                handleClick(1);
                            }}
                            className={cx(styles.tab, {[styles.tabActive]: active === 1})}
                        >
                            <span>{t('features:event.Approved', 'Approved')}</span>
                        </li>

                        <li
                            onClick={() => {
                                trackEvent({
                                    category: 'event request - requested',
                                    action: 'event detail page request view all',
                                });
                                handleClick(2);
                            }}
                            className={cx(styles.tab, {[styles.tabActive]: active === 2})}
                        >
                            <span>{t('features:event.Requested', 'Requested')}</span>
                        </li>
                    </ul>
                </div>

                {active === 2 &&
                    <div className={styles.mainWrapper}>
                        {pendingApplications.length > 0
                        && pendingApplications.map((application, i) => {
                            return (
                                <div className={styles.statsBox}>
                                    <div className={styles.statsContent}>
                                        <div className={styles.statsContentItem}>
                                            <Avatar
                                                key={i}
                                                size={50}
                                                onClick={() => handleUserClick(application.profile)}
                                                image={application.profile.avatar}
                                                gender={application.profile.gender}
                                                circle
                                            />
                                            <div>
                                                <small>{application.profile.name}</small>
                                            </div>
                                        </div>
                                        {/*<span className={styles.vl}></span>*/}
                                        <div className={styles.statsContentItem}>
                                            {/*<IconChats className={styles.icon}/>*/}
                                            <IconChats className={styles.icon}
                                                       onClick={() => handleOpenChat(application.profile, application.chat_id, episode.id)}/>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className={styles.statsAction}>
                                        {episode.profile.uid === uid &&
                                            <ApplicationButtons
                                                application={application}
                                                episodeId={episode.id}
                                                loaderCallback={setBlocked}
                                            />
                                        }
                                        <div>
                                            <small>{userPrice(application)}</small>
                                        </div>
                                    </div>
                                    {isBlocked && (
                                        <div className={styles.overlay}>
                                            <LoaderInline centered color="turq"/>
                                        </div>
                                    )}
                                </div>
                            );
                        })
                        }
                    </div>
                }
                {active === 1 &&
                    <div className={styles.mainWrapper}>
                        {acceptedApplications.length > 0
                        && acceptedApplications.map((application, i) => {
                            return (
                                <div className={styles.statsBox} key={i}>
                                    <div className={styles.statsContent}>
                                        <div className={styles.statsContentItem}>
                                            <Avatar
                                                key={i}
                                                size={50}
                                                onClick={() => handleUserClick(application.profile)}
                                                image={application.profile.avatar}
                                                gender={application.profile.gender}
                                                circle
                                            />
                                            <div>
                                                <small>{application.profile.name}</small>
                                            </div>
                                        </div>
                                        {/*<span className={styles.vl}></span>*/}
                                        <div className={styles.statsContentItem}>
                                            <IconChats className={styles.icon}
                                                       onClick={() => handleOpenChat(application.profile, application.chat_id, episode.id)}/>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                        }
                    </div>
                }
            </div>
        </RenderModal>
    );
};

export default EventRequestModal;
