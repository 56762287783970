import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import cx from 'classnames';

import {trackEvent} from 'helpers/tracking';
import {getAvatarSrc} from 'helpers/s3media';
import {selectUserGender} from 'features/auth/authSlice';
//import { ProfileBadges } from 'components/layout/badges/Badges';
//import {IconPhoto, IconVideo} from 'assets/icons';
import styles from './profileCard.module.css';
//import {ProfileBadges} from "../layout/badges/Badges";
import {LocationInfo} from "../../features/user/profileInfo/profileMetaInfo";
//import {faClose} from "@fortawesome/free-solid-svg-icons";
//import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
//import {faCertificate} from "@fortawesome/free-solid-svg-icons/faCertificate";

const ProfileCardPreview = ({profile, suggestions, narrow, isPublic}) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [imageDefaultSrc, setImageDefaultSrc] = useState(null);
    const history = useHistory();
    const location = useLocation();
    const userGender = useSelector(selectUserGender);

    const {
        id,
        name,
        age,
        avatar,
        gender,
        hash_id,
    } = profile;

    const size = 400;
    const cardStyle = cx(styles.colcustom, {
        [styles.suggestions]: suggestions,
        [styles.narrow]: narrow,
        [styles.loaded]: loaded,
    });

    const handleClick = () => {
        let cat = suggestions ? 'suggestions' : 'home';
        if (location.pathname.includes('/profile')) {
            cat = 'profile suggestions';
        } else if (location.pathname.includes('/community')) {
            cat = 'feed suggestions';
        } else if (location.pathname.includes('/chat')) {
            cat = 'chat suggestions';
        } else if (location.pathname === '/users/search') {
            cat = 'search';
        }
        trackEvent({
            category: cat.concat(isPublic ? ' - public' : ''),
            action: `profile card click - ${gender}`,
            label: userGender,
        });
        const profileLink = isPublic ? `/${hash_id}` : `/profile/${id}`;
        history.push(profileLink);
    };

    useEffect(() => {
        const image_src = getAvatarSrc(avatar, size, gender);
        const srcDefault = getAvatarSrc(null, size, gender);

        setError(false);
        setImageSrc(image_src);
        setImageDefaultSrc(srcDefault);
    }, [avatar, gender]);

    const HandleImageError = () => {
        if (!error) {
            setError(true);
        }
    };

    return (

            <div className={cardStyle} onClick={handleClick}>
                <div className={styles.cola}>
                    <div className={styles.userimg}>
                        {/*<div className={styles.imgover}>
                            <span className={styles.online}>Online</span>
                            <span className={styles.dots}></span>
                        </div>*/}
                        <div className={styles.imageContainer}>
                            <div>
                                {imageSrc && (
                                    <img
                                        className={styles.image}
                                        src={error ? imageDefaultSrc : imageSrc}
                                        alt={name}
                                        onLoad={() => setLoaded(true)}
                                        onError={() => HandleImageError()}
                                        // s3key={`${avatar.public_id}.${avatar.format}`}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <h3>{name} <span className={styles.age}>{age}</span>
                        {/*<FontAwesomeIcon icon={faCertificate} color={'black'} width={20}/>*/}
                    </h3>
                    {/*<span className={styles.city}><img src="img/de.svg" />Hamburg</span>*/}
                    <div className={styles.city}>
                        <LocationInfo
                            town_name={profile.city}
                            country={profile.country}
                        />
                    </div>
                    {/*<div className={styles.media}>
              <span className={styles.videos}><i className="fa-solid fa-clapperboard"></i>103</span>
              <span className={styles.images}><i className="fa-solid fa-camera"></i>65</span>
            </div>*/}
                    <span className={styles.btn}>View Profile</span>
                </div>
            </div>

        /*<div onClick={handleClick} className={cardStyle}>
          <div className={styles.inner}>
            {imageSrc && (
              <img
                className={styles.image}
                src={error ? imageDefaultSrc : imageSrc}
                alt={name}
                onLoad={() => setLoaded(true)}
                onError={() => HandleImageError()}
                // s3key={`${avatar.public_id}.${avatar.format}`}
              />
            )}

            <div className={styles.top}>
              <div className={styles.topLeft}>
                {online && <span className={styles.status}>Online</span>}
              </div>
              <div className={styles.topRight}>
                <ProfileBadges
                  small={suggestions}
                  verified={verified}
                  dating={dating_enabled}
                  phone={verified_phone}
                />
              </div>
            </div>
            {/!*<div className={styles.bottom}>
              <div className={styles.bottomLine}>
                <p className={styles.user}>
                  <span className={styles.name}>{name}</span>
                  {Number(age) > 0 && <span className={styles.age}>{age}</span>}
                </p>
              </div>
              <div className={styles.bottomMeta}>
                {total_images > 0 && (
                  <span>
                    <IconPhoto /> {total_images}
                  </span>
                )}
                {total_videos > 0 && (
                  <span>
                    <IconVideo /> {total_videos}
                  </span>
                )}
              </div>
            </div>*!/}
          </div>
          <div className={styles.bottomLine}>
            <p className={styles.user}>
              <span className={styles.name}>{name}</span>
              {Number(age) > 0 && <span className={styles.age}>{age}</span>}
            </p>
            <LocationInfo
                town_name={profile.city}
                country={profile.country}
            />
          </div>
        </div>*/
    );
};

export default ProfileCardPreview;
