import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { getVideoGifSrc, getFeedMediaPreviewSrc } from 'helpers/s3media';
import Button from 'components/buttons/Button';
import { useModal } from 'components/modal/useModal';
import { startContentTransaction } from 'features/transactions/transactionsSlice';
import { selectUserGender } from 'features/auth/authSlice';
import { feedEmpty } from 'assets/default';
import { IconLock, IconPhoto, IconVideo } from 'assets/icons';
import { trackEvent } from 'helpers/tracking';
import { secToMin } from 'helpers/timeAdaptors';
import styles from '../activityDetail.module.css';

const ActivityFeedPreview = ({
  id,
  actor,
  file,
  coins,
  stats,
  videosDuration,
  publicModal,
}) => {
  const [error, setError] = useState(false);
  const [mediaSrc, setMediaSrc] = useState(null);
  const [defaultMediaSrc, setDefaultMediaSrc] = useState(null);
  const buyModal = useModal();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userGender = useSelector(selectUserGender);
  const startTransactionPayload = {
    actor: actor,
    item: {
      ...file,
      id,
      coins,
      stats,
      videosDuration,
    },
  };

  useEffect(() => {
    const fileSrc =
      file.type === 'video'
        ? getVideoGifSrc(file)
        : getFeedMediaPreviewSrc(file);

    const defaultSrc =
      file.type === 'video'
        ? getVideoGifSrc()
        : getFeedMediaPreviewSrc(feedEmpty);

    setError(false);
    setMediaSrc(fileSrc);
    setDefaultMediaSrc(defaultSrc);
  }, [file]);

  const HandleImageError = () => {
    if (!error) {
      setError(true);
      setMediaSrc(defaultMediaSrc);
    }
  };

  const onUnlockPost = () => {
    trackEvent({
      category: `profile pages${publicModal ? ' - public' : ''}`,
      action: 'unlock post click',
      label: userGender,
    });
    if (publicModal) {
      return publicModal();
    }
    dispatch(startContentTransaction(startTransactionPayload));
  };

  return (
    <div style={{ position: 'relative' }} onClick={onUnlockPost}>
      <div className={styles.preview} onClick={buyModal.showModal}>
        {!isEmpty(file) && (
          <>
            <img
              src={mediaSrc}
              className={cx(styles.imgPreview, {
                [styles.empty]: error,
              })}
              alt=""
              onError={() => HandleImageError()}
            />
            <div className={styles.overlay}>
              <IconLock className={styles.lock} />
              <Button size="xl" style={{ minWidth: '260px' }}>
                {t('features:contentPreview.unlock', 'Unlock post', {
                  coins: coins,
                })}
              </Button>
            </div>
          </>
        )}
      </div>
      <div className={styles.meta}>
        <span>
          {stats.num_images > 0 && (
            <span className={styles.metaItem}>
              <IconPhoto className={styles.metaIcon} /> {stats.num_images}
            </span>
          )}
          {stats.num_videos > 0 && (
            <span className={styles.metaItem}>
              <IconVideo className={styles.metaIcon} />{' '}
              {secToMin(videosDuration)}
            </span>
          )}
        </span>
        <span className={styles.metaRight}>
          <IconLock className={styles.metaIcon} /> {coins} Coins
        </span>
      </div>
    </div>
  );
};

ActivityFeedPreview.propTypes = {
  id: PropTypes.string,
  actor: PropTypes.object.isRequired,
  file: PropTypes.object,
  coins: PropTypes.number.isRequired,
  stats: PropTypes.object.isRequired,
  videosDuration: PropTypes.array,
  publicModal: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default ActivityFeedPreview;
