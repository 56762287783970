import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const WithDelay = ({ children, ms }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const delayFn = setTimeout(() => {
      setShow(true);
    }, ms);
    return () => clearTimeout(delayFn);
  }, [ms]);

  if (!show) {
    return null;
  }

  return <>{children}</>;
};

WithDelay.propTypes = {
  ms: PropTypes.number,
};

WithDelay.defaultProps = {
  ms: 100,
};

export default WithDelay;
