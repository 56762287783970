import React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/buttons/Button';
import { closeDescriptioUpdate, selectInfoMessages } from './infoMessagesSlice';

import { IconClose } from 'assets/icons';
import styles from './contentUpdateMessage.module.css';

const ContentUpdateMessageBody = () => {
  const { descriptioUpdate, newPosts } = useSelector(selectInfoMessages);

  if (newPosts.total && [1, 2].includes(descriptioUpdate.status)) {
    return (
      <Trans i18nKey={'features:updateInfo.aboutAndPosts'}>
        <strong>
          Your description &amp; posting will be confirmed immediately!
        </strong>{' '}
        <span>
          Your content will be published as soon as our team has checked if it
          complies with our guidelines.
        </span>
      </Trans>
    );
  }

  if (newPosts.total) {
    return (
      <Trans i18nKey={'features:updateInfo.posts'}>
        <strong>Your posting will be confirmed immediately!</strong>{' '}
        <span>
          Your content will be published as soon as our team has checked if it
          complies with our guidelines.
        </span>
      </Trans>
    );
  }

  return (
    <Trans i18nKey={'features:updateInfo.about'}>
      <strong>Your profile description will be confirmed immediately!</strong>{' '}
      <span>
        Your content will be published as soon as our team has checked if it
        complies with our guidelines.
      </span>
    </Trans>
  );
};

const ContentUpdateMessage = () => {
  const { descriptioUpdate, newPosts, userDissmissed } = useSelector(
    selectInfoMessages
  );
  const dispatch = useDispatch();

  const isMessageVisible = () => {
    if (userDissmissed) return false;
    return !(newPosts.total === 0) || [1, 2].includes(descriptioUpdate.status);
  };

  const clickClose = () => {
    dispatch(closeDescriptioUpdate());
  };

  if (!isMessageVisible()) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.body}>
        <div className={styles.label}>
          <span>INFO</span>
        </div>
        <div className={styles.content}>
          <ContentUpdateMessageBody />
        </div>
        <div className={styles.close}>
          <Button onClick={clickClose} name="btn-bare" className={styles.btn}>
            <IconClose />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContentUpdateMessage;
