import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation} from 'swiper';

import {checkImageSrc, getAvatarSrc, getImageSrc, getStoryPreviewMedia} from 'helpers/s3media';
import {IconArrowLeft} from 'assets/icons';
import styles from './profileImagesDesk.module.css';
import {useTranslation} from "react-i18next";
import isEmpty from "lodash/isEmpty";
import cx from "classnames";

SwiperCore.use([Navigation]);

const ProfileImagesDesk = ({
                               images,
                               handleImageClick,
                               isMobile,
                               stories,
                               handleStoryAvatarClick,
                               hasSeenAllStories,
                               profileUser,
                               size
                           }) => {
    if (!images.length) return null;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {t} = useTranslation();
    const imageArrowLength = isMobile ? 3 : 6
    const showNextArrow = images.length > imageArrowLength;

    const requestSize = size > 110 ? 220 : 110;
    const image_src =
        typeof profileUser.avatar === 'string'
            ? checkImageSrc(profileUser.avatar, requestSize, profileUser.gender)
            : getAvatarSrc(profileUser.avatar, requestSize, profileUser.gender);
    //const srcDefault = getAvatarSrc(null, requestSize, profileUser.gender);

    const story = !isEmpty(stories) ? stories.find((s) => s.seen === false) || stories[0] : null;
    const storySrc = !isEmpty(story)  ? getStoryPreviewMedia(story.media, 400) : null;

    return (
        <>
            <div className={styles.wrapper}>
                <Swiper
                    freeMode
                    spaceBetween={5}
                    slidesPerView={'auto'}
                    slidesPerGroup={4}
                    navigation={{
                        prevEl: '.home-prev',
                        nextEl: '.home-next',
                    }}
                >
                    {!isEmpty(stories) && (
                        <SwiperSlide key={0} style={{width: '100px'}}>
                            <div
                                onClick={() => handleStoryAvatarClick()}
                                className={styles.slideInner}
                                style={{
                                    backgroundImage: `url(${storySrc})`,
                                }}
                            ></div>
                            <div
                                className={cx(styles.storyWrapper, { [styles.allSeen]: hasSeenAllStories })}
                                onClick={handleStoryAvatarClick}
                            >
                                <div className={styles.storyLeft}>
                                    <img src={image_src} alt="" />
                                </div>
                            </div>
                            {/*<MobileStories
                                stories={stories}
                                onClick={() => handleStoryAvatarClick()}
                                allSeen={hasSeenAllStories}
                            />*/}
                            <div className={styles.storyText}>
                                {t('features:stories.story')}
                            </div>
                        </SwiperSlide>
                    )}

                    {images.map((image, i) => {
                        const url = getImageSrc({
                            public_id: image.public_id,
                            format: image.format,
                            resize: {
                                width: 400,
                                fit: 'cover',
                            },
                        });
                        return (
                            <SwiperSlide key={i} style={{width: '100px'}}>
                                <div
                                    onClick={() => handleImageClick(i)}
                                    className={styles.slideInner}
                                    style={{
                                        backgroundImage: `url(${url})`,
                                    }}
                                ></div>
                                {/*{!isEmpty(stories) && i === 0 && (

                                    <>
                                        <MobileStories
                                            stories={stories}
                                            onClick={() => handleStoryAvatarClick()}
                                            allSeen={hasSeenAllStories}
                                        />
                                        <div className={styles.storyText}>
                                            {t('features:stories.story')}
                                        </div>
                                    </>
                                )}*/}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                <span className={`${styles.prevButton} home-prev`}>
                    <IconArrowLeft width={25} height={16}/>
                </span>
                {showNextArrow && (
                    <span className={`${styles.nextButton} home-next`}>
                        <IconArrowLeft width={25} height={16}/>
                    </span>
                )}
            </div>
        </>
    );
};

export default ProfileImagesDesk;
