import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  getEpisodes,
  selectEpisodes,
  selectEpisodesFetched,
  resetNewEpisodesCount,
} from './episodesSlice';
import { selectFilters } from 'app/miscSlice';

import EpisodesListItem from './EpisodeListItem';
import styles from './episodesList.module.css';
import {useModal} from "../../../components/modal/useModal";
import cx from "classnames";
import EpisodesListHeader from "../episodesListHeader/EpisodesListHeader";
import MyEpisodesEmpty from "./myEpisodes/MyEpisodesEmpty";
import Loader from "../../../components/layout/loader";

const EpisodesList = ({hideFilter}) => {
  const dispatch = useDispatch();
  const fetchedEpisodes = useSelector(selectEpisodesFetched);
  const episodesList = useSelector(selectEpisodes);
  //const user = useSelector(selectUserInfo);
  const filters = useSelector(selectFilters);
  //const town = user.town_name;
  const verificationModal = useModal();
  //const { indispensable_verification } = useSelector(selectUserInfo);

  useEffect(() => {
    if (isEmpty(episodesList) && !fetchedEpisodes) {
      dispatch(getEpisodes());
    }
    /*if (isEmpty(clubList) && !fetchedClubs) {
      dispatch(getClubs());
    }*/
  }, [dispatch, episodesList, filters.gender, fetchedEpisodes]);

  useEffect(() => {
    return () => dispatch(resetNewEpisodesCount());
  }, [dispatch]);

  if (isEmpty(episodesList)) {
    return fetchedEpisodes ? <MyEpisodesEmpty/> : <Loader/>;
  }
  /*if (isEmpty(episodesList) && !fetchedEpisodes) {
    return (
      <div>
        <EpisodesListHeader />
        <Loader />
      </div>
    );
  }*/

  /*const createDateValidation = () => {

    if(indispensable_verification === true)
    {
      verificationModal.showModal();
      return false;
    }
    history.push('/create-event')
  }*/

  /*if (isEmpty(episodesList)) {
    return (
      <div>
        <VerificationModal
            modalContext={verificationModal}
        />
        <EpisodesListHeader />
        <div className={styles.emptyList}>
          <span className={styles.emptyIconWrapper}>
            <FontAwesomeIcon icon={faCalendar} width={20} />
          </span>

          {[1,2,3].includes(filters.filter_episode_applications) && (
              <>
              <strong>{ t('features:episodes.filterNoRequest') }</strong>
              <p>{ t('features:episodes.filterNoRequestSub') }</p>
              </>
          )}

          {[0,4].includes(filters.filter_episode_applications) && (
              <>
              <Trans i18nKey={'features:episodes.noRequests'}>
                <strong>No new events in {{ town }}</strong>
                <p>Come back in a few minutes or search in a city nearby.</p>
              </Trans>
              <Button
                onClick={createDateValidation}
                name="btn-gradient"
                size="xl"
                extraPadding
              >
                {t('features:event.nav.shareEventRequest')}
              </Button>
              </>
            )}
        </div>
      </div>
    );
  }*/
  //let wrapperClass = cx(styles.row, styles.eventlist, styles.grid);
  let wrapperClass = cx(styles.row, styles.eventlist);
  if (episodesList.length !== 1) {
    wrapperClass += ' mainContent';
  }
  return (
    <div>
      <div className={wrapperClass} >
        {!hideFilter &&  <EpisodesListHeader/> }
        {episodesList.map((episode, i) => {
          return <EpisodesListItem hideFilter={hideFilter} key={i} episode={episode} verificationModal={verificationModal} />;
        })}
      </div>
    </div>
  );
};

export default EpisodesList;
