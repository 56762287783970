import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../app/api';

export const redeemVoucher = createAsyncThunk(
  'voucher/redeemVoucher',
  async (code, { rejectWithValue }) => {
    const res = await api.post('/v2/voucher/redeem', { code });
    if (!res.data.status) {
      return rejectWithValue(res.data.error);
    }
    return res.data;
  }
);

export const voucher = createSlice({
  name: 'voucher',
  initialState: {
    loading: false,
    error: false,
    success: false,
    showModal: false,
    showVoucherInfo: false,
  },
  reducers: {
    toggleVoucher: (state, { payload }) => {
      state.showModal = payload;
      if (!payload) {
        state.loading = false;
        state.error = false;
        state.success = false;
      }
    },
  },
  extraReducers: {
    [redeemVoucher.pending]: (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    [redeemVoucher.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.success = true;
    },
    [redeemVoucher.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload.code;
      state.success = false;
    },
  },
});

export const { toggleVoucher } = voucher.actions;
export const selectVoucher = (state) => state.voucher;

export default voucher.reducer;
